import React, { useState, useEffect } from "react";
import "./APIPage.style.scss";
import { API_BASE_PATH, API_BASE_RELATIVE_PATH } from "../../Config/Config";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import Button from "@mui/material/Button";
import { styled } from "@mui/system";
import FolderIcon from "@mui/icons-material/Folder";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { ColorButton } from "./../../components/common/common.Component";
import DeleteIcon from '@mui/icons-material/Delete';
import Alert from "@mui/material/Alert";

import { Box, Typography, FormControl, InputLabel, Select, MenuItem, Paper } from "@mui/material";

import { colorExport } from "../../components/common/common.Component";

import ApiCall from "./../../components/ApiCall/ApiCall.component";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import { CommonMethods } from "./../../components/common/CommonMethods";
import { useNavigate } from "react-router-dom";

import {
  fetchProducts,
  fetchModules,
  fetchEnvironments,
  fetchTestCasesfromModule,
} from "../../API/CreateCollectionAPI";
import { fetchAllApiDetails, fetchAllAutoApiDetails,deleteApi } from "./../../API/Api";
import Divider from "@mui/material/Divider";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";

const StyledTab = styled(Tab)(() => ({
  minWidth: "200px",
  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
  fontWeight: 600,
  "&.Mui-selected": {
    color: "#0fc6ee",
    backgroundColor: "#032f40",
  },
}));

const StyledButton = styled(Button)(() => ({
  width: "100%",
  "&:hover": {
    backgroundColor: "#032f40",
    color: "#0fc6ee",
  },
}));

const StyledTabList = styled(TabList)({
  "& .MuiTabs-indicator": {
    backgroundColor: "#0fc6ee",
    height: 0,
  },
});



const APIPage = () => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);

  const [state, setState] = useState({
    ResolutionList: [],
    URLList: [],
    idproducts: "",
    idproductsApi: "",
    idmodules: "",
    idmodulesApi: "",
    idtest_case: "",
    SelectEnvironmentName: "",
    SelectEnvironmentUrl: "",
    email: "",
    password: "",
    company: "",
  });
  const [apiData, setApiData] = useState({});
  const [LoadApiSide, setLoadApiSide] = useState(false);
  const [loadAutoApiSide, setLoadAutoApiSide] = useState(false);
  const [productList, setproductList] = useState([]);
  const [PrevAPIList, setPrevAPIList] = useState([]);
  const [prevAutoAPIList, setPrevAutoAPIList] = useState([]);
  const [ModuleList, setModuleList] = useState([]);
  const [TestCaseList, setTestCaseList] = useState([]);
  const [product, setProduct] = useState("");
  
  const [uploadnew, setUploadNew] = useState({
    file: "",
    filename: "",
  });

  const [SearchString, setSearchString] = useState("");
  const navigate = useNavigate();
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === "2") {
      // Reset states related to the "Auto Api" tab
      setState((prevState) => ({
        ...prevState,
        idproductsApi: "",
        idmodulesApi: "",
        idtest_case: "",
        SelectEnvironmentName: "",
        SelectEnvironmentUrl: "",
        // Add any other state you want to reset related to the Auto Api tab
      }));
      setPrevAutoAPIList([]); // Resetting the list as an example
      setLoadAutoApiSide(false); // Resetting a flag as an example
    } else if (newValue === "1") {
      // Reset states related to the "Custom Api" tab
      setState((prevState) => ({
        ...prevState,
        idproducts: "",
        idmodules: "",
        SelectEnvironmentName: "",
        SelectEnvironmentUrl: "",
        // Add any other state you want to reset related to the Custom Api tab
      }));
      setPrevAPIList([]); // Resetting the list as an example
      setLoadApiSide(false); // Resetting a flag as an example
    }
  };
  const getProducts = async (api_input) => {
    await fetchProducts(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setproductList(response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const selectedProduct = (event) => {
    setProduct(event.target.value);
    getModules(event.target.value);
  };
  const selectedModule = (event) => {
    // setModule(event.target.value);
  };

  const [deleteIconValue, setDeleteIconValue] = useState();

  const handleUploadNew = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileName = file.name;
      const extension = fileName.split(".").pop().toLowerCase();
      if (extension === "json" ) {
        setUploadNew({
          file: e.target.files,
          filename: fileName,
        });
      } else {
        // setOpenAlertForZip(true);
        e.target.value = null;
      }
    }
  };

  const getUploadFile = () => {
    fetch(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=ingestSwagger&companyid=${temp.companyid}&token=${temp.token}&product=${state.idproducts}&module=${state.idmodules}&fileName=${uploadnew.file[0].name}`,
      {
        method: "POST",
        body: uploadnew.file[0],
        headers: {
          "content-type": uploadnew.file[0].type,
          "content-length": `${uploadnew.file[0].size}`,
        },
      },
    )
      .then((res) => {
        res.json();
        setUploadNew({
          file: "",
          filename: "",
        });
        console.log("137 selected-->", uploadnew.file[0].name);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllApiDetails = async (id) => {
    await fetchAllApiDetails(id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setPrevAPIList(response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllTestCaseApiDetails = async (id) => {
    await fetchAllAutoApiDetails(id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setPrevAutoAPIList(response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getModules = async (id) => {
    await fetchModules(id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setModuleList(response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getEnvironments = async (id) => {
    await fetchEnvironments(id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setState((prev) => {
              return {
                ...prev,
                envDetails: response.data,
              };
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTestCase = async (id) => {
    await fetchTestCasesfromModule(id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setTestCaseList(response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDelete = async (id, modid) => {
    await deleteApi(id, modid)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setTestCaseList(response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const handleClickOpenDeleteAlert = () => {
    setOpenDeleteAlert(true);
  };
  const handleCloseDeleteAlert = () => {
    setOpenDeleteAlert(false);
  };

  {openDeleteAlert && (
    <Alert
      variant="filled"
      severity="warning"
      onClose={handleCloseDeleteAlert}
      open={openDeleteAlert}
      className="delete-alert"
    >
      <span className="delete-text">Are you sure you want to delete this step.?</span>
      <div>
        <ColorButton
          className="delete-button-yes"
          onClick={(event) => {
            handleCloseDeleteAlert();
            handleDelete(deleteIconValue, state.idmodules);
          }}
        >
          Yes
        </ColorButton>
        <ColorButton className="delete-button-no" onClick={handleCloseDeleteAlert}>
          No
        </ColorButton>
      </div>
    </Alert>
  )}

  const colors = colorExport();

  useEffect(() => {
    getProducts();
  }, []);

  const customApi = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flex: 1,
          alignItem: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            paddingY: "1.5rem",
            backgroundColor: colors.secondaryColor,
            color: colors.primaryColor,
          }}
        >
          <FormControl sx={{ width: 240, marginLeft: "2rem", color: colors.primaryColor }}>
            <InputLabel
              style={{
                color: colors.primaryColor,
                backgroundColor: colors.secondaryColor,
                padding: 5,
              }}
              id={"product name"}
            >
              Product
            </InputLabel>
            <Select
              labelId={"product name"}
              id={"product name Select"}
              value={state.idproducts}
              name={"product"}
              label="Product"
              IconComponent={(props) => (
                <ArrowDropDownSharpIcon
                  style={{ color: colors.primaryColor, fontSize: 40 }}
                  {...props}
                />
              )}
              sx={{
                color: colors.primaryColor,
                border: `2px solid ${colors.primaryColor}`,
                fontSize: 16,
                "&:focus": {
                  borderRadius: 4,
                  border: `4px solid ${colors.primaryColor}`,
                },
              }}
              onChange={(event) => {
                setState({ ...state, idproducts: event.target.value });
                getModules(event.target.value);
                getEnvironments(event.target.value);
              }}
              style={{
                minWidth: 100,
                marginRight: "2rem",
              }}
            >
              {productList.map((item, index) => (
                <MenuItem key={index} value={item.idproducts}>
                  {item.productname}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {state.idproducts !== "" && (
            <FormControl sx={{ width: 240 }}>
              <InputLabel
                style={{
                  color: colors.primaryColor,
                  backgroundColor: colors.secondaryColor,
                  padding: 5,
                }}
                id={"module name"}
              >
                Module
              </InputLabel>
              <Select
                labelId={"module name"}
                IconComponent={(props) => (
                  <ArrowDropDownSharpIcon
                    style={{ color: colors.primaryColor, fontSize: 40 }}
                    {...props}
                  />
                )}
                sx={{
                  color: colors.primaryColor,
                  border: `2px solid ${colors.primaryColor}`,
                  fontSize: 16,
                  "&:focus": {
                    borderRadius: 4,
                    border: `4px solid ${colors.primaryColor}`,
                  },
                }}
                id={"module name Select"}
                value={state.idmodules}
                label="Module"
                onChange={(event) => {
                  setState({ ...state, idmodules: event.target.value });

                  getAllApiDetails(event.target.value);
                }}
                style={{
                  minWidth: 100,
                  marginRight: "2rem",
                }}
              >
                {ModuleList.map((item, index) => (
                  <MenuItem key={index} value={item.idmodules}>
                    {item.modulename}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {state.idmodules !== "" && (
            <FormControl>
            <ColorButton
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
              sx={{ marginLeft: 4 }}
              // disabled={stepDetails ? true : false}
            >
              Import From Swagger
              <input
                type="file"
                style={{ display: "none" }}
                onChange={handleUploadNew}
                accept=".json"
              />
            </ColorButton>
          </FormControl>
          )}
          {module && (
            <FormControl>
              {uploadnew.file[0] && (
                <Typography variant="body2" sx={{ marginLeft: 2 }}>
                  {uploadnew.file[0].name}
                </Typography>
              )}
            </FormControl>
          )}
          {module && uploadnew.file[0] && (
            <FormControl>
              <ColorButton
                component="label"
                variant="contained"
                sx={{ marginLeft: 4 }}
                // disabled={createNewTestCase && (stepDetails || uploadnew.file[0]) ? false : true}
                onClick={() => {
                    getUploadFile();
                    // setOpenAlertSave(true);
                    setUploadNew({
                      file: "",
                      filename: "",
                    });

                }}
              >
                Submit
              </ColorButton>
            </FormControl>
          )}
        </Box>

        {state.idmodules !== "" && (
          <Box className="main-box">
            <Box className="left-box">
              <Box
                display="flex"
                p="1rem"
                borderBottom="2px solid rgba(0, 0, 0, 0.12)"
                flexDirection="column"
                rowGap="1rem"
              >
                <Box sx={{ alignSelf: "center", flex: 1, width: "100%" }}>
                  <Paper
                    component="form"
                    sx={{
                      color: colors.primaryColor,
                      p: "2px 0.5rem",
                      marginLeft: "0rem",
                      display: "flex",
                      alignItems: "center",
                      border: `2px solid ${colors.primaryColor}`,
                      boxShadow: "none",
                      width: "100%",
                    }}
                  >
                    <InputBase
                      sx={{ flex: 1 }}
                      placeholder="Search here..."
                      inputProps={{ "aria-label": "Search here..." }}
                      defaultValue={SearchString}
                      onChange={(e) => {
                        e.preventDefault();
                        setSearchString(e.target.value);
                      }}
                    />
                    <SearchIcon />
                  </Paper>
                </Box>
                <Box
                  sx={{
                    alignSelf: "center",
                    textAlign: "right",
                    width: "100%",
                  }}
                >
                  <StyledButton
                    onClick={() => {
                      setApiData({ newApi: true });
                      setLoadApiSide(true);
                    }}
                    sx={{
                      alignSelf: "center",
                      backgroundColor: colors.secondaryColor,
                      color: colors.primaryColor,
                      flex: 1,
                    }}
                  >
                    New Api
                  </StyledButton>
                </Box>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  overflowY: "auto",
                  backgroundColor: colors.secondaryColor,
                  p: "1rem",
                  rowGap: "0.5rem",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {PrevAPIList.filter((value) =>
                  value?.name?.toLowerCase().includes(SearchString.toLowerCase()),
                ).map((item, index) => (
                  <div className="grid-item-api" key={index}>
                    <Typography
                      paddingBottom={0}
                      variant="body1"
                      component="div"
                      display="flex"
                      alignItems="center"
                      sx={{
                        fontWeight: 700,
                        fontSize: "1rem",
                        cursor: "pointer",
                        color: colors.primaryColor,
                        ":hover": {
                          textDecoration: "underline",
                        },
                      }}
                      onClick={() => {
                        setApiData(item);
                        setLoadApiSide(true);
                      }}
                    >
                      <FolderIcon sx={{ mr: "0.5rem" }} />
                      {item?.name}
                    </Typography>
                    <DeleteIcon 
                        sx={{ ml: "1rem", cursor: "pointer" }} 
                        onClick={() => {
                          setDeleteIconValue(item.idapi, state.idmodules);
                          handleClickOpenDeleteAlert();
                          handleDelete(item.idapi, state.idmodules);
                         }}
                      />
                  </div>
                ))}
              </Box>
            </Box>
            {/* <Divider orientation="vertical" flexItem variant="middle" /> */}
            <Box className="right-box">
              {LoadApiSide && (
                <ApiCall
                  idproducts={state.idproducts}
                  idmodules={state.idmodules}
                  SelectEnvironmentName={state.SelectEnvironmentName}
                  SelectEnvironmentUrl={state.SelectEnvironmentUrl}
                  apiData={apiData}
                  value={"custom"}
                />
              )}
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  const testCaseApi = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flex: 1,
          alignItem: "center",
          // justifyContent: "flex-start",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            paddingY: "1.5rem",
            backgroundColor: colors.secondaryColor,
            color: colors.primaryColor,
          }}
        >
          <FormControl sx={{ width: 240, marginLeft: "2rem", color: colors.primaryColor }}>
            <InputLabel
              style={{
                color: colors.primaryColor,
                backgroundColor: colors.secondaryColor,
                padding: 5,
              }}
              id={"product name"}
            >
              Product
            </InputLabel>
            <Select
              labelId={"product name"}
              id={"product name Select"}
              value={state.idproductsApi}
              name={"product"}
              label="Product"
              IconComponent={(props) => (
                <ArrowDropDownSharpIcon
                  style={{ color: colors.primaryColor, fontSize: 40 }}
                  {...props}
                />
              )}
              sx={{
                color: colors.primaryColor,
                border: `2px solid ${colors.primaryColor}`,
                fontSize: 16,
                "&:focus": {
                  borderRadius: 4,
                  border: `4px solid ${colors.primaryColor}`,
                },
              }}
              onChange={(event) => {
                setState({ ...state, idproductsApi: event.target.value });
                getModules(event.target.value);
              }}
              style={{
                minWidth: 100,
                marginRight: "2rem",
              }}
            >
              {productList.map((item, index) => (
                <MenuItem key={index} value={item.idproducts}>
                  {item.productname}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* <Typography paddingBottom={0} variant="h6" component="h2">
          Create API
        </Typography> */}

          {state.idproductsApi !== "" && (
            <FormControl sx={{ width: 240 }}>
              <InputLabel
                style={{
                  color: colors.primaryColor,
                  backgroundColor: colors.secondaryColor,
                  padding: 5,
                }}
                id={"module name"}
              >
                Module
              </InputLabel>
              <Select
                labelId={"module name"}
                IconComponent={(props) => (
                  <ArrowDropDownSharpIcon
                    style={{ color: colors.primaryColor, fontSize: 40 }}
                    {...props}
                  />
                )}
                sx={{
                  color: colors.primaryColor,
                  border: `2px solid ${colors.primaryColor}`,
                  fontSize: 16,
                  "&:focus": {
                    borderRadius: 4,
                    border: `4px solid ${colors.primaryColor}`,
                  },
                }}
                id={"module name Select"}
                value={state.idmodulesApi}
                label="Module"
                onChange={(event) => {
                  setState({ ...state, idmodulesApi: event.target.value });
                  getTestCase(event.target.value);
                }}
                style={{
                  minWidth: 100,
                  marginRight: "2rem",
                }}
              >
                {ModuleList.map((item, index) => (
                  <MenuItem key={index} value={item.idmodules}>
                    {item.modulename}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {state.idmodulesApi !== "" && (
            <FormControl
              sx={{
                width: 240,
              }}
            >
              <InputLabel
                style={{
                  color: colors.primaryColor,
                  backgroundColor: colors.secondaryColor,
                  padding: 5,
                }}
                id={"Test Case name"}
              >
                Test Cases
              </InputLabel>
              <Select
                labelId={"Test Case name"}
                IconComponent={(props) => (
                  <ArrowDropDownSharpIcon
                    style={{ color: colors.primaryColor, fontSize: 40 }}
                    {...props}
                  />
                )}
                sx={{
                  color: colors.primaryColor,
                  border: `2px solid ${colors.primaryColor}`,
                  fontSize: 16,
                  "&:focus": {
                    borderRadius: 4,
                    border: `4px solid ${colors.primaryColor}`,
                  },
                }}
                id={"Test Case Name Select"}
                label="Test Case"
                value={state.idtest_case}
                onChange={(event) => {
                  setState({ ...state, idtest_case: event.target.value });
                  getAllTestCaseApiDetails(event.target.value);
                }}
                style={{
                  minWidth: 100,
                }}
              >
                {TestCaseList.map((item, index) => (
                  <MenuItem key={index} value={item.idtest_case}>
                    {item.Test_Case}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Box>

        {state.idtest_case !== "" && (
          <Box className="main-box">
            <Box className="left-box">
              <Box marginY={"1rem"} sx={{ alignSelf: "center" }}>
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    marginLeft: "0rem",
                    display: "flex",
                    alignItems: "center",
                    width: 280,
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search here..."
                    inputProps={{ "aria-label": "Search here..." }}
                    defaultValue={SearchString}
                    onChange={(e) => {
                      e.preventDefault();
                      setSearchString(e.target.value);
                    }}
                  />
                  <SearchIcon />
                </Paper>
              </Box>
              {prevAutoAPIList
                .filter((value) => value?.url?.includes(SearchString))
                .map((item, index) => (
                  <div
                    className="grid-item-api"
                    style={{ backgroundColor: colors.secondaryColor, color: colors.primaryColor }}
                    key={index}
                    title={item?.url}
                  >
                    <Typography
                      paddingBottom={0}
                      variant="body1"
                      component="div"
                      sx={{
                        fontWeight: 700,
                        fontSize: "1rem",
                        alignItems: "center",
                        cursor: "pointer",
                        ":hover": {
                          textDecoration: "underline",
                        },
                      }}
                      onClick={() => {
                        setApiData(item);
                        setLoadAutoApiSide(true);
                      }}
                    >
                      {item?.url
                        ? item.url.substring(35, 75) + (item.url.length > 75 ? "..." : "")
                        : ""}
                    </Typography>
                  </div>
                ))}
              {/* <ColorButton onClick={() => {
              setApiData({ newApi: true })
              setLoadApiSide(true)
            }}
              sx={{ alignSelf: "center" }}>New Api</ColorButton> */}
            </Box>
            <Divider orientation="vertical" flexItem variant="middle" />
            <Box className="right-box">
              {loadAutoApiSide && (
                <ApiCall
                  idproducts={state.idproductsApi}
                  idmodules={state.idmodulesApi}
                  SelectEnvironmentName={state.SelectEnvironmentName}
                  SelectEnvironmentUrl={state.SelectEnvironmentUrl}
                  apiData={apiData}
                  value={"auto"}
                />
              )}
            </Box>
          </Box>
        )}
      </Box>
    );
  };
  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        alignItem: "center",
        justifyContent: "space-evenly",
        flexDirection: "column",
        px: "1rem",
        pt: "0.5rem",
      }}
    >
      <Box
        sx={{
          width: "100%",
          typography: "body1",
          border: 2,
          borderColor: "divider",
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        <TabContext sx={{}} value={value}>
          <Box sx={{ borderColor: "divider" }}>
            <StyledTabList onChange={handleChange} aria-label="lab API tabs example">
              <StyledTab label="Custom Api" value="1" />
              <StyledTab label="Auto Api" value="2" />
            </StyledTabList>
          </Box>
          <TabPanel sx={{ p: 0 }} value="1">
            {customApi()}
          </TabPanel>
          <TabPanel sx={{ p: 0 }} value="2">
            {testCaseApi()}
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
};

export default APIPage;
