import axios from "axios";

import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "./../Config/Config";

export const fetchAllApiDetails = async (id) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);

  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getAllApi&companyid=${temp.companyid}&token=${temp.token}&ModuleId=${id}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
  return Response;
};

export const fetchAllAutoApiDetails = async (id) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);

  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getapiontc&companyid=${temp.companyid}&token=${temp.token}&testcaseid=${id}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
  return Response;
};

export const deleteApi = async (id, modid) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);

  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=deleteApi&companyid=${temp.companyid}&token=${temp.token}&apiid=${id}&moduleId=${modid}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
  return Response;
};

export const SaveAPI = async (data, action) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  if (data.newApi) {
    if(action !== null && action === "test"){
      action = "addApiTest";
    } else if(action !== null && action === "send"){
      action = "addApiSend";
    } else {
      action = "addApi";
    }
     
    const Response = await axios
      .post(
        `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=${action}&companyid=${temp.companyid}&token=${temp.token}`,
        data
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
    return Response;
  } else {
    
    if(action !== null && action === "test"){
      action = "updateApiTest";
    } else if(action !== null && action === "send"){
      action = "updateApiSend";  
    } else {
      action = "updateApi";
    }
    
    const Response = await axios
    .post(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=${action}&companyid=${temp.companyid}&token=${temp.token}`,
      data
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
  return Response;

  }
};

export const fetchApiDetails = async (id) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getApi&companyid=${temp.companyid}&token=${temp.token}&ApiId=${id}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const fetchAutoApiDetails = async (id) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getapidetailsontc&companyid=${temp.companyid}&token=${temp.token}&Apiid=${id}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};