import React, { useEffect, useState } from "react";
import "./ApiCall.style.scss";

import { Button, IconButton, InputLabel, FormControl } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";

import { API_BASE_PATH, API_BASE_RELATIVE_PATH } from "./../../Config/Config";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SyncDisabledIcon from "@mui/icons-material/SyncDisabled";
import LoopIcon from "@mui/icons-material/Loop";
import CloseIcon from "@mui/icons-material/Close";
import SettingsIcon from "@mui/icons-material/Settings";

import { useNavigate, useLocation } from "react-router-dom";

// import { API_BASE_PATH, API_BASE_RELATIVE_PATH } from "./../../Config/Config";

import {
  fetchProducts,
  fetchModules,
  fetchEnvironments,
  fetchTestCasesfromModule,
} from "./../../API/CreateCollectionAPI";

import {
  ColorButton,
  StyledTableRow,
  StyledTableCell,
  PrimaryColor,
  SecondaryColor,
  BootstrapTooltip,
} from "./../common/common.Component";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { colorExport } from "./../common/common.Component";

import axios from "axios";
import JsonEditorPanel from "./JsonEditorPanel.component";
import { SaveAPI, fetchApiDetails, fetchAutoApiDetails } from "./../../API/Api";

const StyledButton = styled(Button)(() => ({
  backgroundColor: "#032f40",
  color: "#0fc6ee",
  "&:hover": {
    backgroundColor: "#032f40",
    color: "#0fc6ee",
  },
}));

const StyledTab = styled(Tab)(() => ({
  minWidth: "200px",
  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
  fontWeight: 600,
  "&.Mui-selected": {
    color: "#0fc6ee",
    backgroundColor: "#032f40",
  },
}));

const StyledTabList = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: "#0fc6ee",
    height: 0,
  },
});

const CustomTextField = styled(TextField)(() => ({
  "& .MuiInputBase-input": {
    color: "#0fc6ee",
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#0fc6ee",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const colors = colorExport();

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      // sx={{ backgroundColor: colors.PrimaryColor }}
    >
      {value === index && (
        <Box sx={{ padding: "1rem" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const KeyValueEditor = ({ keyPair, setKeyPair, onKeyPairRemove }) => {
  const [keyValue, setKeyValue] = useState(keyPair);
  const [debouncedKeyValue, setDebouncedKeyValue] = useState(keyValue);

  useEffect(() => {
    setDebouncedKeyValue(keyValue);
  }, [keyValue]);

  useEffect(() => {
    setKeyPair(debouncedKeyValue);
    // eslint-disable-next-line
  }, [debouncedKeyValue]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          maxHeight: 56,
          columnGap: "1rem",
          mb: "1rem",
        }}
      >
        <CustomTextField
          size="small"
          onChange={(event) => {
            setKeyValue((prevState) => ({
              ...prevState,
              idapiparams: keyValue.idapiparams,
              [event.target.name]: event.target.value,
            }));
          }}
          variant="outlined"
          autoFocus
          // value={state.reqUrl}
          placeholder="Key"
          name="key"
          value={keyValue.key}
          sx={{ border: "1px solid #0fc6ee", borderRadius: "3px" }}
        />
        <CustomTextField
          size="small"
          onChange={(event) => {
            setKeyValue((prevState) => ({
              ...prevState,
              idapiparams: keyValue.idapiparams,
              [event.target.name]: event.target.value,
            }));
          }}
          variant="outlined"
          autoFocus
          // value={state.reqUrl}
          placeholder="Value"
          name="value"
          value={keyValue.value}
          sx={{ border: "1px solid #0fc6ee", borderRadius: "3px" }}
        />
        <StyledButton
          className="px-4 rounded-md text-red-500 border border-red-300 hover:bg-red-100"
          onClick={() => onKeyPairRemove(keyPair)}
          sx={{ color: "#0fc6ee", letterSpacing: "1.2" }}
        >
          Remove
        </StyledButton>
      </Box>
    </>
  );
};

const requestMethods = [
  {
    slug: "get",
    method: "GET",
  },
  {
    slug: "post",
    method: "POST",
  },
  {
    slug: "put",
    method: "PUT",
  },
  {
    slug: "patch",
    method: "PATCH",
  },
  {
    slug: "delete",
    method: "DELETE",
  },
];

const ApiCall = (props) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const [state, setState] = useState({
    productname: "",
    modulename: "",
    idproducts: props.idproducts,
    idmodules: props.idmodules,
    Type: "GET",
    reqUrl: `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=latestResult&companyid=${temp.companyid}&token=${temp.token}`,
    body: "{\n\t\n}",
    browserDetails: [
      { browsername: "Chrome" },
      { browsername: "Edge" },
      { browsername: "Firefox" },
      { browsername: "Safari" },
      { browsername: "ALL" },
    ],
    version: 1,
    status: 0,
    resolutionDetails: [],
    envDetails: [],
    name: "",
    SelectEnvironmentName: props.SelectEnvironmentName,
    SelectEnvironmentUrl: props.SelectEnvironmentUrl || "",
    SelectResolution: "",
    SelectBrowserName: "Chrome",
    RerunFailTest: false,
    StopTestAfterFailue: false,
    Height: 0,
    Width: 0,
  });
  const [value, setValue] = useState(0);
  const [ParamPaneValue, setParamPaneValue] = useState([]);
  const [HeaderPaneValue, setHeaderPaneValue] = useState([]);
  const [responsevalue, setResponsevalue] = useState(0);

  const [body, setBody] = useState("{\n\t\n}");
  const [results, setResults] = useState("{\n\t\n}");
  const [header, setHeader] = useState("{\n\t\n}");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const responsehandleChange = (event, newValue) => {
    setResponsevalue(newValue);
  };
  const onKeyPairAdd = (type) => {
    if (type === "Param") {
        // Generate a random number between 1000 and 9999
        let randnum = Math.floor(1000 + Math.random() * 9000);
      // };
      setParamPaneValue((ParamPaneValue) => [
        ...ParamPaneValue,
        {
          idapiparams: randnum,
          key: "",
          value: "",
        },
      ]);
    } else {
      setHeaderPaneValue((HeaderPaneValue) => [
        ...HeaderPaneValue,
        {
          idapiparams: "",
          key: "",
          value: "",
        },
      ]);
    }
  };

  const onKeyPairRemove = (keyPair, type) => {
    if (type === "Param") {
      let newKeyValues = [...ParamPaneValue];
      newKeyValues = newKeyValues.filter((x) => x.idapiparams !== keyPair.idapiparams);
      setParamPaneValue(newKeyValues);
    } else {
      let newKeyValues = [...HeaderPaneValue];
      newKeyValues = newKeyValues.filter((x) => x.idapiparams !== keyPair.idapiparams);
      setHeaderPaneValue(newKeyValues);
    }
  };

  const onKeyPairUpdate = (keyPair, type) => {
    if (type === "Param") {
      const elementIndex = ParamPaneValue.findIndex(
        (element) => element.idapiparams === keyPair.idapiparams,
      );
      let newKeyValues = [...ParamPaneValue];
      newKeyValues[elementIndex] = {
        ...newKeyValues[elementIndex],
        key: keyPair.key,
        value: keyPair.value,
      };
      setParamPaneValue(newKeyValues);
    } else {
      const elementIndex = HeaderPaneValue.findIndex(
        (element) => element.idapiparams === keyPair.idapiparams,
      );
      let newKeyValues = [...HeaderPaneValue];
      newKeyValues[elementIndex] = {
        ...newKeyValues[elementIndex],
        key: keyPair.key,
        value: keyPair.value,
      };
      setHeaderPaneValue(newKeyValues);
    }
  };

  const SendApiToSave = async (data) => {
    await SaveAPI(data)
      .then((response) => {
        if (response.status === 200) {
          // setPrevAPIList(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (props.apiData.newApi) {
      setBody('');
      setResults('');
      setState((prev) => {
        return {
          ...prev,
          reqUrl: `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?`,
          version: 1,
          status: 0,
          moduleId: props.moduleId,
          envurl: "",
          type: "GET",
          body: `{ }`,
          headers: `{ }`,
          name: "",
          tests: `{ }`,
        };
      });
    } else {
      if (props.value === "custom") {
        getApiDetails(props.apiData.idapi);
      } else if (props.value === "auto") {
        getAutoApiDetails(props.apiData.apiid);
      }
    }
  }, [props.apiData]);

  const getApiDetails = async (id) => {
    await fetchApiDetails(id)
      .then((response) => {
        if (response.status === 200) {
          setParamPaneValue((prev) => {
            return response.data.param.map((item) => {
              return {
                idapiparams: item.idapiparams,
                key: item.keyname,
                value: item.value,
              };
            });
          });

          setBody('');
          setResults('');
          setState((prev) => {
            return {
              ...prev,
              reqUrl: response.data.url,
              name: response.data.name,
              version: response.data.version,
              status: response.data.status,
              moduleId: response.data.moduleId,
              envurl: response.data.envurl,
              Type: response.data.type,
              body: response.data.body,
              headers: response.data.headers,
              param: response.data.param,
              idapi: response.data.idapi,
              tests: response.data.tests,
            };
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAutoApiDetails = async (id) => {
    await fetchAutoApiDetails(id)
      .then((response) => {
        if (response.status === 200) {
          setBody('');
          setResults('');
          setState((prev) => {
            return {
              ...prev,
              reqUrl: response.data.url,
              // name: response.data.name,
              // version: response.data.version,
              // status: response.data.status,
              // moduleId: response.data.moduleId,
              // envurl: response.data.envurl,
              Type: response.data.type,
              body: response.data.body,
              headers: response.data.headers,
              tests: response.data.tests,
              // param: response.data.param,
              // idapi: response.data.idapi,
            };
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const callApi = async (data) => {
    const Response = await SaveAPI(data, "send")
      .then((response) => {
        if (response.status === 200) {
          // setPrevAPIList(response.data);
          setBody(response.data.response);
          return response;
        }
      })
      .catch((error) => {
        console.log(error);
      });
      return Response;
  };

  const callApiTest = async (data) => {
    const Response = await SaveAPI(data, "test")
      .then((response) => {
        if (response.status === 200) {
          // setPrevAPIList(response.data);
          setBody(response.data.response);
          setResults(response.data.result);
          return response;
        }
      })
      .catch((error) => {
        console.log(error);
      });
      return Response;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        alignItem: "flex-start",
        justifyContent: "flex-start",
        flexDirection: "column",
        rowGap: "1rem",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          columnGap: "1rem",
        }}
      >
        <TextField
          size="small"
          onChange={(event) => {
            setState({
              ...state,
              name: event.target.value,
            });
          }}
          variant="outlined"
          id="name"
          label="API Name"
          name="APINAME"
          autoFocus
          value={state.name}
          sx={{ flex: 1 }}
        />
        <StyledButton
          sx={{ minWidth: "250px" }}
          onClick={(e) => {
            SendApiToSave({
              url: state.reqUrl,
              version: state.version,
              status: state.status,
              moduleId: state.idmodules,
              envurl: state.SelectEnvironmentUrl,
              type: state.Type,
              body: state.body,
              headers: state.headers,
              newApi: props?.apiData?.newApi,
              idapi: state.idapi,
              name: state.name,
              param: ParamPaneValue,
              tests: state.tests,
            });
          }}
        >
          {props?.apiData?.newApi ? "Save" : "Update"}
        </StyledButton>
      </Box>
      <Box
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          maxHeight: 40,
          alignItems: "center",
          columnGap: "1rem",
        }}
      >
        <FormControl>
          <Select
            size="small"
            labelId="Api Type"
            id="Api Type"
            value={state.Type === null ? "GET" : state.Type}
            name="Api Type"
            onChange={(event) => {
              setState({
                ...state,
                Type: event.target.value,
              });
            }}
            style={{
              minWidth: 168,
              alignItems: "center",
            }}
          >
            {requestMethods.map((item, index) => (
              <MenuItem key={index} value={item.method}>
                {item.method}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          size="small"
          onChange={(event) => {
            setState({
              ...state,
              reqUrl: event.target.value,
            });
          }}
          variant="outlined"
          id="reqUrl"
          label="Request URL"
          name="reqUrl"
          autoFocus
          value={state.reqUrl}
          sx={{ flex: 1 }}
        />
        <StyledButton
          sx={{ minWidth: "115px" }}
          onClick={(e) => {
            // addintoUrl();
            callApi({
              url: state.reqUrl,
              version: state.version,
              status: state.status,
              moduleId: state.idmodules,
              envurl: state.SelectEnvironmentUrl,
              type: state.Type,
              body: state.body,
              headers: state.headers,
              newApi: props?.apiData?.newApi,
              name: state.name,
              idapi: state.idapi,
              tests: state.tests,
              param: ParamPaneValue,
            });
          }}
        >
          Call
        </StyledButton>
        <StyledButton
          sx={{ minWidth: "115px" }}
          onClick={(e) => {
            // addintoUrl();
            callApiTest({
              url: state.reqUrl,
              version: state.version,
              status: state.status,
              moduleId: state.idmodules,
              envurl: state.SelectEnvironmentUrl,
              type: state.Type,
              body: state.body,
              headers: state.headers,
              newApi: props?.apiData?.newApi,
              name: state.name,
              idapi: state.idapi,
              tests: state.tests,
              param: ParamPaneValue,
            });
          }}
        >
          Test
        </StyledButton>
      </Box>
      <Box
        sx={{
          border: 1,
          borderRadius: "8px",
          borderColor: "divider",
          overflow: "hidden",
        }}
      >
        <Box>
          <StyledTabList value={value} onChange={handleChange} aria-label="basic tabs example">
            <StyledTab label="Query Params" />
            <StyledTab label="Headers" />
            <StyledTab label="Body" />
          </StyledTabList>
        </Box>
        <TabPanel value={value} index={0}>
          {ParamPaneValue && ParamPaneValue.length > 0 ? (
            ParamPaneValue.map((keyPair) => (
              <KeyValueEditor
                key={keyPair.idapiparams}
                keyPair={keyPair}
                setKeyPair={(keyPairValue) => onKeyPairUpdate(keyPairValue, "Param")}
                onKeyPairRemove={() => onKeyPairRemove(keyPair, "Param")}
              />
            ))
          ) : null} 
          <StyledButton
            className="px-6 py-1 rounded-md text-orange-600 border border-orange-400 hover:bg-orange-100"
            onClick={() => onKeyPairAdd("Param")}
            sx={{ marginRight: "1rem", border: "1px solid #0fc6ee" }}
          >
            Add
          </StyledButton>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <JsonEditorPanel
            paneValue={state.headers}
            setPaneValue={(e) => {
              setState((prev) => {
                return { ...prev, headers: e };
              });
            }}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <JsonEditorPanel
            paneValue={state.body}
            setPaneValue={(e) => {
              setState((prev) => {
                return { ...prev, body: e };
              });
            }}
          />
        </TabPanel>
      </Box>
      <Box
        sx={{
          marginTop: "1rem",
          border: 1,
          borderRadius: 2,
          borderColor: "divider",
          overflow: "hidden",
        }}
      >
        <Box>
          <StyledTabList
            value={responsevalue}
            onChange={responsehandleChange}
            aria-label="basic tabs example"
          >
            <StyledTab label="Response" />
          </StyledTabList>
        </Box>
        <TabPanel value={responsevalue} index={0}>
          <Box
            sx={{
              maxHeight: "400px", // Set a maximum height
              overflow: "auto", // Add scrollbars if content overflows
              whiteSpace: "pre-wrap", // Wrap whitespace for preformatted text like JSON
              wordWrap: "break-word", // Break words to prevent overflow
            }}
          >
            {body}
          </Box>
        </TabPanel>
        </Box>

        <Box
        sx={{
          marginTop: "1rem",
          border: 1,
          borderRadius: 2,
          borderColor: "divider",
          overflow: "hidden",
        }}
      >
        <Box>
          <StyledTabList
            value={responsevalue}
            onChange={responsehandleChange}
            aria-label="basic tabs example"
          >
            <StyledTab label="Tests" />
          </StyledTabList>
        </Box>
        <TabPanel value={value} index={0}
        sx={{
          maxWidth: "600px", // Set your desired max width
          width: "100", // Make it responsive up to the max width
        }}
        >
          <JsonEditorPanel
            paneValue={state.tests}
            setPaneValue={(e) => {
              setState((prev) => {
                return { ...prev, tests: e };
              });
            }}
          />
          <Box
            sx={{
              maxHeight: "400px", // Set a maximum height
              overflow: "auto", // Add scrollbars if content overflows
              whiteSpace: "pre-wrap", // Wrap whitespace for preformatted text like JSON
              wordWrap: "break-word", // Break words to prevent overflow
            }}
          >
            
          </Box>
        </TabPanel>        
      </Box>   
      <Box
        sx={{
          marginTop: "1rem",
          border: 1,
          borderRadius: 2,
          borderColor: "divider",
          overflow: "hidden",
        }}
      >
        <Box>
          <StyledTabList
            value={responsevalue}
            onChange={responsehandleChange}
            aria-label="basic tabs example"
          >
            <StyledTab label="Test Results" />
          </StyledTabList>
        </Box>
        <TabPanel value={responsevalue} index={0}>
          <Box
            sx={{
              maxHeight: "400px", // Set a maximum height
              overflow: "auto", // Add scrollbars if content overflows
              whiteSpace: "pre-wrap", // Wrap whitespace for preformatted text like JSON
              wordWrap: "break-word", // Break words to prevent overflow
            }}
          >
            {results}
          </Box>
        </TabPanel>
        </Box>
         
    </Box>
    
  );
};

export default ApiCall;
