import React from "react";
import {
  ChevronDownIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";

const ChainTestResults = ({ results }) => {
  if (!results || results.length === 0) {
    return (
      <div className="text-postman-gray-medium p-4">No results to display.</div>
    );
  }

  const uniq = [...new Set(results)];

  return (
    <div className="bg-white text-postman-gray-dark w-full">
      {/* Header */}
      <div className="bg-postman-gray-light px-4 py-3 border-b border-gray-200">
        <h2 className="text-sm font-medium">Chain Test Results</h2>
        <p className="text-xs text-postman-gray-medium mt-1">
          Generated on: {new Date().toLocaleString()}
        </p>
      </div>

      {/* Results */}
      <div className="divide-y divide-gray-200">
        {uniq?.map((item, i) =>
          Object.entries(item).map(([path, testCases]) => (
            <details key={path} className="group">
              <summary className="flex items-center justify-between p-3 cursor-pointer list-none hover:bg-postman-gray-light select-none">
                <div className="flex items-center gap-2">
                  <div className="text-xs font-medium px-2 py-1 rounded bg-postman-orange text-white">
                    {path?.split("@")[1]?.split("/")[1] || "API"}
                  </div>
                  <span className="text-sm text-postman-gray-medium">
                    {path?.split("@")[1]}
                  </span>
                </div>
                <ChevronDownIcon className="h-4 w-4 text-postman-gray-medium transition-transform group-open:rotate-180" />
              </summary>

              <div className="border-t border-gray-200 bg-postman-gray-light">
                {Object.entries(testCases).map(([statusCode, result]) => (
                  <div
                    key={statusCode}
                    className="p-3 border-b border-gray-200 last:border-0"
                  >
                    <div className="flex items-center justify-between mb-2">
                      <div className="flex items-center gap-3">
                        <span
                          className={`inline-flex items-center px-2 py-1 rounded text-xs font-medium
                          ${result.success
                              ? "bg-green-100 text-postman-green"
                              : "bg-red-100 text-postman-red"
                            }`}
                        >
                          Status: {statusCode}
                        </span>
                        <span
                          className={`text-xs font-medium ${result.method === "GET"
                              ? "text-postman-blue"
                              : result.method === "POST"
                                ? "text-postman-green"
                                : result.method === "PUT"
                                  ? "text-postman-yellow"
                                  : result.method === "DELETE"
                                    ? "text-postman-red"
                                    : "text-postman-gray-medium"
                            }`}
                        >
                          {result.method}
                        </span>
                      </div>
                      <div className="flex items-center gap-1.5">
                        {result.success ? (
                          <CheckCircleIcon className="h-4 w-4 text-postman-green" />
                        ) : (
                          <XCircleIcon className="h-4 w-4 text-postman-red" />
                        )}
                        <span
                          className={`text-xs font-medium
                          ${result.success
                              ? "text-postman-green"
                              : "text-postman-red"
                            }`}
                        >
                          {result.success ? "PASSED" : "FAILED"}
                        </span>
                      </div>
                    </div>

                    <div className="space-y-2 text-sm">
                      <div>
                        <div className="text-xs text-postman-gray-medium mb-1">
                          Expected Response:
                        </div>
                        <pre className="bg-white p-2 rounded text-xs overflow-x-auto border border-gray-200">
                          {JSON.stringify(result.expected, null, 2)}
                        </pre>
                      </div>

                      <div>
                        <div className="text-xs text-postman-gray-medium mb-1">
                          Actual Response:
                        </div>
                        <pre className="bg-white p-2 rounded text-xs overflow-x-auto border border-gray-200">
                          {JSON.stringify(result.actual, null, 2)}
                        </pre>
                      </div>

                      {result.error && (
                        <div className="mt-2">
                          <div className="text-xs text-postman-red mb-1">
                            Error:
                          </div>
                          <div className="bg-red-50 text-postman-red p-2 rounded text-xs border border-red-200">
                            {result.error}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </details>
          ))
        )}
      </div>
    </div>
  );
};

export default ChainTestResults;
