import React, { useEffect } from "react";
import Navbar from "./components/Navbar/Navbar";
import Sidebar from "./components/Sidebar/Sidebar";
import MainContent from "./components/MainContent/MainContent";
import { useState } from "react";
import "./App.css";
import apiService from "./service/apiService";

// import "./App.style.scss";"
import "../App.style.scss";
import "./index.css";
function App2() {
  const defaultApiRequest = {
    apiName: "",
    apiRequest: {
      method: "", // You can set the default method here, e.g., GET, POST, etc.
      url: "", // You can set a default URL here
      headers: {},
      queryParams: {},
      body: "", // Default body can be an empty string or JSON object
    },
  };

  const [apiNames, setApiNames] = useState([]);
  const [apiRequestData, setApiRequestData] = useState(defaultApiRequest);
  const [apiId, setApiId] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  const addNameToSidebar = (name) => {
    if (name && name !== "") {
      getAllApiNames();
    }
  };

  useEffect(() => {
    getAllApiNames();
  }, []);

  useEffect(() => {
    if (apiId && apiId !== "") {
      getApiById(apiId);
    } else {
      setApiRequestData(defaultApiRequest);
    }
  }, [apiId]);

  const getAllApiNames = async () => {
    const apiData = await apiService("GET", "/all", null, {}, {});
    setApiNames(apiData?.data);
  };

  const getApiById = async (id) => {
    const apiData = await apiService("GET", `/${id}`, null, {}, {});
    setApiRequestData(apiData?.data);
  };

  return (
    <div className="App">
      <Navbar toggleSidebar={toggleSidebar} />
      <div className="app-body">
        {/* <Sidebar setApiId={(id)=>setApiId(id)}  names={apiNames} isOpen={isSidebarOpen}/> */}
        <MainContent apiRequestData={apiRequestData} addNameToSidebar={addNameToSidebar} />
      </div>
    </div>
  );
}

export default App2;
