import React, { useEffect, useState } from "react";
import { XMarkIcon, PlusIcon } from "@heroicons/react/24/outline";
import { API_BACKEND_URL } from "../../../Config/Config";

const TestCaseForm = ({ endpointId, open, handleCloseDialog, collections }) => {
  const getPathById = (id) => {
    for (const collection of collections) {
      const endpoint = collection.endpoints.find((e) => e.id === id);
      if (endpoint) {
        return endpoint.path;
      }
    }
    return null;
  };

  const [formData, setFormData] = useState({
    endpoint: "",
    testCase: "",
    httpMethod: "",
    inputParameters: "",
    expectedStatusCode: "",
    expectedResult: "",
    testType: "",
  });

  useEffect(() => {
    if (endpointId) {
      const endpoint = getPathById(endpointId);
      if (endpoint) {
        setFormData((prev) => ({ ...prev, endpoint }));
      }
    }
  }, [endpointId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_BACKEND_URL}/testcase/${endpointId}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        alert("Test case created successfully!");
        handleCloseDialog();
      } else {
        throw new Error("Failed to create test case");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to create test case.");
    }
  };

  if (!open) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 overflow-y-scroll">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-4xl max-h-[600px]  overflow-y-scroll">
        <div className="flex items-center justify-between p-3 border-b border-postman-gray-light">
          <h2 className="text-lg font-semibold text-postman-gray-dark">Create New Test Case</h2>
          <button
            onClick={handleCloseDialog}
            className="text-postman-gray-medium hover:text-postman-gray-dark"
          >
            <XMarkIcon className="h-6 w-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="col-span-2">
              <input
                type="text"
                name="endpoint"
                value={formData.endpoint}
                onChange={handleChange}
                className="w-full px-3 py-2 bg-postman-gray-light border  rounded-md text-postman-gray-medium"
                disabled
                placeholder="Endpoint"
              />
            </div>

            <div>
              <input
                type="text"
                name="testCase"
                value={formData.testCase}
                onChange={handleChange}
                className="w-full px-3 py-2 border  rounded-md focus:ring-2 focus:ring-postman-blue focus:border-transparent"
                placeholder="Test Case Name"
                required
              />
            </div>

            <div className="flex gap-4">
              <input
                type="text"
                name="httpMethod"
                value={formData.httpMethod}
                onChange={handleChange}
                className="w-1/2 px-3 py-2 border  rounded-md focus:ring-2 focus:ring-postman-blue focus:border-transparent"
                placeholder="HTTP Method"
                required
              />
              <input
                type="text"
                name="testType"
                value={formData.testType}
                onChange={handleChange}
                className="w-1/2 px-3 py-2 border  rounded-md focus:ring-2 focus:ring-postman-blue focus:border-transparent"
                placeholder="Test Type"
                required
              />
            </div>

            <div className="col-span-2">
              <textarea
                name="inputParameters"
                value={formData.inputParameters}
                onChange={handleChange}
                rows={2}
                className="w-full px-3 py-2 border  rounded-md focus:ring-2 focus:ring-postman-blue focus:border-transparent font-mono text-sm"
                placeholder="Input Parameters"
                required
              />
            </div>

            <div className="col-span-2">
              <textarea
                name="expectedResult"
                value={formData.expectedResult}
                onChange={handleChange}
                rows={2}
                className="w-full px-3 py-2 border  rounded-md focus:ring-2 focus:ring-postman-blue focus:border-transparent font-mono text-sm"
                placeholder="Expected Result"
                required
              />
            </div>

            <div className="col-span-2 flex justify-between items-center">
              <input
                type="text"
                name="expectedStatusCode"
                value={formData.expectedStatusCode}
                onChange={handleChange}
                className="w-32 px-3 py-2 border  rounded-md focus:ring-2 focus:ring-postman-blue focus:border-transparent"
                placeholder="Status Code"
                required
              />

              <div className="flex space-x-4">
                <button
                  type="button"
                  onClick={handleCloseDialog}
                  className="px-4 py-2 text-postman-gray-medium hover:text-postman-gray-dark"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="flex items-center px-4 py-2 bg-postman-orange text-white rounded-md hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-postman-orange font-semibold"
                >
                  <PlusIcon className="h-5 w-5 mr-2 " />
                  Create Test Case
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TestCaseForm;
