import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_BASE_PATH, API_BASE_RELATIVE_PATH } from "../Config/Config";
import axios from "axios";

const updateTestData = async ({ api_input, fromStrategy, fileData, modid }) => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");
  const user = JSON.parse(userDetails);

  let params = new URLSearchParams({
    action: "updateTestData",
    companyid: user.companyid,
    token: user.token,
    testCaseId: api_input.Test_Case_Id,
    teststepid: api_input.idtest_step,
    testdata: api_input.testdata_source,
    modid: modid,
  });

  const temp = fromStrategy ? api_input.strategy : api_input.testdata_source;
  let isScope = false;

  const formData = new FormData();

  const headers = fileData
    ? {
        "Content-Type": fileData.type,
        "Content-Length": `${fileData.size}`,
      }
    : {};

  switch (temp) {
    case "AsRecorded":
    case "DynamicText":
    case "D0":
    case "DNext":
    case "DBelow":
      params.append("actualdata", api_input.testdata_source);
      break;
    case "SameRowElement":
      params.append("actualdata", api_input.TestData);
      break;
    case "DependentonOtherTestScenario":
      params.append("testCaseIdNew", api_input.DependentTC);
      params.append("testdatavalue", api_input.useVar);
      break;
    case "ChangeTestData":
      params.append("testdatavalue", api_input.TestData);
      break;
    case "GenData":
      params.append("testdataType", api_input.gendataType);
      params.append("testdatalen", api_input?.gendatalen);
      if (api_input.gendataType === "TODAY") {
        params.append("testdateformat", api_input.TestData.split("~")[2] || "");
        params.append("testdateoffset", api_input.TestData.split("~")[3] || "");
      }
      break;
    case "SameRow":
      params.append("tcid", api_input.Test_Case_Id);
      params.append("elementType", api_input.samerowtype);
      params.append("elementIndex", api_input.samerowindex);
      if (fromStrategy) params.append("strategy", api_input.strategy);
      break;
    case "Search":
      params.append("actualdata", api_input.TestData);
      params.append("strategy", api_input.strategy);
      break;
    case "XY":
      params.append("strategy", api_input.strategy);
      params.append("xpos", api_input.xpos);
      params.append("ypos", api_input.ypos);
      break;
    case "X-Path":
      params.append("strategy", api_input.strategy);
      params.append("xpath", api_input.Object_Xpath);
      break;
    case "UseVariableFromTheScenario":
      params.append("testdatavalue", api_input.useVar ?? api_input.TestData);
      break;
    case "FromReferenceFile":
      {
        const fileValue = api_input.filename;
        if (api_input.fromFileType === "xlsx" || api_input.fromFileType === "csv") {
          isScope = true;
          params.append("name", fileValue);
          params.append("sheetId", api_input.sheet);
          params.append("cell", api_input.cell);
          params.append("endRange", api_input.endRange);
        } else if (api_input.fromFileType === "json") {
          isScope = true;
          params.append("name", fileValue);
          params.append("filter", api_input.filter);
          params.append("endRange", api_input.endRange);
        } else {
          params.append("name", api_input.filename);
        }
      }
      break;
    case "FromFile":
      const fileValue = api_input.filename;
      if (api_input.fromFileType === "xlsx" || api_input.fromFileType === "csv") {
        isScope = true;
        params.append("name", fileValue);
        params.append("sheetId", api_input.sheet);
        params.append("cell", api_input.cell);
        params.append("endRange", api_input.endRange);
      } else if (api_input.fromFileType === "json") {
        isScope = true;
        params.append("name", fileValue);
        params.append("filter", api_input.filter);
        params.append("endRange", api_input.endRange);
      } else {
        params.append("name", api_input.filename);
      }

      formData.append("fileData", fileData);
      break;
    case "GoogleSheet":
      isScope = true;
      params.append("sheetId", api_input.filename);
      params.append("sheet", api_input.sheet);
      params.append("cell", api_input.cell);
      if (api_input.endRange) {
        params.append("endRange", api_input.endRange);
      }
      break;
    case "FromApi":
      params.append("fieldName", api_input.TestData);
      params.append("apiid", api_input.apiid);
      params.append("apiParam", api_input.apiparam || "");
      params.append("apiQuery", api_input.DBQuery || "");
      break;
    case "FromDB":
      params.append("dbquery", api_input.DBQuery);
      break;
    case "FromEmail":
      params.append("customerEmail", api_input.customerEmail);
      params.append("customerPassword", api_input.customerPassword);
      params.append("EmailSelectionCriteria", api_input.EmailSelectionCriteria);
      params.append("EmailFilter", api_input.EmailFilter);
      break;
    case "SendEmail":
      params.append("customerEmail", api_input.customerEmail);
      params.append("customerPassword", api_input.customerPassword);
      params.append("toAddress", api_input.toaddress);
      params.append("subject", api_input.emailsubject);
      params.append("content", api_input.emailcontent);
      break;

    default:
      break;
  }

  const apiUrl = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?${params.toString()}${isScope ? `&scope=${api_input.scope}` : ""}`;

  if (fileData) {
    const response = await axios
      .post(apiUrl, fileData, { headers })
      .then((response) => response)
      .catch((error) => {
        console.log(error);
        throw error;
      });

    return response;
  } else {
    const response = await axios
      .post(apiUrl, {})
      .then((response) => response)
      .catch((error) => {
        console.log(error);
        throw error;
      });

    return response;
  }
};

const useUpdateTestDataQuery = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateTestData,
    onSuccess: () => {
      queryClient.invalidateQueries(["testCaseResults"]);
    },
    onError: (error) => {
      console.log("Error updating test data", error.message);
    },
  });
};

export default useUpdateTestDataQuery;
