import axios from "axios";

import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "./../Config/Config";

export const fetchProducts = async (api_input) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);

  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=searchProdDropDown&companyid=${temp.companyid}&token=${temp.token}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};
export const fetchEnvironments = async (id) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getEnv&companyid=${temp.companyid}&token=${temp.token}&productid=${id}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};
export const fetchModules = async (id) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=searchModDropDown&companyid=${temp.companyid}&token=${temp.token}&prodid=${id}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const fetchModulesCheck = async (id) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=checkmodule&companyid=${temp.companyid}&token=${temp.token}&modid=${id}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const fetchfiledatafromDB = async (id) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getfiledatanames&companyid=${temp.companyid}&token=${temp.token}&productid=${id}`,
      // http://localhost:9000/keyloggingv2/ReactApp?action=getfiledatanames&companyid=1&token=34330226&productid=1
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const fetchdownloadedfiles = async (tcid) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=downloadedFile&companyid=${temp.companyid}&token=${temp.token}&tcid=${tcid}`,
      //http://localhost:9000/keyloggingv2/ReactApp?action=downloadedFile&companyid=1&token=07948319&tcid=1
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const fetchfilesdata = async (id, FileName, filefield) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=UpdateTestData&companyid=${temp.companyid}&token=${temp.token}&testCaseId=493&teststepid=50421&testdata=FromReferenceFile&productid=${id}&fileName=${FileName}&fileField=${filefield}`,
      // http://localhost:9000/keyloggingv2/ReactApp?action=updateTestData&companyid=1&token=06756068&testCaseId=493&teststepid=50421&testdata=FromReferenceFile&fileName=NoGrunt.PNG&fileField=Nogrunt
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const fetchSuiteCase = async (id) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getTestSuites&companyid=${temp.companyid}&token=${temp.token}&productid=${id}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

// export const fetchSuiteCaseDownload = async (id) => {
//   const userDetails = localStorage.getItem("userDetail");
//   const temp = JSON.parse(userDetails);
//   const Response = await axios
//     .get(
//       `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=downloadTestSuiteResults&companyid=${temp.companyid}&token=${temp.token}&suiteresultid=${id}`
//     )
//     .then((response) => {
//       return response;
//     })
//     .catch((error) => {
//       console.log(error.response.data.error);
//     });
//   return Response;
// };

export const fetchSuiteCaseDownload = async (id) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  try {
    const downloadUrl = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=downloadTestSuiteResults&companyid=${temp.companyid}&token=${temp.token}&suiteresultid=${id}`;

    window.location.href = downloadUrl;
  } catch (error) {
    console.log(error.response.data.error);
    throw error;
  }
};

export const fetchCaseResultDownload = async (id) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  try {
    const downloadUrl = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=downloadTestCaseResults&companyid=${temp.companyid}&token=${temp.token}&caseresultid=${id}`;

    window.location.href = downloadUrl;
  } catch (error) {
    console.log(error.response.data.error);
    throw error;
  }
};

export const fetchTestCasesfromModule = async (id) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=searchTCDropDown&companyid=${temp.companyid}&token=${temp.token}&modid=${id}&pnum=1`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};
export const fetchTestCasesfromTestSuite = async (id) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getTestCaseFromTestSuite&companyid=${temp.companyid}&token=${temp.token}&testSuiteId=${id}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};
export const fetchTestCasesResult = async (id) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=searchTCRDropDown&companyid=${temp.companyid}&token=${temp.token}&tcid=${id}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};
export const fetchCurrentExtension = async (id) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getCurrentExecutions&companyid=${temp.companyid}&token=${temp.token}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};
export const fetchTestSuitesResult = async (tsid, tsrid) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getTestSuiteResult&companyid=${temp.companyid}&token=${temp.token}&idtest_suite=${tsid}&TSRId=${tsrid}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const fetchTestStepResult = async (id) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=searchTSRDropDown&companyid=${temp.companyid}&token=${temp.token}&tcrid=${id}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};
export const fetchTestStepFailResult = async (id) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getFailTestSteps&companyid=${temp.companyid}&token=${temp.token}&TSRId=${id}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const editTestSuitefromProduct = async (id, name) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=editTestSuites&companyid=${temp.companyid}&token=${temp.token}&idtest_suite=${id}&newName=${name}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const deleteTestSuitefromProduct = async (id) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=delTestSuites&companyid=${temp.companyid}&token=${temp.token}&idtest_suite=${id}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const sendsuitesInfo = async (data) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);

  const Response = await axios
    .post(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=saveTestSuites&companyid=${temp.companyid}&token=${temp.token}&prodid=${data.id}`,
      data.SuiteCaseList,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const sendSuitesWithExcuiteInfo = async (data) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  let tokenValue;
  if (data.licenseKeyValue !== null) {
    tokenValue = data.licenseKeyValue;
  } else {
    tokenValue = temp.token;
  }

  const url = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=${data.ActionValue}&companyid=${temp.companyid}&token=${tokenValue}&prodid=${data.prodId}&SuiteId=${data.id}&envName=${data.SelectEnvironmentName}&envUrl=${data.SelectEnvironmentUrl}&browsername=${data.SelectBrowserName}&resolution=${data.SelectResolution}&RerunFailTest=${data.RerunFailTest}&StopTestAfterFailure=${data.StopTestAfterFailue}`;
  const urlOne = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=${data.ActionValue}&companyid=${temp.companyid}&token=${tokenValue}&prodid=${data.prodId}&SuiteId=${data.id}`;
  if (data.ActionValue === "DLExecuteSuite") {
    return urlOne;
  } else {
    const Response = await axios
      .post(url, data.SuiteCaseList)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response.data.error);
      });
    return Response;
  }
};
export const fetchTestCaseFromTestSuiteResult = async (id, criteria) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);

  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getTestCaseResultFromTestSuiteResult&companyid=${temp.companyid}&token=${temp.token}&testSuiteResultId=${id}&criteria=${criteria}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const fetchTestCaseResultFromTestCase = async (id) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);

  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getTestCaseResultFromTestCase&companyid=${temp.companyid}&token=${temp.token}&testcaseId=${id}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};
export const postEnviromentSettingtoTestSuite = async (data) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);

  const Response = await axios
    .post(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=editTestSuite&companyid=${temp.companyid}&token=${temp.token}`,
      data,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const fetchScreenShotDiff = async (data) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);

  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=compare&companyid=${temp.companyid}&token=${temp.token}&tcid=${data.tcid}&tcrid=${data.tcrid}&failScreenshot=${data.failScreenshot}&tsid=${data.tsid}&stepNumber=${data.stepNumber}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const modalExecute = async (data) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);

  const Response = await axios
    .post(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=modalExecute&companyid=${temp.companyid}&token=${temp.token}&testcaseid=${data.tcid}&stepnum=${data.stepNum}&nextnum=${data.nextNum}&testcasename=${data.tcname}&envUrl=${data.envUrl}`,
      data,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const AnalysisExecute = async (data) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);

  const Response = await axios
    .post(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=analysis&companyid=${temp.companyid}&token=${temp.token}&testcaseid=${data.tcid}&stepnum=${data.stepNum}&nextnum=${data.nextNum}&testcasename=${data.tcname}&envUrl=${data.envUrl}`,
      data,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const addStopTestSuiteCache = async (id, type) => {
  const userDetail = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetail);

  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=stopTestSuite&companyid=${temp.companyid}&token=${temp.token}&id=${id}&type=${type}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const fetchAllTestCaseCount = async (convertedFormDate, convertedToDate) => {
  const userDetail = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetail);

  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getTestCaseStatusCount&companyid=${temp.companyid}&token=${temp.token}&from=${convertedFormDate}&to=${convertedToDate}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};
export const getCompanyName = async (cname) => {
  const userDetail = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetail);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getCompanyNameFromURL&companyUrl=${cname}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
  return Response;
};

export const generateTC = async (api_input) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);

  const encodedInput = encodeURIComponent(api_input);

  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=generateTC&companyid=${temp.companyid}&token=${temp.token}&req=${encodedInput}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const coverageExecute = async (tcid) => {
  const userDetail = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetail);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=coverageExecute&companyid=${temp.companyid}&token=${temp.token}&testcaseid=${tcid}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
  return Response;
};
