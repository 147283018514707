import React from "react";
import { Box, Typography } from "@mui/material";
import { EllipsisTooltipText, IconContainerCircle } from "../../components-new/common";
import { DeleteIcon, EditIcon, SuiteFolderIcon } from "../../components-new/icons";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ChildCheckBox from "./ChildCheckbox";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";

export default function TestSuiteCard({
  width,
  isFolder = false,
  item,
  handleSuiteClick,
  handleDeleteClick,
  testSuiteId = null,
  handleSettings,
  handleReNameSuite,
  addTestCase,
  caseToBeAdded,
  suiteTitle,
  selectedProdId,
}) {
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();

  const handleViewTestCase = () => {
    navigate("/new/test-studio", {
      state: {
        fromExecutionReports: true,
        idproducts: selectedProdId,
        idmodules: item.Module,
        idtest_case: item.idtest_case,
        Test_Case: item.Test_Case,
      },
    });
  };

  return (
    <Box width={width} display="flex" flexDirection="column" position="relative" pt="16px">
      {isFolder && (
        <Box position="absolute" top="1px">
          <SuiteFolderIcon />
        </Box>
      )}
      <Box
        p={"20px 20px 15px"}
        bgcolor={"#ffffff"}
        border="1px solid #D1DBFF"
        borderRadius={"6px"}
        sx={{ borderTopLeftRadius: isFolder ? 0 : "6px", cursor: "pointer" }}
        onClick={() => (isFolder ? handleSuiteClick(item.idtest_suite) : null)}
      >
        <Box display="flex" alignItems="center" onClick={(e) => e.stopPropagation()}>
          <ChildCheckBox addTestCase={addTestCase} caseToBeAdded={caseToBeAdded} item={item} />
          <EllipsisTooltipText text={suiteTitle ? `Suite :` : ""} />
          <EllipsisTooltipText
            text={isFolder ? item.Test_Suite : item.Test_Case ? item.Test_Case : "Test Case"}
          />
        </Box>
        <Box mt={"20px"} display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            {isFolder && (
              <>
                <Typography fontWeight={600} color="#2B3539">
                  {item.list.length}
                </Typography>
                <Typography color="#6A6A6A" fontSize="12px">
                  Test Cases
                </Typography>
              </>
            )}
          </Box>
          <Box display="flex" alignItems="center" columnGap={2}>
            {isFolder && (
              <>
                <Box onClick={handleReNameSuite}>
                  <IconContainerCircle>
                    <EditIcon />
                  </IconContainerCircle>
                </Box>
                <Box onClick={handleSettings}>
                  <IconContainerCircle>
                    <SettingsOutlinedIcon />
                  </IconContainerCircle>
                </Box>
              </>
            )}
            {(testSuiteId || isFolder) && (
              <Box onClick={handleDeleteClick}>
                <IconContainerCircle>
                  <DeleteIcon />
                </IconContainerCircle>
              </Box>
            )}
            {(testSuiteId || !isFolder) && (
              <Box onClick={handleViewTestCase}>
                <IconContainerCircle tooltipText={"View Test Case"}>
                  <VisibilityIcon />
                </IconContainerCircle>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
