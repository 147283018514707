import React, { useMemo, useState } from "react";
import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  TextField,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { CustomSelect } from "../../components-new/common";
import { uniq, map as lmap, filter as lfilter } from "lodash";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";

const formatAutomationScripts = (automationScripts) => {
  if (!automationScripts) return "NA";

  try {
    const outerContent =
      typeof automationScripts === "string"
        ? JSON.parse(automationScripts)
        : JSON.parse(automationScripts.automationScripts);

    const parsedNlp =
      typeof outerContent.nlp === "string" ? JSON.parse(outerContent.nlp) : outerContent.nlp;

    return Object.keys(parsedNlp).map((key) => (
      <span key={key}>
        Step {key}: {parsedNlp[key]}
        <br /> {/* Add line break */}
      </span>
    ));
  } catch (error) {
    return "Invalid Format";
  }
};

function TestCaseTable({
  data,
  handleProcessSelected,
  handleAutomate,
  handleGerkhinChange,
  handleScriptsChange,
}) {
  const [selected, setSelected] = useState([]);
  const [device, setDevice] = useState("All");
  const [persona, setPersona] = useState("All");

  const devices = useMemo(() => {
    const uniqueDevices = uniq(lmap(data, "device"));
    return ["All", ...uniqueDevices];
  }, [data]);

  const personas = useMemo(() => {
    const uniquePersona = uniq(lmap(data, "persona"));
    return ["All", ...uniquePersona];
  }, [data]);

  const filteredData = useMemo(() => {
    let result = data;

    if (device !== "All") {
      result = lfilter(result, { device });
    }

    if (persona !== "All") {
      result = lfilter(result, { persona });
    }

    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device, persona]);

  const hasAutomationScripts = data.some((item) => item.automationScripts);
  const canAutomate = selected.length > 0 && selected.every((item) => item.automationScripts);
  const canProcess = selected.length > 0 && selected.every((item) => !item.automationScripts);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelected(data);
    } else {
      setSelected([]);
    }
  };

  const handleSelect = (item) => {
    setSelected((prevSelected) =>
      prevSelected.includes(item)
        ? prevSelected.filter((selectedItem) => selectedItem.tcid !== item.tcid)
        : [...prevSelected, item],
    );
  };

  return (
    <TableContainer component={Paper}>
      <Box display="flex" gap={2} m={2} alignItems="center">
        <CustomSelect
          label={"Select Device"}
          options={devices.map((item) => ({
            label: item,
            value: item,
          }))}
          width={"230px"}
          background={"#ffffff"}
          value={device}
          onChange={(e) => {
            setDevice(e.target.value);
          }}
        />
        <CustomSelect
          label={"Select Persona"}
          options={personas.map((item) => ({
            label: item,
            value: item,
          }))}
          width={"230px"}
          background={"#ffffff"}
          value={persona}
          onChange={(e) => {
            setPersona(e.target.value);
          }}
        />
        <Button
          variant="contained"
          onClick={() => {
            setDevice("All");
            setPersona("All");
          }}
        >
          Clear Filter
        </Button>
      </Box>
      <Box display="flex" gap={2}>
        {selected.length > 0 && (
          <Box p={2}>
            <Button
              variant="contained"
              disabled={!canProcess}
              onClick={() => {
                handleProcessSelected(selected);
                setSelected([]);
              }}
            >
              Script The Scenario
            </Button>
          </Box>
        )}
        {selected.length > 0 && hasAutomationScripts && (
          <Box p={2}>
            <Button
              disabled={!canAutomate}
              variant="contained"
              onClick={() => {
                handleAutomate(selected);
              }}
            >
              Save the Script
            </Button>
          </Box>
        )}
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={selected.length > 0 && selected.length < data.length}
                checked={data.length > 0 && selected.length === data.length}
                onChange={handleSelectAll}
              />
            </TableCell>
            <TableCell>Sl No</TableCell>
            <TableCell>Persona</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Device</TableCell>

            <TableCell sx={{ width: "600px" }}>Exit Criteria</TableCell>
            <TableCell sx={{ width: "600px" }}>Automation Scripts</TableCell>

            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData?.map((item, index) => (
            <TableRow key={uuidv4()}>
              <TableCell padding="checkbox">
                <Checkbox checked={selected.includes(item)} onChange={() => handleSelect(item)} />
              </TableCell>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{item.persona}</TableCell>
              <TableCell>{item.description}</TableCell>
              <TableCell>{item.device}</TableCell>

              <TableCell sx={{ width: "600px" }}>
                {/* <TextField
                  multiline
                  rows={3}
                  value={item.gherkin}
                  fullWidth
                  InputProps={{
                    sx: { resize: "none" },
                  }}
                  onChange={(e) => handleGerkhinChange(item, e.target.value)}
                /> */}
                <Typography
                  component="div"
                  sx={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}
                >
                  {item.gherkin}
                </Typography>
                <Box display="flex" gap={2} mt={2}>
                  <Button
                    onClick={() => handleGerkhinChange(item)}
                    startIcon={<ModeEditOutlineOutlinedIcon />}
                  >
                    Edit Exit Criteria
                  </Button>
                </Box>
              </TableCell>
              <TableCell sx={{ width: "600px" }}>
                <Typography
                  component="div"
                  sx={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}
                >
                  {formatAutomationScripts(item.automationScripts) || "NA"}
                </Typography>
                {item.automationScripts && (
                  <Box display="flex" gap={2} mt={2}>
                    <Button
                      onClick={() => handleScriptsChange(item)}
                      startIcon={<ModeEditOutlineOutlinedIcon />}
                    >
                      Edit Automation Script
                    </Button>
                  </Box>
                )}
              </TableCell>

              <TableCell>{item.status || "NA"}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {!filteredData.length && <Box m={2}>No data available</Box>}
    </TableContainer>
  );
}

export default TestCaseTable;
