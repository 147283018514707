import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

let Tid;
let Tvalue;

const fetchParallel = async () => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");
  const user = JSON.parse(userDetails);

  const response = await axios.get(
    `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestSuiteSupportsParallelExecution&companyid=${user.companyid}&token=${user.token}&tsuid=${Tid}&value=${Tvalue}`,
  );

  return response.data;
};

const useUpdateParallel = () => {
  const query = useQuery({
    queryKey: ["updateParallel"],
    queryFn: () => {
      return fetchParallel(Tid, Tvalue);
    },
    enabled: false,
    retry: true,
    cacheTime: 90000,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    staleTime: Infinity,
  });

  const refetchWithParams = (id, value) => {
    Tvalue = value;
    Tid = id;
    query.refetch();
  };

  return {
    ...query,
    refetchWithParams,
  };
};

export default useUpdateParallel;
