import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import { fetchLoginDetails } from "./../../API/UserApi";
import loginpageimg from "../../Assets/loginpageimg.svg";
import nogruntblacklogo from "../../Assets/nogruntblacklogo.svg";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { useNotification } from "../../hooks";

const LoginPage = (props) => {
  const notify = useNotification();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [account, setAccount] = useState({
    email: "",
    password: "",
    // company: "",
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handelAccount = (property, event) => {
    setAccount({ ...account, [property]: event.target.value });
  };

  const handelLogin = async () => {
    await fetchLoginDetails({
      pwd: account.password,
      uname: account.email,
      // company: account.company,
    })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "success") {
            localStorage.setItem(
              "userDetail",
              JSON.stringify({
                ...account,
                ...response.data,
                companyid: response.data?.company?.idcompany,
                usertype: response.data?.usertype,
                token: response.data?.randomkey,
              }),
            );
            // dispatch(changeCodeGen(response.data?.company?.logintype === "codegen"));
            localStorage.setItem(
              "colorcode",
              JSON.stringify({
                primaryColor: response.data?.company?.primaryColor,
                secondaryColor: response.data?.company?.secondaryColor,
                tertiaryColor: response.data?.company?.tertiaryColor,
                brandLogo: response.data?.company.brandLogo,
                companyurl: response.data?.company.companyurl,
              }),
            );
            navigate("/new");
          } else {
            notify("Invalid Username or Password!, Please try Again.", "error");
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="bg-white rounded-sm w-full h-full flex flex-col lg:flex-row  justify-between p-9">
      <div className="left  w-full flex flex-col items-start justify-start ">
        <div className="top flex flex-col">
          <img src={nogruntblacklogo} alt="Nogrunt Logo" className="w-[136px] h-[49px]" />
          <a
            href="https://chromewebstore.google.com/detail/nogrunt-qa-automation/neankkcidljlljifcclgcecadeimmolp"
            className="font-semibold text-[24px] underline underline-offset-2 text-[#2550D1] mt-2 urbanist-font ml-4"
          >
            Get The Extension
          </a>
        </div>
        <div className="bottom flex flex-col  mt-20 w-full max-w-[555px]">
          <div>
            <h1 className="font-semibold text-[24px] text-[#151515] leading-10 urbanist-font">
              Welcome Back
            </h1>
            <p className="font-normal text-[18px] leading-7 text-[#8692A6] urbanist-font mb-10">
              Please sign in to your account
            </p>
          </div>

          {/* /Login Form */}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handelLogin();
            }}
          >
            <label className="font-bold text-[14px] leading-5 text-[#151515] urbanist-font mt-10">
              Email Address
            </label>
            <input
              type="email"
              placeholder="Enter your email"
              onChange={(event) => handelAccount("email", event)}
              className="w-full p-4 border-[#151515] border-[1.5px] border-opacity-30 mb-6 mt-2 rounded-[3px] urbanist-font"
            />

            <label className="font-bold text-[14px] leading-5 text-[#151515] urbanist-font mt-10">
              Password
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                onChange={(event) => handelAccount("password", event)}
                className="w-full p-4 border-[#151515] border-[1.5px] border-opacity-30 mt-2 rounded-[3px] urbanist-font"
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute right-4 top-[55%] transform -translate-y-1/2 focus:outline-none"
              >
                {showPassword ? (
                  <VisibilityOffOutlinedIcon className="text-[#8692A6] w-5 h-5" />
                ) : (
                  <RemoveRedEyeOutlinedIcon className="text-[#8692A6] w-5 h-5" />
                )}
              </button>
            </div>

            <div className="flex flex-row items-center justify-between mt-4">
              <button
                type="button"
                onClick={() => navigate("/SignUpPage")}
                className="text-[#2550D1] font-medium text-[16px] leading-5 urbanist-font"
              >
                Don't have an account?
              </button>

              <div className="flex flex-row items-center">
                <button
                  type="button"
                  onClick={() => navigate("/ForgotPasswordPage")}
                  className="text-[#2550D1] font-medium text-[16px] leading-5 urbanist-font"
                >
                  Forgot Password?
                </button>
              </div>
            </div>

            <div className="flex items-center justify-center w-full">
              <button
                type="submit"
                className="w-full py-5 bg-[#0043CE] rounded-[6px] mt-10 text-white font-semibold text-[20px] leading-5 urbanist-font max-w-md"
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="right w-full hidden lg:block">
        <img src={loginpageimg} alt="loginpageimg" />
      </div>
    </div>
  );
};

export default LoginPage;
