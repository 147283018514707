import React, { useState, useEffect } from "react";
import { ChevronDownIcon, PlayIcon } from "@heroicons/react/24/outline";
import ConstructUrl from "./ConstructUrl";
import { API_BACKEND_URL } from "../../../Config/Config";

const TestCaseDropdown = ({
  endpoint,
  onTestCaseSelect,
  baseUrl,
  setEndpointResults,
  setChainTest,
  setActiveTab,
}) => {
  const [testCases, setTestCases] = useState([]);
  const [selectedTestCase, setSelectedTestCase] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isRunningAll, setIsRunningAll] = useState(false);

  useEffect(() => {
    const fetchTestCases = async () => {
      if (!endpoint) return;
      try {
        const response = await fetch(`${API_BACKEND_URL}/testcase?endpoint=${endpoint.id}`);
        const data = await response.json();
        setTestCases(data);
      } catch (error) {
        console.error("Error fetching test cases:", error);
      }
    };

    fetchTestCases();
  }, [endpoint]);

  const executeTestCase = async (testCase) => {
    let url = ConstructUrl(baseUrl, testCase);
    try {
      const response = await fetch(`${API_BACKEND_URL}/test`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          method: testCase.httpMethod,
          url: url.fullUrl,
          headers: {},
          queryParams: {},
          body: url.requestBody,
          apiName: "",
          id: testCase.id,
          requestKeyValuePairs: {},
        }),
      });

      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.error(`Error executing test case ${testCase.id}:`, error);
      return null;
    }
  };

  const handleRunAllTests = async () => {
    setIsRunningAll(true);
    const results = [];

    try {
      for (const testCase of testCases) {
        const result = await executeTestCase(testCase);
        if (result) {
          // Push both the result and the testCase object into the results array
          results.push({ testCase, result });
        } else {
          console.error(`Test case ${testCase.id} failed.`);
        }
      }
      console.log("All test cases executed:", results);
      setEndpointResults(results);
    } catch (error) {
      console.error("Error running all tests:", error);
    } finally {
      setIsRunningAll(false);
      setActiveTab("testResults");
      setChainTest("endpointResults");
    }
  };

  const handleSelect = (testCaseId) => {
    if (testCaseId === "clear") {
      setSelectedTestCase("");
      onTestCaseSelect({ description: "Add Testcase" });
      setIsOpen(false);
      return;
    }

    const selectedCase = testCases.find((testCase) => testCase.id.toString() === testCaseId);
    setSelectedTestCase(testCaseId);
    if (selectedCase) {
      onTestCaseSelect(selectedCase);
    }
    setIsOpen(false);
  };

  return (
    <div className="relative w-[375px] mt-3">
      <div className="flex justify-between items-center mb-2">
        <label className="block text-md font-sans text-postman-gray-medium font-medium">
          TEST CASES
        </label>
        <button
          onClick={handleRunAllTests}
          disabled={isRunningAll || testCases.length === 0}
          className={`flex items-center px-3 py-1 rounded text-sm font-medium ${
            isRunningAll || testCases.length === 0
              ? "bg-gray-200 text-gray-400 cursor-not-allowed"
              : "bg-green-600 text-white hover:bg-green-700"
          }`}
        >
          <PlayIcon className="w-4 h-4 mr-1" />
          {isRunningAll ? "Running..." : `Run All (${testCases.length})`}
        </button>
      </div>

      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full px-4 py-3 text-left bg-white border-[1px] border-gray-400 rounded-sm shadow-sm focus:outline-none flex items-center justify-between"
      >
        <span
          className={`text-sm font-sans ${selectedTestCase ? "text-gray-700" : "text-gray-400"}`}
        >
          {selectedTestCase
            ? testCases.find((tc) => tc.id.toString() === selectedTestCase)?.testCase ||
              "No description"
            : "Add Testcase"}
        </span>

        <ChevronDownIcon
          className={`w-4 h-4 transition-transform ${isOpen ? "transform rotate-180" : ""}`}
        />
      </button>

      {isOpen && (
        <div className="absolute w-full mt-1 bg-white border rounded-md shadow-lg z-10 max-h-60 overflow-auto">
          <ul className="py-1">
            <li
              className="px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
              onClick={() => handleSelect("clear")}
            >
              Add Testcase
            </li>
            {testCases.map((testCase) => (
              <li
                key={testCase.id}
                className="px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                onClick={() => handleSelect(testCase.id.toString())}
              >
                {testCase.testCase || "No description"}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default TestCaseDropdown;
