import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation, useSearchParams } from "react-router-dom";
import signinpageimg from "../../Assets/signinpageimg.svg";
import nogruntblacklogo from "../../Assets/nogruntblacklogo.svg";
import { sendUserInfo } from "./../../API/UserApi";
import { getCompanyName } from "../../API/CreateCollectionAPI";
import { CommonMethods } from "./../../components/common/CommonMethods";
const SignUpPage = () => {
  const [account, setAccount] = useState({
    status: 0,
    password: "",
    email: "",
    firstname: "",
    lastname: "",
    company: "",
    phonenumber: "",
    confirmpassword: "",
    product: "",
    module: "",
    plan: "",
    techstack: "",
    optForPageObject: false,
  });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [companyName, setCompanyName] = useState([]);
  const [showModuleProduct, setShowModuleProduct] = useState(true);
  const location = useLocation();
  const cname = searchParams.get("cname");
  const codegen = searchParams.get("codegen");
  const plan = searchParams.get("plan");
  const techstack = searchParams.get("techstack");
  useEffect(() => {
    if (cname !== null) {
      console.log("cname", cname);
      getCompanyNameFromUrl(cname);
    } else if (codegen) {
      console.log("codegen", codegen);
    }
    //set the plan and tech stack from the url params in the state
    setAccount({ ...account, plan: plan, techstack: techstack });
  }, [cname, codegen, plan, techstack]);

  const getCompanyNameFromUrl = async (id) => {
    await getCompanyName(id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setCompanyName(response.data);
            setShowModuleProduct(false);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handelAccount = (property, event) => {
    setAccount({ ...account, [property]: event.target.value });
  };
  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
  };

  const validatePassword = (password) => {
    return password.match(
      /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{8,16}$/,
    );
  };

  const validateName = (name) => {
    return name.match(/^[^<>]*$/);
  };
  const validatePhoneNumber = (phonenumber) => {
    return phonenumber.match(/^(\d{3})[- ]?(\d{3})[- ]?(\d{4})$/);
  };

  const createUser = async (account) => {
    if (
      account.password === "" ||
      account.email === "" ||
      account.firstname === "" ||
      account.lastname === "" ||
      account.phonenumber === "" ||
      account.confirmpassword === ""
    ) {
      console.log("field cant be empty");
    } else if (
      cname === null &&
      codegen === null &&
      (account.company === "" || account.product === "" || account.module === "")
    ) {
      console.log("field cant be empty");
    } else {
      if (account.confirmpassword !== account.password) {
        console.log("confirm password and password should be same");
      } else if (!validateName(account.firstname)) {
        console.log("Enter a valid Firstname");
      } else if (!validateName(account.lastname)) {
        console.log("Enter a valid Lastname");
      } else if (!validateEmail(account.email)) {
        console.log("Enter a valid Email");
      } else if (!validatePassword(account.password)) {
        console.log("Enter a strong password");
      } else {
        await sendUserInfo({
          status: account.status,
          password: account.password,
          email: account.email,
          firstname: account.firstname,
          lastname: account.lastname,
          company: account.company,
          phonenumber: account.phonenumber,
          product: account.product,
          module: account.module,
        })
          .then((response) => {
            if (response.status === 200) {
              let user = {
                email: account.email,
                firstname: account.firstname,
                lastname: account.lastname,
                company: account.company,
                phonenumber: account.phonenumber,
              };

              // storing input from register-form
              let json = JSON.stringify(user);
              localStorage.setItem("userDetail", json);
              console.log("USER ADDED");
              setAccount({
                status: 0,
                password: "",
                email: "",
                firstname: "",
                lastname: "",
                company: "",
                phonenumber: "",
                confirmpassword: "",
                product: "",
                module: "",
              });
              navigate("/LoginPage");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  const createUserFromurl = async (account) => {
    if (
      account.password === "" ||
      account.email === "" ||
      account.firstname === "" ||
      account.lastname === "" ||
      // account.company === "" ||
      account.phonenumber === "" ||
      account.confirmpassword === ""
      // account.product === "" ||
      // account.module === ""
    ) {
      console.log("field cant be emptys");
    } else {
      if (account.confirmpassword !== account.password) {
        console.log("confirm password and password should be same");
      } else if (!validateName(account.firstname)) {
        console.log("Enter a valid Firstname");
      } else if (!validateName(account.lastname)) {
        console.log("Enter a valid Lastname");
      } else if (!validateEmail(account.email)) {
        console.log("Enter a valid Email");
      } else if (!validatePassword(account.password)) {
        console.log("Enter a strong password");
      } else {
        await sendUserInfo({
          status: account.status,
          password: account.password,
          email: account.email,
          firstname: account.firstname,
          lastname: account.lastname,
          company: companyName.companyname,
          phonenumber: account.phonenumber,
          // product: account.product,
          // module: account.module,
        })
          .then((response) => {
            if (response.status === 200) {
              let user = {
                email: account.email,
                firstname: account.firstname,
                lastname: account.lastname,
                company: companyName.companyname,
                phonenumber: account.phonenumber,
              };

              // storing input from register-form
              let json = JSON.stringify(user);
              localStorage.setItem("userDetail", json);
              console.log("USER ADDED");
              setAccount({
                status: 0,
                password: "",
                email: "",
                firstname: "",
                lastname: "",
                company: "",
                phonenumber: "",
                confirmpassword: "",
                // product: "",
                // module: "",
              });
              navigate("/LoginPage");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  const createCodegenUser = async (account) => {
    if (
      account.password === "" ||
      account.email === "" ||
      account.firstname === "" ||
      account.lastname === "" ||
      account.techstack === "" ||
      account.plan === "" ||
      account.company === "" ||
      account.phonenumber === "" ||
      account.confirmpassword === ""
    ) {
      console.log("field cant be emptys");
    } else {
      if (account.confirmpassword !== account.password) {
        console.log("confirm password and password should be same");
      } else if (!validateName(account.firstname)) {
        console.log("Enter a valid Firstname");
      } else if (!validateName(account.lastname)) {
        console.log("Enter a valid Lastname");
      } else if (!validateEmail(account.email)) {
        console.log("Enter a valid Email");
      } else if (!validatePassword(account.password)) {
        console.log("Enter a strong password");
      } else {
        await sendUserInfo({
          status: account.status,
          password: account.password,
          email: account.email,
          firstname: account.firstname,
          lastname: account.lastname,
          company: account.company,
          phonenumber: account.phonenumber,
          gentype: "code",
          codegen: codegen,
          techstack: account.techstack,
          plan: account.plan,
          optForPageObject: account.optForPageObject,
        })
          .then((response) => {
            if (response.status === 200) {
              let user = {
                email: account.email,
                firstname: account.firstname,
                lastname: account.lastname,
                company: account.company,
                phonenumber: account.phonenumber,
                gentype: "code",
                codegen: codegen,
                techstack: account.techstack,
                plan: account.plan,
                optForPageObject: account.optForPageObject,
              };

              // storing input from register-form
              let json = JSON.stringify(user);
              localStorage.setItem("userDetail", json);
              localStorage.setItem("payment_session_id", response.data.paymentSessionId);
              localStorage.removeItem("payment_completed");
              console.log("USER ADDED");
              setAccount({
                status: 0,
                password: "",
                email: "",
                firstname: "",
                lastname: "",
                company: "",
                phonenumber: "",
                confirmpassword: "",
                techstack: "",
                plan: "",
              });
              // navigate("/LoginPage");
              navigate("/payment");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };
  return (
    <div className="bg-white rounded-sm w-full h-full flex flex-col lg:flex-row justify-between p-9">
      <div className="left w-full  flex flex-col items-start justify-start">
        <div className="top flex flex-col w-full max-w-[700px]">
          <img src={nogruntblacklogo} alt="Nogrunt Logo" className="w-[136px] h-[49px]" />
          <div className="flex flex-row items-center justify-between w-full">
            <a
              href="https://chromewebstore.google.com/detail/nogrunt-qa-automation/neankkcidljlljifcclgcecadeimmolp"
              className="font-semibold text-[24px] underline underline-offset-2 text-[#2550D1] mt-2 urbanist-font"
            >
              Get The Extension
            </a>

            <a
              href="https://www.loom.com/share/c8941d2c1d4b428dabcb30374219124f"
              className="font-semibold mt-2 text-[24px] underline underline-offset-2 text-[#2550D1] urbanist-font"
            >
              See Nogrunt in Action
            </a>
          </div>
        </div>

        <div className="bottom flex flex-col mt-10 w-full max-w-[800px]">
          <div className="mb-6">
            <h1 className="font-semibold text-[24px] text-[#151515] leading-10 urbanist-font">
              Create Your Account
            </h1>
            <p className="font-normal text-[18px] leading-7 text-[#8692A6] urbanist-font">
              Kindly fill your details to signup
            </p>
          </div>

          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label className="font-bold text-[14px] text-[#151515] urbanist-font">
                First Name
              </label>
              <input
                type="text"
                placeholder="Enter first name"
                value={account.firstname}
                onChange={(event) => handelAccount("firstname", event)}
                className="w-full p-4 border-[#151515] border-[1.5px] border-opacity-30 mt-2 rounded-[3px] urbanist-font"
                error={!validateName(account.firstname) && account.firstname.length > 0}
              />
              {!validateName(account.firstname) && account.firstname.length > 0 && (
                <p className="text-red-500 text-sm mt-1">Invalid First Name</p>
              )}
            </div>
            <div>
              <label className="font-bold text-[14px] text-[#151515] urbanist-font">
                Last Name
              </label>
              <input
                type="text"
                placeholder="Enter last name"
                value={account.lastname}
                onChange={(event) => handelAccount("lastname", event)}
                className="w-full p-4 border-[#151515] border-[1.5px] border-opacity-30 mt-2 rounded-[3px] urbanist-font"
                error={!validateName(account.lastname) && account.lastname.length > 0}
              />
              {!validateName(account.lastname) && account.lastname.length > 0 && (
                <p className="text-red-500 text-sm mt-1">Invalid Last Name</p>
              )}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label className="font-bold text-[14px] text-[#151515] urbanist-font">
                Company Name
              </label>
              {cname !== null ? (
                <input
                  type="text"
                  value={companyName.companyname}
                  readOnly
                  className="w-full p-4 border-[#151515] border-[1.5px] border-opacity-30 mt-2 rounded-[3px] urbanist-font"
                />
              ) : (
                <input
                  type="text"
                  placeholder="Enter company name"
                  value={account.company}
                  onChange={(event) => handelAccount("company", event)}
                  className="w-full p-4 border-[#151515] border-[1.5px] border-opacity-30 mt-2 rounded-[3px] urbanist-font"
                />
              )}
            </div>
            <div>
              <label className="font-bold text-[14px] text-[#151515] urbanist-font">
                Phone Number
              </label>
              <input
                type="text"
                placeholder="Enter phone number"
                value={account.phonenumber}
                onChange={(event) => handelAccount("phonenumber", event)}
                className="w-full p-4 border-[#151515] border-[1.5px] border-opacity-30 mt-2 rounded-[3px] urbanist-font"
                error={!validatePhoneNumber(account.phonenumber) && account.phonenumber.length > 0}
              />
              {!validatePhoneNumber(account.phonenumber) && account.phonenumber.length > 0 && (
                <p className="text-red-500 text-sm mt-1">Invalid Phone Number</p>
              )}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label className="font-bold text-[14px] text-[#151515] urbanist-font">
                Email Address
              </label>
              <input
                type="email"
                placeholder="Enter your email"
                value={account.email}
                onChange={(event) => handelAccount("email", event)}
                className="w-full p-4 border-[#151515] border-[1.5px] border-opacity-30 mt-2 rounded-[3px] urbanist-font"
                error={!validateEmail(account.email) && account.email.length > 0}
              />
              {!validateEmail(account.email) && account.email.length > 0 && (
                <p className="text-red-500 text-sm mt-1">Invalid Email</p>
              )}
            </div>
            {codegen !== null && (
              <div>
                <label className="font-bold text-[14px] text-[#151515] urbanist-font">
                  Pricing Plan
                </label>
                <input
                  type="text"
                  value={plan}
                  readOnly
                  className="w-full p-4 border-[#151515] border-[1.5px] border-opacity-30 mt-2 rounded-[3px] urbanist-font"
                />
              </div>
            )}
          </div>

          {codegen !== null && (
            <div className="grid grid-cols-2 gap-4 mb-4 items-center justify-items-stretch">
              <div className="w-full max-w-xs">
                <label className="font-bold text-[14px] text-[#151515] urbanist-font">
                  Tech Stack
                </label>
                <input
                  type="text"
                  value={techstack}
                  readOnly
                  className="w-full p-4 border-[#151515] border-[1.5px] border-opacity-30 mt-2 rounded-[3px] urbanist-font"
                />
              </div>
              <div className="w-full max-w-xs">
                <label className="font-bold text-[14px] text-[#151515] urbanist-font">
                  Opt for Page Object Model
                </label>
                <div className="flex space-x-4 mt-2">
                  <label className="flex items-center space-x-2">
                    <input
                      type="radio"
                      name="optForPageObject"
                      value={true}
                      checked={account.optForPageObject === true}
                      onChange={() => setAccount({ ...account, optForPageObject: true })}
                      className="form-radio"
                    />
                    <span>Yes</span>
                  </label>
                  <label className="flex items-center space-x-2">
                    <input
                      type="radio"
                      name="optForPageObject"
                      value={false}
                      checked={account.optForPageObject === false}
                      onChange={() => setAccount({ ...account, optForPageObject: false })}
                      className="form-radio"
                    />
                    <span>No</span>
                  </label>
                </div>
              </div>
            </div>
          )}

          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label className="font-bold text-[14px] text-[#151515] urbanist-font">Password</label>
              <input
                type="password"
                placeholder="Enter password"
                value={account.password}
                onChange={(event) => handelAccount("password", event)}
                className="w-full p-4 border-[#151515] border-[1.5px] border-opacity-30 mt-2 rounded-[3px] urbanist-font"
                error={!validatePassword(account.password) && account.password.length > 0}
              />
              {!validatePassword(account.password) && account.password.length > 0 && (
                <p className="text-red-500 text-sm mt-1">Invalid Password</p>
              )}
              <p className="text-[#8692A6] text-sm mt-1">
                - Password should contain 1 Uppercase, 1 Lowercase, 1 Digit, 1 Special Character and
                8 to 16 Characters long
              </p>
            </div>
            <div>
              <label className="font-bold text-[14px] text-[#151515] urbanist-font">
                Confirm Password
              </label>
              <input
                type="password"
                placeholder="Confirm password"
                value={account.confirmpassword}
                onChange={(event) => handelAccount("confirmpassword", event)}
                className="w-full p-4 border-[#151515] border-[1.5px] border-opacity-30 mt-2 rounded-[3px] urbanist-font"
              />
              {account.password !== account.confirmpassword &&
                account.confirmpassword.length > 0 && (
                  <p className="text-red-500 text-sm mt-1">Passwords do not match</p>
                )}
            </div>
          </div>

          {cname === null && codegen === null && (
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <label className="font-bold text-[14px] text-[#151515] urbanist-font">
                  Product Name
                </label>
                <input
                  type="text"
                  placeholder="Enter product name"
                  value={account.product}
                  onChange={(event) => handelAccount("product", event)}
                  className="w-full p-4 border-[#151515] border-[1.5px] border-opacity-30 mt-2 rounded-[3px] urbanist-font"
                />
              </div>
              <div>
                <label className="font-bold text-[14px] text-[#151515] urbanist-font">
                  Module Name
                </label>
                <input
                  type="text"
                  placeholder="Enter module name"
                  value={account.module}
                  onChange={(event) => handelAccount("module", event)}
                  className="w-full p-4 border-[#151515] border-[1.5px] border-opacity-30 mt-2 rounded-[3px] urbanist-font"
                />
              </div>
            </div>
          )}

          <div className="flex items-center mb-4">
            <input
              type="checkbox"
              required
              checked={account.terms}
              onChange={() => {
                setAccount({ ...account, terms: !account.terms });
              }}
              className="mr-2"
            />
            <label className="text-[#696F79] font-medium text-[16px] leading-5 urbanist-font">
              I agree to the terms & conditions
            </label>
          </div>

          <div className="w-full flex items-center justify-center">
            <button
              onClick={() => {
                if (codegen === null) {
                  createUser(account);
                } else {
                  if (cname === undefined) {
                    createUserFromurl(account);
                  } else {
                    console.log("Create CodeGen User", account);
                    createCodegenUser(account);
                  }
                }
              }}
              disabled={!account.terms}
              className={`w-full max-w-xl py-5 rounded-[6px] mt-6 text-white font-semibold text-[20px] leading-5 urbanist-font
          ${!account.terms ? "bg-gray-400 cursor-not-allowed" : "bg-[#0043CE]"}`}
            >
              Sign Up
            </button>
          </div>

          <button
            onClick={() => navigate("/LoginPage")}
            className="text-[#2550D1] font-medium text-[16px] leading-5 urbanist-font mt-4"
          >
            Already have an account? Sign In
          </button>
        </div>
      </div>
      <div className="right hidden lg:block w-full h-screen">
        <div className="flex w-full h-full relative rounded-lg overflow-hidden">
          <img src={signinpageimg} alt="Sign In Page" className="w-full h-full object-contain " />
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
