import React, { useState, useEffect } from "react";
import { Box, styled } from "@mui/material";
import { useSelector } from "react-redux";
import { useStepImgDiffQuery } from "../../../useQuery";

const StyledBox = styled(Box)`
  position: absolute;
  padding: 6px 12px;
  border-radius: 25px;
  background: rgba(255, 255, 255, 1);
  backdrop-filter: blur(2px);
  min-width: 150px;
  font-size: 12px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #444141;
  letter-spacing: 0.9px;
  bottom: 10px;
`;

const ImageContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  position: relative;
  height: 100%;
  background-color: #f5f5f5;
  overflow: hidden;
`;

const StyledImage = styled("img")`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  width: auto;
  height: auto;
`;

const Screenshot = ({ fromExcReports, currentEditItem, fromImageDiff }) => {
  const tsrData = useSelector((state) => state.authoringSlice.tsrData);
  const currentEditItemIndex = useSelector((state) => state.authoringSlice.editItemIndex);
  const imgeDiffTcid = useSelector((state) => state.executionSlice.selectedTestCase);

  const [isBeforeImageLoaded, setIsBeforeImageLoaded] = useState(false);
  const [beforeImageSrc, setBeforeImageSrc] = useState(
    tsrData[currentEditItemIndex]?.screenshot1 || "https://via.placeholder.com/900?text=No+Image",
  );

  const [isAfterImageLoaded, setIsAfterImageLoaded] = useState(false);
  const [afterImageSrc, setAfterImageSrc] = useState(
    tsrData[currentEditItemIndex]?.Failure_Screenshot_Location ||
      "https://via.placeholder.com/900?text=No+Image",
  );

  const { refetchWithParams: getImgDiff, data } = useStepImgDiffQuery();

  useEffect(() => {
    if (!fromImageDiff) return;

    getImgDiff({
      tsid: currentEditItem.Test_Step,
      stepNumber: currentEditItem.Step_Number,
      tcid: imgeDiffTcid,
      tcrid: currentEditItem.Test_Case_Results_Id,
    });
  }, [fromImageDiff]);

  const handleBeforeImageLoad = () => setIsBeforeImageLoaded(true);
  const handleBeforeImageError = (e) => {
    e.preventDefault();
    setIsBeforeImageLoaded(false);
    setBeforeImageSrc("https://via.placeholder.com/900?text=No+Image");
  };

  const handleAfterImageLoad = () => setIsAfterImageLoaded(true);
  const handleAfterImageError = (e) => {
    e.preventDefault();
    setIsAfterImageLoaded(false);
    setAfterImageSrc("https://via.placeholder.com/900?text=No+Image");
  };

  useEffect(() => {
    setBeforeImageSrc(
      tsrData[currentEditItemIndex]?.screenshot1 || "https://via.placeholder.com/900?text=No+Image",
    );
    setAfterImageSrc(
      tsrData[currentEditItemIndex]?.Failure_Screenshot_Location ||
        "https://via.placeholder.com/900?text=No+Image",
    );
  }, [tsrData, currentEditItemIndex]);

  return (
    <Box
      border="2px solid rgba(0, 0, 0, 0.30)"
      borderRadius="12px"
      height={500}
      display="flex"
      overflow="hidden"
    >
      <ImageContainer borderRight="2px solid blue">
        <StyledImage
          src={
            fromImageDiff
              ? data?.image1 || "https://via.placeholder.com/900?text=No+Image"
              : fromExcReports
                ? currentEditItem.screenshot1 || "https://via.placeholder.com/900?text=No+Image"
                : beforeImageSrc
          }
          alt="Before"
          onLoad={handleBeforeImageLoad}
          onError={handleBeforeImageError}
        />
        <StyledBox>{fromImageDiff ? " Latest Image " : "Element Selected"}</StyledBox>
      </ImageContainer>

      <ImageContainer>
        <StyledImage
          src={
            fromImageDiff
              ? data?.image2 || "https://via.placeholder.com/900?text=No+Image"
              : fromExcReports
                ? currentEditItem.Failure_Screenshot_Location ||
                  "https://via.placeholder.com/900?text=No+Image"
                : afterImageSrc
          }
          alt="After"
          onLoad={handleAfterImageLoad}
          onError={handleAfterImageError}
        />
        <StyledBox>{fromImageDiff ? "Previous Image" : "Action Completed"}</StyledBox>
      </ImageContainer>
    </Box>
  );
};

export default Screenshot;
