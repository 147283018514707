import React from "react";
import {
  ChevronDownIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";

const TestResults = ({ testResults }) => {
  if (!testResults || Object.keys(testResults).length === 0) {
    return (
      <div className="text-postman-gray-medium p-4">No results to display.</div>
    );
  }

  return (
    <div className="bg-white text-postman-gray-dark w-full min-h-full">
      {/* Header */}
      <div className="bg-postman-gray-light px-4 py-3 border-b border-gray-200">
        <h2 className="text-sm font-medium">Test Results</h2>
        <p className="text-xs text-postman-gray-medium mt-1">
          Generated on: {new Date().toLocaleString()}
        </p>
      </div>

      {/* Results */}
      <div className="divide-y divide-gray-200">
        {Object.entries(testResults.testResults).map(([key, value]) => {
          const isPassed = value.startsWith("PASS");
          const [, ...description] = value.split(" ");

          return (
            <details key={key} className="group">
              <summary className="flex items-center justify-between p-3 cursor-pointer list-none hover:bg-postman-gray-light select-none">
                <div className="flex items-center gap-2">
                  {/* <div className="text-xs font-medium px-2 py-1 rounded bg-postman-orange text-white">
                    Test
                  </div> */}
                  <div className="flex items-center gap-1.5">
                    {isPassed ? (
                      <CheckCircleIcon className="h-4 w-4 text-postman-green" />
                    ) : (
                      <XCircleIcon className="h-4 w-4 text-postman-red" />
                    )}
                    <span
                      className={`text-xs font-medium
                        ${isPassed ? "text-postman-green" : "text-postman-red"
                        }`}
                    >
                      {isPassed ? "PASSED" : "FAILED"}
                    </span>
                  </div>

                  <span className="text-sm text-postman-gray-dark font-medium">
                    {key}
                  </span>
                  {/* <span
                    className={`inline-flex items-center px-2 py-1 rounded text-xs font-medium
                        ${isPassed
                        ? "bg-green-100 text-postman-green"
                        : "bg-red-100 text-postman-red"
                      }`}
                  >
                    Status: {status}
                  </span> */}

                  <div className="space-y-2 text-sm">
                    <div>
                      <div className="bg-white p-2 rounded text-xs overflow-x-auto border border-gray-200">
                        {description.join(" ")}
                      </div>
                    </div>
                  </div>



                </div>
                <ChevronDownIcon className="h-4 w-4 text-postman-gray-medium transition-transform group-open:rotate-180" />
              </summary>

              <div className="border-t border-gray-200 bg-postman-gray-light">
                <div className="p-3 border-b border-gray-200 last:border-0">
                  <div className="flex items-center justify-between mb-2">
                    <div className="flex items-center gap-3">
                    </div>
                    {/* <div className="flex items-center gap-1.5">
                      {isPassed ? (
                        <CheckCircleIcon className="h-4 w-4 text-postman-green" />
                      ) : (
                        <XCircleIcon className="h-4 w-4 text-postman-red" />
                      )}
                      <span
                        className={`text-xs font-medium
                        ${isPassed ? "text-postman-green" : "text-postman-red"
                          }`}
                      >
                        {isPassed ? "PASSED" : "FAILED"}
                      </span>
                    </div> */}
                  </div>

                  {/* <div className="space-y-2 text-sm">
                    <div>
                      <div className="text-xs text-postman-gray-medium mb-1">
                        Description:
                      </div>
                      <div className="bg-white p-2 rounded text-xs overflow-x-auto border border-gray-200">
                        {description.join(" ")}
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </details>
          );
        })}
      </div>

      {/* Response body */}
      <div className="bg-postman-gray-light px-4 py-3 border-b border-gray-200">
        <h3 className="text-sm font-medium">Response Body</h3>
        <div className="text-xs text-postman-gray-medium mt-1">
          <pre>{JSON.stringify(testResults.originalResponse, null, 2)}</pre>
        </div>
      </div>

    </div>
  );
};

export default TestResults;
