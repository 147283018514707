import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Typography, CircularProgress, Container, Card, CardContent } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import axios from "axios";
import { API_BASE_PATH, API_BASE_RELATIVE_PATH } from "../../Config/Config";

const PaymentVerification = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [verificationStatus, setVerificationStatus] = useState("loading");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const orderId = queryParams.get("order_id");

    if (!orderId) {
      setVerificationStatus("error");
      setErrorMessage("No order ID provided");
      return;
    }

    const verifyPayment = async () => {
      try {
        const apiUrl = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}`;

        const config = {
          params: {
            action: "verifyorder",
            ngorderid: orderId,
          },
          headers: {
            "Content-Type": "application/json",
          },
        };

        const response = await axios.get(apiUrl, config);

        if (response.data.status === "SUCCESS") {
          localStorage.removeItem("payment_session_id");

          setVerificationStatus("success");
          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else {
          setVerificationStatus("error");
          setErrorMessage("Payment verification failed: Invalid order details");
        }
      } catch (error) {
        console.error("Payment verification API error:", error);
        setVerificationStatus("error");
        if (error.response) {
          setErrorMessage(`Verification failed: ${error.response.data.message || "Server error"}`);
        } else if (error.request) {
          setErrorMessage("No response from verification server");
        } else {
          setErrorMessage("Error in payment verification process");
        }
      }
    };

    verifyPayment();
  }, [location.search, navigate]);

  const renderContent = () => {
    switch (verificationStatus) {
      case "loading":
        return (
          <Container maxWidth="sm">
            <Card>
              <CardContent>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <CircularProgress />
                  <Typography variant="h6" style={{ marginTop: 16 }}>
                    Verifying Payment...
                  </Typography>
                  <Typography variant="body2">Please do not close this page</Typography>
                </Box>
              </CardContent>
            </Card>
          </Container>
        );

      case "success":
        return (
          <Container maxWidth="sm">
            <Card>
              <CardContent>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <CheckCircleOutlineIcon color="success" style={{ fontSize: 80 }} />
                  <Typography variant="h6" color="success.main">
                    Payment Successful
                  </Typography>
                  <Typography variant="body2">Redirecting to dashboard...</Typography>
                </Box>
              </CardContent>
            </Card>
          </Container>
        );

      case "error":
        return (
          <Container maxWidth="sm">
            <Card>
              <CardContent>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <ErrorOutlineIcon color="error" style={{ fontSize: 80 }} />
                  <Typography variant="h6" color="error.main">
                    Payment Verification Failed
                  </Typography>
                  <Typography variant="body2" color="error">
                    {errorMessage}
                  </Typography>
                  <Typography variant="body2" style={{ marginTop: 16 }}>
                    Please contact support or try again
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Container>
        );

      default:
        return null;
    }
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      {renderContent()}
    </Box>
  );
};

export default PaymentVerification;
