import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  static getDerivedStateFromError(error) {
    if (process.env.NODE_ENV === "development") {
      console.clear();
    }
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // Prevent the error from propagating
    error.preventDefault?.();

    if (process.env.NODE_ENV === "development") {
      // Hide any existing error overlays
      const overlay = document.querySelector('iframe[style*="position: fixed"]');
      if (overlay) {
        overlay.style.display = "none";
      }
    }

    this.setState({
      error,
      errorInfo,
      hasError: true,
    });
  }

  handleClearLocalStorage = () => {
    localStorage.clear(); // Clear all data in localStorage
    window.location.reload(); // Reload the application
  };

  render() {
    if (this.state.hasError) {
      return (
        <div
          key="error-boundary-fallback"
          className="fixed inset-0 flex items-center justify-center bg-gray-50 z-[9999]"
          style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0 }}
        >
          <div className="p-6 max-w-lg mx-auto bg-white rounded-lg shadow-xl m-4">
            <h2 className="text-2xl font-bold text-red-600 mb-4">An Error Occurred</h2>
            <div className="mb-4 text-gray-700">
              Something went wrong in this part of the application.
            </div>
            {process.env.NODE_ENV === "development" && (
              <div className="bg-gray-100 p-4 rounded mb-4">
                <p className="font-mono text-sm break-all whitespace-pre-wrap">
                  {this.state.error?.message || this.state.error?.toString()}
                </p>
                {this.state.errorInfo && (
                  <pre className="mt-2 text-xs overflow-auto max-h-48">
                    {this.state.errorInfo.componentStack}
                  </pre>
                )}
              </div>
            )}
            <div className="flex gap-2">
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                onClick={() => window.location.reload()}
              >
                Reload Application
              </button>
              <button
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                onClick={this.handleClearLocalStorage}
              >
                Clear Data & Reload
              </button>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
