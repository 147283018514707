import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton } from "@mui/material";
import { ColorButton } from "./../common/common.Component";
import { useEffect, useState } from "react";
// import {editStepInrecording} from "./../../API/RecordingTableAPI";
import {
  editStepInrecording,
  fetchRecordingInResult,
  addStepInrecording,
  fetchDiff,
  fetchImageDiff,
} from "./../../API/RecordingTableAPI";
import { API_BASE_PATH, API_BASE_RELATIVE_PATH } from "./../../Config/Config";
import { CommonMethods } from "./../common/CommonMethods";
import { useNavigate } from "react-router-dom";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { InputLabel, FormControl } from "@mui/material";
import "./TCTable.style.scss";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ModalExpand from "./../RecordingTable/ModalExpand";
import { FormControlLabel, Switch } from "@mui/material";
import MyCarousel from "./../RecordingTable/MyCarousel";
import { colorExport } from "./../common/common.Component";
import axios from "axios";
import { validateDetailsBySearch } from "../../API/ValidateAPI";

import {
  modalExecute,
  AnalysisExecute,
  postEnviromentSettingtoTestSuite,
} from "./../../API/CreateCollectionAPI";
import ElementLocate from "../RecordingTable/ElementLocate";
import { ReactComponent as CTAIcon } from "./../../Assets/Vector.svg";

const colors = colorExport();
const userDetails = localStorage.getItem("userDetail");
const temp = JSON.parse(userDetails);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const ScreenshotModal = ({
  valuesSavedResult,
  checkModuleEnable,
  TestDataDetails,
  setTestDataDetails,
  handleClose,
  valuesSaved,
  oneTimeTestCaseObject,
  indexValue,
  logFile,
  tcid,
  tcname,
  testStepIdResult,
  productId,
}) => {
  const [indexValueChange, setIndexValueChange] = useState(indexValue);
  const [editValue, setEditValue] = useState(false);
  const [imageDiffValue, setImageDiffValue] = useState(false);
  const [analysisDiffValue, setAnalysisDiffValue] = useState(false);
  const [newTestStep, setNewTestStep] = useState({});
  const [valuesSavedRecording, setValuesSavedRecording] = useState([]);
  const [modalExpandValue, setModalExpandValue] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [toggleOn, setToggleOn] = useState(false);
  const [chooseData, setChooseData] = useState("");
  const [failedImage, setFailedImage] = useState("");
  const [passedImage, setPassedImage] = useState("");
  const [jsonData, setJsonData] = useState("");
  const [state, setState] = useState({
    stepnums: [],
  });
  const navigate = useNavigate();

  const editValuePositive = () => {
    setEditValue(true);
  };

  const editValueNegative = () => {
    setEditValue(false);
  };

  const imageDiffValuePositive = () => {
    setImageDiffValue(true);
  };

  const imageDiffValueNegative = () => {
    setImageDiffValue(false);
  };

  const analysisDiffValuePositive = () => {
    setAnalysisDiffValue(true);
  };

  const analysisDiffValueNegative = () => {
    setAnalysisDiffValue(false);
  };

  const handleToggle = () => {
    setToggleOn(!toggleOn);
  };

  useEffect(() => {
    setIndexValueChange(indexValue);
  }, [indexValue]);

  useEffect(() => {
    if (indexValueChange !== null) {
      getRecording(valuesSaved[indexValueChange].Test_Case_Results_Id);
    }
  }, []);

  useEffect(() => {
    if (valuesSavedRecording !== undefined) {
      setNewTestStep(valuesSavedRecording[indexValueChange]);
    }
  }, [indexValueChange, valuesSavedRecording]);

  const incrementCount = () => {
    if (editValue === true) {
      setNewTestStep(valuesSavedRecording[indexValueChange + 1]);
      if (indexValueChange < valuesSavedRecording.length - 1) {
        setIndexValueChange(indexValueChange + 1);
      }
    } else {
      if (indexValueChange < valuesSaved.length - 1) {
        setIndexValueChange(indexValueChange + 1);
      }
    }
  };

  const decrementCount = () => {
    if (editValue === true) {
      setNewTestStep(valuesSavedRecording[indexValueChange - 1]);
      if (indexValueChange < valuesSavedRecording.length) {
        setIndexValueChange(indexValueChange - 1);
      }
    } else {
      if (indexValueChange > 0) {
        setIndexValueChange(indexValueChange - 1);
      }
    }
  };
  const getRecording = async (api_input) => {
    await fetchRecordingInResult(api_input)
      .then((response) => {
        if (response && response.data) {
          if (response.status === 200) {
            if (response.data.message === "fail") {
              CommonMethods(navigate);
            }
            // setloader(false);
            else {
              setState((prev) => {
                return {
                  ...prev,
                  stepnums: Object.values(response.data[0].stepnums),
                  // resolutionDetails:response.data.resolutionDetails,
                };
              });
              setValuesSavedRecording(Object.values(response.data[0].data));
            }
          }
        } else {
          // Handle the case where response is null or undefined
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const aexecute = async (api_input) => {
    await AnalysisExecute(api_input)
      .then((response) => {
        if (response && response.data) {
          if (response.status === 200) {
            if (response.data.message === "fail") {
              CommonMethods(navigate);
            } else {
              navigate("/dashboard/app", { state: { isExecuteClick: true } });
            }
          }
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const getDiff = async (api_input, sn, tcid) => {
    await fetchDiff(api_input, sn, tcid)
      .then((response) => {
        if (response && response.data) {
          if (response.status === 200) {
            if (response.data.message === "fail") {
              CommonMethods(navigate);
            }
            // setloader(false);
            else {
              setPassedImage(response.data.image1);
              setFailedImage(response.data.image2);
              setJsonData(
                response.data.data.map(
                  (item) =>
                    `Key: ${item.errorkey}\nError: ${item.errorseverity}\nOld Value: ${item.oldValue}\nCurrent Value: ${item.latestvalue}`,
                ),
              );
            }
          }
        } else {
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const getImageDiff = async (api_input, sn, tcid, tcrid) => {
    await fetchImageDiff(api_input, sn, tcid, tcrid)
      .then((response) => {
        if (response && response.data) {
          if (response.status === 200) {
            if (response.data.message === "fail") {
              CommonMethods(navigate);
            }
            // setloader(false);
            else {
              setPassedImage(response.data.image1);
              setFailedImage(response.data.image2);
              setJsonData(
                response.data.data.map(
                  (item) =>
                    `Key: ${item.errorkey}\nError: ${item.errorseverity}\nOld Value: ${item.oldValue}\nCurrent Value: ${item.latestvalue}`,
                ),
              );
            }
          }
        } else {
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const KeywordData = [
    { value: "Assertion", name: "Assertion" },
    { value: "Button", name: "Button" },
    { value: "Check Box", name: "Check Box" },
    { value: "DropDown", name: "DropDown" },
    { value: "EditBox", name: "EditBox" },
    { value: "File Upload", name: "File Upload" },
    { value: "Link", name: "Link" },
    { value: "Radio Button", name: "Radio Button" },
    { value: "SVGElement", name: "SVGElement" },
    { value: "Tab", name: "Tab" },
    { value: "TextArea", name: "TextArea" },
    { value: "Send", name: "Send" },
  ];

  const ActionData = [
    { value: "validatePartial", name: "Assert" },
    { value: "ArrowUp", name: "Arrow Up" },
    { value: "ArrowDown", name: "Arrow Down" },
    { value: "Button", name: "Button" },
    { value: "Change", name: "Change" },
    { value: "Checkbox", name: "Check Box" },
    { value: "Click", name: "Click" },
    { value: "DblClick", name: "Double Click" },
    { value: "DynamicText", name: "Dynamic Text" },
    { value: "Enter", name: "Enter" },
    { value: "FileUpload", name: "File Upload" },
    { value: "NewTab", name: "New Tab" },
    { value: "Radio", name: "Radio" },
    { value: "Scroll", name: "Scroll To Element" },
    { value: "Select", name: "Select" },
    { value: "Submit", name: "Submit" },
    { value: "Email", name: "Email" },
    // { value: "ValidateImage", name: "Validate Image" },
    // { value: "ValidateColor", name: "Validate Color" },
  ];

  const sendAddStep = async (api_input) => {
    await addStepInrecording(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sendEditStep = async (api_input) => {
    await editStepInrecording(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleButtonClick = () => {
    const newWindow = window.open(
      `/dashboard/ResultStepPage/${valuesSaved[indexValueChange]?.testStepResultId}`,
      "_blank",
    );
  };

  const numRows = Math.ceil(jsonData.length / 6);

  const rows = Array.from({ length: numRows }).map((_, rowIndex) => (
    <div key={rowIndex} style={{ display: "flex", flexWrap: "wrap", marginBottom: "10px" }}>
      {jsonData.slice(rowIndex * 6, (rowIndex + 1) * 6).map((textBlock, index) => (
        <textarea
          key={`${rowIndex}-${index}`} // Unique key for each textarea
          value={textBlock}
          disabled
          style={{
            marginRight: "10px",
            flex: "1 1 calc(16.666% - 10px)",
            marginBottom: "10px",
            height: "75px", // Automatically adjust the height
            resize: "none", // Prevent resizing
          }}
        />
      ))}
    </div>
  ));

  return (
    <>
      <BootstrapDialog
        onClose={() => {
          handleClose();
          editValueNegative();
        }}
        aria-labelledby="customized-dialog"
        open={true}
        sx={{
          marginTop: 5,
          width: 10 / 10,
          height: 9 / 10,
        }}
        className="custom-dialog"
      >
        <div className="alinging-textbox-two">
          <BootstrapDialogTitle
            id="customized-dialog"
            onClose={() => {
              handleClose();
              editValueNegative();
            }}
            sx={{
              width: 10 / 10,
            }}
          >
            <span className="main-heading">Screenshots</span>

            <a className="title-link" href={logFile} target="_blank">
              Log File
            </a>

            {
              <ColorButton
                size="small"
                variant="contained"
                onClick={(event) => {
                  event.preventDefault();
                  modalExecute({
                    tcid,
                    stepNum:
                      editValue === true
                        ? newTestStep?.Step_Number
                        : valuesSaved[indexValueChange]?.stepNumber,
                    nextNum:
                      editValue === true
                        ? newTestStep?.Step_Number + 1
                          ? newTestStep?.Step_Number + 1
                          : newTestStep?.Step_Number
                        : valuesSaved[indexValueChange + 1]?.stepNumber
                          ? valuesSaved[indexValueChange + 1]?.stepNumber
                          : valuesSaved[indexValueChange].stepNumber,
                    tcname,
                    envUrl: encodeURIComponent(valuesSaved[0].testData),
                  });
                }}
                sx={{ marginLeft: 5 }}
              >
                Merge Co Pilot
              </ColorButton>
            }

            {/* {<ColorButton
              size="small"
              variant="contained"
              onClick={(event) => {
                event.preventDefault();
                aexecute({
                  tcid,
                  stepNum: editValue === true ? newTestStep?.Step_Number : valuesSaved[indexValueChange]?.stepNumber,
                  nextNum: editValue === true ? newTestStep?.Step_Number + 1 ? newTestStep?.Step_Number + 1 : newTestStep?.Step_Number : valuesSaved[indexValueChange + 1]?.stepNumber ? valuesSaved[indexValueChange + 1]?.stepNumber : valuesSaved[indexValueChange].stepNumber,
                  tcname,
                  envUrl: encodeURIComponent(valuesSaved[0].testData),
                });
              }}
              sx={{ marginLeft: 5 }}
            >
              Analysis Co Pilot
            </ColorButton>} */}

            <ColorButton
              onClick={handleButtonClick}
              size="small"
              variant="contained"
              disabled={editValue === false ? false : true}
              sx={{ marginLeft: 4 }}
            >
              Step History
            </ColorButton>
            {valuesSaved[indexValueChange]?.status === "FAIL" ? (
              <ColorButton
                size="small"
                variant="contained"
                onClick={() => {
                  handleClose(
                    "Compare Screenshot",
                    valuesSaved[indexValueChange].Test_Case_Results_Id,
                    valuesSaved[indexValueChange]?.screenShot,
                    valuesSaved[indexValueChange]?.testStepId,
                    valuesSaved[indexValueChange]?.stepNumber,
                  );
                }}
                sx={{ marginLeft: 4 }}
              >
                analyze failure
              </ColorButton>
            ) : (
              ""
            )}
            <TextField
              className="header-textfield"
              label="Page Name"
              variant="filled"
              defaultValue={valuesSaved[indexValueChange]?.pageName}
              value={
                editValue === true
                  ? newTestStep?.Page_Name
                  : valuesSaved[indexValueChange]?.page_Name
              }
              style={{
                width: 200,
              }}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => {
                // event.preventDefault();
                setNewTestStep((prev) => {
                  return {
                    ...prev,
                    Page_Name: event.target.value,
                  };
                });
              }}
              disabled={editValue === true ? false : true}
            />
            {editValue === true ? (
              <ColorButton
                size="small"
                variant="contained"
                sx={{ marginLeft: 4 }}
                onClick={() => {
                  editValueNegative();
                }}
                disabled={indexValueChange <= valuesSaved.length - 1 ? false : true}
              >
                View Results
              </ColorButton>
            ) : (
              <ColorButton
                size="small"
                variant="contained"
                sx={{ marginLeft: 4 }}
                onClick={() => {
                  editValuePositive();
                  imageDiffValueNegative();
                }}
              >
                Edit
              </ColorButton>
            )}
            {editValue === false && analysisDiffValue === false ? (
              imageDiffValue === false ? (
                <ColorButton
                  size="small"
                  sx={{ marginLeft: 4 }}
                  variant="contained"
                  onClick={() => {
                    getImageDiff(
                      valuesSaved[indexValueChange]?.testStepId,
                      valuesSaved[indexValueChange]?.stepNumber,
                      tcid,
                      valuesSaved[indexValueChange]?.Test_Case_Results_Id,
                    );
                    imageDiffValuePositive();
                  }}
                >
                  Image Diff
                </ColorButton>
              ) : (
                <ColorButton
                  size="small"
                  variant="contained"
                  sx={{ marginLeft: 4 }}
                  onClick={() => {
                    imageDiffValueNegative();
                  }}
                >
                  Go Back
                </ColorButton>
              )
            ) : (
              ""
            )}
            {oneTimeTestCaseObject.beenanalysed === 1 ? (
              editValue === false && imageDiffValue === false ? (
                analysisDiffValue === false ? (
                  <ColorButton
                    size="small"
                    sx={{ marginLeft: 4 }}
                    variant="contained"
                    onClick={() => {
                      getDiff(
                        valuesSaved[indexValueChange]?.testStepId,
                        valuesSaved[indexValueChange]?.stepNumber,
                        tcid,
                      );
                      analysisDiffValuePositive();
                    }}
                  >
                    Analysis Result
                  </ColorButton>
                ) : (
                  <ColorButton
                    size="small"
                    variant="contained"
                    sx={{ marginLeft: 4 }}
                    onClick={() => {
                      analysisDiffValueNegative();
                    }}
                  >
                    Go Back
                  </ColorButton>
                )
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </BootstrapDialogTitle>
        </div>
        {console.log(valuesSaved)}
        <DialogContent dividers className="dialogcontent-css">
          {imageDiffValue === false && analysisDiffValue === false ? (
            <>
              <MyCarousel
                secondImage={valuesSaved[indexValueChange]?.screenShot}
                firstImage={valuesSaved[indexValueChange]?.screenShotOne}
                indexValueChanges={indexValueChange}
              />

              {editValue === true ? (
                <div className="alinging-textbox-for-edit">
                  <div className="edit-all-select">
                    <div className="edit-select-addStep">
                      <InputLabel>Add After Step</InputLabel>
                      <Select
                        // className="textfield"
                        // label="Step Number"
                        style={{ backgroundColor: colors.tertiaryColor }}
                        variant="filled"
                        fullWidth
                        value={newTestStep?.Step_Number}
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => {
                          setNewTestStep((prev) => {
                            return {
                              ...prev,
                              Step_Number: event.target.value,
                            };
                          });
                        }}
                      >
                        {state.stepnums.map((item, index) => (
                          <MenuItem key={index} value={item}>
                            {item.toString().slice(0, 3)}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className="edit-select-keyword">
                      <InputLabel>Keyword</InputLabel>
                      <Select
                        // className="textfield"
                        // label="Keyword"
                        style={{ backgroundColor: colors.tertiaryColor }}
                        variant="filled"
                        fullWidth
                        //defaultValue={newTestStep?.keyword}
                        value={newTestStep?.Keyword}
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => {
                          // event.preventDefault();
                          setNewTestStep((prev) => {
                            return {
                              ...prev,
                              Keyword: event.target.value,
                            };
                          });
                        }}
                      >
                        {KeywordData.map((item, index) => (
                          <MenuItem key={index} value={item.value}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className="edit-select-action">
                      <InputLabel>Action</InputLabel>
                      <Select
                        // className="textfield"
                        // label="Action"
                        style={{ backgroundColor: colors.tertiaryColor }}
                        variant="filled"
                        fullWidth
                        // defaultValue={valuesSaved[indexValueChange]?.action}
                        value={newTestStep?.Action}
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => {
                          event.preventDefault();
                          setNewTestStep((prev) => {
                            return {
                              ...prev,
                              Action: event.target.value,
                            };
                          });
                        }}
                      >
                        {ActionData.map((item, index) => (
                          <MenuItem key={index} value={item.value}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    {newTestStep?.Action === "Select" && (
                      <div className="edit-select-Options">
                        <InputLabel>Options</InputLabel>
                        <Select
                          // className="textfield"
                          // label="Action"
                          style={{ backgroundColor: colors.tertiaryColor }}
                          variant="filled"
                          fullWidth
                          // defaultValue={valuesSaved[indexValueChange]?.action}
                          value={newTestStep?.subAction}
                          InputLabelProps={{ shrink: true }}
                          onChange={(event) => {
                            event.preventDefault();
                            setNewTestStep((prev) => {
                              return {
                                ...prev,
                                subAction: event.target.value,
                              };
                            });
                          }}
                        >
                          <MenuItem value="ById">By Index</MenuItem>
                          <MenuItem value="ByText">By Text</MenuItem>
                          <MenuItem value="ByValue">By Value</MenuItem>
                        </Select>
                      </div>
                    )}
                    {newTestStep?.Action === "Checkbox" && (
                      <div className="edit-select-Options">
                        <InputLabel>Options</InputLabel>
                        <Select
                          // className="textfield"
                          // label="Action"
                          style={{ backgroundColor: colors.tertiaryColor }}
                          variant="filled"
                          fullWidth
                          // defaultValue={valuesSaved[indexValueChange]?.action}
                          value={newTestStep?.subAction}
                          InputLabelProps={{ shrink: true }}
                          onChange={(event) => {
                            event.preventDefault();
                            setNewTestStep((prev) => {
                              return {
                                ...prev,
                                subAction: event.target.value,
                              };
                            });
                          }}
                        >
                          <MenuItem value="ById">By Id</MenuItem>
                          <MenuItem value="ByXpath">By Path</MenuItem>
                          <MenuItem value="ByText">By Text</MenuItem>
                        </Select>
                      </div>
                    )}
                    {newTestStep?.Action === "validatePartial" && (
                      <div className="edit-select-Options">
                        <InputLabel>Options</InputLabel>
                        <Select
                          // className="textfield"
                          // label="Action"
                          style={{ backgroundColor: colors.tertiaryColor }}
                          variant="filled"
                          fullWidth
                          // defaultValue={valuesSaved[indexValueChange]?.action}
                          value={newTestStep?.subAction}
                          InputLabelProps={{ shrink: true }}
                          onChange={(event) => {
                            event.preventDefault();
                            setNewTestStep((prev) => {
                              return {
                                ...prev,
                                subAction: event.target.value,
                              };
                            });
                          }}
                        >
                          <MenuItem value="validateBGcolor">Background Color</MenuItem>
                          <MenuItem value="validatePartial">Contains</MenuItem>
                          <MenuItem value="validatePartial">Color</MenuItem>
                          <MenuItem value="validateExact">Exact</MenuItem>
                          <MenuItem value="validateImage">Image</MenuItem>
                        </Select>
                      </div>
                    )}
                    <div className="edit-select-flow">
                      <InputLabel>Flow</InputLabel>
                      <Select
                        // className="textfield"
                        // label={editValue === true ? "Flow" : "Duration"}
                        style={{ backgroundColor: colors.tertiaryColor }}
                        variant="filled"
                        fullWidth
                        // defaultValue={editValue === true ? valuesSaved[indexValueChange]?.flow : valuesSaved[indexValueChange]?.duration}
                        value={newTestStep?.Flow}
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => {
                          event.preventDefault();
                          setNewTestStep((prev) => {
                            return {
                              ...prev,
                              Flow: event.target.value,
                            };
                          });
                        }}
                      >
                        <MenuItem value="Negative">Must fail</MenuItem>
                        <MenuItem value="Positive">Must pass</MenuItem>
                        <MenuItem value="Optional">Optional</MenuItem>
                      </Select>
                    </div>
                  </div>
                  <div className="edit-all-textfield">
                    <div className="edit-textfield-threshold">
                      <InputLabel>Step Threshold</InputLabel>
                      <TextField
                        style={{ backgroundColor: colors.tertiaryColor }}
                        variant="filled"
                        fullWidth
                        value={newTestStep?.teststepthreshold}
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => {
                          event.preventDefault();
                          setNewTestStep((prev) => {
                            return {
                              ...prev,
                              teststepthreshold: event.target.value,
                            };
                          });
                        }}
                      />
                    </div>
                    <div className="edit-textfield-Wait">
                      <InputLabel>Wait</InputLabel>
                      <Select
                        // className="textfield"
                        // label={editValue === true ? "Flow" : "Duration"}
                        style={{ backgroundColor: colors.tertiaryColor }}
                        variant="filled"
                        fullWidth
                        // defaultValue={editValue === true ? valuesSaved[indexValueChange]?.flow : valuesSaved[indexValueChange]?.duration}
                        value={newTestStep?.wait}
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => {
                          event.preventDefault();
                          setNewTestStep((prev) => {
                            return {
                              ...prev,
                              wait: event.target.value,
                            };
                          });
                        }}
                      >
                        <MenuItem value="After">After</MenuItem>
                        <MenuItem value="Before">Before</MenuItem>
                        <MenuItem value="Check">Check</MenuItem>
                      </Select>
                    </div>
                    <div className="edit-textfield-Waittime">
                      <InputLabel>Wait Time</InputLabel>
                      <TextField
                        // className="textfield"
                        // label="Wait Time"
                        style={{ backgroundColor: colors.tertiaryColor }}
                        variant="filled"
                        fullWidth
                        // defaultValue={valuesSaved[indexValueChange]?.status}
                        value={newTestStep?.waittime}
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => {
                          event.preventDefault();
                          setNewTestStep((prev) => {
                            return {
                              ...prev,
                              waittime: event.target.value,
                            };
                          });
                        }}
                      />
                    </div>
                    <div className="edit-textfield-pd">
                      <InputLabel>Page Description</InputLabel>
                      <TextField
                        // className="textfield"
                        // label={editValue === true ? "Page Description" : "Validate In"}
                        style={{ backgroundColor: colors.tertiaryColor }}
                        variant="filled"
                        fullWidth
                        // defaultValue={editValue === true ? valuesSaved[indexValueChange]?.pageDescription : valuesSaved[indexValueChange]?.device}
                        value={newTestStep?.Page_Description}
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => {
                          event.preventDefault();
                          setNewTestStep((prev) => {
                            return {
                              ...prev,
                              Page_Description: event.target.value,
                            };
                          });
                        }}
                      />
                    </div>

                    <div className="textfield-type">
                      <InputLabel>Type</InputLabel>
                      <TextField
                        style={{ backgroundColor: colors.tertiaryColor }}
                        value={newTestStep?.type}
                        variant="filled"
                        fullWidth
                        onChange={(event) => {
                          event.preventDefault();
                          setNewTestStep((prev) => {
                            return {
                              ...prev,
                              type: event.target.value,
                            };
                          });
                        }}
                        disabled={editValue === true ? false : true}
                      />
                    </div>

                    <div className="edit-textfield-img">
                      <InputLabel>Color</InputLabel>
                      <TextField
                        variant="filled"
                        fullWidth
                        style={{ backgroundColor: colors.tertiaryColor }}
                        // value={newTestStep?.VarName}
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => {
                          event.preventDefault();
                          setNewTestStep((prev) => {
                            return {
                              ...prev,
                              // VarName: event.target.value,
                            };
                          });
                        }}
                        disabled={editValue === true ? false : true}
                      />
                    </div>

                    <div className="edit-textfield-BG">
                      <InputLabel>Background Color</InputLabel>

                      <TextField
                        variant="filled"
                        fullWidth
                        style={{ backgroundColor: colors.tertiaryColor }}
                        // value={newTestStep?.VarName}
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => {
                          event.preventDefault();
                          setNewTestStep((prev) => {
                            return {
                              ...prev,
                              // VarName: event.target.value,
                            };
                          });
                        }}
                        disabled={editValue === true ? false : true}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="alinging-textbox">
                  <TextField
                    className="textfield"
                    label="Step Number"
                    variant="filled"
                    style={{ backgroundColor: colors.tertiaryColor }}
                    fullWidth
                    value={
                      editValue === true
                        ? newTestStep?.Step_Number
                        : valuesSaved[indexValueChange]?.stepNumber
                    }
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) => {
                      // event.preventDefault();
                      setNewTestStep((prev) => {
                        return {
                          ...prev,
                          Step_Number: event.target.value,
                        };
                      });
                    }}
                    disabled={editValue === true ? false : true}
                  />
                  <TextField
                    className="textfield"
                    label="Keyword"
                    variant="filled"
                    style={{ backgroundColor: colors.tertiaryColor }}
                    fullWidth
                    //defaultValue={newTestStep?.keyword}
                    value={
                      editValue === true
                        ? newTestStep?.Keyword
                        : valuesSaved[indexValueChange]?.keyword
                    }
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) => {
                      // event.preventDefault();
                      setNewTestStep((prev) => {
                        return {
                          ...prev,
                          Keyword: event.target.value,
                        };
                      });
                    }}
                    disabled={editValue === true ? false : true}
                  />
                  <TextField
                    className="textfield"
                    label="Action"
                    variant="filled"
                    style={{ backgroundColor: colors.tertiaryColor }}
                    fullWidth
                    // defaultValue={valuesSaved[indexValueChange]?.action}
                    value={
                      editValue === true
                        ? newTestStep?.Action
                        : valuesSaved[indexValueChange]?.action
                    }
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) => {
                      event.preventDefault();
                      setNewTestStep((prev) => {
                        return {
                          ...prev,
                          Action: event.target.value,
                        };
                      });
                    }}
                    disabled={editValue === true ? false : true}
                  />
                  <TextField
                    className="textfield"
                    label={editValue === true ? "Flow" : "Duration(s)"}
                    variant="filled"
                    style={{ backgroundColor: colors.tertiaryColor }}
                    fullWidth
                    // defaultValue={editValue === true ? valuesSaved[indexValueChange]?.flow : valuesSaved[indexValueChange]?.duration}
                    value={
                      editValue === true
                        ? newTestStep?.Flow
                        : valuesSaved[indexValueChange]?.duration
                    }
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) => {
                      event.preventDefault();
                      setNewTestStep((prev) => {
                        return {
                          ...prev,
                          Flow: event.target.value,
                        };
                      });
                    }}
                    disabled={editValue === true ? false : true}
                  />
                  <TextField
                    className="textfield"
                    label={editValue === true ? "Wait" : "Status"}
                    style={{ backgroundColor: colors.tertiaryColor }}
                    variant="filled"
                    fullWidth
                    // defaultValue={valuesSaved[indexValueChange]?.status}
                    value={
                      editValue === true ? newTestStep?.wait : valuesSaved[indexValueChange]?.status
                    }
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) => {
                      event.preventDefault();
                      setNewTestStep((prev) => {
                        return {
                          ...prev,
                          wait: event.target.value,
                        };
                      });
                    }}
                    disabled={editValue === true ? false : true}
                  />
                  <TextField
                    className="textfield"
                    label={editValue === true ? "Page Description" : "Validate In"}
                    variant="filled"
                    style={{ backgroundColor: colors.tertiaryColor }}
                    fullWidth
                    // defaultValue={editValue === true ? valuesSaved[indexValueChange]?.pageDescription : valuesSaved[indexValueChange]?.device}
                    value={
                      editValue === true
                        ? newTestStep?.Page_Description
                        : valuesSaved[indexValueChange]?.device
                    }
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) => {
                      event.preventDefault();
                      setNewTestStep((prev) => {
                        return {
                          ...prev,
                          Page_Description: event.target.value,
                        };
                      });
                    }}
                    disabled={editValue === true ? false : true}
                  />
                </div>
              )}

              <div className="edit-all-secondrow">
                <div className="edit-textfield-var">
                  <InputLabel>Variable</InputLabel>
                  <TextField
                    variant="filled"
                    fullWidth
                    style={{ backgroundColor: colors.tertiaryColor }}
                    value={newTestStep?.VarName}
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) => {
                      event.preventDefault();
                      setNewTestStep((prev) => {
                        return {
                          ...prev,
                          VarName: event.target.value,
                        };
                      });
                    }}
                    disabled={editValue === true ? false : true}
                  />
                </div>

                <div className="textfield-TD">
                  <InputLabel>Testdata</InputLabel>
                  <TextField
                    // label="TestData"
                    variant="filled"
                    fullWidth
                    style={{ backgroundColor: colors.tertiaryColor }}
                    // defaultValue={valuesSaved[indexValueChange]?.testData}
                    value={
                      editValue === true
                        ? newTestStep?.TestData
                        : valuesSaved[indexValueChange]?.testData
                    }
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) => {
                      event.preventDefault();
                      setNewTestStep((prev) => {
                        return {
                          ...prev,
                          TestData: event.target.value,
                        };
                      });
                    }}
                    disabled={editValue === true ? false : true}
                  />
                </div>
                {editValue === true ? (
                  <IconButton
                    style={{ backgroundColor: "transparent" }}
                    onClick={() => {
                      setModalExpandValue((prev) => !prev);
                    }}
                  >
                    <ColorButton
                      style={{ marginTop: "2rem", marginRight: "24px" }}
                      size="small"
                      variant="contained"
                    >
                      {newTestStep?.Keyword === "URL"
                        ? "Enviornment"
                        : newTestStep?.Keyword === "Assertion"
                          ? "Assertion Setup"
                          : "TestData Setup"}
                      <ExpandMoreIcon />
                    </ColorButton>
                  </IconButton>
                ) : null}

                {editValue === true ? (
                  <IconButton
                    style={{ backgroundColor: "transparent" }}
                    onClick={() => {
                      if (checkModuleEnable !== 1) {
                        setModalShow((prev) => !prev);
                        setModalExpandValue(false);
                      }
                    }}
                  >
                    <ColorButton
                      style={{ marginTop: "2rem", marginRight: "0.5rem" }}
                      size="small"
                      variant="contained"
                      disabled={checkModuleEnable === 1 ? true : false}
                    >
                      Element Locator
                      <ExpandMoreIcon />
                    </ColorButton>
                  </IconButton>
                ) : null}
                <FormControl style={{ marginTop: "38px", marginLeft: "50px" }}>
                  <FormControlLabel
                    control={<Switch checked={toggleOn} onChange={handleToggle} />}
                    label="Dynamic Value"
                    disabled={editValue === true ? false : true}
                  />
                </FormControl>
              </div>
              <div className="modal-expand">
                {editValue === true
                  ? modalExpandValue && (
                      <ModalExpand
                        test={newTestStep}
                        keywordValue={newTestStep?.Keyword}
                        handleClose={handleClose}
                        additionalTestCase={oneTimeTestCaseObject}
                        productId={productId}
                      />
                    )
                  : null}
              </div>
              <div className="element-locate">
                {editValue === true
                  ? modalShow && (
                      <ElementLocate
                        setTestDataDetails={setTestDataDetails}
                        TestDataDetails={TestDataDetails}
                        editValue={editValue}
                        valuesSavedResult={valuesSavedResult}
                        indexValueChange={indexValueChange}
                        setNewTestStep={setNewTestStep}
                        setState={setState}
                        newTestStep={newTestStep}
                        handleClose={handleClose}
                      />
                    )
                  : null}
              </div>

              <div className="align-iframe-tabid">
                <div className="textfield-iframe">
                  <InputLabel>Iframexpath</InputLabel>

                  <TextField
                    value={newTestStep?.iframexpath}
                    variant="filled"
                    fullWidth
                    style={{ backgroundColor: colors.tertiaryColor }}
                    onChange={(event) => {
                      event.preventDefault();
                      setNewTestStep((prev) => {
                        return {
                          ...prev,
                          iframexpath: event.target.value,
                        };
                      });
                    }}
                    disabled={editValue === true ? false : true}
                  />
                </div>

                <div className="textfield-IP">
                  <InputLabel>Image Path</InputLabel>
                  <TextField
                    style={{ backgroundColor: colors.tertiaryColor }}
                    // value={newTestStep?.iframexpath}
                    variant="filled"
                    fullWidth
                    onChange={(event) => {
                      event.preventDefault();
                      setNewTestStep((prev) => {
                        return {
                          ...prev,
                          // iframexpath: event.target.value,
                        };
                      });
                    }}
                    disabled={editValue === true ? false : true}
                  />
                </div>

                <div className="edit-select-tabs">
                  <InputLabel>Tabs</InputLabel>
                  <Select
                    style={{ backgroundColor: colors.tertiaryColor }}
                    variant="filled"
                    fullWidth
                    value={newTestStep?.tabid === 0 ? 0 : newTestStep?.tabid || ""}
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) => {
                      setNewTestStep((prev) => {
                        return {
                          ...prev,
                          tabid: event.target.value,
                        };
                      });
                    }}
                    disabled={editValue !== null && editValue === true ? false : true}
                  >
                    {state.tabsdata?.map((item, index) => (
                      <MenuItem key={index} value={item.tabId}>
                        {item.tabName}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  padding: "20px",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  {analysisDiffValue === true ? (
                    <div className="image-heading">
                      {"This Image shows differences if any - Analysed on :" +
                        oneTimeTestCaseObject.analysisdate}
                    </div>
                  ) : (
                    <div className="image-heading">{"The Latest Image for this step"}</div>
                  )}
                  {failedImage ? (
                    <img
                      src={failedImage}
                      style={{
                        width: "calc(100% - 10px)",
                        height: "60vh",
                        marginRight: "10px",
                        border: "1px solid #000",
                      }}
                      alt="Failed Test Image Not Available"
                    />
                  ) : (
                    <div
                      className="carousel-placeholder"
                      style={{
                        width: "calc(100% - 10px)",
                        height: "60vh",
                        marginRight: "10px",
                        border: "1px solid #000",
                      }}
                    >
                      <span>- - - - - - - - - -Diff Image Does Not Exist - - - - - - - - - - </span>
                    </div>
                  )}
                </div>
                <div style={{ textAlign: "center" }}>
                  <div className="image-heading">
                    {analysisDiffValue === true ? (
                      oneTimeTestCaseObject.imagesbaselined === 0 ? (
                        "The Latest Image for this step"
                      ) : (
                        "The Baselined Image for this step - baselined on " +
                        oneTimeTestCaseObject.baselinedate
                      )
                    ) : (
                      <div className="image-heading">
                        {
                          "This Image is from the last time this test case had executed successfully"
                        }
                      </div>
                    )}
                  </div>
                  {passedImage ? (
                    <img
                      src={passedImage}
                      style={{
                        width: "calc(100% - 10px)",
                        height: "60vh",
                        marginRight: "10px",
                        border: "1px solid #000",
                      }}
                      alt="Passed Test Image Not Available"
                    />
                  ) : (
                    <div
                      className="carousel-placeholder"
                      style={{
                        width: "calc(100% - 10px)",
                        height: "60vh",
                        marginRight: "10px",
                        border: "1px solid #000",
                      }}
                    >
                      <span>- - - - - - - Latest/Baseline Image Does Not Exist- - - - - - - </span>
                    </div>
                  )}
                </div>
              </div>
              <div>{rows}</div>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <ColorButton
            size="small"
            autoFocus
            variant="contained"
            onClick={() => {
              sendAddStep({
                Keyword: newTestStep.Keyword,
                Action: newTestStep.ActionTwo ? newTestStep.ActionTwo : newTestStep.Action,
                Flow: newTestStep.Flow,
                Page_Description: newTestStep.Page_Description,
                Page_Name: newTestStep.Page_Name,
                TestData: newTestStep.TestData,
                Test_Case_Id: newTestStep.Test_Case_Id,
                ObjectIdentifier: newTestStep.Object_Xpath,
                stepnumber: newTestStep.Step_Number,
                wait: newTestStep.wait,
                waittime: newTestStep.waittime,
                subAction: newTestStep.subAction,
                teststepthreshold: newTestStep.teststepthreshold,
                iframexpath: newTestStep.iframexpath,
                VarName: newTestStep.VarName,
                type: newTestStep.type,
                tabid: newTestStep.tabid,
                isDynamic: toggleOn ? 1 : 0,
              });
              handleClose("Add Step");
              editValueNegative();
            }}
            disabled={editValue === false ? true : false}
          >
            Add Step
          </ColorButton>
          <ColorButton
            size="small"
            variant="contained"
            onClick={() => {
              sendEditStep({
                Keyword: newTestStep.Keyword,
                Action: newTestStep.ActionTwo ? newTestStep.ActionTwo : newTestStep.Action,
                Flow: newTestStep.Flow,
                Page_Description: newTestStep.Page_Description,
                Page_Name: newTestStep.Page_Name,
                TestData: newTestStep.TestData,
                Test_Case_Id: newTestStep.Test_Case_Id,
                ObjectIdentifier: newTestStep.Object_Xpath,
                tsid: newTestStep.idtest_step,
                wait: newTestStep.wait,
                waittime: newTestStep.waittime,
                subAction: newTestStep.subAction,
                teststepthreshold: newTestStep.teststepthreshold,
                iframexpath: newTestStep.iframexpath,
                VarName: newTestStep.VarName,
                type: newTestStep.type,
                tabid: newTestStep.tabid,
                isDynamic: toggleOn ? 1 : 0,
              });
              editValueNegative();
              handleClose("Save");
            }}
            disabled={editValue === false ? true : false}
          >
            Save Step
          </ColorButton>
          <ColorButton
            size="small"
            autoFocus
            variant="contained"
            onClick={() => {
              handleClose("Delete", newTestStep.idtest_step, newTestStep.Test_Case_Id);
              editValueNegative();
            }}
            disabled={editValue === false ? true : false}
          >
            Delete Step
          </ColorButton>

          <ColorButton
            size="small"
            variant="contained"
            onClick={() => {
              decrementCount();
              if (imageDiffValue === true) {
                getImageDiff(
                  valuesSaved[indexValueChange - 1]?.testStepId,
                  valuesSaved[indexValueChange - 1]?.stepNumber,
                  tcid,
                  valuesSaved[indexValueChange]?.Test_Case_Results_Id,
                );
              } else if (analysisDiffValue === true) {
                getDiff(
                  valuesSaved[indexValueChange - 1]?.testStepId,
                  valuesSaved[indexValueChange - 1]?.stepNumber,
                  tcid,
                );
              }
            }}
            disabled={
              editValue === true
                ? indexValueChange === 0
                  ? true
                  : false
                : indexValueChange === 0
                  ? true
                  : false
            }
          >
            Previous
          </ColorButton>

          <ColorButton
            size="small"
            variant="contained"
            onClick={() => {
              incrementCount();
              if (imageDiffValue === true) {
                getImageDiff(
                  valuesSaved[indexValueChange + 1]?.testStepId,
                  valuesSaved[indexValueChange + 1]?.stepNumber,
                  tcid,
                  valuesSaved[indexValueChange]?.Test_Case_Results_Id,
                );
              } else if (analysisDiffValue === true) {
                getDiff(
                  valuesSaved[indexValueChange + 1]?.testStepId,
                  valuesSaved[indexValueChange + 1]?.stepNumber,
                  tcid,
                );
              }
            }}
            disabled={
              editValue === true
                ? indexValueChange === valuesSavedRecording.length - 1
                  ? true
                  : false
                : indexValueChange === valuesSaved.length - 1
                  ? true
                  : false
            }
          >
            next
          </ColorButton>

          <Button
            autoFocus
            onClick={() => {
              handleClose();
              editValueNegative();
            }}
            disabled={
              indexValueChange === valuesSaved.length - 1 || editValue === true ? true : false
            }
          >
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default ScreenshotModal;
