import React from "react";

const DeleteStepsIcon = () => (
  <svg width="424" height="339" viewBox="0 0 424 339" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M307.219 127.125V105.938H264.844V127.125H211.875V158.906H233.062V243.656C233.062 261.242 247.258 275.438 264.844 275.438H307.219C324.804 275.438 339 261.242 339 243.656V158.906H360.188V127.125H307.219ZM307.219 243.656H264.844V158.906H307.219V243.656ZM381.375 42.375H211.875L169.5 0H42.375C18.8569 0 0.211875 18.8569 0.211875 42.375L0 296.625C0 320.143 18.8569 339 42.375 339H381.375C404.893 339 423.75 320.143 423.75 296.625V84.75C423.75 61.2319 404.893 42.375 381.375 42.375ZM381.375 296.625H42.375V42.375H151.914L194.289 84.75H381.375V296.625Z" fill="#242F3F"/>
</svg>

);

export default DeleteStepsIcon;
