import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import {
  FormControl,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { colorExport } from "./../../components/common/common.Component";
import ShadowPage from "./Shadowpage";

export default function DateTimePickerValue() {
  const [value, setValue] = useState(dayjs());

  const getCurrentTimeFormatted = () => {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const [timeValue, setTimeValue] = useState(getCurrentTimeFormatted());

  const colors = colorExport();
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);

  // Update the value every second
  //   useEffect(() => {
  //     const interval = setInterval(() => {
  //       setValue(dayjs());
  //     }, 1000); // Update every second

  //     return () => {
  //       clearInterval(interval);
  //     };
  //   }, []);

  const formatDateForInput = (date) => {
    // Convert the Day.js date to a string in 'YYYY-MM-DDTHH:mm' format
    return date.format("YYYY-MM-DDTHH:mm");
  };
  const showAlert = () => {
    alert("The process id is 631509 for the job");
  };

  return (
    <Table
      sx={{ border: "2px solid #032F40", marginTop: "100px", marginLeft: "20px", width: "98%" }}
    >
      <TableHead sx={{ backgroundColor: colors.secondaryColor }}>
        <TableRow>
          <TableCell
            sx={{ color: colors.primaryColor }}
            style={{ color: colors.primaryColor, backgroundColor: colors.secondaryColor }}
          >
            Element Name
          </TableCell>
          <TableCell
            sx={{ color: colors.primaryColor }}
            style={{ color: colors.primaryColor, backgroundColor: colors.secondaryColor }}
          >
            Element Type
          </TableCell>
          <TableCell
            sx={{ color: colors.primaryColor }}
            style={{ color: colors.primaryColor, backgroundColor: colors.secondaryColor }}
          >
            Description
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Date Time Picker</TableCell>
          <TableCell>
            <FormControl sx={{ width: 340, color: colors.primaryColor, marginLeft: 2 }}>
              <input
                type="datetime-local"
                value={formatDateForInput(value)}
                onChange={(event) => setValue(dayjs(event.target.value))}
                style={{ height: "50px", border: "1px solid black" }}
              />
            </FormControl>
          </TableCell>
          <TableCell>Used to select Date and Time</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Input Field</TableCell>
          <TableCell>
            <FormControl sx={{ width: 240, color: colors.primaryColor, marginLeft: 2 }}>
              <TextField
                sx={{
                  marginRight: "2rem",
                  border: "1px solid black",
                }}
                label="New Data"
                variant="standard"
                // defaultValue={TestDataDetails.UploadString}
              />
            </FormControl>
          </TableCell>
          <TableCell>Used to enter text</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Time Picker</TableCell>
          <TableCell>
            <FormControl sx={{ width: 200, color: colors.primaryColor, marginLeft: 2 }}>
              <input
                type="time"
                value={timeValue}
                onChange={(event) => setTimeValue(event.target.value)}
                style={{ height: "50px", fontFamily: "inherit", border: "1px solid black" }}
              />
            </FormControl>
          </TableCell>
          <TableCell>Used to select Time</TableCell>
        </TableRow>
      </TableBody>
      <div>
        <ShadowPage />
      </div>
      <button onClick={showAlert}>Click me</button>
      <iframe>df</iframe>
    </Table>
  );
}
