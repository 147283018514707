import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

const fetchFailureAnalysis = async (id) => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");

  const user = JSON.parse(userDetails);
  const response = await axios.get(
    `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getAnalysisForFailedTestStep&companyid=${user.companyid}&token=${user.token}&TCRid=${id}`,
  );

  return response.data;
};

const useFetchFailureAnalysis = (id) => {
  return useQuery({
    queryKey: ["failureAnalysis", id],
    queryFn: () => fetchFailureAnalysis(id), // Wrap in an arrow function
    enabled: !!id,
    retry: 3, // Specify number of retries
    refetchOnWindowFocus: false, // Prevent unnecessary refetches
  });
};

export default useFetchFailureAnalysis;
