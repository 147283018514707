import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Drawer,
  List,
  ListItemText,
  ListItemButton,
  IconButton,
} from "@mui/material";
import {
  History as HistoryIcon,
  Close as CloseIcon,
  AccessTime as AccessTimeIcon,
  Edit as EditIcon,
  FileDownload as FileDownloadIcon,
} from "@mui/icons-material";
import { useBotGenerateTC, useModuleQuery, useProductQuery } from "../../useQuery";
import { v4 as uuidv4 } from "uuid";
import TestCaseTable from "./TestCaseTabel";
import axios from "axios";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../../Config/Config";
import CircularProgress from "@mui/material/CircularProgress";
import { useQueryClient } from "@tanstack/react-query";
import { CustomSelect } from "../../components-new/common";
import CustomDialog from "../../components-new/common/CustomDialog";
import useNotification from "../../hooks/useNotification";
import * as XLSX from "xlsx";
import { IntegrationOptions } from "../../components-new/utils/constants";
import { PillRadio } from "../../components-new/common";
import useEditExitCriteria from "../../useQuery/useEditExitCriteriaQuery";
function ChatBot() {
  const queryClient = useQueryClient();
  const notify = useNotification();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [showPrompt, setShowPrompt] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [productId, setProductId] = useState(null);
  const [moduleId, setModuleId] = useState(null);
  const [gerkinMsg, setGerkinMsg] = useState("");
  const [gerkinInfoMsg, setGerkinInfoMsg] = useState("");
  const [isGerkinModal, setIsGerkinModal] = useState(false);
  const [gerkinItem, setGerkinItem] = useState(null);
  const [isScripts, setIsScripts] = useState(false);
  const [scriptsMsg, setScriptsMsg] = useState("");
  const [scriptsInfoMsg, setScriptsInfoMsg] = useState("");
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [history, setHistory] = useState([]);
  const [selectedIntegration, setSelectedIntegration] = useState(IntegrationOptions[0].value);

  const { data: products } = useProductQuery();
  const { data: modules, refetch: refetchModules } = useModuleQuery(productId, {
    enabled: false,
  });
  const { refetchWithParams: generateTC, data, isFetching, isSuccess } = useBotGenerateTC();
  const { mutate: editExitCriteria, isLoading } = useEditExitCriteria();
  useEffect(() => {
    const savedHistory = localStorage.getItem("chatHistory");
    if (savedHistory) {
      setHistory(JSON.parse(savedHistory));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("chatHistory", JSON.stringify(history));
  }, [history]);

  useEffect(() => {
    if (!isSuccess || !data || showPrompt) return;

    setMessages((prev) => [...prev, { sender: "Bot", data: data }]);

    setHistory((prev) => {
      const updatedHistory = [...prev];
      if (updatedHistory[0]) {
        updatedHistory[0] = {
          ...updatedHistory[0],
          response: data,
        };
      }
      return updatedHistory;
    });
  }, [isSuccess, data]);
  useEffect(() => {
    if (!productId) return;

    refetchModules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  const convertAutomationScriptsToText = (scriptsJson) => {
    try {
      if (!scriptsJson) return "";

      const scripts = typeof scriptsJson === "string" ? JSON.parse(scriptsJson) : scriptsJson;

      if (scripts.nlp) {
        return Object.entries(scripts.nlp)
          .sort(([keyA], [keyB]) => parseInt(keyA) - parseInt(keyB))
          .map(([_, value]) => value)
          .join("\n");
      }

      return "";
    } catch (error) {
      console.error("Error converting automation scripts:", error);
      return scriptsJson;
    }
  };
  const prepareDataForExcel = (data) => {
    return data.map((item) => ({
      ...item,

      automationScripts: item.automationScripts
        ? convertAutomationScriptsToText(item.automationScripts)
        : item.automationScripts,
    }));
  };

  const downloadExcel = (data) => {
    const preparedData = prepareDataForExcel(data);

    const workbook = XLSX.utils.book_new();

    const worksheet = XLSX.utils.json_to_sheet(preparedData);

    const columnWidths = {};
    preparedData.forEach((row) => {
      Object.keys(row).forEach((key) => {
        const cellLength = row[key]?.toString().length || 10;
        columnWidths[key] = Math.max(columnWidths[key] || 0, cellLength);
      });
    });

    worksheet["!cols"] = Object.keys(columnWidths).map((key) => ({
      wch: Math.min(Math.max(columnWidths[key] + 2, 10), 100),
    }));

    Object.keys(worksheet).forEach((cell) => {
      if (cell[0] !== "!") {
        if (!worksheet[cell].s) worksheet[cell].s = {};
        worksheet[cell].s.alignment = { wrapText: true, vertical: "top" };
      }
    });

    XLSX.utils.book_append_sheet(workbook, worksheet, "Test Cases");

    XLSX.writeFile(
      workbook,
      `test_cases_${new Date().toLocaleDateString().replace(/\//g, "-")}.xlsx`,
    );
  };

  const handleSubmit = () => {
    if (!input.trim()) return;

    const newMessage = { sender: "User", text: input };
    setMessages((prev) => [...prev, newMessage]);

    const historyItem = {
      id: uuidv4(),
      prompt: input,
      timestamp: Date.now(),
    };
    setHistory((prev) => [historyItem, ...prev].slice(0, 10));

    setInput("");
    generateTC({
      req: input,
    });
    setShowPrompt(false);
  };

  const handleHistoryItemClick = (historyItem) => {
    if (!historyItem.response) return;

    setShowPrompt(false);
    setMessages([
      { sender: "User", text: historyItem.prompt },
      { sender: "Bot", data: historyItem.response },
    ]);
    setIsHistoryOpen(false);
  };

  const formatTime = (timestamp) => {
    return new Date(timestamp).toLocaleTimeString();
  };

  const generateScript = async (selected) => {
    const userDetails = localStorage.getItem("userDetail");
    if (!userDetails) throw new Error("No user details found in localStorage");
    const user = JSON.parse(userDetails);

    setIsProcessing(true);
    const response = await axios.post(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=generateScript&companyid=${user.companyid}&token=${user.token}`,
      selected, // Send the selected data in the body
    );
    setIsProcessing(false);
    const updatedResponseData = messages[1].data.map((item) => {
      const matchingMessage = response.data.find((message) => message.tcid === item.tcid);
      return matchingMessage ? { ...item, ...matchingMessage } : item;
    });

    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages];

      updatedMessages[1] = { ...updatedMessages[1], data: updatedResponseData };

      return updatedMessages;
    });
  };

  const handleProcessSelected = (selected) => {
    generateScript(selected);
  };

  const handleAutomate = (selected) => {
    saveScript(selected);
  };

  const saveScript = async (selected) => {
    if (!moduleId) {
      notify("Please select product and module", "warning");
      return;
    }
    const userDetails = localStorage.getItem("userDetail");
    if (!userDetails) throw new Error("No user details found in localStorage");
    const user = JSON.parse(userDetails);

    setIsProcessing(true);
    const response = await axios.post(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=saveScript&companyid=${user.companyid}&token=${user.token}`,
      selected, // Send the selected data in the body
      {
        params: {
          modid: moduleId,
        },
      },
    );
    setIsProcessing(false);
    const updatedResponseData = messages[1].data.map((item) => {
      const matchingMessage = response.data.find((message) => message.tcid === item.tcid);
      return matchingMessage ? { ...item, ...matchingMessage } : item;
    });

    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages];

      updatedMessages[1] = { ...updatedMessages[1], data: updatedResponseData };

      return updatedMessages;
    });
  };

  const handleGerkhinChange = () => {
    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages];
      const index = updatedMessages[1].data.findIndex(
        (msgItem) => msgItem.tcid === gerkinItem.tcid,
      );

      if (index !== -1) {
        const updatedItem = { ...updatedMessages[1].data[index], gherkin: gerkinMsg };

        updatedMessages[1].data[index] = updatedItem;
      }

      if (index !== -1) {
        if (!gerkinInfoMsg.length) return;
        const updatedItem = { ...updatedMessages[1].data[index], gherkinInfo: gerkinInfoMsg };

        updatedMessages[1].data[index] = updatedItem;
      }

      return updatedMessages;
    });
    editExitCriteria(gerkinInfoMsg);
  };

  const handleScriptsChange = () => {
    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages];
      const index = updatedMessages[1].data.findIndex(
        (msgItem) => msgItem.tcid === gerkinItem.tcid,
      );

      if (index !== -1) {
        const updatedItem = { ...updatedMessages[1].data[index], automationScripts: scriptsMsg };

        updatedMessages[1].data[index] = updatedItem;
      }

      if (index !== -1) {
        if (!scriptsInfoMsg.length) return;
        const updatedItem = {
          ...updatedMessages[1].data[index],
          automationScriptsInfo: scriptsInfoMsg,
        };

        updatedMessages[1].data[index] = updatedItem;
      }

      return updatedMessages;
    });
  };

  const handleEdit = (item) => {
    setIsScripts(false);
    setGerkinMsg(item.gherkin);
    setGerkinItem(item);
    if (item.gherkinInfo) {
      setGerkinInfoMsg(item.gherkinInfo);
    }
    setIsGerkinModal(true);
  };

  const handleScriptsEdit = (item) => {
    setGerkinItem(item);
    setScriptsMsg(item.automationScripts);

    if (item.automationScriptsInfo) {
      setScriptsInfoMsg(item.automationScriptsInfo);
    }
    setIsScripts(true);
    setIsGerkinModal(true);
  };

  return (
    <>
      <CustomDialog
        width={"1000px"}
        title={isScripts ? "Scripts" : "Edit Exit Criteria"}
        open={isGerkinModal}
        actions={
          <Box p={2} pt={0}>
            <Button
              sx={{ color: "#ff5336" }}
              onClick={() => {
                if (isScripts) {
                  setScriptsMsg("");
                  setScriptsInfoMsg("");
                }
                if (!isScripts) {
                  setGerkinInfoMsg("");
                  setGerkinMsg("");
                }
                setIsGerkinModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ background: "#ff5336" }}
              onClick={() => {
                if (!isScripts) {
                  handleGerkhinChange();
                }
                if (isScripts) {
                  handleScriptsChange();
                }
                setIsGerkinModal(false);
              }}
              disabled={isScripts ? !scriptsInfoMsg : !gerkinInfoMsg}
            >
              Save
            </Button>
          </Box>
        }
        content={
          <>
            {!isScripts ? (
              <Box display={"flex"} gap={2}>
                <Box flex={1}>
                  <Typography color="#8089A8" fontSize="14px" fontWeight={500}>
                    Exit Criteria
                  </Typography>
                  <TextField
                    multiline
                    rows={10}
                    value={gerkinMsg}
                    fullWidth
                    InputProps={{
                      sx: { resize: "none" },
                    }}
                    onChange={(e) => setGerkinMsg(e.target.value)}
                  />
                </Box>

                <Box flex={1}>
                  <Typography color="#8089A8" fontSize="14px" fontWeight={500}>
                    Reason for change (mandatory)
                  </Typography>
                  <TextField
                    multiline
                    rows={10}
                    value={gerkinInfoMsg}
                    fullWidth
                    InputProps={{
                      sx: { resize: "none" },
                    }}
                    onChange={(e) => setGerkinInfoMsg(e.target.value)}
                  />
                </Box>
              </Box>
            ) : (
              <Box display={"flex"} gap={2}>
                <Box flex={1}>
                  <Typography color="#8089A8" fontSize="14px" fontWeight={500}>
                    Scripts Value
                  </Typography>
                  <TextField
                    multiline
                    rows={10}
                    value={scriptsMsg}
                    fullWidth
                    InputProps={{
                      sx: { resize: "none" },
                    }}
                    onChange={(e) => setScriptsMsg(e.target.value)}
                  />
                </Box>

                <Box flex={1}>
                  <Typography color="#8089A8" fontSize="14px" fontWeight={500}>
                    Scripts Info Message
                  </Typography>
                  <TextField
                    multiline
                    rows={10}
                    value={scriptsInfoMsg}
                    fullWidth
                    InputProps={{
                      sx: { resize: "none" },
                    }}
                    onChange={(e) => setScriptsInfoMsg(e.target.value)}
                  />
                </Box>
              </Box>
            )}
          </>
        }
      />

      <Box display="flex" gap={2} p={2} flexDirection="column">
        {!showPrompt && (
          <>
            <Box display="flex" alignItems="center" justifyContent="space-between" gap={2}>
              <Box display="flex" alignItems="center" gap={2}>
                <CustomSelect
                  label={"Product"}
                  options={(Array.isArray(products) ? products : []).map((prodItem) => ({
                    label: prodItem.productname,
                    value: prodItem.idproducts,
                  }))}
                  width={"230px"}
                  background={"#ffffff"}
                  value={productId || ""}
                  onChange={(e) => {
                    setProductId(e.target.value);
                  }}
                />
                {productId && (
                  <CustomSelect
                    label={"Module"}
                    options={(Array.isArray(modules) ? modules : []).map((modItem) => ({
                      label: modItem.modulename,
                      value: modItem.idmodules,
                    }))}
                    width={"230px"}
                    background={"#ffffff"}
                    value={moduleId || ""}
                    onChange={(e) => {
                      setModuleId(e.target.value);
                    }}
                  />
                )}
              </Box>
              <Box display="flex" flexDirection={"row"} gap={2} alignItems={"center"}>
                {messages.length > 0 && messages[1]?.data && (
                  <Button
                    style={{ height: "40px", marginTop: "20px" }}
                    variant="outlined"
                    startIcon={<FileDownloadIcon />}
                    onClick={() => downloadExcel(messages[1].data)}
                    sx={{
                      borderColor: "#4CAF50",
                      color: "#4CAF50",
                      "&:hover": {
                        borderColor: "#45a049",
                        backgroundColor: "rgba(76, 175, 80, 0.04)",
                      },
                    }}
                  >
                    Export Excel
                  </Button>
                )}

                <Button
                  style={{ height: "40px", marginTop: "20px" }}
                  variant="outlined"
                  disabled={isFetching}
                  startIcon={<HistoryIcon />}
                  onClick={() => setIsHistoryOpen(true)}
                >
                  History
                </Button>
                <CustomSelect
                  label="Integration"
                  options={IntegrationOptions.map((option) => ({
                    label: option.label,
                    value: option.value,
                  }))}
                  width={"200px"}
                  background={"#ffffff"}
                  value={selectedIntegration || ""}
                  onChange={(e) => {
                    setSelectedIntegration(e.target.value);
                  }}
                />

                <Button
                  style={{ height: "40px", marginTop: "20px" }}
                  disabled={isFetching}
                  variant="contained"
                  onClick={() => {
                    setMessages([]);
                    queryClient.setQueryData(["generateBotTC"], null);
                    setShowPrompt(true);
                  }}
                >
                  {messages.length > 0 ? "Ask Again" : "New requirement"}
                </Button>
              </Box>
            </Box>

            <Box flex={1} p={2} boxShadow={"0 4px 6px rgba(0,0,0,0.1)"} overflow={"auto"}>
              {messages.length > 0 &&
                messages.map((message) => (
                  <Fragment key={uuidv4()}>
                    {message.sender === "User" && (
                      <Box mb={2}>
                        <Typography fontWeight="800">{message.sender}</Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            gap: 1,
                            width: "100%",
                          }}
                        >
                          <Box
                            component="pre"
                            style={{
                              whiteSpace: "pre-wrap",
                              wordWrap: "break-word",
                              flex: 1,
                            }}
                          >
                            {message.text}
                          </Box>
                        </Box>
                      </Box>
                    )}
                    {message.sender === "Bot" && !isProcessing && (
                      <Box mb={2}>
                        <TestCaseTable
                          data={message.data}
                          handleProcessSelected={handleProcessSelected}
                          handleAutomate={handleAutomate}
                          handleGerkhinChange={handleEdit}
                          handleScriptsChange={handleScriptsEdit}
                        />
                      </Box>
                    )}
                  </Fragment>
                ))}
              {(isFetching || isProcessing) && (
                <Box sx={{ display: "flex" }}>
                  <CircularProgress />
                </Box>
              )}
            </Box>
          </>
        )}

        <Box
          flex={1}
          p={2}
          boxShadow={"0 4px 6px rgba(0,0,0,0.1)"}
          display="flex"
          flexDirection="column"
          gap={2}
        >
          {showPrompt && (
            <>
              <textarea
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Type your message here..."
                style={{ resize: "none" }}
                rows={!showPrompt ? 10 : 40}
              />
              <Button
                disabled={isFetching}
                variant="contained"
                onClick={handleSubmit}
                sx={{ width: "50%", margin: "auto" }}
              >
                {isFetching ? `Generating test case...` : `Generate Test Cases`}
              </Button>
            </>
          )}
        </Box>
      </Box>

      {/* History Drawer */}
      <Drawer
        anchor="right"
        open={isHistoryOpen}
        onClose={() => setIsHistoryOpen(false)}
        PaperProps={{
          sx: {
            width: 350,
            backgroundColor: "#f5f5f5",
            borderLeft: "1px solid #e0e0e0",
          },
        }}
      >
        <Box sx={{ p: 2, borderBottom: "1px solid #e0e0e0", marginTop: "70px" }}>
          <Typography variant="h6" sx={{ fontWeight: 600, color: "#1976d2" }}>
            History
          </Typography>
          <IconButton
            onClick={() => setIsHistoryOpen(false)}
            sx={{
              position: "absolute",
              right: 20,
              top: "10%",
              color: "rgba(0, 0, 0, 0.54)",
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <List sx={{ p: 0, overflowY: "auto" }}>
          {history.map((item) => (
            <ListItemButton
              key={item.id}
              onClick={() => handleHistoryItemClick(item)}
              disabled={!item.response}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "rgba(25, 118, 210, 0.08)",
                },
                "&.Mui-disabled": {
                  opacity: 0.6,
                  cursor: "not-allowed",
                },
                borderBottom: "1px solid #eeeeee",
                p: 2,
              }}
            >
              <ListItemText
                primary={
                  item.prompt && item.prompt.length > 50
                    ? item.prompt.substring(0, 50) + "..."
                    : item.prompt
                }
                secondary={formatTime(item.timestamp)}
                sx={{
                  "& .MuiListItemText-primary": {
                    fontWeight: 500,
                    mb: 0.5,
                  },
                  "& .MuiListItemText-secondary": {
                    display: "flex",
                    alignItems: "center",
                    gap: 0.5,
                    color: "text.secondary",
                    fontSize: "0.75rem",
                  },
                }}
              />
            </ListItemButton>
          ))}
          {history.length > 0 && (
            <ListItemButton
              disabled={history.length === 0 || isFetching}
              onClick={() => {
                //remove all history  from local storage
                localStorage.removeItem("chatHistory");
                setHistory([]);
              }}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "rgba(25, 118, 210, 0.08)",
                },
                "&.Mui-disabled": {
                  opacity: 0.6,
                  cursor: "not-allowed",
                },
                border: "1px solid black",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "20px",
              }}
            >
              <ListItemText
                primary="Clear History"
                sx={{
                  "& .MuiListItemText-primary": {
                    fontWeight: 500,
                    mb: 0.5,
                  },

                  textAlign: "center",
                }}
              />
            </ListItemButton>
          )}

          {history.length === 0 && (
            <Box sx={{ p: 3, textAlign: "center", color: "text.secondary" }}>
              <Typography variant="body2">No history available</Typography>
            </Box>
          )}
        </List>
      </Drawer>
    </>
  );
}

export default ChatBot;
