import React, { useState } from "react";
import { XMarkIcon, PlusIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { API_BACKEND_URL } from "../../../Config/Config";

const CollectionForm = ({ open, handleCloseDialog }) => {
  const [formData, setFormData] = useState({
    name: "",
    baseurl: "",
    description: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const url = `${API_BACKEND_URL}/collections`;

    try {
      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log("Response:", response.data);
      alert("Collection created successfully!");
      handleCloseDialog();
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to create collection.");
    }
  };

  if (!open) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl">
        <div className="flex items-center justify-between p-3 border-b ">
          <h2 className="text-lg font-semibold text-postman-gray-dark">Create New Collection</h2>
          <button
            onClick={handleCloseDialog}
            className="text-postman-gray-medium hover:text-postman-gray-dark"
          >
            <XMarkIcon className="h-6 w-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-4">
          <div className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border  rounded-md focus:ring-2 focus:ring-postman-blue focus:border-transparent"
                  placeholder="Collection Name"
                  required
                />
              </div>
              <div>
                <input
                  type="text"
                  name="baseurl"
                  value={formData.baseurl}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border  rounded-md focus:ring-2 focus:ring-postman-blue focus:border-transparent"
                  placeholder="Base URL"
                  required
                />
              </div>
            </div>

            <div>
              <textarea
                name="description"
                value={formData.description}
                onChange={handleChange}
                rows={3}
                className="w-full px-3 py-2 border  rounded-md focus:ring-2 focus:ring-postman-blue focus:border-transparent"
                placeholder="Description"
                required
              />
            </div>

            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={handleCloseDialog}
                className="px-4 py-2 text-postman-gray-medium hover:text-postman-gray-dark"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="flex items-center px-4 py-2 bg-postman-orange text-white rounded-md hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-postman-orange"
              >
                <PlusIcon className="h-5 w-5 mr-2" />
                Create Collection
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CollectionForm;
