import React from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import AppRouting from "./pages/routes";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Navbar from "./components/Navbar/Navbar.componet";
import Notification from "./components-new/notification/Notification";
import Navigation from "./components/CodeGen/TopNav";
import App2 from "./app-2/App";
import "./App.style.scss";

const AppContent = () => {
  const location = useLocation();
  const isSidebarOpen = useSelector((state) => state.SiteSettings.issidebaropen);
  let isCodeGen = false;

  let isPaymentCompleted = false;
  try {
    const userDetails = localStorage.getItem("userDetail");
    if (userDetails) {
      const user = JSON.parse(userDetails);
      isCodeGen = user?.company?.logintype === "codegen" || user?.codegen === "codegen";
      isPaymentCompleted =
        user?.company?.plan && user?.company?.plan?.available_credits > 0 ? true : false;
    }
  } catch (error) {
    console.error("Error parsing user details from localStorage:", error);
  }

  const isUsingOldLayout = !location.pathname.startsWith("/new") && !isCodeGen;
  const isLoginPage = location.pathname === "/LoginPage";
  const isSignUpPage = location.pathname.startsWith("/SignUpPage");
  const isForgotPasswordPage = location.pathname === "/ForgotPasswordPage";
  const isApp2Route = location.pathname.startsWith("/api");
  const isGenAiRoute = location.pathname.startsWith("/genai");
  const shouldRenderNavigation =
    isCodeGen && !isLoginPage && !isSignUpPage && !isForgotPasswordPage && isPaymentCompleted;

  const dynamicStyles = (theme) => ({
    marginTop: isApp2Route
      ? "0"
      : isLoginPage || isSignUpPage || isCodeGen || isForgotPasswordPage
        ? "0"
        : "64px",
    marginLeft: isApp2Route ? "0" : isUsingOldLayout ? "64px" : "0",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(isSidebarOpen &&
      isUsingOldLayout && {
        marginLeft: "248px",
        width: `calc(100% - 248px)`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }),
  });

  return (
    <>
      {/* Hide Navbar and Navigation for /app2 routes */}
      {!isApp2Route && isUsingOldLayout && !isCodeGen && <Navbar />}
      {!isApp2Route && shouldRenderNavigation && <Navigation />}
      <Box className={"test"} sx={(theme) => dynamicStyles(theme)} open={isSidebarOpen}>
        <Routes>
          <Route path="/*" element={<AppRouting />} />
          <Route path="/api/*" element={<App2 />} />
        </Routes>
      </Box>
      {isGenAiRoute && (
        <iframe
          src="http://demo.nogrunt:3001"
          width="100%"
          height="100%"
          style={{ border: "none", position: "fixed", top: 0, left: 0, zIndex: 9999 }}
        />
      )}
    </>
  );
};

const App = () => {
  return (
    <div className="App">
      <Router>
        <AppContent />
        <Notification />
      </Router>
    </div>
  );
};

export default App;
