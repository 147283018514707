import { useState, forwardRef, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Slide,
  styled,
  Switch,
  Typography,
} from "@mui/material";
import { CustomInput, PageTitle, PillRadio } from "../../components-new/common";
import TabComponent from "../../components-new/common/TabComponent";
import {
  BrowserOptions,
  IntegrationOptions,
  ResolutionOptions,
} from "../../components-new/utils/constants";
import { API_BASE_PATH, API_BASE_RELATIVE_PATH } from "../../Config/Config";
import {
  useExecuteTestSuitesMutation,
  useGetEnvQuery,
  useLicenceKeyQuery,
  useStaticIntegrationsQuery,
  useUpdateHeadless,
  useUpdateParallel,
  useUpdateResultEmail,
  useUpdateScreenShot,
  useUpdateStopAfterFail,
  useUpdateSuiteBrowser,
  useUpdateSuiteEmail,
  useUpdateSuiteUrl,
  useUpdateSync,
} from "../../useQuery";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateSuiteField } from "../../Redux/TestSuite/TestSuite";
import debounce from "lodash/debounce";
import useUpdateRerun from "../../useQuery/useUpdateRerun";
import Scheduler from "./Scheduler";
dayjs.extend(advancedFormat);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    textAlign: "auto",
    padding: theme.spacing(2),
  },
}));

const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: "13px",
  fontWeight: 500,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  padding: "15px 28px",
  borderRadius: "8px",
  textTransform: "capitalize",
  textAlign: "center",
  fontSize: "17px",
  fontWeight: 600,
  lineHeight: "20px",
}));

function stringToBoolean(value) {
  return value === "true";
}

export default function TestSuiteSettings({ open, setOpenSuiteSettings, suite }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data } = useLicenceKeyQuery();
  const { refetchWithParams } = useUpdateSuiteBrowser();
  const { refetchWithParams: updateStopAfterFail } = useUpdateStopAfterFail();
  const { refetchWithParams: updateRerun } = useUpdateRerun();
  const { refetchWithParams: updateParallel } = useUpdateParallel();
  const { refetchWithParams: updateSync } = useUpdateSync();
  const { refetchWithParams: udpateHeadless } = useUpdateHeadless();
  const { refetchWithParams: updateScreenShot } = useUpdateScreenShot();
  const { refetchWithParams: updateResultEmail } = useUpdateResultEmail();
  const { data: envData, refetch: fetchEnv } = useGetEnvQuery(suite.productid);

  const [selectedIntegration, setSelectedIntegration] = useState(IntegrationOptions[0].value);
  const [selectedResolution, setSelectedResolution] = useState("");
  const [proxyUrl, setProxyUrl] = useState("");
  const [iterations, setIterations] = useState("");
  const [exceUrl, setExceUrl] = useState("");

  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");
  const temp = JSON.parse(userDetails);

  const { data: staticIntegrations } = useStaticIntegrationsQuery();

  const { mutate: handleExecuteTestSuite, isSuccess: isExecuteTestSuiteSuccess } =
    useExecuteTestSuitesMutation();
  const { mutate: updateEmail } = useUpdateSuiteEmail();
  const { mutate: udpateSuiteUrl } = useUpdateSuiteUrl();

  const generateExceUrl = (integrationType, workItemId, jiraId) => {
    const baseURL = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=DLExecuteSuite&companyid=${temp.companyid}&token=${data}&prodid=${suite.productid}&SuiteId=${suite.idtest_suite}`;

    return `${baseURL}${integrationType ? `&inttype=${integrationType}` : ""}${workItemId ? `&WorkItemId=${workItemId}` : ""}${jiraId ? `&IssueId=${jiraId}` : ""}`;
  };

  useEffect(() => {
    fetchEnv();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const intType = staticIntegrations?.find((item) => item.intname === selectedIntegration);
    setExceUrl(generateExceUrl(intType?.staticintid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIntegration, data]);

  useEffect(() => {
    if (isExecuteTestSuiteSuccess) {
      navigate("/new/reports", {
        state: {
          isExecuteClick: true,
          selectedSuit: suite.idtest_suite,
          product: suite.productid,
          fetchTestSuite: true,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExecuteTestSuiteSuccess, navigate]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateEmail = useCallback(
    debounce((email) => updateEmail({ data: { email: email }, id: suite.idtest_suite }), 800),
    [suite.idtest_suite, updateEmail],
  );

  const handleRun = () => {
    const resolutionMap = {
      "1920x1080": { width: 1920, height: 1080 },
      "1440x780": { width: 1440, height: 780 },
      "1366X768": { width: 1366, height: 768 },
      "1280X780": { width: 1280, height: 780 },
    };
    const resolution = resolutionMap[selectedResolution] || {};

    const payload = {
      prodid: suite.productid,
      suitid: suite.idtest_suite,
      envname: suite.envUrl,
      envUrl: "", // Add logic to retrieve the environment URL if necessary
      browser: suite.browser,
      stop: suite.stopafterfailure,
      rerun: suite.rerunfailedtests,
      runparallel: suite.supports_parallel_execution,
      ssfail: suite.ssonerror,
      indEmail: suite.indEmail,
      inputemail: suite.resultsemail,
      height: resolution.height || "",
      width: resolution.width || "",
      iterations: iterations || "1",
      proxy: proxyUrl,
    };
    handleExecuteTestSuite(payload);
  };

  const tabs = [
    {
      icon: <></>,
      label: "Run Parameters",
      content: (
        <>
          <Box p={2} display={"flex"}>
            <Box flex={1}>
              <Box>
                <StyledLabel>Environment</StyledLabel>
                <Box>
                  {/* {EnvOptions.map((option) => (
                    <PillRadio
                      key={option.value}
                      value={option.value}
                      label={option.label}
                      checked={selectedEnv === option.value}
                      onChange={(event) => setSelectedEnv(event.target.value)}
                    />
                  ))} */}
                  {envData?.map((option) => (
                    <PillRadio
                      key={option.envurl}
                      value={option.envurl}
                      label={option.envname}
                      checked={suite.envurl === option.envurl}
                      onChange={(event) => {
                        dispatch(updateSuiteField({ field: "envurl", value: event.target.value }));
                        udpateSuiteUrl({
                          data: { url: event.target.value },
                          id: suite.idtest_suite,
                        });
                      }}
                    />
                  ))}
                </Box>
              </Box>
              <Box pt={2}>
                <StyledLabel>Screen Resolution</StyledLabel>
                <Box>
                  {ResolutionOptions.map((option) => (
                    <PillRadio
                      key={option.value}
                      value={option.value}
                      label={option.label}
                      checked={selectedResolution === option.value}
                      onChange={(event) => setSelectedResolution(event.target.value)}
                    />
                  ))}
                </Box>
              </Box>
              <Box pt={2}>
                <StyledLabel>Browser</StyledLabel>
                <Box>
                  {BrowserOptions.map((option) => (
                    <PillRadio
                      key={option.value}
                      value={option.value}
                      label={option.label}
                      checked={suite?.browser === option.value}
                      onChange={(event) => {
                        dispatch(updateSuiteField({ field: "browser", value: event.target.value }));
                        refetchWithParams(suite.idtest_suite, event.target.value);
                      }}
                    />
                  ))}
                </Box>
              </Box>
            </Box>
            <Box flex={1} p={2}>
              <FormGroup sx={{ display: "flex", rowGap: 2 }}>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={suite.stopafterfailure === "true"}
                      onChange={() => {
                        const newValue = !stringToBoolean(suite.stopafterfailure);

                        dispatch(
                          updateSuiteField({
                            field: "stopafterfailure",
                            value: newValue.toString(),
                          }),
                        );

                        updateStopAfterFail(suite.idtest_suite, newValue);
                      }}
                    />
                  }
                  label="Terminate test even if a single test case fails"
                />
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={suite.rerunfailedtests === "true"}
                      onChange={() => {
                        const newValue = !stringToBoolean(suite.rerunfailedtests);

                        dispatch(
                          updateSuiteField({
                            field: "rerunfailedtests",
                            value: newValue.toString(),
                          }),
                        );

                        updateRerun(suite.idtest_suite, newValue);
                      }}
                    />
                  }
                  label="Re-run failed test cases"
                />
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={suite.supports_parallel_execution === "true"}
                      onChange={() => {
                        const newValue = !stringToBoolean(suite.supports_parallel_execution);

                        dispatch(
                          updateSuiteField({
                            field: "supports_parallel_execution",
                            value: newValue.toString(),
                          }),
                        );

                        updateParallel(suite.idtest_suite, newValue);
                      }}
                    />
                  }
                  label="Runs Tests in Parallel"
                />
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={suite.synchedscenarios ? true : false}
                      onChange={() => {
                        dispatch(
                          updateSuiteField({
                            field: "synchedscenarios",
                            value: !suite.synchedscenarios,
                          }),
                        );
                        updateSync(suite.idtest_suite, !suite.synchedscenarios);
                      }}
                    />
                  }
                  label="Synchronized Tests"
                />
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={suite.headless ? true : false}
                      onChange={() => {
                        dispatch(
                          updateSuiteField({
                            field: "headless",
                            value: !suite.headless,
                          }),
                        );
                        udpateHeadless(suite.idtest_suite, !suite.headless);
                      }}
                    />
                  }
                  label="Headless Execution"
                />
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={suite.ssonerror}
                      onChange={() => {
                        dispatch(
                          updateSuiteField({
                            field: "ssonerror",
                            value: !suite.ssonerror,
                          }),
                        );
                        updateScreenShot(suite.idtest_suite, !suite.ssonerror);
                      }}
                    />
                  }
                  label="Capture screenshots only on test failure"
                />
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={suite.indEmail}
                      onChange={() => {
                        dispatch(
                          updateSuiteField({
                            field: "indEmail",
                            value: !suite.indEmail,
                          }),
                        );
                        updateResultEmail(suite.idtest_suite, !suite.indEmail);
                      }}
                    />
                  }
                  label="Email individual test result"
                />
              </FormGroup>
            </Box>
          </Box>
          <Box>
            <CustomInput
              labelinput="Proxy Url"
              placeholder={"Enter proxy Url"}
              value={proxyUrl}
              onChange={(e) => setProxyUrl(e.target.value)}
            />
            <Box display="flex" pt={2} columnGap={2}>
              <CustomInput
                labelinput="Iterations"
                placeholder={"Enter iterations"}
                value={iterations}
                onChange={(e) => setIterations(e.target.value)}
              />
              <CustomInput
                labelinput="Email to receive reports "
                placeholder={"Enter email"}
                value={suite?.resultsemail}
                onChange={(event) => {
                  dispatch(updateSuiteField({ field: "resultsemail", value: event.target.value }));
                  debouncedUpdateEmail(event.target.value);
                }}
                flex={1}
              />
            </Box>
          </Box>
        </>
      ),
    },
    {
      icon: <></>,
      label: "Integration",
      content: (
        <Box py={2}>
          <Box mb={2}>
            <StyledLabel>Integrations</StyledLabel>
            <Box>
              {IntegrationOptions.map((option) => (
                <PillRadio
                  key={option.value}
                  value={option.value}
                  label={option.label}
                  checked={selectedIntegration === option.value}
                  onChange={(event) => setSelectedIntegration(event.target.value)}
                />
              ))}
            </Box>
          </Box>
          <CustomInput
            labelinput="Suite Execution URL"
            placeholder={"Enter Suite Execution URL"}
            value={exceUrl}
          />
        </Box>
      ),
    },
    {
      icon: <></>,
      label: "Scheduler",
      content: (
        <Box py={2}>
          <Box mb={2}>
            <Scheduler suiteId={suite.idtest_suite} />
          </Box>
        </Box>
      ),
    },
  ];

  return (
    <>
      <CustomDialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenSuiteSettings(false)}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          sx: {
            width: "900px",
            maxWidth: "900px",
          },
        }}
      >
        <PageTitle title="Settings" variant="h5" />
        <DialogContent sx={{ width: "100%", px: 0 }}>
          <TabComponent tabs={tabs} />
        </DialogContent>
        <DialogActions sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
          <StyledButton
            sx={{ color: "#ff5336" }}
            variant="text"
            color="warning"
            onClick={() => setOpenSuiteSettings(false)}
          >
            Cancel
          </StyledButton>
          <StyledButton
            variant="text"
            color="warning"
            sx={{
              backgroundColor: "#ff5336",
              color: "#ffffff",
              boxShadow: "0px 16px 30.6px 0px rgba(255, 83, 54, 0.27)",
            }}
            onClick={() => {
              handleRun();
            }}
          >
            Run
          </StyledButton>
        </DialogActions>
      </CustomDialog>
    </>
  );
}
