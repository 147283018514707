import React from 'react';

const ReplaceStepsIcon = ({ width = 406, height = 307, fill = "currentColor" }) => (
  <svg width="394" height="360" viewBox="0 0 394 360" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M298.823 358.861C298.185 359.493 297.159 359.493 296.521 358.861L234.646 297.633C233.998 296.993 233.998 295.948 234.646 295.308L296.778 233.825C297.419 233.19 298.453 233.195 299.09 233.835L311.765 246.602C312.402 247.243 312.397 248.281 311.754 248.917L277.228 283.083C276.189 284.11 276.917 285.883 278.378 285.883H370.938C371.841 285.883 372.574 285.149 372.574 284.246V181.636C372.574 180.733 373.307 180 374.21 180H392.338C393.242 180 393.974 180.733 393.974 181.636V305.422C393.974 306.326 393.242 307.059 392.338 307.059H278.398C276.933 307.059 276.207 308.836 277.252 309.862L311.736 343.749C312.387 344.389 312.389 345.436 311.741 346.079L298.823 358.861Z" fill="#242F3F"/>
<path d="M95.1523 1.13811C95.7898 0.507347 96.8161 0.507347 97.4535 1.13811L159.329 62.3657C159.977 63.0061 159.977 64.0518 159.329 64.6922L97.1963 126.175C96.5548 126.809 95.5207 126.805 94.8848 126.164L82.2099 113.398C81.5725 112.756 81.5771 111.718 82.2202 111.082L116.747 76.9169C117.786 75.8886 117.058 74.1172 115.596 74.1172H23.0366C22.1332 74.1172 21.4006 74.8498 21.4006 75.7536V178.363C21.4006 179.266 20.6681 180 19.7646 180H1.636C0.732462 180 -8.18e-08 179.266 0 178.363L1.08136e-05 54.5771C1.08954e-05 53.6733 0.732473 52.9407 1.63601 52.9407H115.576C117.041 52.9407 117.768 51.1636 116.723 50.1371L82.2387 16.251C81.5877 15.6113 81.5859 14.5625 82.2347 13.9205L95.1523 1.13811Z" fill="#242F3F"/>
<path d="M16.3639 216.68H187.923C192.815 216.68 196.783 220.646 196.783 225.543V343.627C196.783 348.524 192.815 352.491 187.923 352.491H16.3639C11.4722 352.491 7.50391 348.524 7.50391 343.627V225.543C7.50391 220.646 11.4722 216.68 16.3639 216.68Z" stroke="#242F3F" stroke-width="15"/>
<path d="M206.053 7.5H377.612C382.504 7.5 386.472 11.4668 386.472 16.3636V134.447C386.472 139.344 382.504 143.311 377.612 143.311H206.053C201.162 143.311 197.193 139.344 197.193 134.447V16.3636C197.193 11.4668 201.162 7.5 206.053 7.5Z" stroke="#242F3F" stroke-width="15"/>
</svg>

);

export default ReplaceStepsIcon;


