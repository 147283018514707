import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const ProfilePage = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetail") || "{}");

  const ConfigurationDetail = ({ label, value, unit = "" }) => {
    return (
      <div className="flex flex-col gap-4">
        <p className="text-white urbanist-font font-bold text-[16px] leading-5">{label}</p>
        <div className="flex items-center justify-start bg-[#262626] rounded-lg p-4">
          <p className="text-white urbanist-font font-medium text-[18px] leading-5">
            {value} {unit}
          </p>
        </div>
      </div>
    );
  };

  const generateChartData = () => {
    return [
      { name: "June", tokens: 20 },
      { name: "July", tokens: 43 },
      { name: "August", tokens: 105 },
      { name: "September", tokens: 43 },
      { name: "October", tokens: 20 },
    ];
  };

  const tokenAnalysisData = () => {
    return [
      { name: "June", tokens: 13 },
      { name: "July", tokens: 42 },
      { name: "August", tokens: 20 },
      { name: "September", tokens: 5 },
      { name: "October", tokens: 52 },
    ];
  };
  return (
    <div className="bg-[#151515] min-h-screen p-8 ml-[320px] ">
      <div className="top">
        <div className="">
          <h1 className="urbanist-font font-medium text-[24px] leading-7 text-white">
            Welcome ! {userDetails.firstname}
          </h1>
          <p className="urbanist-font font-light text-[16px] text-[#ADA7A7] leading-5 mt-5">
            {new Date().toLocaleDateString("en-US", {
              weekday: "short",
              day: "2-digit",
              month: "short",
              year: "numeric",
            })}
          </p>
        </div>
      </div>
      <div className="middle w-full flex flex-row items-center justify-between  mt-10">
        <div className="left flex flex-row items-center gap-6">
          <div className="profile flex items-center justify-center bg-gray-700 w-[100px] h-[100px] rounded-full">
            <span className="text-white text-[40px] font-bold">
              {userDetails.company.companyname.charAt(0).toUpperCase()}
            </span>
          </div>
          <div className="profile-details flex flex-col">
            <p className="text-white urbanist-font font-medium text-[20px] leading-6">
              {userDetails.company.companyname.charAt(0).toUpperCase()}
              {userDetails.company.companyname.slice(1).toLowerCase()}
              <span className="ml-4 mr-2">|</span>
              <span className="text-[#ADA7A7] text-[16px] urbanist-font">Company Name</span>
            </p>
            <p className="text-[#ADA7A7] urbanist-font font-normal text-[16px] leading-5 mt-3">
              {userDetails.email}
              <span className="ml-4 mr-2 text-white text-[20px]">|</span>
              <span className="text-[#ADA7A7] text-[16px] urbanist-font">Contact Person</span>
            </p>
          </div>
        </div>
        <div className="right">
          <button
            className="h-[44px] w-[109px] flex items-center justify-center rounded-lg text-white urbanist-font bg-[#2651D1] bg-opacity-20"
            onClick={() => {}}
          >
            Edit
          </button>
        </div>
      </div>
      <div className="bottom mt-6">
        <h1 className="text-white urbanist-font font-medium text-[24px] leading-7">
          Company Configuration
        </h1>
        <hr className="mt-6 border-[0.8px] border-[#747474]" />

        <div className="configuration grid grid-cols-1 lg:grid-cols-2 gap-6 mt-6">
          <ConfigurationDetail
            label="Available Credits"
            value={userDetails?.company?.plan?.available_credits}
            unit="Credits"
          />
          <ConfigurationDetail
            label="Current Plan"
            value={userDetails?.company?.plan?.planname}
            unit=""
          />
        </div>
      </div>
      <div className="flex flex-row items-center w-full justify-between">
        <div className="bottom mt-6 w-full">
          <h1 className="text-white urbanist-font font-medium text-[24px] leading-7">
            Token Usage
          </h1>
          <hr className="mt-6 border-[0.8px] border-[#747474]" />
          <div className="chart-container mt-6">
            <ResponsiveContainer width="100%" height={400}>
              <AreaChart data={generateChartData()}>
                <XAxis dataKey="name" />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="tokens"
                  stroke="#8884d8"
                  fillOpacity={1}
                  fill="url(#colorUv)"
                />
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="1" x2="0" y2="0">
                    <stop offset="0%" stopColor="#6E38E0" />
                    <stop offset="100%" stopColor="#FF5F36" />
                  </linearGradient>
                </defs>
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="bottom mt-6 w-full">
          <h1 className="text-white urbanist-font font-medium text-[24px] leading-7 ml-6">
            Token Usage Analysis
          </h1>
          <hr className="mt-6 border-[0.8px] border-[#747474]" />
          <div className="chart-container mt-6">
            <ResponsiveContainer width="100%" height={400}>
              <AreaChart data={tokenAnalysisData()}>
                <XAxis dataKey="name" />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="tokens"
                  stroke="#8884d8"
                  fillOpacity={1}
                  fill="url(#colorUv)"
                />
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="1" x2="0" y2="0">
                    <stop offset="0%" stopColor="#6E38E0" />
                    <stop offset="100%" stopColor="#FF5F36" />
                  </linearGradient>
                </defs>
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
