import React, { useEffect, useRef, useState } from "react";
import { Box, Button, styled, TextField, Typography } from "@mui/material";
import { CustomInput, CustomSelect, EllipsisTooltipText } from "../../components-new/common";
import { CognitiveSrc, SuiteOptions } from "./constants";
import {
  useProductQuery,
  useRepoQuery,
  useTCFromCommentsMutation,
  useTCTagsQuery,
} from "../../useQuery";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import useGetTestSuitesQuery from "../../useQuery/useGetTestSuitesQuery";
import { useItemWidth } from "../../hooks";
import { useNavigate } from "react-router-dom";

dayjs.extend(utc);

const StyledButton = styled(Button)`
  padding: 15px 28px;
  border-radius: 8px;
  text-transform: capitalize;
  height: 50px;
`;

const StyledLabel = styled(Typography)(() => ({
  color: "#8089A8",
  fontSize: "13px",
  fontWeight: 500,
}));

const StyledName = styled(Typography)(() => ({
  color: "#2B3539",
  fontSize: "18px",
  fontWeight: 500,
  marginTop: "13px",
}));

const formatDateToGMT = (date) => {
  return dayjs(date).utc().format("ddd, DD MMM YYYY HH:mm:ss [GMT]");
};

export default function IAComments() {
  const containerRef = useRef(null);
  const gap = 16;
  const itemWidth = useItemWidth(containerRef, 5, gap);
  const navigate = useNavigate();

  const [repo, setRepo] = useState(null);
  const [cognitive, setCognitive] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [productId, setProductId] = useState(null);
  const [beforeDate, setBeforeDate] = useState(null);
  const [suiteOption, setSuiteOption] = useState(null);
  const [suiteId, setSuiteId] = useState(null);
  const [newSuiteName, setNewSuiteName] = useState("");
  const [isEnable, setIsEnable] = useState(true);
  const [showImpScenarios, setShowImpScenarios] = useState(false);
  const [comment, setComment] = useState(null);
  const [showImpOptions, setShowImpOptions] = useState(false);

  const { data: products } = useProductQuery();
  const { refetchWithParams: fetchRepos, data: repos } = useRepoQuery();
  const { refetchWithParams: fetchTCTags, data: comments, isSuccess } = useTCTagsQuery();
  const {
    refetchWithParams: fetchTestSuites,
    data: testSuites,
    isPending,
  } = useGetTestSuitesQuery();
  const { mutate: postTCData, data: TCData } = useTCFromCommentsMutation();

  useEffect(() => {
    if (!productId) return;
    fetchRepos(productId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  useEffect(() => {
    if (!TCData) return;

    fetchTestSuites(productId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TCData]);

  useEffect(() => {
    if (!suiteOption) return;
    if (!productId) return;
    if (suiteOption !== SuiteOptions[1].value) return;

    fetchTestSuites(productId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suiteOption, productId]);

  const handleSubmit = () => {
    fetchTCTags({
      productId: productId,
      repo: repo,
      fromDate: formatDateToGMT(fromDate),
      toDate: formatDateToGMT(beforeDate),
      cognitive: cognitive,
    });
    setShowImpOptions(true);
  };

  useEffect(() => {
    if (!testSuites) return;

    console.log(testSuites?.flat(), "ssea");
  }, [testSuites]);

  const handleGetImpactedScenarios = () => {
    setShowImpScenarios(true);

    if (suiteOption === SuiteOptions[0].value) {
      postTCData({
        data: comments,
        params: {
          product: productId,
          suiteOption: suiteOption,
          newTestSuite: newSuiteName,
        },
      });
      return;
    }

    if (suiteOption === SuiteOptions[1].value) {
      postTCData({
        data: comments,
        params: {
          product: productId,
          suiteOption: suiteOption,
          suiteId: suiteId,
        },
      });
      return;
    }

    if (suiteOption === SuiteOptions[2].value) {
      postTCData({
        data: comments,
        params: {
          product: productId,
          suiteOption: suiteOption,
        },
      });
      return;
    }
  };

  const handleViewSuite = () => {
    navigate("/new/test-suites", {
      state: {
        prodId: productId,
        suiteId: suiteId,
      },
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {!showImpScenarios && (
        <>
          <Box display="flex" alignItems="center" columnGap={2} justifyContent="space-between">
            <Box my={4} display="flex" columnGap={2}>
              <CustomSelect
                label={"Product"}
                options={(Array.isArray(products) ? products : []).map((prodItem) => ({
                  label: prodItem.productname,
                  value: prodItem.idproducts,
                }))}
                width={"230px"}
                background={"#ffffff"}
                value={productId || ""}
                onChange={(e) => {
                  setProductId(e.target.value);
                  setRepo(null);
                }}
              />
              <CustomSelect
                label={"Cognitive Source"}
                options={CognitiveSrc.map((item) => ({
                  label: item.label,
                  value: item.value,
                }))}
                width={"230px"}
                background={"#ffffff"}
                value={cognitive}
                onChange={(e) => {
                  setCognitive(e.target.value);
                }}
              />
              <CustomSelect
                label={"Repos"}
                options={(Array.isArray(repos) ? repos : []).map((repo) => ({
                  label: repo,
                  value: repo,
                }))}
                width={"230px"}
                background={"#ffffff"}
                value={repo}
                onChange={(e) => {
                  setRepo(e.target.value);
                }}
              />
            </Box>
            <Box display="flex" columnGap={2} alignItems="flex-end">
              <Box>
                <StyledLabel>From Date</StyledLabel>
                <DatePicker
                  value={fromDate}
                  onChange={(newValue) => setFromDate(dayjs(newValue))}
                  sx={{ background: "#ffffff" }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
              <Box>
                <StyledLabel>Before Date</StyledLabel>
                <DatePicker
                  value={beforeDate}
                  onChange={(newValue) => setBeforeDate(dayjs(newValue))}
                  sx={{ background: "#ffffff" }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
              <StyledButton
                disabled={!productId || !repo || !cognitive || !fromDate || !beforeDate}
                variant="contained"
                color="warning"
                onClick={handleSubmit}
                sx={{
                  backgroundColor: "#ff5336",
                  boxShadow: "0px 16px 30.6px 0px rgba(255, 83, 54, 0.27)",
                }}
              >
                Submit
              </StyledButton>
            </Box>
          </Box>
          {showImpOptions && comments?.length > 0 && (
            <Box display="flex" columnGap={2} mb={4} alignItems={"flex-end"}>
              <CustomSelect
                label={"Suite Option"}
                options={SuiteOptions.map((item) => ({
                  label: item.label,
                  value: item.value,
                }))}
                width={"230px"}
                background={"#ffffff"}
                value={suiteOption}
                onChange={(e) => {
                  setSuiteOption(e.target.value);
                  if (e.target.value === SuiteOptions[2].value) {
                    setIsEnable(false);
                  } else {
                    setIsEnable(true);
                  }
                }}
              />
              {suiteOption === SuiteOptions[0].value && (
                <CustomInput
                  labelinput="New Suite Name"
                  placeholder={"Enter Suite Name"}
                  value={newSuiteName}
                  onChange={(e) => {
                    setNewSuiteName(e.target.value);
                    if (e.target.value.length > 0) {
                      setIsEnable(false);
                    } else {
                      setIsEnable(true);
                    }
                  }}
                  name={"NewSuiteName"}
                />
              )}
              {suiteOption === SuiteOptions[1].value && (
                <CustomSelect
                  label={"Test Suite"}
                  options={(testSuites?.flat() ?? []).map((item) => ({
                    label: item.Test_Suite,
                    value: item.idtest_suite,
                  }))}
                  width={"230px"}
                  background={"#ffffff"}
                  value={suiteId}
                  onChange={(e) => {
                    setSuiteId(e.target.value);
                    setIsEnable(false);
                  }}
                />
              )}
              <StyledButton
                disabled={isEnable}
                variant="contained"
                color="warning"
                onClick={handleGetImpactedScenarios}
                sx={{
                  backgroundColor: "#ff5336",
                  boxShadow: "0px 16px 30.6px 0px rgba(255, 83, 54, 0.27)",
                }}
              >
                Get Impacted Scenarios
              </StyledButton>
            </Box>
          )}
          <Box
            p={"30px"}
            backgroundColor="#ffffff"
            boxShadow={"0px 43px 62.4px 0px rgba(0, 0, 0, 0.16)"}
            minHeight={"600px"}
            maxHeight={"600px"}
            overflow="scroll"
          >
            <Typography mb={2} color="#2B3539" fontSize={"24px"} fontWeight={500}>
              All Comments
            </Typography>
            {isSuccess && !comments?.length && (
              <Typography mb={2} color="#2B3539" fontSize={"24px"} fontWeight={500}>
                No data available
              </Typography>
            )}

            {repo &&
              (comments ?? []).map((comment, index) => (
                <Typography color="#2B3539" fontSize={"16px"} fontWeight={400} lineHeight={"26px"}>
                  {index}:{comment}
                </Typography>
              ))}
          </Box>
        </>
      )}
      {showImpScenarios && (
        <>
          <Box
            display="flex"
            alignItems="center"
            columnGap={2}
            justifyContent="space-between"
            mt={4}
          >
            <Box display="flex" columnGap={2}>
              <Box>
                <StyledLabel>Product</StyledLabel>
                <StyledName>
                  {(products ?? []).find((product) => product.idproducts === productId).productname}
                </StyledName>
              </Box>
              <Box>
                <StyledLabel>Cognitive Source</StyledLabel>
                <StyledName>{cognitive}</StyledName>
              </Box>
              <Box>
                <StyledLabel>Repos</StyledLabel>
                <StyledName>{repo}</StyledName>
              </Box>
            </Box>
            <Box display="flex" columnGap={2}>
              <Box>
                <StyledLabel>From Date</StyledLabel>
                <StyledName>{dayjs(fromDate).format("DD/MM/YYYY")}</StyledName>
              </Box>
              <Box>
                <StyledLabel>To Date</StyledLabel>
                <StyledName>{dayjs(beforeDate).format("DD/MM/YYYY")}</StyledName>
              </Box>
            </Box>
          </Box>
          <Box display="flex" alignItems="flex-end" columnGap={2} mt={4}>
            <CustomSelect
              label={"All Impacted Scenarios for "}
              options={comments.map((comment) => ({
                label: comment,
                value: comment,
              }))}
              width={"230px"}
              background={"#ffffff"}
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
              }}
              isEllipse
            />
            <StyledButton
              variant="contained"
              color="warning"
              onClick={() => setShowImpScenarios(false)}
              sx={{
                backgroundColor: "#ff5336",
                boxShadow: "0px 16px 30.6px 0px rgba(255, 83, 54, 0.27)",
              }}
            >
              View All comments
            </StyledButton>
            <StyledButton
              variant="contained"
              color="warning"
              onClick={handleViewSuite}
              sx={{
                backgroundColor: "#ff5336",
                boxShadow: "0px 16px 30.6px 0px rgba(255, 83, 54, 0.27)",
              }}
            >
              View Suite
            </StyledButton>
          </Box>
          {TCData && !isPending && (
            <Box
              display="flex"
              mt={4}
              columnGap={2}
              rowGap={2}
              flexWrap={"wrap"}
              ref={containerRef}
            >
              {console.log(testSuites)}
              {testSuites
                .flat()
                .filter((item) => item.idtest_suite === TCData[0].idtest_suite)
                .flatMap((item) => item.list)
                .map((suite) =>
                  Object.values(suite.testcases).map(
                    (testCase) =>
                      testCase.Test_Case_Desc && (
                        <Box
                          width={itemWidth}
                          p="20px"
                          bgcolor={"#ffffff"}
                          key={testCase.idtest_case}
                        >
                          {testCase.Test_Case}
                          <EllipsisTooltipText
                            text={`${testCase.Test_Case_Desc}`}
                            maxChars={20}
                            mode="chars"
                          />
                        </Box>
                      ),
                  ),
                )}
            </Box>
          )}
        </>
      )}
    </LocalizationProvider>
  );
}
