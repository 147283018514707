import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, styled, Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import SyncDisabledIcon from "@mui/icons-material/SyncDisabled";

import { EditHeader, Settings, StudioSelection, TSMenu } from "./Studio";
import {
  useTestCaseResultsQuery,
  useRecentTestCaseResultsQuery,
  useTSRQuery,
  useAddStepQuery,
  useExecuteTCQuery,
  useProductQuery,
  useModuleQuery,
  useTestCaseQuery,
  useGetEnvQuery,
  useDeleteTestStepsMutation,
  useCreateTestCaseMutation,
  useMergeCopilotQuery,
} from "../useQuery";
import { ContextIcon, RefreshIcon, ExportIcon, VisiblityIcon } from "../components-new/icons";
import { CustomInput, IconContainer, PageTitle, TestItemCard } from "../components-new/common";
import EditTabs from "./Studio/EditTabs";
import {
  setEditItemIndex,
  setEditStep,
  setSelectedComponent,
  setSelectedProduct,
  setSelectedTestCase,
  setSelectedTestCaseItem,
  setTestCases,
  setTsrData,
} from "../Redux/TestAuthoring/TestAuthoring";
import useSaveStepQuery from "../useQuery/useSaveStepQuery";
import { useLocation, useNavigate } from "react-router-dom";
import { useItemWidth, useNotification } from "../hooks";
import CustomDialog from "../components-new/common/CustomDialog";
import { useQueryClient } from "@tanstack/react-query";

const StyledButton = styled(Button)`
  padding: 15px 28px;
  border-radius: 8px;
  text-transform: capitalize;
`;

const TestStudio = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const notify = useNotification();
  const queryClient = useQueryClient();
  const RouteState = useLocation();
  const containerRef = useRef(null);
  const itemsPerRow = 4;
  const gap = 32;
  const itemWidth = useItemWidth(containerRef, itemsPerRow, gap);

  const [refreshFlag, setRefreshFlag] = useState(true);
  const [editData, setEditData] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [open, setOpen] = useState(false);
  const [pageTitle, setPageTitle] = useState("");
  const [testCaseSearchKeyword, setTestCaseSearchKeyword] = useState("");
  const [message, setMessage] = useState("");
  const [checkedItems, setCheckedItems] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [testCaseName, setTestCaseName] = useState("");

  const selectedTestCase = useSelector((state) => state.authoringSlice.selectedTestCase);
  const selectedProduct = useSelector((state) => state.authoringSlice.selectedProduct);
  const selectedModule = useSelector((state) => state.authoringSlice.selectedComponent);
  const currentEditItemIndex = useSelector((state) => state.authoringSlice.editItemIndex);
  const [isProductSetInitially, setIsProductSetInitially] = useState(false);

  const { data: products, refetch: refetchProductQuery } = useProductQuery();
  const { data: modules, refetch: refetchModuleQuery } = useModuleQuery(selectedProduct, {
    enabled: selectedProduct !== "" && selectedProduct !== 0,
  });
  // eslint-disable-next-line no-unused-vars
  const { data: envDetails, refetch: refetchEnvQuery } = useGetEnvQuery(selectedProduct);

  const {
    data: testCases,
    isLoadingTestcase,
    refetch: refetchTestCases,
  } = useTestCaseQuery(selectedModule, testCaseSearchKeyword, {
    enabled: selectedModule !== "" && selectedModule !== 0,
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const {
    data: testCaseResults,
    isLoading,
    isSuccess,
  } = useTestCaseResultsQuery(selectedTestCase, {
    enabled: selectedTestCase !== "",
  });
  const { mutate: addStep, isSuccess: isAddSuccess } = useAddStepQuery();
  const { mutate: saveStep, isSuccess: isSaveSuccess } = useSaveStepQuery();
  const { mutate: executeTest, isSuccess: isExecuteSuccess } = useExecuteTCQuery();
  const { mutate: deleteTestSteps } = useDeleteTestStepsMutation();
  const { mutate: createTestCase } = useCreateTestCaseMutation();

  const { data: latestRecordings } = useRecentTestCaseResultsQuery({});
  const { data: tsrData } = useTSRQuery(selectedTestCase);

  useEffect(() => {
    const intervalId = setInterval(() => {
      queryClient.invalidateQueries(["latestRecordings"]);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [queryClient]);

  useEffect(() => {
    if (!pageTitle) return;

    setTestCaseSearchKeyword(pageTitle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedModule) refetchTestCases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedModule]);

  useEffect(() => {
    refetchProductQuery();
    if (RouteState?.state?.fromExecutionReports) {
      setRefreshFlag(false);
      dispatch(setSelectedProduct(RouteState?.state?.idproducts));
      dispatch(setSelectedComponent(RouteState?.state?.idmodules));
      dispatch(setSelectedTestCase(RouteState?.state?.idtest_case));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RouteState?.state, dispatch]);

  useEffect(() => {
    refetchModuleQuery();
  }, [refetchModuleQuery, selectedProduct]);

  useEffect(() => {
    setCheckedItems([]);
  }, [selectedTestCase]);

  useEffect(() => {
    if (isAddSuccess || isSaveSuccess) {
      setOpen(true);
      notify(message, "success");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaveSuccess, isAddSuccess]);

  useEffect(() => {
    dispatch(setEditStep(""));
    dispatch(setEditItemIndex(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (testCaseResults) dispatch(setTestCases(testCaseResults.data ?? []));
  }, [dispatch, testCaseResults]);

  useEffect(() => {
    dispatch(setTsrData(tsrData));
  }, [dispatch, tsrData]);

  useEffect(() => {
    if (currentEditItemIndex != null) {
      const currentEditItem = testCaseResults?.data[currentEditItemIndex];

      setEditData(currentEditItem);
      dispatch(setEditStep(currentEditItem));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEditItemIndex]);

  useEffect(() => {
    if (!selectedProduct && latestRecordings && !isProductSetInitially) {
      dispatch(setSelectedProduct(latestRecordings.Product));
      dispatch(setSelectedComponent(latestRecordings.Modules));
      dispatch(setSelectedTestCase(latestRecordings.Testcase));
      setPageTitle(latestRecordings?.testcase?.Test_Case);
      setIsProductSetInitially(true);
      setTestCaseSearchKeyword(latestRecordings.testcase.Test_Case);
    }
  }, [latestRecordings, dispatch, selectedProduct, isProductSetInitially]);

  useEffect(() => {
    if (isSuccess) {
      setPageTitle(testCaseResults.testcase.Test_Case);
      dispatch(setSelectedTestCaseItem(testCaseResults.testcase));
    }
  }, [dispatch, isSuccess, selectedProduct, testCaseResults]);

  useEffect(() => {
    if (isExecuteSuccess)
      navigate("/new/reports", {
        state: {
          isExecuteClick: true,
          fromTestStudio: true,
          idproducts: selectedProduct,
          idmodules: selectedModule,
          idtest_case: selectedTestCase,
          Test_Case: decodeURI(testCaseResults.testcase.Test_Case),
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExecuteSuccess]);
  const getNextNum = (testCaseResults, currentStepNum) => {
    if (!testCaseResults || !testCaseResults?.stepnums) return null;
    const { stepnums } = testCaseResults;
    const currentSeq = Object.entries(stepnums).find(
      ([seq, stepNum]) => stepNum === currentStepNum,
    )?.[0];
    const nextSeq = currentSeq ? String(Number(currentSeq) + 1) : null;
    return stepnums[nextSeq] ?? currentStepNum;
  };

  const handleEdit = (item) => {
    setRefreshFlag(false);
    const currenItemIndex = testCaseResults.data.findIndex(
      (testCaseItem) => testCaseItem.Step_Number === item.Step_Number,
    );
    dispatch(setEditItemIndex(currenItemIndex));
  };

  const nextNum = testCaseResults ? getNextNum(testCaseResults, editData?.Step_Number) : null;
  const data = {
    tcid: editData?.Test_Case_Id,
    stepNumber: editData?.Step_Number,
    nextStepNumber: nextNum,
    tcname: testCaseResults?.testcase?.Test_Case,
    envurl: encodeURIComponent(testCaseResults?.data[0]?.TestData),
  };

  const { mutate: modalExecute } = useMergeCopilotQuery(data, {});

  const handleMerge = () => {
    modalExecute(data);
    //close the menu
  };

  const handleExecuteTest = (isBaseline) => {
    const selectedItem = testCaseResults.testcase;
    const obj = {
      id: selectedItem.idtest_case,
      SelectEnvironmentName: selectedItem.envname,
      SelectEnvironmentUrl: selectedItem.envurl,
      SelectBrowserName: selectedItem.browser,
      SelectResolution: selectedItem.SelectResolution,
      SelectRunonce: selectedItem.multirun,
      TcThreshold: selectedItem.testcasethreshold,
      testcase: selectedItem?.Test_Case,
      proxyurl: selectedItem.proxyurl,
      baseline: isBaseline,
    };
    executeTest(obj);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const addStepQuery = (payload) => {
    setMessage("Step added successfully");
    addStep(payload);
  };
  const saveStepQuery = (payload) => {
    setMessage("Step updated successfully");
    saveStep(payload);
  };

  const handleCheckboxChange = (e, item) => {
    if (e.target.checked) {
      setCheckedItems((prev) => [...prev, item]);
    } else {
      setCheckedItems((prev) => prev.filter((testStep) => testStep !== item));
    }
  };

  const handleDeleteTestSteps = () => {
    if (!checkedItems.length) {
      notify("Please select steps to delete...", "warning");
      return;
    } else {
      setShowDeleteModal(true);
    }
  };

  const handleCreateTestCase = () => {
    if (!checkedItems.length) {
      notify("Please select steps to create...", "warning");
      return;
    } else {
      setShowCreateModal(true);
    }
  };

  const DeleteAction = () => {
    return (
      <>
        <Button
          sx={{ color: "#ff5336" }}
          onClick={() => {
            setCheckedItems([]);
            setShowDeleteModal(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{ background: "#ff5336" }}
          onClick={() => {
            deleteTestSteps({
              data: checkedItems.map((item) => item.idtest_step),
              params: {
                testCaseId: selectedTestCase,
              },
            });
            setCheckedItems([]);
            setShowDeleteModal(false);
          }}
        >
          Delete
        </Button>
      </>
    );
  };

  const CreateAction = () => {
    return (
      <>
        <Button
          sx={{ color: "#ff5336" }}
          onClick={() => {
            setCheckedItems([]);
            setShowCreateModal(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{ background: "#ff5336" }}
          onClick={() => {
            createTestCase({
              data: {
                testCaseName: testCaseName,
                testSteps: checkedItems,
              },
            });
            setCheckedItems([]);
            setShowCreateModal(false);
          }}
        >
          Create
        </Button>
      </>
    );
  };

  return (
    <Box sx={{ py: 4 }}>
      <CustomDialog
        title={"Are you sure you want to delete selected test steps?"}
        open={showDeleteModal}
        actions={<DeleteAction />}
      />
      <CustomDialog
        title={"Create Test Case"}
        open={showCreateModal}
        actions={<CreateAction />}
        content={
          <Box>
            <CustomInput
              labelinput="Test Case Name"
              placeholder="Enter test case name"
              onChange={(e) => setTestCaseName(e.target.value)}
              value={testCaseName}
            />
          </Box>
        }
      />
      {currentEditItemIndex === null && (
        <>
          <Box mb={2} display="flex" alignItems="center">
            <PageTitle title={pageTitle} variant="h4" />
            <Box
              display={"flex"}
              columnGap={"1rem"}
              alignItems="center"
              flex={1}
              justifyContent="flex-end"
            >
              <Settings />
              <Box>
                {refreshFlag ? (
                  <IconContainer
                    handleClick={() => {
                      setRefreshFlag(false);
                      clearInterval(window.ExecuteTimer);
                    }}
                  >
                    <SyncDisabledIcon />
                  </IconContainer>
                ) : (
                  <IconContainer
                    handleClick={() => {
                      setRefreshFlag(true);
                    }}
                  >
                    <RefreshIcon />
                  </IconContainer>
                )}
              </Box>

              <TSMenu
                handleExecuteTest={handleExecuteTest}
                anchorEl={anchorEl}
                handleClose={handleMenuClose}
                setTestCaseSearchKeyword={setTestCaseSearchKeyword}
                handleDeleteTestSteps={handleDeleteTestSteps}
                handleCreateTestCase={handleCreateTestCase}
              />
              <Box onClick={handleMenuClick}>
                <IconContainer>
                  <ContextIcon />
                </IconContainer>
              </Box>
              <StyledButton
                disabled={!selectedTestCase}
                variant="contained"
                endIcon={<VisiblityIcon />}
                onClick={() => {
                  navigate("/new/reports", {
                    state: {
                      fromTestStudio: true,
                      idproducts: selectedProduct,
                      idmodules: selectedModule,
                      idtest_case: selectedTestCase,
                      Test_Case: testCaseResults.testcase.Test_Case,
                    },
                  });
                }}
                sx={{
                  backgroundColor: "#2550d1",
                  boxShadow: "0px 16px 30.6px 0px rgba(37, 80, 209, 0.27)",
                  width: "229px",
                }}
              >
                Execution Reports
              </StyledButton>
              <StyledButton
                variant="contained"
                color="warning"
                onClick={() => handleExecuteTest(false)}
                endIcon={<ExportIcon />}
                sx={{
                  backgroundColor: "#ff5336",
                  boxShadow: "0px 16px 30.6px 0px rgba(255, 83, 54, 0.27)",
                }}
              >
                Run
              </StyledButton>
            </Box>
          </Box>
          <Box display="flex" justifyContent={"space-between"} alignItems="center">
            <Box display="flex" columnGap={3} alignItems="center">
              <StudioSelection
                refetchTestCases={refetchTestCases}
                products={products}
                modules={modules}
                testCases={testCases}
                isLoadingTestcase={isLoadingTestcase}
                setTestCaseSearchKeyword={setTestCaseSearchKeyword}
                testCaseSearchKeyword={testCaseSearchKeyword}
                setRefreshFlag={setRefreshFlag}
              />
            </Box>
          </Box>
          {isLoading ? <CircularProgress /> : null}
          <Box ref={containerRef} display="flex" columnGap={4} flexWrap="wrap" rowGap={4} mt={3}>
            {selectedTestCase && testCaseResults ? (
              testCaseResults.data.map((result, index) => (
                <TestItemCard
                  item={result}
                  index={index}
                  key={result.idtest_step}
                  handleEdit={handleEdit}
                  width={itemWidth}
                  isCheckbox
                  handleCheckboxChange={(e, item) => handleCheckboxChange(e, item)}
                  checkedItems={checkedItems}
                />
              ))
            ) : (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                height={"300px"}
                width={"100%"}
              >
                No data available
              </Box>
            )}
          </Box>
        </>
      )}
      {currentEditItemIndex !== null && (
        <>
          <EditHeader
            editItem={editData}
            handleAddStep={addStepQuery}
            handleSaveStep={saveStepQuery}
            handleMerge={handleMerge}
          />
          <EditTabs editItem={editData} />
        </>
      )}
    </Box>
  );
};

export default TestStudio;
