import axios from "axios";

import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "./../Config/Config";

export const fetchProducts = async (api_input) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);

  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=searchProdDropDown&companyid=${temp.companyid}&token=${temp.token}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
}; //to get the product list
export const ExecuteTestSuites = async (
  id,
  suitid,
  envname,
  envUrl,
  browser,
  stop,
  rerun,
  runparallel,
  ssfail,
  indEmail,
  inputemail,
  height,
  width,
  tsrId,
  iterations,
  proxy,
  statustype,
  statusTypeValueExecute,
  buildtag
) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);

  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=ExecuteTestSuites&companyid=${
        temp.companyid
      }&token=${
        temp.token
      }&prodid=${id}&SuiteId=${suitid}&envName=${envname}&envUrl=${envUrl}&browsername=${browser}&height=${height}
      &width=${width}&RerunFailTest=${rerun}&StopTestAfterFailure=${stop}&RunsTestsinParallel=${runparallel}
      &resultsemail=${inputemail}&criteria=${statustype}&tsrid=${tsrId}&ssonerror=${ssfail}
      &iterations=${iterations}&proxy=${proxy}&indEmail=${indEmail}&buildtag=${buildtag}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
}; //exexuite working part
// export const ExecuteTestSuites = async (
//   id,
//   suitid,
//   env,
//   stop,
//   rerun,
//   runparallel,
//   email
// ) => {
//   const userDetails = localStorage.getItem("userDetail");
//   const temp = JSON.parse(userDetails);
//   const Response = await axios
//     .post(
//       `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=ExecuteTestSuites&companyid=${
//         temp.companyid
//       }&token=${
//         temp.token
//       }&prodid=${id}&SuiteId=${suitid}&envName=${env}&envUrl=${env}&browsername=${""}&resolution=${""}&RerunFailTest=${rerun}&StopTestAfterFailure=${stop}&
//       RunsTestsinParallel${runparallel}&resultsemail${email}`
//     )
//     .then((response) => {
//       return response;
//     })
//     .catch((error) => {
//       console.log(error.response.data.error);
//     });
//   return Response;
// }; //to add or delete test case form the test suite has error

export const fetchEnvironments = async (id) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getEnv&companyid=${temp.companyid}&token=${temp.token}&productid=${id}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
}; //to fetch the environmets of that product
export const fetchModules = async (id) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=searchModDropDown&companyid=${temp.companyid}&token=${temp.token}&prodid=${id}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
}; //fetch the modules and it needs productid
export const fetchSuiteCase = async (id) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getTestSuites&companyid=${temp.companyid}&token=${temp.token}&productid=${id}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
}; //fetch the suites and it needs productid
export const fetchTestCasesfromModule = async (id) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=searchTCDropDown&companyid=${temp.companyid}&token=${temp.token}&modid=${id}&pnum=1`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
}; //to get the test cases it need moduleid

export const Addordeletetestcase = async (id, action, selectedcases,pgno, index) => {
  console.log("selectedcases", selectedcases);
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .post(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=${action}&companyid=${temp.companyid}&token=${temp.token}&idtest_suite=${id}&pnum=${pgno}&index=${index}`,
      selectedcases
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
}; //to add or delete test case form the test suite
export const search = async (id, searchword,pnum) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=searchTCSearch&companyid=${temp.companyid}&token=${temp.token}&modid=${id}&key=${searchword}&pnum=${pnum}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
}; //for search
export const pagination = async (id, pgno) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=searchTCDropDown&companyid=${temp.companyid}&token=${temp.token}&modid=${id}&pnum=${pgno}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
}; //for nextpage and previous
export const userexpreince = async (id) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getData&companyid=${temp.companyid}&token=${temp.token}&productid=${id}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
}; //for nextpage and previous
export const urlupdate = async (id, newurl) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .post(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestSuiteUrl&companyid=${temp.companyid}&token=${temp.token}&tsuid=${id}`,
      { url: newurl }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};
export const resultsEmailupdate = async (id, newemail) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .post(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestSuiteResultsEmail&companyid=${temp.companyid}&token=${temp.token}&tsuid=${id}`,
      { email: newemail }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};
export const BuildTagupdate = async (id, buildtag) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .post(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestSuiteBuildTag&companyid=${temp.companyid}&token=${temp.token}&tsuid=${id}`,
      { buildtag: buildtag }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const browserUpdate = async (id, newBrowser) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestSuiteBrowser&companyid=${temp.companyid}&token=${temp.token}&tsuid=${id}&browser=${newBrowser}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};
export const resolutionUpdate = async (id, height, width) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestSuiteResolution&companyid=${temp.companyid}&token=${temp.token}&tsuid=${id}&height=${height}&width=${width}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};
export const stoptestafterfailUpdate = async (id, bool) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestSuiteStopAfterFailure&companyid=${temp.companyid}&token=${temp.token}&tsuid=${id}&value=${bool}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};
export const rerunfailtestUpdate = async (id, bool) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestSuiteRerunFailedTests&companyid=${temp.companyid}&token=${temp.token}&tsuid=${id}&value=${bool}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};
export const runstestsinparallelUpdate = async (id, bool) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestSuiteSupportsParallelExecution&companyid=${temp.companyid}&token=${temp.token}&tsuid=${id}&value=${bool}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const runssonerror = async (id, bool) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestSuiteSSOnerror&companyid=${temp.companyid}&token=${temp.token}&tsuid=${id}&value=${bool}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const runIndEmail = async (id, bool) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestSuiteIndEmail&companyid=${temp.companyid}&token=${temp.token}&tsuid=${id}&value=${bool}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const reorderTestcase = async (testid, suiteid, source, destination, index, destind, pageno) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=reorderTestcase&companyid=${temp.companyid}&token=${temp.token}&tcid=${testid}&tsuid=${suiteid}&source=${source}&destination=${destination}&index=${index}&destindex=${destind}&pnum=${pageno}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};
export const newSuite = async (prodid, name) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=createTestSuite&companyid=${temp.companyid}&token=${temp.token}&productid=${prodid}&tsuname=${name}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};
export const Deletesuite = async (suiteid,pgno) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=delTestSuites&companyid=${temp.companyid}&token=${temp.token}&idtest_suite=${suiteid}&pnum=${pgno}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};
export const editTestSuitename = async (id, name) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=editTestSuites&companyid=${temp.companyid}&token=${temp.token}&idtest_suite=${id}&newName=${name}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};
//Fetch licence key
export const fetchLicenceKey = async () => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getlicencekey&companyid=${temp.companyid}&token=${temp.token}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const TestSuitespagination = async (id, pgno) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=gettestsuiteonpage&companyid=${temp.companyid}&token=${temp.token}&idtest_suite=${id}&pnum=${pgno}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
}
export const SearchTestSuite = async (id,key,pnum) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=searchInSuite&companyid=${temp.companyid}&token=${temp.token}&suiteid=${id}&key=${key}&pnum=${pnum}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error)
    });
  return Response;
}
export const SearchAllTestSuites = async (id, key, pnum) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=searchInAllSuite&companyid=${temp.companyid}&token=${temp.token}&prodid=${id}&key=${key}&pnum=${pnum}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
}

export const GetTestCasesByTagsForProduct = async (id, key, pnum) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const apiKey = encodeURIComponent(key);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getTCbyTags&companyid=${temp.companyid}&token=${temp.token}&prodid=${id}&key=${apiKey}&pnum=${pnum}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
}

export const SubmitSchedular = async (id,date,time,frequency,dayname) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=suiteScheduler&companyid=${temp.companyid}&token=${temp.token}&suiteid=${id}&date=${date}&time=${time}&frequency=${frequency}&subfreq=${dayname}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
}
export const getSchedule = async (id) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getSchedule&companyid=${temp.companyid}&token=${temp.token}&suiteid=${id}`
    ).then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error)
    });
  return Response;
}
export const stopSchedular = async (id) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=deleteSchedule&companyid=${temp.companyid}&token=${temp.token}&suiteid=${id}`
    ).then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
}
export const AddModuleTestCase = async (modid,selectedcases) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .post(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=moveTestCases&companyid=${temp.companyid}&token=${temp.token}&modid=${modid}`,
      selectedcases
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};
export const SynchorinizedTests = async (id,synctoggle) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updatesync&companyid=${temp.companyid}&token=${temp.token}&suiteid=${id}&synctoggle=${synctoggle}`
    ).then((response) => {
      return response;
    }).catch((error) => {
      console.log(error);
    });
  return Response;
}
export const headlessExecutes = async (id,handlelesstoggle) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateheadless&companyid=${temp.companyid}&token=${temp.token}&suiteid=${id}&handlelesstoggle=${handlelesstoggle}`
    ).then((response) => {
      return response;
    }).catch((error) => {
      console.log(error);
    });
  return Response;
}
// export const sendSuitesWithExcuiteInfo = async (
//   ActionValue,
//   prodId,
//   suiteid,
//   SelectEnvironmentName,
//   SelectEnvironmentUrl,
//   SelectBrowserName,
//   SelectResolution,
//   RerunFailTest,
//   StopTestAfterFailue,
//   SuiteCaseList,
//   licenseKeyValue
// ) => {
//   const userDetails = localStorage.getItem("userDetail");
//   const temp = JSON.parse(userDetails);
//   let tokenValue;
//   if (licenseKeyValue !== null) {
//     tokenValue = licenseKeyValue;
//   } else {
//     tokenValue = temp.token;
//   }

//   const url = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=${ActionValue}&companyid=${temp.companyid}&token=${tokenValue}&prodid=${prodId}&SuiteId=${suiteid}&envName=${SelectEnvironmentName}&envUrl=${SelectEnvironmentUrl}&browsername=${SelectBrowserName}&resolution=${SelectResolution}&RerunFailTest=${RerunFailTest}&StopTestAfterFailure=${StopTestAfterFailue}`;
//   const urlOne = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=${ActionValue}&companyid=${temp.companyid}&token=${tokenValue}&prodid=${prodId}&SuiteId=${suiteid}`;
//   if (ActionValue === "DLExecuteSuite") {
//     return urlOne;
//   } else {
//     const Response = await axios
//       .post(url, SuiteCaseList)
//       .then((response) => {
//         return response;
//       })
//       .catch((error) => {
//         console.log(error.response.data.error);
//       });
//     return Response;
//   }
// };
