import React from "react";

const MoveIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
    <path
      d="M7.2 7.2V4.8L11.2 8L7.2 11.2V8.8H0V7.2H7.2ZM1.1664 10.4H2.8656C3.42404 11.7753 4.44297 12.9138 5.74809 13.6208C7.05322 14.3277 8.56345 14.5593 10.0204 14.2758C11.4774 13.9923 12.7906 13.2114 13.7355 12.0666C14.6803 10.9218 15.198 9.48431 15.2 8C15.2008 6.51413 14.6846 5.07427 13.7399 3.92738C12.7952 2.78049 11.4809 1.99805 10.0224 1.71425C8.56386 1.43045 7.05204 1.66299 5.74622 2.37198C4.44041 3.08097 3.42197 4.22222 2.8656 5.6H1.1664C2.1856 2.3544 5.2176 0 8.8 0C13.2184 0 16.8 3.5816 16.8 8C16.8 12.4184 13.2184 16 8.8 16C5.2176 16 2.1856 13.6456 1.1664 10.4Z"
      fill="currentColor"
    />
  </svg>
);

export default MoveIcon;
