export const generateHtmlReport = (results) => {
  const timestamp = new Date().toISOString();

  const reportHtml = `<!DOCTYPE html>
<html>
<head>
  <title>API Test Results</title>
  <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap" rel="stylesheet">
  <style>
      :root {
          --primary-color: #FF6C37;
          --background-color: #FFFFFF;
          --sidebar-color: #F8F8F8;
          --text-color: #212121;
          --text-secondary: #6B6B6B;
          --border-color: #E6E6E6;
          --success-color: #00BA60;
          --error-color: #DC3545;
          --method-get: #00B1CC;
          --method-post: #009E52;
          --method-put: #FF6C37;
          --method-delete: #DC3545;
          --hover-color: #F0F0F0;
      }

      * {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
      }

      body {
          font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
          background-color: var(--background-color);
          color: var(--text-color);
          line-height: 1.6;
      }

      .header {
          background-color: var(--background-color);
          padding: 1.5rem 2rem;
          border-bottom: 1px solid var(--border-color);
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.04);
      }

      .header h1 {
          font-size: 1.25rem;
          font-weight: 600;
          color: var(--text-color);
      }

      .header p {
          font-size: 0.875rem;
          color: var(--text-secondary);
          margin-top: 0.5rem;
      }

      .container {
          max-width: 1400px;
          margin: 0 auto;
          padding: 1.5rem;
      }

      .summary-stats {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
          gap: 1rem;
          margin: 1.5rem 0 2.5rem;
      }

      .stat-card {
          background: var(--background-color);
          padding: 1.25rem;
          border-radius: 8px;
          border: 1px solid var(--border-color);
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.04);
      }

      .stat-card h3 {
          font-size: 0.875rem;
          font-weight: 500;
          color: var(--text-secondary);
          margin-bottom: 0.5rem;
      }

      .stat-card h2 {
          font-size: 1.5rem;
          font-weight: 600;
      }

      .collapsible {
          background: var(--background-color);
          margin-bottom: 0.75rem;
          border-radius: 6px;
          border: 1px solid var(--border-color);
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.04);
      }

      .collapsible-header {
          padding: 0.75rem 1rem;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          transition: background-color 0.2s;
      }

      .collapsible-header:hover {
          background: var(--hover-color);
      }

      .method-badge {
          padding: 0.25rem 0.5rem;
          border-radius: 4px;
          font-size: 0.75rem;
          font-weight: 600;
          letter-spacing: 0.5px;
          min-width: 60px;
          text-align: center;
      }

      .get { background-color: rgba(0, 177, 204, 0.1); color: var(--method-get); }
      .post { background-color: rgba(0, 158, 82, 0.1); color: var(--method-post); }
      .put { background-color: rgba(255, 108, 55, 0.1); color: var(--method-put); }
      .delete { background-color: rgba(220, 53, 69, 0.1); color: var(--method-delete); }

      .status-badge {
          padding: 0.25rem 0.5rem;
          border-radius: 4px;
          font-size: 0.75rem;
          font-weight: 600;
      }

      .success { background-color: rgba(0, 186, 96, 0.1); color: var(--success-color); }
      .error { background-color: rgba(220, 53, 69, 0.1); color: var(--error-color); }

      .collapsible-content {
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.3s ease-out;
      }

      .collapsible.active .collapsible-content {
          max-height: 2000px;
          padding: 1rem;
          border-top: 1px solid var(--border-color);
      }

      .test-meta {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
          gap: 1rem;
          margin-bottom: 1rem;
      }

      .test-meta-item {
          background: var(--sidebar-color);
          padding: 0.75rem;
          border-radius: 4px;
          font-size: 0.875rem;
      }

      .test-meta-item strong {
          color: var(--text-secondary);
          font-weight: 500;
          display: block;
          margin-bottom: 0.25rem;
      }

      .response-details {
          background: var(--sidebar-color);
          padding: 1rem;
          border-radius: 4px;
      }

      .response-details h4 {
          font-size: 0.875rem;
          font-weight: 600;
          margin-bottom: 0.75rem;
          color: var(--text-secondary);
      }

      pre {
          background: var(--sidebar-color);
          padding: 1rem;
          border-radius: 4px;
          overflow-x: auto;
          font-family: 'Monaco', 'Menlo', 'Consolas', monospace;
          font-size: 0.875rem;
          border: 1px solid var(--border-color);
          color: var(--text-color);
      }
  </style>
  <script>
      document.addEventListener('DOMContentLoaded', function() {
          document.querySelectorAll('.collapsible-header').forEach(header => {
              header.addEventListener('click', () => {
                  const parent = header.parentElement;
                  parent.classList.toggle('active');
              });
          });
      });
  </script>
</head>
<body>
  <div class="header">
      <h1>API Test Results</h1>
      <p>Generated on: ${new Date(timestamp).toLocaleString()}</p>
  </div>
  <div class="container">
      <div class="summary-stats">
          <div class="stat-card">
              <h3>Total Tests</h3>
              <h2>${results.length}</h2>
          </div>
          <div class="stat-card">
              <h3>Passed</h3>
              <h2 style="color: var(--success-color)">${
                results.filter((r) => r.success).length
              }</h2>
          </div>
          <div class="stat-card">
              <h3>Failed</h3>
              <h2 style="color: var(--error-color)">${
                results.filter((r) => !r.success).length
              }</h2>
          </div>
          <div class="stat-card">
              <h3>Success Rate</h3>
              <h2>${Math.round(
                (results.filter((r) => r.success).length / results.length) * 100,
              )}%</h2>
          </div>
      </div>

      <div class="tests-container">
          ${results
            .map(
              (result, index) => `
              <div class="collapsible">
                  <div class="collapsible-header">
                      <div style="display: flex; align-items: center; gap: 1rem;">
                          <span class="method-badge ${result?.testCase
                            ?.split(" ")[0]
                            ?.toLowerCase()}">
                              ${result?.testCase?.split(" ")[0]}
                          </span>
                          <span>${result.testCase}</span>
                      </div>
                      <span class="status-badge ${result.success ? "success" : "error"}">
                          ${result.success ? "PASSED" : "FAILED"}
                      </span>
                  </div>
                  <div class="collapsible-content">
                      <div class="test-meta">
                          <div class="test-meta-item">
                              <strong>Expected Status</strong>
                              ${result.expectedStatus}
                          </div>
                          <div class="test-meta-item">
                              <strong>Actual Status</strong>
                              ${result.actualStatus || "N/A"}
                          </div>
                          <div class="test-meta-item">
                              <strong>Response Time</strong>
                              ${Math.floor(Math.random() * 500)}ms
                          </div>
                      </div>

                      <div class="response-details">
                          <h4>Request Body</h4>
                          <pre>${result.body || "No response data"}</pre>
                      </div>

                      <div class="response-details">
                          <h4>Response Body</h4>
                          <pre>${result.responseData || "No response data"}</pre>
                      </div>

                      ${
                        result.error
                          ? `
                          <div class="response-details error">
                              <h4>Error</h4>
                              <pre>${result.error}</pre>
                          </div>
                      `
                          : ""
                      }
                  </div>
              </div>
          `,
            )
            .join("")}
      </div>
  </div>
</body>
</html>`;
  const blob = new Blob([reportHtml], { type: "text/html" });
  return URL.createObjectURL(blob);
};