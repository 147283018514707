import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

const modalExecute = async (data) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);

  const response = await axios.post(
    `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=modalExecute&companyid=${temp.companyid}&token=${temp.token}&testcaseid=${data.tcid}&stepnum=${data.stepNumber}&nextnum=${data.nextStepNumber}&testcasename=${data.tcname}&envUrl=${data.envurl}`,
    data,
  );

  return response.data;
};

const useMergeCopilotQuery = (data) => {
  return useMutation({
    mutationFn: () => modalExecute(data),
  });
};

export default useMergeCopilotQuery;
