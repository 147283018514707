import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import DownloadIcon from "@mui/icons-material/Download";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PaymentIcon from "@mui/icons-material/Payment";
import logo from "../../Assets/nogrunt-logo.svg";
import { Dashboard } from "@mui/icons-material";

const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    let user = {
      email: "",
      firstname: "",
      lastname: "",
      company: "",
      phonenumber: "",
    };
    localStorage.setItem("userDetail", JSON.stringify(user));
    localStorage.removeItem("payment_completed");
    navigate("/LoginPage");
  };

  const menuItems = [
    {
      text: "Download Code",
      path: "/",
      onClick: () => navigate("/"),
      icon: <DownloadIcon sx={{ fontSize: 24 }} />,
    },
    {
      text: "Page Configuration",
      path: "/config",
      onClick: () => navigate("/config"),
      icon: <Dashboard sx={{ fontSize: 24 }} />,
    },
    {
      text: "Selector Ranking",
      path: "/ranking",
      onClick: () => navigate("/ranking"),
      icon: <DownloadIcon sx={{ fontSize: 24, transform: "rotate(180deg)" }} />,
    },
    // {
    //   text: "Payment",
    //   path: "/payment",
    //   onClick: () => navigate("/payment"),
    //   icon: <PaymentIcon sx={{ fontSize: 24 }} />,
    // },
    {
      text: "Profile",
      path: "/profile",
      onClick: () => navigate("/profile"),
      icon: <AccountCircleIcon sx={{ fontSize: 24 }} />,
    },
  ];

  const isActive = (path) => {
    if (path === "/") {
      return location.pathname === "/";
    }
    return location.pathname.startsWith(path);
  };

  return (
    <>
      <div className="fixed inset-y-0 left-0 w-80 bg-[#151515] flex flex-col py-7 border-r-[1.5px] border-[#272727]">
        {/* Logo Section */}
        <div className="flex items-center justify-center text-white mb-6">
          <img src={logo} alt="Logo" />
        </div>

        {/* Divider */}
        <div className="h-px bg-[#272727] mb-4" />

        {/* Navigation Items */}
        <nav className="flex-grow pr-4">
          <ul className="space-y-4">
            {menuItems.map((item) => (
              <li key={item.text}>
                <button
                  onClick={item.onClick}
                  className={`w-full py-4 flex items-center pl-10 rounded-r-[30px] font-medium transition-colors urbanist-font text-[16px] leading-5
                    ${
                      isActive(item.path)
                        ? "bg-custom-gradient text-white"
                        : "text-gray-400 hover:text-white hover:bg-[#272727]"
                    }`}
                >
                  <span className="mr-3">{item.icon}</span>
                  {item.text}
                </button>
              </li>
            ))}
          </ul>
        </nav>

        {/* Divider */}
        <div className="h-px bg-[#272727] mb-4" />

        {/* Logout Button */}
        <div className="flex items-center justify-center">
          <button
            onClick={handleLogout}
            className="flex items-center px-4 py-3 text-gray-400 hover:text-white urbanist-font text-[16px] leading-5 transition-colors"
          >
            <PowerSettingsNewIcon sx={{ fontSize: 24, marginRight: "12px" }} />
            Logout
          </button>
        </div>
      </div>
    </>
  );
};

export default Navigation;
