import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useGetScheduleQuery, useSuiteSchedulerQuery } from "../../useQuery";
import { Days, SchedulerOptions } from "../../components-new/utils/constants";
import { PillRadio } from "../../components-new/common";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import { useTheme } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import useDeleteScheduleQuery from "../../useQuery/useDeleteScheduleQuery";
import { useNotification } from "../../hooks";

const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: "13px",
  fontWeight: 500,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  padding: "15px 28px",
  borderRadius: "8px",
  textTransform: "capitalize",
  textAlign: "center",
  fontSize: "17px",
  fontWeight: 600,
  lineHeight: "20px",
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const getSubfreqArray = (subfreq) => {
  if (typeof subfreq === "string") {
    const trimmed = subfreq.split(",").map((day) => day.trim());
    return trimmed.length === Days.length ? [...Days] : trimmed;
  }
  return [];
};

function getStyles(day, schedule, theme) {
  return {
    fontWeight: schedule?.includes(day)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

const Scheduler = ({ suiteId }) => {
  const theme = useTheme();
  const notify = useNotification();

  const { data: scheduleData, refetchWithParams: getSchedule } = useGetScheduleQuery();
  const { refetchWithParams: updateSchedule } = useSuiteSchedulerQuery();
  const { refetchWithParams: deleteSchedule, isSuccess: isDeleteSuccess } =
    useDeleteScheduleQuery();

  const [schedule, setSchedule] = useState(null);

  useEffect(() => {
    if (!isDeleteSuccess) return;

    getSchedule(suiteId);
    notify("Scheduler is Successfully Deleted", "success");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleteSuccess]);

  useEffect(() => {
    getSchedule(suiteId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suiteId]);

  useEffect(() => {
    if (scheduleData) {
      setSchedule(scheduleData);
    }
  }, [scheduleData]);

  const handleSchedulerChange = (event) => {
    const newFrequency = event.target.value;
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      frequency: newFrequency,
    }));
  };

  const handleTimeChange = (newTime) => {
    const formattedTime = dayjs(newTime).format("HH:mm:ss.0");
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      scheduletime: formattedTime,
    }));
  };

  const handleDayChange = (event) => {
    const {
      target: { value },
    } = event;

    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      scheduletime: `${prevSchedule.scheduletime}.0`,
      subfreq: value.join(","),
    }));
  };

  const handleDateChange = (newDate) => {
    setSchedule((prevSchedule) => {
      return {
        ...prevSchedule,
        scheduletime: `${prevSchedule.scheduletime}.0`,
        scheduledate: dayjs(newDate).format("YYYY-MM-DD"),
      };
    });
  };

  const handleSubmitSchedule = () => {
    updateSchedule(schedule, suiteId).then(() => {
      getSchedule(suiteId);
      notify("Scheduler is Successfully Saved", "success");
    });
  };

  const handleDeleteSchedule = () => {
    deleteSchedule(suiteId);
  };

  return (
    <>
      <Box>
        <StyledLabel>Select Frequency</StyledLabel>
        {SchedulerOptions.map((option) => (
          <PillRadio
            key={option?.value}
            value={option?.value}
            label={option?.label}
            checked={schedule?.frequency === option.value}
            onChange={handleSchedulerChange}
          />
        ))}
      </Box>
      <Box my={2} display={"flex"} alignItems={"center"} columnGap={2}>
        <Box>
          <StyledLabel>Time</StyledLabel>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              value={schedule?.scheduletime ? dayjs(schedule?.scheduletime, "HH:mm:ss") : null}
              onChange={handleTimeChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Box>
        {schedule?.frequency === SchedulerOptions[1].value && (
          <Box>
            <FormControl sx={{ width: 257 }}>
              <StyledLabel>Select Day</StyledLabel>
              <Select
                multiple
                value={getSubfreqArray(schedule?.subfreq)}
                onChange={handleDayChange}
                MenuProps={MenuProps}
              >
                {Days.map((day) => (
                  <MenuItem
                    key={day}
                    value={day}
                    style={getStyles(day, getSubfreqArray(schedule?.subfreq), theme)}
                  >
                    {day}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}
        {schedule?.frequency === SchedulerOptions[2].value && (
          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StyledLabel>Select Day</StyledLabel>
              <DatePicker
                value={schedule.scheduledate ? dayjs(schedule.scheduledate) : null}
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Box>
        )}
      </Box>
      <Box mb={2}>
        <Box display="flex" mt={2} columnGap={2}>
          <StyledButton sx={{ height: "50px" }} variant="contained" onClick={handleDeleteSchedule}>
            Delete
          </StyledButton>
          <StyledButton
            disabled={!schedule}
            sx={{ height: "50px" }}
            variant="contained"
            onClick={handleSubmitSchedule}
          >
            Submit
          </StyledButton>
        </Box>
      </Box>
    </>
  );
};

export default Scheduler;
