import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  CircularProgress,
  Card,
  CardContent,
  Stack,
  Divider,
} from "@mui/material";
import { Star, Rocket, Diamond, CheckCircle } from "@mui/icons-material";

const PaymentPage = () => {
  const [loadingStates, setLoadingStates] = useState({});
  const [sdkReady, setSdkReady] = useState(false);
  const userDetail = JSON.parse(localStorage.getItem("userDetail"));
  const [paymentSessionid, setPaymentSessionid] = useState(null);

  useEffect(() => {
    const paymentSessionId = localStorage.getItem("payment_session_id");
    setPaymentSessionid(paymentSessionId);
  }, []);

  // Pricing plans
  const plans = [
    {
      id: "basic",
      name: "Basic Plan",
      price: 499,
      features: ["10 Projects", "Basic Support", "1GB Storage"],
      icon: <Star sx={{ fontSize: 40, color: "#FFD700" }} />,
    },
    {
      id: "pro",
      name: "Pro Plan",
      price: 999,
      features: ["Unlimited Projects", "Priority Support", "10GB Storage", "Advanced Analytics"],
      icon: <Rocket sx={{ fontSize: 40, color: "#FF4081" }} />,
    },
    {
      id: "enterprise",
      name: "Enterprise Plan",
      price: 1999,
      features: ["Custom Solutions", "24/7 Support", "Unlimited Storage", "Dedicated Manager"],
      icon: <Diamond sx={{ fontSize: 40, color: "#00BCD4" }} />,
    },
  ];

  // Load Cashfree SDK dynamically
  useEffect(() => {
    const loadCashfreeSdk = () => {
      const script = document.createElement("script");
      script.src = "https://sdk.cashfree.com/js/v3/cashfree.js";
      script.async = true;
      script.onload = () => {
        setSdkReady(true);
      };
      script.onerror = () => {
        console.error("Failed to load Cashfree SDK");
      };
      document.body.appendChild(script);
    };

    loadCashfreeSdk();

    return () => {
      const script = document.querySelector(
        'script[src="https://sdk.cashfree.com/js/v3/cashfree.js"]',
      );
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, []);

  const initiateCashfreePayment = async (plan) => {
    if (!sdkReady) {
      alert("Please wait for the payment system to initialize");
      return;
    }

    setLoadingStates((prev) => ({ ...prev, [plan.id]: true }));

    try {
      if (typeof window.Cashfree === "undefined") {
        throw new Error("Cashfree SDK not loaded");
      }

      const cashfree = new window.Cashfree({
        mode: "sandbox",
      });

      const paymentSessionId = paymentSessionid;

      const checkoutOptions = {
        paymentSessionId: paymentSessionId,
        // redirectTarget: "_modal",
        redirectTarget: "_self",
        orderAmount: plan.price,
        orderCurrency: "INR",
        customerEmail: userDetail.email,
        customerName: userDetail.company.companycontactname,
        customerPhone: "",
      };

      const result = await cashfree.checkout(checkoutOptions);
    } catch (error) {
      console.error("Payment initialization failed:", error);
      alert("Failed to initialize payment. Please try again.");
    } finally {
      setLoadingStates((prev) => ({ ...prev, [plan.id]: false }));
    }
  };

  // Pricing card component
  const PricingCard = ({ plan }) => {
    const isLoading = loadingStates[plan.id] || false;

    return (
      <Card
        sx={{
          height: "100%",
          cursor: "pointer",
          transition: "all 0.2s ease-in-out",
          "&:hover": {
            transform: "scale(1.02)",
            boxShadow: 4,
          },
        }}
      >
        <CardContent>
          <Stack spacing={2} alignItems="center">
            <Box>{plan.icon}</Box>
            <Typography variant="h5" component="div" textAlign="center">
              {plan.name}
            </Typography>
            <Typography variant="h4" color="primary" textAlign="center">
              ₹{plan.price}
            </Typography>
            <Divider sx={{ width: "100%" }} />
            <Stack spacing={1} alignItems="center">
              {plan.features.map((feature, index) => (
                <Typography key={index} textAlign="center">
                  {feature}
                </Typography>
              ))}
            </Stack>
            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={() => initiateCashfreePayment(plan)}
              disabled={isLoading || !sdkReady}
              startIcon={isLoading ? <CircularProgress size={20} /> : <CheckCircle />}
              sx={{
                py: 1.5,
                mt: 2,
                backgroundColor: "primary.main",
                "&:hover": {
                  backgroundColor: "primary.dark",
                },
              }}
            >
              {isLoading ? "Processing..." : "Purchase Now"}
            </Button>
          </Stack>
        </CardContent>
      </Card>
    );
  };

  return (
    <Box sx={{ p: 3, maxWidth: "1200px", margin: "0 auto", marginLeft: "320px" }}>
      <Typography variant="h4" gutterBottom textAlign="center" sx={{ mb: 4 }}>
        Choose Your Plan
      </Typography>
      <Grid container spacing={3}>
        {plans.map((plan) => (
          <Grid item xs={12} md={4} key={plan.id}>
            <PricingCard plan={plan} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PaymentPage;
