import React, { useState } from "react";
import "./ForgotPasswordPage.style.scss";
import { CommonMethods } from "./../../components/common/CommonMethods";
import { useNavigate } from "react-router-dom";
import nogruntblacklogo from "../../Assets/nogruntblacklogo.svg";
import forgotpasswordimg from "../../Assets/forgotpasswordimg.svg";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import {
  fetchForgotPasswordToken,
  validateOTP,
  changepasswordFromForgotPassword,
} from "./../../API/UserApi";

const ForgotPasswordPage = (props) => {
  const navigate = useNavigate();
  const [account, setAccount] = useState({
    email: "",
    token: "",
    NewPassword: "",
    RENewPassword: "",
  });
  const [isTokenGenerated, setIsTokenGenerated] = useState(false);
  const [isTokenvalidated, setIsTokenvalidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handelAccount = (property, event) => {
    setAccount({ ...account, [property]: event.target.value });
  };

  const validatePassword = (password) => {
    return password.match(
      /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{8,16}$/,
    );
  };

  const GetForgotPasswordToken = async () => {
    await fetchForgotPasswordToken(account.email)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setIsTokenGenerated(true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const CheckOTP = async () => {
    await validateOTP(account.email, account.token)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            if (response.data === "Token Verified") {
              setIsTokenvalidated(true);
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changePassword = async () => {
    if (account.RENewPassword !== account.NewPassword) {
      console.log("confirm password and password should be same");
    } else if (!validatePassword(account.NewPassword)) {
      console.log("Enter a strong password");
    } else {
      await changepasswordFromForgotPassword({
        username: account.email,
        NewPassword: account.NewPassword,
        RENewPassword: account.RENewPassword,
      })
        .then((response) => {
          if (response.status === 200) {
            if (response.data.message === "fail") {
              CommonMethods(navigate);
            } else {
              navigate("/LoginPage");
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  return (
    <div className="bg-white rounded-sm w-full h-full flex flex-col lg:flex-row justify-between p-9">
      <div className="left w-full flex flex-col items-start justify-start">
        <div className="top flex flex-col">
          <img src={nogruntblacklogo} alt="Nogrunt Logo" className="w-[136px] h-[49px]" />
        </div>

        <div className="bottom flex flex-col mt-20 w-full max-w-[555px]">
          <div>
            <h1 className="font-semibold text-[24px] text-[#151515] leading-10 urbanist-font">
              Forgot Password
            </h1>
            <p className="font-normal text-[18px] leading-7 text-[#8692A6] urbanist-font">
              Reset your account password
            </p>
          </div>

          {errorMessage && (
            <div className="mt-4 p-3 bg-red-100 text-red-700 rounded-md">{errorMessage}</div>
          )}

          {!isTokenGenerated && (
            <>
              <label className="font-bold text-[14px] leading-5 text-[#151515] urbanist-font mt-10">
                Email Address
              </label>
              <input
                type="email"
                placeholder="Enter your email"
                value={account.email}
                onChange={(event) => handelAccount("email", event)}
                className="w-full p-4 border-[#151515] border-[1.5px] border-opacity-30 mt-2 rounded-[3px] urbanist-font"
              />
              <button
                onClick={GetForgotPasswordToken}
                className="w-full py-5 bg-[#0043CE] rounded-[6px] mt-10 text-white font-semibold text-[20px] leading-5 urbanist-font"
              >
                Get OTP
              </button>
            </>
          )}

          {isTokenGenerated && !isTokenvalidated && (
            <>
              <label className="font-bold text-[14px] leading-5 text-[#151515] urbanist-font mt-10">
                OTP
              </label>
              <input
                type="text"
                placeholder="Enter OTP"
                value={account.token}
                onChange={(event) => handelAccount("token", event)}
                className="w-full p-4 border-[#151515] border-[1.5px] border-opacity-30 mt-2 rounded-[3px] urbanist-font"
              />
              <button
                onClick={CheckOTP}
                className="w-full py-5 bg-[#0043CE] rounded-[6px] mt-10 text-white font-semibold text-[20px] leading-5 urbanist-font"
              >
                Validate OTP
              </button>
            </>
          )}

          {isTokenvalidated && (
            <>
              <label className="font-bold text-[14px] leading-5 text-[#151515] urbanist-font mt-10">
                New Password
              </label>
              <input
                type="password"
                placeholder="Enter new password"
                value={account.NewPassword}
                onChange={(event) => handelAccount("NewPassword", event)}
                className="w-full p-4 border-[#151515] border-[1.5px] border-opacity-30 mt-2 rounded-[3px] urbanist-font"
              />
              <p className="text-[#8692A6] text-[12px] mt-2">
                - Password should contain 1 Uppercase, 1 Lowercase, 1 Digit, 1 Special Character and
                8 to 16 Characters long.
              </p>

              <label className="font-bold text-[14px] leading-5 text-[#151515] urbanist-font mt-10">
                Confirm New Password
              </label>
              <input
                type="password"
                placeholder="Confirm new password"
                value={account.RENewPassword}
                onChange={(event) => handelAccount("RENewPassword", event)}
                className="w-full p-4 border-[#151515] border-[1.5px] border-opacity-30 mt-2 rounded-[3px] urbanist-font"
              />

              <button
                onClick={changePassword}
                className="w-full py-5 bg-[#0043CE] rounded-[6px] mt-10 text-white font-semibold text-[20px] leading-5 urbanist-font"
              >
                Reset Password
              </button>
            </>
          )}

          <div className="flex flex-row items-center justify-between mt-4">
            <button
              onClick={() => navigate("/SignUpPage")}
              className="text-[#2550D1] font-medium text-[16px] leading-5 urbanist-font"
            >
              Don't have an account?
            </button>

            <button
              onClick={() => navigate("/LoginPage")}
              className="text-[#2550D1] font-medium text-[16px] leading-5 urbanist-font"
            >
              Back to Login
            </button>
          </div>
        </div>
      </div>
      <div className="right w-full hidden lg:block">
        <img src={forgotpasswordimg} alt="loginpageimg" />
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
