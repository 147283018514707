import React from "react";
import {
  Box,
  Typography,
  Modal,
  Paper,
  Link,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  styled,
} from "@mui/material";
import {
  Close as CloseIcon,
  ArrowForward as ArrowForwardIcon,
  HelpOutline as HelpOutlineIcon,
} from "@mui/icons-material";
import { useHelp } from "../../context/help-context";

const StyledModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const ModalContent = styled(Paper)(({ theme }) => ({
  width: "600px",
  maxWidth: "90vw",
  maxHeight: "80vh",
  padding: theme.spacing(3),
  borderRadius: "8px",
  overflowY: "auto",
  position: "relative",
}));

const ModalHeader = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "16px",
});

const HelpLink = styled(ListItem)({
  cursor: "pointer",
  borderRadius: "8px",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
  },
  padding: "16px",
  marginBottom: "8px",
});

const HelpModal = ({ open, onClose, pageHelp }) => {
  if (!pageHelp) return null;

  return (
    <StyledModal open={open} onClose={onClose} aria-labelledby="help-modal-title">
      <ModalContent>
        <ModalHeader>
          <Typography id="help-modal-title" variant="h5" component="h2">
            {pageHelp.title}
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </ModalHeader>

        <Typography variant="body1" color="textSecondary" paragraph>
          {pageHelp.description}
        </Typography>

        <Divider sx={{ my: 2 }} />

        <List disablePadding>
          {pageHelp.helpLinks.map((link, index) => (
            <HelpLink
              key={index}
              component={Link}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              underline="none"
              color="inherit"
            >
              <ListItemText
                primary={
                  <Typography variant="h6" color="primary">
                    {link.title}
                  </Typography>
                }
                secondary={
                  <Typography variant="body2" color="textSecondary">
                    {link.description}
                  </Typography>
                }
              />
              <ArrowForwardIcon color="primary" />
            </HelpLink>
          ))}
        </List>
      </ModalContent>
    </StyledModal>
  );
};

// Help Icon component that opens the modal
export const HelpIconButton = ({ path }) => {
  const [open, setOpen] = React.useState(false);
  const { getPageHelp } = useHelp();
  const pageHelp = getPageHelp(path);

  if (!pageHelp) return null;

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <IconButton
        onClick={handleOpen}
        color="primary"
        size="medium"
        aria-label="help"
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.04)",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.08)",
          },
        }}
      >
        <HelpOutlineIcon />
      </IconButton>

      <HelpModal open={open} onClose={handleClose} pageHelp={pageHelp} />
    </>
  );
};

export default HelpModal;
