import React, { useEffect, useState } from "react";
import "./RecordingTable.style.scss";
import { Button, IconButton, InputLabel, FormControl } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import TableFooter from "@mui/material/TableFooter";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { FormControlLabel, Switch } from "@mui/material";

import { TablePagination, tablePaginationClasses as classes } from "@mui/base/TablePagination";
import FirstPageRoundedIcon from "@mui/icons-material/FirstPageRounded";
import LastPageRoundedIcon from "@mui/icons-material/LastPageRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

import { Input, Tooltip } from "@mui/material";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SyncDisabledIcon from "@mui/icons-material/SyncDisabled";
import VerifiedSharpIcon from "@mui/icons-material/VerifiedSharp";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DownloadIcon from "@mui/icons-material/Download";
import LoopIcon from "@mui/icons-material/Loop";
import CloseIcon from "@mui/icons-material/Close";
import SettingsIcon from "@mui/icons-material/Settings";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import StraightenIcon from "@mui/icons-material/Straighten";
import InfoIcon from "@mui/icons-material/Info";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import FolderZipIcon from "@mui/icons-material/FolderZip";

import { useNavigate, useLocation } from "react-router-dom";

import { API_BASE_PATH, API_BASE_RELATIVE_PATH } from "../../Config/Config";

import { CommonMethods } from "../common/CommonMethods";
import LoaderSpinner from "../common/LoaderSpinner";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DoneIcon from "@mui/icons-material/Done";
import {
  fetchProducts,
  fetchModules,
  fetchModulesCheck,
  fetchEnvironments,
  fetchTestCasesfromModule,
  fetchfiledatafromDB,
  fetchdownloadedfiles,
  fetchfilesdata,
  coverageExecute,
} from "../../API/CreateCollectionAPI";

import {
  ColorButton,
  StyledTableRow,
  StyledTableCell,
  BootstrapTooltip,
  colorExport,
} from "../common/common.Component";
import { fetchAllApiDetails } from "../../API/Api";

import {
  handleFlowChange,
  handleSelectChange,
  handleCheckBoxChange,
  handleValidateChange,
  handleBeforeChange,
  handleWaitTimeChange,
  fetchRecording,
  // executeTest,
  deleteStep,
  handleTDChange,
  fetchSelectFromListData,
  fetchSteps,
  generateCode,
  downloadTestSteps,
  downloadPomZipFile,
  addStepInrecording,
  editStepInrecording,
  handleTestCaseSearchByMid,
  editTestCaseName,
  copyTestCaseName,
  deleteTestCaseName,
} from "../../API/RecordingTableAPI";

import {
  validateDetailsFromDB,
  validateDetailsFromEmail,
  validateDetailsFromFile,
  postEnviromentSubmit,
  validateDetailsOnScreen,
  validateDetailsBySearch,
  validateDetailsFromRegex,
  validateDetailsFromGoogleSheet,
  validateDetailsFromApi,
  validateDetailsFromDate,
  validateDetailsFromDependantTC,
  validateDetailsChangeText,
  validateReferenceFileData,
  validateDownloadedFile,
  validateDescriptionData,
  saveSettingsData,
} from "../../API/ValidateAPI";

import { getResolution } from "../../API/adminAPI";
import EditModal from "./EditModal";
import ScreenshotModal from "../TCTable/ScreenshotModal";
import VideoPopup from "./VideoPopup";
import { OpenInNewOutlined } from "@mui/icons-material";
import { color } from "@mui/system";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const selectLIstData = [
  { value: "AsRecorded", name: "As Recorded" },
  { value: "ChangeTestData", name: "Change TestData" },
  { value: "IsAVar", name: "Create As Reference Variable" },
  { value: "FromApi", name: "From Api" },

  { value: "FromDB", name: "From DB" },
  { value: "FromEmail", name: "From Email" },
  { value: "FromFile", name: "From File" },
  { value: "FromReferenceFile", name: "From TestData" },
  { value: "GenData", name: "Generate Data" },
  { value: "GoogleSheet", name: "Google Sheet" },
  // { value: "MultipleFiles", name: "Multiple Files" },
  // { value: "SameRow", name: "On Same Row as Test Data" },
  // { value: "SameRowElement", name: "On Same Row as Previous Element" },
  { value: "UseVariableFromTheScenario", name: "Data Dependence-This Scenario" },
  {
    value: "DependentonOtherTestScenario",
    name: "Data Dependence-Other Scenario",
  },
  { value: "Search", name: "Search on Page" },
  { value: "Script", name: "Script" },
];

const DependentonOtherTestScenarioList = [
  { value: "Product", name: "Product" },
  { value: "Module", name: "Module" },
  { value: "TestCase", name: "Test Case" },
];

const validateSelectLIstData = [
  { value: "ChangeTestData", name: "Change Text" },
  { value: "IsAVar", name: "Create As Reference Variable" },
  { value: "Date", name: "Date" },
  { value: "DynamicText", name: "Dynamic Text" },
  { value: "InApi", name: "In Api" },
  { value: "DB", name: "In DB" },
  { value: "InDownloadedFile", name: "In Downloaded Files" },
  { value: "FromEmail", name: "In Email" },
  { value: "InFile", name: "In File" },
  { value: "InRefrenceFile", name: "In TestData" },
  { value: "InGoogleSheet", name: "In Google Sheet" },
  { value: "OnScreen", name: "On Screen" },
  { value: "UseVariableFromTheScenario", name: "Data Dependence-This Scenario" },
  {
    value: "DependentonOtherTestScenario",
    name: "Data Dependence-Other Scenario",
  },
  { value: "Regex", name: "Regex" },
  { value: "Search", name: "Search on Page" },
];

const KeywordData = [
  { value: "Button", name: "Button" },
  { value: "Check Box", name: "Check Box" },
  { value: "EditBox", name: "EditBox" },
  { value: "File Upload", name: "File Upload" },
  { value: "Link", name: "Link" },
  { value: "Radio Button", name: "Radio Button" },
  { value: "SVGElement", name: "SVGElement" },
  { value: "SameRow", name: "Sibling (Data)" },
  { value: "SameRowElement", name: "Sibling (Element)" },
  { value: "TextArea", name: "TextArea" },
  { value: "Validation", name: "Validation" },
];

const ActionData = [
  { value: "Button", name: "Button" },
  { value: "ById", name: "By Id" },
  { value: "ByPath", name: "By Path" },
  { value: "ByText", name: "By Text" },
  { value: "ByValue", name: "By Value" },
  { value: "Change", name: "Change" },
  { value: "Checkbox", name: "Check Box" },
  { value: "Click", name: "Click" },
  { value: "DblClick", name: "Double Click" },
  { value: "DynamicText", name: "Dynamic Text" },
  { value: "Enter", name: "Enter" },
  { value: "FileUpload", name: "File Upload" },
  { value: "Radio", name: "Radio" },
  { value: "Scroll", name: "Scroll To Element" },
  { value: "Select", name: "Select" },
  { value: "Submit", name: "Submit" },
  { value: "validatePartial", name: "Validate Text" },
];

const DBTypeLIstData = [
  { value: "Oracle", name: "Oracle" },
  { value: "MongoDB", name: "MongoDB" },
  { value: "MYSQL", name: "MySQL" },
  { value: "MSSQL", name: "MS SQL" },
  { value: "Other", name: "Other" },
];
const tableColums = [
  {
    name: "Step",
    id: "Step",
    isLongString: false,
    isNumeric: false,
    align: "center",
    colspan: 1,
    width: "7%",
  },
  {
    name: "Element Description",
    id: "PageName",
    isLongString: false,
    isNumeric: false,
    align: "center",
    colspan: 2,
    width: "14%",
  },
  {
    name: "Keyword",
    id: "Keyword",
    isLongString: false,
    isNumeric: false,
    align: "center",
    colspan: 1,
    width: "7%",
  },
  {
    name: "Action",
    id: "Action",
    isLongString: false,
    isNumeric: false,
    align: "center",
    colspan: 1,
    width: "7%",
  },
  {
    name: "Flow",
    id: "Flow",
    isLongString: false,
    isNumeric: false,
    align: "center",
    colspan: 1,
    width: "10%",
  },
  {
    name: "TestData",
    id: "TestData",
    isLongString: false,
    isNumeric: false,
    align: "center",
    colspan: 3,
    width: "15%",
  },
  {
    name: "Variable",
    id: "Variable",
    isLongString: false,
    isNumeric: false,
    align: "center",
    colspan: 1,
    width: "12%",
  },

  // {
  //   name: "Activity",
  //   id: "Activity",
  //   isLongString: false,
  //   isNumeric: false,
  // align:"center",
  // },
];

const RecordingsTable = () => {
  const [SelectedRow, setSelectedRow] = useState(null);
  const [SelectedRowForTime, setSelectedRowForTime] = useState(null);
  const [filedropdowndata, setFiledropdowndata] = useState([]);
  const [downloadedFileData, setDownloadedFileData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorCode, setErrorCode] = useState("");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  // const [filenamestorage,setFilenameStorage] = useState('')
  // const [selectedvaluefile,setSelectedvaluefile] = useState('')
  const [TestDataDetails, setTestDataDetails] = useState({
    id: "",
    Source: "",
    UploadString: "",
    GenerateDataType: "",
    generateDataLength: "",
    useVar: "",
    DBType: "",
    DBOtherType: "",
    DBURL: "",
    DBusername: "",
    DBpwd: "",
    DBQuery: "",
    FileName: "",
    AssertionValue: "",
    DownloadedFile: "",
    filefromDB: "",
    dynamicvalue: "",
    file: "",
    filename: "",
    filefield: "",
    multipleFilenames: "",
    multipleFiles: [],
    testCaseId: "",
    SelectEnvironmentName: "",
    SelectEnvironmentUrl: "",
    Height: 0,
    Width: 0,
    SelectBrowserName: "",
    regex: "",
    date: "",
    dateFromat: "",
    xpath: "",
    testdata: "",
    search: "",
    ElementType: "",
    ElementIndex: "",
    DependantTC: "",
    valTarget: "OnScreen",
    waittime: "",
    DependentModule: "",
    DependentProduct: "",
    DependentTC: "",
    customerEmail: "",
    customerPassword: "",
    EmailSelectionCriteria: "",
    EmailFilter: "",
    apiName: "",
    apiId: "",
    apiParam: "",
    apiQuery: "",
    apiproduct: "",
    apimodule: "",
    sheetId: "",
    cell: "",
    scope: "Single Run",
    endRange: "",
    filter: "",
    fromFileType: "",
    scriptfile: "",
    createsAlert: "",
  });
  const [ListData, SetListData] = useState([]);
  const [SearchString, setSearchString] = useState();
  const [SearchStringValidation, setSearchStringValidation] = useState();
  const [SearchStringTestDataSource, setSearchStringTestDataSource] = useState();

  const [state, setState] = useState({
    file: "",
    tc: "",
    xls: [],
    tcs: {},
    stepnums: [],
    tabsvalues: [],
    display: "view",
    Height: 0,
    Width: 0,
    testCaseId: -1,
    expanded: false,
    isGenDataSelected: false,
    generateDataValue: "",
    productname: "",
    modulename: "",
    idproducts: "",
    idproducts_One: "",
    idmodules: "",
    idmodules_One: "",
    idtest_case: "",

    idtest_case_One: "",
    Test_Case: "",
    Test_Case_One: "",
    browserDetails: [
      { browsername: "Chrome" },
      { browsername: "Edge" },
      { browsername: "Firefox" },
      { browsername: "Safari" },
      { browsername: "ALL" },
    ],
    runOnceDetails: [
      { value: 1, option: "No" },
      { value: 0, option: "Yes" },
    ],
    resolutionDetails: [],
    envDetails: [],
    SelectEnvironmentName: "",
    SelectEnvironmentUrl: "",
    SelectResolution: "",
    SelectBrowserName: "Chrome",
    SelectRunonce: "",
    TcThreshold: "",
    DescriptionData: "",
    proxy: "",
    VideoUrl: "",
    enablevideo: false,
    continuetest: false,
    forcenewsession: false,
  });
  // const handleChange = (panel) => (event, isExpanded) => {
  //   setState({ expanded: isExpanded ? panel : false });
  // };

  const [productList, setproductList] = useState([]);
  const [ModuleList, setModuleList] = useState([]);
  const [checkModuleEnable, setCheckModuleEnable] = useState();
  const [ModuleListOne, setModuleListOne] = useState([]);
  const [EnvironmentList, setEnvironmentList] = useState([]);
  const [TestCaseList, setTestCaseList] = useState([]);
  const [TestCaseListOne, setTestCaseListOne] = useState([]);
  const [refreshFlag, setRefreshFlag] = useState(true);
  const [hidingTick, setHidingTick] = useState(false);
  const [stepNumber, setStepNumber] = useState();
  const [rowValue, setRowValue] = useState();
  const [deleteIconValue, setDeleteIconValue] = useState();
  const [deleteTestCaseValue, setDeleteTestCaseValue] = useState();
  const [tcValue, setTcValue] = useState(false);
  const [copyTcValue, setCopyTcValue] = useState(false);
  const [apiList, setApiList] = useState([]);
  const [newTestStep, setNewTestStep] = useState({
    stepNumber: "",
    Keyword: "Button",
    Action: "button",
    Flow: "Positive",
    Page_Description: "",
    Page_Name: "",
    TestData: "",
    prevStep: {},
    ObjectIdentifier: "",
  });

  const [openDialog, setOpenDialog] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [openCopyAlert, setOpenCopyAlert] = useState(false);
  const [openRenameAlert, setOpenRenameAlert] = useState(false);
  const [openAlertSave, setOpenAlertSave] = useState(false);
  const [deleteIconValueTestStep, setDeleteIconValueTestStep] = useState();
  const [deleteIconValueTestCase, setDeleteIconValueTestCase] = useState();
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [openDeletionAlert, setOpenDeletionAlert] = useState(false);
  const [openModalDeleteAlert, setOpenModalDeleteAlert] = useState(false);
  const [openDialogExecute, setOpenDialogExecute] = useState(false);
  const [openTestcase, setOpenTestcase] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [indexValue, setIndexValue] = useState();
  const [testCaseName, setTestCaseName] = useState();
  const [additionalTestCase, setAdditionalTestCase] = useState();
  const [envUrl, setEnvUrl] = useState();
  const [valueSaved, setValueSaved] = useState([]);
  const [test, setTest] = useState([]);
  const [testCaseNameChange, setTestCaseNameChange] = useState();
  const [renameCheck, setRenameCheck] = useState(false);
  const [copyCheck, setCopyCheck] = useState(false);
  const [deletecheck, setDeleteCheck] = useState(false);

  const [valueForValidation, setValueForValidation] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [newName, setNewName] = useState("");
  const [copyNewName, setCopyNewName] = useState("");
  const [deleteValue, setDeleteValue] = useState(true);
  const [rowWaitTimes, setRowWaitTimes] = useState({});
  const [getAllSteps, setGetAllSteps] = useState("");
  const [getInfo, setInfo] = useState("");

  const [testCaseSearchValue, setTestCaseSearchValue] = useState(false);
  const [testCaseSearchValueInTable, setTestCaseSearchValueInTable] = useState(false);
  const [testcaseThreshold, setTestcaseThreshold] = useState();
  const [fileTypeCheck, setFileTypeCheck] = useState();
  const [fileNameTypeCheck, setFileNameTypeCheck] = useState();
  const [fromTypeCheck, setFromTypeCheck] = useState();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [showPopup, setShowPopup] = useState(false);

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleClickOpen = (record, index, row) => {
    setValueSaved(
      record.xls.map((rr) => {
        return {
          Keyword: rr.Keyword,
          Action: rr.Action,
          Flow: rr.Flow,
          Page_Description: rr.Page_Description,
          Page_Name: rr.Page_Name,
          TestData: rr.TestData,
          Test_Case_Id: rr.Test_Case_Id,
          ObjectIdentifier: rr.Object_Xpath,
          tsid: rr.idtest_step,
          wait: rr.wait,
          waittime: rr.waittime,
          Step_Number: rr.Step_Number,
          subAction: rr.subAction,
          teststepthreshold: rr.teststepthreshold,
          TabId: rr.tabid,
          VarName: rr.VarName,
          type: rr.type,
          iframexpath: rr.iframexpath,
          dynamicvalue: rr.isDynamic,
          strategy: rr.strategy,
          script: rr.script,
          scriptfile: rr.scriptfile,
          color: rr.color,
          bgcolor: rr.bgcolor,
          imgpath: rr.imgpath,
          samerowtype: rr.samerowtype,
          samerowindex: rr.samerowindex,
          xpos: rr.xpos,
          ypos: rr.ypos,
          createsAlert: rr.createsAlert,
        };
      }),
    );
    setAdditionalTestCase(state?.tcs);
    setTestCaseName(record.tcs.Test_Case);
    setEnvUrl(record?.envDetails[0]?.envurl);
    setIndexValue(index);
    setOpenDialog(true);
    setTest(record.xls.map((rr) => rr));
  };
  const handleClose = (input, stringOne, StringTwo) => {
    setValueSaved([]);
    setTestCaseName();
    setEnvUrl();
    setIndexValue();
    setOpenDialog(false);
    setSelectedRow();
    if (input === "Save") {
      setRefreshFlag(true);
      setRowWaitTimes({});
      setOpenAlert(true);
      setOpenErrorAlert(true);
      window.myTimer = setInterval(() => {
        getRecording(state);
      }, 5000);
      return () => clearInterval(window.myTimer);
      handleClickOpenAlert();
    }

    if (input === "Delete") {
      setRowWaitTimes({});
      setDeleteIconValueTestStep(stringOne);
      setDeleteIconValueTestCase(StringTwo);
      setOpenModalDeleteAlert(true);
    }
    if (input === "Add Step") {
      setRowWaitTimes({});
      setRefreshFlag(true);
      handleClickOpenAlert();
      handleClickOpenErrorAlert();
      window.myTimer = setInterval(() => {
        getRecording(state);
      }, 5000);
      return () => clearInterval(window.myTimer);
    }
  };
  const handleCloseModalDeleteAlert = () => {
    setOpenModalDeleteAlert(false);
  };
  const navigate = useNavigate();
  const RouteState = useLocation();

  const colors = colorExport();
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const [toggleOn, setToggleOn] = useState(false);

  useEffect(() => {
    getProducts();

    // Get state from URL parameters if present
    const params = new URLSearchParams(window.location.search);
    const stateParam = params.get("state");

    if (stateParam) {
      // Handle state from URL parameters (new tab case)
      const decodedState = JSON.parse(decodeURIComponent(stateParam));
      setState((prev) => {
        getModules(decodedState.idproducts);
        getEnvironments(decodedState.idproducts);
        getModulesCheck(decodedState.details.Module);
        getTestCase(decodedState.details.Module);
        setTestCaseNameChange(decodedState.details.Test_Case);
        return {
          ...prev,
          idproducts: decodedState.idproducts,
          idmodules: decodedState.details.Module,
          idtest_case: decodedState.idtest_case,
          Test_Case: decodedState.Test_Case,
          tcs: decodedState.details,
          DescriptionData: decodedState.details.Test_Case_Desc,
        };
      });
      setRefreshFlag(false);
      clearInterval(window.myTimer);
      getSearchValue(decodedState.Test_Case);
      setValueForValidation(true);
    } else if (RouteState?.state?.fromRecordingPage) {
      // Handle normal navigation through React Router
      setState((prev) => {
        getModules(RouteState.state.idproducts);
        getEnvironments(RouteState.state.idproducts);
        getModulesCheck(RouteState.state.details.Module);
        getTestCase(RouteState.state.details.Module);
        setTestCaseNameChange(RouteState.state.details.Test_Case);
        return {
          ...prev,
          idproducts: RouteState.state.idproducts,
          idmodules: RouteState.state.details.Module,
          idtest_case: RouteState.state.idtest_case,
          Test_Case: RouteState.state.Test_Case,
          tcs: RouteState.state.details,
          DescriptionData: RouteState.state.details.Test_Case_Desc,
        };
      });
      setRefreshFlag(false);
      clearInterval(window.myTimer);
      getSearchValue(RouteState.state.Test_Case);
      window.history.replaceState({}, document.title);
      setValueForValidation(true);
    } else if (RouteState?.state?.toRecordingPage) {
      getSearchValue(RouteState.state.Test_Case);
      setRefreshFlag(false);
      clearInterval(window.myTimer);
      window.history.replaceState({}, document.title);
      setValueForValidation(true);
    } else {
      if (!openDialog && !hidingTick) {
        getRecording(state);
      }
      window.myTimer = setInterval(() => {
        if (!openDialog && !hidingTick) {
          getRecording(state);
        }
      }, 5000);
    }

    return () => clearInterval(window.myTimer);
  }, [RouteState, hidingTick, valueForValidation]);

  useEffect(() => {
    let arr = TestCaseList;
    if (
      !TestCaseList?.find((val) => val.idtest_case === state?.tcs.idtest_case) &&
      !testCaseSearchValue &&
      deleteValue
    ) {
      arr = [...arr, state?.tcs];
      setTestCaseList(arr);
    }
  }, [TestCaseList, testCaseSearchValue]);

  useEffect(() => {
    let arr = TestCaseListOne;
    if (
      !TestCaseListOne.find((val) => val.idtest_case === state?.tcs.idtest_case) &&
      !testCaseSearchValueInTable &&
      deleteValue
    ) {
      arr = [...arr, state?.tcs];
      setTestCaseListOne(arr);
    }
  }, [TestCaseListOne, testCaseSearchValueInTable]);

  useEffect(() => {
    if (TestDataDetails.DependentProduct && TestDataDetails.DependentProduct !== null) {
      getModules(TestDataDetails.DependentProduct);
    }
  }, [TestDataDetails.DependentProduct]);

  useEffect(() => {
    if (TestDataDetails.DependentModule && TestDataDetails.DependentModule !== null) {
      getTestCase(TestDataDetails.DependentModule);
    }
  }, [TestDataDetails.DependentModule]);

  useEffect(() => {
    if (TestDataDetails.DependentTC && TestDataDetails.DependentTC !== null) {
      getSelectFromListData(SelectedRow.idtest_step, TestDataDetails.DependentTC);
    }
  }, [TestDataDetails.DependentTC]);

  useEffect(() => {
    if (TestDataDetails.apiproduct && TestDataDetails.apiproduct !== null) {
      getModules(TestDataDetails.apiproduct);
    }
  }, [TestDataDetails.apiproduct]);

  useEffect(() => {
    if (TestDataDetails.FileName && TestDataDetails.FileName !== null) {
      getSelectFildata(state.idproducts);
    }
  }, [TestDataDetails.FileName]);
  useEffect(() => {
    if (TestDataDetails.DownloadedFile && TestDataDetails.DownloadedFile !== null) {
      getDownloadedFile(state.tcs.idtest_case);
    }
  }, [TestDataDetails.DownloadedFile]);

  useEffect(() => {
    if (TestDataDetails.apiName && TestDataDetails.apiName !== null) {
      getAllApiDetails(TestDataDetails.apimodule);
    }
  }, [TestDataDetails.apiName]);

  const handleClickOpenAlert = () => {
    setOpenAlert(true);
  };
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  const handleClickOpenErrorAlert = () => {
    setOpenErrorAlert(true);
  };

  const handleCloseErrorAlert = () => {
    setOpenErrorAlert(false);
  };
  const handleOpenCopyAlert = () => {
    setOpenCopyAlert(true);
  };
  const handleCloseCopyAlert = () => {
    setOpenCopyAlert(false);
  };
  const handleCloseRenameAlert = () => {
    setOpenRenameAlert(false);
  };
  const handleOpenRenameAlert = () => {
    setOpenRenameAlert(true);
  };
  const handleClickOpenSave = () => {
    setOpenAlertSave(false);
  };
  const handleClickOpenDeleteAlert = () => {
    setOpenDeleteAlert(true);
  };
  const handleCloseDeleteAlert = () => {
    setOpenDeleteAlert(false);
  };
  const handleClickOpenDeletionAlert = () => {
    setOpenDeletionAlert(true);
  };

  const handleCloseDeletion = () => {
    setOpenDeletionAlert(false);
  };

  const handleClickOpenExecute = () => {
    setOpenDialogExecute(true);
  };
  const handleCloseExecute = () => {
    setOpenDialogExecute(false);
  };
  const handleCloseTescase = () => {
    setOpenTestcase(false);
  };

  const handleCloseInfo = () => {
    setOpenInfo(false);
  };

  const getProducts = async (api_input) => {
    await fetchProducts(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setproductList(response.data);
            getResolutionData(response.data[0].idproducts);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //coverageExecute
  const regenrateScreenshot = async (api_input) => {
    await coverageExecute(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setproductList(response.data);
            getResolutionData(response.data[0].idproducts);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getEnvironments = async (id) => {
    await fetchEnvironments(id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setEnvironmentList(response.data);
            setState((prev) => {
              return {
                ...prev,
                envDetails: response.data,
              };
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sendAddStep = async (api_input) => {
    await addStepInrecording(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          }
          // setloader(false);
          else {
            setState((prev) => {
              return {
                ...prev,
                xls: Object.values(response.data.data),
                tcs: response.data.testcase,
                envDetails: response.data.envDetails,
                stepnums: Object.values(response.data.stepnums),
                DescriptionData: response.data.testcase.Test_Case_Desc,
                // resolutionDetails:response.data.resolutionDetails,
              };
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sendEditStep = async (api_input) => {
    await editStepInrecording(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          }
          // setloader(false);
          else {
            setState((prev) => {
              return {
                ...prev,
                xls: Object.values(response.data?.data),
                tcs: response.data.testcase,
                envDetails: response.data.envDetails,
                stepnums: Object.values(response.data.stepnums),
                DescriptionData: response.data.testcase.Test_Case_Desc,
                // resolutionDetails:response.data.resolutionDetails,
              };
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getModules = async (id) => {
    await fetchModules(id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setModuleList(response.data);
            setModuleListOne(response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getModulesCheck = async (id) => {
    await fetchModulesCheck(id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setCheckModuleEnable(response.data.isdisable);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTestCase = async (id) => {
    await fetchTestCasesfromModule(id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setTestCaseList(response.data);
            setTestCaseListOne(response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllApiDetails = async (id) => {
    await fetchAllApiDetails(id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setApiList(response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getRecording = async (api_input) => {
    // const startTime = new Date();
    await fetchRecording(api_input)
      .then((response) => {
        if (response && response.data) {
          if (response.status === 200) {
            if (response.data.message === "fail") {
              CommonMethods(navigate);
            }
            // setloader(false);
            else {
              // const endTime = new Date(); // End timing
              // const elapsedTime = (endTime - startTime) / 1000;
              // console.log("elapsedTime---->",elapsedTime)
              setIsLoading(false);
              setState((prev) => {
                return {
                  ...prev,
                  xls: Object.values(response.data.data),
                  tcs: response.data.testcase,
                  TcThreshold: response.data.testcase.testcasethreshold,
                  proxy: response.data.testcase.proxyurl,
                  SelectBrowserName:
                    response.data.testcase.browser === null
                      ? "Chrome"
                      : response.data.testcase.browser,
                  SelectEnvironmentName: response.data.testcase.envname,
                  SelectEnvironmentUrl: response.data.envDetails
                    ?.filter((item) => item.envurl === response.data.testcase.envurl)
                    .map((item) => item.envurl)[0],
                  SelectRunonce: response.data.testcase.multirun,
                  Height: response.data.testcase.height,
                  DescriptionData: response.data.testcase.Test_Case_Desc,
                  envDetails: response.data.envDetails,
                  stepnums: Object.values(response.data.stepnums),
                  tabsvalues: Object.values(response.data.tabs),
                  VideoUrl: response.data.testcase.videofileurl,
                  continuetest: response.data.testcase.continuetest,
                  forcenewsession: response.data.testcase.forcenewsession,
                  enablevideo: response.data.testcase.enablevideo,
                };
              });
              setTestCaseNameChange(response.data.testcase.Test_Case);
              if (valueForValidation === false) {
                setState((prev) => {
                  getEnvironments(response.data.Product);
                  getModules(response.data.Product);
                  getModulesCheck(response.data.Modules);
                  getTestCase(response.data.Modules);
                  // setTestCaseNameChange(RouteState.state.details.Test_Case)
                  return {
                    ...prev,
                    idproducts: response.data.Product,
                    idmodules: response.data.Modules,
                    idtest_case: response.data.Testcase,
                    Test_Case: response.data.Testcase,
                    // tcs: RouteState.state.details,
                  };
                });
                setValueForValidation(true);
              }
              if (
                state.idtest_case !== "" &&
                response.data.testcase.idtest_case !== "" &&
                response.data.testcase.idtest_case > state.idtest_case
              ) {
                setState((prev) => {
                  // getTestCase(state.idmodules);
                  // getModules(response.data.Product);
                  // setTestCaseNameChange(RouteState.state.details.Test_Case)
                  getTestCase(response.data.Modules);
                  return {
                    ...prev,
                    idproducts: response.data.Product,
                    idmodules: response.data.Modules,
                    idtest_case: response.data.Testcase,
                    Test_Case: response.data.Testcase,
                  };
                });
              }
            }
          }
        } else {
          // Handle the case where response is null or undefined
          console.log("Response is null or undefined");
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const SendEnviromentOnSubmit = async (api_input) => {
    await postEnviromentSubmit(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changeFlow = async (idtest_step, Test_Case_Id, value) => {
    await handleFlowChange(idtest_step, Test_Case_Id, value)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setState((prev) => {
              return {
                ...prev,
                xls: Object.values(response.data.data),
              };
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validateDataFromDB = async (api_input) => {
    await validateDetailsFromDB(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setState((prev) => {
              return {
                ...prev,
                xls: Object.values(response.data.data),
                // resolutionDetails:response.data.resolutionDetails,
              };
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validateDataFromFile = async () => {
    if (!TestDataDetails.file) {
      return;
    }
    // let apicall = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=validate&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${TestDataDetails.testCaseId}&teststepid=${TestDataDetails.id}&valdtype=InFile&testdata=${TestDataDetails.Source}&name=${TestDataDetails.file[0].name}&filefield=${TestDataDetails.filefield}`;
    let apicall;
    if (fileTypeCheck === "xlsx" || fileTypeCheck === "csv") {
      apicall = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=validate&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${TestDataDetails.testCaseId}&teststepid=${TestDataDetails.id}&valdtype=InFile&testdata=${TestDataDetails.Source}&name=${TestDataDetails.file[0].name}&sheetId=${TestDataDetails.sheetId}&cell=${TestDataDetails.cell}&scope=${TestDataDetails.scope}&endRange=${TestDataDetails.endRange}`;
    } else if (fileTypeCheck === "json") {
      apicall = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=validate&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${TestDataDetails.testCaseId}&teststepid=${TestDataDetails.id}&valdtype=InFile&testdata=${TestDataDetails.Source}&name=${TestDataDetails.file[0].name}&filter=${TestDataDetails.filter}&scope=${TestDataDetails.scope}&endRange=${TestDataDetails.endRange}`;
    } else {
      apicall = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=validate&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${TestDataDetails.testCaseId}&teststepid=${TestDataDetails.id}&valdtype=InFile&testdata=${TestDataDetails.Source}&name=${TestDataDetails.file[0].name}`;
    }

    fetch(apicall, {
      method: "POST",
      body: TestDataDetails.file[0],
      headers: {
        "content-type": TestDataDetails.file[0].type,
        "content-length": `${TestDataDetails.file[0].size}`, // 👈 Headers need to be a string
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setState((prev) => {
          return {
            ...prev,
            xls: Object.values(data.data),
            // resolutionDetails:response.data.resolutionDetails,
          };
        });
      })
      .catch((err) => console.error(err));
    setTestDataDetails({
      ...TestDataDetails,
      file: "",
    });
  };
  const validateDataByRegex = async (api_input) => {
    await validateDetailsFromRegex(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setState((prev) => {
              return {
                ...prev,
                xls: Object.values(response.data.data),
                // resolutionDetails:response.data.resolutionDetails,
              };
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validateDataByGoogleSheet = async (api_input) => {
    await validateDetailsFromGoogleSheet(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setState((prev) => {
              return {
                ...prev,
                xls: Object.values(response.data.data),
                // resolutionDetails:response.data.resolutionDetails,
              };
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validateDataByApi = async (api_input) => {
    await validateDetailsFromApi(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setState((prev) => {
              return {
                ...prev,
                xls: Object.values(response.data.data),
                // resolutionDetails:response.data.resolutionDetails,
              };
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validateDataByDate = async (api_input) => {
    await validateDetailsFromDate(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setState((prev) => {
              return {
                ...prev,
                xls: Object.values(response.data.data),
                // resolutionDetails:response.data.resolutionDetails,
              };
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const validateDataByDependence = async (api_input) => {
    await validateDetailsFromDependantTC(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setState((prev) => {
              return {
                ...prev,
                xls: Object.values(response.data.data),
                // resolutionDetails:response.data.resolutionDetails,
              };
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validatefiledatareference = async (api_input) => {
    await validateReferenceFileData(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setState((prev) => {
              return {
                ...prev,
                xls: Object.values(response.data.data),
                // resolutionDetails:response.data.resolutionDetails,
              };
            });
          }
        } else if (response.status !== 200) {
          handleClickOpenErrorAlert();
          setErrorCode(response.code);
          setErrorMessage(response.message);
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };
  const validationdownloadfiles = async (api_input) => {
    await validateDownloadedFile(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setState((prev) => {
              return {
                ...prev,
                xls: Object.values(response.data.data),
                // resolutionDetails:response.data.resolutionDetails,
              };
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const validationdescriptiondata = async (api_input) => {
    await validateDescriptionData(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setState((prev) => {
              return {
                ...prev,
                DescriptionData: state.DescriptionData,

                // resolutionDetails:response.data.resolutionDetails,
              };
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const savingDataInSettings = async (api_input) => {
    await saveSettingsData(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            console.log("api worked");
            setState((prev) => ({
              ...prev,
              continuetest: response.data.testcase.continuetest,
              forcenewsession: response.data.testcase.forcenewsession,
              enablevideo: response.data.testcase.enablevideo,
            }));
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validateDataChangeData = async (api_input) => {
    await validateDetailsChangeText(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setState((prev) => {
              return {
                ...prev,
                xls: Object.values(response.data.data),
                // resolutionDetails:response.data.resolutionDetails,
              };
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validateDataOnScreen = async (api_input) => {
    await validateDetailsOnScreen(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setState((prev) => {
              return {
                ...prev,
                xls: Object.values(response.data.data),
                // resolutionDetails:response.data.resolutionDetails,
              };
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validateDataBySearch = async (api_input) => {
    await validateDetailsBySearch(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setState((prev) => {
              return {
                ...prev,
                xls: Object.values(response.data.data),
                // resolutionDetails:response.data.resolutionDetails,
              };
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [selectedValue, setSelectedValue] = useState("before");
  const [textBoxValue, setTextBoxValue] = useState("");

  const handleDropDownChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleTextBoxChange = (event) => {
    setTextBoxValue(event.target.value);

    if (event.target.value > 0) {
      // Call backend API with row.teststepid
    }
  };

  const searchTestCaseInMod = async (mid, key) => {
    await handleTestCaseSearchByMid(mid, key)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setTestCaseSearchValue(true);
            setTestCaseList(response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const searchTestCaseInModinTable = async (mid, key) => {
    await handleTestCaseSearchByMid(mid, key)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setTestCaseSearchValueInTable(true);
            setTestCaseListOne(response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSearchValue = async (api_input) => {
    let apicall = "";
    apicall = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=search&companyid=${temp.companyid}&token=${temp.token}&testcaseid=${api_input}`;
    fetch(apicall, {
      method: "POST",
      body: state.file,
      headers: {
        "content-type": state.file.type,
        "content-length": `${state.file.size}`, // 👈 Headers need to be a string
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setState((prev) => {
          if (RouteState?.state?.toRecordingPage) {
            getModules(data.Product);
            getEnvironments(data.Product);
            getModulesCheck(data.Modules);
            getTestCase(data.Modules);
          }
          return {
            ...prev,
            xls: Object.values(data.data),
            tcs: data.testcase,
            TcThreshold: data.testcase.testcasethreshold,
            proxy: data.testcase.proxyurl,
            stepnums: Object.values(data.stepnums),
            tabsvalues: Object.values(data.tabs),
            VideoUrl: data.testcase.videofileurl,
            DescriptionData: data.testcase.Test_Case_Desc,
            idproducts: data.Product,
            idmodules: data.Modules,
            idtest_case: data.Testcase,
            Test_Case: data.Testcase,
            SelectEnvironmentName: data.testcase.envname,
            SelectEnvironmentUrl: data.envDetails
              .filter((item) => item.envurl === data.testcase.envurl)
              .map((item) => item.envurl)[0],
            continuetest: data.testcase.continuetest,
            forcenewsession: data.testcase.forcenewsession,
            enablevideo: data.testcase.enablevideo,
          };
        });
        setTestDataDetails({
          waittime: "",
        });
        setTestCaseNameChange(data.testcase.Test_Case);
        setIsLoading(false);
      })
      .catch((err) => console.error(err));
  };

  const executeTest = ({
    id,
    SelectEnvironmentName,
    SelectEnvironmentUrl,
    SelectBrowserName,
    SelectResolution,
    SelectRunonce,
    TcThreshold,
    testcase,
    proxyurl,
    baseline,
  }) => {
    let apicall =
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=execute&companyid=${temp.companyid}` +
      `&token=${temp.token}&testcaseid=${id}&testcase=${testcase}&envName=${SelectEnvironmentName}` +
      `&envUrl=${SelectEnvironmentUrl}&browsername=${SelectBrowserName}&resolution=${SelectResolution}` +
      `&runonce=${SelectRunonce}&threshold=${TcThreshold}&proxyurl=${proxyurl}&baseline=${baseline}`;
    fetch(apicall, {
      method: "POST",
      body: state.file,
      headers: {
        "content-type": state.file.type,
        "content-length": `${state.file.size}`, // 👈 Headers need to be a string
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data === null) {
        } else {
          setState((prev) => {
            return {
              ...prev,
              xls: Object.values(data.data),
            };
          });
        }
        navigate("/dashboard/app", { state: { isExecuteClick: true } });
      })
      .catch((err) => console.error(err));
    setState({ ...state, display: "execute" });
  };
  const deleteTableField = async (id, tcid) => {
    await deleteStep(id, tcid)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            // setloader(false);
            setState((prev) => {
              return {
                ...prev,
                xls: Object.values(response.data.data),
              };
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSteps = async (tcid) => {
    await fetchSteps(tcid)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            // setloader(false);
            setGetAllSteps(response.data.nlpsteps);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllInfo = async (tcid) => {
    // setInfo(response.data.nlpsteps)
  };

  const genCode = async (tcid, tcname) => {
    await generateCode(tcid, tcname)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            // setloader(false);
            //setGetAllSteps(response.data.nlpsteps)
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const tsDownload = async (tcid) => {
    await downloadTestSteps(tcid)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const pomFileDownload = async (pid) => {
    await downloadPomZipFile(pid)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteTestcase = async (tcid) => {
    await deleteTestCaseName(tcid)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setTestCaseNameChange(response.data.Test_Case);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const ChangeTableRowData = async (api_input) => {
    await handleTDChange(api_input)
      .then((response) => {
        if (response.status === 200) {
          // setloader(false);
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setState((prev) => {
              return {
                ...prev,
                xls: Object.values(response?.data.data),
              };
            });
            setTestDataDetails({
              id: "",
              Source: "",
              UploadString: "",
              GenerateDataType: "",
              generateDataLength: "",
              useVar: "",
              FileName: "",
              DownloadedFile: "",
              dynamicvalue: "",
              AssertionValue: "",
              DBType: "",
              DBOtherType: "",
              DBURL: "",
              DBusername: "",
              DBpwd: "",
              DBQuery: "",
              file: "",
              filename: "",
              filefield: "",
              SelectEnvironmentName: "",
              SelectEnvironmentUrl: "",
              regex: "",
              testdata: "",
              xpath: "",
              search: "",
              ElementType: "",
              ElementIndex: "",
              valTarget: "OnScreen",
              customerEmail: "",
              customerPassword: "",
              EmailSelectionCriteria: "",
              EmailFilter: "",
              createsAlert: "",
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getSelectFromListData = async (api_input, tcid) => {
    await fetchSelectFromListData(api_input, tcid)
      .then((response) => {
        if (response.status === 200) {
          // setloader(false);
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            SetListData(response.data.list);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSelectFildata = async (id) => {
    await fetchfiledatafromDB(id)
      .then((response) => {
        if (response.status === 200) {
          // setloader(false);
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setFiledropdowndata(response.data.list);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDownloadedFile = async (tcid) => {
    await fetchdownloadedfiles(tcid)
      .then((response) => {
        if (response.status === 200) {
          // setloader(false);
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setDownloadedFileData(response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getResolutionData = async (id) => {
    await getResolution(id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setState((prev) => {
              return {
                ...prev,
                resolutionDetails: response.data.ResolutionList,
              };
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleUploadClick = () => {
    if (!TestDataDetails.file) {
      return;
    }
    let apicall;
    if (fileTypeCheck === "xlsx" || fileTypeCheck === "csv") {
      apicall = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestData&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${TestDataDetails.testCaseId}&teststepid=${TestDataDetails.id}&testdata=${TestDataDetails.Source}&name=${TestDataDetails.file[0].name}&sheetId=${TestDataDetails.sheetId}&cell=${TestDataDetails.cell}&scope=${TestDataDetails.scope}&endRange=${TestDataDetails.endRange}`;
    } else if (fileTypeCheck === "json") {
      apicall = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestData&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${TestDataDetails.testCaseId}&teststepid=${TestDataDetails.id}&testdata=${TestDataDetails.Source}&name=${TestDataDetails.file[0].name}&filter=${TestDataDetails.filter}&scope=${TestDataDetails.scope}&endRange=${TestDataDetails.endRange}`;
    } else {
      apicall = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestData&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${TestDataDetails.testCaseId}&teststepid=${TestDataDetails.id}&testdata=${TestDataDetails.Source}&name=${TestDataDetails.file[0].name}`;
    }
    fetch(apicall, {
      method: "POST",
      body: TestDataDetails.file[0],
      headers: {
        "content-type": TestDataDetails.file[0].type,
        "content-length": `${TestDataDetails.file[0].size}`, // 👈 Headers need to be a string
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setState((prev) => {
          return {
            ...prev,
            xls: Object.values(data.data),
            // resolutionDetails:response.data.resolutionDetails,
          };
        });
      })
      .catch((err) => console.error(err));
    setTestDataDetails({
      ...TestDataDetails,
      file: "",
      filefield: "",
    });
  };
  const handleFileChange = (e) => {
    if (e.target.files) {
      setTestDataDetails((prevState) => ({
        ...prevState,
        file: e.target.files,
        filename: e.target.files[0].name,
      }));
      setFileNameTypeCheck();
      const filename = e.target.files[0].name;
      const parts = filename.split(".");
      const fileExtension = parts.pop();

      setFileTypeCheck(fileExtension);
    }
  };

  const handleNameType = (name) => {
    if (name !== null) {
      const parts = name.split(".");
      const fileExtension = parts.pop();
      setFileNameTypeCheck(fileExtension);
    }
  };

  const handleFromType = (name) => {
    if (name !== null) {
      const parts = name.split(".");
      const fileExtension = parts.pop();
      setTestDataDetails((prevState) => ({
        ...prevState,
        fromFileType: fileExtension,
        FileName: name,
      }));
      setFromTypeCheck(fileExtension);
    }
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - state.xls.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const Formfield = (row) => {
    return (
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-end",
          //
        }}
      >
        <FormControl>
          <InputLabel id={`TestData Source-${row.idtest_step}`}>Test Data Source</InputLabel>
          <Select
            key={row.idtest_step}
            labelId={`TestData Source-${row.idtest_step}`}
            id={`row.idtest_step-${row.idtest_step}`}
            defaultValue={TestDataDetails.Source}
            label="TestData Source"
            name="Test Data Source"
            variant="standard"
            onChange={(event) => {
              handleNameType(TestDataDetails.filename);
              setTestDataDetails({
                ...TestDataDetails,
                id: row.idtest_step,
                testCaseId: state.tcs.idtest_case,
                Source: event.target.value,
                testdata: row.TestData,
              });
              setFileTypeCheck();
              if (event.target.value === "UseVariableFromTheScenario") {
                getSelectFromListData(row.idtest_step, state.tcs.idtest_case);
              }
              if (event.target.value === "FromReferenceFile") {
                getSelectFildata(state.idproducts);
              }
            }}
            style={{ minWidth: 168, marginRight: "2rem", alignItems: "center" }}
          >
            {selectLIstData.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {TestDataDetails.Source === "ChangeTestData" && (
          <TextField
            sx={{ marginRight: "2rem" }}
            label="New Data"
            variant="standard"
            defaultValue={TestDataDetails.UploadString}
            onChange={(event) => {
              event.preventDefault();
              setTestDataDetails((prev) => {
                return {
                  ...prev,
                  UploadString: event.target.value,
                };
              });
            }}
          />
        )}
        {TestDataDetails.Source === "GenData" && (
          <>
            <FormControl>
              <InputLabel id={`Generate Data Type-${row.idtest_step}`}>
                Generate Data Type
              </InputLabel>
              <Select
                labelId={`Generate Data Type-${row.idtest_step}`}
                id={`row.idtest_step-${row.idtest_step}`}
                value={TestDataDetails.GenerateDataType}
                label="Generate Data Type"
                variant="standard"
                onChange={(event) => {
                  setTestDataDetails({
                    ...TestDataDetails,
                    GenerateDataType: event.target.value,
                  });
                }}
                style={{
                  minWidth: 192,
                  marginRight: "2rem",
                  alignItems: "center",
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="CHAR">CHAR</MenuItem>
                <MenuItem value="ALPHA">ALPHA</MenuItem>
                <MenuItem value="NUMBER">NUMBER</MenuItem>
                <MenuItem value="EMAIL">EMAIL</MenuItem>
                <MenuItem value="TODAY">TODAY</MenuItem>
              </Select>
            </FormControl>
            <TextField
              sx={{ marginRight: "2rem" }}
              label="Generate Data Length"
              variant="standard"
              defaultValue={TestDataDetails.generateDataLength}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    generateDataLength: event.target.value,
                  };
                });
              }}
            />
          </>
        )}
        {TestDataDetails.Source === "FromApi" && (
          <>
            <FormControl>
              <InputLabel id={`DB Type-${row.idtest_step}`}>Product</InputLabel>
              <Select
                labelId={"product name"}
                id={"product name Select"}
                variant="standard"
                value={TestDataDetails.apiproduct}
                name={"product"}
                label="Product"
                onChange={(event) => {
                  setTestDataDetails({
                    ...TestDataDetails,
                    apiproduct: event.target.value,
                  });
                  getModules(event.target.value);
                }}
                style={{
                  minWidth: 100,
                  marginRight: "2rem",
                  alignItems: "center",
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {productList.map((item, index) => (
                  <MenuItem key={index} value={item.idproducts}>
                    {item.productname}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {TestDataDetails.apiproduct !== "" && TestDataDetails.apiproduct !== 0 && (
              <FormControl>
                <InputLabel id={"module name"}> Module</InputLabel>
                <Select
                  labelId={"module name"}
                  id={"module name Select"}
                  variant="standard"
                  defaultValue={TestDataDetails.apimodule}
                  label="Module"
                  onChange={(event) => {
                    setTestDataDetails({
                      ...TestDataDetails,
                      apimodule: event.target.value,
                    });
                    getAllApiDetails(event.target.value);
                  }}
                  style={{
                    minWidth: 100,
                    marginRight: "2rem",
                    alignItems: "center",
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {ModuleListOne.map((item, index) => (
                    <MenuItem key={index} value={item.idmodules}>
                      {item.modulename}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {TestDataDetails.apimodule !== "" && TestDataDetails.apimodule !== 0 && (
              <>
                <FormControl>
                  <InputLabel id={"Api name"}> Api Name </InputLabel>
                  <Select
                    labelId={"Api name"}
                    id={"Api Name Select"}
                    variant="standard"
                    defaultValue={TestDataDetails.apiName}
                    label="Api name"
                    onChange={(event) => {
                      const selectedApiName = event.target.value;
                      const selectedApi = apiList.find((item) => item.idapi === selectedApiName);
                      setTestDataDetails({
                        ...TestDataDetails,
                        apiName: selectedApiName, //wanted the id to get the name
                        apiId: selectedApi ? selectedApi.name : null, //wanted the name to pass in the api
                      });
                      setRefreshFlag(false); //to close auto refesh on button
                      clearInterval(window.myTimer); ////to close auto refesh
                    }}
                    style={{
                      minWidth: 100,
                      marginRight: "2rem",
                      alignItems: "center",
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {apiList.map((item, index) => (
                      <MenuItem key={index} value={item.idapi}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
            {TestDataDetails.apimodule !== "" &&
              TestDataDetails.apimodule !== 0 &&
              TestDataDetails.apiName !== "" &&
              TestDataDetails.apiName !== undefined &&
              TestDataDetails.apiName !== 0 && (
                <>
                  <TextField
                    // sx={{ marginRight: "2rem" }}
                    sx={{ marginRight: "2rem", width: "20%" }}
                    label="Api Param"
                    variant="standard"
                    defaultValue={TestDataDetails.apiParam}
                    onChange={(event) => {
                      event.preventDefault();
                      setTestDataDetails((prev) => {
                        return {
                          ...prev,
                          apiParam: event.target.value,
                        };
                      });
                    }}
                  />
                  <TextField
                    sx={{ width: "35%" }}
                    // sx={{ marginRight: "2rem" }}
                    label="Api Query"
                    variant="standard"
                    defaultValue={TestDataDetails.apiQuery}
                    onChange={(event) => {
                      event.preventDefault();
                      setTestDataDetails((prev) => {
                        return {
                          ...prev,
                          apiQuery: event.target.value,
                        };
                      });
                    }}
                  />
                </>
              )}

            {/* {(TestDataDetails.DependentProduct !== "" && TestDataDetails.DependentProduct !== 0) && (TestDataDetails.DependentModule !== 0 && TestDataDetails.DependentModule !== "") && (
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  marginLeft: "0rem",
                  display: "flex",
                  alignItems: "center",
                  width: 300,
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search Api Name here..."
                  inputProps={{ "aria-label": "Search Api Name here..." }}
                  value={SearchStringTestDataSource}
                  onChange={(e) => {
                    e.preventDefault();
                    setSearchStringTestDataSource(e.target.value);
                  }}
                />
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                  onClick={() => {
                    searchTestCaseInModinTable(TestDataDetails.DependentModule, SearchStringTestDataSource);
                  }}
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            )} */}

            {TestDataDetails.apimodule !== "" && TestDataDetails.apimodule !== 0 && (
              <Button
                onClick={(event) => {
                  setTestDataDetails({
                    ...TestDataDetails,
                    apimodule: "",
                    apiName: "",
                    apiproduct: "",
                    apiId: "",
                    apiQuery: "",
                    apiParam: "",
                  });
                  setSearchStringTestDataSource("");
                }}
                sx={{ marginLeft: 3 }}
              >
                Clear
              </Button>
            )}
          </>
        )}
        {TestDataDetails.Source === "GoogleSheet" && (
          <>
            <TextField
              sx={{ marginRight: "2rem", width: "20%" }}
              label="Sheet Id"
              variant="standard"
              defaultValue={TestDataDetails.filename}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    filename: event.target.value,
                  };
                });
              }}
            />
            <TextField
              sx={{ marginX: "2rem" }}
              label="Sheet"
              variant="standard"
              defaultValue={TestDataDetails.sheetId}
              onChange={(event) => {
                setTestDataDetails({
                  ...TestDataDetails,
                  sheetId: event.target.value,
                });
              }}
            />
            <TextField
              sx={{ marginX: "2rem", width: "4%" }}
              label="Cell"
              variant="standard"
              defaultValue={TestDataDetails.cell}
              onChange={(event) => {
                setTestDataDetails({
                  ...TestDataDetails,
                  cell: event.target.value,
                });
              }}
            />
            <div>
              <Typography>Scope</Typography>
              <Select
                variant="standard"
                key={row.idtest_step}
                labelId={`demo-simple-select-label-${row.idtest_step}`}
                id={`row.idtest_step-${row.idtest_step}`}
                value={TestDataDetails.scope}
                label="Scope"
                onChange={(event) => {
                  setTestDataDetails({
                    ...TestDataDetails,
                    scope: event.target.value,
                  });
                }}
              >
                <MenuItem value="Multi Run">Multi Run</MenuItem>
                <MenuItem value="Multi Execution">Multi Execution</MenuItem>
                <MenuItem value="Single Run">Single Run</MenuItem>
              </Select>
            </div>
            {TestDataDetails.scope === "Multi Run" ? (
              <TextField
                sx={{ marginX: "2rem", width: "4%" }}
                label="End Range"
                variant="standard"
                defaultValue={TestDataDetails.endRange}
                onChange={(event) => {
                  setTestDataDetails({
                    ...TestDataDetails,
                    endRange: event.target.value,
                  });
                }}
              />
            ) : (
              ""
            )}
            {/* <TextField
              sx={{ marginRight: "2rem" }}
              label="Field Name"
              variant="standard"
              defaultValue={TestDataDetails.filefield}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    filefield: event.target.value,
                  };
                });
              }}
            /> */}
          </>
        )}
        {TestDataDetails.Source === "SameRow" && (
          <>
            <FormControl>
              <InputLabel id={`SameRow-${row.idtest_step}`}>Element Type</InputLabel>
              <Select
                labelId={`SameRow-${row.idtest_step}`}
                id={`row.idtest_step-${row.idtest_step}`}
                value={TestDataDetails.ElementType}
                label="Element Type"
                variant="standard"
                onChange={(event) => {
                  setTestDataDetails({
                    ...TestDataDetails,
                    ElementType: event.target.value,
                  });
                }}
                style={{
                  minWidth: 192,
                  marginRight: "2rem",
                  alignItems: "center",
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="IMG">Image</MenuItem>
              </Select>
            </FormControl>
            <TextField
              sx={{ marginRight: "2rem" }}
              label="Element Index"
              variant="standard"
              value={TestDataDetails.ElementIndex}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    ElementIndex: event.target.value,
                  };
                });
              }}
            />
          </>
        )}

        {TestDataDetails.Source === "UseVariableFromTheScenario" && (
          <FormControl>
            <InputLabel id={`Use Var From this Scenario-${row.idtest_step}`}>
              Use Var From this Scenario
            </InputLabel>
            <Select
              key={row.idtest_step}
              variant="standard"
              labelId={`Use Var From this Scenario-${row.idtest_step}`}
              id={`row.idtest_step-${row.idtest_step}`}
              value={TestDataDetails.useVar}
              label="Use Var From this Scenario"
              onChange={(event) => {
                setTestDataDetails({
                  ...TestDataDetails,
                  useVar: event.target.value,
                });
              }}
              style={{
                minWidth: 240,
                marginRight: "2rem",
                alignItems: "center",
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {ListData.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {TestDataDetails.Source === "FromFile" && (
          <>
            {!TestDataDetails.file && (
              <TextField
                sx={{ marginX: "2rem", width: "15%" }}
                label="File Name"
                variant="standard"
                defaultValue={TestDataDetails.filename}
                disabled={true}
              />
            )}
            <TextField
              type="file"
              id="recording-file-upload"
              onChange={(event) => {
                handleFileChange(event);
              }}
              variant="standard"
              sx={{ width: "20%" }}
            />
            {/* <TextField
              sx={{ marginX: "2rem" }}
              label="File Field"
              variant="standard"
              defaultValue={TestDataDetails.filefield}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    filefield: event.target.value,
                  };
                });
              }}
            /> */}
            {fileTypeCheck === "xlsx" ||
            fileTypeCheck === "csv" ||
            fileNameTypeCheck === "xlsx" ||
            fileNameTypeCheck === "csv" ? (
              <>
                <TextField
                  sx={{ marginX: "2rem" }}
                  label="Sheet"
                  variant="standard"
                  defaultValue={TestDataDetails.sheetId}
                  onChange={(event) => {
                    setTestDataDetails({
                      ...TestDataDetails,
                      sheetId: event.target.value,
                    });
                  }}
                />
                <TextField
                  sx={{ marginX: "2rem", width: "4%" }}
                  label="Cell"
                  variant="standard"
                  defaultValue={TestDataDetails.cell}
                  onChange={(event) => {
                    setTestDataDetails({
                      ...TestDataDetails,
                      cell: event.target.value,
                    });
                  }}
                />
                <div>
                  <Typography>Scope</Typography>
                  <Select
                    variant="standard"
                    key={row.idtest_step}
                    labelId={`demo-simple-select-label-${row.idtest_step}`}
                    id={`row.idtest_step-${row.idtest_step}`}
                    value={TestDataDetails.scope}
                    label="Scope"
                    onChange={(event) => {
                      setTestDataDetails({
                        ...TestDataDetails,
                        scope: event.target.value,
                      });
                    }}
                  >
                    <MenuItem value="Multi Run">Multi Run</MenuItem>
                    <MenuItem value="Multi Execution">Multi Execution</MenuItem>
                    <MenuItem value="Single Run">Single Run</MenuItem>
                  </Select>
                </div>
                {TestDataDetails.scope === "Multi Run" ? (
                  <TextField
                    sx={{ marginX: "2rem", width: "4%" }}
                    label="End Range"
                    variant="standard"
                    defaultValue={TestDataDetails.endRange}
                    onChange={(event) => {
                      setTestDataDetails({
                        ...TestDataDetails,
                        endRange: event.target.value,
                      });
                    }}
                  />
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}

            {fileTypeCheck === "json" || fileNameTypeCheck === "json" ? (
              <>
                <TextField
                  sx={{ marginX: "2rem", width: "25%" }}
                  label="Filter"
                  variant="standard"
                  defaultValue={TestDataDetails.filter}
                  onChange={(event) => {
                    setTestDataDetails({
                      ...TestDataDetails,
                      filter: event.target.value,
                    });
                  }}
                />
                <div>
                  <Typography>Scope</Typography>
                  <Select
                    variant="standard"
                    key={row.idtest_step}
                    labelId={`demo-simple-select-label-${row.idtest_step}`}
                    id={`row.idtest_step-${row.idtest_step}`}
                    value={TestDataDetails.scope}
                    label="Scope"
                    onChange={(event) => {
                      setTestDataDetails({
                        ...TestDataDetails,
                        scope: event.target.value,
                      });
                    }}
                  >
                    <MenuItem value="Multi Run">Multi Run</MenuItem>
                    <MenuItem value="Multi Execution">Multi Execution</MenuItem>
                    <MenuItem value="Single Run">Single Run</MenuItem>
                  </Select>
                </div>
                {TestDataDetails.scope === "Multi Run" ? (
                  <TextField
                    sx={{ marginX: "2rem", width: "25%" }}
                    label="End Range"
                    variant="standard"
                    defaultValue={TestDataDetails.endRange}
                    onChange={(event) => {
                      setTestDataDetails({
                        ...TestDataDetails,
                        endRange: event.target.value,
                      });
                    }}
                  />
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
            <Button
              component="label"
              size="medium"
              onClick={() => {
                handleUploadClick();
                handleClickOpenAlert();
              }}
              sx={{ marginLeft: 4 }}
            >
              Upload
            </Button>
          </>
        )}

        {TestDataDetails.Source === "Script" && (
          <>
            {!TestDataDetails.file && (
              <TextField
                sx={{ marginX: "2rem", width: "15%" }}
                label="File Name"
                variant="standard"
                defaultValue={TestDataDetails.filename}
                disabled={true}
              />
            )}
            <TextField
              type="file"
              id="recording-file-upload"
              onChange={(event) => {
                handleFileChange(event);
              }}
              variant="standard"
              sx={{ width: "30%" }}
            />
            {/* <TextField
              sx={{ marginX: "2rem" }}
              label="File Field"
              variant="standard"
              defaultValue={TestDataDetails.filefield}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    filefield: event.target.value,
                  };
                });
              }}
            /> */}

            <Button
              component="label"
              size="medium"
              onClick={() => {
                handleUploadClick();
                handleClickOpenAlert();
              }}
              sx={{ marginLeft: 4 }}
            >
              Upload
            </Button>
          </>
        )}

        {TestDataDetails.Source === "FromReferenceFile" && (
          <>
            <FormControl>
              <InputLabel id={`FromReferenceFile-${row.idtest_step}`}>Select File</InputLabel>

              <Select
                key={row.idtest_step}
                variant="standard"
                labelId={`FromReferenceFile-${row.idtest_step}`}
                id={`row.idtest_step-${row.idtest_step}`}
                label="FromReferenceFile"
                value={TestDataDetails.FileName}
                onChange={(event) => {
                  handleFromType(event.target.value);
                  // setTestDataDetails({
                  //   ...TestDataDetails,
                  //   FileName: event.target.value,
                  //   // id: row.idtest_step,
                  // });
                  // setSelectedvaluefile(event.target.value)
                }}
                sx={{ width: 180 }}
              >
                {filedropdowndata.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {fromTypeCheck === "xlsx" || fromTypeCheck === "csv" ? (
              <>
                <TextField
                  sx={{ marginX: "2rem" }}
                  label="Sheet"
                  variant="standard"
                  defaultValue={TestDataDetails.sheetId}
                  onChange={(event) => {
                    setTestDataDetails({
                      ...TestDataDetails,
                      sheetId: event.target.value,
                    });
                  }}
                />
                <TextField
                  sx={{ marginX: "2rem", width: "4%" }}
                  label="Cell"
                  variant="standard"
                  defaultValue={TestDataDetails.cell}
                  onChange={(event) => {
                    setTestDataDetails({
                      ...TestDataDetails,
                      cell: event.target.value,
                    });
                  }}
                />
                <div>
                  <Typography>Scope</Typography>
                  <Select
                    variant="standard"
                    key={row.idtest_step}
                    labelId={`demo-simple-select-label-${row.idtest_step}`}
                    id={`row.idtest_step-${row.idtest_step}`}
                    value={TestDataDetails.scope}
                    label="Scope"
                    onChange={(event) => {
                      setTestDataDetails({
                        ...TestDataDetails,
                        scope: event.target.value,
                      });
                    }}
                  >
                    <MenuItem value="Multi Run">Multi Run</MenuItem>
                    <MenuItem value="Multi Execution">Multi Execution</MenuItem>
                    <MenuItem value="Single Run">Single Run</MenuItem>
                  </Select>
                </div>
                {TestDataDetails.scope === "Multi Run" ? (
                  <TextField
                    sx={{ marginX: "2rem", width: "4%" }}
                    label="End Range"
                    variant="standard"
                    defaultValue={TestDataDetails.endRange}
                    onChange={(event) => {
                      setTestDataDetails({
                        ...TestDataDetails,
                        endRange: event.target.value,
                      });
                    }}
                  />
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
            {fromTypeCheck === "json" ? (
              <>
                <TextField
                  sx={{ marginX: "2rem", width: "25%" }}
                  label="Filter"
                  variant="standard"
                  defaultValue={TestDataDetails.filter}
                  onChange={(event) => {
                    setTestDataDetails({
                      ...TestDataDetails,
                      filter: event.target.value,
                    });
                  }}
                />
                <div>
                  <Typography>Scope</Typography>
                  <Select
                    variant="standard"
                    key={row.idtest_step}
                    labelId={`demo-simple-select-label-${row.idtest_step}`}
                    id={`row.idtest_step-${row.idtest_step}`}
                    value={TestDataDetails.scope}
                    label="Scope"
                    onChange={(event) => {
                      setTestDataDetails({
                        ...TestDataDetails,
                        scope: event.target.value,
                      });
                    }}
                  >
                    <MenuItem value="Multi Run">Multi Run</MenuItem>
                    <MenuItem value="Multi Execution">Multi Execution</MenuItem>
                    <MenuItem value="Single Run">Single Run</MenuItem>
                  </Select>
                </div>
                {TestDataDetails.scope === "Multi Run" ? (
                  <TextField
                    sx={{ marginX: "2rem", width: "25%" }}
                    label="End Range"
                    variant="standard"
                    defaultValue={TestDataDetails.endRange}
                    onChange={(event) => {
                      setTestDataDetails({
                        ...TestDataDetails,
                        endRange: event.target.value,
                      });
                    }}
                  />
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </>
        )}

        {TestDataDetails.Source === "FromDB" && (
          <>
            {/* <FormControl>
              <InputLabel id={`DB Type-${row.idtest_step}`}>DB Type</InputLabel>
              <Select
                key={row.idtest_step}
                labelId={`DB Type-${row.idtest_step}`}
                variant="standard"
                id={`row.idtest_step-${row.idtest_step}`}
                value={TestDataDetails.DBType}
                label="list data Source"
                onChange={(event) => {
                  setTestDataDetails({
                    ...TestDataDetails,
                    DBType: event.target.value,
                  });
                }}
                style={{
                  minWidth: 100,
                  marginRight: "2rem",
                  alignItems: "center",
                }}
              >
                {DBTypeLIstData.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {TestDataDetails.DBType === "Other" && (
              <TextField
                sx={{ marginRight: "2rem" }}
                label="DataBase Type"
                variant="standard"
                defaultValue={TestDataDetails.DBOtherType}
                onChange={(event) => {
                  event.preventDefault();
                  setTestDataDetails((prev) => {
                    return {
                      ...prev,
                      DBOtherType: event.target.value,
                    };
                  });
                }}
              />
            )}
            <TextField
              sx={{ marginRight: "2rem" }}
              label="DB URL"
              variant="standard"
              defaultValue={TestDataDetails.DBURL}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    DBURL: event.target.value,
                  };
                });
              }}
            />
            <TextField
              sx={{ marginRight: "2rem" }}
              label="DB username"
              variant="standard"
              defaultValue={TestDataDetails.DBusername}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    DBusername: event.target.value,
                  };
                });
              }}
            />
            <TextField
              sx={{ marginRight: "2rem" }}
              label="DB password"
              variant="standard"
              defaultValue={TestDataDetails.DBpwd}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    DBpwd: event.target.value,
                  };
                });
              }}
            /> */}
            <TextField
              sx={{ marginRight: "2rem", width: "50%" }}
              label="DB Query(Ensure That There Should be One Select and Where Clause)"
              variant="standard"
              defaultValue={TestDataDetails.DBQuery}
              onChange={(event) => {
                event.preventDefault();
                const { value } = event.target;
                const isSelectAndWherePresent =
                  value.toLowerCase().includes("select") && value.toLowerCase().includes("where");
                setIsSubmitDisabled(!isSelectAndWherePresent);
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    DBQuery: event.target.value,
                  };
                });
              }}
            />
          </>
        )}

        {TestDataDetails.Source === "FromEmail" && (
          <>
            <TextField
              sx={{ marginRight: "2rem", width: "10%" }}
              label="Email"
              variant="standard"
              defaultValue={TestDataDetails.customerEmail}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    customerEmail: event.target.value,
                  };
                });
              }}
            />
            <TextField
              sx={{ marginRight: "2rem", width: "10%" }}
              label="Password"
              type="password"
              variant="standard"
              defaultValue={TestDataDetails.customerPassword}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    customerPassword: event.target.value,
                  };
                });
              }}
            />
            <TextField
              sx={{ marginRight: "2rem", width: "25%" }}
              label="Selection Criterion"
              variant="standard"
              defaultValue={TestDataDetails.EmailSelectionCriteria}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    EmailSelectionCriteria: event.target.value,
                  };
                });
              }}
            />
            <TextField
              sx={{ marginRight: "2rem", width: "25%" }}
              label="Filter"
              variant="standard"
              defaultValue={TestDataDetails.EmailFilter}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    EmailFilter: event.target.value,
                  };
                });
              }}
            />
          </>
        )}

        {TestDataDetails.Source === "DependentonOtherTestScenario" && (
          <>
            <FormControl>
              <InputLabel id={`DB Type-${row.idtest_step}`}>Product</InputLabel>
              <Select
                labelId={"product name"}
                id={"product name Select"}
                variant="standard"
                value={TestDataDetails.DependentProduct}
                name={"product"}
                label="Product"
                onChange={(event) => {
                  setTestDataDetails({
                    ...TestDataDetails,
                    DependentProduct: event.target.value,
                  });
                  getModules(event.target.value);
                }}
                style={{
                  minWidth: 100,
                  marginRight: "2rem",
                  alignItems: "center",
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {productList.map((item, index) => (
                  <MenuItem key={index} value={item.idproducts}>
                    {item.productname}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {TestDataDetails.DependentProduct !== "" && TestDataDetails.DependentProduct !== 0 && (
              <FormControl>
                <InputLabel id={"module name"}> Module</InputLabel>
                <Select
                  labelId={"module name"}
                  id={"module name Select"}
                  variant="standard"
                  defaultValue={TestDataDetails.DependentModule}
                  label="Module"
                  onChange={(event) => {
                    setTestDataDetails({
                      ...TestDataDetails,
                      DependentModule: event.target.value,
                    });
                    getTestCase(event.target.value);
                  }}
                  style={{
                    minWidth: 100,
                    marginRight: "2rem",
                    alignItems: "center",
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {ModuleListOne.map((item, index) => (
                    <MenuItem key={index} value={item.idmodules}>
                      {item.modulename}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {TestDataDetails.DependentModule !== "" && TestDataDetails.DependentModule !== 0 && (
              <FormControl>
                <InputLabel id={"Test Case name"}> Test Cases</InputLabel>
                <Select
                  labelId={"Test Case name"}
                  id={"Test Case Name Select"}
                  variant="standard"
                  defaultValue={TestDataDetails.DependentTC}
                  label="Test Case"
                  onChange={(event) => {
                    setTestDataDetails({
                      ...TestDataDetails,
                      DependentTC: event.target.value,
                    });
                    setRefreshFlag(false); //to close auto refesh on button
                    clearInterval(window.myTimer); ////to close auto refesh
                    getSelectFromListData(row.idtest_step, event.target.value);
                  }}
                  style={{
                    minWidth: 100,
                    marginRight: "2rem",
                    alignItems: "center",
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {TestCaseListOne.map((item, index) => (
                    <MenuItem key={index} value={item.idtest_case}>
                      {item.Test_Case}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {TestDataDetails.DependentTC !== "" && TestDataDetails.DependentTC !== 0 && (
              <FormControl>
                <InputLabel id={`Use Var From this Scenario-${row.idtest_step}`}>
                  Use Var From this Scenario
                </InputLabel>
                <Select
                  key={row.idtest_step}
                  variant="standard"
                  labelId={`Use Var From this Scenario-${row.idtest_step}`}
                  id={`row.idtest_step-${row.idtest_step}`}
                  defaultValue={TestDataDetails.useVar}
                  label="Use Var From this Scenario"
                  onChange={(event) => {
                    setTestDataDetails({
                      ...TestDataDetails,
                      useVar: event.target.value,
                      testCaseId: row.Test_Case_Id,
                      id: row.idtest_step,
                    });
                  }}
                  style={{
                    minWidth: 240,
                    marginRight: "2rem",
                    alignItems: "center",
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {ListData.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {TestDataDetails.DependentProduct !== "" &&
              TestDataDetails.DependentProduct !== 0 &&
              TestDataDetails.DependentModule !== 0 &&
              TestDataDetails.DependentModule !== "" && (
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    marginLeft: "0rem",
                    display: "flex",
                    alignItems: "center",
                    width: 300,
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search Test Case here..."
                    inputProps={{ "aria-label": "Search Test Case here..." }}
                    value={SearchStringTestDataSource}
                    onChange={(e) => {
                      e.preventDefault();
                      setSearchStringTestDataSource(e.target.value);
                    }}
                  />
                  <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="search"
                    onClick={() => {
                      searchTestCaseInModinTable(
                        TestDataDetails.DependentModule,
                        SearchStringTestDataSource,
                      );
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
              )}

            {TestDataDetails.DependentModule !== "" && TestDataDetails.DependentModule !== 0 && (
              <Button
                onClick={(event) => {
                  setTestDataDetails({
                    ...TestDataDetails,
                    DependentModule: "",
                    DependentTC: "",
                    useVar: "",
                    DependentProduct: "",
                  });
                  setSearchStringTestDataSource("");
                }}
                sx={{ marginLeft: 3 }}
              >
                Clear
              </Button>
            )}
          </>
        )}

        {TestDataDetails.Source !== "" &&
          TestDataDetails.Source !== "Script" &&
          TestDataDetails.Source !== "FromFile" && (
            <Button
              onClick={() => {
                ChangeTableRowData({
                  ...TestDataDetails,
                  DBType:
                    TestDataDetails.DBType === "Other"
                      ? TestDataDetails.DBOtherType
                      : TestDataDetails.DBType,
                  idtest_case_One:
                    state.idtest_case_One === ""
                      ? TestDataDetails.DependentTC
                      : state.idtest_case_One,
                  testCaseId: row.Test_Case_Id,
                  id: row.idtest_step,
                });
                handleClickOpenAlert();
              }}
              disabled={
                TestDataDetails.Source === "FromDB"
                  ? isSubmitDisabled
                  : "" ||
                      (TestDataDetails.Source === "SameRow" &&
                        (TestDataDetails.ElementIndex === "" || TestDataDetails.ElementType === ""))
                    ? true
                    : false
              }
            >
              Submit
            </Button>
          )}
      </Box>
    );
  };

  const fromURL = (row) => {
    return (
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-end",
          //
        }}
      >
        <FormControl>
          <InputLabel id={`TestData Source-${row.idtest_step}`}>Environment</InputLabel>
          <Select
            labelId={`TestData Source-${row.idtest_step}`}
            id={`row.idtest_step-${row.idtest_step}`}
            value={state.SelectEnvironmentName}
            label="Environment"
            name="SelectEnvironment"
            variant="standard"
            onChange={(event) => {
              setState({
                ...state,
                testCaseId: state.tcs.idtest_case,
                SelectEnvironmentName: event.target.value,
                SelectEnvironmentUrl: state.envDetails
                  .filter((item) => item.envname === event.target.value)
                  .map((item) => item.envurl)[0],
              });
            }}
            style={{ minWidth: 168, marginRight: "2rem", alignItems: "center" }}
          >
            <MenuItem key="ASRECORDED" value="ASRECORDED">
              As Recorded
            </MenuItem>
            {state.envDetails.map((item, index) => (
              <MenuItem key={index} value={item.envname}>
                {item.envname}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography style={{ minWidth: 168, marginRight: "2rem", alignItems: "center" }}>
          {state.SelectEnvironmentUrl}
        </Typography>
        {state.SelectEnvironmentName !== "" && (
          <>
            <FormControl>
              <InputLabel id={`TestData Environment`}>Browser</InputLabel>
              <Select
                labelId={`TestData Browser`}
                id={`TestData Browser select`}
                value={state.SelectBrowserName}
                label="Browser"
                variant="standard"
                name="SelectBrowser"
                IconComponent={(props) => <ArrowDropDownSharpIcon {...props} />}
                onChange={(event) => {
                  setState({
                    ...state,
                    testCaseId: state.tcs.idtest_case,
                    SelectBrowserName: event.target.value,
                  });
                }}
                style={{
                  minWidth: 168,
                  marginRight: "2rem",
                  alignItems: "center",
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {state.browserDetails.map((item, index) => (
                  <MenuItem key={index} value={item.browsername}>
                    {item.browsername}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id={`TestData Environment`}>Resolution</InputLabel>
              <Select
                labelId={`TestData Resolution`}
                id={`TestData Resolution select`}
                value={state.Height}
                label="Resolution"
                name="SelectResolution"
                variant="standard"
                IconComponent={(props) => <ArrowDropDownSharpIcon {...props} />}
                onChange={(event) => {
                  if (event.target.value !== "") {
                    setState({
                      ...state,
                      Height: event.target.value,
                      Width: state.resolutionDetails.filter(
                        (item) => item.Height === event.target.value,
                      )[0].Width,
                    });
                  } else {
                    setState({
                      ...setState,
                      Height: 0,
                      Width: 0,
                    });
                  }
                }}
                style={{
                  minWidth: 168,
                  marginRight: "2rem",
                  alignItems: "center",
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {state.resolutionDetails.map((item, index) => (
                  <MenuItem key={index} value={item.Height}>
                    {`${item.Height}x${item.Width}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              onClick={() => {
                SendEnviromentOnSubmit({
                  idtest_case: state.testCaseId,
                  EnvironmentUrl: state.SelectEnvironmentUrl,
                  EnvironmentName: state.SelectEnvironmentName,
                  EnvironmentBrowser: state.SelectBrowserName,
                  Width: state.Width,
                  Height: state.Height,
                  EnvironmentRunonce: TestDataDetails.SelectRunonce,
                  // filename: TestDataDetails.filename,
                  // filefield:TestDataDetails.filefield
                });
                // getfilesdata()
                handleClickOpenAlert();
              }}
            >
              Submit
            </Button>
          </>
        )}
      </Box>
    );
  };
  const fromValidate = (row) => {
    return (
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-end",
          //
        }}
      >
        <FormControl>
          <InputLabel id={`TestData Source-${row.idtest_step}`}>Assertion Target</InputLabel>
          <Select
            key={row.idtest_step}
            labelId={`TestData Source-${row.idtest_step}`}
            id={`row.idtest_step-${row.idtest_step}`}
            defaultValue={TestDataDetails.Target}
            label="TestData Source"
            name="Test Data Source"
            variant="standard"
            onChange={(event) => {
              setTestDataDetails({
                ...TestDataDetails,
                id: row.idtest_step,
                testCaseId: state.tcs.idtest_case,
                Target: event.target.value,
              });
              if (event.target.value === "UseVariableFromTheScenario") {
                getSelectFromListData(row.idtest_step, state.tcs.idtest_case);
              }
              if (event.target.value === "InRefrenceFile") {
                getSelectFildata(state.idproducts);
              }
              if (event.target.value === "InDownloadedFile") {
                getDownloadedFile(state.tcs.idtest_case);
              }
            }}
            style={{ minWidth: 168, marginRight: "2rem", alignItems: "center" }}
          >
            {/* <MenuItem value="">
              <em>None</em>
            </MenuItem> */}
            {validateSelectLIstData.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {TestDataDetails.Target === "ChangeTestData" && (
          <TextField
            sx={{ marginRight: "2rem" }}
            label="New Data"
            variant="standard"
            value={TestDataDetails.UploadString}
            onChange={(event) => {
              event.preventDefault();
              setTestDataDetails((prev) => {
                return {
                  ...prev,
                  UploadString: event.target.value,
                };
              });
            }}
          />
        )}
        {TestDataDetails.Target === "InRefrenceFile" && (
          <>
            <FormControl>
              <InputLabel id={`InRefrenceFile-${row.idtest_step}`}>Select File</InputLabel>

              <Select
                key={row.idtest_step}
                variant="standard"
                labelId={`InRefrenceFile-${row.idtest_step}`}
                id={`row.idtest_step-${row.idtest_step}`}
                label="InRefrenceFile"
                value={TestDataDetails.FileName}
                onChange={(event) => {
                  handleFromType(event.target.value);
                  // setTestDataDetails({
                  //   ...TestDataDetails,
                  //   FileName: event.target.value,
                  //   // id: row.idtest_step,
                  // });
                  // setSelectedvaluefile(event.target.value)
                }}
                sx={{ width: 180 }}
              >
                {filedropdowndata.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {fromTypeCheck === "xlsx" || fromTypeCheck === "csv" ? (
              <>
                <TextField
                  sx={{ marginX: "2rem" }}
                  label="Sheet"
                  variant="standard"
                  defaultValue={TestDataDetails.sheetId}
                  onChange={(event) => {
                    setTestDataDetails({
                      ...TestDataDetails,
                      sheetId: event.target.value,
                    });
                  }}
                />
                <TextField
                  sx={{ marginX: "2rem", width: "4%" }}
                  label="Cell"
                  variant="standard"
                  defaultValue={TestDataDetails.cell}
                  onChange={(event) => {
                    setTestDataDetails({
                      ...TestDataDetails,
                      cell: event.target.value,
                    });
                  }}
                />
                <div>
                  <Typography>Scope</Typography>
                  <Select
                    variant="standard"
                    key={row.idtest_step}
                    labelId={`demo-simple-select-label-${row.idtest_step}`}
                    id={`row.idtest_step-${row.idtest_step}`}
                    value={TestDataDetails.scope}
                    label="Scope"
                    onChange={(event) => {
                      setTestDataDetails({
                        ...TestDataDetails,
                        scope: event.target.value,
                      });
                    }}
                  >
                    <MenuItem value="Multi Run">Multi Run</MenuItem>
                    <MenuItem value="Multi Execution">Multi Execution</MenuItem>
                    <MenuItem value="Single Run">Single Run</MenuItem>
                  </Select>
                </div>
                {TestDataDetails.scope === "Multi Run" ? (
                  <TextField
                    sx={{ marginX: "2rem", width: "4%" }}
                    label="End Range"
                    variant="standard"
                    defaultValue={TestDataDetails.endRange}
                    onChange={(event) => {
                      setTestDataDetails({
                        ...TestDataDetails,
                        endRange: event.target.value,
                      });
                    }}
                  />
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
            {fromTypeCheck === "json" ? (
              <>
                <TextField
                  sx={{ marginX: "2rem", width: "25%" }}
                  label="Filter"
                  variant="standard"
                  defaultValue={TestDataDetails.filter}
                  onChange={(event) => {
                    setTestDataDetails({
                      ...TestDataDetails,
                      filter: event.target.value,
                    });
                  }}
                />
                <div>
                  <Typography>Scope</Typography>
                  <Select
                    variant="standard"
                    key={row.idtest_step}
                    labelId={`demo-simple-select-label-${row.idtest_step}`}
                    id={`row.idtest_step-${row.idtest_step}`}
                    value={TestDataDetails.scope}
                    label="Scope"
                    onChange={(event) => {
                      setTestDataDetails({
                        ...TestDataDetails,
                        scope: event.target.value,
                      });
                    }}
                  >
                    <MenuItem value="Multi Run">Multi Run</MenuItem>
                    <MenuItem value="Multi Execution">Multi Execution</MenuItem>
                    <MenuItem value="Single Run">Single Run</MenuItem>
                  </Select>
                </div>
                {TestDataDetails.scope === "Multi Run" ? (
                  <TextField
                    sx={{ marginX: "2rem", width: "25%" }}
                    label="End Range"
                    variant="standard"
                    defaultValue={TestDataDetails.endRange}
                    onChange={(event) => {
                      setTestDataDetails({
                        ...TestDataDetails,
                        endRange: event.target.value,
                      });
                    }}
                  />
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </>
        )}
        {TestDataDetails.Target === "InDownloadedFile" && (
          <>
            <FormControl>
              <InputLabel id={`Download Files-${row.idtest_step}`}>Downloaded Files</InputLabel>
              <Select
                key={row.idtest_step}
                variant="standard"
                labelId={`Download Files-${row.idtest_step}`}
                id={`row.idtest_step-${row.idtest_step}`}
                value={TestDataDetails.DownloadedFile}
                label="Download Files"
                onChange={(event) => {
                  setTestDataDetails({
                    ...TestDataDetails,
                    DownloadedFile: event.target.value,
                  });
                }}
                style={{
                  minWidth: 240,
                  marginRight: "2rem",
                  alignItems: "center",
                }}
              >
                {/* <MenuItem value="">
                <em>None</em>
              </MenuItem> */}

                {downloadedFileData?.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              sx={{ marginX: "2rem" }}
              label="Assertion Value"
              variant="standard"
              value={TestDataDetails.AssertionValue}
              onChange={(event) => {
                setTestDataDetails({
                  ...TestDataDetails,
                  AssertionValue: event.target.value,
                });
              }}
            />
          </>
        )}

        {TestDataDetails.Target === "InFile" && (
          <>
            {!TestDataDetails.file && (
              <TextField
                sx={{ marginX: "2rem", width: "15%" }}
                label="File Name"
                variant="standard"
                defaultValue={TestDataDetails.filename}
                disabled={true}
              />
            )}
            <TextField
              type="file"
              id="recording-file-upload"
              onChange={(event) => {
                handleFileChange(event);
              }}
              variant="standard"
              sx={{ width: "20%" }}
            />
            {/* <TextField
              sx={{ marginX: "2rem" }}
              label="File Field"
              variant="standard"
              defaultValue={TestDataDetails.filefield}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    filefield: event.target.value,
                  };
                });
              }}
            /> */}
            {fileTypeCheck === "xlsx" ||
            fileTypeCheck === "csv" ||
            fileNameTypeCheck === "xlsx" ||
            fileNameTypeCheck === "csv" ? (
              <>
                <TextField
                  sx={{ marginX: "2rem" }}
                  label="Sheet"
                  variant="standard"
                  defaultValue={TestDataDetails.sheetId}
                  onChange={(event) => {
                    setTestDataDetails({
                      ...TestDataDetails,
                      sheetId: event.target.value,
                    });
                  }}
                />
                <TextField
                  sx={{ marginX: "2rem", width: "4%" }}
                  label="Cell"
                  variant="standard"
                  defaultValue={TestDataDetails.cell}
                  onChange={(event) => {
                    setTestDataDetails({
                      ...TestDataDetails,
                      cell: event.target.value,
                    });
                  }}
                />
                <div>
                  <Typography>Scope</Typography>
                  <Select
                    variant="standard"
                    key={row.idtest_step}
                    labelId={`demo-simple-select-label-${row.idtest_step}`}
                    id={`row.idtest_step-${row.idtest_step}`}
                    value={TestDataDetails.scope}
                    label="Scope"
                    onChange={(event) => {
                      setTestDataDetails({
                        ...TestDataDetails,
                        scope: event.target.value,
                      });
                    }}
                  >
                    <MenuItem value="Multi Run">Multi Run</MenuItem>
                    <MenuItem value="Multi Execution">Multi Execution</MenuItem>
                    <MenuItem value="Single Run">Single Run</MenuItem>
                  </Select>
                </div>
                {TestDataDetails.scope === "Multi Run" ? (
                  <TextField
                    sx={{ marginX: "2rem", width: "4%" }}
                    label="End Range"
                    variant="standard"
                    defaultValue={TestDataDetails.endRange}
                    onChange={(event) => {
                      setTestDataDetails({
                        ...TestDataDetails,
                        endRange: event.target.value,
                      });
                    }}
                  />
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}

            {fileTypeCheck === "json" || fileNameTypeCheck === "json" ? (
              <>
                <TextField
                  sx={{ marginX: "2rem", width: "25%" }}
                  label="filter"
                  variant="standard"
                  defaultValue={TestDataDetails.filter}
                  onChange={(event) => {
                    setTestDataDetails({
                      ...TestDataDetails,
                      filter: event.target.value,
                    });
                  }}
                />
                <div>
                  <Typography>Scope</Typography>
                  <Select
                    variant="standard"
                    key={row.idtest_step}
                    labelId={`demo-simple-select-label-${row.idtest_step}`}
                    id={`row.idtest_step-${row.idtest_step}`}
                    value={TestDataDetails.scope}
                    label="Scope"
                    onChange={(event) => {
                      setTestDataDetails({
                        ...TestDataDetails,
                        scope: event.target.value,
                      });
                    }}
                  >
                    <MenuItem value="Multi Run">Multi Run</MenuItem>
                    <MenuItem value="Multi Execution">Multi Execution</MenuItem>
                    <MenuItem value="Single Run">Single Run</MenuItem>
                  </Select>
                </div>
                {TestDataDetails.scope === "Multi Run" ? (
                  <TextField
                    sx={{ marginX: "2rem", width: "25%" }}
                    label="End Range"
                    variant="standard"
                    defaultValue={TestDataDetails.endRange}
                    onChange={(event) => {
                      setTestDataDetails({
                        ...TestDataDetails,
                        endRange: event.target.value,
                      });
                    }}
                  />
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
            <Button
              component="label"
              size="medium"
              onClick={() => {
                validateDataFromFile();
                handleClickOpenAlert();
              }}
              sx={{ marginLeft: 4 }}
            >
              Upload
            </Button>
          </>
        )}

        {TestDataDetails.Target === "InGoogleSheet" && (
          <>
            <TextField
              sx={{ marginRight: "2rem", width: "20%" }}
              label="Sheet Id"
              variant="standard"
              defaultValue={TestDataDetails.filename}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    filename: event.target.value,
                  };
                });
              }}
            />
            <TextField
              sx={{ marginX: "2rem" }}
              label="Sheet"
              variant="standard"
              defaultValue={TestDataDetails.sheetId}
              onChange={(event) => {
                setTestDataDetails({
                  ...TestDataDetails,
                  sheetId: event.target.value,
                });
              }}
            />
            <TextField
              sx={{ marginX: "2rem", width: "4%" }}
              label="Cell"
              variant="standard"
              defaultValue={TestDataDetails.cell}
              onChange={(event) => {
                setTestDataDetails({
                  ...TestDataDetails,
                  cell: event.target.value,
                });
              }}
            />
            <div>
              <Typography>Scope</Typography>
              <Select
                variant="standard"
                key={row.idtest_step}
                labelId={`demo-simple-select-label-${row.idtest_step}`}
                id={`row.idtest_step-${row.idtest_step}`}
                value={TestDataDetails.scope}
                label="Scope"
                onChange={(event) => {
                  setTestDataDetails({
                    ...TestDataDetails,
                    scope: event.target.value,
                  });
                }}
              >
                <MenuItem value="Multi Run">Multi Run</MenuItem>
                <MenuItem value="Multi Execution">Multi Execution</MenuItem>
                <MenuItem value="Single Run">Single Run</MenuItem>
              </Select>
            </div>
            {TestDataDetails.scope === "Multi Run" ? (
              <TextField
                sx={{ marginX: "2rem", width: "4%" }}
                label="End Range"
                variant="standard"
                defaultValue={TestDataDetails.endRange}
                onChange={(event) => {
                  setTestDataDetails({
                    ...TestDataDetails,
                    endRange: event.target.value,
                  });
                }}
              />
            ) : (
              ""
            )}
            {/* <TextField
              sx={{ marginX: "2rem" }}
              label="File Name"
              variant="standard"
              defaultValue={TestDataDetails.filefield}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    filefield: event.target.value,
                  };
                });
              }}
            /> */}
          </>
        )}

        {TestDataDetails.Target === "FromEmail" && (
          <>
            <TextField
              sx={{ marginRight: "2rem" }}
              label="Email"
              variant="standard"
              defaultValue={TestDataDetails.customerEmail}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    customerEmail: event.target.value,
                  };
                });
              }}
            />
            <TextField
              sx={{ marginRight: "2rem" }}
              label="Password"
              type="password"
              variant="standard"
              defaultValue={TestDataDetails.customerPassword}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    customerPassword: event.target.value,
                  };
                });
              }}
            />
            <TextField
              sx={{ marginRight: "2rem" }}
              label="Selection Criterion"
              variant="standard"
              defaultValue={TestDataDetails.EmailSelectionCriteria}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    EmailSelectionCriteria: event.target.value,
                  };
                });
              }}
            />
            <TextField
              sx={{ marginRight: "2rem" }}
              label="Filter"
              variant="standard"
              defaultValue={TestDataDetails.EmailFilter}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    EmailFilter: event.target.value,
                  };
                });
              }}
            />
          </>
        )}

        {TestDataDetails.Target === "InApi" && (
          <>
            <FormControl>
              <InputLabel id={`DB Type-${row.idtest_step}`}>Product</InputLabel>
              <Select
                labelId={"product name"}
                id={"product name Select"}
                variant="standard"
                value={TestDataDetails.apiproduct}
                name={"product"}
                label="Product"
                onChange={(event) => {
                  setTestDataDetails({
                    ...TestDataDetails,
                    apiproduct: event.target.value,
                  });
                  getModules(event.target.value);
                }}
                style={{
                  minWidth: 100,
                  marginRight: "2rem",
                  alignItems: "center",
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {productList.map((item, index) => (
                  <MenuItem key={index} value={item.idproducts}>
                    {item.productname}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {TestDataDetails.apiproduct !== "" && TestDataDetails.apiproduct !== 0 && (
              <FormControl>
                <InputLabel id={"module name"}> Module</InputLabel>
                <Select
                  labelId={"module name"}
                  id={"module name Select"}
                  variant="standard"
                  defaultValue={TestDataDetails.apimodule}
                  label="Module"
                  onChange={(event) => {
                    setTestDataDetails({
                      ...TestDataDetails,
                      apimodule: event.target.value,
                    });
                    getAllApiDetails(event.target.value);
                  }}
                  style={{
                    minWidth: 100,
                    marginRight: "2rem",
                    alignItems: "center",
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {ModuleListOne.map((item, index) => (
                    <MenuItem key={index} value={item.idmodules}>
                      {item.modulename}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {TestDataDetails.apimodule !== "" && TestDataDetails.apimodule !== 0 && (
              <>
                <FormControl>
                  <InputLabel id={"Api name"}> Api Name </InputLabel>
                  <Select
                    labelId={"Api name"}
                    id={"Api Name Select"}
                    variant="standard"
                    defaultValue={TestDataDetails.apiName}
                    label="Api name"
                    onChange={(event) => {
                      const selectedApiName = event.target.value;
                      const selectedApi = apiList.find((item) => item.idapi === selectedApiName);
                      setTestDataDetails({
                        ...TestDataDetails,
                        apiName: selectedApiName, //wanted the id to get the name
                        apiId: selectedApi ? selectedApi.name : null, //wanted the name to pass in the api
                      });
                      setRefreshFlag(false); //to close auto refesh on button
                      clearInterval(window.myTimer); ////to close auto refesh
                    }}
                    style={{
                      minWidth: 100,
                      marginRight: "2rem",
                      alignItems: "center",
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {apiList.map((item, index) => (
                      <MenuItem key={index} value={item.idapi}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
            {TestDataDetails.apimodule !== "" &&
              TestDataDetails.apimodule !== 0 &&
              TestDataDetails.apiName !== "" &&
              TestDataDetails.apiName !== undefined &&
              TestDataDetails.apiName !== 0 && (
                <>
                  <TextField
                    sx={{ marginRight: "2rem", width: "20%" }}
                    label="Api Param"
                    variant="standard"
                    defaultValue={TestDataDetails.apiParam}
                    onChange={(event) => {
                      event.preventDefault();
                      setTestDataDetails((prev) => {
                        return {
                          ...prev,
                          apiParam: event.target.value,
                        };
                      });
                    }}
                  />

                  <TextField
                    sx={{ marginRight: "2rem", width: "30%" }}
                    label="Api Query"
                    variant="standard"
                    defaultValue={TestDataDetails.apiQuery}
                    onChange={(event) => {
                      event.preventDefault();
                      setTestDataDetails((prev) => {
                        return {
                          ...prev,
                          apiQuery: event.target.value,
                        };
                      });
                    }}
                  />
                </>
              )}

            {/* {(TestDataDetails.DependentProduct !== "" && TestDataDetails.DependentProduct !== 0) && (TestDataDetails.DependentModule !== 0 && TestDataDetails.DependentModule !== "") && (
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  marginLeft: "0rem",
                  display: "flex",
                  alignItems: "center",
                  width: 300,
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search Api Name here..."
                  inputProps={{ "aria-label": "Search Api Name here..." }}
                  value={SearchStringTestDataSource}
                  onChange={(e) => {
                    e.preventDefault();
                    setSearchStringTestDataSource(e.target.value);
                  }}
                />
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                  onClick={() => {
                    searchTestCaseInModinTable(TestDataDetails.DependentModule, SearchStringTestDataSource);
                  }}
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            )} */}

            {TestDataDetails.apimodule !== "" && TestDataDetails.apimodule !== 0 && (
              <Button
                onClick={(event) => {
                  setTestDataDetails({
                    ...TestDataDetails,
                    apimodule: "",
                    apiName: "",
                    apiproduct: "",
                    apiId: "",
                    apiQuery: "",
                    apiParam: "",
                  });
                  // setSearchStringTestDataSource("");
                }}
                sx={{ marginLeft: 3 }}
              >
                Clear
              </Button>
            )}
          </>
        )}

        {TestDataDetails.Target === "DependentonOtherTestScenario" && (
          <>
            <FormControl>
              <InputLabel id={`DB Type-${row.idtest_step}`}>Product</InputLabel>
              <Select
                labelId={"product name"}
                id={"product name Select"}
                variant="standard"
                value={TestDataDetails.DependentProduct}
                name={"product"}
                label="Product"
                onChange={(event) => {
                  setTestDataDetails({
                    ...TestDataDetails,
                    DependentProduct: event.target.value,
                  });
                  getModules(event.target.value);
                }}
                style={{
                  minWidth: 100,
                  marginRight: "2rem",
                  alignItems: "center",
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {productList.map((item, index) => (
                  <MenuItem key={index} value={item.idproducts}>
                    {item.productname}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {TestDataDetails.DependentProduct !== "" && TestDataDetails.DependentProduct !== 0 && (
              <FormControl>
                <InputLabel id={"module name"}> Module</InputLabel>
                <Select
                  labelId={"module name"}
                  id={"module name Select"}
                  variant="standard"
                  defaultValue={TestDataDetails.DependentModule}
                  label="Module"
                  onChange={(event) => {
                    setTestDataDetails({
                      ...TestDataDetails,
                      DependentModule: event.target.value,
                    });
                    getTestCase(event.target.value);
                  }}
                  style={{
                    minWidth: 100,
                    marginRight: "2rem",
                    alignItems: "center",
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {ModuleListOne.map((item, index) => (
                    <MenuItem key={index} value={item.idmodules}>
                      {item.modulename}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {TestDataDetails.DependentModule !== "" && TestDataDetails.DependentModule !== 0 && (
              <FormControl>
                <InputLabel id={"Test Case name"}> Test Case </InputLabel>
                <Select
                  labelId={"Test Case name"}
                  id={"Test Case Name Select"}
                  variant="standard"
                  defaultValue={TestDataDetails.DependentTC}
                  label="Test Case"
                  onChange={(event) => {
                    setTestDataDetails({
                      ...TestDataDetails,
                      DependentTC: event.target.value,
                    });
                    setRefreshFlag(false); //to close auto refesh on button
                    clearInterval(window.myTimer); ////to close auto refesh
                    getSelectFromListData(row.idtest_step, event.target.value);
                  }}
                  style={{
                    minWidth: 100,
                    marginRight: "2rem",
                    alignItems: "center",
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {TestCaseListOne.map((item, index) => (
                    <MenuItem key={index} value={item.idtest_case}>
                      {item.Test_Case}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {TestDataDetails.DependentTC !== "" && TestDataDetails.DependentTC !== 0 && (
              <FormControl>
                <InputLabel id={`Use Var From this Scenario-${row.idtest_step}`}>
                  Use Var From this Scenario
                </InputLabel>
                <Select
                  key={row.idtest_step}
                  variant="standard"
                  labelId={`Use Var From this Scenario-${row.idtest_step}`}
                  id={`row.idtest_step-${row.idtest_step}`}
                  defaultValue={TestDataDetails.useVar}
                  label="Use Var From this Scenario"
                  onChange={(event) => {
                    setTestDataDetails({
                      ...TestDataDetails,
                      useVar: event.target.value,
                    });
                  }}
                  style={{
                    minWidth: 240,
                    marginRight: "2rem",
                    alignItems: "center",
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {ListData.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {TestDataDetails.DependentProduct !== "" &&
              TestDataDetails.DependentProduct !== 0 &&
              TestDataDetails.DependentModule !== 0 &&
              TestDataDetails.DependentModule !== "" && (
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    marginLeft: "0rem",
                    display: "flex",
                    alignItems: "center",
                    width: 300,
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search Test Case here..."
                    inputProps={{ "aria-label": "Search Test Case here..." }}
                    defaultValue={SearchStringValidation}
                    onChange={(e) => {
                      e.preventDefault();
                      setSearchStringValidation(e.target.value);
                    }}
                  />
                  <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="search"
                    onClick={() => {
                      searchTestCaseInModinTable(
                        TestDataDetails.DependentModule,
                        SearchStringValidation,
                      );
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
              )}

            {TestDataDetails.DependentModule !== "" && TestDataDetails.DependentModule !== 0 && (
              <Button
                onClick={(event) => {
                  setTestDataDetails({
                    ...TestDataDetails,
                    DependentModule: "",
                    DependentTC: "",
                    useVar: "",
                    DependentProduct: "",
                  });
                  setSearchStringValidation("");
                }}
                sx={{ marginLeft: 3 }}
              >
                Clear
              </Button>
            )}
          </>
        )}
        {TestDataDetails.Target === "UseVariableFromTheScenario" && (
          <FormControl>
            <InputLabel id={`Use Var From this Scenario-${row.idtest_step}`}>
              Use Var From this Scenario
            </InputLabel>
            <Select
              key={row.idtest_step}
              variant="standard"
              labelId={`Use Var From this Scenario-${row.idtest_step}`}
              id={`row.idtest_step-${row.idtest_step}`}
              value={TestDataDetails.useVar}
              label="Use Var From this Scenario"
              onChange={(event) => {
                setTestDataDetails({
                  ...TestDataDetails,
                  useVar: event.target.value,
                });
              }}
              style={{
                minWidth: 240,
                marginRight: "2rem",
                alignItems: "center",
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {ListData.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {TestDataDetails.Target === "Regex" && (
          <>
            <TextField
              sx={{ marginX: "2rem" }}
              label="Regex"
              variant="standard"
              defaultValue={TestDataDetails.regex}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    regex: event.target.value,
                  };
                });
              }}
            />
          </>
        )}
        {TestDataDetails.Target === "Date" && (
          <>
            <TextField
              sx={{ marginX: "2rem" }}
              label="Date"
              variant="standard"
              defaultValue={splitDateString(row.TestData).dateData}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    date: event.target.value,
                  };
                });
              }}
            />
            <TextField
              sx={{ marginX: "2rem" }}
              label="DateFormat"
              variant="standard"
              defaultValue={splitDateString(row.TestData).dateFormatData}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    dateFormat: event.target.value,
                  };
                });
              }}
            />
          </>
        )}
        {TestDataDetails.Target === "DB" && (
          <>
            {/* <FormControl>
              <InputLabel id={`DB Type-${row.idtest_step}`}>DB Type</InputLabel>
              <Select
                key={row.idtest_step}
                labelId={`DB Type-${row.idtest_step}`}
                variant="standard"
                id={`row.idtest_step-${row.idtest_step}`}
                value={TestDataDetails.DBType}
                label="list data Source"
                onChange={(event) => {
                  setTestDataDetails({
                    ...TestDataDetails,
                    DBType: event.target.value,
                  });
                }}
                style={{
                  minWidth: 100,
                  marginRight: "2rem",
                  alignItems: "center",
                }}
              >
                {DBTypeLIstData.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {TestDataDetails.DBType === "Other" && (
              <TextField
                sx={{ marginRight: "2rem" }}
                label="DataBase Type"
                variant="standard"
                defaultValue={TestDataDetails.DBOtherType}
                onChange={(event) => {
                  event.preventDefault();
                  setTestDataDetails((prev) => {
                    return {
                      ...prev,
                      DBOtherType: event.target.value,
                    };
                  });
                }}
              />
            )}
            <TextField
              sx={{ marginRight: "2rem" }}
              label="DB URL"
              variant="standard"
              defaultValue={TestDataDetails.DBURL}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    DBURL: event.target.value,
                  };
                });
              }}
            />
            <TextField
              sx={{ marginRight: "2rem" }}
              label="DB username"
              variant="standard"
              defaultValue={TestDataDetails.DBusername}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    DBusername: event.target.value,
                  };
                });
              }}
            />
            <TextField
              sx={{ marginRight: "2rem" }}
              label="DB password"
              variant="standard"
              defaultValue={TestDataDetails.DBpwd}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    DBpwd: event.target.value,
                  };
                });
              }}
            /> */}
            <TextField
              sx={{ marginRight: "2rem", width: "50%" }}
              label="DB Query"
              variant="standard"
              defaultValue={TestDataDetails.DBQuery}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    DBQuery: event.target.value,
                  };
                });
              }}
            />
          </>
        )}

        {TestDataDetails.Target !== "" && TestDataDetails.Target !== "InFile" && (
          <Button
            onClick={() => {
              if (TestDataDetails.Target === "DB") {
                validateDataFromDB({
                  testCaseId: row.Test_Case_Id,
                  teststepid: row.idtest_step,
                  valdtype: TestDataDetails.Target,
                  dbquery: TestDataDetails.DBQuery,
                });
                // handleClickOpenAlert();
                handleClose("Save");
              } else if (TestDataDetails.Target === "FromEmail") {
                validateDetailsFromEmail({
                  testCaseId: row.Test_Case_Id,
                  teststepid: row.idtest_step,
                  valdtype: "FromEmail",
                  customerEmail: TestDataDetails.customerEmail,
                  customerPassword: TestDataDetails.customerPassword,
                  EmailSelectionCriteria: TestDataDetails.EmailSelectionCriteria,
                  EmailFilter: TestDataDetails.EmailFilter,
                });
                handleClose("Save");
              } else if (TestDataDetails.Target === "Regex") {
                validateDataByRegex({
                  teststepid: TestDataDetails.id,
                  testCaseId: TestDataDetails.testCaseId,
                  valdtype: "Regex",
                  regex: TestDataDetails.regex,
                });
                handleClickOpenAlert();
              } else if (TestDataDetails.Target === "InGoogleSheet") {
                validateDataByGoogleSheet({
                  teststepid: TestDataDetails.id,
                  testCaseId: TestDataDetails.testCaseId,
                  valdtype: "InGoogleSheet",
                  fieldname: TestDataDetails.filename,
                  cell: TestDataDetails.cell,
                  sheetId: TestDataDetails.sheetId,
                  scope: TestDataDetails.scope,
                  endRange: TestDataDetails.endRange,
                  // filefield: TestDataDetails.filefield
                });
                handleClickOpenAlert();
              } else if (TestDataDetails.Target === "InApi") {
                validateDataByApi({
                  teststepid:
                    TestDataDetails.id === "" ? SelectedRow.idtest_step : TestDataDetails.id,
                  testCaseId:
                    TestDataDetails.testCaseId === ""
                      ? SelectedRow.Test_Case_Id
                      : TestDataDetails.testCaseId,
                  valdtype: "InApi",
                  fieldName: TestDataDetails.apiId,
                  apiid: TestDataDetails.apiName,
                  apiParam: TestDataDetails.apiParam,
                  apiQuery: TestDataDetails.apiQuery,
                });
                handleClickOpenAlert();
              } else if (TestDataDetails.Target === "ChangeTestData") {
                validateDataChangeData({
                  teststepid: TestDataDetails.id,
                  testCaseId: TestDataDetails.testCaseId,
                  valdtype: "ChangeTestData",
                  UploadString: TestDataDetails.UploadString,
                });
                handleClickOpenAlert();
              } else if (TestDataDetails.Target === "Date") {
                validateDataByDate({
                  teststepid: TestDataDetails.id,
                  testCaseId: TestDataDetails.testCaseId,
                  valdtype: "Date",
                  date: TestDataDetails.date,
                  dateFormat: TestDataDetails.dateFormat,
                });
                handleClickOpenAlert();
              } else if (TestDataDetails.Target === "OnScreen") {
                validateDataOnScreen({
                  teststepid: TestDataDetails.id,
                  testCaseId: TestDataDetails.testCaseId,
                  valdtype: "OnScreen",
                });
                handleClickOpenAlert();
              } else if (TestDataDetails.Target === "IsAVar") {
                validateDataOnScreen({
                  teststepid: TestDataDetails.id,
                  testCaseId: TestDataDetails.testCaseId,
                  valdtype: "IsAVar",
                });
                handleClickOpenAlert();
              } else if (TestDataDetails.Target === "Search") {
                validateDataBySearch({
                  teststepid: TestDataDetails.id,
                  testCaseId: TestDataDetails.testCaseId,
                  valdtype: "Search",
                  testdata: row.TestData,
                });
                handleClickOpenAlert();
              } else if (TestDataDetails.Target === "DynamicText") {
                validateDataOnScreen({
                  teststepid: TestDataDetails.id,
                  testCaseId: TestDataDetails.testCaseId,
                  valdtype: "DynamicText",
                });
                handleClickOpenAlert();
              } else if (TestDataDetails.Target === "DependentonOtherTestScenario") {
                validateDataByDependence({
                  teststepid:
                    TestDataDetails.id === "" ? SelectedRow.idtest_step : TestDataDetails.id,
                  testCaseId:
                    TestDataDetails.testCaseId === ""
                      ? SelectedRow.Test_Case_Id
                      : TestDataDetails.testCaseId,
                  dependantTestCaseId: TestDataDetails.DependentTC,
                  usevar: TestDataDetails.useVar,
                  valdtype: "DependentonOtherTestScenario",
                });
                handleClickOpenAlert();
              } else if (TestDataDetails.Target === "UseVariableFromTheScenario") {
                validateDataByDependence({
                  teststepid: TestDataDetails.id,
                  testCaseId: TestDataDetails.testCaseId,
                  dependantTestCaseId: state.idtest_case_One,
                  usevar: TestDataDetails.useVar,
                  valdtype: "UseVariableFromTheScenario",
                });
                handleClickOpenAlert();
              } else if (TestDataDetails.Target === "InRefrenceFile") {
                validatefiledatareference({
                  teststepid:
                    TestDataDetails.id === "" ? SelectedRow.idtest_step : TestDataDetails.id,
                  testCaseId:
                    TestDataDetails.testCaseId === ""
                      ? SelectedRow.Test_Case_Id
                      : TestDataDetails.testCaseId,
                  filefield: TestDataDetails.filefield,
                  FileName: TestDataDetails.FileName,
                  valdtype: "InRefrenceFile",
                  cell: TestDataDetails.cell,
                  sheetId: TestDataDetails.sheetId,
                  scope: TestDataDetails.scope,
                  endRange: TestDataDetails.endRange,
                  filter: TestDataDetails.filter,
                  fromFileType: TestDataDetails.fromFileType,
                });
                handleClickOpenAlert();
                // handleClickOpenErrorAlert();
              } else if (TestDataDetails.Target === "InDownloadedFile") {
                validationdownloadfiles({
                  teststepid:
                    TestDataDetails.id === "" ? SelectedRow.idtest_step : TestDataDetails.id,
                  testCaseId:
                    TestDataDetails.testCaseId === ""
                      ? SelectedRow.Test_Case_Id
                      : TestDataDetails.testCaseId,
                  DownloadedFile: TestDataDetails.DownloadedFile,
                  AssertionValue: TestDataDetails.AssertionValue,
                  valdtype: "InDownloadedFile",
                });
                handleClickOpenAlert();
              }
            }}
          >
            Submit
          </Button>
        )}
      </Box>
    );
  };

  const splitDateString = (string) => {
    const dateValue = string.split("~");
    const dateData = dateValue[2];
    const dateFormatData = dateValue[3];
    return { dateData, dateFormatData };
  };

  const editTCName = async (tcid, newName) => {
    await editTestCaseName(tcid, newName)
      .then((response) => {
        if (response.status === 200) {
          // setloader(false);
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setTestCaseNameChange(response.data.Test_Case);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const copyTCName = async (tcid, newName) => {
    await copyTestCaseName(tcid, newName)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setTestCaseNameChange(response.data.Test_Case);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const waitTimeChange = async (tsid, tcid, time) => {
    await handleWaitTimeChange(tsid, tcid, time)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            console.log("worked");
            // setTestDataDetails(response.data.waittime);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleInputChange = (event, row) => {
    event.persist();
    setHidingTick(true);
    //     setTestDataDetails((prev) => ({
    //   ...prev,
    //   waittime: event.target.value,
    // }));
    const newWaitTime = parseInt(event.target.value, 10); // Convert string to integer
    setRowWaitTimes((prevState) => ({
      ...prevState,
      [row.idtest_step]: isNaN(newWaitTime) ? 0 : newWaitTime, // Use 0 if conversion fails
    }));
    setSelectedRowForTime(row);
  };

  return (
    <>
      {isLoading ? (
        <LoaderSpinner />
      ) : (
        <Box
          className="box-mui"
          sx={{
            display: "flex",
            flex: 1,
            alignItem: "center",
            justifyContent: "space-evenly",
            paddingTop: "2rem",
            paddingX: "2rem",
          }}
        >
          <TableContainer component={Paper}>
            <div style={{ position: "sticky", top: 0 }}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell colSpan={16}>
                      <Box
                        sx={{
                          display: "flex",
                          // ml: "5rem",
                          justifyContent: "space-between",
                          alignItems: "center",
                          flexDirection: "row",
                          paddingTop: "1.5rem",
                          backgroundColor: colors.secondaryColor,
                          color: colors.primaryColor,
                        }}
                      >
                        <Box></Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <Typography paddingBottom={0} variant="h6" component="h2">
                            <>
                              TEST SCENARIO NAME:{" "}
                              {renameCheck === false && copyCheck === false ? (
                                testCaseNameChange?.slice(0, 40)
                              ) : renameCheck === true ? (
                                <span hidden={!renameCheck}>
                                  <Input
                                    placeholder={testCaseNameChange}
                                    value={newName}
                                    onChange={(e) => setNewName(e.target.value)}
                                    style={{
                                      width: "250px",
                                      backgroundColor: colors.secondaryColor,
                                      color: colors.primaryColor,
                                    }}
                                  />

                                  <IconButton
                                    onClick={() => {
                                      editTCName(state.tcs.idtest_case, newName);
                                      setRenameCheck(!renameCheck);
                                      const cleartimeout = setTimeout(() => {
                                        getTestCase(state.idmodules);
                                      }, 500);
                                      return () => {
                                        clearTimeout(cleartimeout);
                                      };
                                      handleOpenRenameAlert();
                                    }}
                                    style={{
                                      backgroundColor: colors.secondaryColor,
                                      color: colors.primaryColor,
                                    }}
                                    disabled={
                                      newName === testCaseNameChange && newName !== ""
                                        ? true
                                        : false
                                    }
                                  >
                                    <DoneIcon />
                                  </IconButton>

                                  <IconButton
                                    onClick={() => {
                                      setRenameCheck(!renameCheck);
                                    }}
                                    style={{
                                      backgroundColor: colors.secondaryColor,
                                      color: colors.primaryColor,
                                    }}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </span>
                              ) : (
                                <span hidden={!copyCheck}>
                                  <Input
                                    placeholder={testCaseNameChange}
                                    value={copyNewName}
                                    onChange={(e) => setCopyNewName(e.target.value)}
                                    style={{
                                      width: "250px",
                                      backgroundColor: colors.secondaryColor,
                                      color: colors.primaryColor,
                                    }}
                                  />

                                  <IconButton
                                    onClick={() => {
                                      copyTCName(state.tcs.idtest_case, copyNewName);
                                      setCopyCheck(!copyCheck);
                                      // setTestCaseList(copyNewName)
                                      // alert('Copied successfully')
                                      handleOpenCopyAlert();
                                      setCopyTcValue(true);
                                      const cleartimeout = setTimeout(() => {
                                        getTestCase(state.idmodules);
                                      }, 1000);
                                      return () => {
                                        clearTimeout(cleartimeout);
                                      };
                                    }}
                                    style={{
                                      backgroundColor: colors.secondaryColor,
                                      color: colors.primaryColor,
                                    }}
                                    disabled={
                                      copyNewName === testCaseNameChange && copyNewName !== ""
                                        ? true
                                        : false
                                    }
                                  >
                                    <DoneIcon />
                                  </IconButton>

                                  <IconButton
                                    onClick={() => {
                                      setCopyCheck(!copyCheck);
                                    }}
                                    style={{
                                      backgroundColor: colors.secondaryColor,
                                      color: colors.primaryColor,
                                    }}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </span>
                              )}
                              <span hidden={renameCheck || copyCheck}>
                                <Tooltip title={"Rename Test Case"}>
                                  <IconButton
                                    onClick={() => {
                                      // handleRenameIconClick();
                                      setRenameCheck(!renameCheck);
                                      setNewName(testCaseNameChange);
                                    }}
                                    style={{
                                      backgroundColor: colors.secondaryColor,
                                      color: colors.primaryColor,
                                    }}
                                    disabled={checkModuleEnable === 1 ? true : false}
                                  >
                                    {/* {iscopyIconVisible === true ?  <ContentCopyIcon/> :  <DriveFileRenameOutlineIcon />   } */}
                                    <DriveFileRenameOutlineIcon />
                                  </IconButton>
                                </Tooltip>

                                <span>
                                  <Tooltip title={"Copy Test Case"}>
                                    <IconButton
                                      onClick={() => {
                                        setCopyCheck(!copyCheck);
                                        setCopyNewName(testCaseNameChange);
                                      }}
                                      style={{
                                        backgroundColor: colors.secondaryColor,
                                        color: colors.primaryColor,
                                      }}
                                      disabled={checkModuleEnable === 1 ? true : false}
                                    >
                                      {/* <FileCopyIcon /> */}
                                      <ContentCopyIcon />
                                    </IconButton>
                                  </Tooltip>

                                  <span>
                                    <Tooltip title={"Delete Test Case"}>
                                      <IconButton
                                        onClick={() => {
                                          setDeleteCheck(!deletecheck);
                                          // setDeleteTestCaseValue(tcid)
                                          // setDeleteTestCaseValue(row.idtest_step);
                                          handleClickOpenDeletionAlert();
                                          // setCopyNewName(testCaseNameChange)
                                          // handleClickOpenDeletionAlert()
                                        }}
                                        style={{
                                          backgroundColor: colors.secondaryColor,
                                          color: colors.primaryColor,
                                        }}
                                        disabled={checkModuleEnable === 1 ? true : false}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </span>
                                </span>
                              </span>
                            </>
                          </Typography>
                          <Tooltip title={"Execute Test Case"}>
                            <IconButton
                              size="small"
                              variant="contained"
                              onClick={(event) => {
                                event.preventDefault();
                                executeTest({
                                  id: state?.tcs?.idtest_case,
                                  SelectEnvironmentName: state.SelectEnvironmentName,
                                  SelectEnvironmentUrl: state.SelectEnvironmentUrl,
                                  SelectBrowserName: state.SelectBrowserName,
                                  SelectResolution: state.SelectResolution,
                                  SelectRunonce: state.SelectRunonce,
                                  TcThreshold: state.TcThreshold,
                                  testcase: encodeURIComponent(state?.tcs?.Test_Case),
                                  proxyurl: state.proxy,
                                  baseline: "false",
                                });
                                if (state.display !== "execute") {
                                  setState((prev) => {
                                    return { ...prev, display: "execute" };
                                  });
                                } else {
                                  setState((prev) => {
                                    return { ...prev, display: "view" };
                                  });
                                }
                              }}
                              // sx={{ marginLeft: 2 }}
                              style={{
                                backgroundColor: colors.secondaryColor,
                                color: colors.primaryColor,
                                marginLeft: "2px",
                              }}
                            >
                              <VerifiedSharpIcon />
                            </IconButton>
                          </Tooltip>
                          {state && state?.VideoUrl && !state.VideoUrl.endsWith("null") && (
                            <Tooltip title={"Test Case Video"}>
                              <IconButton
                                onClick={openPopup}
                                style={{
                                  backgroundColor: colors.secondaryColor,
                                  color: colors.primaryColor,
                                }}
                                disabled={checkModuleEnable === 1 ? true : false}
                              >
                                <OndemandVideoIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          <VideoPopup
                            videoUrl={
                              state?.VideoUrl
                                ? (() => {
                                    const vurl = new URL(state?.VideoUrl.replace(/ /g, "%20"));
                                    if (vurl.searchParams.get("token") === "") {
                                      vurl.searchParams.set("token", temp.token);
                                    }
                                    return vurl.toString().replace(/ /g, "%20");
                                  })()
                                : ""
                            }
                            onClose={closePopup}
                            open={showPopup}
                            tcnameForvideo={state?.tcs?.Test_Case}
                          />
                          {temp?.usertype === 1 && (
                            <Tooltip title={"NLP Steps"}>
                              <IconButton
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  setOpenTestcase(true);
                                  getSteps(state.tcs.idtest_case);
                                }}
                                style={{
                                  backgroundColor: colors.secondaryColor,
                                  color: colors.primaryColor,
                                  marginLeft: "2px",
                                }}
                              >
                                <AddCircleOutlineIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          {temp?.usertype !== 11 && (
                            <Tooltip title={"Download Code"}>
                              <IconButton
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  genCode(state.tcs.idtest_case, state.tcs.Test_Case);
                                }}
                                style={{
                                  backgroundColor: colors.secondaryColor,
                                  color: colors.primaryColor,
                                  marginLeft: "2px",
                                }}
                              >
                                <DownloadIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          {temp?.usertype === 1 && (
                            <Tooltip title={"Download test steps"}>
                              <IconButton
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  tsDownload(state.tcs.idtest_case);
                                }}
                                style={{
                                  backgroundColor: colors.secondaryColor,
                                  color: colors.primaryColor,
                                  marginLeft: "2px",
                                }}
                              >
                                <SimCardDownloadIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          {temp?.usertype !== 11 && (
                            <Tooltip title={"Download Pom File"}>
                              <IconButton
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  pomFileDownload(state.idproducts);
                                }}
                                style={{
                                  backgroundColor: colors.secondaryColor,
                                  color: colors.primaryColor,
                                  marginLeft: "2px",
                                }}
                              >
                                <FolderZipIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          {temp?.usertype !== 11 && (
                            <Tooltip title={"Baseline Images"}>
                              <IconButton
                                size="small"
                                variant="contained"
                                onClick={(event) => {
                                  event.preventDefault();
                                  executeTest({
                                    id: state?.tcs?.idtest_case,
                                    SelectEnvironmentName: state.SelectEnvironmentName,
                                    SelectEnvironmentUrl: state.SelectEnvironmentUrl,
                                    SelectBrowserName: state.SelectBrowserName,
                                    SelectResolution: state.SelectResolution,
                                    SelectRunonce: state.SelectRunonce,
                                    TcThreshold: state.TcThreshold,
                                    testcase: encodeURIComponent(state?.tcs?.Test_Case),
                                    proxyurl: state.proxy,
                                    baseline: "true",
                                  });
                                  if (state.display !== "execute") {
                                    setState((prev) => {
                                      return { ...prev, display: "execute" };
                                    });
                                  } else {
                                    setState((prev) => {
                                      return { ...prev, display: "view" };
                                    });
                                  }
                                }}
                                // sx={{ marginLeft: 2 }}
                                style={{
                                  backgroundColor: colors.secondaryColor,
                                  color: colors.primaryColor,
                                  marginLeft: "2px",
                                }}
                              >
                                <StraightenIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          <Tooltip title={"Info"}>
                            <IconButton
                              size="small"
                              variant="contained"
                              onClick={() => {
                                setOpenInfo(true);
                              }}
                              // sx={{ marginLeft: 2 }}
                              style={{
                                backgroundColor: colors.secondaryColor,
                                color: colors.primaryColor,
                                marginLeft: "2px",
                              }}
                            >
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title={"Generate Test Cases"}>
                            <Button
                              variant="contained"
                              size="small"
                              onClick={() => {
                                window.open("http://demo.nogrunt.com:3001", "_blank");
                              }}
                              style={{
                                backgroundColor: colors.secondaryColor,
                                color: colors.primaryColor,
                                marginLeft: "2px",
                              }}
                            >
                              <OpenInNewOutlined />
                            </Button>
                          </Tooltip>

                          <BootstrapDialog
                            onClose={handleCloseTescase}
                            aria-labelledby="customized-dialog"
                            open={openTestcase}
                            className="custom-dialog"
                            sx={{
                              display: "flex",
                              top: "35%",
                              left: "35%",
                              right: "30%",
                              width: "35%",
                              height: "55%",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <BootstrapDialogTitle
                              id="customized-dialog"
                              onClose={handleCloseTescase}
                              sx={{
                                padding: "16px 90px",
                                color: colors.primaryColor,
                                backgroundColor: colors.secondaryColor,
                              }}
                            >
                              Test Case Step Details
                            </BootstrapDialogTitle>
                            <DialogContent
                              sx={{
                                padding: "20px", // Adjust the padding as needed
                                height: "100%", // Adjust the height as needed
                              }}
                            >
                              <div style={{ display: "flex", marginTop: "13px" }}>
                                <TextField
                                  inputProps={{ maxLength: 5000 }}
                                  multiline
                                  rows={15}
                                  type="text"
                                  size="small"
                                  value={getAllSteps}
                                  label="Step Details"
                                  sx={{
                                    width: "28rem",
                                  }}
                                  disabled={true}
                                />
                              </div>
                            </DialogContent>

                            <DialogActions>
                              <ColorButton
                                sx={{ padding: "3px 40px" }}
                                onClick={handleCloseTescase}
                              >
                                CLOSE
                              </ColorButton>
                            </DialogActions>
                          </BootstrapDialog>

                          <BootstrapDialog
                            onClose={handleCloseInfo}
                            aria-labelledby="customized-dialog"
                            open={openInfo}
                            className="custom-dialog"
                            sx={{
                              display: "flex",
                              top: "35%",
                              left: "35%",
                              right: "30%",
                              width: "35%",
                              height: "20%",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <BootstrapDialogTitle
                              id="customized-dialog"
                              onClose={handleCloseInfo}
                              sx={{
                                padding: "16px 90px",
                                color: colors.primaryColor,
                                backgroundColor: colors.secondaryColor,
                              }}
                            >
                              Test Case Info
                            </BootstrapDialogTitle>
                            <DialogContent
                              sx={{
                                padding: "20px", // Adjust the padding as needed
                                height: "100%", // Adjust the height as needed
                              }}
                            >
                              <div style={{ display: "flex", marginTop: "13px" }}>
                                <label className="highlighted-label">
                                  <div>Created Date : {state.tcs.Created_Date}</div>
                                  <div>Baseline Date : {state.tcs.baselinedate}</div>
                                </label>
                              </div>
                            </DialogContent>

                            <DialogActions>
                              <ColorButton sx={{ padding: "3px 40px" }} onClick={handleCloseInfo}>
                                CLOSE
                              </ColorButton>
                            </DialogActions>
                          </BootstrapDialog>
                        </Box>
                        <Box>
                          <IconButton
                            size="small"
                            variant="contained"
                            color="inherit"
                            onClick={(event) => {
                              handleClickOpenExecute();
                              setRefreshFlag(false);
                              clearInterval(window.myTimer);
                            }}
                            sx={{ marginLeft: 2 }}
                          >
                            <SettingsIcon />
                          </IconButton>
                          <BootstrapDialog
                            onClose={handleCloseExecute}
                            aria-labelledby="customized-dialog"
                            open={openDialogExecute}
                            className="custom-dialog"
                            sx={{
                              display: "flex",
                              top: "10%",
                              left: "35%",
                              right: "30%",
                              justifyContent: "center",
                              alignItems: "center",
                              maxHeight: "55vh",
                            }}
                          >
                            <BootstrapDialogTitle
                              id="customized-dialog"
                              onClose={handleCloseExecute}
                              sx={{
                                padding: "16px 90px",
                                color: colors.primaryColor,
                                backgroundColor: colors.secondaryColor,
                              }}
                            >
                              Test Scenario Settings
                            </BootstrapDialogTitle>
                            <DialogContent>
                              <div style={{ display: "flex", marginTop: "13px" }}>
                                <TextField
                                  inputProps={{ maxLength: 500 }}
                                  multiline
                                  rows={4}
                                  type="text"
                                  size="small"
                                  value={state.DescriptionData}
                                  label="TestCase Description"
                                  sx={{
                                    width: "28rem",
                                  }}
                                  onChange={(event) => {
                                    setState({
                                      ...state,
                                      testCaseId: state.tcs.idtest_case,
                                      DescriptionData: event.target.value,
                                      enablevideo: state.enablevideo,
                                      continuetest: state.continuetest,
                                      forcenewsession: state.forcenewsession,
                                    });
                                  }}
                                />
                              </div>
                              <div style={{ justifyContent: "center", display: "flex" }}>
                                <ColorButton
                                  sx={{ padding: "3px 40px", marginTop: "13px" }}
                                  onClick={() => {
                                    validationdescriptiondata({
                                      testCaseId: state.tcs.idtest_case,
                                      DescriptionData: state.DescriptionData,
                                    });
                                  }}
                                >
                                  SUBMIT
                                </ColorButton>
                              </div>
                              <div style={{ justifyContent: "center", display: "flex" }}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={state.continuetest}
                                      onChange={() =>
                                        setState((prevState) => ({
                                          ...prevState,
                                          continuetest: !prevState.continuetest,
                                        }))
                                      }
                                    />
                                  }
                                  label="Continue Previous Scenario"
                                />
                              </div>
                              <div style={{ justifyContent: "center", display: "flex" }}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={state.forcenewsession}
                                      onChange={() =>
                                        setState((prevState) => ({
                                          ...prevState,
                                          forcenewsession: !prevState.forcenewsession,
                                        }))
                                      }
                                    />
                                  }
                                  label="Run in New Session"
                                />
                              </div>
                              <div style={{ justifyContent: "center", display: "flex" }}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={state.enablevideo}
                                      onChange={() =>
                                        setState((prevState) => ({
                                          ...prevState,
                                          enablevideo: !prevState.enablevideo,
                                        }))
                                      }
                                    />
                                  }
                                  label="Enable Audio/Video"
                                />
                              </div>
                              <div style={{ display: "flex" }}>
                                <FormControl
                                  sx={{
                                    marginTop: "0.5rem",
                                  }}
                                  fullWidth
                                >
                                  <div style={{ marginBottom: "0.5em" }}>
                                    <InputLabel id={`TestData Environment`}>Environment</InputLabel>
                                  </div>
                                  <Select
                                    labelId={`TestData Environment`}
                                    id={`TestData Environment select`}
                                    value={state.SelectEnvironmentName}
                                    label="Environment"
                                    fullWidth
                                    name="SelectEnvironment"
                                    IconComponent={(props) => <ArrowDropDownSharpIcon {...props} />}
                                    onChange={(event) => {
                                      setState({
                                        ...state,
                                        testCaseId: state.tcs.idtest_case,
                                        SelectEnvironmentName: event.target.value,
                                        SelectEnvironmentUrl: state.envDetails
                                          .filter((item) => item.envname === event.target.value)
                                          .map((item) => item.envurl)[0],
                                      });
                                    }}
                                    style={{
                                      minWidth: 180,
                                      alignItems: "center",
                                      height: "30px",
                                    }}
                                  >
                                    {state.envDetails && state.envDetails.length > 0 && (
                                      <MenuItem key="ASRECORDED" value="ASRECORDED">
                                        As Recorded
                                      </MenuItem>
                                    )}
                                    {state.envDetails?.map((item, index) => (
                                      <MenuItem key={index} value={item.envname}>
                                        {item.envname}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                                <FormControl
                                  sx={{
                                    marginTop: "0.5rem",
                                    marginLeft: "1rem",
                                  }}
                                  fullWidth
                                >
                                  <div style={{ marginBottom: "0.5em" }}>
                                    <InputLabel
                                    // id={`TestData Environment`}
                                    // style={{
                                    //   paddingTop: 4,
                                    // }}
                                    >
                                      Browser
                                    </InputLabel>
                                  </div>
                                  <Select
                                    labelId={`TestData Browser`}
                                    id={`TestData Browser select`}
                                    value={
                                      state.SelectBrowserName === null
                                        ? "Chrome"
                                        : state.SelectBrowserName
                                    }
                                    label="Browser"
                                    name="SelectBrowser"
                                    IconComponent={(props) => <ArrowDropDownSharpIcon {...props} />}
                                    onChange={(event) => {
                                      setState({
                                        ...state,
                                        testCaseId: state.tcs.idtest_case,
                                        SelectBrowserName: event.target.value,
                                      });
                                    }}
                                    style={{
                                      minWidth: 180,
                                      alignItems: "center",
                                      height: "30px",
                                    }}
                                  >
                                    <MenuItem value="">
                                      <em>None</em>
                                    </MenuItem>
                                    {state.browserDetails.map((item, index) => (
                                      <MenuItem key={index} value={item.browsername}>
                                        {item.browsername}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </div>
                              <div style={{ display: "flex" }}>
                                <FormControl
                                  sx={{
                                    marginTop: "1rem",
                                  }}
                                  fullWidth
                                >
                                  <div style={{ marginBottom: "0.5em" }}>
                                    <InputLabel
                                    // id={`TestData Environment`}
                                    // style={{
                                    //   paddingTop: 4,
                                    // }}
                                    >
                                      Resolution
                                    </InputLabel>
                                  </div>
                                  <Select
                                    labelId={`TestData Resolution`}
                                    id={`TestData Resolution select`}
                                    value={state.Height}
                                    label="Resolution"
                                    name="SelectResolution"
                                    IconComponent={(props) => <ArrowDropDownSharpIcon {...props} />}
                                    onChange={(event) => {
                                      if (event.target.value !== "") {
                                        const selectedWidth = state.resolutionDetails.filter(
                                          (item) => item.Height === event.target.value,
                                        )[0].Width;

                                        const dimensions = `${event.target.value}x${selectedWidth}`;

                                        setState({
                                          ...state,
                                          Height: event.target.value,
                                          Width: selectedWidth,
                                          SelectResolution: dimensions,
                                        });
                                      } else {
                                        setState({
                                          ...state,
                                          Height: 0,
                                          Width: 0,
                                        });
                                      }
                                    }}
                                    style={{
                                      minWidth: 180,
                                      alignItems: "center",
                                      height: "30px",
                                    }}
                                  >
                                    {state.resolutionDetails.map((item, index) => (
                                      <MenuItem key={index} value={item.Height}>
                                        {`${item.Height}x${item.Width}`}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                                <FormControl
                                  sx={{
                                    marginTop: "1rem",
                                    marginLeft: "1rem",
                                  }}
                                  fullWidth
                                >
                                  <div style={{ marginBottom: "0.5em" }}>
                                    <InputLabel
                                    // id={`TestData Environment`}
                                    // style={{
                                    //   paddingTop: 4,
                                    // }}
                                    >
                                      Run Once
                                    </InputLabel>
                                  </div>
                                  <Select
                                    labelId={`TestData Runonce`}
                                    id={`TestData Runonce select`}
                                    value={state.SelectRunonce}
                                    label="Runonce"
                                    name="SelectRunonce"
                                    IconComponent={(props) => <ArrowDropDownSharpIcon {...props} />}
                                    onChange={(event) => {
                                      setState({
                                        ...state,
                                        testCaseId: state.tcs.idtest_case,
                                        SelectRunonce: event.target.value,
                                      });
                                    }}
                                    style={{
                                      minWidth: 180,
                                      alignItems: "center",
                                      height: "30px",
                                    }}
                                  >
                                    {state.runOnceDetails.map((item, index) => (
                                      <MenuItem key={index} value={item.value}>
                                        {item.option}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </div>
                              <div style={{ display: "flex" }}>
                                <TextField
                                  className="textfield"
                                  label="Threshold"
                                  variant="filled"
                                  size="small"
                                  // defaultValue={valuesSaved[indexValueChange]?.status}
                                  value={state.TcThreshold}
                                  InputLabelProps={{ shrink: true }}
                                  onChange={(event) => {
                                    setState({
                                      ...state,
                                      testCaseId: state.tcs.idtest_case,
                                      TcThreshold: event.target.value,
                                    });
                                  }}
                                  InputProps={{
                                    style: {
                                      paddingTop: "0 !important",
                                      paddingBottom: "0 !important",
                                    },
                                  }}
                                />
                                <TextField
                                  className="textfield"
                                  label="Proxy"
                                  variant="filled"
                                  size="small"
                                  // defaultValue={valuesSaved[indexValueChange]?.status}
                                  value={state.proxy}
                                  InputLabelProps={{ shrink: true }}
                                  onChange={(event) => {
                                    setState({
                                      ...state,
                                      proxy: event.target.value,
                                    });
                                  }}
                                />
                              </div>
                              <div style={{ justifyContent: "center", display: "flex" }}>
                                <ColorButton
                                  sx={{ padding: "3px 40px", marginTop: "13px" }}
                                  onClick={() => {
                                    savingDataInSettings({
                                      EnvironmentName: state.SelectEnvironmentName,
                                      EnvironmentUrl: state.envDetails
                                        .filter(
                                          (item) => item.envname === state.SelectEnvironmentName,
                                        )
                                        .map((item) => item.envurl)[0],
                                      Browser: state.SelectBrowserName,
                                      Resolution: state.SelectResolution,
                                      RunOnce: state.SelectRunonce,
                                      Threshold: state.TcThreshold,
                                      Proxy: state.proxy,
                                      testCaseId: state.tcs.idtest_case,
                                      enablevideo: state.enablevideo,
                                      continuetest: state.continuetest,
                                      forcenewsession: state.forcenewsession,
                                    });
                                    setOpenAlertSave(true);
                                    handleCloseExecute();
                                  }}
                                >
                                  Save
                                </ColorButton>
                                {/* <ColorButton
                                sx={{ padding: "3px 40px" }}
                                onClick={() => {
                                  regenrateScreenshot(state.tcs.idtest_case)
                                  handleCloseExecute()
                                }}
                              >
                                Regenrate Screenshot
                              </ColorButton> */}
                                <ColorButton
                                  sx={{ padding: "3px 40px", marginTop: "13px" }}
                                  onClick={handleCloseExecute}
                                >
                                  CLOSE
                                </ColorButton>
                              </div>
                            </DialogContent>
                          </BootstrapDialog>

                          {refreshFlag === true ? (
                            <IconButton
                              size="small"
                              variant="contained"
                              onClick={() => {
                                setRefreshFlag(false);
                                clearInterval(window.myTimer);
                              }}
                              // sx={{ marginLeft: 1 }}
                              style={{
                                backgroundColor: colors.secondaryColor,
                                color: colors.primaryColor,
                                marginLeft: "16px",
                              }}
                            >
                              <SyncDisabledIcon />
                            </IconButton>
                          ) : (
                            <IconButton
                              size="small"
                              variant="contained"
                              onClick={() => {
                                setRefreshFlag(true);
                                window.myTimer = setInterval(() => {
                                  getRecording(state);
                                  return () => clearInterval(window.myTimer);
                                }, 5000);
                              }}
                              style={{
                                backgroundColor: colors.secondaryColor,
                                color: colors.primaryColor,
                                marginLeft: "16px",
                              }}
                            >
                              <LoopIcon />
                            </IconButton>
                          )}
                        </Box>
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell colSpan={16}>
                      <Box
                        sx={{
                          display: "flex",
                          flex: 1,
                          justifyContent: "flex-end",
                          alignItems: "center",
                          flexDirection: "row",
                          paddingX: "1.5rem",
                          color: colors.primaryColor,
                          backgroundColor: colors.secondaryColor,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "flex-start",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <FormControl sx={{ width: 240, color: colors.primaryColor }}>
                            <InputLabel
                              style={{
                                color: colors.primaryColor,
                                backgroundColor: colors.secondaryColor,
                                padding: 5,
                              }}
                              id={"product name"}
                            >
                              Product
                            </InputLabel>
                            <Select
                              labelId={"product name"}
                              id={"product name Select"}
                              value={state.idproducts}
                              name={"product"}
                              label="Product"
                              IconComponent={(props) => (
                                <ArrowDropDownSharpIcon
                                  style={{ color: colors.primaryColor, fontSize: 40 }}
                                  {...props}
                                />
                              )}
                              sx={{
                                color: colors.primaryColor,
                                border: `2px solid ${colors.primaryColor}`,
                                fontSize: 16,
                                "&:focus": {
                                  borderRadius: 4,
                                  border: `4px solid ${colors.primaryColor}`,
                                },
                              }}
                              onChange={(event) => {
                                setState({
                                  ...state,
                                  idproducts: event.target.value,
                                });
                                getModules(event.target.value);
                                getResolutionData(event.target.value);
                                getEnvironments(event.target.value);
                              }}
                              style={{
                                minWidth: 100,
                                marginRight: "2rem",
                              }}
                            >
                              {productList.map((item, index) => (
                                <MenuItem key={index} value={item.idproducts}>
                                  {item.productname}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {state.idproducts !== "" && (
                            <FormControl sx={{ width: 240 }}>
                              <InputLabel
                                style={{
                                  color: colors.primaryColor,
                                  backgroundColor: colors.secondaryColor,
                                  padding: 5,
                                }}
                                id={"module name"}
                              >
                                Module
                              </InputLabel>
                              <Select
                                labelId={"module name"}
                                IconComponent={(props) => (
                                  <ArrowDropDownSharpIcon
                                    style={{ color: colors.primaryColor, fontSize: 40 }}
                                    {...props}
                                  />
                                )}
                                sx={{
                                  color: colors.primaryColor,
                                  border: `2px solid ${colors.primaryColor}`,
                                  fontSize: 16,
                                  "&:focus": {
                                    borderRadius: 4,
                                    border: `4px solid ${colors.primaryColor}`,
                                  },
                                }}
                                id={"module name Select"}
                                value={state.idmodules}
                                label="Module"
                                onChange={(event) => {
                                  setState({
                                    ...state,
                                    idmodules: event.target.value,
                                  });
                                  getModulesCheck(event.target.value);
                                  getTestCase(event.target.value);
                                }}
                                style={{
                                  minWidth: 100,
                                  marginRight: "2rem",
                                }}
                              >
                                {ModuleList?.map((item, index) => (
                                  <MenuItem key={index} value={item.idmodules}>
                                    {item.modulename}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                          {state.idmodules !== "" && (
                            <FormControl
                              sx={{
                                width: 240,
                              }}
                            >
                              <InputLabel
                                style={{
                                  color: colors.primaryColor,
                                  backgroundColor: colors.secondaryColor,

                                  padding: 5,
                                }}
                                id={"Test Case name"}
                              >
                                Test Cases
                              </InputLabel>

                              <Select
                                labelId={"Test Case name"}
                                IconComponent={(props) => (
                                  <ArrowDropDownSharpIcon
                                    style={{ color: colors.primaryColor, fontSize: 40 }}
                                    {...props}
                                  />
                                )}
                                sx={{
                                  color: colors.primaryColor,
                                  border: `2px solid ${colors.primaryColor}`,
                                  fontSize: 16,
                                  "&:focus": {
                                    borderRadius: 4,
                                    border: `4px solid ${colors.primaryColor}`,
                                  },
                                }}
                                id={"Test Case Name Select"}
                                label="Test Case"
                                value={
                                  tcValue === true || copyTcValue === true
                                    ? TestCaseList[0].idtest_case
                                    : state.idtest_case
                                }
                                onChange={(event) => {
                                  clearInterval(window.myTimer);
                                  TestCaseList.map((item) => {
                                    if (event.target.value === "") {
                                      setState({
                                        ...state,
                                        idtest_case: 0,
                                        Test_Case: event.target.value,
                                      });
                                    } else if (item.idtest_case === event.target.value) {
                                      setState({
                                        ...state,
                                        idtest_case: event.target.value,
                                        Test_Case: item.Test_Case,
                                        tcs: item,
                                        DescriptionData: item.Test_Case_Desc,
                                      });
                                    }
                                    setPage(0);

                                    return true;
                                  });
                                  setRefreshFlag(false);
                                  getSearchValue(event.target.value);
                                  setIsLoading(true);
                                  setTcValue(false);
                                  setCopyTcValue(false);
                                }}
                                style={{
                                  minWidth: 100,
                                }}
                              >
                                {TestCaseList.map((item, index) => (
                                  <MenuItem key={index} value={item.idtest_case}>
                                    {item.Test_Case}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                          {state.idtest_case !== "" && (
                            <ColorButton
                              size="small"
                              variant="contained"
                              onClick={(event) => {
                                setState({
                                  ...state,
                                  productname: "",
                                  modulename: "",
                                  idproducts: "",
                                  idmodules: "",
                                  idtest_case: "",
                                  Test_Case: "",
                                  idproducts_One: "",
                                  idmodules_One: "",
                                  idtest_case_One: "",
                                });
                                getRecording({
                                  file: "",
                                  tc: "",
                                  xls: [],
                                  tcs: {},
                                  display: "view",
                                  testCaseId: -1,
                                  expanded: false,
                                  isGenDataSelected: false,
                                  generateDataValue: "",
                                  productname: "",
                                  modulename: "",
                                  idproducts: "",
                                  idmodules: "",
                                  idtest_case: "",
                                  idproducts_One: "",
                                  idmodules_One: "",
                                  idtest_case_One: "",
                                  Test_Case: "",
                                });
                                setSearchString("");
                              }}
                              sx={{ marginLeft: 4 }}
                            >
                              Clear Filter
                            </ColorButton>
                          )}
                        </Box>
                        {state.idproducts !== "" && state.idmodules !== "" && (
                          <Box
                            sx={{
                              display: "flex",
                              ml: "5rem",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              flexDirection: "row",
                              paddingY: "1.5rem",
                              backgroundColor: colors.secondaryColor,
                              color: colors.primaryColor,
                            }}
                          >
                            <Paper
                              component="form"
                              sx={{
                                p: "2px 4px",
                                marginLeft: "0rem",
                                display: "flex",
                                alignItems: "center",
                                width: 400,
                              }}
                            >
                              <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Search Test Case here..."
                                inputProps={{ "aria-label": "Search Test Case here..." }}
                                defaultValue={SearchString}
                                onChange={(e) => {
                                  e.preventDefault();
                                  setSearchString(e.target.value);
                                }}
                              />
                              <IconButton
                                type="button"
                                sx={{ p: "10px" }}
                                aria-label="search"
                                onClick={() => {
                                  searchTestCaseInMod(state.idmodules, SearchString);
                                }}
                              >
                                <SearchIcon />
                              </IconButton>
                            </Paper>
                          </Box>
                        )}
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                </TableHead>
              </Table>
            </div>

            <Table>
              <TableHead style={{}}>
                <StyledTableRow style={{ align: "left" }}>
                  {tableColums.map((item) => (
                    <StyledTableCell
                      key={item.id}
                      align={item.align}
                      colSpan={item.colspan}
                      width={item.width}
                    >
                      {item.name}
                    </StyledTableCell>
                  ))}
                  {state.display === "execute" && (
                    <StyledTableCell align="right">Status</StyledTableCell>
                  )}
                  <StyledTableCell />
                  <StyledTableCell width="16%" colSpan={2} align="left">
                    Wait Time
                  </StyledTableCell>
                  <StyledTableCell colSpan={1} />
                </StyledTableRow>
              </TableHead>
            </Table>
            <div style={{ overflow: "auto" }}>
              <Table style={{ tableLayout: "fixed" }}>
                <TableBody>
                  {(rowsPerPage > 0
                    ? (state.xls ?? []).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : state.xls ?? []
                  ).map((row, index) => {
                    const dataIndex = page * rowsPerPage + index;
                    return (
                      <React.Fragment key={dataIndex}>
                        <StyledTableRow>
                          <StyledTableCell width="6%" align="center" colSpan={1}>
                            <Tooltip
                              arrow
                              disableHoverListener={row.IssuesDesc === ""}
                              title={
                                <React.Fragment>
                                  <Typography color="inherit">{row?.IssuesDesc}</Typography>
                                </React.Fragment>
                              }
                            >
                              <Box
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                  paddingY: 1,
                                  color: "#FFF",
                                  borderRadius: "0.5rem",
                                  backgroundColor:
                                    row.Issues === "" ||
                                    row.Issues === null ||
                                    row.Issues === undefined
                                      ? "green"
                                      : row.Issues.includes("FATAL")
                                        ? "red"
                                        : row.Issues.includes("WARN")
                                          ? "#F29F05"
                                          : "",
                                  fontSize: "1rem",
                                  fontWeight: "700",
                                }}
                              >
                                {`${row.Step_Number} `}
                                {row.Issues === "" ||
                                row.Issues === null ||
                                row.Issues === undefined ? (
                                  <CheckCircleIcon color="inherited" />
                                ) : row.Issues.includes("FATAL") ? (
                                  <ErrorIcon color="inherited" />
                                ) : row.Issues.includes("WARN") ? (
                                  <WarningIcon color="inherited" />
                                ) : (
                                  ""
                                )}
                              </Box>
                            </Tooltip>
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            width="14%"
                            component="th"
                            colSpan={2}
                            scope="row"
                          >
                            {row?.Page_Description?.length <= 40 ? (
                              row.Page_Description
                            ) : (
                              <BootstrapTooltip
                                title={
                                  <React.Fragment>
                                    <Typography color="inherit">{row.Page_Description}</Typography>
                                  </React.Fragment>
                                }
                                arrow
                              >
                                <div>{row.Page_Description?.slice(0, 40) + "..."}</div>
                              </BootstrapTooltip>
                            )}
                          </StyledTableCell>
                          {/* ////Keyword column */}
                          <StyledTableCell width="7%" align="center" colSpan={1}>
                            {row.Keyword}
                          </StyledTableCell>

                          {row.Action == "ById" ||
                          row.Action == "ByValue" ||
                          row.Action == "ByText" ||
                          row.Action == "Select" ? (
                            <StyledTableCell align="center" colSpan={1} width="7%">
                              <FormControl>
                                <Select
                                  variant="standard"
                                  key={row.idtest_step}
                                  labelId={`demo-simple-select-label-${row.idtest_step}`}
                                  id={`row.idtest_step-${row.idtest_step}`}
                                  value={row.subAction}
                                  label="Action"
                                  onChange={handleSelectChange(row.idtest_step, row.Test_Case_Id)}
                                >
                                  <MenuItem value="ById">By Index</MenuItem>
                                  <MenuItem value="ByValue">By Value</MenuItem>
                                  <MenuItem value="ByText">By Text</MenuItem>
                                </Select>
                              </FormControl>
                            </StyledTableCell>
                          ) : row.Keyword === "Check Box" ? (
                            <StyledTableCell align="center" colSpan={1} width="7%">
                              <FormControl>
                                <Select
                                  variant="standard"
                                  key={row.idtest_step}
                                  labelId={`demo-simple-select-label-${row.idtest_step}`}
                                  id={`row.idtest_step-${row.idtest_step}`}
                                  value={row.Action === "click" ? "ByXpath" : row.Action}
                                  defaultValue="ByXpath"
                                  label="Action"
                                  onChange={handleCheckBoxChange(row.idtest_step, row.Test_Case_Id)}
                                >
                                  <MenuItem value="ByXpath">By Path</MenuItem>
                                  <MenuItem value="ById">By Id</MenuItem>
                                  <MenuItem value="ByText">By Text</MenuItem>
                                </Select>
                              </FormControl>
                            </StyledTableCell>
                          ) : row.Keyword === "Assertion" && row.Action === "validatePartial" ? (
                            <StyledTableCell align="center" colSpan={1} width="7%">
                              <FormControl>
                                <Select
                                  variant="standard"
                                  key={row.idtest_step}
                                  labelId={`demo-simple-select-label-${row.idtest_step}`}
                                  id={`row.idtest_step-${row.idtest_step}`}
                                  value={row.subAction}
                                  defaultValue="validatePartial"
                                  label="Action"
                                  onChange={handleValidateChange(row.idtest_step, row.Test_Case_Id)}
                                >
                                  <MenuItem value="validatePartial">Contains</MenuItem>
                                  <MenuItem value="validateExact">Exact</MenuItem>
                                </Select>
                              </FormControl>
                            </StyledTableCell>
                          ) : (
                            <StyledTableCell align="center" colSpan={1} width="7%">
                              {row.Action}
                            </StyledTableCell>
                          )}
                          <StyledTableCell align="center" colSpan={1} width="10%">
                            <FormControl>
                              <Select
                                variant="standard"
                                key={row.idtest_step}
                                labelId={`demo-simple-select-label-${row.idtest_step}`}
                                id={`row.idtest_step-${row.idtest_step}`}
                                value={row.Flow}
                                label="Flow"
                                onChange={(event) =>
                                  changeFlow(row.idtest_step, row.Test_Case_Id, event.target.value)
                                }
                                disabled={checkModuleEnable === 1 ? true : false}
                              >
                                <MenuItem value="Positive">Must Pass</MenuItem>
                                <MenuItem value="Negative">Must Fail</MenuItem>
                                <MenuItem value="Optional">Optional</MenuItem>
                              </Select>
                            </FormControl>
                          </StyledTableCell>
                          <StyledTableCell align="center" colSpan={3} width="15%">
                            {row.TestData?.length <= 40 ? (
                              row.TestData
                            ) : (
                              <BootstrapTooltip
                                title={
                                  <React.Fragment>
                                    <Typography color="inherit">{row.TestData}</Typography>
                                  </React.Fragment>
                                }
                                arrow
                              >
                                <div>{row.TestData?.slice(0, 40) + "..."}</div>
                              </BootstrapTooltip>
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center" colSpan={1} width="12%">
                            {row.VarName}
                          </StyledTableCell>
                          <StyledTableCell align="center" width="4%">
                            <Link href={row.fsl} underline="hover">
                              {row.fsl}
                            </Link>
                          </StyledTableCell>
                          {/* <StyledTableCell align="right">
                      {row.ValDevice}
                    </StyledTableCell> */}
                          {state.display === "execute" && (
                            <StyledTableCell align="right" width="7%">
                              {row.status}
                            </StyledTableCell>
                          )}
                          <StyledTableCell align="left" colSpan={1} width="8%">
                            <FormControl>
                              <Select
                                variant="standard"
                                key={row.idtest_step}
                                labelId={`demo-simple-select-label-${row.idtest_step}`}
                                id={`row.idtest_step-${row.idtest_step}`}
                                value={row?.wait || ""}
                                label="Before"
                                onChange={handleBeforeChange(row.idtest_step, row.Test_Case_Id)}
                                disabled={checkModuleEnable === 1 ? true : false}
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                <MenuItem value="Before">Wait Before</MenuItem>
                                <MenuItem value="After">Wait After</MenuItem>
                              </Select>
                            </FormControl>
                          </StyledTableCell>

                          <StyledTableCell align="left" colSpan={1} width="9%">
                            <FormControl>
                              <span>
                                <TextField
                                  variant="standard"
                                  key={row.idtest_step}
                                  labelId={`demo-simple-select-label-${row.idtest_step}`}
                                  id={`row.idtest_step-${row.idtest_step}`}
                                  value={rowWaitTimes[row.idtest_step] ?? row?.waittime}
                                  onChange={(event) => handleInputChange(event, row)}
                                  style={{
                                    paddingLeft: "2px",
                                    marginLeft: "2px",
                                    width: 35,
                                  }}
                                  disabled={checkModuleEnable === 1 ? true : false}
                                />
                                {rowWaitTimes !== "" &&
                                rowWaitTimes !== row?.waittime &&
                                hidingTick === true &&
                                SelectedRowForTime === row ? (
                                  <IconButton
                                    onClick={() => {
                                      waitTimeChange(
                                        row.idtest_step,
                                        row.Test_Case_Id,
                                        rowWaitTimes[row.idtest_step],
                                      );
                                      setHidingTick(false);
                                    }}
                                    disabled={
                                      rowWaitTimes === row?.waittime && rowWaitTimes !== ""
                                        ? true
                                        : false
                                    }
                                  >
                                    <DoneIcon fontSize="small" />
                                  </IconButton>
                                ) : null}
                              </span>
                            </FormControl>
                          </StyledTableCell>

                          <StyledTableCell align="right" colSpan={1} sx={{ padding: 0 }}>
                            {row.Keyword === "URL" && checkModuleEnable !== 1 && (
                              <IconButton
                                color="inherit"
                                aria-label="expand more"
                                component="label"
                                onClick={() => {
                                  setFileTypeCheck();
                                  setTestDataDetails({
                                    ...TestDataDetails,
                                    // SelectEnvironmentName: "",
                                    // SelectEnvironmentUrl: "",
                                    // EnvironmentBrowser: "Chrome",
                                    // Width: "",
                                    // Height: "",
                                    EnvironmentRunonce: "",
                                  });
                                  setRefreshFlag(false);
                                  clearInterval(window.myTimer);
                                  if (SelectedRow !== row) {
                                    setSelectedRow(row);
                                  } else setSelectedRow(null);
                                }}
                              >
                                <ExpandMoreIcon />
                              </IconButton>
                            )}

                            {/* {((row.Keyword === "File Upload") ||
                            (row.Keyword !== "URL" && row.TestData !== "" && index !== 0)) && checkModuleEnable !== 1 && (
                              <IconButton
                                color="inherit"
                                aria-label="expand more"
                                component="label"
                                onClick={() => {
                                  setFileTypeCheck()
                                  handleNameType(row.filename)
                                  handleFromType(row.filename)
                                
                                  setTestDataDetails((prev) => {
                                    const fileNameParts = row.filename ? row.filename.split('~') : ""
                                  
                                    const fileNameParts2 = row.filename ? row.filename.split('~') : ""
                               
                                    return {
                                      ...prev,
                                      AssertionValue: fileNameParts.length > 1 ? fileNameParts[1] : row.filename,
                                      DownloadedFile: fileNameParts2[0],
                                      Source: row.testdata_source,
                                      filefield: row.fileField,
                                      filename: row.filename,
                                      regex: row.regex,
                                    
                                      DBType: row.DBType,
                                      DBpwd: row.DBPwd,
                                      DBQuery: row.DBQuery,
                                      DBURL: row.DBUrl,
                                      DBusername: row.DBUsername,
                                      UploadString: row.TestData,
                                      FileName: row.filename,
                                       
                                      GenerateDataType: row.gendataType,
                                      generateDataLength: row.gendatalen,
                                      ElementType: row.samerowtype,
                                      ElementIndex: row.samerowindex,
                                      useVar: row.TestData,
                                      File: row.FileName,
                                      Target: row.ValDevice,
                                      DependentModule: row.DependentModule,
                                      DependentProduct: row.DependentProduct,
                                      DependentTC: row.DependentTC,
                                      customerEmail: row.customerEmail,
                                      customerPassword: row.customerPassword,
                                      EmailSelectionCriteria: row.EmailSelectionCriteria,
                                      EmailFilter: row.EmailFilter,
                                      apiName: row.apiid,
                                      apiId: row.TestData,
                                      apiParam: row.apiparam,
                                      apiQuery: row.DBQuery,
                                      apiproduct: row?.apiproduct,
                                      apimodule: row?.apimodule,
                                      sheetId: row.sheet,
                                      cell: row.cell,
                                      scope: row.scope === null ? "Single Run" : row.scope,
                                      filter: row.fileField,
                                      endRange: row.endRange
                                    };
                                  });
                                  setRefreshFlag(false);
                                  clearInterval(window.myTimer);
                                  if (SelectedRow !== row) {
                                    setSelectedRow(row);
                                  } else setSelectedRow(null);
                                }}
                              >
                                <ExpandMoreIcon />
                              </IconButton>
                            )} */}
                            <IconButton
                              variant="contained"
                              onClick={(event) => {
                                event.persist();
                                setRowValue(row);
                                // setNewTestStep({
                                //   stepNumber: row.Step_Number,
                                //   Keyword: row.Keyword,
                                //   Action: row.Action,
                                //   Flow: row.Flow,
                                //   Page_Description: row.Page_Description,
                                //   Page_Name: row.Page_Name,
                                //   TestData: row.TestData,
                                //   ObjectIdentifier: row.Object_Xpath
                                // })
                                setRefreshFlag(false);
                                handleClickOpen(state, dataIndex, row);
                                clearInterval(window.myTimer);
                                setStepNumber(row.Step_Number);
                              }}
                            >
                              <AddCircleIcon />
                            </IconButton>
                          </StyledTableCell>
                        </StyledTableRow>
                        {SelectedRow === row && (
                          <StyledTableRow key={row.Action}>
                            <StyledTableCell align="center" colSpan={13} style={{ height: "5rem" }}>
                              {row.Keyword === "URL"
                                ? fromURL(row)
                                : row.Keyword === "Assertion"
                                  ? fromValidate(row)
                                  : Formfield(row)}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {row.Keyword === "URL" ? null : (
                                <IconButton
                                  onClick={(event) => {
                                    setDeleteIconValue(row.idtest_step);
                                    handleClickOpenDeleteAlert();
                                    // deleteTableField(row.idtest_step);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        )}
                      </React.Fragment>
                    );
                  })}
                  {emptyRows > 0 && (
                    <tr style={{ height: 72 * emptyRows }}>
                      <td colSpan={3} aria-hidden />
                    </tr>
                  )}
                </TableBody>
                <TableFooter>
                  <StyledTableRow>
                    <CustomTablePagination
                      style={{ padding: "16px" }}
                      rowsPerPageOptions={[7, 10, 25, 50]}
                      colSpan={14}
                      count={state.xls.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      slotProps={{
                        select: {
                          "aria-label": "rows per page",
                        },
                        actions: {
                          showFirstButton: true,
                          showLastButton: true,
                          slots: {
                            firstPageIcon: FirstPageRoundedIcon,
                            lastPageIcon: LastPageRoundedIcon,
                            nextPageIcon: ChevronRightRoundedIcon,
                            backPageIcon: ChevronLeftRoundedIcon,
                          },
                        },
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </StyledTableRow>
                </TableFooter>
              </Table>
            </div>
          </TableContainer>
        </Box>
      )}
      {openDialog && (
        <div
          style={{
            width: "100%",
            height: "100%",

            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <EditModal
            TestDataDetails={TestDataDetails}
            valueSaved={valueSaved}
            additionalTestCase={additionalTestCase}
            indexValue={indexValue}
            state={state}
            handleClose={handleClose}
            testCaseName={testCaseName}
            envUrl={envUrl}
            test={test}
            stepNumber={stepNumber}
            productId={state.idproducts}
            setTestDataDetails={setTestDataDetails}
            checkModuleEnable={checkModuleEnable}
            SelectEnvironmentName={TestDataDetails.SelectEnvironmentName}
            SelectEnvironmentUrl={TestDataDetails.SelectEnvironmentUrl}
          />
        </div>
      )}
      {openAlert && (
        <Alert
          variant="filled"
          severity="success"
          onClose={handleCloseAlert}
          open={openAlert}
          className="alert"
        >
          The data is successfully saved.
        </Alert>
      )}
      {openCopyAlert && (
        <Alert
          varient="filled"
          severity="success"
          onClose={handleCloseCopyAlert}
          open={openCopyAlert}
          className="alert"
        >
          Copied successfully
        </Alert>
      )}

      {openRenameAlert && (
        <Alert
          varient="filled"
          severity="success"
          onClose={handleCloseRenameAlert}
          open={openRenameAlert}
          className="alert"
        >
          Renamed successfully
        </Alert>
      )}

      {openAlertSave && (
        <Alert
          varient="filled"
          severity="success"
          onClose={handleClickOpenSave}
          open={openAlertSave}
          className="alert"
        >
          Settings changes saved
        </Alert>
      )}

      {openDeleteAlert && (
        <Alert
          variant="filled"
          severity="warning"
          onClose={handleCloseDeleteAlert}
          open={openDeleteAlert}
          className="delete-alert"
        >
          <span className="delete-text">Are you sure you want to delete this step.?</span>
          <div>
            <ColorButton
              className="delete-button-yes"
              onClick={(event) => {
                deleteTableField(deleteIconValue, state.tcs.idtest_case);
                handleCloseDeleteAlert();
                deleteTestcase();
              }}
            >
              Yes
            </ColorButton>
            <ColorButton className="delete-button-no" onClick={handleCloseDeleteAlert}>
              No
            </ColorButton>
          </div>
        </Alert>
      )}

      {openDeletionAlert && (
        <Alert
          variant="filled"
          severity="warning"
          onClose={handleCloseDeletion}
          open={openDeletionAlert}
          className="delete-alert"
        >
          <span className="delete-text">
            Are you sure you want to delete this {state.tcs.Test_Case}?
          </span>
          <div className="button-container">
            <ColorButton
              className="delete-button-yes"
              onClick={() => {
                deleteTestcase(state.tcs.idtest_case);
                handleCloseDeletion();
                setDeleteValue(false);
                setTimeout(() => {
                  getTestCase(state.idmodules);
                }, 500);

                setTcValue(true);
                setRefreshFlag(true);
                window.myTimer = setInterval(() => {
                  getRecording(state);
                }, 5000);
              }}
            >
              Yes
            </ColorButton>
            <ColorButton className="delete-button-no" onClick={handleCloseDeletion}>
              No
            </ColorButton>
          </div>
        </Alert>
      )}

      {openModalDeleteAlert && (
        <Alert
          variant="filled"
          severity="warning"
          onClose={handleCloseModalDeleteAlert}
          open={openModalDeleteAlert}
          className="delete-alert"
        >
          <span className="delete-text">Are you sure you want to delete this step.?</span>
          <div>
            <ColorButton
              className="delete-button-yes"
              onClick={(event) => {
                deleteTableField(deleteIconValueTestStep, deleteIconValueTestCase);
                handleCloseModalDeleteAlert();
              }}
            >
              Yes
            </ColorButton>
            <ColorButton className="delete-button-no" onClick={handleCloseModalDeleteAlert}>
              No
            </ColorButton>
          </div>
        </Alert>
      )}
    </>
  );
};
export default RecordingsTable;

const blue = {
  50: "#F0F7FF",
  200: "#A5D8FF",
  400: "#3399FF",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const CustomTablePagination = styled(TablePagination)(
  ({ theme }) => `
  & .${classes.spacer} {
    display: none;
  }

  & .${classes.toolbar}  {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    padding: 4px 0;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }

  & .${classes.selectLabel} {
    margin: 0;
  }

  & .${classes.select}{
    font-family: 'IBM Plex Sans', sans-serif;
    padding: 2px 0 2px 4px;
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
    border-radius: 6px; 
    background-color: transparent;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    transition: all 100ms ease;

    &:hover {
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
      border-color: ${theme.palette.mode === "dark" ? grey[600] : grey[300]};
    }

    &:focus {
      outline: 3px solid ${theme.palette.mode === "dark" ? blue[400] : blue[200]};
      border-color: ${blue[400]};
    }
  }

  & .${classes.displayedRows} {
    margin: 0;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }

  & .${classes.actions} {
    display: flex;
    gap: 6px;
    border: transparent;
    text-align: center;
  }

  & .${classes.actions} > button {
    display: flex;
    align-items: center;
    padding: 0;
    border: transparent;
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    transition: all 120ms ease;

    > svg {
      font-size: 22px;
    }

    &:hover {
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
      border-color: ${theme.palette.mode === "dark" ? grey[600] : grey[300]};
    }

    &:focus {
      outline: 3px solid ${theme.palette.mode === "dark" ? blue[400] : blue[200]};
      border-color: ${blue[400]};
    }

    &:disabled {
      opacity: 0.3;
      &:hover {
        border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
        background-color: transparent;
      }
    }
  }
  `,
);
