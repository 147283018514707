import React, { useEffect, useState } from "react";
import "./NewCreateCollection.css";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Switch,
  Typography,
  Input,
  Tooltip,
} from "@mui/material";

import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { ColorButton } from "./../common/common.Component";
import {
  ExecuteTestSuites,
  SubmitSchedular,
  browserUpdate,
  getSchedule,
  rerunfailtestUpdate,
  resolutionUpdate,
  resultsEmailupdate,
  BuildTagupdate,
  runstestsinparallelUpdate,
  stopSchedular,
  stoptestafterfailUpdate,
  urlupdate,
  SynchorinizedTests,
  headlessExecutes,
  runssonerror,
  runIndEmail
} from "./../../API/NewCreateCollectionAPI";
import { CommonMethods } from "./../common/CommonMethods";
import { useNavigate } from "react-router-dom";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { colorExport } from "./../common/common.Component";
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { getIntegrations, getStaticIntegrations } from "../../API/adminAPI";
import { API_BASE_PATH, API_BASE_RELATIVE_PATH } from "../../Config/Config";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Alert from '@mui/material/Alert';
function Execute({
  suitesList,
  suiteexecute,
  environments,
  cancelFunc,
  // showStopButton,
  browsers,
  resolutions,
  settingOnOff,
  updatesuite,
  url,
  getLicenceKey,
  statustype,
  seturlidfromexecute,
  editname,
  tsrId,
  product,
  urlid,
  licenceKey,
}) {
  const navigate = useNavigate();
  let type = "Execute";
  const environment = environments;
  let testsuiteemty = settingOnOff;
  if (testsuiteemty) type = "Execution";
  const [selectedtestcase, setselectedtestcase] = useState("");
  const [selectedSuit, changesSelectedSuite] = useState("");
  const [envName, changeEnvName] = useState("");
  const [envUrl, changeEnvurl] = useState("");
  const [SelectBrowser, changeBrowser] = useState("");
  const [inputemail, chaneinputemail] = useState("");
  const [buildtag, changebuildtag] = useState("");  
  const [iterations, setIterations] = useState("");
  const [proxy, setProxy] = useState("");
  const [height, changeHeight] = useState("");
  const [width, changewidht] = useState("");
  const [stoptestafterfail, setStoptestafterfail] = useState(false);
  const [rerunfailtest, changererun] = useState(false);
  const [runstestsinparallel, changeparallel] = useState(false);
  const [suitename, setsuitename] = useState("");
  const [newname, setnewname] = useState("");
  const [renameopen, setrenameopen] = useState(false);
  const [object, setobject] = useState({});
  const [statusTypeValueExecute, setStatusTypeValueExecute] = useState("");
  const [showStopButton, setShowStopButton] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(true);
  const [selectExecuteInterval, setSelelctExecuteInterval] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [convertedFromDate, setConvertedFormDate] = useState(null);
  const [showSelectDay, setShowSelectDay] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [DeleteAlert, setDeleteAlert] = useState(false);
  const [screenShotsFails, setScreenShotFail] = useState(false);
  const [indEmail, setIndEmail] = useState(false);
  const [isSynchronized, setIsSynchronized] = useState(false);
  const [headlessExecute, setHeadlessExecute] = useState(false);

  const handleSynchorinized = async () => {
    const synctoggle = isSynchronized ? 0 : 1;
    setIsSynchronized(!isSynchronized)

    await SynchorinizedTests(selectedSuit, synctoggle, !isSynchronized)
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate)
        } else {

        }
      }).then(() => {
        updatesuite();
      })
      .catch((error) => {
        console.log(error);
      })
  }
  const handleheadlessExecute = async () => {
    const handlelesstoggle = headlessExecute ? 0 : 1;
    setHeadlessExecute(!headlessExecute)
    await headlessExecutes(selectedSuit, handlelesstoggle, !headlessExecute)
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate)
        } else {

        }
      }).then(() => {
        updatesuite();
      })
      .catch((error) => {
        console.log(error);
      })
  }
  // useEffect(() => {
  //   console.log("outsideusestate", suiteexecute);
  //   if (suiteexecute.lenght !== undefined) {
  //     console.log("insideusestate", suiteexecute, suiteexecute.lenght);
  //     // changesSelectedSuite(suiteexecute[0]["idtest_suite"]);
  //     // changeBrowser(suiteexecute[0]["browser"]);
  //   }
  //   // if(suite[0][])
  // }, [suiteexecute]);
  const [showExecuteUrl, setShowExecuteUrl] = useState(false);
  const closeUrl = () => {
    setShowExecuteUrl(false);
  }
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const colors = colorExport()
  useEffect(() => {
    // console.log(selectedtestcase, object);
  }, [object, selectedtestcase]);
  useEffect(() => {
    if (suiteexecute.length !== 0) {
      changesSelectedSuite(suiteexecute[0]["idtest_suite"]);
      setexecute(suiteexecute);
      setnewname("");
      setselectedtestcase(suiteexecute);
    }
  }, [suiteexecute]);

  useEffect(() => {
    console.log(
      "selected for exicute :",
      envName,
      envUrl,
      SelectBrowser,
      height,
      width,
      stoptestafterfail,
      rerunfailtest,
      runstestsinparallel,
      "email :",
      inputemail,
      screenShotsFails,
      indEmail,
      buildtag
    );
  }, [
    envName,
    envUrl,
    SelectBrowser,
    inputemail,
    height,
    width,
    stoptestafterfail,
    rerunfailtest,
    runstestsinparallel,
    screenShotsFails,
    indEmail,
    buildtag
  ]);

  function setexecute(obj) {

    if (obj[0]["browser"] !== null) changeBrowser(obj[0]["browser"]);
    else changeBrowser("");
    // onChangeEnvi(obj[0]["envurl"]);
    if (obj[0]["envurl"] !== null && obj[0]["envurl"] !== "") {
      let envobj = environment.find((otion) => {
        return otion["envurl"] === obj[0]["envurl"];
      });
      changeEnvName(envobj["envname"]);
      changeEnvurl(envobj["envurl"]);
    } else {
      changeEnvName("");
      changeEnvurl("");
    }
    if (obj[0]["height"] !== 0 && obj[0]["width"] !== 0) {
      changeHeight(obj[0]["height"]);
      changewidht(obj[0]["width"]);
    } else {
      changeHeight("");
      changewidht("");
    }
    if (obj[0]["stopafterfailure"] === "true") {
      setStoptestafterfail(true);
    } else {
      setStoptestafterfail(false);
    }
    if (obj[0]["rerunfailedtests"] === "true") {
      changererun(true);
    } else {
      changererun(false);
    }
    if (obj[0]["supports_parallel_execution"] === "true") {
      changeparallel(true);
    } else {
      changeparallel(false);
    }
    if (obj[0]["synctoggle"] === 1) {
      setIsSynchronized(true);
    } else {
      setIsSynchronized(false);
    }
    if (obj[0]["headless"] === true) {
      setHeadlessExecute(true);
    }
    else {
      setHeadlessExecute(false);
    }

    if (obj[0]["ssonerror"] === true) {
      setScreenShotFail(true);
    }
    else {
      setScreenShotFail(false);
    }

    if (obj[0]["indEmail"] === true) {
      setIndEmail(true);
    }
    else {
      setIndEmail(false);
    }

    if (obj[0]["buildtag"] !== null)
      changebuildtag(obj[0]["buildtag"]);

    if (obj[0]["resultsemail"] !== null)
      chaneinputemail(obj[0]["resultsemail"]);
    else chaneinputemail("");
    setnewname("");
    setsuitename(obj[0].Test_Suite);
  }

  function OnchangeSuit(event) {
    // changesSelectedSuite((e) => (e = event.target.value));
    changesSelectedSuite(event.target.value);
    let seleded = suitesList.find((suite) => {
      return suite[0]["idtest_suite"] === event.target.value;
    });
    setselectedtestcase(seleded);
    setexecute(seleded);
    // console.log(event.target.value, "id");
    seturlidfromexecute(event.target.value);
  }

  function onChangeEnvi(event) {
    let obj = environment.find((obj) => {
      return obj["envurl"] === event.target.value;
    });
    changeEnvName(obj["envname"]);
    changeEnvurl(obj["envurl"]);
    if (settingOnOff) updateurl(selectedSuit, event.target.value);
  }
  function OnchangeBrowser(event) {
    changeBrowser(event.target.value);
    if (settingOnOff) updateBrowser(selectedSuit, event.target.value);
  }
  function onchangresolution(event) {
    changeHeight(event.target.value);
    let obj1 = resolutions.find((obj1) => {
      if (obj1["Height"] === event.target.value) {
        changewidht(obj1.Width)
      }
    });
    let obj = resolutions.find((obj) => {
      return obj["Height"] === event.target.value;
    });
    if (settingOnOff)
      updateResolution(selectedSuit, event.target.value, obj["Width"]);
  }
  function onAfter(event) {
    setStoptestafterfail(!stoptestafterfail);
    if (settingOnOff) updatestoptestafterfail(selectedSuit, !stoptestafterfail);
  }
  function onrerun(event) {
    // changesSelectedSuite((e) => (e = event.target.value));
    changererun(!rerunfailtest);
    if (settingOnOff) updatererunfailtest(selectedSuit, !rerunfailtest);
  }
  function onparellel(event) {
    // changesSelectedSuite((e) => (e = event.target.value));
    changeparallel(!runstestsinparallel);
    if (settingOnOff)
      updaterunstestsinparallel(selectedSuit, !runstestsinparallel);
  }

  function handleScreenShotsFailure(event){
    setScreenShotFail(!screenShotsFails);
    if (settingOnOff)
      updatessonerror(selectedSuit, !screenShotsFails);
  }

  function handleIndEmail(event){
    setIndEmail(!indEmail);
    if (settingOnOff)
    updateIndEmail(selectedSuit, !indEmail);
  }

  function changemail(event) {
    chaneinputemail(event.target.value);
    if (settingOnOff) updateResultsEmail(selectedSuit, event.target.value);
  }

  function changetag(event) {
    changebuildtag(event.target.value);
    if (settingOnOff) updateBuildTag(selectedSuit, event.target.value);
  }
  const [state, setState] = useState({
    product: "",
    selectedSuit: "",
  });
  const execute = async () => {
    await ExecuteTestSuites(
      product,
      selectedSuit,
      envName,
      envUrl,
      SelectBrowser,
      stoptestafterfail,
      rerunfailtest,
      runstestsinparallel,
      screenShotsFails,
      indEmail,
      inputemail,
      height,
      width,
      tsrId,
      iterations,
      proxy,
      statusTypeValueExecute === "" ? statustype : statusTypeValueExecute,
      buildtag
    )
      .then((response) => {
        if (response.status === 200 && response.data === "fail") {
          CommonMethods(navigate);
        } else {
          setState({
            product: product,
            selectedSuit: selectedSuit,
          });
          navigate("/dashboard/app", { state: { isExecuteClick: true, selectedSuit: selectedSuit, product: product } });
        }

      })
      .catch((error) => {
        console.log(error);
      });
  };
  const updateurl = async (id, url) => {
    await urlupdate(id, url)
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate);
        } else {
        }
      })
      .then(() => {
        updatesuite();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const updateBrowser = async (id, url) => {
    await browserUpdate(id, url)
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
        } else {
        }
      })
      .then(() => {
        updatesuite();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const updateResolution = async (id, height, width) => {
    await resolutionUpdate(id, height, width)
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate);
        } else {
        }
      })
      .then(() => {
        updatesuite();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const updatestoptestafterfail = async (id, stoptestafterfail) => {
    await stoptestafterfailUpdate(id, stoptestafterfail)
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate);
        } else {
        }
      })
      .then(() => {
        updatesuite();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const updatererunfailtest = async (id, rerunfailtest) => {
    await rerunfailtestUpdate(id, rerunfailtest)
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate);
        } else {
        }
      })
      .then(() => {
        updatesuite();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const updaterunstestsinparallel = async (id, runstestsinparallel) => {
    await runstestsinparallelUpdate(id, runstestsinparallel)
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate);
        } else {
        }
      })
      .then(() => {
        updatesuite();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updatessonerror = async (id, screenShotsFails) => {
    await runssonerror(id, screenShotsFails)
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate);
        } else {
        }
      })
      .then(() => {
        updatesuite();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateIndEmail = async (id, indEmail) => {
    await runIndEmail(id, indEmail)
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate);
        } else {
        }
      })
      .then(() => {
        updatesuite();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateResultsEmail = async (id, newmail) => {
    await resultsEmailupdate(id, newmail)
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate);
        } else {
        }
      })
      .then(() => {
        updatesuite();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateBuildTag = async (id, buildtag) => {
    await BuildTagupdate(id, buildtag)
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate);
        } else {
        }
      })
      .then(() => {
        updatesuite();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function cancel() {
    cancelFunc();
    // changeBrowser("");
    // changeEnvName("");
    // changeEnvurl("");
    // changeHeight("");
    // changewidht("");
    // changesSelectedSuite("");
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const days = [
    'All Day',
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday ',
    'Saturday ',
  ];
  const [dayName, setDayName] = useState([]);
  const handleChange = (event) => {
    const { value } = event.target;
    setDayName(
      typeof value === 'string' ? value.split(',') : value,
    );
  }
  const handleSubmitSchedular = () => {
    setShowStopButton(true);
    setShowSubmitButton(false);
  }
  const handleStopSchedular = () => {
    setShowStopButton(false);
    setShowSubmitButton(true);
  };
  // const currentDate = new Date();
  const getMonthNumber = (monthName) => {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    return String(months.indexOf(monthName) + 1).padStart(2, '0');
  };
  const convertDate = (inputDate) => {
    if (!inputDate) {
      return '';
    }
    const dateString = inputDate.toString();
    const dateParts = dateString.split(' ');
    const day = dateParts[2];
    const month = getMonthNumber(dateParts[1]);
    const year = dateParts[3];
    const time = dateParts[4];
    const formatedDate = `${year}-${month}-${day}`;
    return formatedDate;
  }
  useEffect(() => {
    if (fromDate !== null) {
      setConvertedFormDate(convertDate(fromDate.$d));
    }
  }, [fromDate])
  const [time, setTime] = useState(null);
  const [convertedTime, setConvertedTime] = useState(null);
  const convertTime = (inputTime) => {
    if (!inputTime) {
      return '';
    }
    const dateString = inputTime.toString();
    const dateParts = dateString.split(' ');
    // const day = dateParts[2];
    // const month = getMonthNumber(dateParts[1]);
    // const year = dateParts[3];
    const time = dateParts[4];
    const formatedDate = `${time}.0`;
    return formatedDate;
  }
  useEffect(() => {
    if (time !== null) {
      setConvertedTime(convertTime(time.$d));
    }
  }, [time])
  const onSubmitSchedular = async () => {
    const filteredDayName = dayName.filter((day) => day !== null);
    await SubmitSchedular(selectedSuit, convertedFromDate, convertedTime, selectExecuteInterval, filteredDayName)
      .then((response) => {
        if (response.data.status === 20 && response.data.message === "fali") {
          CommonMethods(navigate);
        } else {
          setOpenAlert(true);
          // console.log(response);
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  useEffect(() => {
    getSchedular();
  }, [selectedSuit])
  const [scheduleData, setScheduleData] = useState(null);
  const getSchedular = async () => {
    await getSchedule(selectedSuit)
      .then((response) => {
        if (response.data.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate);
        } else {
          // ////////
          // const jsonData = JSON.parse(response.data);
          // console.log("schedular data", jsonData)
          // //////
          setSelelctExecuteInterval(response.data.frequency);
          setDayName([response.data.subfreq]);
          function convertTimeToDateString() {
            const currentDate = new Date();
            let timedata = response.data.scheduletime;
            const [hours, minutes, seconds] = timedata.split(':').map(Number);
            currentDate.setHours(hours);
            currentDate.setMinutes(minutes);
            currentDate.setSeconds(seconds);
            const dateString = currentDate.toString();
            return dateString;
          }
          if (response.data.scheduletime) {
            setTime(convertTimeToDateString(response.data.scheduletime));
          }
          // setTime(convertTimeToDateString(response.data.scheduletime))
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }
  const handleStopsSchedular = async () => {
    await stopSchedular(selectedSuit)
      .then((response) => {
        if (response.data.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate);
        }
        else {
          setSelelctExecuteInterval("");
          setDayName([]);
          setFromDate(null);
          setTime(null);
          setDeleteAlert(true);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  const handleCloseDeleteAlert = () => {
    setDeleteAlert(false);
  }
  // integration part
  const [integrationDatas, setIntegratonData] = useState([]);
  const [selectedIntegration, setSelectedIntegration] = useState([]);
  const AdminIntegration = async () => {
    await getStaticIntegrations()
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate)
        } else {
          setSelectedIntegration(response.data)
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }
  useEffect(() => {
    AdminIntegration();
  }, [])
  const [selectIntegration, setSelectIntegration] = useState(null);
  const [intName, setIntName] = useState(null);
  const handleIntegrationChange = (event) => {
    const integrationId = event.target.value;
    setSelectIntegration(integrationId);
    const name = selectedIntegration.map((item, index) => (
      item.staticintid === event.target.value ? item.intname : null
    ))
    const nameNew = name.find((value) => value !== null);
    setIntName(nameNew);
    setIntegratonData("");
  };
  // const [urlExtension, setUrlExtension] = useState()
  let urlExtension;
  let valueParam;
  let textvalParam;
  const renderTextFields = () => {
    if (!selectIntegration) return null;

    let selectedIntegrationData = selectedIntegration.find(
      (item) => item.staticintid === selectIntegration
    );
    if (!selectedIntegrationData) return null;
    const adminAttributesArray = selectedIntegrationData.user_attributes ? selectedIntegrationData.user_attributes.split(',') : []
    const valueTextField = integrationDatas.userprop ? integrationDatas.userprop.split(',') : [];
    valueParam = adminAttributesArray;
    textvalParam = valueTextField
    urlExtension = (`& values=${valueParam} & textValues=${textvalParam}`)
    return adminAttributesArray.map((attribute, index) => (
      <div key={index} className="input-group">
        <TextField
          label={attribute}
          id="outlined-size-small"
          value={valueTextField[index] || ""}
          onChange={(event) => {
            event.preventDefault();
            const updatedValueTextField = [...valueTextField];
            updatedValueTextField[index] = event.target.value;
            setIntegratonData((prevIntegrationDatas) => ({
              ...prevIntegrationDatas,
              userprop: updatedValueTextField.join(','),
            }));
          }}
          style={{
            width: "175px",
            marginLeft: index === 0 ? "" : "27px",
          }}
        />
      </div>
    ));
  };
  //integration part end 
  return (
    <div className="exicutepage">
      <span style={{ display: "flex", justifyContent: "flex-start" }} >
        {/* <span className="head">
          <Typography
            paddingBottom={0}
            variant="h6"
            component="h2"
            style={{ color: colors.primaryColor, backgroundColor: colors.secondaryColor }}
          >
            {type}
          </Typography>
        </span> */}
        <span>
          <span className="head">
            <Typography
              paddingBottom={0}
              variant="h6"
              component="h2"
              style={{
                color: colors.primaryColor,
                backgroundColor: colors.secondaryColor,
                marginLeft: "10px",
                borderRadius: "5px",
              }}
            >
              {type}
            </Typography>
          </span>
          <div className="leftright">
            <div className="left">
              <FormControl style={{ marginTop: "18px" }}>
                <InputLabel style={{ marginLeft: "19.5px", marginTop: "-12px" }}>
                  Suite
                </InputLabel>
                <Select
                  onChange={OnchangeSuit}
                  value={selectedSuit}
                  className="Dropdown"
                >

                  {suitesList.map((suite, index) => (
                    <MenuItem key={index} value={suite[0]["idtest_suite"]}>
                      {suite[0]["Test_Suite"]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl style={{ marginTop: "18px" }}>
                <InputLabel style={{ marginLeft: "19.5px", marginTop: "-12px", fontWeight: "400" }}>
                  Environment
                </InputLabel>
                <Select onChange={onChangeEnvi} value={envUrl} className="Dropdown">
                  {environment.map((env, index) => (
                    <MenuItem key={index} value={env["envurl"]}>
                      {env["envname"]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl style={{ marginTop: "18px" }}>
                <InputLabel style={{ marginLeft: "35px", marginTop: "-12px" }}>
                  Browser
                </InputLabel>
                <Select
                  onChange={OnchangeBrowser}
                  value={SelectBrowser}
                  className="Dropdown"
                >
                  {browsers.map((browser, index) => (
                    <MenuItem key={index} value={browser}>
                      {browser}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl style={{ marginTop: "18px" }}>
                <InputLabel style={{ marginLeft: "30px", marginTop: "-12px" }}>
                  resolution
                </InputLabel>
                <Select
                  onChange={onchangresolution}
                  value={height}
                  className="Dropdown"
                >
                  {resolutions.map((resolution, index) => (
                    <MenuItem key={index} value={resolution["Height"]}>
                      {resolution["Height"]} x {resolution["Width"]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl style={{ marginTop: "18px" }}>
                <Input
                  placeholder="Iterations"
                  value={iterations}
                  onChange={(event) => {
                    setIterations(event.target.value);
                  }}
                  style={{ marginLeft: "2px", marginTop: "-12px" }}
                ></Input>
              </FormControl>
            </div>
            <div className="right">
              <FormGroup className="switch">
                <FormControlLabel
                  control={
                    <Switch checked={stoptestafterfail} onChange={onAfter} />
                  }
                  label="Stop test after fail"
                />
                <FormControlLabel
                  control={<Switch checked={rerunfailtest} onChange={onrerun} />}
                  label="Rerun fail test"
                />
                <FormControlLabel
                  control={
                    <Switch checked={runstestsinparallel} onChange={onparellel} />
                  }
                  label="Runs Tests in Parallel"
                />
                <FormControlLabel
                  control={
                    <Switch checked={isSynchronized} onChange={handleSynchorinized} />
                  }
                  label="Synchronized Tests"
                />
                <FormControlLabel
                  control={
                    <Switch checked={headlessExecute} onChange={handleheadlessExecute} />
                  }
                  label="Headless Execution"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={screenShotsFails}
                      onChange={handleScreenShotsFailure}
                    />
                  }
                  label={
                    <div>
                      ScreenShots on Failure
                    </div>
                  }
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={indEmail}
                      onChange={handleIndEmail}
                    />
                  }
                  label={
                    <div>
                      Send Individual Result Email
                    </div>
                  }
                />
              </FormGroup>

              <FormControl>
                {/* {console.log("statustype", statustype)}
            {console.log("statusTypeValueExecute", statusTypeValueExecute)} */}
                {statustype !== undefined ? <Select
                  variant="standard"
                  defaultValue={statusTypeValueExecute === "" ? statustype : statusTypeValueExecute}
                  onChange={(event) => {
                    // console.log("Dikh raha hai", event.target.value)
                    setStatusTypeValueExecute(event.target.value)
                  }
                  }
                >
                  <MenuItem value="ALL">All</MenuItem>
                  <MenuItem value="FAIL">Only Fail</MenuItem>
                  <MenuItem value="STARTED">In-Complete</MenuItem>
                  <MenuItem value="BOTH">Both Failed and InComplete</MenuItem>
                </Select> : null}
              </FormControl>
            </div>

          </div>
          <div style={{ display: "flex", justifyContent: "flex-start", marginLeft: "25px", marginTop: "12px" }} >
            <Input
              placeholder="Proxy"
              value={proxy}
              onChange={(event) => {
                setProxy(event.target.value);
              }}
              style={{ marginLeft: "2px", marginTop: "-12px", marginRight: '16px', width: "400px" }}
            ></Input>
            <Input
              placeholder="Build Tag"
              className="buildtag"
              value={buildtag}
              onChange={changetag}
            ></Input>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-start", marginLeft: "25px" }} >
            <Input
              placeholder="Results Email"
              className="emailInput"
              value={inputemail}
              onChange={changemail}
              style={{ marginRight: '16px', minWidth: '400px' }} // Adjust for 50 characters
            ></Input>
            
            <div hidden={!settingOnOff}>
              <div hidden={renameopen} style={{ marginLeft: "45px" }}>
                <Tooltip title={"Rename the suite"}>
                  <IconButton
                    onClick={() => {
                      setrenameopen(!renameopen);
                    }}
                  >
                    <DriveFileRenameOutlineIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <div hidden={!renameopen}>
                <Input
                  placeholder={suitename}
                  value={newname}
                  onChange={(e) => setnewname(e.target.value)}
                  style={{ width: "140px", marginLeft: "65px" }}
                />
                <IconButton
                  disabled={newname !== "" ? false : true}
                  onClick={() => {
                    editname(newname, selectedSuit);
                    setrenameopen(!renameopen);
                  }}
                >
                  <DoneIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setrenameopen(!renameopen);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          </div>
          <span>
            <div className="execute_button">
              <ColorButton
                size="small"
                variant="contained"
                sx={{
                  margin: "10px 20px 2px 20px",
                  width: "45%"
                }}
                onClick={(e) => {
                  e.preventDefault()
                  execute()
                  cancel()
                }}
              >
                Execute
              </ColorButton>
            </div>
          </span>
        </span>
        <span>
          <div>
            <span className="schedular">
              <Typography
                paddingBottom={0}
                variant="h6"
                component="h2"
                style={{
                  color: colors.primaryColor,
                  backgroundColor: colors.secondaryColor,
                  height: 32,
                  marginLeft: 10,
                  marginRight: 10,
                  borderRadius: 5,
                }}
              >
                Integration
              </Typography>
            </span>
          </div>
          <div style={{
            display: 'flex',
            justifyContent: "flex-start",
            marginLeft: "30px",
            // marginTop: "15px",
          }} >
            <FormControl style={{ marginTop: "18px" }} >
              <InputLabel style={{ marginLeft: "19.5px", marginTop: "-12px" }}>Integrations</InputLabel>
              <Select
                label="Select"
                value={selectIntegration || ''}
                onChange={handleIntegrationChange}
                className="Dropdown"
              >
                {selectedIntegration.map((item, index) => (
                  <MenuItem key={index} value={item.staticintid}>
                    {item.intname}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <ColorButton
              size="small"
              variant="contained"
              sx={{
                margin: "10px 20px 2px 20px",
                marginLeft: "27px",
                width: "48%",
              }}
              onClick={() => {
                // url();
                setShowExecuteUrl(true);
                getLicenceKey();
              }}
              className="execute_button"
            >
              Suite Execute Url
            </ColorButton>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: "flex-start",
              marginLeft: "30px",
              marginTop: "15px",
            }}
          >
            {renderTextFields()}
          </div>
          <div>
            <span className="schedular">
              <Typography
                paddingBottom={0}
                variant="h6"
                component="h2"
                style={{
                  color: colors.primaryColor,
                  backgroundColor: colors.secondaryColor,
                  height: 32,
                  marginLeft: 10,
                  marginRight: 10,
                  borderRadius: 5,
                }}
              >
                Scheduler
              </Typography>
            </span>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginLeft: "30px",
                // marginRight:"15px"

              }}
            >
              <FormControl style={{ marginTop: "18px" }} >
                <InputLabel style={{ marginLeft: "19.5px", marginTop: "-12px" }}>Select</InputLabel>
                <Select
                  // labelId="demo-select-small-label"
                  // id="demo-select-small"
                  value={selectExecuteInterval}
                  label="Select"
                  className="Dropdown"
                  onChange={(e) => setSelelctExecuteInterval(e.target.value)}
                >
                  <MenuItem value='daily' >Daily</MenuItem>
                  <MenuItem value='weekly' >Weekly</MenuItem>
                  <MenuItem value='other' >Other</MenuItem>
                </Select>
              </FormControl>
              {selectExecuteInterval === "weekly" && (
                <FormControl style={{ marginTop: "18px", marginRight: "30px", marginLeft: "10px" }}>
                  <InputLabel id="demo-multiple-checkbox-label" style={{ marginLeft: "19.5px", marginTop: "-12px" }}>Select Day</InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={dayName}
                    className="Dropdown"
                    onChange={handleChange}
                    input={<OutlinedInput label="Tag" />}
                    // renderValue={(selected) => selected.join(', ')}
                    renderValue={(selected) =>
                      selected.length === 0
                        ? 'Select Day'
                        : selected.join(' ')
                    }
                    MenuProps={MenuProps}
                  >
                    {days.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={dayName.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </div>
            <div style={{ display: 'flex', marginLeft: "30px" }} >
              {selectExecuteInterval === 'weekly' || selectExecuteInterval === 'daily' || selectExecuteInterval === 'other' ? (
                <FormControl style={{ width: 210 }} >
                  <LocalizationProvider dateAdapter={AdapterDayjs}  >
                    <DemoContainer components={['TimePicker', 'TimePicker']}>
                      <TimePicker
                        label="Time"
                        // disablePast={true}
                        value={dayjs(time)}
                        onChange={(time) => {
                          setTime(time);
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </FormControl>
              ) : null}
              {selectExecuteInterval === 'other' ? (
                <FormControl sx={{ width: 210 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      <DatePicker
                        className="date-picker"
                        label="Date"
                        disablePast={true}
                        value={fromDate}
                        onChange={(date) => {
                          setFromDate(date);
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </FormControl>
              ) : null}
              {/* <div>
            <ColorButton
              size="small"
              variant="contained"
              sx={{ margin: "20px 10px 2px 0px" }}
              // className="executebutton"
              // style={{
              //   width: '50%',
              //   marginRight: '50px',
              //   height: '40px',
              //   marginTop: "20px"
              // }}
              onClick={() => {
                handleSubmitSchedular();
                onSubmitSchedular(convertedFromDate, convertedTime);
              }}
            >
              Submit
            </ColorButton>
          </div>
          <div>
            <ColorButton
              size="small"
              variant="contained"
              sx={{ margin: "20px 10px 2px 10px" }}
              // className="executebutton"
              // style={{
              //   width: '50%',
              //   marginRight: '50px',
              //   height: '40px',
              //   marginTop: '20px',
              // }}
              onClick={() => {
                handleStopSchedular();
                handleStopsSchedular();
              }}
            >
              Delete
            </ColorButton>
          </div> */}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }} >
              <div>
                <ColorButton
                  size="small"
                  variant="contained"
                  sx={{ margin: "20px 10px 2px 0px" }}
                  // className="executebutton"
                  // style={{
                  //   width: '50%',
                  //   marginRight: '50px',
                  //   height: '40px',
                  //   marginTop: "20px"
                  // }}
                  onClick={() => {
                    handleSubmitSchedular();
                    onSubmitSchedular(convertedFromDate, convertedTime);
                  }}
                >
                  Submit
                </ColorButton>
              </div>
              <div>
                <ColorButton
                  size="small"
                  variant="contained"
                  sx={{ margin: "20px 10px 2px 10px" }}
                  // className="executebutton"
                  // style={{
                  //   width: '50%',
                  //   marginRight: '50px',
                  //   height: '40px',
                  //   marginTop: '20px',
                  // }}
                  onClick={() => {
                    handleStopSchedular();
                    handleStopsSchedular();
                  }}
                >
                  Delete
                </ColorButton>
              </div>
            </div>
          </div>
        </span>
      </span>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      > */}
      <ColorButton
        size="small"
        variant="contained"
        sx={{
          margin: "10px 20px 10px 20px",
          width: "27%",
          marginLeft: "20rem",
        }}
        onClick={cancel}
      // className="executebutton"
      >
        cancel
      </ColorButton>
      {/* </div> */}
      {showExecuteUrl && (
        <div className="geturl">
          {(() => {
            if (intName) {
              let selectintType = selectedIntegration.map((item, index) => (
                item.intname === intName ? item.staticintid : null
              ));
              let intTypes = selectintType.find((value) => value !== null);
              let url = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=DLExecuteSuite&companyid=${temp.companyid}&token=${licenceKey}&prodid=${product}&SuiteId=${urlid}&inttype=${intTypes}`
              for (let i = 0; i < valueParam.length; i++) {
                url += `&${valueParam[i]}=${textvalParam[i]}`;
              }
              return (
                <>
                  <h4>{url}</h4>
                  <IconButton onClick={closeUrl}>
                    <CloseIcon />
                  </IconButton>
                </>
              );
            } else {
              let url = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=DLExecuteSuite&companyid=${temp.companyid}&token=${licenceKey}&prodid=${product}&SuiteId=${urlid}`
              return (
                <>
                  <h4>{url}</h4>
                  <IconButton onClick={closeUrl}>
                    <CloseIcon />
                  </IconButton>
                </>
              );
            }
          }
          )()}

        </div>
      )}
      {openAlert && (
        <Alert
          variant="filled"
          severity="success"
          onClose={handleCloseAlert}
          open={openAlert}
          className="alert"
        >
          Scheduler is Successfully Saved.
        </Alert>
      )}
      {DeleteAlert && (
        <Alert
          variant="filled"
          severity="success"
          onClose={handleCloseDeleteAlert}
          open={DeleteAlert}
          className="alert"
        >
          Scheduler is Successfully Deleted.
        </Alert>
      )}
    </div>
  );
}

export default Execute;