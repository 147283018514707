export const getActionOptions = (type) => {
  switch (type) {
    case "Script":
      return [{ label: "Javascript", value: "Javascript" }];
    case "Assertion":
    case "Validation":
      return [
        { label: "Assert Text", value: "validatePartial" },
        { label: "Properties", value: "Properties" },
        { value: "DynamicText", label: "Dynamic Text" },
      ];
    case "Button":
      return [
        { label: "Click", value: "Click" },
        { label: "Double Click", value: "DblClick" },
      ];
    case "Alert":
      return [{ label: "Alert OK", value: "AlertOK" }];
    case "Check Box":
      return [{ label: "Checkbox", value: "Checkbox" }];
    case "Clipboard":
    case "Url":
    case "URL":
      return [{ label: "Get", value: "Get" }];
    case "DropDown":
      return [{ label: "Select", value: "Select" }];
    case "Link":
    case "SVGElement":
    case "Radio Button":
      return [
        { label: "Click", value: "Click" },
        { label: "Double Click", value: "dblClick" },
      ];
    case "File Upload":
      return [
        { label: "File Upload", value: "FileUpload" },
        { value: "Upload", label: "Upload" },
      ];
    case "Inline":
      return [{ label: "Inline", value: "Inline" }];
    case "File":
      return [{ label: "Download", value: "Download" }];
    case "TextArea":
      return [
        { label: "Enter", value: "Enter" },
        { label: "Click", value: "Click" },
      ];
    case "TestCase":
      return [{ label: "Include", value: "Include" }];
    case "Send":
      return [{ label: "Email", value: "Email" }];
    case "Window":
      return [{ label: "New Tab", value: "NewTab" }];
    case "EditBox":
      return [
        { label: "Enter", value: "Enter" },
        { label: "Click", value: "Click" },
        { label: "Clear", value: "Clear" },
      ];
    case "mobile":
      return [
        { label: "Click", value: "click" },
        { label: "Swipe", value: "Swipe" },
        { label: "Find And Assign", value: "findAndAssign" },
        { label: "Tap", value: "tap" },
        { label: "Send Keys", value: "sendKeys" },
      ];
    default:
      return [{ label: "Click", value: "Click" }];
  }
};
