import React, { useState, memo } from "react";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { API_BACKEND_URL } from "../../../Config/Config";
import { toast } from "react-toastify";

const EditableField = memo(({ value, onChange, multiline, type = "text" }) => {
  const Component = multiline ? "textarea" : "input";

  return (
    <Component
      type={type}
      value={value || ""}
      onChange={(e) => onChange(e.target.value)}
      className="w-full p-1 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
      rows={multiline ? 3 : undefined}
    />
  );
});

const TableRow = memo(
  ({
    testCase,
    isSelected,
    onSelect,
    isGlobalEdit,
    editedData,
    onFieldChange,
    getTestResult,
    getMethodColor,
    formatJSONDisplay,
  }) => {
    const result = getTestResult(testCase);

    return (
      <tr className="hover:bg-gray-50">
        <td className="w-12 px-2 py-3">
          <div className="flex items-center justify-center">
            <input
              type="checkbox"
              checked={isSelected}
              onChange={() => onSelect(testCase)}
              className="h-4 w-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
            />
          </div>
        </td>
        <td className="w-12 px-2 py-3 text-sm text-postman-gray-dark">{testCase.id}</td>
        <td className="w-48 px-3 py-3">
          {isGlobalEdit ? (
            <EditableField
              value={editedData?.endpoint || testCase.endpoint}
              onChange={(value) => onFieldChange(testCase.id, "endpoint", value)}
              multiline={true}
            />
          ) : (
            <div className="text-sm text-postman-gray-dark">{testCase.endpoint}</div>
          )}
        </td>
        <td className="w-48 px-3 py-3">
          {isGlobalEdit ? (
            <EditableField
              value={editedData?.testCase || testCase.testCase}
              onChange={(value) => onFieldChange(testCase.id, "testCase", value)}
              multiline={true}
            />
          ) : (
            <div className="text-sm text-postman-gray-dark">{testCase.testCase}</div>
          )}
        </td>
        <td className="w-24 px-3 py-3">
          {isGlobalEdit ? (
            <select
              value={editedData?.httpMethod || testCase.httpMethod}
              onChange={(e) => onFieldChange(testCase.id, "httpMethod", e.target.value)}
              className="w-full p-1 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="GET">GET</option>
              <option value="POST">POST</option>
              <option value="PUT">PUT</option>
              <option value="DELETE">DELETE</option>
            </select>
          ) : (
            <div className="text-sm text-postman-gray-dark">
              <span
                className={`px-2 py-1 rounded text-xs font-medium ${getMethodColor(testCase.httpMethod)}`}
              >
                {testCase.httpMethod}
              </span>
            </div>
          )}
        </td>
        <td className="w-24 px-3 py-3 text-sm text-postman-gray-dark">
          {testCase.expectedStatusCode}
        </td>
        <td className="w-48 px-3 py-3">
          {isGlobalEdit ? (
            <EditableField
              value={editedData?.expectedResult || formatJSONDisplay(testCase.expectedResult)}
              onChange={(value) => onFieldChange(testCase.id, "expectedResult", value)}
              multiline={true}
            />
          ) : (
            <div className="text-sm text-postman-gray-dark">
              {formatJSONDisplay(testCase.expectedResult)}
            </div>
          )}
        </td>
        <td className="w-24 px-3 py-3 text-sm text-postman-gray-dark">
          {" "}
          <span
            className={`px-2 py-1 rounded text-xs font-medium ${testCase?.testType?.toLowerCase() === "positive"
              ? "bg-green-100 text-postman-green"
              : "bg-red-100 text-postman-red"
              }`}
          >
            {testCase?.testType || "Not specified"}
          </span>
        </td>
        <td className="w-24 py-3 text-sm text-postman-gray-dark">
          <td className="w-24 py-3">
            {result ? (
              <span
                className={`inline-flex items-center gap-1 px-2 py-1 rounded text-xs font-medium ${result.success ? "bg-green-100 text-postman-green" : "bg-red-100 text-postman-red"
                  }`}
              >
                {result.success ? (
                  <CheckCircleIcon className="h-4 w-4" />
                ) : (
                  <XCircleIcon className="h-4 w-4" />
                )}
                {result.success ? "PASS" : "FAIL"}
              </span>
            ) : (
              <span className="px-2 py-1 rounded text-xs font-medium border border-gray-300 text-postman-gray-medium">
                NOT RUN
              </span>
            )}
          </td>
        </td>
        <td className="w-36 px-3 py-3">
          {isGlobalEdit ? (
            <EditableField
              value={editedData?.body || formatJSONDisplay(testCase.body)}
              onChange={(value) => onFieldChange(testCase.id, "body", value)}
              multiline={true}
            />
          ) : (
            <div className="text-sm text-postman-gray-dark">{formatJSONDisplay(testCase.body)}</div>
          )}
        </td>
        <td className="w-36 px-3 py-3">
          {isGlobalEdit ? (
            <EditableField
              value={editedData?.queryParams || formatJSONDisplay(testCase.queryParams)}
              onChange={(value) => onFieldChange(testCase.id, "queryParams", value)}
              multiline={true}
            />
          ) : (
            <div className="text-sm text-postman-gray-dark">
              {formatJSONDisplay(testCase.queryParams)}
            </div>
          )}
        </td>
      </tr>
    );
  },
);

const TestCasesTable = ({
  testCases: initialTestCases,
  testResults,
  selectedTestCases = [],
  onTestCaseSelect,
  onMarkAsReviewed,
  onSaveTestCase,
}) => {
  const [filters, setFilters] = useState({
    method: "",
  });
  const [sortBy, setSortBy] = useState("");
  const [isGlobalEdit, setIsGlobalEdit] = useState(false);
  const [editedTestCases, setEditedTestCases] = useState({});

  const parseTestCases = (data) => {
    try {
      if (typeof data === "string") {
        return JSON.parse(data);
      }
      return Array.isArray(data) ? data : [];
    } catch (error) {
      console.error("Error parsing test cases:", error);
      return [];
    }
  };

  const testCases = parseTestCases(initialTestCases)
    .filter((testCase) => {
      return !filters.method || testCase.httpMethod === filters.method;
    })
    .sort((a, b) => {
      if (sortBy === "createdAt") {
        return new Date(a.createdAt) - new Date(b.createdAt);
      } else if (sortBy === "modifiedAt") {
        return new Date(a.modifiedAt) - new Date(b.modifiedAt);
      } else if (a.httpMethod === "GET" && b.httpMethod !== "GET") {
        return -1;
      } else if (a.httpMethod !== "GET" && b.httpMethod === "GET") {
        return 1;
      }
      return 0;
    });

  const getTestResult = (testCase) => {
    if (!testResults) return null;
    return testResults.find((result) => result.testCase === testCase.testCase);
  };

  const handleCheckboxChange = (testCase) => {
    if (onTestCaseSelect) {
      onTestCaseSelect(testCase);
    }
  };

  const handleFieldChange = (testCaseId, field, value) => {
    setEditedTestCases((prev) => ({
      ...prev,
      [testCaseId]: {
        ...prev[testCaseId],
        [field]: value,
      },
    }));
  };

  const handleSaveAll = async () => {
    const editedEntries = Object.entries(editedTestCases).filter(([testCaseId, data]) => {
      const originalTestCase = testCases.find((tc) => tc.id.toString() === testCaseId.toString());
      if (!originalTestCase) return false;

      return Object.entries(data).some(([key, value]) => {
        if (key === "body" || key === "queryParams") {
          const originalValue = formatJSONDisplay(originalTestCase[key]);
          return value !== originalValue;
        }
        return originalTestCase[key]?.toString() !== value?.toString();
      });
    });

    if (editedEntries.length === 0) {
      console.log("No changes detected");
      setIsGlobalEdit(false);
      setEditedTestCases({});
      return;
    }
    console.log("Saving edited test cases:", editedEntries);
    try {
      await axios.put(`${API_BACKEND_URL}/testcase/update`, editedEntries);

      toast.success("Test case updated successfully");

    } catch (error) {
      console.error('Error updating test case:', error);
    }
    // editedEntries.forEach(([testCaseId, data]) => {
    //   if (onSaveTestCase) {
    //     onSaveTestCase(testCaseId, data);
    //   }
    // });

    setIsGlobalEdit(false);
    setEditedTestCases({});
  };

  const handleCancelClick = () => {
    setIsGlobalEdit(false);
    setEditedTestCases({});
  };

  const formatJSONDisplay = (data) => {
    if (!data) return "Not specified";
    try {
      return typeof data === "string" ? data : JSON.stringify(data, null, 2);
    } catch {
      return String(data);
    }
  };

  const getMethodColor = (method) => {
    switch (method) {
      case "GET":
        return "text-postman-green bg-green-100";
      case "POST":
        return "text-yellow-600 bg-yellow-100";
      case "PUT":
        return "text-postman-blue bg-blue-100";
      case "DELETE":
        return "text-postman-red bg-red-100";
      default:
        return "text-postman-gray-medium bg-gray-100";
    }
  };
  if (!testCases.length) {
    return (
      <div className="p-4">
        <div className="bg-red-100 text-postman-red px-4 py-3 rounded-lg">
          No test cases data available or invalid data format
        </div>
      </div>
    );
  }
  return (
    <div className=" pt-2 w-full">
      {/* Controls Row */}
      <div className="mb-4 flex justify-between items-center">
        <div className="flex gap-4">
          <select
            value={filters.method}
            onChange={(e) => setFilters({ ...filters, method: e.target.value })}
            className="p-2 border border-gray-300 rounded bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">Filter by Method</option>
            <option value="GET">GET</option>
            <option value="POST">POST</option>
            <option value="PUT">PUT</option>
            <option value="DELETE">DELETE</option>
          </select>

          <select
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
            className="p-2 border border-gray-300 rounded bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">Sort By</option>
            <option value="createdAt">Created At</option>
            <option value="modifiedAt">Modified At</option>
          </select>
        </div>

        <div className="flex gap-4">
          {selectedTestCases.length > 0 && (
            <button
              onClick={() => onMarkAsReviewed(selectedTestCases)}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
            >
              Mark {selectedTestCases.length} as Reviewed
            </button>
          )}

          {isGlobalEdit ? (
            <>
              <button
                onClick={handleCancelClick}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition-colors cursor-pointer"
              >
                Cancel
              </button>
              <button
                onClick={handleSaveAll}
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition-colors cursor-pointer"
              >
                Save All Changes
              </button>
            </>
          ) : (
            <button
              onClick={() => setIsGlobalEdit(true)}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors cursor-pointer"
            >
              Edit All Test Cases
            </button>
          )}
        </div>
      </div>

      {/* Table */}
      <div className="overflow-x-auto border border-gray-200 rounded-lg shadow">
        <div className="inline-block min-w-full align-middle">
          <div className="overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-postman-gray-light">
                <tr>
                  <th className="w-12 px-2 py-3 text-left text-sm font-medium text-postman-gray-dark"></th>
                  <th className="w-12 px-2 py-3 text-left text-sm font-medium text-postman-gray-dark">
                    ID
                  </th>
                  <th className="w-48 px-3 py-3 text-left text-sm font-medium text-postman-gray-dark">
                    Endpoint
                  </th>
                  <th className="w-48 px-3 py-3 text-left text-sm font-medium text-postman-gray-dark">
                    Test Case
                  </th>
                  <th className="w-24 px-3 py-3 text-left text-sm font-medium text-postman-gray-dark">
                    Method
                  </th>
                  <th className="w-24 px-3 py-3 text-left text-sm font-medium text-postman-gray-dark">
                    Status
                  </th>
                  <th className="w-48 px-3 py-3 text-left text-sm font-medium text-postman-gray-dark">
                    Expected Result
                  </th>
                  <th className="w-24 px-3 py-3 text-left text-sm font-medium text-postman-gray-dark">
                    Type
                  </th>
                  <th className="w-24 px-3 py-3 text-left text-sm font-medium text-postman-gray-dark">
                    Result
                  </th>
                  <th className="w-48 px-3 py-3 text-left text-sm font-medium text-postman-gray-dark">
                    Body
                  </th>
                  <th className="w-48 px-3 py-3 text-left text-sm font-medium text-postman-gray-dark">
                    Query Params
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {testCases.map((testCase) => (
                  <TableRow
                    key={testCase.id}
                    testCase={testCase}
                    isSelected={selectedTestCases.includes(testCase)}
                    onSelect={handleCheckboxChange}
                    isGlobalEdit={isGlobalEdit}
                    editedData={editedTestCases[testCase.id]}
                    onFieldChange={handleFieldChange}
                    getTestResult={getTestResult}
                    getMethodColor={getMethodColor}
                    formatJSONDisplay={formatJSONDisplay}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestCasesTable;