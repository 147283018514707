import { Box, Button, styled, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  EditHeader,
  ExecutionReportsTestSuiteTable,
  ExeMenu,
  ExeTestCaseReportsTable,
  ReportSelection,
} from "./Reports";
import { IconContainer, IconContainerCircle, PageTitle } from "../components-new/common";
import SyncDisabledIcon from "@mui/icons-material/SyncDisabled";
import { ContextIcon, ExportIcon, RefreshIcon } from "../components-new/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  useAnalysisQuery,
  useCurrentExecutionQuery,
  useExecuteTCQuery,
  useFetchTestStepResultQuery,
  useLastestResultQuery,
  useModuleQuery,
  useProductQuery,
  useSearchTestCaseRecordsQuery,
  useStopTestSuiteQuery,
  useTestCaseQuery,
  useTestCaseResultsQuery,
  useTestSuiteResultQuery,
} from "../useQuery";
import {
  setTestCaseResults,
  setSelectedComponent,
  setSelectedProduct,
  setSelectedTestCase,
  resetExecutionReport,
  setSelectedTestSuite,
} from "../Redux/ExecutionReport/ExecutionReport";
import TestItemCard from "./Reports/TestItemCard";
import useTestStepSearchQuery from "../useQuery/useTestStepSearchQuery";
import { useLocation, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CustomBreadcrumbs from "../components-new/common/CustomBreadCrums";
import CustomDialog from "../components-new/common/CustomDialog";
import EditTabs from "./Studio/EditTabs";
import StatusBar from "./Studio/StatusBar";
import { useItemWidth } from "../hooks";

const StyledButton = styled(Button)`
  padding: 15px 28px;
  border-radius: 8px;
  text-transform: capitalize;
`;

const ExecutionReports = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [refreshFlag, setRefreshFlag] = useState(true);
  const [testSuiteName, setTestSuiteName] = useState("");
  const [testCaseSearchKeyword, setTestCaseSearchKeyword] = useState("");
  const [editStep, setEditStep] = useState(false);
  const [editItemIndex, setEditItemIndex] = useState(null);
  const [currentEditItem, setCurrentEditItem] = useState(null);
  const [isExecute, setIsExecute] = useState(null);
  const [editCardData, setEditCardData] = useState([]);
  const containerRef = useRef(null);
  const itemsPerRow = 4;
  const gap = 32;
  const itemWidth = useItemWidth(containerRef, itemsPerRow, gap);

  const dispatch = useDispatch();
  const RouteState = useLocation();
  const navigate = useNavigate();

  const [isFiltered, setIsFiltered] = useState(false);
  const [isProductSetInitially, setIsProductSetInitially] = useState(false);
  const [isInitialSuiteExecution, setIsInitialSuiteExecution] = useState(false);

  const selectedTestCase = useSelector((state) => state.executionSlice.selectedTestCase);
  const selectedProduct = useSelector((state) => state.executionSlice.selectedProduct);
  const selectedModule = useSelector((state) => state.executionSlice.selectedComponent);
  const selectedTestSuite = useSelector((state) => state.executionSlice.selectedTestSuite);
  const testcaseResults = useSelector((state) => state.executionSlice.testCaseResults);
  const selectedTestCaseName = useSelector((state) => state.executionSlice.selectedTestCaseName);

  const [openStopDialog, setOpenStopDialog] = useState(false); // State for stop execution dialog

  const queryParams = new URLSearchParams(RouteState.search);
  const tcrid = queryParams.get("tcrid");
  const runDate = queryParams.get("date");
  const name = queryParams.get("name");
  const tcname = queryParams.get("tcname");
  const tcRunDate = queryParams.get("tcRundate");

  const TestSuitesBreadCrumbs = [
    <Typography key="3" color="text.primary">
      Execution Reports
    </Typography>,
    <Typography key="3" color="text.primary">
      {`Test Suite - ${name}`} - {`Run date - ${runDate}`}
    </Typography>,
    <Typography key="3" color="text.primary">
      {`Test case - ${tcname} - ${tcRunDate}`}
    </Typography>,
  ];

  const TestCasesBreadCrumbs = [
    <Typography key="3" color="text.primary">
      Execution Reports
    </Typography>,
    <Typography key="3" color="text.primary">
      {`Test case - ${tcname} - ${tcRunDate}`}
    </Typography>,
  ];

  const {
    data: currentExecutionData,
    isSuccess: isCurrentExecutionSuccess,
    refetch: refetchCurrentExecution,
    fetchStatus: fetchCurrentExecutionStatus,
  } = useCurrentExecutionQuery();
  const { data: products, refetch: refetchProductQuery } = useProductQuery();
  const {
    data: latestResult,
    refetch: refetchLatestResult,
    isLoading,
    fetchStatus,
  } = useLastestResultQuery({
    refetchInterval: refreshFlag ? 5000 : false,
    enabled:
      tcRunDate === null && !currentExecutionData?.length && !RouteState?.state?.fetchTestSuite,
  });

  const { data: modules, refetch: refetchModuleQuery } = useModuleQuery(selectedProduct, {
    enabled: selectedProduct !== "",
  });

  const { refetch: handleStopTSExecution } = useStopTestSuiteQuery(
    latestResult?.result?.idtest_case_results,
    "case",
  );

  const {
    data: testCasesData,
    refetch: executionReportFetch,
    isSuccess: isGetTestCaseResultSuccess,
  } = useSearchTestCaseRecordsQuery(selectedTestCase, {
    enabled: selectedTestCase !== "" && tcrid === null && selectedTestCase !== null,
  });

  const { data: testStepResults } = useTestCaseResultsQuery(selectedTestCase, {
    enabled: selectedTestCase !== null,
  });
  const {
    data: testSuitesResults,
    refetch: fetchTestSuitesResult,
    isSuccess: isGetTestSuiteResultSuccess,
    refetch: refetchTestSuiteResults,
  } = useTestSuiteResultQuery(selectedTestSuite, "", { enabled: selectedTestSuite !== "" });

  const { data: testCases, refetch: refetchTestCases } = useTestCaseQuery(
    selectedModule,
    testCaseSearchKeyword,
    { enabled: selectedModule !== "" && selectedModule !== 0 },
  );

  const { data: oneTimeTestCaseObject, isSuccess: isGetTestStepResultSuccess } =
    useTestStepSearchQuery(tcrid, {
      enabled: tcrid !== null || editStep !== null,
    });

  const { mutate: executeTest, isSuccess: isExecuteSuccess } = useExecuteTCQuery();
  const { mutate: analysisTest, isSuccess: isAnalysisSuccess } = useAnalysisQuery();

  const { data: testStepResult, refetch: refetchTestStepResult } =
    useFetchTestStepResultQuery(tcrid);

  useEffect(() => {
    setEditStep("");
    setEditItemIndex(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fetchStatus !== "fetching" && latestResult) {
      const valuesArray = Object.values(latestResult?.data);
      dispatch(setTestCaseResults(valuesArray));
    }
    if (latestResult?.result?.Status === "STARTED" || latestResult?.result?.Status === "RUNNING") {
      setIsExecute(true);
    } else {
      setIsExecute(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchStatus, latestResult]);

  useEffect(() => {
    if (isGetTestStepResultSuccess) {
      dispatch(setTestCaseResults(testStepResult));
    }
  }, [dispatch, isGetTestStepResultSuccess, testStepResult]);

  useEffect(() => {
    if (RouteState?.state?.initialSuiteExecution) {
      setIsInitialSuiteExecution(true);
    }
  }, [RouteState?.state?.initialSuiteExecution]);

  useEffect(() => {
    refetchProductQuery();
    if (RouteState?.state?.fromTestStudio) {
      setIsExecute(true);
      setRefreshFlag(false);
      setTestCaseSearchKeyword(RouteState?.state?.Test_Case);
      refetchModuleQuery(RouteState?.state?.idproducts);
      refetchTestCases(RouteState?.state?.idmodules, RouteState?.state?.Test_Case);
      dispatch(setSelectedProduct(RouteState?.state?.idproducts));
      dispatch(setSelectedComponent(RouteState?.state?.idmodules));
      dispatch(setSelectedTestCase(RouteState?.state?.idtest_case));
    }
    if (RouteState?.state?.isExecuteClick) {
      setIsFiltered(false);
      if (
        (latestResult?.result?.Status === "STARTED" ||
          latestResult?.result?.Status === "RUNNING" ||
          latestResult?.result?.Status === undefined) &&
        isExecute &&
        !selectedTestSuite &&
        !RouteState.state.fetchTestSuite
      ) {
        window.ExecuteTimer = setInterval(() => {
          refetchLatestResult();
        }, 5000);
      } else if (selectedTestSuite !== "") {
        if (RouteState?.state?.fetchTestSuite) {
          setIsFiltered(true);
          window.ExecuteTimer = setInterval(() => {
            fetchTestSuitesResult();
          }, 5000);
        }
      } else if (RouteState.state.fetchTestSuite) {
        setIsFiltered(true);
        dispatch(setSelectedProduct(RouteState?.state?.product));
        dispatch(setSelectedTestSuite(RouteState?.state?.selectedSuit));
      }
    }
    return () => clearInterval(window.ExecuteTimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    RouteState,
    isExecute,
    dispatch,
    fetchTestSuitesResult,
    latestResult?.result?.Status,
    navigate?.state?.idmodules,
    navigate?.state?.idproducts,
    navigate?.state?.idtest_case,
    refetchLatestResult,
    refetchModuleQuery,
    refetchProductQuery,
    refetchTestCases,
    selectedTestSuite,
  ]);

  useEffect(() => {
    refetchModuleQuery();
    setTestCaseSearchKeyword("");
  }, [refetchModuleQuery, selectedProduct]);

  useEffect(() => {
    if (isCurrentExecutionSuccess) dispatch(setTestCaseResults(currentExecutionData));
  }, [currentExecutionData, dispatch, isCurrentExecutionSuccess, fetchCurrentExecutionStatus]);

  useEffect(() => {
    if (!selectedProduct && latestResult && !isProductSetInitially) {
      const valuesArray = Object.values(latestResult.data);
      setTestCaseSearchKeyword(latestResult.testcase.Test_Case);
      dispatch(setTestCaseResults(valuesArray));
      dispatch(setSelectedProduct(latestResult.Product));
      dispatch(setSelectedComponent(latestResult.Modules));
      dispatch(setSelectedTestCase(latestResult.Testcase));
      setIsProductSetInitially(true);
    }
  }, [
    latestResult,
    dispatch,
    selectedProduct,
    isProductSetInitially,
    refetchTestCases,
    selectedModule,
  ]);

  useEffect(() => {
    if (selectedModule) refetchTestCases(selectedModule, testCaseSearchKeyword);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchTestCases, selectedModule]);

  useEffect(() => {
    if (tcrid && oneTimeTestCaseObject) {
      setRefreshFlag(false);
      setIsFiltered(true);
      dispatch(setSelectedProduct(oneTimeTestCaseObject[0].Product));
      dispatch(setSelectedComponent(oneTimeTestCaseObject[0].Modules));
      dispatch(setSelectedTestCase(oneTimeTestCaseObject[0].Testcase));
    }
  }, [tcrid, dispatch, oneTimeTestCaseObject]);

  useEffect(() => {
    if (tcrid) {
      refetchTestStepResult(tcrid);
    }
  }, [refetchTestStepResult, tcrid]);

  useEffect(() => {
    if (editCardData) setCurrentEditItem(editCardData?.[editItemIndex]);
  }, [editCardData, editItemIndex]);

  useEffect(() => {
    if (isExecuteSuccess || isAnalysisSuccess)
      navigate("/new/reports", {
        state: { isExecuteClick: true, selectedSuit: selectedTestSuite, product: selectedProduct },
      });
  }, [isExecuteSuccess, isAnalysisSuccess, navigate, selectedTestSuite, selectedProduct]);

  useEffect(() => {
    return () => {
      dispatch(resetExecutionReport());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (
  //     isExecute &&
  //     (latestResult?.result?.Status !== "STARTED" || latestResult?.result?.Status !== "RUNNING")
  //   ) {
  // const selectedItem = testStepResults.testcase;
  // const obj = {
  //   id: selectedItem?.idtest_case,
  //   SelectEnvironmentName: selectedItem.envname,
  //   SelectEnvironmentUrl: selectedItem.envurl,
  //   SelectBrowserName: selectedItem.browser,
  //   SelectResolution: selectedItem.SelectResolution,
  //   SelectRunonce: selectedItem.multirun,
  //   testcase: selectedItem.Test_Case,
  // };
  //     setRefreshFlag(true);
  //     executeTest(obj);
  //   } else if (
  //     isExecute === false &&
  //     (latestResult?.result?.Status === "STARTED" || latestResult?.result?.Status === "RUNNING")
  //   ) {
  //     setOpenStopDialog(true);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isExecute]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event) => {
    setAnchorEl(null);
  };

  const handleEdit = (dataList, currentItemIndex) => {
    setRefreshFlag(false);
    setEditStep(true);
    if (tcrid) {
      refetchTestStepResult(tcrid);
    }

    // // Convert object to an array of entries (key-value pairs)
    const dataEntries = Array.isArray(dataList) ? dataList : Object.values(dataList.data);
    setEditCardData(dataEntries);

    // const currentItemIndex = dataEntries.findIndex(
    //   (testCaseItem) => testCaseItem.Step_Number === item.Step_Number,
    // );
    const currentEditItem = dataEntries[currentItemIndex];
    setEditItemIndex(currentItemIndex);
    setCurrentEditItem(currentEditItem);
  };

  const handleExecute = () => {
    // setIsExecute((prev) => !prev);
    const selectedItem = testStepResults.testcase;
    const obj = {
      id: selectedItem?.idtest_case,
      SelectEnvironmentName: selectedItem.envname,
      SelectEnvironmentUrl: selectedItem.envurl,
      SelectBrowserName: selectedItem.browser,
      SelectResolution: selectedItem.SelectResolution,
      SelectRunonce: selectedItem.multirun,
      testcase: selectedItem.Test_Case,
    };
    executeTest(obj);
    setRefreshFlag(true);
  };

  const handleAnalysis = () => {
    const selectedItem = testStepResults.testcase;
    const obj = {
      id: selectedItem?.idtest_case,
      SelectEnvironmentName: selectedItem.envname,
      SelectEnvironmentUrl: selectedItem.envurl,
      SelectBrowserName: selectedItem.browser,
      SelectResolution: selectedItem.SelectResolution,
      SelectRunonce: selectedItem.multirun,
      testcase: selectedItem.Test_Case,
    };
    setRefreshFlag(true);
    analysisTest(obj);
    setIsExecute(true);
    handleMenuClose();
  };

  const handleCurrentExecution = () => {
    setRefreshFlag(false);
    refetchCurrentExecution();
    handleMenuClose();
  };

  const handleViewTestCase = () => {
    navigate("/new/test-studio", {
      state: {
        fromExecutionReports: true,
        idproducts: selectedProduct,
        idmodules: selectedModule,
        idtest_case: selectedTestCase,
        Test_Case: selectedTestCase,
      },
    });
  };

  const handleConfirmStopExecution = () => {
    // Add logic to stop execution
    handleStopTSExecution();
    setOpenStopDialog(false);
  };

  const handleCancelStopExecution = () => {
    setOpenStopDialog(false);
  };

  const testCaseId = latestResult?.testcase?.idtest_case;
  const testCaseResultId = latestResult?.result?.idtest_case_results;

  return (
    <Box sx={{ my: 2 }}>
      {!isLoading && latestResult && !isFiltered && (
        <StatusBar
          data={latestResult?.result}
          testcaseid={testCaseId}
          testcaseresultId={testCaseResultId}
        />
      )}
      {editItemIndex === null ? (
        <>
          <Box>
            <Box display="flex" alignItems="center" justifyContent={"space-between"}>
              <Box display="flex" columnGap={3} alignItems={"center"}>
                {!tcrid && (
                  <ReportSelection
                    setRefreshFlag={setRefreshFlag}
                    testCases={testCases}
                    refetchTestCases={refetchTestCases}
                    test
                    testCaseSearchKeyword={testCaseSearchKeyword}
                    setTestCaseSearchKeyword={setTestCaseSearchKeyword}
                    products={products}
                    modules={modules}
                    setTestSuiteName={setTestSuiteName}
                    executionReportFetch={executionReportFetch}
                    setIsFiltered={setIsFiltered}
                    oneTimeTestCaseObject={oneTimeTestCaseObject}
                    refetchTestSuiteResults={refetchTestSuiteResults}
                    setIsInitialSuiteExecution={setIsInitialSuiteExecution}
                  />
                )}
                {tcRunDate && (
                  <Box>
                    <PageTitle title={tcname} variant="h4" mb={2} />
                    <CustomBreadcrumbs ariaLabel="breadcrumb">
                      {name ? TestSuitesBreadCrumbs : TestCasesBreadCrumbs}
                    </CustomBreadcrumbs>
                  </Box>
                )}
              </Box>
              {selectedTestCase && (
                <Box display={"flex"} columnGap={"1rem"} alignItems="center">
                  <Box>
                    {refreshFlag ? (
                      <IconContainer
                        handleClick={() => {
                          setRefreshFlag(false);
                          clearInterval(window.ExecuteTimer);
                        }}
                      >
                        <SyncDisabledIcon />
                      </IconContainer>
                    ) : (
                      <IconContainer
                        handleClick={() => {
                          setRefreshFlag(true);
                        }}
                      >
                        <RefreshIcon />
                      </IconContainer>
                    )}
                  </Box>
                  <Box>
                    <IconContainer handleClick={handleViewTestCase} tooltipText={"View Test Case"}>
                      <VisibilityIcon />
                    </IconContainer>
                  </Box>
                  {latestResult?.result?.Status !== "STARTED" &&
                    latestResult?.result?.Status !== "RUNNING" && (
                      <Box>
                        <StyledButton
                          variant="contained"
                          onClick={handleExecute}
                          color="warning"
                          endIcon={<ExportIcon />}
                          sx={{
                            backgroundColor: "#ff5336",
                            boxShadow: "0px 16px 30.6px 0px rgba(255, 83, 54, 0.27)",
                          }}
                        >
                          Run
                        </StyledButton>
                      </Box>
                    )}
                  {(latestResult?.result?.Status === "STARTED" ||
                    latestResult?.result?.Status === "RUNNING") && (
                    <Box>
                      <StyledButton
                        variant="contained"
                        onClick={() => setOpenStopDialog(true)}
                        color="warning"
                        endIcon={<ExportIcon />}
                        sx={{
                          backgroundColor: "#ff5336",
                          boxShadow: "0px 16px 30.6px 0px rgba(255, 83, 54, 0.27)",
                        }}
                      >
                        Stop
                      </StyledButton>
                    </Box>
                  )}
                  <Box onClick={handleMenuClick}>
                    <IconContainerCircle>
                      <ContextIcon />
                    </IconContainerCircle>
                  </Box>
                </Box>
              )}
              <ExeMenu
                anchorEl={anchorEl}
                handleClose={handleMenuClose}
                handleAnalysis={handleAnalysis}
                setRefreshFlag={setRefreshFlag}
                handleCurrentExecution={handleCurrentExecution}
                selectedTestCaseName={selectedTestCaseName}
              />
            </Box>
          </Box>
          {(tcrid === null && !isFiltered && selectedTestCase && !oneTimeTestCaseObject?.length) ||
          (!isFiltered && testcaseResults?.length) ? (
            <Box ref={containerRef} display="flex" columnGap={4} flexWrap="wrap" rowGap={4} mt={3}>
              {testcaseResults &&
                testcaseResults.map((data, index) => {
                  return (
                    <TestItemCard
                      item={data}
                      index={index}
                      key={index}
                      handleEdit={() => handleEdit(testcaseResults, index)}
                      width={itemWidth}
                    />
                  );
                })}
            </Box>
          ) : null}
          {tcrid === null &&
          testCasesData?.length &&
          isGetTestCaseResultSuccess &&
          isFiltered &&
          !isInitialSuiteExecution ? (
            <Box display="flex" mt={3}>
              <ExeTestCaseReportsTable
                data={testCasesData}
                isSuccess={isGetTestCaseResultSuccess}
              />
            </Box>
          ) : null}

          {tcrid !== null && testStepResult?.length ? (
            <Box ref={containerRef} display="flex" columnGap={4} flexWrap="wrap" rowGap={4} mt={3}>
              {testStepResult &&
                isGetTestStepResultSuccess &&
                testStepResult.map((item, index) => {
                  return (
                    <TestItemCard
                      item={item}
                      index={index}
                      key={index}
                      handleEdit={() => handleEdit(testStepResult, index)}
                      width={itemWidth}
                    />
                  );
                })}
            </Box>
          ) : null}
          {tcrid === null && isFiltered && testSuitesResults?.length ? (
            <Box display="flex" mt={3}>
              <ExecutionReportsTestSuiteTable
                testSuiteName={testSuiteName}
                data={testSuitesResults}
                isSuccess={isGetTestSuiteResultSuccess}
                prod={products}
              />
            </Box>
          ) : null}

          {!testSuitesResults?.data?.length &&
            !testStepResults?.length &&
            !latestResult &&
            !testcaseResults && (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                height={"300px"}
              >
                No data available
              </Box>
            )}
        </>
      ) : (
        <Box pb={2}>
          <EditHeader
            editItemIndex={editItemIndex}
            editItem={currentEditItem}
            setEditItemIndex={setEditItemIndex}
            EditData={editCardData}
          />
          <EditTabs editItem={currentEditItem} fromExcReports={true} />
        </Box>
      )}

      <CustomDialog
        open={openStopDialog}
        onClose={handleCancelStopExecution}
        title="Stop Execution"
        content={
          <Typography>Are you sure you want to stop execution of this test case?</Typography>
        }
        actions={
          <>
            <Button onClick={handleConfirmStopExecution}>Yes</Button>
            <Button onClick={handleCancelStopExecution}>No</Button>
          </>
        }
      />
    </Box>
  );
};

export default ExecutionReports;
