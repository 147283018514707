import React from "react";

const DownloadStepsIcon = () => (
  <svg width="309" height="314" viewBox="0 0 309 314" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M182.33 313.818C177.354 313.818 173.095 312.205 169.555 308.978C166.008 305.746 164.234 301.863 164.234 297.328V272.592H182.33V297.328H290.904V272.592H309V297.328C309 301.863 307.23 305.746 303.689 308.978C300.142 312.205 295.881 313.818 290.904 313.818H182.33ZM236.617 280.837L191.378 239.611L204.045 227.656L227.569 249.093V181.895H245.665V249.093L269.19 227.656L281.857 239.611L236.617 280.837Z" fill="#242F3F"/>
<path d="M34.8325 314C25.2536 314 17.0534 310.584 10.2321 303.751C3.41068 296.919 0 288.706 0 279.111V34.8889C0 25.2944 3.41068 17.081 10.2321 10.2486C17.0534 3.4162 25.2536 0 34.8325 0H156.746L261.244 104.667V157.436H226.411V122.111H139.33V34.8889H34.8325V279.111H139.33V314H34.8325Z" fill="#242F3F"/>
</svg>

);

export default DownloadStepsIcon;
