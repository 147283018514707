import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

const fetchTestSuite = async (id) => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found");

  const user = JSON.parse(userDetails);

  const response = await axios.get(
    `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getTestSuites&companyid=${user.companyid}&token=${user.token}&productid=${id}`,
  );

  return response.data;
};
const useTestSuiteQueryNew = (id, enabled = false) => {
  return useQuery({
    queryKey: ["testSuiteNew", id],
    queryFn: () => fetchTestSuite(id),
    enabled: enabled && !!id,
    retry: 1,
    cacheTime: 90000,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    staleTime: Infinity,
    onError: (error) => {
      console.error("Query error in useTestSuiteQueryNew:", error);
    },
  });
};

export default useTestSuiteQueryNew;
