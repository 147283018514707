import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link,
  Chip,
  Pagination,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { useFetchFailureAnalysis } from "../useQuery";

const StyledTableCell = styled(TableCell)({
  color: "white",
  fontWeight: "600",
  padding: "16px",
  fontSize: "0.875rem",
});

const StyledTableRow = styled(TableRow)({
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
  },
});

const FailureAnalysisPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tsrid = searchParams.get("tsrid");

  const [page, setPage] = useState(1);
  const rowsPerPage = 10;

  const { data, isLoading, isError, error } = useFetchFailureAnalysis(tsrid);

  const getStatusChipColor = (status) => {
    return status.toUpperCase() === "PASS" ? "success" : "error";
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error: {error.message}</div>;

  const tableData = data?.tableData || [];
  const paginatedData = tableData.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  return (
    <div className="bg-gray-100 min-h-screen px-4 md:px-6">
      <div className="max-w-8xl mx-auto">
        {/* Header */}
        <div className="bg-gray-800 text-white py-5 px-4 text-center mb-6 rounded-md shadow-md">
          <h1 className="text-xl font-semibold tracking-wide">Failure Analysis</h1>
        </div>

        {/* Assessment and Action Section */}
        <div className="flex flex-col md:flex-row gap-6 mb-8">
          {/* Our Assessment */}
          <div className="bg-white p-6 md:p-8 rounded-md shadow-md md:w-1/2 border border-gray-100">
            <h2 className="text-lg font-semibold text-center mb-5 text-gray-800 pb-2 border-b border-gray-100">
              Our Assessment
            </h2>
            {/* TO DO : MAP Our Assessment from data from api when integrated */
              data?.ourAssessment && Object.keys(data.ourAssessment).length > 0 ? (
                <div>
                  <p className="text-gray-700 mb-2">
                    <strong>Issue Type:</strong> {data.ourAssessment.issueType}
                  </p>
                  <p className="text-gray-700">
                    <strong>Issues:</strong> {data.ourAssessment.issues}
                  </p>
                </div>
              ) : (
                <p className="text-gray-500 italic">No assessment data available at the moment.</p>
              )
            }
          </div>

          {/* Recommended Action */}
          <div className="bg-white p-6 md:p-8 rounded-md shadow-md md:w-1/2 border border-gray-100">
            <h2 className="text-lg font-semibold text-center mb-5 text-gray-800 pb-2 border-b border-gray-100">
              Recommended Action
            </h2>
            {/* TO DO : MAP Recommended Action from data from api when integrated */}
          </div>
        </div>

        {/* Results Table */}
        <TableContainer
          component={Paper}
          className="shadow-md rounded-md overflow-hidden border border-gray-200"
        >
          <Table aria-label="failure analysis results">
            <TableHead className="bg-gray-800">
              <TableRow>
                <StyledTableCell>Result ID</StyledTableCell>
                <StyledTableCell>Executed Date</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Screenshot</StyledTableCell>
                <StyledTableCell>Page Source</StyledTableCell>
                <StyledTableCell>Analysis Log</StyledTableCell>
                <StyledTableCell>Log File</StyledTableCell>
                <StyledTableCell>Element Found</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.map((row, index) => (
                <StyledTableRow key={row.idtest_step_result || index}>
                  <TableCell className="font-medium">{row.Test_Case_Results_Id}</TableCell>
                  <TableCell>{row.Executed_Date}</TableCell>
                  <TableCell>
                    <Chip
                      label={row.Status}
                      size="small"
                      color={getStatusChipColor(row.Status)}
                      className="font-medium"
                    />
                  </TableCell>
                  <TableCell>
                    <Link
                      href={row.Failure_Screenshot_Location}
                      target="_blank"
                      underline="hover"
                      className="text-blue-600 font-medium"
                    >
                      Screenshot
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      href={row.page_source_file}
                      target="_blank"
                      underline="hover"
                      className="text-blue-600 font-medium"
                    >
                      Page Source
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      href={row.analysis_log_file}
                      target="_blank"
                      underline="hover"
                      className="text-blue-600 font-medium"
                    >
                      Analysis Log
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link 
                      href={row.logFile}
                      target="_blank"
                      underline="hover" 
                      className="text-blue-600 font-medium"
                    >
                      Log File
                    </Link>
                  </TableCell>
                  <TableCell className="text-xs max-w-md">
                    <div className="max-h-48 overflow-y-auto p-2 bg-gray-50 rounded border border-gray-100">
                      {row.outerhtml}
                    </div>
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination */}
        <div className="flex justify-center mt-4">
          <Pagination
            count={Math.ceil(tableData.length / rowsPerPage)}
            page={page}
            onChange={handlePageChange}
            color="primary"
            showFirstButton
            showLastButton
          />
        </div>
      </div>
    </div>
  );
};

export default FailureAnalysisPage;
