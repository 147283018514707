/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Pagination, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useItemWidth, useNotification } from "../../hooks";
import TestSuiteCard from "./TestSuiteCard";
import CustomDialog from "../../components-new/common/CustomDialog";
import {
  useAddToSuiteMutation,
  useDeleteTestSuiteQuery,
  useMoveProductsQuery,
  useMoveSuiteQuery,
} from "../../useQuery";
import { useQueryClient } from "@tanstack/react-query";
import TestSuiteSettings from "./TestSuiteSettings";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedSuite } from "../../Redux/TestSuite/TestSuite";
import { CustomInput, CustomSelect } from "../../components-new/common";
import useReNameTestSuite from "../../useQuery/useRenameTestSuite";
import { PlusIcon } from "../../components-new/icons";

export default function TestSuiteItems({
  data,
  itemsPerPage,
  showTestCases,
  itemsPerRow,
  handleSuiteClick,
  prodName,
}) {
  const containerRef = useRef(null);
  const gap = 16;
  const itemWidth = useItemWidth(containerRef, itemsPerRow, gap);
  const queryClient = useQueryClient();
  const notify = useNotification();
  const dispatch = useDispatch();

  const [testSuitePage, setTestSuitePage] = useState(1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [suiteId, setSuiteId] = useState(null);
  const [openSuiteSettings, setOpenSuiteSettings] = useState(false);
  const [caseToBeAdded, setCaseToBeAdded] = useState([]);
  const [casesToBeMoved, setCasesToBeMoved] = useState([]);
  const [moveProdID, setMoveProdId] = useState(null);
  const [moveSuiteId, setMoveSuiteId] = useState(null);
  const [showAddTestCaseModal, setShowAddTestCaseModal] = useState(false);
  const [testSuiteData, setTestSuiteData] = useState([]);

  const [renameModal, setRenameModal] = useState(false);
  const [suiteName, setSuiteName] = useState("");

  const { refetch, isSuccess } = useDeleteTestSuiteQuery(suiteId, false);
  const { mutate: postTestCase } = useAddToSuiteMutation();
  const { data: products } = useMoveProductsQuery();
  const { data: testSuits, refetch: refetchMove } = useMoveSuiteQuery(moveProdID, !!moveProdID);

  const suiteList = useSelector((state) => state.testSuite.suiteList);
  const selectedSuite = useSelector((state) => state.testSuite.selectedSuite);
  const suite = suiteList?.find((suite) => suite.idtest_suite === selectedSuite);

  const { refetchWithParams: renameTestSuite } = useReNameTestSuite();

  useEffect(() => {
    if (!testSuits) return;
    setTestSuiteData(testSuits.flat());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testSuits]);

  useEffect(() => {
    refetchMove();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moveProdID]);

  const paginatedData = (data ?? [])
    .flat()
    .slice((testSuitePage - 1) * itemsPerPage, testSuitePage * itemsPerPage);

  const handleDeleteClick = (e, suite) => {
    e.stopPropagation();
    setShowDeleteModal(true);
    setSuiteId(suite.idtest_suite);
  };

  const handleSettingsClick = (e, suite) => {
    e.stopPropagation();
    dispatch(setSelectedSuite(suite.idtest_suite));
    setOpenSuiteSettings(true);
  };

  useEffect(() => {
    if (!isSuccess) return;
    notify("Test suite deleted", "success");
  }, [isSuccess]);

  const handleReNameSuite = (e, suite) => {
    e.stopPropagation();
    dispatch(setSelectedSuite(suite.idtest_suite));
    setRenameModal(true);
  };

  const addTestCase = (e, testCase) => {
    if (e.target.checked) {
      setCaseToBeAdded((prev) => [...prev, testCase]);
    } else {
      setCaseToBeAdded((prev) => prev.filter((item) => item !== testCase));
    }
  };

  const DeleteAction = () => {
    return (
      <>
        <Button
          sx={{ color: "#ff5336" }}
          onClick={() => {
            setSuiteId(null);
            setShowDeleteModal(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{ background: "#ff5336" }}
          onClick={() => {
            refetch().finally(() => {
              setShowDeleteModal(false);
              setSuiteId(null);
              queryClient.invalidateQueries(["testSuiteNew"]);
            });
          }}
        >
          Delete
        </Button>
      </>
    );
  };

  const RenameActions = () => {
    return (
      <>
        <Button
          sx={{ color: "#ff5336" }}
          onClick={() => {
            setSuiteId(null);
            setRenameModal(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{ background: "#ff5336" }}
          onClick={() => {
            setRenameModal(false);
            renameTestSuite(suite.idtest_suite, suiteName);
          }}
        >
          Rename
        </Button>
      </>
    );
  };

  const AddTestCaseAction = ({ caseToBeAdded, setCaseToBeAdded }) => {
    return (
      <>
        <Button
          sx={{ color: "#ff5336" }}
          onClick={() => {
            setShowAddTestCaseModal(false);
          }}
        >
          Cancel
        </Button>
        <Button
          // disabled={!caseToBeAdded?.length}
          variant="contained"
          sx={{ background: "#ff5336" }}
          onClick={() => {
            postTestCase({
              data: caseToBeAdded,
              params: {
                idtest_suite: moveSuiteId,
                pnum: 1,
              },
            });
            setCaseToBeAdded([]);
            setShowAddTestCaseModal(false);
          }}
        >
          Save
        </Button>
      </>
    );
  };

  return (
    <>
      <CustomDialog
        title={"Are you sure you want to delete this Suite?"}
        open={showDeleteModal}
        actions={<DeleteAction />}
      />
      <CustomDialog
        title={"Are you sure you want to Rename this Suite?"}
        open={renameModal}
        actions={<RenameActions />}
        content={
          <>
            <CustomInput
              labelinput="Suite Name"
              placeholder={"Enter Suite Name"}
              value={suiteName}
              onChange={(e) => setSuiteName(e.target.value)}
              name={"createSuiteModal"}
            />
          </>
        }
      />
      <CustomDialog
        title={"Add Test Suite"}
        open={showAddTestCaseModal}
        actions={
          <AddTestCaseAction
            casesToBeMoved={casesToBeMoved}
            caseToBeAdded={caseToBeAdded}
            setCaseToBeAdded={setCaseToBeAdded}
          />
        }
        width={"unset"}
        content={
          <Box display={"flex"} flexDirection={"row"} columnGap={2}>
            <CustomSelect
              label={"Products"}
              options={(Array.isArray(products) ? products : []).map((prodItem) => ({
                label: prodItem.productname,
                value: prodItem.idproducts,
              }))}
              width={"230px"}
              background={"#ffffff"}
              value={moveProdID || ""}
              onChange={(e) => {
                setMoveProdId(e.target.value);
                setMoveSuiteId(null);
              }}
            />
            <CustomSelect
              label={"Suite"}
              options={(Array.isArray(testSuiteData) ? testSuiteData : []).map((suite) => ({
                label: suite.Test_Suite,
                value: suite.idtest_suite,
              }))}
              width={"230px"}
              background={"#ffffff"}
              value={moveSuiteId || ""}
              onChange={(e) => {
                setMoveSuiteId(e.target.value);
              }}
            />
          </Box>
        }
      />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex">
          <Typography fontSize="21px" fontWeight={600}>
            All Test Suite
          </Typography>
          {prodName && (
            <Typography fontSize="21px" fontWeight={600} ml={1}>
              {`for ${prodName} product`}
            </Typography>
          )}
        </Box>
        <Box>
          <Button
            sx={{ textTransform: "capitalize", p: `10px 15px`, ml: 2 }}
            endIcon={<PlusIcon />}
            variant="contained"
            disabled={!caseToBeAdded.length}
            onClick={() => setShowAddTestCaseModal(true)}
          >
            Add Test Suite
          </Button>
        </Box>
      </Box>
      <Box
        width={"100%"}
        py={1}
        ref={containerRef}
        display="flex"
        columnGap={2}
        rowGap={2}
        flexWrap="wrap"
      >
        {paginatedData?.length > 0
          ? paginatedData?.map((suite) => (
              <TestSuiteCard
                item={suite}
                width={itemWidth}
                isFolder={!showTestCases}
                key={suite.idtest_suite}
                handleSuiteClick={handleSuiteClick}
                handleDeleteClick={(e) => handleDeleteClick(e, suite)}
                handleSettings={(e) => handleSettingsClick(e, suite)}
                handleReNameSuite={(e) => handleReNameSuite(e, suite)}
                addTestCase={(e) => addTestCase(e, suite)}
                caseToBeAdded={caseToBeAdded}
              />
            ))
          : null}
        {data?.length < 1 && <Box>No Test Suites Available</Box>}
      </Box>
      {data?.length > itemsPerPage && (
        <Box py={2} display="flex" justifyContent="center">
          <Pagination
            count={Math.ceil(data.flat().length / itemsPerPage)}
            page={testSuitePage}
            onChange={(e, newPage) => setTestSuitePage(newPage)}
            color="primary"
            variant="outlined"
            shape="rounded"
          />
        </Box>
      )}
      {openSuiteSettings && (
        <TestSuiteSettings
          open={openSuiteSettings}
          setOpenSuiteSettings={setOpenSuiteSettings}
          suite={suite}
        />
      )}
    </>
  );
}
