import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton } from "@mui/material";
import { ColorButton, colorExport } from "./../common/common.Component";
import { useEffect, useState } from "react";
// import {editStepInrecording} from "./../../API/RecordingTableAPI";
import {
  editStepInrecording,
  fetchResultInRecording,
  addStepInrecording,
} from "./../../API/RecordingTableAPI";
import { API_BASE_PATH, API_BASE_RELATIVE_PATH } from "./../../Config/Config";
import { CommonMethods } from "./../common/CommonMethods";
import { useNavigate } from "react-router-dom";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { InputLabel, FormControl } from "@mui/material";
import { modalExecute } from "./../../API/CreateCollectionAPI";
import ModalExpand from "./ModalExpand";
import MyCarousel from "./MyCarousel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormControlLabel, Switch } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { validateDetailsBySearch } from "../../API/ValidateAPI";
import axios from "axios";
import ElementLocate from "./ElementLocate";
import Script from "./Script";
import CommandLine from "./CommandLine";
const colors = colorExport();
const userDetails = localStorage.getItem("userDetail");
const temp = JSON.parse(userDetails);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper ": {
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    width: "100%",
    height: "100%",
    maxWidth: "100%!important",
    maxWeight: "100% !important",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const EditModal = ({
  setState,
  valueSaved,
  additionalTestCase,
  indexValue,
  state,
  TestDataDetails,
  setTestDataDetails,
  handleClose,
  testCaseName,
  envUrl,
  test,
  productId,
  checkModuleEnable,
  stepNumber,
}) => {
  const KeywordData = [
    { value: "Assertion", name: "Assertion" },
    { value: "Alert", name: "Alert" },
    { value: "Api", name: "Api" },
    { value: "Button", name: "Button" },
    { value: "Check Box", name: "Check Box" },
    { value: "Clipboard", name: "Clipboard" },
    { value: "DropDown", name: "DropDown" },
    { value: "EditBox", name: "EditBox" },
    { value: "File Upload", name: "File Upload" },
    { value: "File", name: "File" },
    { value: "Link", name: "Link" },
    { value: "Mouse", name: "Mouse" },
    { value: "Radio Button", name: "Radio Button" },
    { value: "SVGElement", name: "SVGElement" },
    { value: "Tab", name: "Tab" },
    { value: "TextArea", name: "TextArea" },
    { value: "Url", name: "Url" },
    { value: "Send", name: "Send" },
    { value: "Script", name: "Script" },
    { value: "TestCase", name: "Test Case" },
    { value: "Window", name: "Window" },
  ];

  const ActionData = [
    { value: "validatePartial", name: "Assert Text" },
    { value: "AlertOK", name: "Alert OK" },
    { value: "ArrowUp", name: "Arrow Up" },
    { value: "ArrowDown", name: "Arrow Down" },
    { value: "Button", name: "Button" },
    { value: "Change", name: "Change" },
    { value: "Checkbox", name: "Check Box" },
    { value: "Clear", name: "Clear" },
    { value: "Click", name: "Click" },
    { value: "Create", name: "Create" },
    { value: "DblClick", name: "Double Click" },
    { value: "DynamicText", name: "Dynamic Text" },
    { value: "Download", name: "Download" },
    { value: "Email", name: "Email" },
    { value: "Enter", name: "Enter" },
    { value: "FileUpload", name: "File Upload" },
    { value: "Get", name: "Get" },
    { value: "Hover", name: "Hover" },
    { value: "Include", name: "Include" },
    { value: "Javascript", name: "Javascript" },
    { value: "CommandLine", name: "CommandLine" },
    { value: "NewTab", name: "New Tab" },
    { value: "Properties", name: "Properties" },
    { value: "Radio", name: "Radio" },
    { value: "AlertText", name: "Read Alert Text" },
    { value: "Scroll", name: "Scroll To Element" },
    { value: "Select", name: "Select" },
    { value: "Slide", name: "Slide" },
    { value: "Submit", name: "Submit" },
    { value: "Test", name: "Test" },
  ];

  const [indexValueChange, setIndexValueChange] = useState(indexValue);
  const [newTestStep, setNewTestStep] = useState({ Step_Number: stepNumber });
  const [editValue, setEditValue] = useState(true);
  const [cmdValue, setCmdValue] = useState(true);
  const [chooseData, setChooseData] = useState("");
  const [valuesSavedResult, setValuesSavedResult] = useState([]);
  const [modalExpandValue, setModalExpandValue] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [javascriptShow, setJavascriptShow] = useState(false);
  const [cmdShow, setCmdShow] = useState(false);
  const [toggleOn, setToggleOn] = useState(newTestStep.dynamicvalue === 1 ? true : false);
  const [toggleOnAlert, setToggleOnAlert] = useState(newTestStep.createsAlert === 1 ? true : false);

  const navigate = useNavigate();

  const editValuePositive = () => {
    setEditValue(true);
  };

  const editValueNegative = () => {
    setEditValue(false);
  };

  const handleToggle = () => {
    setToggleOn(!toggleOn);
  };

  const handleToggleAlert = () => {
    setToggleOnAlert(!toggleOnAlert);
  };

  useEffect(() => {
    setIndexValueChange(indexValue);
  }, [indexValue]);

  useEffect(() => {
    if (indexValueChange !== null) {
      getResult(valueSaved[indexValueChange].Test_Case_Id);
    }
  }, []);

  useEffect(() => {
    if (valueSaved !== undefined) {
      setNewTestStep(valueSaved[indexValueChange]);
    }
  }, [indexValueChange, valueSaved]);

  useEffect(() => {
    if (newTestStep?.dynamicvalue === 1) {
      setToggleOn(true);
    }
  }, [newTestStep.dynamicvalue]);

  useEffect(() => {
    if (newTestStep?.createsAlert === 1) {
      setToggleOnAlert(true);
    }
  }, [newTestStep.createsAlert]);

  

  const incrementCount = () => {
    if (editValue === true) {
      setNewTestStep(valueSaved[indexValueChange + 1]);
      if (indexValueChange < valueSaved.length - 1) {
        setIndexValueChange(indexValueChange + 1);
      }
    } else {
      if (indexValueChange < valuesSavedResult.length - 1) {
        setIndexValueChange(indexValueChange + 1);
      }
    }
  };

  const decrementCount = () => {
    if (editValue === true) {
      setNewTestStep(valueSaved[indexValueChange - 1]);
      if (indexValueChange < valueSaved.length) {
        setIndexValueChange(indexValueChange - 1);
      }
    } else {
      if (indexValueChange > 0) {
        setIndexValueChange(indexValueChange - 1);
      }
    }
  };

  const getResult = async (api_input) => {
    await fetchResultInRecording(api_input)
      .then((response) => {
        if (response && response.data) {
          if (response.status === 200) {
            if (response.data.message === "fail") {
              CommonMethods(navigate);
            }
            // setloader(false);
            else {
              // setState((prev) => {
              //   return {
              //     ...prev,
              //     // stepnums: Object.values(response.data.stepnums),
              //     // resolutionDetails:response.data.resolutionDetails,
              //   };
              // });
              setValuesSavedResult(Object.values(response.data));
            }
          }
        } else {
          // Handle the case where response is null or undefined
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const sendEditStep = async (api_input) => {
    await editStepInrecording(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sendAddStep = async (api_input) => {
    await addStepInrecording(api_input)
      .then((response) => {
        if (response.status === 200) {
          // return ErrorModalOpen({ errorMessage: 'system admin network' });
          // <ErrorModalOpen errorMessage={'Something went wrong, please contact the administrator'}/>

          // ErrorModalOpen('Something went wrong, please contact the administrator')
          setErrorModalVisible(true); // Show the error modal
          setErrorMessage("Something went wrong, please contact the administrator");

          if (response.data.message === "fail") {
            CommonMethods(navigate);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleButtonClick = () => {
    const newWindow = window.open(
      `/dashboard/ResultStepPage/${valuesSavedResult[indexValueChange]?.idtest_step_result}`,
      "_blank",
    );
  };
  return (
    <>
      <BootstrapDialog
        className="custom-dialog"
        onClose={() => {
          handleClose();
          editValueNegative();
        }}
        aria-labelledby="customized-dialog"
        open={true}
        sx={{
          marginTop: 5,
          width: 10 / 10,
          height: 9 / 10,
        }}
      >
        <div className="alinging-textbox-two">
          <BootstrapDialogTitle
            id="customized-dialog"
            onClose={() => {
              handleClose();
              editValueNegative();
            }}
            sx={{
              width: 10 / 10,
            }}
          >
            <span className="main-heading">ADD/EDIT STEP</span>
            <a className="title-link" href={valuesSavedResult[0]?.logFile} target="_blank">
              Log File
            </a>
            {/* <ColorButton
            size="small"
            variant="contained"
            onClick={(event) => {
              event.preventDefault();
              modalExecute({
                tcid : newTestStep.Test_Case_Id,
                stepNum: editValue === true ? newTestStep?.Step_Number : valuesSavedResult[indexValueChange]?.Step_Number,
                nextNum: editValue === true ? newTestStep?.Step_Number+1 ? newTestStep?.Step_Number+1 : newTestStep?.Step_Number : valuesSavedResult[indexValueChange+1]?.Step_Number ? valuesSavedResult[indexValueChange+1]?.Step_Number : valuesSavedResult[indexValueChange].Step_Number,
                tcname: testCaseName,
                envUrl: valuesSavedResult[0]?.TestData ? encodeURIComponent(valuesSavedResult[0]?.TestData) : '',
              });
            }}
            sx={{ marginLeft: 5 }}
            disabled={checkModuleEnable === 1 ? true : false}
          >
            Co Pilot
          </ColorButton> */}
            <ColorButton
              onClick={handleButtonClick}
              disabled={
                checkModuleEnable === 1 ? true : false || editValue === false ? false : true
              }
              sx={{ marginLeft: 4 }}
            >
              Step History
            </ColorButton>
            <TextField
              className="header-textfield"
              label="Page Name"
              variant="filled"
              // defaultValue={valuesSaved[indexValueChange]?.pageName}
              value={
                editValue === true
                  ? newTestStep?.Page_Name
                  : valuesSavedResult[indexValueChange]?.Page_Name
              }
              // value={newTestStep?.Page_Name}
              style={{
                width: 400,
                backgroundColor: colors?.secondaryColor,
              }}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => {
                // event.preventDefault();
                setNewTestStep((prev) => {
                  return {
                    ...prev,
                    Page_Name: event.target.value,
                  };
                });
              }}
              disabled={editValue === true ? false : true}
            />
            {editValue === true ? (
              <ColorButton
                size="small"
                onClick={() => {
                  editValueNegative();
                }}
                disabled={indexValueChange <= valuesSavedResult.length - 1 ? false : true}
                variant="contained"
              >
                View Results
              </ColorButton>
            ) : (
              <ColorButton
                onClick={() => {
                  editValuePositive();
                }}
              >
                Edit
              </ColorButton>
            )}
          </BootstrapDialogTitle>
        </div>
        <DialogContent dividers className="dialog-content">
          {/* {
          valuesSavedResult[indexValueChange]?.Failure_Screenshot_Location === null || valuesSavedResult[indexValueChange]?.Failure_Screenshot_Location === "" || valuesSavedResult[indexValueChange]?.Failure_Screenshot_Location === undefined || newTestStep?.screenshot === "" ?
            <div className="image-box">
              <span className="image-text">Screenshot not available</span>
            </div> :
            <img className="image" src={valuesSavedResult[indexValueChange]?.Failure_Screenshot_Location} width="100%" height="80%" alt="Image not Loading" />
        } */}
          {/* {editValue === true ?
          newTestStep?.screenshot === undefined ?
            <div className="image-box">
              <span className="image-text">Screenshot not available</span>
            </div> :
            <img className="image" src={newTestStep?.screenshot} width="100%" height="80%" alt="Image not Loading" /> :
            valuesSavedResult[indexValueChange]?.Failure_Screenshot_Location === null || valuesSavedResult[indexValueChange]?.Failure_Screenshot_Location === "" || valuesSavedResult[indexValueChange]?.Failure_Screenshot_Location === undefined ?
            <div className="image-box">
              <span className="image-text">Screenshot not available</span>
            </div> :
            <img className="image" src={valuesSavedResult[indexValueChange]?.Failure_Screenshot_Location} width="100%" height="80%" alt="Image not Loading" />
        } */}
          {
            <MyCarousel
              firstImage={valuesSavedResult[indexValueChange]?.screenshot1}
              secondImage={valuesSavedResult[indexValueChange]?.Failure_Screenshot_Location}
              indexValueChanges={indexValueChange}
            />
          }
          {editValue === true ? (
            <div className="alinging-textbox-for-edit">
              <div className="edit-all-select">
                <div className="edit-select-addStep">
                  <InputLabel>Add After Step</InputLabel>
                  <Select
                    style={{ backgroundColor: colors?.tertiaryColor }}
                    // className="textfield"
                    // label="Step Number"
                    variant="filled"
                    fullWidth
                    value={newTestStep?.Step_Number === 0 ? 0 : newTestStep?.Step_Number || ""}
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) => {
                      setNewTestStep((prev) => {
                        return {
                          ...prev,
                          Step_Number: event.target.value,
                        };
                      });
                    }}
                  >
                    {state.stepnums.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item}
                        selected={item === newTestStep.Step_Number}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="edit-select-keyword">
                  <InputLabel>Keyword</InputLabel>
                  <Select
                    style={{ backgroundColor: colors?.tertiaryColor }}
                    // style={{ backgroundColor: '#D3DDE6' }}
                    // className="textfield"
                    // label="Keyword"
                    variant="filled"
                    fullWidth
                    //defaultValue={newTestStep?.keyword}
                    value={newTestStep?.Keyword || ""}
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) => {
                      // event.preventDefault();
                      setNewTestStep((prev) => {
                        return {
                          ...prev,
                          Keyword: event.target.value,
                          Action:
                            event.target.value === "DropDown"
                              ? "Select"
                              : event.target.value === "Send"
                                ? "Email"
                                : null,
                        };
                      });
                    }}
                  >
                    {KeywordData.map((item, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>

                {
                  <div className="edit-select-action">
                    <InputLabel>Action</InputLabel>
                    {newTestStep?.Keyword === "DropDown" ? (
                      <Select
                        style={{ backgroundColor: colors?.tertiaryColor }}
                        variant="filled"
                        fullWidth
                        value={"Select"}
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => {
                          event.preventDefault();
                          setNewTestStep((prev) => ({
                            ...prev,
                            Action: event.target.value,
                          }));
                        }}
                      >
                        {ActionData.map(
                          (item, index) =>
                            item.value === "Select" && (
                              <MenuItem key={index} value={item.value}>
                                {item.name}
                              </MenuItem>
                            ),
                        )}
                      </Select>
                    ) : newTestStep?.Keyword === "Send" ? (
                      <Select
                        style={{ backgroundColor: colors?.tertiaryColor }}
                        variant="filled"
                        fullWidth
                        value={"Email"}
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => {
                          event.preventDefault();
                          setNewTestStep((prev) => ({
                            ...prev,
                            Action: event.target.value,
                          }));
                        }}
                      >
                        {ActionData.map(
                          (item, index) =>
                            item.value === "Email" && (
                              <MenuItem key={index} value={item.value}>
                                {item.name}
                              </MenuItem>
                            ),
                        )}
                      </Select>
                    ) : (
                      <Select
                        style={{ backgroundColor: colors?.tertiaryColor }}
                        variant="filled"
                        fullWidth
                        value={newTestStep?.Action || ""}
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => {
                          event.preventDefault();
                          setNewTestStep((prev) => ({
                            ...prev,
                            Action: event.target.value,
                          }));
                        }}
                      >
                        {ActionData.map((item, index) => (
                          <MenuItem key={index} value={item.value}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </div>
                }

                {newTestStep?.Action === "Select" && (
                  <div className="edit-select-Options">
                    <InputLabel>Options</InputLabel>
                    <Select
                      style={{
                        backgroundColor: colors?.tertiaryColor,
                      }}
                      // className="textfield"
                      // label="Action"
                      variant="filled"
                      fullWidth
                      // defaultValue={valuesSaved[indexValueChange]?.action}
                      value={newTestStep?.subAction || ""}
                      InputLabelProps={{ shrink: true }}
                      onChange={(event) => {
                        event.preventDefault();
                        setNewTestStep((prev) => {
                          return {
                            ...prev,
                            subAction: event.target.value,
                          };
                        });
                      }}
                    >
                      <MenuItem value="ById">By Index</MenuItem>
                      <MenuItem value="ByText">By Text</MenuItem>
                      <MenuItem value="ByValue">By Value</MenuItem>
                    </Select>
                  </div>
                )}
                {newTestStep?.Action === "Checkbox" && (
                  <div className="edit-select-Options">
                    <InputLabel>Options</InputLabel>
                    <Select
                      style={{ backgroundColor: colors?.tertiaryColor }}
                      // className="textfield"
                      // label="Action"
                      variant="filled"
                      fullWidth
                      // defaultValue={valuesSaved[indexValueChange]?.action}
                      value={newTestStep?.subAction || ""}
                      InputLabelProps={{ shrink: true }}
                      onChange={(event) => {
                        event.preventDefault();
                        setNewTestStep((prev) => {
                          return {
                            ...prev,
                            subAction: event.target.value,
                          };
                        });
                      }}
                    >
                      <MenuItem value="ById">By Id</MenuItem>
                      <MenuItem value="ByXpath">By Path</MenuItem>
                      <MenuItem value="ByText">By Text</MenuItem>
                    </Select>
                  </div>
                )}
                {(newTestStep?.Action === "validatePartial" ||
                  newTestStep?.Action === "Properties") && (
                  <div className="edit-select-Options">
                    <InputLabel>Options</InputLabel>
                    <Select
                      style={{ backgroundColor: colors?.tertiaryColor }}
                      // className="textfield"
                      // label="Action"
                      variant="filled"
                      fullWidth
                      // defaultValue={valuesSaved[indexValueChange]?.action}
                      value={newTestStep?.subAction || ""}
                      InputLabelProps={{ shrink: true }}
                      onChange={(event) => {
                        event.preventDefault();
                        setNewTestStep((prev) => {
                          return {
                            ...prev,
                            subAction: event.target.value,
                          };
                        });
                      }}
                    >
                      <MenuItem value="validateActive">Active</MenuItem>
                      <MenuItem value="validateChecked">Checked</MenuItem>
                      <MenuItem value="validateCount">Count</MenuItem>
                      <MenuItem value="validatePartial">Contains</MenuItem>
                      <MenuItem value="validateEditable">Editable</MenuItem>
                      <MenuItem value="validateExact">Exact</MenuItem>
                      <MenuItem value="validateFontFam">Font Family</MenuItem>
                      <MenuItem value="validateFontSize">Font Size</MenuItem>
                      <MenuItem value="validateHref">Href</MenuItem>
                      <MenuItem value="validateSrc">Image Src</MenuItem>
                      <MenuItem value="validateMulti">Multiple</MenuItem>
                      <MenuItem value="validatePageUrl">Page URL</MenuItem>
                    </Select>
                  </div>
                )}
                <div className="edit-select-flow">
                  <InputLabel>Flow</InputLabel>
                  <Select
                    style={{ backgroundColor: colors?.tertiaryColor }}
                    // className="textfield"
                    // label={editValue === true ? "Flow" : "Duration"}
                    variant="filled"
                    fullWidth
                    // defaultValue={editValue === true ? valuesSaved[indexValueChange]?.flow : valuesSaved[indexValueChange]?.duration}
                    value={newTestStep?.Flow || ""}
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) => {
                      event.preventDefault();
                      setNewTestStep((prev) => {
                        return {
                          ...prev,
                          Flow: event.target.value,
                        };
                      });
                    }}
                  >
                    <MenuItem value="Negative">Must fail</MenuItem>
                    <MenuItem value="Positive">Must pass</MenuItem>
                    <MenuItem value="Optional">Optional</MenuItem>
                  </Select>
                </div>
              </div>

              <div className="edit-all-textfield">
                <div className="edit-textfield-threshold">
                  <InputLabel>Step Threshold</InputLabel>
                  <TextField
                    style={{ backgroundColor: colors?.tertiaryColor }}
                    // className="textfield"
                    // label="Wait Time"
                    variant="filled"
                    fullWidth
                    // defaultValue={valuesSaved[indexValueChange]?.status}

                    value={newTestStep?.teststepthreshold}
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) => {
                      event.preventDefault();
                      setNewTestStep((prev) => {
                        return {
                          ...prev,
                          teststepthreshold: event.target.value,
                        };
                      });
                    }}
                  />
                </div>
                <div className="edit-textfield-Wait">
                  <InputLabel>Wait</InputLabel>
                  <Select
                    style={{ backgroundColor: colors?.tertiaryColor }}
                    // className="textfield"
                    // label={editValue === true ? "Flow" : "Duration"}
                    variant="filled"
                    fullWidth
                    // defaultValue={editValue === true ? valuesSaved[indexValueChange]?.flow : valuesSaved[indexValueChange]?.duration}
                    value={newTestStep?.wait || ""}
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) => {
                      event.preventDefault();
                      setNewTestStep((prev) => {
                        return {
                          ...prev,
                          wait: event.target.value,
                        };
                      });
                    }}
                  >
                    <MenuItem value="After">After</MenuItem>
                    <MenuItem value="Before">Before</MenuItem>
                    <MenuItem value="Check">Check</MenuItem>
                  </Select>
                  {/* <TextField 
                // className="textfield"
                  // label={editValue === true ? "Wait" : "Status"}
                  variant="filled"
                  fullWidth
                  // defaultValue={valuesSaved[indexValueChange]?.status}
                  value={newTestStep?.wait}
                  InputLabelProps={{ shrink: true }}
                  onChange={(event) => {
                    event.preventDefault();
                    setNewTestStep((prev) => {
                      return {
                        ...prev,
                        wait: event.target.value,
                      };
                    });
                  }}
                /> */}
                </div>
                <div className="edit-textfield-Waittime">
                  <InputLabel>Wait Time</InputLabel>
                  <TextField
                    style={{ backgroundColor: colors?.tertiaryColor }}
                    // className="textfield"
                    // label="Wait Time"
                    variant="filled"
                    fullWidth
                    // defaultValue={valuesSaved[indexValueChange]?.status}
                    value={newTestStep?.waittime}
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) => {
                      event.preventDefault();
                      setNewTestStep((prev) => {
                        return {
                          ...prev,
                          waittime: event.target.value,
                        };
                      });
                    }}
                  />
                </div>
                <div className="edit-textfield-pd">
                  <InputLabel>Page Description</InputLabel>
                  <TextField
                    style={{ backgroundColor: colors?.tertiaryColor }}
                    // className="textfield"
                    // label={editValue === true ? "Page Description" : "Validate In"}
                    variant="filled"
                    fullWidth
                    // defaultValue={editValue === true ? valuesSaved[indexValueChange]?.pageDescription : valuesSaved[indexValueChange]?.device}
                    value={newTestStep?.Page_Description}
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) => {
                      event.preventDefault();
                      setNewTestStep((prev) => {
                        return {
                          ...prev,
                          Page_Description: event.target.value,
                        };
                      });
                    }}
                  />
                </div>

                <div className="textfield-type">
                  <InputLabel>Type</InputLabel>
                  <TextField
                    style={{ backgroundColor: colors?.tertiaryColor }}
                    value={newTestStep?.type}
                    variant="filled"
                    fullWidth
                    onChange={(event) => {
                      event.preventDefault();
                      setNewTestStep((prev) => {
                        return {
                          ...prev,
                          type: event.target.value,
                        };
                      });
                    }}
                    disabled={editValue === true ? false : true}
                  />
                </div>

                <div className="edit-textfield-img">
                  <InputLabel>Color</InputLabel>
                  <TextField
                    style={{ backgroundColor: colors?.tertiaryColor }}
                    variant="filled"
                    fullWidth
                    value={newTestStep?.color}
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) => {
                      event.preventDefault();
                      setNewTestStep((prev) => {
                        return {
                          ...prev,
                        };
                      });
                    }}
                    disabled={editValue === true ? false : true}
                  />
                </div>

                <div className="edit-textfield-BG">
                  <InputLabel>Background Color</InputLabel>

                  <TextField
                    style={{ backgroundColor: colors?.tertiaryColor }}
                    variant="filled"
                    fullWidth
                    value={newTestStep?.bgcolor}
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) => {
                      event.preventDefault();
                      setNewTestStep((prev) => {
                        return {
                          ...prev,
                          // VarName: event.target.value,
                        };
                      });
                    }}
                    disabled={editValue === true ? false : true}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="alinging-textbox">
              <TextField
                className="textfield"
                style={{ backgroundColor: colors?.tertiaryColor }}
                label="Step Number"
                variant="filled"
                fullWidth
                value={valuesSavedResult[indexValueChange]?.Step_Number}
                InputLabelProps={{ shrink: true }}
                onChange={(event) => {
                  // event.preventDefault();
                  setNewTestStep((prev) => {
                    return {
                      ...prev,
                      Step_Number: event.target.value,
                    };
                  });
                }}
                disabled={editValue === true ? false : true}
              />
              <TextField
                className="textfield"
                style={{ backgroundColor: colors?.tertiaryColor }}
                label="Keyword"
                variant="filled"
                fullWidth
                //defaultValue={newTestStep?.keyword}
                value={valuesSavedResult[indexValueChange]?.Keyword}
                InputLabelProps={{ shrink: true }}
                onChange={(event) => {
                  // event.preventDefault();
                  setNewTestStep((prev) => {
                    return {
                      ...prev,
                      Keyword: event.target.value,
                    };
                  });
                }}
                disabled={editValue === true ? false : true}
              />
              <TextField
                className="textfield"
                style={{ backgroundColor: colors?.tertiaryColor }}
                label="Action"
                variant="filled"
                fullWidth
                // defaultValue={valuesSaved[indexValueChange]?.action}
                value={valuesSavedResult[indexValueChange]?.Action}
                InputLabelProps={{ shrink: true }}
                onChange={(event) => {
                  event.preventDefault();
                  setNewTestStep((prev) => {
                    return {
                      ...prev,
                      Action: event.target.value,
                    };
                  });
                }}
                disabled={editValue === true ? false : true}
              />
              <TextField
                className="textfield"
                style={{ backgroundColor: colors?.tertiaryColor }}
                label="Duration(s)"
                variant="filled"
                fullWidth
                // defaultValue={editValue === true ? valuesSaved[indexValueChange]?.flow : valuesSaved[indexValueChange]?.duration}
                value={valuesSavedResult[indexValueChange]?.test_step_duration / 1000}
                InputLabelProps={{ shrink: true }}
                onChange={(event) => {
                  event.preventDefault();
                  setNewTestStep((prev) => {
                    return {
                      ...prev,
                      Flow: event.target.value,
                    };
                  });
                }}
                disabled={editValue === true ? false : true}
              />
              <TextField
                className="textfield"
                style={{ backgroundColor: colors?.tertiaryColor }}
                label="Status"
                variant="filled"
                fullWidth
                // defaultValue={valuesSaved[indexValueChange]?.status}
                value={valuesSavedResult[indexValueChange]?.test_step_status}
                InputLabelProps={{ shrink: true }}
                onChange={(event) => {
                  event.preventDefault();
                  setNewTestStep((prev) => {
                    return {
                      ...prev,
                      wait: event.target.value,
                    };
                  });
                }}
                disabled={editValue === true ? false : true}
              />
              <TextField
                className="textfield"
                style={{ backgroundColor: colors?.tertiaryColor }}
                label="Validate In"
                variant="filled"
                fullWidth
                // defaultValue={editValue === true ? valuesSaved[indexValueChange]?.pageDescription : valuesSaved[indexValueChange]?.device}
                value={valuesSavedResult[indexValueChange]?.ValDevice}
                InputLabelProps={{ shrink: true }}
                onChange={(event) => {
                  event.preventDefault();
                  setNewTestStep((prev) => {
                    return {
                      ...prev,
                      Page_Description: event.target.value,
                    };
                  });
                }}
                disabled={editValue === true ? false : true}
              />
            </div>
          )}
          <div className="edit-all-secondrow">
            {/* <div className="textfield-OI">
        <InputLabel>Object Identifier</InputLabel>
          <TextField 
            style={{ backgroundColor:colors?.tertiaryColor
 }}
            variant="filled"
            fullWidth
            value={editValue === true ? newTestStep?.ObjectIdentifier : valuesSavedResult[indexValueChange]?.issues}
            InputLabelProps={{ shrink: true }}
            onChange={(event) => {
              event.preventDefault();
              setNewTestStep((prev) => {
                return {
                  ...prev,
                  ObjectIdentifier: event.target.value,
                };
              });
            }}
            disabled={editValue === true ? false : true}
          />
          </div> */}

            <div className="textfield-TD">
              <InputLabel>Test Data</InputLabel>
              <TextField
                style={{
                  backgroundColor: colors?.tertiaryColor,
                }}
                variant="filled"
                fullWidth
                value={
                  editValue === true
                    ? newTestStep?.TestData
                    : valuesSavedResult[indexValueChange]?.TestData
                }
                InputLabelProps={{ shrink: true }}
                onChange={(event) => {
                  event.preventDefault();
                  setNewTestStep((prev) => {
                    return {
                      ...prev,
                      TestData: event.target.value,
                    };
                  });
                }}
                disabled={editValue === true ? false : true}
              />
            </div>

            <div className="edit-textfield-var">
              <InputLabel>Variable</InputLabel>
              <TextField
                style={{ backgroundColor: colors?.tertiaryColor }}
                variant="filled"
                fullWidth
                value={newTestStep?.VarName}
                InputLabelProps={{ shrink: true }}
                onChange={(event) => {
                  event.preventDefault();
                  setNewTestStep((prev) => {
                    return {
                      ...prev,
                      VarName: event.target.value,
                    };
                  });
                }}
                disabled={editValue === true ? false : true}
              />
            </div>

            {editValue === true ? (
              <IconButton
                style={{ backgroundColor: "transparent" }}
                onClick={() => {
                  if (checkModuleEnable !== 1) {
                    setModalExpandValue((prev) => !prev);
                    setModalShow(false);
                    setJavascriptShow(false);
                    setCmdShow(false);
                  }
                }}
              >
                <ColorButton
                  style={{ marginTop: "2rem", marginRight: "0.5rem" }}
                  size="small"
                  variant="contained"
                  disabled={checkModuleEnable === 1 ? true : false}
                >
                  {newTestStep?.Keyword === "URL"
                    ? "ENVIRONMENT"
                    : newTestStep?.Keyword === "Assertion"
                      ? "Assertion Setup"
                      : "TestData Setup"}
                  <ExpandMoreIcon />
                </ColorButton>
              </IconButton>
            ) : null}
            {editValue === true ? (
              <IconButton
                style={{ backgroundColor: "transparent" }}
                onClick={() => {
                  if (checkModuleEnable !== 1) {
                    setModalShow((prev) => !prev);
                    setModalExpandValue(false);
                    setJavascriptShow(false);
                  }
                }}
              >
                <ColorButton
                  style={{ marginTop: "2rem", marginRight: "0.5rem" }}
                  size="small"
                  variant="contained"
                  disabled={checkModuleEnable === 1 ? true : false}
                >
                  Element Locator
                  <ExpandMoreIcon />
                </ColorButton>
              </IconButton>
            ) : null}
            {editValue === true && newTestStep?.Action === "Javascript" ? (
              <IconButton
                style={{ backgroundColor: "transparent" }}
                onClick={() => {
                  if (checkModuleEnable !== 1) {
                    setJavascriptShow((prev) => !prev);
                    setModalShow(false);
                    setModalExpandValue(false);
                  }
                }}
              >
                <ColorButton
                  style={{ marginTop: "2rem", marginRight: "0.5rem" }}
                  size="small"
                  variant="contained"
                  disabled={checkModuleEnable === 1 ? true : false}
                >
                  Scripts
                  <ExpandMoreIcon />
                </ColorButton>
              </IconButton>
            ) : null}

            {editValue === true && newTestStep?.Action === "CommandLine" ? (
              <IconButton
                style={{ backgroundColor: "transparent" }}
                onClick={() => {
                  if (checkModuleEnable !== 1) {
                    setCmdShow((prev) => !prev);
                    setJavascriptShow(false);
                    setModalShow(false);
                    setModalExpandValue(false);
                  }
                }}
              >
                <ColorButton
                  style={{ marginTop: "2rem", marginRight: "0.5rem" }}
                  size="small"
                  variant="contained"
                  disabled={checkModuleEnable === 1 ? true : false}
                >
                  CommandLine
                  <ExpandMoreIcon />
                </ColorButton>
              </IconButton>
            ) : null}
            
            <FormControl style={{ marginTop: "38px", marginLeft: "50px" }}>
              <FormControlLabel
                control={<Switch checked={toggleOn} onChange={handleToggle} />}
                label="Dynamic Value"
                disabled={editValue === true ? false : true}
              />
              </FormControl>
              
              <FormControl style={{ marginTop: "38px", marginLeft: "50px" }}>
              <FormControlLabel
                control={<Switch checked={toggleOnAlert} onChange={handleToggleAlert} />}
                label="Creates Alert"
                disabled={editValue === true ? false : true}
              />
            </FormControl>
          </div>

          <div className="modal-expand">
            {editValue === true
              ? modalExpandValue && (
                  <ModalExpand
                    test={test[indexValueChange]}
                    keywordValue={newTestStep?.Keyword}
                    handleClose={handleClose}
                    additionalTestCase={additionalTestCase}
                    productId={productId}
                    TestDetails={TestDataDetails}
                  />
                )
              : null}
          </div>

          <div className="element-locate">
            {editValue === true
              ? modalShow && (
                  <ElementLocate
                    setTestDataDetails={setTestDataDetails}
                    TestDataDetails={TestDataDetails}
                    editValue={editValue}
                    valuesSavedResult={valuesSavedResult}
                    indexValueChange={indexValueChange}
                    setNewTestStep={setNewTestStep}
                    setState={setState}
                    newTestStep={newTestStep}
                    handleClose={handleClose}
                  />
                )
              : null}
          </div>

          <div className="modal-expand">
            {editValue === true
              ? newTestStep?.Action === "Javascript" &&
                newTestStep?.Keyword === "Script" &&
                javascriptShow && (
                  <Script
                    setTestDataDetails={setTestDataDetails}
                    TestDataDetails={TestDataDetails}
                    handleClose={handleClose}
                    actionValue={newTestStep?.Action}
                    newTestStep={newTestStep}
                    setNewTestStep={setNewTestStep}
                  />
                )
              : null}
          </div>

          <div className="command-line">
            {cmdValue === true
              ? newTestStep?.Action === "CommandLine" &&
                newTestStep?.Keyword === "Script" &&
                cmdShow && (
                  <CommandLine
                    setTestDataDetails={setTestDataDetails}
                    TestDataDetails={TestDataDetails}
                    handleClose={handleClose}
                    actionValue={newTestStep?.Action}
                    newTestStep={newTestStep}
                    setNewTestStep={setNewTestStep}
                  />
                )
              : null}
          </div>

          <div className="align-iframe-tabid">
            <div className="textfield-iframe">
              <InputLabel>Iframexpath</InputLabel>
              <TextField
                style={{ backgroundColor: colors?.tertiaryColor }}
                value={newTestStep?.iframexpath}
                InputLabelProps={{ shrink: true }}
                variant="filled"
                fullWidth
                onChange={(event) => {
                  event.preventDefault();
                  setNewTestStep((prev) => {
                    return {
                      ...prev,
                      iframexpath: event.target.value,
                    };
                  });
                }}
                disabled={editValue === true ? false : true}
              />
            </div>

            <div className="textfield-IP">
              <InputLabel>Image Src</InputLabel>
              <TextField
                style={{ backgroundColor: colors?.tertiaryColor }}
                value={newTestStep?.imgpath}
                variant="filled"
                fullWidth
                onChange={(event) => {
                  event.preventDefault();
                  setNewTestStep((prev) => {
                    return {
                      ...prev,
                      // iframexpath: event.target.value,
                    };
                  });
                }}
                disabled={editValue === true ? false : true}
              />
            </div>

            <div className="edit-select-tabs">
              <InputLabel>Tabs</InputLabel>
              <Select
                style={{ backgroundColor: colors?.tertiaryColor }}
                fullWidth
                value={newTestStep?.TabId === 0 ? 0 : newTestStep?.TabId || ""}
                InputLabelProps={{ shrink: true }}
                onChange={(event) => {
                  setNewTestStep((prev) => {
                    return {
                      ...prev,
                      TabId: event.target.value,
                    };
                  });
                }}
                disabled={editValue === true ? false : true}
              >
                {state.tabsvalues.map((item, index) => (
                  <MenuItem key={index} value={item.tabId}>
                    {item.tabName}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div>
            <ColorButton
              size="small"
              autoFocus
              variant="contained"
              onClick={() => {
                sendAddStep({
                  Keyword: newTestStep.Keyword,
                  Action: newTestStep.Action,
                  Flow: newTestStep.Flow,
                  Page_Description: newTestStep.Page_Description,
                  Page_Name: newTestStep.Page_Name,
                  TestData: newTestStep.TestData,
                  Test_Case_Id: newTestStep.Test_Case_Id,
                  Test_Step_Id: newTestStep.tsid,
                  ObjectIdentifier: newTestStep.ObjectIdentifier,
                  stepnumber: newTestStep.Step_Number,
                  wait: newTestStep.wait,
                  waittime: newTestStep.waittime,
                  subAction: newTestStep.subAction,
                  teststepthreshold: newTestStep.teststepthreshold,
                  iframexpath: newTestStep.iframexpath,
                  tabid: newTestStep.tabid,
                  VarName: newTestStep.VarName,
                  type: newTestStep.type,
                  tabid: newTestStep.TabId,
                  isDynamic: toggleOn ? 1 : 0,
                  createsAlert: toggleOnAlert ? 1:0,
                });

                handleClose("Add Step");
                editValueNegative();
              }}
              disabled={checkModuleEnable === 1 ? true : false || editValue === true ? false : true}
            >
              Add step
            </ColorButton>
          </div>
          <ColorButton
            size="small"
            autoFocus
            variant="contained"
            onClick={() => {
              sendEditStep({
                Keyword: newTestStep.Keyword,
                Action: newTestStep.Action,
                Flow: newTestStep.Flow,
                Page_Description: newTestStep.Page_Description,
                Page_Name: newTestStep.Page_Name,
                TestData: newTestStep.TestData,
                Test_Case_Id: newTestStep.Test_Case_Id,
                ObjectIdentifier: newTestStep.ObjectIdentifier,
                tsid: newTestStep.tsid,
                wait: newTestStep.wait,
                waittime: newTestStep.waittime,
                subAction: newTestStep.subAction,
                teststepthreshold: newTestStep.teststepthreshold,
                iframexpath: newTestStep.iframexpath,
                tabid: newTestStep.tabid,
                VarName: newTestStep.VarName,
                type: newTestStep.type,
                tabid: newTestStep.TabId,
                isDynamic: toggleOn ? 1 : 0,
                createsAlert: toggleOnAlert ? 1 : 0,
              });
              handleClose("Save");
              editValueNegative();
            }}
            disabled={checkModuleEnable === 1 ? true : false || editValue === true ? false : true}
          >
            Save step
          </ColorButton>
          <ColorButton
            size="small"
            autoFocus
            variant="contained"
            onClick={() => {
              handleClose("Delete", newTestStep.tsid, newTestStep.Test_Case_Id);
              editValueNegative();
            }}
            disabled={checkModuleEnable === 1 ? true : false || editValue === true ? false : true}
          >
            Delete Step
          </ColorButton>
          <ColorButton
            size="small"
            variant="contained"
            onClick={() => {
              decrementCount();
            }}
            disabled={
              editValue === true
                ? indexValueChange === 0
                  ? true
                  : false
                : indexValueChange === 0
                  ? true
                  : false
            }
            // disabled={indexValueChange === 0}
          >
            Previous
          </ColorButton>

          <ColorButton
            size="small"
            variant="contained"
            onClick={() => {
              incrementCount();
            }}
            disabled={
              editValue === true
                ? indexValueChange === valueSaved.length - 1
                  ? true
                  : false
                : indexValueChange === valuesSavedResult.length - 1
                  ? true
                  : false
            }
            // disabled={indexValueChange === valueSaved.length - 1}
          >
            next
          </ColorButton>
          <Button
            autoFocus
            onClick={() => {
              handleClose();
              editValueNegative();
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};
export default EditModal;
