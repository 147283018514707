import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

const postCapability = async (data) => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found");
  const user = JSON.parse(userDetails);

  const response = await axios.post(
    `${API_BASE_PATH}/KeyLogging/api/mobile-capabilities/company`,
    data,
  );
  return response.data;
};

const usePostCapabilityQuery = () => {
  return useMutation({
    mutationFn: postCapability,
    onError: (error) => {
      console.log("Error ", error.message);
    },
  });
};

export default usePostCapabilityQuery;
