import React from "react";

const ChangeToAssertIcon = () => (
  <svg width="309" height="336" viewBox="0 0 309 336" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M34.8325 314C25.2536 314 17.0534 310.584 10.2321 303.751C3.41068 296.919 0 288.706 0 279.111V34.8889C0 25.2944 3.41068 17.081 10.2321 10.2486C17.0534 3.4162 25.2536 0 34.8325 0H156.746L261.244 104.667V157.436H226.411V122.111H139.33V34.8889H34.8325V279.111H139.33V314H34.8325Z" fill="#242F3F"/>
  <path d="M231.5 181C188.72 181 154 215.72 154 258.5C154 301.28 188.72 336 231.5 336C274.28 336 309 301.28 309 258.5C309 215.72 274.28 181 231.5 181ZM231.5 320.5C197.323 320.5 169.5 292.677 169.5 258.5C169.5 224.323 197.323 196.5 231.5 196.5C265.677 196.5 293.5 224.323 293.5 258.5C293.5 292.677 265.677 320.5 231.5 320.5ZM267.073 224.245L216 275.318L195.927 255.323L185 266.25L216 297.25L278 235.25L267.073 224.245Z" fill="#242F3F"/>
  </svg>
);

export default ChangeToAssertIcon;
