import React from "react";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Rectangle,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import {
  InsertDriveFile,
  Share,
  GetApp,
  Chat,
  InsertChart,
  NotificationsActive,
  Search,
} from "@mui/icons-material";

const sprintDefectData = [
  { sprint: "Sprint 1", density: 45 },
  { sprint: "Sprint 2", density: 30 },
  { sprint: "Sprint 3", density: 65 },
  { sprint: "Sprint 4", density: 40 },
  { sprint: "Sprint 5", density: 55 },
  { sprint: "Sprint 6", density: 35 },
  { sprint: "Sprint 7", density: 50 },
];

const defectAgingData = [
  { severity: "0-5", critical: 10, high: 25, medium: 15, low: 35 },
  { severity: "6-10", critical: 5, high: 15, medium: 8, low: 20 },
  { severity: "11+", critical: 8, high: 18, medium: 12, low: 25 },
];

const ragData = [
  { name: "Red", value: 1 },
  { name: "Amber", value: 2 },
  { name: "Green", value: 3 },
];

const RAG_COLORS = ["#FF4D4D", "#FFA500", "#4CAF50"];

const navigationItems = [
  { label: "File", icon: <InsertDriveFile className="w-5 h-5" /> },
  { label: "Share", icon: <Share className="w-5 h-5" /> },
  { label: "Export", icon: <GetApp className="w-5 h-5" /> },
  { label: "Chat In Teams", icon: <Chat className="w-5 h-5" /> },
  { label: "Get Insights", icon: <InsertChart className="w-5 h-5" /> },
  { label: "Subscribe To Report", icon: <NotificationsActive className="w-5 h-5" /> },
];

const metrics = [
  { label: "Total Executable", value: "59585" },
  { label: "Total Executed", value: "89%" },
  { label: "Total Pass", value: "88%" },
  { label: "Total Fail", value: "1%" },
  { label: "Total Blocked", value: "3%" },
  { label: "Total NoRun", value: "9%" },
  { label: "Execution Velocity", value: "88%" },
];

const defectMetrics = [
  { label: "Total Defects", value: "6119" },
  { label: "Total Open Defects", value: "751" },
  { label: "Open Defects High & Official", value: "201" },
  { label: "Defect Density", value: "12%" },
  { label: "ReOpen Ratio", value: "32%" },
];

const RAGView = ({ index }) => {
  return (
    <div className="bg-white rounded-lg border border-gray-200 p-2">
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-sm font-semibold">
          {index === 3 ? "SprintWise RAG" : "ProjectWise RAG"}
        </h2>
        <div className="relative w-24">
          <input
            type="text"
            placeholder="Search"
            className="w-full px-2 py-1 text-xs border rounded"
          />
          <Search className="absolute right-1 top-1 w-4 h-4 text-gray-400" />
        </div>
      </div>

      <div className="space-y-1 mb-2">
        {["CIR", "Intuition", "ODO"].map((item) => (
          <button
            key={item}
            className="w-full text-left px-2 py-1 text-xs border rounded hover:bg-gray-50"
          >
            {item}
          </button>
        ))}
      </div>

      <div className="h-20">
        {index === 3 ? (
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={[
                { name: "Green", value: 11, color: "#4CAF50" },
                { name: "Amber", value: 5, color: "#FFA500" },
                { name: "Red", value: 4, color: "#FF4D4D" },
              ]}
              layout="horizontal"
              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            >
              <XAxis type="number" hide />
              <YAxis dataKey="name" type="category" tick={{ fontSize: 10 }} />
              <Bar dataKey="value">
                {ragData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={RAG_COLORS[index]} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <PieChart margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
              <Pie
                data={ragData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={25}
                innerRadius={15}
              >
                {ragData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={RAG_COLORS[index]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

const DefectAgingChart = () => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        data={defectAgingData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="severity" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar
          dataKey="critical"
          fill="#FF4D4D"
          name="Critical"
          activeBar={<Rectangle fill="#FF8080" stroke="#FF4D4D" />}
        />
        <Bar
          dataKey="high"
          fill="#2196F3"
          name="High"
          activeBar={<Rectangle fill="#64B5F6" stroke="#2196F3" />}
        />
        <Bar
          dataKey="medium"
          fill="#4CAF50"
          name="Medium"
          activeBar={<Rectangle fill="#81C784" stroke="#4CAF50" />}
        />
        <Bar
          dataKey="low"
          fill="#FFC107"
          name="Low"
          activeBar={<Rectangle fill="#FFD54F" stroke="#FFC107" />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

const MetricCard = ({ label, value }) => (
  <div className="text-center border border-gray-200 hover:border-black rounded-lg p-2 transition-all duration-200">
    <div className="text-xl font-bold text-black mb-1">{value}</div>
    <div className="text-sm text-gray-600">{label}</div>
  </div>
);

const Navigation = () => (
  <div className="flex flex-wrap gap-2 mb-8 items-center ">
    <div className="bg-black py-2 px-4 text-white font-bold text-lg rounded-lg mr-20">
      <h1>Project Level Dashboard</h1>
    </div>
    {navigationItems.map((item) => (
      <button
        key={item.label}
        className="flex items-center gap-2 px-4 py-2 bg-white rounded-lg shadow-sm hover:shadow-md transition-all duration-200 border border-gray-200 text-black hover:bg-black hover:text-white group"
      >
        <span className="transition-colors">{item.icon}</span>
        <span className="text-sm font-medium transition-colors">{item.label}</span>
      </button>
    ))}
  </div>
);

const ProjectDashboard = () => {
  return (
    <div className="min-h-screen bg-white p-6">
      {/* Header */}
      {/* <div className="bg-black text-white p-6 rounded-lg mb-6">
        <h1 className="text-2xl font-bold text-center">Project Level Dashboard</h1>
      </div> */}

      {/* Navigation */}
      <Navigation />

      {/* Main Content */}
      <div className="flex gap-8 mb-6">
        {/* RAG Views */}
        <div className="w-1/3 grid grid-cols-2 gap-2 max-h-48">
          {[...Array(4)].map((_, index) => (
            <RAGView key={index} index={index} />
          ))}
        </div>

        {/* Metrics and Charts */}
        <div className="w-2/3 space-y-4">
          <div className="bg-white rounded-lg border border-gray-200 p-4">
            <div className="grid grid-cols-7 gap-4 mb-6">
              {metrics.map((metric) => (
                <MetricCard key={metric.label} {...metric} />
              ))}
            </div>

            <div className="grid grid-cols-5 gap-4">
              {defectMetrics.map((metric) => (
                <MetricCard key={metric.label} {...metric} />
              ))}
            </div>
          </div>

          <div className="bg-white rounded-lg border border-gray-200 p-4">
            <h2 className="text-lg font-bold mb-4">Open Defects Aging By Severity</h2>
            <DefectAgingChart />
          </div>
        </div>
      </div>

      {/* Bottom Charts */}
      <div className="grid grid-cols-2 gap-6">
        {[1, 2].map((index) => (
          <div key={index} className="bg-white rounded-lg border border-gray-200 p-4">
            <h2 className="text-lg font-bold mb-4">
              ProjectWide Defect Density % - [Total Defects]/[Total Executed Test Cases]
            </h2>
            <ResponsiveContainer width="100%" height={200}>
              <LineChart data={sprintDefectData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="sprint" />
                <YAxis />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="density"
                  stroke="#2196F3"
                  dot={{ fill: "#2196F3" }}
                  strokeWidth={2}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectDashboard;
