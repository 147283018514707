import axios from "axios";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

let Tid;
let Tvalue;

const fetchRenameSuite = async () => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");
  const user = JSON.parse(userDetails);

  const response = await axios.get(
    `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=editTestSuites&companyid=${user.companyid}&token=${user.token}&idtest_suite=${Tid}&newName=${Tvalue}`,
  );

  return response.data;
};

const useReNameTestSuite = () => {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: ["renameTestSuiteUp"],
    queryFn: () => {
      return fetchRenameSuite(Tid, Tvalue);
    },
    enabled: false,
    retry: true,
    cacheTime: 90000,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    staleTime: Infinity,
  });

  const refetchWithParams = (id, value) => {
    Tvalue = value;
    Tid = id;
    query.refetch().finally(() => {
      queryClient.invalidateQueries(["testSuiteNew"]);
    });
  };

  return {
    ...query,
    refetchWithParams,
  };
};

export default useReNameTestSuite;
