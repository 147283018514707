import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

let params;

const generateTC = async () => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");
  const user = JSON.parse(userDetails);

  const response = await axios.get(
    `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=generateTC&companyid=${user.companyid}&token=${user.token}`,
    {
      params: params,
    },
  );

  return response.data;
};

const useBotGenerateTC = () => {
  const query = useQuery({
    queryKey: ["generateBotTC"],
    queryFn: () => {
      return generateTC(params);
    },
    enabled: false,
    retry: true,
    refetchInterval: false,
    refetchIntervalInBackground: false,
  });

  const refetchWithParams = (queryParams) => {
    params = queryParams;
    query.refetch();
  };

  return {
    ...query,
    refetchWithParams,
  };
};

export default useBotGenerateTC;
