import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_BASE_PATH } from "../Config/Config";
import axios from "axios";

const executeMobileTC = async ({ Test_Case_Id }) => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");

  const user = JSON.parse(userDetails);
  const token = user?.token;

  try {
    const response = await axios.post(
      `${API_BASE_PATH}/KeyLogging/execute?token=${token}&Test_Case_Id=${Test_Case_Id}`,
    );
    return response;
  } catch (error) {
    console.error("Failed to execute test case:", error);
    throw error;
  }
};

const useExecuteMobileTCQuery = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: executeMobileTC,
    onSuccess: () => {
      queryClient.invalidateQueries([]);
    },
    onError: (error) => {
      console.error("Error executing test case:", error.message);
    },
  });
};

export default useExecuteMobileTCQuery;
