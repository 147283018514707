// import axios from "axios";
// import { useQuery } from "@tanstack/react-query";
// import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

// const fetchPomFiles = async (id) => {
//   const userDetails = localStorage.getItem("userDetail");
//   if (!userDetails) throw new Error("No user details found in localStorage");
//   const user = JSON.parse(userDetails);

//   try {
//     const response = await axios.get(
//       `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=gpcTesting&companyid=${user.companyid}&token=${user.token}&pid=${id}`,
//       { responseType: "blob" },
//     );
//     if (
//       response.headers["content-type"] &&
//       !response.headers["content-type"].includes("zip") &&
//       response.data.size < 100
//     ) {
//       throw new Error("Server did not return a valid ZIP file");
//     }

//     const url = window.URL.createObjectURL(response.data);
//     const link = document.createElement("a");
//     link.href = url;
//     link.setAttribute("download", `pom_files_${id}.zip`);
//     document.body.appendChild(link);
//     link.click();

//     setTimeout(() => {
//       link.remove();
//       window.URL.revokeObjectURL(url);
//     }, 100);

//     return "Download initiated";
//   } catch (error) {
//     console.error("Download failed:", error);
//     throw error;
//   }
// };

// const useDownloadPomFilesQuery = (id) => {
//   return useQuery({
//     queryKey: ["downloadpom", id],
//     queryFn: () => fetchPomFiles(id),
//     retry: true,
//     enabled: false,
//     cacheTime: 90000,
//     refetchInterval: false,
//     refetchIntervalInBackground: false,
//     staleTime: Infinity,
//   });
// };

// export default useDownloadPomFilesQuery;

import { useQuery } from "@tanstack/react-query";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

const initiateFileDownload = async (id) => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");
  const user = JSON.parse(userDetails);

  const downloadUrl = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=gpcTesting&companyid=${user.companyid}&token=${user.token}&pid=${id}`;

  window.location.href = downloadUrl;

  return "Download initiated";
};

const useDownloadPomFilesQuery = (id) => {
  return useQuery({
    queryKey: ["downloadpom", id],
    queryFn: () => initiateFileDownload(id),
    retry: true,
    enabled: false,
    cacheTime: 90000,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    staleTime: Infinity,
  });
};

export default useDownloadPomFilesQuery;
