import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";
import { useNotification } from "../hooks";

const editExitCriteria = async (reasonText) => {
  const userDetail = localStorage.getItem("userDetail");
  if (!userDetail) throw new Error("No user details found in localStorage");

  const user = JSON.parse(userDetail);

  const response = await axios.post(
    `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=editExitCriteria&companyid=${user.companyid}&token=${user.token}`,
    {
      reasonText,
    },
  );

  return response.data;
};

const useEditExitCriteria = () => {
  const notify = useNotification();
  return useMutation({
    mutationFn: editExitCriteria,
    onSuccess: () => {
      notify("Exit criteria updated successfully!", "success");
    },
    onError: () => {
      notify("Failed to update exit criteria. Please try again.", "error");
    },
  });
};

export default useEditExitCriteria;
