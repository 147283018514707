import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const SynopContainer = styled(Box)`
  padding: 30px 25px;
  margin-bottom: 40px;
  background-color: white;
  border-radius: 0px 0px 12px 12px;
  box-shadow: 0px 24px 23.3px 0px rgba(0, 0, 0, 0.03);
`;

const CardHeader = styled(Typography)`
  color: #000000;
  font-size: 16px;
  font-weight: 500;
`;

const ItemRow = styled(Box)`
  display: flex;
  margin-bottom: 20px;
  gap: 35px;
`;

const Item = styled(Box)`
  display: flex;
  flex-direction: column;
  row-gap: 3px;
`;

const ItemHeader = styled(Typography)`
  color: #8089a8;
  font-size: 13px;
  font-weight: 500;
`;

const ItemValue = styled(Typography)`
  color: #2b3539;
  font-size: 16px;
  font-weight: 400;
`;

const MobileSynopsis = ({ valuesSavedResult, fromExcReports, currentEditItem }) => {
  const [item, setItem] = useState({});
  const authData = useSelector((state) => state.authoringSlice.editStep);
  const [view, setView] = useState("card");

  useEffect(() => {
    if (fromExcReports) {
      setItem(currentEditItem);
    } else setItem(authData);
  }, [authData, currentEditItem, fromExcReports]);

  // Helper function to parse JSON safely
  const parseJSON = (jsonString) => {
    try {
      return JSON.parse(jsonString);
    } catch {
      return null;
    }
  };

  return (
    <Box>
      <SynopContainer>
        <Box mb="20px">
          <CardHeader>Test Data Configuration</CardHeader>
        </Box>
        <Box>
          <Box display="flex" mb="25px" columnGap={2}>
            <Item>
              <ItemHeader>Test Data</ItemHeader>
              <ItemValue>{item.test_data || "-"}</ItemValue>
            </Item>
            <Item>
              <ItemHeader>Test Data Source</ItemHeader>
              <ItemValue>{item.testdata_source || "As Recorded"}</ItemValue>
            </Item>
          </Box>
        </Box>
      </SynopContainer>

      <SynopContainer>
        <Box mb="20px">
          <CardHeader>Element Details</CardHeader>
        </Box>
        <ItemRow>
          <Item minWidth="126px">
            <ItemHeader>Element</ItemHeader>
            <ItemValue>{item.processedKeyword || item.Keyword}</ItemValue>
          </Item>
          <Item minWidth="126px">
            <ItemHeader>Action</ItemHeader>
            <ItemValue>{item.processedAction || item.Action}</ItemValue>
          </Item>
          <Item minWidth="126px">
            <ItemHeader>Step Number</ItemHeader>
            <ItemValue>{item.Step_Number}</ItemValue>
          </Item>
          <Item minWidth="126px">
            <ItemHeader>Wait</ItemHeader>
            <ItemValue>{item.wait || "-"}</ItemValue>
          </Item>
          <Item minWidth="126px">
            <ItemHeader>Wait Time</ItemHeader>
            <ItemValue>{item.waittime || "0"}</ItemValue>
          </Item>
          <Item minWidth="126px">
            <ItemHeader>Dynamic Processed</ItemHeader>
            <ItemValue>{item.dynamicProcessed ? "Yes" : "No"}</ItemValue>
          </Item>
        </ItemRow>
      </SynopContainer>

      <SynopContainer>
        <Box mb="20px">
          <CardHeader>Mobile Element Locators</CardHeader>
        </Box>
        <ItemRow>
          <Item minWidth="126px">
            <ItemHeader>Android UI Automator</ItemHeader>
            <ItemValue style={{ wordBreak: "break-word" }}>
              {item.android_uiautomator
                ? parseJSON(item.android_uiautomator)?.params?.selector || "-"
                : "-"}
            </ItemValue>
          </Item>
        </ItemRow>
        <ItemRow>
          <Item minWidth="126px">
            <ItemHeader>XPath</ItemHeader>
            <ItemValue style={{ wordBreak: "break-word" }}>
              {item.xpath ? parseJSON(item.xpath)?.params?.selector || "-" : "-"}
            </ItemValue>
          </Item>
        </ItemRow>
        <ItemRow>
          <Item minWidth="126px">
            <ItemHeader>Element ID</ItemHeader>
            <ItemValue>{item.element_id || "-"}</ItemValue>
          </Item>
          <Item minWidth="126px">
            <ItemHeader>Clickable</ItemHeader>
            <ItemValue>{item.clickable ? "Yes" : "No"}</ItemValue>
          </Item>
        </ItemRow>
      </SynopContainer>

      <SynopContainer>
        <Box mb="20px">
          <CardHeader>Timing Information</CardHeader>
        </Box>
        <ItemRow>
          <Item minWidth="126px">
            <ItemHeader>Timestamp</ItemHeader>
            <ItemValue>{item.timestamp || "-"}</ItemValue>
          </Item>
          <Item minWidth="126px">
            <ItemHeader>Appium Timestamp</ItemHeader>
            <ItemValue>{item.appium_timestamp || "-"}</ItemValue>
          </Item>
          <Item minWidth="126px">
            <ItemHeader>Actual Time</ItemHeader>
            <ItemValue>{item.actualtime || "0"}</ItemValue>
          </Item>
        </ItemRow>
      </SynopContainer>

      <SynopContainer>
        <Box display="flex" alignItems="center" columnGap={2}>
          <Box>
            <CardHeader>All Properties</CardHeader>
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setView(view === "table" ? "card" : "table")}
            >
              Switch to {view === "table" ? "Card" : "Table"} View
            </Button>
          </Box>
        </Box>
        {view === "table" ? (
          <Box my={4}>
            <Table component={Paper}>
              <TableHead>
                <TableRow>
                  <TableCell>Property</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(item)
                  .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                  .map(([key, value]) => {
                    let displayValue = value;
                    if (
                      typeof value === "string" &&
                      (value.startsWith("{") || value.startsWith("["))
                    ) {
                      try {
                        const parsed = JSON.parse(value);
                        displayValue = JSON.stringify(parsed, null, 2);
                      } catch {
                        displayValue = value;
                      }
                    }
                    return (
                      <TableRow key={key}>
                        <TableCell>{key}</TableCell>
                        <TableCell style={{ whiteSpace: "pre-wrap" }}>
                          {displayValue !== null && displayValue !== "" ? displayValue : "-"}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Box>
        ) : (
          <Box display="flex" flexWrap="wrap" rowGap={3} my={4}>
            {Object.entries(item)
              .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
              .map(([key, value]) => (
                <Box key={key} flexBasis="calc(25% - 10px)">
                  <Item>
                    <ItemHeader>{key}</ItemHeader>
                    <ItemValue style={{ wordBreak: "break-word" }}>
                      {value !== null && value !== "" ? value : "-"}
                    </ItemValue>
                  </Item>
                </Box>
              ))}
          </Box>
        )}
      </SynopContainer>
    </Box>
  );
};

export default MobileSynopsis;
