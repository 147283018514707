import React, { useState } from "react";
import {
  CloudArrowUpIcon,
  DocumentTextIcon,
  ChatBubbleBottomCenterTextIcon,
  XMarkIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";

const ContextModal = ({ isOpen, onClose, onFileUpload }) => {
  const [selectedOption, setSelectedOption] = useState("swagger");
  const [selectedFile, setSelectedFile] = useState(null);
  const [value, setValue] = useState("");

  if (!isOpen) return null;

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleAnalyze = () => {
    if (selectedOption === "swagger" && selectedFile) {
      // Create a synthetic event object to match the existing file upload handler
      const syntheticEvent = {
        target: {
          files: [selectedFile],
        },
      };
      onFileUpload(syntheticEvent);
      onClose();
      setSelectedFile(null);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl">
        {/* Header */}
        <div className="flex items-center justify-between p-4 border-b">
          <h2 className="text-lg font-semibold">Provide Context</h2>
          <button onClick={onClose} className="p-1 hover:bg-gray-100 rounded-full">
            <XMarkIcon className="h-5 w-5" />
          </button>
        </div>

        {/* Content */}
        <div className="p-6">
          <div className="space-y-4">
            {/* Options */}
            <div className="grid grid-cols-2 gap-4">
              <button
                onClick={() => setSelectedOption("jira")}
                className={`p-4 border rounded-lg flex items-center gap-3 transition-colors
                  ${"border-orange-500 bg-orange-50"}`}
                disabled={true}
              >
                <ArrowPathIcon className="h-6 w-6 text-gray-600" />
                <span className="text-gray-600">Import from Jira</span>
              </button>

              <button
                onClick={() => setSelectedOption("file")}
                className={`p-4 border rounded-lg flex items-center gap-3 transition-colors
                 ${"border-orange-500 bg-orange-50"}`}
                disabled={true}
              >
                <DocumentTextIcon className="h-6 w-6 text-gray-600" />
                <span className="text-gray-600">Upload a File</span>
              </button>

              <button
                onClick={() => setSelectedOption("swagger")}
                className={`p-4 border rounded-lg flex items-center gap-3 transition-colors
                 ${"border-orange-500 bg-orange-50"}`}
              >
                <CloudArrowUpIcon className="h-6 w-6 text-gray-600" />
                <span>Upload a Swagger file</span>
              </button>

              <button
                onClick={() => setSelectedOption("prompt")}
                className={`p-4 border rounded-lg flex items-center gap-3 transition-colors
                ${"border-orange-500 bg-orange-50"}`}
                disabled={false}
              >
                <ChatBubbleBottomCenterTextIcon className="h-6 w-6 text-gray-600" />
                <span className="text-gray-600">Describe Your Requirement</span>
              </button>
              {/* <input
                type="text"
                placeholder="Describe your requirement"
                className="p-4 border rounded-lg flex items-center gap-3 transition-colors
                border-orange-500 bg-orange-50"
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
              /> */}
              {/* <textarea
                type="text"
                placeholder="Describe your requirement"
                className="p-4 border rounded-lg flex items-center gap-3 transition-colors
                border-orange-500 bg-orange-50"
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
              /> */}
            </div>

            {/* Input area based on selection */}
            <div className="mt-6">
              {selectedOption === "swagger" && (
                <div
                  className={`border-2 border-dashed rounded-lg p-6 text-center
                  ${selectedFile ? "border-orange-500 bg-orange-50" : "border-gray-300"}`}
                >
                  <input
                    type="file"
                    className="hidden"
                    id="swagger-file"
                    onChange={handleFileSelect}
                    accept=".json,.yaml,.yml"
                  />
                  <label htmlFor="swagger-file" className="cursor-pointer text-gray-600">
                    <CloudArrowUpIcon className="h-12 w-12 mx-auto text-gray-400" />
                    {selectedFile ? (
                      <p className="mt-2">Selected file: {selectedFile.name}</p>
                    ) : (
                      <p className="mt-2">Drop your Swagger file here or click to browse</p>
                    )}
                  </label>
                </div>
              )}

              {selectedOption === "prompt" && (
                <div className="text-gray-500 text-center  border rounded-lg">
                  {/* Prompt feature coming soon... */}
                  {/* show a text field to enter prompt */}
                  <textarea
                    type="text"
                    placeholder="Describe your requirement"
                    className="p-4 border rounded-lg flex items-center gap-3 transition-colors
                    border-orange-500 bg-orange-50 w-full
                    p-6"
                    value={value}
                    onChange={(e) => {
                      setValue(e.target.value);
                    }}
                  />
                </div>
              )}

              {selectedOption === "jira" && (
                <div className="text-gray-500 text-center p-6 border rounded-lg">
                  Jira integration coming soon...
                </div>
              )}

              {selectedOption === "file" && (
                <div className="text-gray-500 text-center p-6 border rounded-lg">
                  File upload feature coming soon...
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="flex justify-end gap-3 p-4 border-t bg-gray-50">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-md transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={handleAnalyze}
            disabled={!(selectedOption === "swagger" && selectedFile)}
            className={`px-4 py-2 text-sm font-medium text-white rounded-md transition-colors focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2
              ${
                selectedOption === "swagger" && selectedFile
                  ? "bg-orange-500 hover:bg-orange-600"
                  : "bg-gray-300 cursor-not-allowed"
              }`}
          >
            Analyze
          </button>
        </div>
      </div>
    </div>
  );
};

const TopNav = ({ activeTab, onTabChange, onFileUpload }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleTabClick = (tabValue) => {
    if (onTabChange) {
      onTabChange({ target: { value: tabValue } });
    }
  };

  return (
    <>
      <div className="flex items-center justify-between px-4 py-2 bg-gray-50 border-b z-50">
        <div className="flex space-x-1">
          <button
            onClick={() => handleTabClick("mainTab")}
            className={`px-4 py-2 text-sm font-medium rounded-md transition-colors
              ${
                activeTab === "mainTab"
                  ? "bg-gray-300 text-gray-900 shadow-sm border border-gray-200"
                  : "text-gray-600 hover:text-gray-900 hover:bg-gray-100"
              }`}
          >
            Main tab
          </button>
          <button
            onClick={() => handleTabClick("testCaseTab")}
            className={`px-4 py-2 text-sm font-medium rounded-md transition-colors
              ${
                activeTab === "testCaseTab"
                  ? "bg-gray-300 text-gray-900 shadow-sm border border-gray-200"
                  : "text-gray-600 hover:text-gray-900 hover:bg-gray-100"
              }`}
          >
            Execution
          </button>
        </div>

        <div className="flex flex-row items-center justify-center gap-5">
          <button
            onClick={() => setIsModalOpen(true)}
            className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-orange-500 
            rounded-md hover:bg-orange-600 transition-colors focus:outline-none focus:ring-2 
            focus:ring-orange-500 focus:ring-offset-2 cursor-pointer"
          >
            <CloudUploadOutlinedIcon className="h-5 w-5" />
            Provide Context
          </button>

          <button
            className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-orange-500 
          rounded-md hover:bg-orange-600 transition-colors focus:outline-none focus:ring-2 
          focus:ring-orange-500 focus:ring-offset-2 cursor-pointer"
            onClick={() => {
              navigate("/new");
            }}
          >
            <ArrowCircleLeftOutlinedIcon className="h-4 w-4" />
            Back to dashboard
          </button>
        </div>
      </div>

      <ContextModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onFileUpload={onFileUpload}
      />
    </>
  );
};

export default TopNav;
