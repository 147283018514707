import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "./../../Config/Config";
import { styled } from "@mui/material/styles";
import { Box, Link } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { fetchLoginDetails } from "./../../API/UserApi";
import ChatIcon from "@mui/icons-material/Chat";
import BarChartRoundedIcon from "@mui/icons-material/BarChartRounded";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import GetAppIcon from "@mui/icons-material/GetApp";
import GitHubIcon from "@mui/icons-material/GitHub";
import VideocamRoundedIcon from "@mui/icons-material/VideocamRounded";
import CollectionsIcon from "@mui/icons-material/Collections";
import SdStorageIcon from "@mui/icons-material/SdStorage";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import PersonIcon from "@mui/icons-material/Person";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import SettingsIcon from "@mui/icons-material/Settings";
import ApiIcon from "@mui/icons-material/Api";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import { ChangeSideBar } from "./../../Redux/SiteSettings/SiteSettings";
import { Link as RouterLink } from "react-router-dom";
import SwitchAccessShortcutOutlinedIcon from "@mui/icons-material/SwitchAccessShortcutOutlined";

// import { ReactComponent as CTAIcon } from "./../../Assets/Vector.svg";

import { colorExport, BootstrapTooltip, ColorButton } from "../common/common.Component";

import { logout, fetchRecording } from "./../../API/RecordingTableAPI";

const drawerWidth = 248;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  // backgroundColor: SecondaryColor,
  // color: PrimaryColor,
  boxShadow: "none",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  backgroundColor: "white",
  color: "black",
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const navConfig = [
  {
    title: "Insights",
    path: "/dashboard/Reports",
    icon: <AutoGraphIcon />,
  },
  {
    title: "Results",
    path: "/dashboard/app",
    icon: <BarChartRoundedIcon />,
  },
  {
    title: "Test Authoring",
    path: "/dashboard/recording",
    icon: <VideocamRoundedIcon />,
  },

  //uncomment to show the create new test case

  // {
  //   title: "Create New Test Case",
  //   path: "/dashboard/createTestCase",
  //   icon: <AddCircleOutlineIcon />,
  // },

  {
    title: "Manage Test Suites",
    path: "/dashboard/newcreateCollection",
    icon: <CollectionsIcon />,
  },

  // {
  //   title: "Manage Test Data",
  //   path: "/dashboard/newcreateFiles",
  //   icon: <SdStorageIcon />,
  // }
  // {
  //   title: "User Experience",
  //   path: "/dashboard/userExperience",
  //   icon: <AspectRatioIcon />,
  // },
  // {
  //   title: "API",
  //   path: "/dashboard/Api",
  //   icon: <ApiIcon />,
  // }
];

const Navbar = () => {
  const colors = colorExport();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);

  const [open, setOpen] = useState(false);
  const [brandLogo, setBrandLogo] = useState("");

  const location = useLocation();
  const handleDrawerOpen = () => {
    setOpen(true);
    dispatch(ChangeSideBar(true));
  };

  const handleDrawerClose = () => {
    setOpen(false);
    dispatch(ChangeSideBar(false));
  };

  return (
    !(
      location.pathname === "/LoginPage" ||
      location.pathname === "/SignUpPage" ||
      location.pathname === "/ForgotPasswordPage" ||
      location.pathname.startsWith("/SignUpPage/cname/")
    ) && (
      <Box sx={{ display: "flex" }}>
        <AppBar
          position="fixed"
          open={open}
          sx={{
            backgroundColor: `${colors?.secondaryColor} !important`,
            color: `${colors?.primaryColor} !important`,
          }}
        >
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 5,
                  ...(open && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton>

              <img
                variant="h5"
                nowrap
                component="div"
                src={colors?.brandLogo}
                // alt="NOGRUNT"
                width="200"
                height="50"
                onClick={() => {
                  navigate("/dashboard/app");
                }}
                style={{
                  textTransform: "uppercase",
                  cursor: "pointer",
                  marginLeft: "2%",
                }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Link component={RouterLink} to="/new" underline="none">
                <Box
                  color={"#0fc6ee"}
                  display="flex"
                  border="1px solid #0fc6ee"
                  p={1}
                  borderRadius={2}
                  sx={{ cursor: "pointer" }}
                  mr={2}
                  alignItems="center"
                >
                  <SwitchAccessShortcutOutlinedIcon />
                  Switch to new
                </Box>
              </Link>
              <Typography
                variant="h5"
                nowrap
                component="div"
                sx={{
                  // textTransform: "uppercase",
                  marginRight: "0rem !important",
                }}
              >
                {temp?.username}
              </Typography>
              <IconButton
                color="inherit"
                variant="contained"
                onClick={() => {
                  let user = {
                    email: "",
                    firstname: "",
                    lastname: "",
                    company: "",
                    phonenumber: "",
                  };
                  logout();

                  // storing input from register-form
                  let json = JSON.stringify(user);
                  localStorage.setItem("userDetail", json);
                  //remove chatHistory from localStorage
                  localStorage.removeItem("chatHistory");
                  console.log("USER remove");
                  navigate("/LoginPage");
                }}
                sx={{ marginLeft: 4 }}
              >
                <PowerSettingsNewIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          open={open}
          PaperProps={{
            sx: {
              backgroundColor: `${colors?.secondaryColor} !important`,
              color: `${colors?.primaryColor} !important`,
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              flexDirection: "column",
            },
          }}
        >
          <Box>
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose} color="inherit">
                <ChevronLeftIcon />
              </IconButton>
            </DrawerHeader>
            {/* {temp?.company?.gentype === "recording" ? ( */}
            {true ? (
              <List>
                {navConfig.map((item, index) => (
                  <ListItem key={index} disablePadding sx={{ display: "block" }}>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                      onClick={() => {
                        navigate(item.path);
                      }}
                    >
                      <BootstrapTooltip
                        placement="right"
                        title={
                          <React.Fragment>
                            <Typography color="inherit">{item.title}</Typography>
                          </React.Fragment>
                        }
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            color: "inherit",
                          }}
                        >
                          {item.icon}
                        </ListItemIcon>
                      </BootstrapTooltip>
                      <ListItemText primary={item.title} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                  </ListItem>
                ))}

                {temp?.usertype === 1 && (
                  <>
                    <ListItem disablePadding sx={{ display: "block" }}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                        onClick={() => {
                          navigate("/dashboard/chatBot");
                        }}
                      >
                        <BootstrapTooltip
                          placement="right"
                          title={
                            <React.Fragment>
                              <Typography color="inherit">{"Generate Test Cases"}</Typography>
                            </React.Fragment>
                          }
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                              color: "inherit",
                            }}
                          >
                            <ChatIcon />
                          </ListItemIcon>
                        </BootstrapTooltip>
                        <ListItemText
                          primary={"Generate Test Cases"}
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding sx={{ display: "block" }}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                        onClick={() => {
                          navigate("/dashboard/createTestCase");
                        }}
                      >
                        <BootstrapTooltip
                          placement="right"
                          title={
                            <React.Fragment>
                              <Typography color="inherit">{"Create New Test Case"}</Typography>
                            </React.Fragment>
                          }
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                              color: "inherit",
                            }}
                          >
                            <AutoAwesomeIcon />
                          </ListItemIcon>
                        </BootstrapTooltip>
                        <ListItemText
                          primary={"Create New Test Case"}
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding sx={{ display: "block" }}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                        onClick={() => {
                          navigate("/dashboard/testData");
                        }}
                      >
                        <BootstrapTooltip
                          placement="right"
                          title={
                            <React.Fragment>
                              <Typography color="inherit">{" Manage Test Data"}</Typography>
                            </React.Fragment>
                          }
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                              color: "inherit",
                            }}
                          >
                            <DocumentScannerIcon />
                          </ListItemIcon>
                        </BootstrapTooltip>
                        <ListItemText primary={"Manage Test Data"} sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding sx={{ display: "block" }}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                        onClick={() => {
                          navigate("/dashboard/Api");
                        }}
                      >
                        <BootstrapTooltip
                          placement="right"
                          title={
                            <React.Fragment>
                              <Typography color="inherit">{"API"}</Typography>
                            </React.Fragment>
                          }
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                              color: "inherit",
                            }}
                          >
                            <ApiIcon />
                          </ListItemIcon>
                        </BootstrapTooltip>
                        <ListItemText primary={"API"} sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding sx={{ display: "block" }}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                        onClick={() => {
                          navigate("/dashboard/AdminPage");
                        }}
                      >
                        <BootstrapTooltip
                          placement="right"
                          title={
                            <React.Fragment>
                              <Typography color="inherit">{"Admin Page"}</Typography>
                            </React.Fragment>
                          }
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                              color: "inherit",
                            }}
                          >
                            <PersonIcon />
                          </ListItemIcon>
                        </BootstrapTooltip>
                        <ListItemText primary={"Admin Page"} sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding sx={{ display: "block" }}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                        onClick={() => {
                          navigate("/dashboard/CoverageDashboard");
                        }}
                      >
                        <BootstrapTooltip
                          placement="right"
                          title={
                            <React.Fragment>
                              <Typography color="inherit">{"Coverage Page"}</Typography>
                            </React.Fragment>
                          }
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                              color: "inherit",
                            }}
                          >
                            <SdStorageIcon />
                          </ListItemIcon>
                        </BootstrapTooltip>
                        <ListItemText primary={"Coverage Page"} sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding sx={{ display: "block" }}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                        onClick={() => {
                          navigate("/dashboard/userExperience");
                        }}
                      >
                        <BootstrapTooltip
                          placement="right"
                          title={
                            <React.Fragment>
                              <Typography color="inherit">{"User Experience"}</Typography>
                            </React.Fragment>
                          }
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                              color: "inherit",
                            }}
                          >
                            <AspectRatioIcon />
                          </ListItemIcon>
                        </BootstrapTooltip>
                        <ListItemText primary={"User Experience"} sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding sx={{ display: "block" }}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                        onClick={() => {
                          navigate("/dashboard/Test");
                        }}
                      >
                        <BootstrapTooltip
                          placement="right"
                          title={
                            <React.Fragment>
                              <Typography color="inherit">{"Test Page"}</Typography>
                            </React.Fragment>
                          }
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                              color: "inherit",
                            }}
                          >
                            <DateRangeIcon />
                          </ListItemIcon>
                        </BootstrapTooltip>
                        <ListItemText primary={"Test Page"} sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>
                    {/* <ListItem disablePadding sx={{ display: "block" }}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                        onClick={() => {
                          navigate("/dashboard/recordings/new");
                        }}
                      >
                        <BootstrapTooltip
                          placement="right"
                          title={
                            <React.Fragment>
                              <Typography color="inherit">{"Test Authoring"}</Typography>
                            </React.Fragment>
                          }
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                              color: "inherit",
                            }}
                          >
                            <VideocamRoundedIcon />
                          </ListItemIcon>
                        </BootstrapTooltip>
                        <ListItemText primary={"Test Authoring"} sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem> */}
                  </>
                )}

                {temp?.usertype === 4 && (
                  <>
                    <ListItem disablePadding sx={{ display: "block" }}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                        onClick={() => {
                          navigate("/dashboard/testData");
                        }}
                      >
                        <BootstrapTooltip
                          placement="right"
                          title={
                            <React.Fragment>
                              <Typography color="inherit">{" Manage Test Data"}</Typography>
                            </React.Fragment>
                          }
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                              color: "inherit",
                            }}
                          >
                            <DocumentScannerIcon />
                          </ListItemIcon>
                        </BootstrapTooltip>
                        <ListItemText primary={"Manage Test Data"} sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding sx={{ display: "block" }}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                        onClick={() => {
                          navigate("/dashboard/Api");
                        }}
                      >
                        <BootstrapTooltip
                          placement="right"
                          title={
                            <React.Fragment>
                              <Typography color="inherit">{"API"}</Typography>
                            </React.Fragment>
                          }
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                              color: "inherit",
                            }}
                          >
                            <ApiIcon />
                          </ListItemIcon>
                        </BootstrapTooltip>
                        <ListItemText primary={"API"} sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>
                  </>
                )}

                {temp?.usertype === 3 && (
                  <>
                    <ListItem disablePadding sx={{ display: "block" }}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                        onClick={() => {
                          navigate("/dashboard/testData");
                        }}
                      >
                        <BootstrapTooltip
                          placement="right"
                          title={
                            <React.Fragment>
                              <Typography color="inherit">{" Manage Test Data"}</Typography>
                            </React.Fragment>
                          }
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                              color: "inherit",
                            }}
                          >
                            <DocumentScannerIcon />
                          </ListItemIcon>
                        </BootstrapTooltip>
                        <ListItemText primary={"Manage Test Data"} sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding sx={{ display: "block" }}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                        onClick={() => {
                          navigate("/dashboard/Api");
                        }}
                      >
                        <BootstrapTooltip
                          placement="right"
                          title={
                            <React.Fragment>
                              <Typography color="inherit">{"API"}</Typography>
                            </React.Fragment>
                          }
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                              color: "inherit",
                            }}
                          >
                            <ApiIcon />
                          </ListItemIcon>
                        </BootstrapTooltip>
                        <ListItemText primary={"API"} sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding sx={{ display: "block" }}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                        onClick={() => {
                          navigate("/dashboard/AdminPage");
                        }}
                      >
                        <BootstrapTooltip
                          placement="right"
                          title={
                            <React.Fragment>
                              <Typography color="inherit">{"Admin Page"}</Typography>
                            </React.Fragment>
                          }
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                              color: "inherit",
                            }}
                          >
                            <PersonIcon />
                          </ListItemIcon>
                        </BootstrapTooltip>
                        <ListItemText primary={"Admin Page"} sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>
                  </>
                )}
              </List>
            ) : (
              ""
            )}
          </Box>
          <List>
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => {
                  navigate("/dashboard/UserPage");
                }}
              >
                <BootstrapTooltip
                  placement="right"
                  title={
                    <React.Fragment>
                      <Typography color="inherit">{"User Setting Page"}</Typography>
                    </React.Fragment>
                  }
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color: "inherit",
                    }}
                  >
                    <SettingsIcon />
                  </ListItemIcon>
                </BootstrapTooltip>
                <ListItemText primary={"User Setting Page"} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
      </Box>
    )
  );
};

export default Navbar;
