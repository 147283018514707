import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

export const getPageElements = async (pageId) => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) {
    throw new Error("No user details found in localStorage");
  }
  const user = JSON.parse(userDetails);

  const response = await axios.get(
    `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getPageElementsByPageid&pageid=${pageId}&token=${user.token}&companyid=${user.companyid}`,
  );

  return response.data;
};

const useGetPageElements = (pageId) => {
  return useQuery({
    queryKey: ["getPageElements", pageId],
    queryFn: () => getPageElements(pageId),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};

export default useGetPageElements;
