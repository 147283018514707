import axios from "axios";

import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "./../Config/Config";

export const handleTDChange = async (api_input) => {
  let data = {
    body: api_input.file,
    headers: {},
  };
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);

  if (
    api_input.Source === "AsRecorded" ||
    api_input.Source === "DynamicText" ||
    api_input.Source === "Search" ||
    api_input.Source === "SameRowElement" ||
    api_input.Source === "D0" ||
    api_input.Source === "DNext" ||
    api_input.Source === "DBelow"
  ) {
    const Response = await axios
      .post(
        `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestData&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${api_input.testCaseId}&teststepid=${api_input.id}&testdata=${api_input.Source}&actualdata=${api_input.testdata}`,
        data,
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
    return Response;
  } else if (api_input.Source === "DependentonOtherTestScenario") {
    const encodedValue = encodeURIComponent(api_input.useVar);
    const Response = await axios
      .post(
        `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestData&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${api_input.testCaseId}&teststepid=${api_input.id}&testdata=${api_input.Source}&testCaseIdNew=${api_input.idtest_case_One}&testdatavalue=${encodedValue}`,
        data,
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
    return Response;
  } else if (api_input.Source === "ChangeTestData") {
    const encodedValue = encodeURIComponent(api_input.UploadString);
    const Response = await axios
      .post(
        `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestData&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${api_input.testCaseId}&teststepid=${api_input.id}&testdata=${api_input.Source}&testdatavalue=${encodedValue}`,
        data,
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
    return Response;
  } else if (api_input.Source === "IsAVar") {
    const Response = await axios
      .post(
        `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestData&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${api_input.testCaseId}&teststepid=${api_input.id}&testdata=${api_input.Source}`,
        data,
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
    return Response;
  } else if (api_input.Source === "GenData") {
    const Response = await axios
      .post(
        `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestData&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${api_input.testCaseId}&teststepid=${api_input.id}&testdata=${api_input.Source}&testdataType=${api_input.GenerateDataType}&testdatalen=${api_input.generateDataLength}&testdateformat=${api_input.generateDateFormat}&testdateoffset=${api_input.generateDateOffset}`,
        data,
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
    return Response;
  } else if (api_input.Source === "FromSMS") {
    // URL encode the regex pattern before adding it to the URL
    const encodedRegex = encodeURIComponent(api_input.regex);

    const Response = await axios
      .post(
        `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestData&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${api_input.testCaseId}&teststepid=${api_input.id}&testdata=${api_input.Source}&regex=${encodedRegex}`,
        data,
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
    return Response;
  } else if (api_input.Source === "SameRow") {
    const Response = await axios
      .post(
        `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestData&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${api_input.testCaseId}&teststepid=${api_input.id}&testdata=${api_input.Source}&elementType=${api_input.ElementType}&elementIndex=${api_input.ElementIndex}`,
        data,
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
    return Response;
  } else if (api_input.Source === "UseVariableFromTheScenario") {
    const encodedValue = encodeURIComponent(api_input.useVar);
    const Response = await axios
      .post(
        `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestData&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${api_input.testCaseId}&teststepid=${api_input.id}&testdata=${api_input.Source}&testdatavalue=${encodedValue}`,
        data,
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
    return Response;
  } else if (api_input.Source === "FromFile") {
    const Response = await axios
      .post(
        `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestData&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${api_input.testCaseId}&teststepid=${api_input.id}&testdata=${api_input.Source}&name=${api_input.filename}&filefield=${api_input.filefield}&sheet=${api_input.sheetId}&cell=${api_input.cell}`,
        data,
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
    return Response;
  } else if (api_input.Source === "GoogleSheet") {
    // `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestData&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${api_input.testCaseId}&teststepid=${api_input.id}&testdata=${api_input.Source}&sheetId=${api_input.filename}&fieldName=${api_input.filefield}`,
    const Response = await axios
      .post(
        `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestData&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${api_input.testCaseId}&teststepid=${api_input.id}&testdata=${api_input.Source}&sheetId=${api_input.filename}&sheet=${api_input.sheetId}&cell=${api_input.cell}&scope=${api_input.scope}&endRange=${api_input.endRange}`,
        data,
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
    return Response;
  } else if (api_input.Source === "FromApi") {
    const encodedQuery = api_input.apiQuery === null ? "" : encodeURIComponent(api_input.apiQuery);
    const encodedParam = api_input.apiParam === null ? "" : encodeURIComponent(api_input.apiParam);

    const Response = await axios
      .post(
        `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestData&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${api_input.testCaseId}&teststepid=${api_input.id}&testdata=${api_input.Source}&fieldName=${api_input.apiId}&apiid=${api_input.apiName}&apiParam=${encodedParam}&apiQuery=${encodedQuery}`,
        data,
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
    return Response;
  } else if (api_input.Source === "FromReferenceFile") {
    const filevalue =
      api_input.FileName.FileName != undefined ? api_input.FileName.FileName : api_input.FileName;
    const encodedValue = encodeURIComponent(filevalue);
    // `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestData&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${api_input.testCaseId}&teststepid=${api_input.id}&testdata=${api_input.Source}&fileName=${encodedValue}&fileField=${api_input.filefield}`,
    let apicall;
    if (api_input.fromFileType === "xlsx" || api_input.fromFileType === "csv") {
      apicall = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestData&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${api_input.testCaseId}&teststepid=${api_input.id}&testdata=${api_input.Source}&name=${encodedValue}&sheetId=${api_input.sheetId}&cell=${api_input.cell}&scope=${api_input.scope}&endRange=${api_input.endRange}`;
    } else if (api_input.fromFileType === "json") {
      apicall = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestData&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${api_input.testCaseId}&teststepid=${api_input.id}&testdata=${api_input.Source}&name=${encodedValue}&filter=${api_input.filter}&scope=${api_input.scope}&endRange=${api_input.endRange}`;
    } else {
      apicall = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestData&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${api_input.testCaseId}&teststepid=${api_input.id}&testdata=${api_input.Source}&name=${encodedValue}`;
    }

    // `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestData&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${api_input.testCaseId}&teststepid=${api_input.id}&testdata=${api_input.Source}&name=${encodedValue}&sheetId=${api_input.sheetId}&cell=${api_input.cell}&scope=${api_input.scope}&endRange=${api_input.endRange}`,

    const Response = await axios
      .post(apicall, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
    return Response;
  } else if (api_input.Source === "FromDB") {
    const encodedValue = encodeURIComponent(api_input.DBQuery);
    const Response = await axios
      .post(
        `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestData&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${api_input.testCaseId}&teststepid=${api_input.id}&testdata=${api_input.Source}&dbquery=${encodedValue}`,
        data,
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
    return Response;
  } else if (api_input.Source === "FromEmail" || api_input.Source === "FromOutlook") {
    const baseURL = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestData&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${api_input.testCaseId}&teststepid=${api_input.id}&testdata=${api_input.Source}`;
    const url =
      api_input.Source === "FromEmail"
        ? `${baseURL}&customerEmail=${api_input.customerEmail}&customerPassword=${api_input.customerPassword}&EmailSelectionCriteria=${api_input.EmailSelectionCriteria}&EmailFilter=${encodeURIComponent(api_input.EmailFilter)}`
        : `${baseURL}&EmailSelectionCriteria=${api_input.EmailSelectionCriteria}&EmailFilter=${encodeURIComponent(api_input.EmailFilter)}`;

    const Response = await axios
      .post(url, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
    return Response;
  } else if (api_input.Source === "SendEmail") {
    const Response = await axios
      .post(
        `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestData&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${api_input.testCaseId}&teststepid=${api_input.id}&testdata=${api_input.Source}&customerEmail=${api_input.customerEmail}&customerPassword=${encodeURIComponent(api_input.customerPassword)}&toAddress=${encodeURIComponent(api_input.toAddress)}&subject=${encodeURIComponent(api_input.subject)}&content=${encodeURIComponent(api_input.content)}`,
        data,
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
    return Response;
  }
};

export const fetchSelectFromListData = async (id, tcid) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getRecordedTestDataList&companyid=${temp.companyid}&token=${temp.token}&teststepid=${id}&testcaseid=${tcid}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const fetchRecording = async (data) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  if (data.display === "view") {
    const Response = await axios
      .get(
        `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=latestRecording&companyid=${temp.companyid}&token=${temp.token}`,
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response.data.error);
      });
    return Response;
  } else {
    const Response = await axios
      .get(
        `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=latestRecordingStatus&companyid=${temp.companyid}&token=${temp.token}&testcaseid=${data.testCaseId}`,
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response.data.error);
      });
    return Response;
  }
};

export const fetchRecordingInResult = async (tcrid) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);

  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getTsFromTcr&companyid=${temp.companyid}&token=${temp.token}&tcrid=${tcrid}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const fetchDiff = async (tsid, stepNumber, tcid) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);

  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getStepDiff&companyid=${temp.companyid}&token=${temp.token}&tsid=${tsid}&stepNumber=${stepNumber}&tcid=${tcid}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const fetchImageDiff = async (tsid, stepNumber, tcid, tcrid) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);

  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getStepImageDiff&companyid=${temp.companyid}&token=${temp.token}&tsid=${tsid}&stepNumber=${stepNumber}&tcid=${tcid}&tcrid=${tcrid}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const fetchResultInRecording = async (tcid) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);

  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getTsrFromTc&companyid=${temp.companyid}&token=${temp.token}&tcid=${tcid}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const executeTest = (id) => {
  // let apicall =
  //   "http://localhost:8080/KeyLogging/ReactApp?action=execute&testcaseid=" +
  //   id;
  // fetch(apicall, {
  //   method: "POST",
  //   body: api_input.file,
  //   headers: {
  //     "content-type": api_input.file.type,
  //     "content-length": `${api_input.file.size}`, // 👈 Headers need to be a string
  //   },
  // })
  //   .then((res) => res.json())
  //   .then((data) => {
  //     setapi_input({
  //       xls: get(data, "data", {}),
  //       tcs: get(data, "testcase", {}),
  //     });
  //   })
  //   .catch((err) => console.error(err));
  // api_input.display = "execute";
};

export const deleteStep = async (id, tcid) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=deletestep&companyid=${temp.companyid}&token=${temp.token}&teststepid=${id}&testcaseid=${tcid}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const fetchSteps = async (tcid) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getnlpfortc&companyid=${temp.companyid}&token=${temp.token}&testcaseid=${tcid}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const generateCode = async (tcid, tcname) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios;
  try {
    const downloadUrl = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=genCode&companyid=${temp.companyid}&token=${temp.token}&testcaseid=${tcid}&testcasename=${tcname}`;
    window.location.href = downloadUrl;
  } catch (error) {
    console.log(error.response.data.error);
    throw error;
  }
};

export const downloadTestSteps = async (tcid) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios;
  try {
    const downloadUrl = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=downloadTestSteps&companyid=${temp.companyid}&token=${temp.token}&tcid=${tcid}`;
    window.location.href = downloadUrl;
  } catch (error) {
    console.log(error.response.data.error);
    throw error;
  }
};

export const downloadPomZipFile = async (pid) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios;
  try {
    const downloadUrl = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=gpcTesting&companyid=${temp.companyid}&token=${temp.token}&pid=${pid}`;
    window.location.href = downloadUrl;
  } catch (error) {
    console.log(error.response.data.error);
    throw error;
  }
};

export const addStepInrecording = async (data) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .post(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=addStepRecording&companyid=${temp.companyid}&token=${temp.token}`,
      data,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const editStepInrecording = async (data) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .post(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateStepRecording&companyid=${temp.companyid}&token=${temp.token}`,
      data,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const editStepInresult = async (data) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .post(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateStepResults&companyid=${temp.companyid}&token=${temp.token}`,
      data,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const handleFlowChange = async (teststepid, tcid, flow) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const data = {
    teststepid,
    tcid,
    flow: flow,
  };
  const Response = await axios
    .post(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateFlow&companyid=${temp.companyid}&token=${temp.token}&teststepid=${data.teststepid}&flow=${data.flow}&testcaseid=${tcid}`,
      data,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const logout = async (data) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);

  // added null check if user details is not present
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=logout&companyid=${temp?.companyid}&token=${temp?.token}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const handleSelectChange = (teststepid, tcid) => async (event) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const data = {
    teststepid,
    tcid,
    flow: event.target.value,
  };
  const Response = await axios
    .post(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateSelect&companyid=${temp.companyid}&token=${temp.token}&teststepid=${data.teststepid}&select=${data.flow}&testcaseid=${tcid}`,
      data,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const handleCheckBoxChange = (teststepid, testcaseid) => async (event) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const data = {
    teststepid,
    testcaseid,
    flow: event.target.value,
  };
  const Response = await axios
    .post(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateCheckBox&companyid=${temp.companyid}&token=${temp.token}&teststepid=${data.teststepid}&select=${data.flow}&testcaseid=${data.testcaseid}`,
      data,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const handleValidateChange = (teststepid, testcaseid) => async (event) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const data = {
    teststepid,
    testcaseid,
    validate: event.target.value,
  };
  const Response = await axios
    .post(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateValidation&companyid=${temp.companyid}&token=${temp.token}&teststepid=${data.teststepid}&select=${data.validate}&testcaseid=${data.testcaseid}`,
      data,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const handleBeforeChange = (teststepid, tcid) => async (event) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const data = {
    teststepid,
    tcid,
    before: event.target.value,
  };
  const Response = await axios
    .post(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateBefore&companyid=${temp.companyid}&token=${temp.token}&teststepid=${data.teststepid}&before=${data.before}&testcaseid=${tcid}`,
      data,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const handleWaitTimeChange = async (tsid, tcid, time) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const data = {
    tsid,
    tcid,
    time,
  };
  const Response = await axios
    .post(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateWaitTime&companyid=${temp.companyid}&token=${temp.token}&teststepid=${tsid}&waittime=${time}&testcaseid=${tcid}`,
      data,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const handleTestCaseSearchByMid = async (mid, key) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=searchTCSearch&companyid=${temp.companyid}&token=${temp.token}&modid=${mid}&key=${key}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const editTestCaseName = async (tcid, newName) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=changetcname&companyid=${temp.companyid}&token=${temp.token}&tcid=${tcid}&tcname=${newName}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const copyTestCaseName = async (tcid, copyNewName) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=copyTC&companyid=${temp.companyid}&token=${temp.token}&tcid=${tcid}&tcname=${copyNewName}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

//http://localhost:9000/keyloggingv2/ReactApp?action=copyTC&companyid=1&token=24460063&tcid=520&tcname=sanjeev

export const deleteTestCaseName = async (tcid) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=deleteTC&companyid=${temp.companyid}&token=${temp.token}&tcid=${tcid}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

// http://localhost:9000/keyloggingv2/ReactApp?action=deleteTC&companyid=1&token=64441742&tcid=528
