import React, { useState } from "react";
import {
  ChevronDownIcon,
  FolderIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";

const Collection = ({
  collection,
  setUrl,
  setMethod,
  setBody,
  setHeaders,
  setEndPoint,
  setActiveTab,
  setCollectionId,
  setEndpointFormOpen,
  setResponse5,
  setTestResults,
  setChainResult,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen((prev) => !prev);
  };

  const handleAddNewApiClick = (collectionId) => {
    setCollectionId(collectionId);
    setEndpointFormOpen(true);
  };

  const handleApiClick = (endpoint, collection) => {
    setEndPoint(endpoint);
    const isCompleteEndpoint =
      endpoint.path.includes("http") || endpoint.path.includes("https");
    if (isCompleteEndpoint) {
      setUrl(`${endpoint.path}`);
    } else {
      setUrl(`${collection.baseurl}${endpoint.path}`);
    }
    const path = endpoint.method;
    setMethod(path);

    if (path === "GET" || path === "DELETE") {
      setActiveTab("queryParams");
    } else if (endpoint.body) {
      setActiveTab("body");
      setBody(inputData(endpoint.body));
      setHeaders(inputData(endpoint.headers));
      
    }

    setResponse5("");
    setTestResults("");
    setChainResult("");
  };

  const inputData = (inputArray) => {
    if (inputArray) {
      const outputArray = Object.entries(inputArray).map(([key, value]) => ({
        key,
        value,
      }));
      return outputArray;
    }
    return [];
  };

  return (
    <div className="border-b border-gray-200">
      <div className="group">
        <div
          className="flex items-center px-4 py-3 hover:bg-gray-100 cursor-pointer bg-white border mt-2 shadow-sm"
          onClick={toggleAccordion}
        >
          <ChevronDownIcon
            className={`h-5 w-5 text-gray-500 mr-2 transition-transform ${
              isOpen ? "rotate-180" : ""
            }`}
          />
          <FolderIcon className="h-6 w-6 text-blue-400 mr-2" />
          <div className="flex-1 text-sm">
            <span>{collection?.name}</span>
            <span className="ml-2 text-xs font-medium text-orange-500">
              [test]
            </span>
          </div>
        </div>

        {isOpen && (
          <div className="bg-white py-4">
            <div className="py-1 px-2">
              <button
                onClick={() => handleAddNewApiClick(collection.id)}
                className="w-full py-2 px-4 border border-gray-300 bg-white text-sm text-postman-gray-medium hover:bg-gray-100 flex items-center justify-center space-x-2 rounded-md font-sans"
              >
                <PlusIcon className="h-4 w-4 mr-2" />
                Add new API
              </button>
            </div>

            {collection?.endpoints?.map((endpoint, i) => (
              <button
                key={i}
                onClick={() => handleApiClick(endpoint, collection)}
                className="w-full text-left px-4 py-2 hover:bg-gray-50 flex items-center text-sm"
              >
                <span
                  className={`mr-2 font-medium ${
                    endpoint.method === "GET"
                      ? "text-green-600"
                      : endpoint.method === "POST"
                      ? "text-yellow-600"
                      : endpoint.method === "PUT"
                      ? "text-blue-600"
                      : "text-red-600"
                  }`}
                >
                  {endpoint.method}
                </span>
                <span className="truncate text-gray-600">{endpoint.path}</span>
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Collection;
