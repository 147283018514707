import React, { useEffect, useRef, useState } from "react";
import "./MainContent.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Circles } from "react-loader-spinner";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import JsonView from "react18-json-view";
import _ from "lodash";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import TestResults from "./TestResults";
import TestCasesTable from "./TestCasesTable";
import BulkApiTester from "./BulkApiTester";
import TestCaseDropdown from "./TestCaseDropdown";
import ChainTestResults from "./ChainTestResults";
import Chain from "./Chain";
import Collection from "./Collection";
import EndpointForm from "./EndpointForm";
import CollectionForm from "./CollectionForm";
import TestCaseForm from "./TestCaseForm";
import {
  ArchiveBoxIcon,
  ClockIcon,
  PaperClipIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/outline";
import HistoryPanel from "./HistoryPanel";
import TopNav from "./TopNav";
import usePolling from "../../hooks/usePoolingHook";
import ConstructUrl from "./ConstructUrl";
import TestEndpointCasesResults from "./TestEndpointCasesResults";
import BulkResultsPanel from "./BulkResultsPanel";
import { API_BACKEND_URL } from "../../../Config/Config";
import { LinearProgress, Typography } from "@mui/material";
import { generateHtmlReport } from "../utils/helper";
import { Loader2 } from "./Loader2";

function MainContent({ addNameToSidebar, apiRequestData }) {
  const [method, setMethod] = useState("GET");
  const [apiName, setApiName] = useState("");
  // const [testCase, setTestCase] = useState("");
  const [url, setUrl] = useState("");
  // const [response, setResponse] = useState(null);
  const [response3, setResponse3] = useState(null); // Collection
  const [response5, setResponse5] = useState(null);
  const [chainResults, setChainResult] = useState(null);
  const [activeTab, setActiveTab] = useState("headers");
  const [sidebarTab, setSidebarTab] = useState("collections");

  const [queryParams, setQueryParams] = useState("");
  const [headers, setHeaders] = useState("");
  const [body, setBody] = useState("");
  const [testcase, setTestcase] = useState("");
  const [loader, setLoader] = useState(false);
  const [collections, setCollections] = useState([]);
  const [chains, setChains] = useState([]);
  const [saveButton, setSaveButton] = useState(false);
  const [currentCollection, setCurrentCollection] = useState("");
  const [jsonInput, setJsonInput] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [mainTab, setMainTab] = useState("mainTab");
  const [testResults, setTestResults] = useState([]);
  const [endpoint, setEndPoint] = useState("");
  const [showTable, setShowTable] = useState(true);
  const [testReport, setTestReport] = useState(null);
  const [chainTest, setChainTest] = useState("testResults");
  const [baseUrl, setBaseUrl] = useState(false);
  const [collectionId, setCollectionId] = useState(null);
  const [endpointFormOpen, setEndpointFormOpen] = useState(false);

  const [allData, setAllTestCases] = useState(null);
  const [testCasesCount, setTestCasesCount] = useState(null);

  const [openDialog, setOpenDialog] = useState(false);
  const [collectionDialogOpen, setCollectionDialogOpen] = useState(false);
  const [shouldPoll, setShouldPoll] = useState(true);
  const [endpointResults, setEndpointResults] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [bulkResultsData, setBulkResultsData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentTest, setCurrentTest] = useState("");

  const [selectedTestCases, setSelectedTestCases] = useState([]);

  // const {
  //   data: allData,
  //   error,
  //   isLoading: isDataLoading,
  // } = usePolling(`${API_BACKEND_URL}/testcase/all`, shouldPoll);

  const handlTestCaseSelect = (testcase) => {
    setShouldPoll(false);
    setSelectedTestCases((prev) => {
      if (prev.includes(testcase)) {
        return prev.filter((tc) => tc !== testcase);
      }
      return [...prev, testcase];
    });
  };

  const handleExecuteSelected = () => {
    //execute only selected test cases logic here.
  };

  const handleMarkAsReviewed = async (selectedTestCases) => {
    const ids = selectedTestCases.map((item) => item.id);
    console.log("Marking as reviewed:", ids);
    try {
      const response = await axios.put(
        `${API_BACKEND_URL}/testcase/review`,
        ids, // Send the array directly as JSON body
        { headers: { "Content-Type": "application/json" } } // Ensure correct headers
      );

      if (response.status === 200) {
        toast.success("Test cases marked as reviewed");
      } else {
        console.error("Failed to mark test cases as reviewed", response);
      }
    } catch (error) {
      console.error("Error while marking test cases as reviewed:", error);
    }
    setSelectedTestCases([]);
  };

  const handleSaveTestCase = (testCaseId, updatedData) => {
    // console.log("Saving test case:", testCaseId, updatedData);
  };


  const handleMainTabChange = (event) => {
    setMainTab(event.target.value);
  };
  const handleTabChange = (event, tab) => {
    setActiveTab(tab);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    fetchLatestResults();
  }, []);

  const handleOpenCollectionDialog = () => {
    setCollectionDialogOpen(true);
  };
  const handleCloseCollectionDialog = () => {
    setCollectionDialogOpen(false);
  };
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleEndpointFormClose = () => {
    setEndpointFormOpen(false); // Close the dialog
  };

  const handleTestComplete = (results, reportUrl) => {
    setTestResults(results);
    setShowTable(false);
    setTestReport(reportUrl); // Add this line
    setShouldPoll(!shouldPoll);
  };

  const handleSendRequest = async () => {
    setLoader(true);
    setActiveTab("responseBody");
    const backendUrl = `${API_BACKEND_URL}/test`;

    try {
      const defaultStructure = {};

      // Parse jsonInput if it's a string
      const parsedJsonInput = jsonInput
        ? typeof jsonInput === "string"
          ? JSON.parse(jsonInput)
          : jsonInput
        : {};

      // Ensure headers and queryParams are not null/undefined before calling Object.keys
      const safeHeaders =
        headers && typeof headers === "object" && Object.keys(headers).length
          ? headers
          : defaultStructure;
      const safeQueryParams =
        queryParams && typeof queryParams === "object" && Object.keys(queryParams).length
          ? queryParams
          : defaultStructure;

      const requestBody = {
        method: method || "",
        url: url || "",
        headers: safeHeaders,
        queryParams: safeQueryParams,
        body: body || "string",
        apiName: apiName || "string",
        id: testcase.id,
        expectedStatusCode: testcase.expectedStatusCode,
        requestKeyValuePairs: parsedJsonInput,
      };

      console.log(requestBody);

      const response = await fetch(backendUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();
      console.log("data", data);
      // Check if the response data contains an error message
      if (data.error || data.message) {
        setResponse5({
          originalResponse: {
            status: response.status,
            message: data.error || data.message,
            details: data,
          },
        });
        return;
      }

      console.log("Response received:", data);
      setResponse5(data);
    } catch (err) {
      console.error("Error details:", {
        message: err.message,
        stack: err.stack,
      });

      setResponse5({
        originalResponse: {
          status: err.status || 500,
          message: err.message || "An unexpected error occurred",
          details: err,
        },
      });
    } finally {
      setActiveTab("testResults");
      setLoader(false);
      setChainTest("testResults");
    }
  };

  // Update useEffect for collections if you have one
  useEffect(() => {
    if (response3) {
      try {
        setBaseUrl(response3?.collections[0].baseurl);
        setCollections(response3.collections);
        setChains(response3.chains);
      } catch (error) {
        console.error("Error parsing response:", error);
        setCollections([]);
      }
    }
  }, [response3]);

  // const testCases = response3;
  // Update useEffect for collections if you have one
  useEffect(() => {
    if (response3) {
      try {
        setBaseUrl(response3?.collections[0].baseurl);
        setCollections(response3.collections);
        setChains(response3.chains);
      } catch (error) {
        console.error("Error parsing response:", error);
        setCollections([]);
      }
    }
  }, [response3]);

  const [status, setStatus] = useState({ currentEndpoint: null, isGenerating: false });
  const previousEndpointRef = useRef(null);

  useEffect(() => {
    const statusInterval = setInterval(() => {
      // Check the current status every 5 seconds
      axios
        .get(`${API_BACKEND_URL}/testcase/status`)
        .then((response) => {
          const newStatus = response.data;
          setStatus(newStatus);

          // Check if the endpoint has changed
          if (
            newStatus.currentEndpoint &&
            newStatus.currentEndpoint !== previousEndpointRef.current
          ) {
            // Update previous endpoint and call "Get All Test Cases" API
            previousEndpointRef.current = newStatus.currentEndpoint;
            fetchAllTestCases();
          }

          // Stop polling if the generation process is complete
          if (allData != null && !newStatus.isGenerating) {
            clearInterval(statusInterval);
            fetchAllTestCases();
          }
        })
        .catch((error) => console.error("Error fetching generation status:", error));
    }, 5000);

    return () => clearInterval(statusInterval); // Cleanup on component unmount
  }, [allData]);

  useEffect(() => {
    fetchAllTestCases();
  }, []);

  const fetchAllTestCases = async () => {
    try {
      const response = await axios.get(`${API_BACKEND_URL}/testcase/all`);
      setAllTestCases(response.data);
      setTestCasesCount(response.data.length);
    } catch (error) {
      console.error("Error fetching test cases:", error);
    }
  };

  // Update the onTestCaseSelect function to properly format the JSON

  const onTestCaseSelect = (testCase) => {
    if (!testCase) return;

    // Handle the case where the user wants to add a new testcase
    if (testCase.description === "Add Testcase") {
      // Clear all params and headers
      setHeaders("");
      setQueryParams("");
      setBody("");
      setResponse5("");
      setTestResults("");
      handleOpenDialog();
      return;
    }

    try {
      const updatedUrl = ConstructUrl(baseUrl, testCase);
      // Update the state with the processed values
      setUrl(updatedUrl.fullUrl);
      setBody(updatedUrl.requestBody);
      setHeaders(testCase.headers);
      setTestcase(testCase);
      setResponse5("");
      setTestResults("");
      // setHeaders(JSON.stringify(params.headers || {}, null, 2));
      // setBody(JSON.stringify(params.body || {}, null, 2));
      // setQueryParams(JSON.stringify(params.query_params || {}, null, 2));
    } catch (error) {
      console.error("Error parsing input parameters:", error);
    }
  };

  const handleAddApiClick = () => {
    // setSaveButton(false);
    // const updatedCollections = _.cloneDeep(collections);
    // const curCollection = updatedCollections.map((collection) => {
    //   if (collection.name === currentCollection) {
    //     const newEndpoint = {
    //       path: url,
    //       method: method,
    //       summary: "",
    //       operationId: "",
    //       headers: { headers },
    //       body: { body },
    //     };
    //     collection.endpoints.push(newEndpoint);
    //   }
    // });
    // console.log("update", updatedCollections);
    // setCollections(updatedCollections);
  };

  // Function to replace path parameters with actual values
  const replacePathParams = (path, paramValue) => {
    return path.replace(/{(\w+)}/g, paramValue);
  };

  const handleAddParam = () => {
    const finalPath = replacePathParams(url, queryParams);
    setUrl(finalPath);
    setQueryParams("");
  };

  const fetchWithRetry = async (url, options, retriesLeft) => {
    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 5000);

      const response = await fetch(url, {
        ...options,
        signal: controller.signal,
      });

      clearTimeout(timeoutId);
      return response;
    } catch (error) {
      if (retriesLeft > 0) {
        await new Promise((resolve) => setTimeout(resolve, 100));
        return fetchWithRetry(url, options, retriesLeft - 1);
      }
      throw error;
    }
  };

  const storeTestCaseResult = async (result) => {
    // const stringResult = JSON.stringify(result)
    try {
      const response = await axios.post(`${API_BACKEND_URL}/testcase_summary`, result);
      if (!response.ok) {
        console.error("Error sending results to API:", response.statusText);
      } else {
        console.log("Results successfully sent to API");
      }
    } catch (error) {
      console.error("Error while sending results to API:", error.message);
    }
  };

  const handleBulkRequests = async (testCases) => {
    if (testCases && testCases.length > 0) {
      setShouldPoll(false);
      setIsLoading(true);
      const results = [];

      try {
        for (let i = 0; i < testCases.length; i++) {
          const testCase = testCases[i];
          setProgress(Math.round((i / testCases.length) * 100));
          setCurrentTest(testCase.testCase);

          const { endpoint = "", httpMethod } = testCase;

          // Null or undefined check for endpoint
          if (!endpoint || typeof endpoint !== "string") {
            results.push({
              endpoint: testCase.endpoint,
              body: testCase.body,
              testCase: testCase.testCase,
              success: false,
              expectedStatus: parseInt(testCase.expectedStatusCode),
              actualStatus: null,
              effectiveStatus: null,
              responseData: null,
              error: "Invalid endpoint provided",
            });
            continue; // Skip this test case
          }

          const updatedUrl = ConstructUrl(baseUrl, testCase);
          let fullUrl = updatedUrl.fullUrl;

          try {
            let response;
            const requestOptions = {
              method: httpMethod,
              headers: {
                "Content-Type": "application/json",
                ...headers,
              },
            };

            // Check for body for POST, PUT, DELETE
            if (["POST", "PUT", "DELETE"].includes(httpMethod)) {
              if (testCase.body) {
                requestOptions.body = JSON.stringify(testCase.body);
              }
            }

            response = await fetchWithRetry(fullUrl, requestOptions, 2);
            const responseText = await response.text();
            let responseData;
            try {
              responseData = JSON.parse(responseText);
            } catch {
              responseData = responseText;
            }

            results.push({
              testCase: testCase.testCase,
              endpoint: testCase.endpoint,
              body: testCase.body,
              success: response.status === parseInt(testCase.expectedStatusCode),
              expectedStatus: parseInt(testCase.expectedStatusCode),
              actualStatus: response.status,
              effectiveStatus: response.status,
              responseData:
                typeof responseData === "object"
                  ? JSON.stringify(responseData, null, 2)
                  : responseData,
              error: null,
            });
          } catch (err) {
            results.push({
              testCase: testCase.testCase,
              endpoint: testCase.endpoint,
              body: testCase.body,
              success: false,
              expectedStatus: testCase.expectedStatusCode,
              actualStatus: null,
              effectiveStatus: null,
              responseData: null,
              error: err.message,
            });
          }
          // await new Promise((resolve) => setTimeout(resolve, 500)); // Optional delay between requests
        }

        setProgress(100);
        storeTestCaseResult(results);
        const reportUrl = generateHtmlReport(results);
        setTestReport(reportUrl);
        handleTestComplete(results, reportUrl);
        return results;
      } catch (err) {
        console.error("Bulk request error:", err);
        throw err;
      } finally {
        setIsLoading(false);
      }
    }
  };

  const fetchLatestResults = async (event) => {
    // Start test case generation immediately
    setActiveTab("headers");
    setLoader(true);

    try {
      const response = await axios.get(`${API_BACKEND_URL}/results`);
      console.log("actual response from db", response);
      setResponse3(response.data);
      setLoader(false);
    } catch (error) {
      console.error("Error fetching results:", error);
      setLoader(false);
    }
  };

  const loaderStyles = `
.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 40;
}

.content-container {
  position: relative;
  flex-grow: 1;
  height: calc(100vh - 48px); /* Adjust based on your nav height */
}
`;

  // Updated handleFileChange function
  const handleAnalyseSwagger = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setOpen(true);

      // Start test case generation immediately
      setActiveTab("headers");
      setLoader(true);

      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await axios.post(`${API_BACKEND_URL}/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        console.log("actual response", response);

        setResponse3(response.data); // response
        setLoader(false);
        console.log("Test cases generated for:", file.name);
      } catch (error) {
        console.error("Error uploading file:", error);
        setLoader(false);
      }
    }
  };

  return (
    <Box sx={{ width: "100%" }} height={"100vh"}>
      <Grid container sx={{ height: "100%" }}>
        <Grid size={12} sx={{ height: "100%" }}>
          <div className="main-content h-[100%] w-full ">
            <div>
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  alignItems: "center",
                }}
              >
                <Snackbar
                  open={open}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  autoHideDuration={5000}
                  onClose={handleClose}
                  message={`${selectedFile ? selectedFile.name : "File"} uploaded successfully`}
                />
              </Stack>
            </div>

            <div className="h-[100%] w-full ">
              <TabContext value={mainTab} sx={{ height: "100%" }}>
                <TopNav
                  activeTab={mainTab}
                  onTabChange={handleMainTabChange}
                  onFileUpload={handleAnalyseSwagger}
                />
                <TabPanel sx={{ padding: 0, height: "100%" }} value="mainTab">
                  <Grid
                    container
                    className="px-2 rounded-md bg-postman-gray-light h-full border-t "
                  >
                    {/* Left Sidebar */}

                    <Grid item xs={3} className="min-h-full border-r border-gray-200 ">
                      <Box className="bg-[#F9F9F9] min-h-full">
                        <div className="flex flex-col pr-3 gap-4 mt-6">
                          {/* Collections */}
                          <button
                            className={`flex flex-col items-center justify-center border-[1px] p-4 rounded-md ${sidebarTab === "collections"
                              ? " shadow-md border-[#FF5336] text-[#FF5336]"
                              : "bg-postman-gray-light text-postman-gray-medium"
                              }`}
                            onClick={() => setSidebarTab("collections")}
                          >
                            <ArchiveBoxIcon className="h-4 w-4" />
                            <span className="text-xs font-sans font-extralight text-postman-gray-medium mt-1">
                              Collections
                            </span>
                          </button>
                          {/* Chains */}
                          <button
                            className={`flex flex-col items-center justify-center border-[1px] p-4 rounded-md ${sidebarTab === "chains"
                              ? " shadow-md border-[#FF5336] text-[#FF5336]"
                              : "bg-postman-gray-light text-postman-gray-medium"
                              }`}
                            onClick={() => setSidebarTab("chains")}
                          >
                            <PaperClipIcon className="h-4 w-4" />
                            <span className="text-xs font-sans font-extralight  mt-1">Chains</span>
                          </button>

                          {/* history */}
                          <button
                            className={`flex flex-col items-center justify-center border-[1px] p-4 rounded-md ${sidebarTab === "history"
                              ? " shadow-md border-[#FF5336] text-[#FF5336]"
                              : "bg-postman-gray-light text-postman-gray-medium"
                              }`}
                            onClick={() => setSidebarTab("history")}
                          >
                            <ClockIcon className="h-4 w-4" />
                            <span className="text-xs font-sans font-extralight  mt-1">History</span>
                          </button>

                          {/* BulkResults */}
                          <button
                            className={`flex flex-col items-center justify-center border-[1px] p-4 rounded-md ${sidebarTab === "bulkResults"
                              ? " shadow-md border-[#FF5336] text-[#FF5336]"
                              : "bg-postman-gray-light text-postman-gray-medium"
                              }`}
                            onClick={() => setSidebarTab("bulkResults")}
                          >
                            <ClockIcon className="h-4 w-4" />
                            <span className="text-xs font-sans font-extralight  mt-1">
                              BulkResults
                            </span>
                          </button>
                        </div>
                      </Box>
                    </Grid>

                    <Grid item className="min-h-full  border-r  ">
                      <Box className=" h-full w-full  min-w-[300px]  ">
                        <div className="px-4 mt-6 h-full">
                          {sidebarTab === "collections" && (
                            <div>
                              <div className="flex justify-center mb-4">
                                <button
                                  // onClick={handleOpenDialog}
                                  onClick={handleOpenCollectionDialog}
                                  className="w-full py-2 px-4 border border-gray-300 bg-white text-sm text-postman-gray-medium hover:bg-gray-100 flex items-center justify-center space-x-2 rounded-md font-sans"
                                >
                                  <PlusCircleIcon className="h-5 w-5" />
                                  <span>Add New Collection</span>
                                </button>
                              </div>

                              {collections?.map((collection, i) => (
                                <Collection
                                  key={i}
                                  collection={collection}
                                  setUrl={setUrl}
                                  setMethod={setMethod}
                                  setBody={setBody}
                                  setHeaders={setHeaders}
                                  setQueryParams={setQueryParams}
                                  setSaveButton={setSaveButton}
                                  setCurrentCollection={setCurrentCollection}
                                  setEndPoint={setEndPoint}
                                  setActiveTab={setActiveTab}
                                  setCollectionId={setCollectionId}
                                  setEndpointFormOpen={setEndpointFormOpen}
                                  setResponse5={setResponse5}
                                  setTestResults={setTestResults}
                                  setChainResult={setChainResult}
                                />
                              ))}

                              <CollectionForm
                                // open={openDialog}
                                open={collectionDialogOpen}
                                // handleCloseDialog={handleCloseDialog}
                                handleCloseDialog={handleCloseCollectionDialog}
                              />

                              {endpointFormOpen && (
                                <EndpointForm
                                  collectionID={collectionId}
                                  onClose={handleEndpointFormClose}
                                />
                              )}
                            </div>
                          )}

                          {sidebarTab === "chains" && (
                            <div>
                              {chains?.map((chain, index) => (
                                <Chain
                                  chains={chains}
                                  key={index}
                                  chain={chain}
                                  baseUrl={baseUrl}
                                  setLoader={setLoader}
                                  setActiveTab={setActiveTab}
                                  setResponse5={setResponse5}
                                  setChainResult={setChainResult}
                                  setChainTest={setChainTest}
                                  setEndPoint={setEndPoint}
                                  setUrl={setUrl}
                                  setMethod={setMethod}
                                  setBody={setBody}
                                  setHeaders={setHeaders}
                                />
                              ))}
                            </div>
                          )}

                          {sidebarTab === "history" && (
                            <HistoryPanel
                              setHistoryData={setHistoryData}
                              setUrl={setUrl}
                              setMethod={setMethod}
                              setResponse5={setResponse5}
                              setActiveTab={setActiveTab}
                              historyData={historyData}
                            />
                          )}

                          {sidebarTab === "bulkResults" && (
                            <BulkResultsPanel
                              setBulkResultsData={setBulkResultsData}
                              bulkResultsData={bulkResultsData}
                            />
                          )}
                        </div>
                      </Box>
                    </Grid>

                    {/* Main Request Panel */}
                    <div className="flex-1 flex flex-col overflow-hidden ">
                      {/* Top Test Case Section */}
                      <div className="bg-white  px-4 py-2 flex items-center flex-row space-x-2">
                        <TestCaseDropdown
                          endpoint={endpoint}
                          handleOpenDialog={handleOpenDialog}
                          onTestCaseSelect={(testcase) => {
                            onTestCaseSelect(testcase);
                          }}
                          baseUrl={baseUrl}
                          setEndpointResults={setEndpointResults}
                          setChainTest={setChainTest}
                          setActiveTab={setActiveTab}
                        />
                        <button className="px-9 py-[12px] bg-postman-blue hover:bg-blue-700 rounded-md text-white font-semibold font-sans text-sm mt-11">
                          Apply
                        </button>
                      </div>

                      {/* Request URL Bar */}
                      <div className="px-4 bg-white">
                        <div className="px-4  flex items-center space-x-2 border border-postman-gray-medium rounded-md mb-2 bg-postman-gray-light w-full h-full ">
                          <select
                            value={method}
                            onChange={(e) => setMethod(e.target.value)}
                            className={`w-20 py-1.5  text-sm font-sans focus:outline-none font-bold bg-postman-gray-light  ${method === "GET"
                              ? "text-green-600"
                              : method === "POST"
                                ? "text-yellow-600"
                                : method === "PUT"
                                  ? "text-blue-600"
                                  : "text-red-600"
                              }`}
                          >
                            <option value="GET" className="text-green-600">
                              GET
                            </option>
                            <option value="POST" className="text-yellow-600">
                              POST
                            </option>
                            <option value="PUT" className="text-blue-600">
                              PUT
                            </option>
                            <option value="DELETE" className="text-red-600">
                              DELETE
                            </option>
                          </select>

                          <div className="h-6 w-0.5 bg-gray-300"></div>

                          <input
                            type="text"
                            value={url}
                            onChange={(e) => setUrl(e.target.value) || setQueryParams("")}
                            placeholder="Enter request URL"
                            className="flex-1 px-3 py-4 h-full text-sm focus:ring-1 focus:ring-blue-500 focus:border-blue-500 bg-postman-gray-light font-sans"
                          />

                          <TestCaseForm
                            open={openDialog}
                            endpointId={endpoint.id}
                            handleCloseDialog={handleCloseDialog}
                            collections={collections}
                          />

                          {saveButton ? (
                            <button
                              onClick={handleAddApiClick}
                              className="px-9 py-[12px] bg-postman-blue hover:bg-blue-700 rounded-md text-white font-semibold font-sans text-sm "
                            >
                              Save
                            </button>
                          ) : (
                            <button
                              onClick={handleSendRequest}
                              className="px-9 py-[12px] bg-postman-blue hover:bg-blue-700 rounded-md text-white font-semibold font-sans text-sm "
                            >
                              Test
                            </button>
                          )}
                        </div>
                      </div>

                      {/* Tabs Section */}
                      <div className="flex-1 flex flex-col  py-2 bg-white px-4">
                        <div className="border-b flex bg-postman-gray-light">
                          {[
                            { label: "Query Params", value: "queryParams" },
                            { label: "Headers", value: "headers" },
                            { label: "Body", value: "body" },
                            { label: "Test Scripts", value: "testScripts" },
                            { label: "Response", value: "responseBody" },
                            { label: "Test Results", value: "testResults" },
                          ].map((tab) => (
                            <button
                              key={tab.value}
                              onClick={() => handleTabChange(null, tab.value)}
                              className={`px-6 py-3 text-sm font-medium border-r last:border-r-0 ${activeTab === tab.value
                                ? "bg-[#FF5336] text-white"
                                : "text-postman-gray-medium hover:bg-gray-50"
                                }`}

                            >
                              {tab.label}
                            </button>
                          ))}
                        </div>

                        {/* Tab Content */}
                        <div className="flex-1 overflow-auto mt-2">
                          {activeTab === "queryParams" && (
                            <div className="flex flex-col space-y-2">
                              <textarea
                                value={queryParams}
                                onChange={(e) => setQueryParams(e.target.value)}
                                className="w-full h-[calc(100vh-300px)] p-4 font-mono text-sm border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-gray-50"
                                placeholder="Enter query parameters..."
                              />
                              <button
                                onClick={handleAddParam}
                                className="px-9 py-[12px] bg-postman-blue hover:bg-blue-700 rounded-md text-white font-semibold font-sans text-sm max-w-[150px]"
                              >
                                Submit
                              </button>
                            </div>
                          )}

                          {activeTab === "headers" && (
                            <textarea
                              value={headers}
                              onChange={(e) => setHeaders(e.target.value)}
                              className="w-full h-[calc(100vh-300px)] p-4 font-mono text-sm border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-gray-50"
                              placeholder="Enter headers..."
                            />
                          )}

                          {activeTab === "body" && (
                            <textarea
                              value={body}
                              onChange={(e) => setBody(e.target.value)}
                              className="w-full h-[calc(100vh-300px)] p-4 font-mono text-sm border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-gray-50"
                              placeholder="Enter request body..."
                            />
                          )}

                          {activeTab === "testScripts" && (
                            <textarea
                              value={jsonInput}
                              onChange={(e) => setJsonInput(e.target.value)}
                              className="w-full h-[calc(100vh-300px)] p-4 font-mono text-sm border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-gray-50"
                              placeholder="Enter test scripts..."
                            />
                          )}

                          {activeTab === "responseBody" && (
                            <div className="bg-gray-50 rounded-md border h-[calc(100vh-300px)] overflow-auto">
                              {response5?.originalResponse && (
                                <JsonView
                                  editable={true}
                                  enableClipboard={true}
                                  src={response5.originalResponse}
                                  style={
                                    response5.originalResponse.status === "error"
                                      ? {
                                        backgroundColor: "#fff8f8",
                                        color: "#d32f2f",
                                      }
                                      : undefined
                                  }
                                />
                              )}
                            </div>
                          )}

                          {activeTab === "testResults" && (
                            <div className=" rounded-md w-full min-h-full">
                              {chainTest === "chainResults" && (
                                <ChainTestResults results={chainResults} />
                              )}
                              {chainTest === "testResults" && (
                                <TestResults testResults={response5} />
                              )}
                              {chainTest === "endpointResults" && (
                                <TestEndpointCasesResults results={endpointResults} />
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Grid>
                </TabPanel>

                <TabPanel value="testCaseTab" maxWidth="100vh">
                  <div sx={{ minHeight: "600px", position: "relative" }}>
                    <div className="flex gap-4 ">
                      {!showTable && (
                        <button
                          onClick={() => setShowTable(true)}
                          className="px-4 py-2 bg-postman-orange text-white text-sm font-medium rounded-md hover:bg-postman-orange-dark focus:outline-none focus:ring-2 focus:ring-postman-orange focus:ring-offset-2 transition-colors"
                        >
                          Show Test table
                        </button>
                      )}
                      <button
                        onClick={() => handleBulkRequests(allData)}
                        className="px-4 py-2 bg-postman-orange text-white text-sm font-medium rounded-md hover:bg-postman-orange-dark focus:outline-none focus:ring-2 focus:ring-postman-orange focus:ring-offset-2 transition-colors"
                      >
                        {isLoading ? "Running Tests..." : `Run All Tests`}
                      </button>
                      {showTable && testResults && testResults.length > 0 && (
                        <button
                          onClick={() => setShowTable(false)}
                          className="px-4 py-2 bg-postman-orange text-white text-sm font-medium rounded-md hover:bg-postman-orange-dark focus:outline-none focus:ring-2 focus:ring-postman-orange focus:ring-offset-2 transition-colors"
                        >
                          Show Test summary
                        </button>
                      )}
                    </div>

                    {status.isGenerating && (
                      <div className="p-8 rounded-lg w-full flex items-center justify-center bg-muted my-4">
                        <Loader2 description={status.currentEndpoint} />
                      </div>
                    )}

                    {isLoading && (
                      <Box sx={{ width: "100%", my: 3 }}>
                        <LinearProgress
                          variant="determinate"
                          value={progress}
                          sx={{ height: 10, borderRadius: 1 }}
                        />
                        <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                          Testing: {currentTest} ({Math.floor((progress / 100) * allData.length)}/
                          {allData.length})
                        </Typography>
                      </Box>
                    )}
                    {allData && !showTable && (
                      <>
                        <div className="flex flex-col items-end w-full absolute right-10 top-0">
                          {selectedTestCases.length > 0 && (
                            <button
                              onClick={handleExecuteSelected}
                              className="px-10 py-4 bg-postman-orange text-white text-sm font-medium rounded-md hover:bg-postman-orange-dark focus:outline-none focus:ring-2 focus:ring-postman-orange focus:ring-offset-2 transition-colors"
                            >
                              Run Selected Tests
                            </button>
                          )}
                        </div>
                        <BulkApiTester results={testResults} testReport={testReport} />
                      </>
                    )}

                    <style>{loaderStyles}</style>

                    {/* {isDataLoading && (
                      <div className="loader-overlay">
                        <Circles
                          height="80"
                          width="80"
                          color="#1976d2"
                          ariaLabel="loading-indicator"
                        />
                      </div>
                    )} */}

                    {/* {error && <div className="error">{error}</div>} */}

                    {allData && showTable && (
                      <TestCasesTable
                        testCases={allData}
                        testResults={testResults}
                        selectedTestCases={selectedTestCases}
                        onTestCaseSelect={handlTestCaseSelect}
                        onMarkAsReviewed={handleMarkAsReviewed}
                        onSaveTestCase={handleSaveTestCase}
                      />
                    )}
                  </div>
                </TabPanel>
              </TabContext>
            </div>

            {loader && (
              <div className="loader-overlay">
                <Circles height="80" width="80" color="#1976d2" ariaLabel="loading-indicator" />
              </div>
            )}
            <ToastContainer />
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}

export default MainContent;