import { Box, Button, styled, Typography, Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CustomInput, CustomSelect, PageTitle } from "../components-new/common";
import { DeleteIcon, PlusIcon } from "../components-new/icons";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import {
  useCreateSuiteQuery,
  useModuleQuery,
  useProductQuery,
  useSearchInAllTestCase,
  useSearchInQuery,
  useSearchSuiteQuery,
  useTestCaseQuery,
  useTestSuiteQueryNew,
  useTestCasePaginationQuery,
} from "../useQuery";
import TestSuiteItems from "./TestSuite/TestSuiteItems";
import TestSuiteCases from "./TestSuite/TestSuiteCases";
import { useDispatch } from "react-redux";
import { updateSuiteList } from "../Redux/TestSuite/TestSuite";
import CustomDialog from "../components-new/common/CustomDialog";
import { useQueryClient } from "@tanstack/react-query";
import { toNumber } from "lodash";
import { useLocation } from "react-router-dom";

const StyledButton = styled(Button)`
  padding: 15px 28px;
  border-radius: 8px;
  text-transform: capitalize;
`;

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #e2e6f3;
  border-radius: 8px;
  cursor: pointer;
  padding: 9px 20px;
`;

const extractTestCases = (data, targetTestSuiteId = null) => {
  return data
    ?.flat()
    ?.find((item) => item.idtest_suite === toNumber(targetTestSuiteId))
    ?.list.map((item) => Object.values(item.testcases))
    ?.flat();

  // return data?.flatMap((suite) =>
  //   suite?.flatMap((suiteItem) =>
  //     suiteItem.list
  //       .filter((item) => targetTestSuiteId === null || item.TestSuiteId === targetTestSuiteId)
  //       .flatMap((item) => Object.values(item.testcases)),
  //   ),
  // );
};

const TestSuite = () => {
  const itemsPerRow = 4;
  const itemsPerPage = 52;
  const dispatch = useDispatch();
  const location = useLocation();
  const queryClient = useQueryClient();

  const [productId, setProductId] = useState(null);
  const [moduleId, setModuleId] = useState(null);
  // const [testCaseId, setTestCaseId] = useState(null);
  const [showTestCases, setShowTestCases] = useState(false);
  const [suiteId, setSuiteId] = useState(null);
  const [prodName, setProdName] = useState(null);
  const [titleSuiteName, setTitleSuiteName] = useState(null);
  const [isCreateModal, setIsCreateModal] = useState(false);
  const [suiteName, setSuiteName] = useState("");
  const [suiteData, setSuiteData] = useState([]);
  const [isSearched, setIsSearched] = useState(false);

  const { data: products } = useProductQuery();
  const { refetchWithParams: createTestSuite, data: createSuiteData } = useCreateSuiteQuery();
  const { data, refetch, isSuccess } = useTestSuiteQueryNew(productId, !!productId);
  const { refetchWithParams: fetchSuiteSearch, data: searchSuiteData } = useSearchSuiteQuery();
  const { refetchWithParams: fetchSearchInSuite, data: searchInSuiteData } = useSearchInQuery();
  const { refetchWithParams: fetchSearchAllTC, data: searchTCData } = useSearchInAllTestCase();
  const { data: modules, refetch: refetchModules } = useModuleQuery(productId, {
    enabled: false,
  });
  const { data: testCases, refetch: refetchTestCases } = useTestCaseQuery(moduleId, "", {
    enabled: false,
  });

  const [currentPage, setCurrentPage] = useState(1);

  const { data: testCaseData } = useTestCasePaginationQuery(moduleId, currentPage, {
    enabled: !!moduleId,
  });
  const totalPages = testCaseData?.[0]?.totalPages || 1;

  useEffect(() => {
    setCurrentPage(1);
  }, [moduleId]);

  useEffect(() => {
    if (location.state) {
      setProductId(location.state.prodId);
      setSuiteId(location.state.suiteId);
      setShowTestCases(true);
    }
  }, [location.state]);

  useEffect(() => {
    if (!createSuiteData) return;

    queryClient.invalidateQueries(["testSuiteNew"]);
  }, [createSuiteData, queryClient]);

  useEffect(() => {
    setSuiteData(searchSuiteData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchSuiteData]);

  useEffect(() => {
    if (isSuccess && data) {
      setSuiteData(data);
      dispatch(updateSuiteList(data.flat()));
    }
  }, [isSuccess, data, dispatch]);

  useEffect(() => {
    if (!productId) return;

    refetch();
    refetchModules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  useEffect(() => {
    if (!moduleId) return;
    refetchTestCases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleId]);

  const handleSuiteClick = (item) => {
    setTitleSuiteName(suiteData.flat().find((suite) => suite.idtest_suite === item).Test_Suite);
    setSuiteId(item);
    setShowTestCases((pre) => !pre);
  };

  const handleClearSelection = () => {
    setProductId(null);
    setModuleId(null);
    setShowTestCases(false);
    setSuiteName("");
  };

  const CreateActions = () => {
    return (
      <>
        <Button
          sx={{ color: "#ff5336" }}
          onClick={() => {
            setIsCreateModal(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{ background: "#ff5336" }}
          onClick={() => {
            createTestSuite(productId, suiteName);
            setSuiteName("");
            setIsCreateModal(false);
          }}
        >
          Create
        </Button>
      </>
    );
  };

  const handleSuiteSearch = () => {
    fetchSuiteSearch({
      pnum: 1,
      key: suiteName,
      prodid: productId,
    });
  };

  const handleSearchInSuite = () => {
    fetchSearchInSuite({
      pnum: 1,
      key: suiteName,
      suiteid: suiteId,
    });
  };

  const handleSearchAllTC = () => {
    fetchSearchAllTC({
      pnum: 1,
      key: suiteName,
      modid: moduleId,
    });
  };

  return (
    <>
      <CustomDialog
        title={"Are you sure you want to create test suite?"}
        open={isCreateModal}
        actions={<CreateActions />}
        content={
          <>
            <CustomInput
              labelinput="Suite Name"
              placeholder={"Enter Suite Name"}
              value={suiteName}
              onChange={(e) => setSuiteName(e.target.value)}
              name={"createSuiteModal"}
            />
          </>
        }
      />
      <Box sx={{ mt: 2, mb: 4 }} display="flex" alignItems="center" justifyContent="space-between">
        <PageTitle title="Test Suite" />
        <Box display="flex" alignItems="center">
          <StyledBox
            display="flex"
            alignItems="center"
            onClick={() => {
              setShowTestCases((prev) => !prev);
              setTitleSuiteName(null);
              setSuiteId(null);
            }}
          >
            <FolderOutlinedIcon />
            <Typography ml={1}>View All Test {!showTestCases ? "Cases" : "Suites"}</Typography>
          </StyledBox>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <Box display="flex" alignItems="center" columnGap={2} rowGap={2}>
          <CustomSelect
            label={"Product"}
            options={(Array.isArray(products) ? products : []).map((prodItem) => ({
              label: prodItem.productname,
              value: prodItem.idproducts,
            }))}
            width={"230px"}
            background={"#ffffff"}
            value={productId || ""}
            onChange={(e) => {
              setProdName(products.find((item) => item.idproducts === e.target.value).productname);
              setProductId(e.target.value);
              setModuleId(null);
            }}
          />
          {productId && (
            <CustomSelect
              label={"Module"}
              options={(Array.isArray(modules) ? modules : []).map((modItem) => ({
                label: modItem.modulename,
                value: modItem.idmodules,
              }))}
              width={"230px"}
              background={"#ffffff"}
              value={moduleId || ""}
              onChange={(e) => {
                setModuleId(e.target.value);
                // setTestCaseId(null);
              }}
            />
          )}
          <Button
            variant="outlined"
            startIcon={<DeleteIcon />}
            size="medium"
            onClick={handleClearSelection}
            sx={{
              color: "#828286",
              background: "#ffffff",
              height: "44px",
              border: "1px solid #E2E6F3",
              outline: 0,
              textTransform: "capitalize",
              mt: 2.5,
            }}
          >
            Clear filter
          </Button>
        </Box>
        <Box display="flex" alignItems="center" columnGap={2}>
          <Box display="flex" columnGap={2}>
            <CustomInput
              placeholder={showTestCases ? "Search test case" : "Search test suite"}
              value={suiteName}
              onChange={(e) => setSuiteName(e.target.value)}
              name={"createSuiteModal"}
            />
            <StyledButton
              disabled={!productId}
              variant="contained"
              onClick={() => {
                suiteName ? setIsSearched(true) : setIsSearched(false);
                if (!showTestCases) handleSuiteSearch();
                if (showTestCases && suiteId) handleSearchInSuite();
                if (showTestCases && moduleId) handleSearchAllTC();
              }}
            >
              Search
            </StyledButton>
          </Box>
          <StyledButton
            variant="contained"
            color="warning"
            onClick={() => setIsCreateModal(true)}
            endIcon={<PlusIcon />}
            sx={{
              backgroundColor: "#ff5336",
              boxShadow: "0px 16px 30.6px 0px rgba(255, 83, 54, 0.27)",
            }}
            disabled={!productId}
          >
            Create Suite
          </StyledButton>
        </Box>
      </Box>
      {!showTestCases && productId && (
        <TestSuiteItems
          data={suiteData}
          itemsPerPage={itemsPerPage}
          itemsPerRow={itemsPerRow}
          showTestCases={showTestCases}
          handleSuiteClick={handleSuiteClick}
          prodName={prodName}
        />
      )}

      {showTestCases && !isSearched && (
        <>
          <TestSuiteCases
            data={suiteId || !moduleId ? extractTestCases(data ?? [], suiteId) : testCaseData}
            itemsPerRow={itemsPerRow}
            showTestCases={showTestCases}
            testSuiteId={suiteId}
            setShowTestCases={setShowTestCases}
            selectedProdId={productId}
            titleSuiteName={titleSuiteName}
          />
          {!suiteId && (
            <Box py={2} display="flex" justifyContent="center">
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={(e, newpage) => setCurrentPage(newpage)}
                color="primary"
                variant="outlined"
                shape="rounded"
              />
            </Box>
          )}
        </>
      )}
      {showTestCases && isSearched && (
        <TestSuiteCases
          data={!moduleId ? searchInSuiteData : searchTCData}
          itemsPerRow={itemsPerRow}
          showTestCases={showTestCases}
          testSuiteId={suiteId}
          setShowTestCases={setShowTestCases}
          selectedProdId={productId}
        />
      )}
    </>
  );
};

export default TestSuite;
