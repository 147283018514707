import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

let params;

const fetchSearch = async () => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");
  const user = JSON.parse(userDetails);

  const response = await axios.get(
    `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=searchInAllSuite&companyid=${user.companyid}&token=${user.token}`,
    {
      params: params,
    },
  );

  return response.data;
};

const useSearchSuiteQuery = () => {
  const query = useQuery({
    queryKey: ["fetchSearchSuiute"],
    queryFn: () => {
      return fetchSearch(params);
    },
    enabled: false,
    retry: true,
    cacheTime: 90000,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    staleTime: Infinity,
  });

  const refetchWithParams = (paramsData) => {
    params = paramsData;
    query.refetch();
  };

  return {
    ...query,
    refetchWithParams,
  };
};

export default useSearchSuiteQuery;
