import { useState, useEffect } from "react";
import axios from "axios";

const usePolling = (url, shouldPoll = false, interval = 10000) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    let mounted = true;
    let timeoutId = null;

    const fetchData = async () => {
      if (!mounted) return false;

      // Only show loading on initial fetch, not during polling
      if (!data && !isEmpty) {
        setIsLoading(true);
      }

      try {
        const response = await axios.get(url);

        if (mounted) {
          // Check if data is empty
          if (!response.data || response.data.length === 0) {
            // if (!response.data) {
            setIsEmpty(true);
            setData(null);
          } else {
            setIsEmpty(false);
            setData(response.data);
          }
          setError(null);
          setIsLoading(false);
          return true;
        }
      } catch (error) {
        if (mounted) {
          setError(error.message);
          setData(null);
          setIsEmpty(false);
          setIsLoading(false);
        }
        return false;
      }
    };

    const pollData = async () => {
      if (!mounted) return;

      await fetchData();

      // Continue polling if shouldPoll is true and either data is empty or doesn't exist
      if (shouldPoll && mounted && (isEmpty || !data)) {
        timeoutId = setTimeout(pollData, interval);
      }
    };

    // Initial fetch
    pollData();

    // Cleanup function
    return () => {
      mounted = false;
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [url, interval, shouldPoll]);

  // Combine loading states for component
  const isLoadingState = isLoading || isEmpty;

  return { data, error, isLoading: isLoadingState };
};

export default usePolling;
