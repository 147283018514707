import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

let data;

const fetchTCTags = async () => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");
  const user = JSON.parse(userDetails);

  const response = await axios.get(
    `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getTCbyTags&companyid=${user.companyid}&token=${user.token}&product=${data.productId}&repo=${data.repo}&fromdate=${data.fromDate}&toDate=${data.toDate}&knowledge=${data.cognitive}`,
  );

  return response.data;
};

const useTCTagsQuery = () => {
  const query = useQuery({
    queryKey: ["TCTags"],
    queryFn: () => {
      return fetchTCTags(data);
    },
    enabled: false,
    retry: true,
    cacheTime: 90000,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    staleTime: Infinity,
  });

  const refetchWithParams = (payload) => {
    data = payload;
    query.refetch();
  };

  return {
    ...query,
    refetchWithParams,
  };
};

export default useTCTagsQuery;
