import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

const fetchTestCases = async ({ moduleId, pageNumber = 1 }) => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");
  const user = JSON.parse(userDetails);

  const response = await axios.get(
    `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=searchTCDropDown&companyid=${user.companyid}&token=${user.token}&modid=${moduleId}&pnum=${pageNumber}`,
  );

  return response.data;
};

const useTestCasePaginationQuery = (moduleId, pageNumber, options = {}) => {
  return useQuery({
    queryKey: ["searchTCDropDown", moduleId, pageNumber],
    queryFn: () => fetchTestCases({ moduleId, pageNumber }),
    enabled: false,
    ...options,
    retry: true,
  });
};

export default useTestCasePaginationQuery;
