import { useQuery } from "@tanstack/react-query";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

const fetchDownloadCode = async (id, name) => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");
  const user = JSON.parse(userDetails);

  try {
    const downloadUrl = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=genCode&companyid=${user.companyid}&token=${user.token}&testcaseid=${id}&testcasename=${name}`;
    window.location.href = downloadUrl;
  } catch (error) {
    console.log(error.response?.data?.error || error);
    throw error;
  }
};

const useDownloadCodeQuery = (id, name) => {
  return useQuery({
    queryKey: ["downloadcode", id],
    queryFn: () => fetchDownloadCode(id, name),
    retry: true,
    enabled: false,
    cacheTime: 90000,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    staleTime: Infinity,
  });
};

export default useDownloadCodeQuery;
