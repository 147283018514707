import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  issidebaropen: false,
  // isCodeGen: false,
};

export const SiteSettingsSlice = createSlice({
  name: "SiteSettings",
  initialState,
  reducers: {
    ChangeSideBar: (state, action) => {
      state.issidebaropen = action.payload;
    },
    // changeCodeGen: (state, action) => {
    //   state.isCodeGen = action.payload;
    // },
  },
});

// Action creators are generated for each case reducer function
export const { ChangeSideBar, changeCodeGen } = SiteSettingsSlice.actions;

export default SiteSettingsSlice.reducer;
