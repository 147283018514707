import React from "react";

const ImpactAnalysisIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="17" viewBox="0 0 19 17" fill="none">
    <path
      d="M10.1053 0.679688V1.52179H16V3.206H10.1053V14.9955H13.4737V16.6797H5.05263V14.9955H8.42105V3.206H2.52632V1.52179H8.42105V0.679688H10.1053ZM3.36842 4.33695L5.74989 6.71927C6.35958 7.32811 6.73684 8.17021 6.73684 9.10074C6.73684 10.9618 5.22947 12.4692 3.36842 12.4692C1.50737 12.4692 0 10.9618 0 9.10074C0 8.17021 0.377263 7.32811 0.986947 6.71927L3.36842 4.33695ZM15.1579 4.33695L17.5394 6.71927C18.1491 7.32811 18.5263 8.17021 18.5263 9.10074C18.5263 10.9618 17.0189 12.4692 15.1579 12.4692C13.2968 12.4692 11.7895 10.9618 11.7895 9.10074C11.7895 8.17021 12.1667 7.32811 12.7764 6.71927L15.1579 4.33695ZM3.36842 6.71927L2.17768 7.91C1.86274 8.22495 1.68421 8.646 1.68421 9.10074C1.68421 10.0313 2.43789 10.785 3.36842 10.785C4.29895 10.785 5.05263 10.0313 5.05263 9.10074C5.05263 8.646 4.87411 8.22495 4.55916 7.91L3.36842 6.71927ZM15.1579 6.71927L13.9672 7.91C13.6522 8.22495 13.4737 8.646 13.4737 9.10074C13.4737 10.0313 14.2274 10.785 15.1579 10.785C16.0884 10.785 16.8421 10.0313 16.8421 9.10074C16.8421 8.646 16.6636 8.22495 16.3486 7.91L15.1579 6.71927Z"
      fill="currentColor"
    />
  </svg>
);

export default ImpactAnalysisIcon;
