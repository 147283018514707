import React, { useState } from "react";
import { Modal, Box } from "@mui/material";

const TestCaseExplorer = ({ isOpen, handleClose, testCases }) => {
  const [selectedTestCase, setSelectedTestCase] = useState(testCases[0]);

  const steps = JSON.parse(selectedTestCase?.steps);

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        className="flex h-screen bg-white p-6 rounded-lg shadow-lg overflow-y-auto"
        sx={{ width: "80%", height: "90%", margin: "auto", outline: "none", mt: 5 }}
      >
        {/* Sidebar */}
        <div className="w-1/4 bg-gray-100 p-4 border-r">
          <h2 className="text-lg font-semibold mb-4">Test Cases</h2>
          <ul className="space-y-2">
            {testCases.map((testCase) => (
              <li
                key={testCase.id}
                onClick={() => setSelectedTestCase(testCase)}
                className={`p-2 cursor-pointer rounded-md ${
                  selectedTestCase.id === testCase.id
                    ? "bg-blue-500 text-white"
                    : "hover:bg-blue-100"
                }`}
              >
                {testCase.name}
              </li>
            ))}
          </ul>
        </div>

        {/* Main Content */}
        <div className="w-3/4 p-6 overflow-y-auto">
          {/* Header Section */}
          <h1 className="text-2xl font-bold mb-2">{selectedTestCase.name}</h1>
          <p className="text-gray-600 mb-4">{selectedTestCase.description}</p>
          <div className="mb-6">
            <span className="inline-block bg-blue-100 text-blue-800 text-sm px-2 py-1 rounded mr-2">
              {selectedTestCase.test_type.toUpperCase()}
            </span>
            <span className="text-gray-700">{selectedTestCase.validation_criteria}</span>
          </div>

          {/* Steps Section */}
          <div className="space-y-4">
            {steps?.map((step) => (
              <StepAccordion key={step.step_number} step={step} />
            ))}
          </div>
        </div>
      </Box>
    </Modal>
  );
};

const StepAccordion = ({ step }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border rounded-lg">
      {/* Accordion Header */}
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="p-4 flex justify-between items-center cursor-pointer bg-gray-50 hover:bg-gray-100"
      >
        <div>
          <span className="font-medium">Step {step.step_number}:</span> {step.test_case}
        </div>
        <span className="text-sm bg-green-100 text-green-800 px-2 py-1 rounded">
          {step.http_method}
        </span>
      </div>

      {/* Accordion Content */}
      {isOpen && (
        <div className="p-4 space-y-2">
          <div>
            <strong>Endpoint:</strong> <span className="text-blue-600">{step.endpoint}</span>
          </div>
          <div>
            <strong>Headers:</strong>
            <KeyValueList data={step.headers} />
          </div>
          <div>
            <strong>Input Parameters:</strong>
            <KeyValueList data={step.input_parameters} />
          </div>
          <div>
            <strong>Expected Status Code:</strong> {step.expected_status_code}
          </div>
          <div>
            <strong>Expected Result:</strong> {step.expected_result}
          </div>
          <div>
            <strong>Output Mapping:</strong>
            <KeyValueList data={step.output_mapping} />
          </div>
        </div>
      )}
    </div>
  );
};

const KeyValueList = ({ data }) => {
  return (
    <ul className="pl-4 list-disc text-gray-700">
      {Object.entries(data).map(([key, value]) => (
        <li key={key}>
          <strong>{key}:</strong> {value}
        </li>
      ))}
    </ul>
  );
};

export default TestCaseExplorer;
