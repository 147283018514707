import React from "react";
import { Box, IconButton } from "@mui/material";
import PageTitle from "../components-new/common/PageTitle";
import DesignIcon from "../components-new/icons/DesignIcon";
import ExecutionIcon from "../components-new/icons/ExecutionIcon";
import OptimizationIcon from "../components-new/icons/OptimizationIcon";
import TabComponent from "../components-new/common/TabComponent";
import { DesignSection, ExecutionSection, OptimizationSection } from "./Dash";
import ProjectDashboard from "./Dash/ProjectDashboard";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import BugReportOutlinedIcon from "@mui/icons-material/BugReportOutlined";
import ChecklistRtlOutlinedIcon from "@mui/icons-material/ChecklistRtlOutlined";
import TestCase from "./Dash/TestCase";

import HighCriticalDefects from "./Dash/HighCriticalDefects";

const Dashboard = () => {
  const tabs = [
    {
      icon: <DesignIcon />,
      label: "Design",
      content: <DesignSection />,
    },
    {
      icon: <ExecutionIcon />,
      label: "Execution",
      content: <ExecutionSection />,
    },
    {
      icon: <OptimizationIcon />,
      label: "Optimization",
      content: <OptimizationSection />,
    },
    {
      icon: <AccountTreeOutlinedIcon />,
      label: "Project Dashboard",
      content: <ProjectDashboard />,
    },
    {
      icon: <ChecklistRtlOutlinedIcon />,
      label: "Test Cases",
      content: <TestCase />,
    },
    {
      icon: <BugReportOutlinedIcon />,
      label: "High - Critical Defects",
      content: <HighCriticalDefects />,
    },
  ];

  return (
    <Box sx={{ py: 4 }}>
      <PageTitle title="Dashboard" />
      <TabComponent tabs={tabs} />
    </Box>
  );
};

export default Dashboard;
