import React from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";
import { Search } from "@mui/icons-material";

const HighCriticalDefects = () => {
  const agingData = [
    { range: "0-5", Critical: 27, High: 109 },
    { range: "6-10", Critical: 6, High: 26 },
    { range: "11+", Critical: 7, High: 26 },
  ];

  const defectsData = [
    {
      id: "243860",
      sprint: "Sprint 9",
      state: "Assigned",
      stateChangeDate: "7/22/2023",
      title:
        'iConnect Amendment | displays "Please subscribe product" while initiating Amendment service req',
    },
    {
      id: "243428",
      sprint: "Peripheral Systems",
      state: "Assigned",
      stateChangeDate: "7/22/2023",
      title:
        "iManage | Checker Action and Edit iManage Auth Rules | Approver 1 unable to create authorization rule from detail screen",
    },
    {
      id: "241377",
      sprint: "Peripheral Systems",
      state: "Assigned",
      stateChangeDate: "7/19/2023",
      title:
        "iManage | Checker Action - iManage Auth Rules - List try to Authorize or Reject from screen then application selected records on listing",
    },
  ];

  return (
    <div className="min-h-screen bg-gray-50 p-4">
      <div className="bg-black text-white p-4 rounded-t-lg">
        <h1 className="text-2xl font-bold">High & Critical Defects Details</h1>
      </div>

      <div className="bg-white rounded-lg shadow p-6">
        {/* Search Filters */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
          {/* Project Search */}
          <div className="border border-gray-200 rounded-lg p-4">
            <div className="flex items-center gap-2 mb-2">
              <Search className="w-4 h-4 text-gray-600" />
              <input
                type="text"
                placeholder="Search Project"
                className="w-full border-b border-gray-300 focus:outline-none focus:border-black"
              />
            </div>
            <div className="space-y-2">
              {["CIB", "Intuition", "ODD", "RIB"].map((item) => (
                <div
                  key={item}
                  className="border border-gray-200 rounded p-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                >
                  {item}
                </div>
              ))}
            </div>
          </div>

          {/* Sprint Search */}
          <div className="border border-gray-200 rounded-lg p-4">
            <div className="flex items-center gap-2 mb-2">
              <Search className="w-4 h-4 text-gray-600" />
              <input
                type="text"
                placeholder="Search Sprint"
                className="w-full border-b border-gray-300 focus:outline-none focus:border-black"
              />
            </div>
            <div className="space-y-2">
              {["Peripheral Systems", "Regression", "SIT", "Sprint 1"].map((item) => (
                <div
                  key={item}
                  className="border border-gray-200 rounded p-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                >
                  {item}
                </div>
              ))}
            </div>
          </div>

          {/* State Search */}
          <div className="border border-gray-200 rounded-lg p-4">
            <div className="flex items-center gap-2 mb-2">
              <Search className="w-4 h-4 text-gray-600" />
              <input
                type="text"
                placeholder="Search State"
                className="w-full border-b border-gray-300 focus:outline-none focus:border-black"
              />
            </div>
            <div className="space-y-2">
              {["Active", "Assigned", "Dev In Progress", "Needs Clarification"].map((item) => (
                <div
                  key={item}
                  className="border border-gray-200 rounded p-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Status Filters and Count */}
        <div className="grid grid-cols-1 md:grid-cols-5 gap-4 mb-6">
          <div className="col-span-3">
            <div className="grid grid-cols-3 gap-2">
              {["Open", "Ready for Retest", "Reopened"].map((status) => (
                <div
                  key={status}
                  className="border border-gray-200 rounded p-2 text-center text-gray-700 hover:bg-gray-100 cursor-pointer"
                >
                  {status}
                </div>
              ))}
            </div>
            <div className="grid grid-cols-2 gap-2 mt-2">
              {["Critical", "High"].map((severity) => (
                <div
                  key={severity}
                  className="border border-gray-200 rounded p-2 text-center text-gray-700 hover:bg-gray-100 cursor-pointer"
                >
                  {severity}
                </div>
              ))}
            </div>
          </div>
          <div className="col-span-2">
            <div className="border border-gray-200 rounded-lg p-8 text-center">
              <span className="text-5xl font-bold text-gray-800">201</span>
            </div>
          </div>
        </div>

        {/* Charts and Table */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Aging Chart */}
          <div className="border border-gray-200 rounded-lg p-4">
            <h3 className="text-lg font-bold text-gray-800 mb-4">Open Defects Aging by Severity</h3>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={agingData}>
                <XAxis dataKey="range" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="Critical" fill="red" />
                <Bar dataKey="High" fill="blue" />
              </BarChart>
            </ResponsiveContainer>
          </div>

          {/* Defects Table */}
          <div className="border border-gray-200 rounded-lg p-4">
            <table className="w-full">
              <thead className="bg-gray-800 text-white">
                <tr>
                  <th className="p-2 text-left">Defect ID</th>
                  <th className="p-2 text-left">Sprint</th>
                  <th className="p-2 text-left">State</th>
                  <th className="p-2 text-left">State Change Date</th>
                  <th className="p-2 text-left">Title</th>
                </tr>
              </thead>
              <tbody>
                {defectsData.map((defect) => (
                  <tr key={defect.id} className="border-b border-gray-200 hover:bg-gray-50">
                    <td className="p-2">{defect.id}</td>
                    <td className="p-2">{defect.sprint}</td>
                    <td className="p-2">{defect.state}</td>
                    <td className="p-2">{defect.stateChangeDate}</td>
                    <td className="p-2 truncate max-w-xs" title={defect.title}>
                      {defect.title}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HighCriticalDefects;
