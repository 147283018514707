import React, { useEffect } from "react";
import "./AdminPage.style.scss";
import "./Admin.css"

import {
  Box,
  Card,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
} from "@mui/material";

import {
  PrimaryColor,
  SecondaryColor,
  ColorButton,
  StyledTableRow,
  StyledTableCell,
} from "./../../components/common/common.Component";
import { Button, TextField, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import Dialog from "@mui/material/Dialog";
import { useState } from "react";
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { makeStyles } from '@mui/styles';
import axios from "axios";
import { API_BASE_PATH, API_BASE_RELATIVE_PATH } from "../../Config/Config";
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import {
  AddProductAPI,
  AddCompanyURLAPI,
  AddModuleAPI,
  addEmailCredentialApi,
  addDbCredentialApi,
  fetchEmailCredentialDetails,
  fetchDbCredentialDetails,
  fetchUserDetails,
  getUserApproved,
  getuserReset,
  clearStorage,
  googleAuthcredentials,
  getStaticIntegrations,
  getIntegrations,
  addIntegration,
  deleteIntegrations,
  getUSerRoles,
  updateUserType,
  getModulePermissions,
  updateModulePerm,
} from "./../../API/adminAPI";
import { fetchProducts, fetchModules } from "./../../API/CreateCollectionAPI";
import { colorExport } from "./../../components/common/common.Component";
import { CommonMethods } from "./../../components/common/CommonMethods";
import { useNavigate } from "react-router-dom";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import Checkbox from '@mui/material/Checkbox';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`full-width-tabpanel-${index}`}
//       aria-labelledby={`full-width-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box sx={{ p: 3 }}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const AdminPage = () => {
  const [toDate, setToDate] = useState(null);
  const [newProductName, setNewProductName] = useState("");
  
  const [newModuleName, setNewModuleName] = useState("");
  const [ProductOpenDialog, setProductOpenDialog] = useState(false);
  const [productList, setproductList] = useState([]);
  const [product, setproduct] = useState("");
  const [ModuleOpenDialog, setModuleOpenDialog] = useState(false);
  const [userData, setUserData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openAlert, setOpenAlert] = useState(false);
  const [DeleteAlert, setDeleteAlert] = useState(false);
  const [moduleList, setModuleList] = useState([])
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  const handleCloseDeleteAlert = () => {
    setDeleteAlert(false);
  }
  const DBTypeLIstData = [
    { value: "Oracle", name: "Oracle" },
    { value: "MongoDB", name: "MongoDB" },
    { value: "MYSQL", name: "MySQL" },
    { value: "MSSQL", name: "MS SQL" },
    { value: "Other", name: "Other" },
  ];

  const [credEmailData, setEmailCredData] = useState({
    idproducts: "",
    port: "",
    host: "",
    protocol: "",
    action: "",
    sendport: "",
    sendhost: "",
  });

  const [credDbData, setDbCredData] = useState({
    idproducts: "",
    custDbType: "",
    custDbUrl: "",
    custDbUsername: "",
    custDbPwd: "",
    DBOtherType: "",
  });
  const [cloudType, setCloudType] = useState({
    idproducts: "",
    cloudTypes: "",
    url: "",
    token: "",
    userName: "",
    password: "",
  })

  const colors = colorExport();
  const userDetails = localStorage.getItem("colorcode");
  const temp = JSON.parse(userDetails);

  const [TestDataDetails, setTestDataDetails] = useState({
    file: "",
    filename: "",
    primary: temp.primaryColor,
    secondary: temp.secondaryColor,
    tertiary: temp.tertiaryColor,
    companyurl: temp.companyurl
  })

  const [companyURL, setCompanyURL] = useState(temp.companyurl);

  const [userGroups, setUserGroup] = useState({
    idproducts: "",
    idmodules: "",
  })
  const theme = useTheme();
  const [value, setValue] = React.useState("1");

  const [savenextmessage, setsavenextmessage] = React.useState(false);
  const [format, setFormat] = React.useState("");
  const [snackBarMsg, setSnackBarMsg] = React.useState("");
  const closeSuccessToast = () => {
    setsavenextmessage(false)
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const navigate = useNavigate();

  const handleModuleClickOpen = () => {
    setModuleOpenDialog(true);
  };
  const handleModuleClose = () => {
    setModuleOpenDialog(false);
  };

  const handleProductClickOpen = () => {
    setProductOpenDialog(true);
  };
  const handleProductClose = () => {
    setProductOpenDialog(false);
  };

  const handleFileChange = (e) => {
    if (e.target.files) {
      setTestDataDetails({
        ...TestDataDetails,
        file: e.target.files,
        filename: e.target.files[0].name,
      });
    }
  };


  const handleUpload = () => {
    const userDetails = localStorage.getItem("userDetail");
    const temp = JSON.parse(userDetails);
    console.log("sanjeev test above")
    if (!TestDataDetails.file[0]) {
      console.log("sanjeev test")
      const Response = axios
        .post(
          `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=addBranding&companyid=${temp.companyid}&token=${temp.token}&primaryColor=${encodeURIComponent(TestDataDetails.primary)}&secondaryColor=${encodeURIComponent(TestDataDetails.secondary)}&tertiaryColor=${encodeURIComponent(TestDataDetails.tertiary)}&brandLogo=${'null'}`,
          TestDataDetails
        )
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error.response.data.error);
        });
      return Response;
    } else {
      console.log("sanjeev test")
      fetch(`${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=addBranding&companyid=${temp.companyid}&token=${temp.token}&primaryColor=${encodeURIComponent(TestDataDetails.primary)}&secondaryColor=${encodeURIComponent(TestDataDetails.secondary)}&tertiaryColor=${encodeURIComponent(TestDataDetails.tertiary)}&brandLogo=${TestDataDetails.file[0].name}`,
        {

          method: "POST",
          body: TestDataDetails.file[0],
          headers: {
            "content-type": TestDataDetails.file[0].type,
            "content-length": `${TestDataDetails.file[0].size}`, // 👈 Headers need to be a string
          },
        })
        .then((res) => res.json())
        .catch((err) => console.error(err));
    }
  }

  const handleUploaddata = () => {

    const userDetails = localStorage.getItem("userDetail");
    const temp = JSON.parse(userDetails);
    const Response = axios
      .get(
        `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getBranding&companyid=${temp.companyid}&token=${temp.token}`
      )
      .then((response) => {
        localStorage.setItem(

          "colorcode",
          JSON.stringify({
            brandLogo: (response.data.brandLogo),
            primaryColor: (response.data.primaryColor),
            secondaryColor: (response.data.secondaryColor),
            tertiaryColor: (response.data.tertiaryColor),
            companyurl: (response.data.companyurl),
          })
        )

        navigate("/dashboard/app");

      })
      .catch((error) => {
        console.log(error.response.data.error);
      });
    return Response;
  }
  //clearStorage
  const setClearStorage = async (data) => {
    await clearStorage(data)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            // console.log("saveCredentialDetail", response);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const saveEmailCredentialDetail = async (data) => {
    await addEmailCredentialApi(data)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            console.log("saveCredentialDetail", response);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const saveDbCredentialDetail = async (data) => {
    await addDbCredentialApi(data)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            console.log("saveCredentialDetail", response);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getEmailCredentialDetail = async (api_input) => {
    await fetchEmailCredentialDetails(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            {
              Object.keys(response.data).length > 0
                ? setEmailCredData({
                  idproducts: api_input,
                  port: response.data.port,
                  host: response.data.host,
                  protocol: response.data.protocol,
                  sendport: response.data.sendport,
                  sendhost: response.data.sendhost,
                })
                : setEmailCredData({
                  idproducts: api_input,
                  port: "",
                  host: "",
                  protocol: "",
                });
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDbCredentialDetail = async (api_input) => {
    await fetchDbCredentialDetails(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            const custDbType = response.data.custDbType;
            const otherDatabaseType = DBTypeLIstData.find(item => item.value === custDbType) ? custDbType : "Other";
            {
              Object.keys(response.data).length > 0
                ? setDbCredData({
                  idproducts: api_input,
                  custDbType: otherDatabaseType,
                  custDbUrl: response.data.custDbUrl,
                  custDbUsername: response.data.custDbUsername,
                  custDbPwd: response.data.custDbPwd,
                  DBOtherType: response.data.custDbType, //custDbType
                })
                : setDbCredData({
                  idproducts: api_input,
                  custDbType: "",
                  custDbUrl: "",
                  custDbUsername: "",
                  custDbPwd: "",
                  DBOtherType: ""
                });
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProducts = async (api_input) => {
    await fetchProducts(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setproductList(response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getModules = async (api_input) => {
    await fetchModules(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setModuleList(response.data);
            console.log("resopooo", response.data);
          }
        }
      }).catch((error) => {
        console.log(error);
      })
  }
  const handleUserTypeChanges = (event, userId) => {
    // const newUserProfiles = userData.map(user => {
    //   if (user.iduserprofile === userId) {
    //     user.usertype = event.target.value === 'SUPER' ? 1 : event.target.value === 'USER' ? 2 : event.target.value === 'COMPANYADMIN' ? 3 : event.target.value === "QA USER" ? 4 : event.target.value === "QA ADMIN" ? 5 : event.target.value === "DEV USER" ? 6 : event.target.value === "DEV ADMIN" ? 7 : 0;
    //   }
    //   return user;
    // });
    const newUserProfiles = userData.map((user) => {
      if (user.iduserprofile === userId) {
        const selectedUserRole = userRole.find((item) => item.user_role === event.target.value);
        user.usertype = selectedUserRole ? selectedUserRole.iduser_roles : 0;
      }
      return user;
    });
    setUserData(newUserProfiles);
  };
  const handlegetModulepermission = (event, userId) => {
    const newUserProfiles = userRole.map((user) => {
      if (user.iduser_roles === userId) {
        const selectedUserRole = modulePermission.find((item) => item.roleid === event.target.value);
        user.iduser_roles = selectedUserRole ? selectedUserRole.iduser_roles : 0;
      }
      return user;
    });
    setUserRole(newUserProfiles);
  }
  const [userRole, setUserRole] = useState([]);
  const handlegetUSerRoles = async () => {
    await getUSerRoles()
      .then((response) => {
        setUserRole(response.data)
      }).catch((error) => {
        console.log(error);
      })
  }
  useEffect(() => {
    handlegetUSerRoles();
  }, [])
  const handleupdateUserType = async (iduserprofile, usertype) => {
    await updateUserType(iduserprofile, usertype)
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate);
        }
        else {
          const updatedUserData = userData.map((user) =>
            user.iduserprofile === iduserprofile ? { ...user, usertype } : user
          );

          setUserData(updatedUserData);

        }
      })
      .catch((error) => {
        console.log(error);
      })
  }
  const handleupdateModulePerm = async (moduleid, roleid, perm) => {
    await updateModulePerm(moduleid, roleid, perm)
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate);
        }
        else {
          const updatedModulePermission = modulePermission.map((item) =>
            item.roleid === roleid ? { ...item, permission: perm } : item
          );

          setModulePermission(updatedModulePermission);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }
  const [modulePermission, setModulePermission] = useState([])
  const getModulePermission = async (idmodules) => {
    await getModulePermissions(idmodules)
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate)
        } else {
          setModulePermission(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }
  const sendProduct = async (name) => {
    await AddProductAPI(name)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            console.log("add module succesful");
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sendCompanyURL = async (name) => {
    await AddCompanyURLAPI(name)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            console.log("Company URL Updated Successfully");
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formatDate = (date) => {
    if (date) {
      return dayjs(date).format('DD/MM/YYYY');
    }
    return '';
  };

  const sendModule = async (id, name) => {
    await AddModuleAPI(id, name)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            console.log("add module succesful");
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getProducts();
    getUserData();
  }, []);
  const getUserData = async (id) => {
    await fetchUserDetails(id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === 'fail') {
            console.log('error')
          }
          //   if (response.data[0].status == 3) {
          //     setUserData(response.data);
          //  }
          else {
            setUserData(response.data);

          }
        }
      }).catch((error) => {
        console.log('get user details error', error)
      })
  }
  const SnackPosition = {
    vertical: 'top',
    horizontal: 'center',
    duration: 6000,
  }
  const userApproved = async (id) => {
    await getUserApproved(id)
      .then((response) => {
        if (response.status === 200) {
          console.log('userApproved', response)
          if (response.data.message === 'fail') {
            console.log('error')
            setSnackBarMsg(response.data);
            setsavenextmessage(true);
            setFormat('error');
          }
          else {
            getUserData()
            // window.location.reload();
            setSnackBarMsg(response.data);
            setsavenextmessage(true);
            setFormat('success');

          }
        }
      }).catch((error) => {
        console.log('get user approved error', error)
      })
  }
  const userReset = async (id) => {
    await getuserReset(id)
      .then((response) => {
        if (response.status === 200) {
          console.log('userApproved', response)
          if (response.data.message === 'fail') {
            console.log('error');
            setSnackBarMsg(response.data);
            setsavenextmessage(true);
            setFormat('error');

          }
          else {
            console.log('user reset', response)
            getUserData()
            // window.location.reload();
            setSnackBarMsg(response.data);
            setsavenextmessage(true);
            setFormat('success');
          }
        }
      }).catch((error) => {
        console.log('get user approved error', error)
      })
  }
  const [uploadedFile, setUploadedFile] = useState({
    file: "",
    filename: "",
  });
  const handleFileUpload = (e) => {
    // const file = event.target.files[0];
    // console.log('Selected file:', file.name);
    // setUploadedFile(file);
    if (e.target.files) {
      console.log("e.target.files", e.target.files)
      console.log("e------------> ", e)
      setUploadedFile({
        file: e.target.files,
        filename: e.target.files[0].name,
      });
    }
  };
  // const uploadGooleOauth = async () => {
  //   await googleAuthcredentials()
  //     .then((response) => {
  //       if (response.status === 200 && response.data.message === "Fail") {
  //         CommonMethods(navigate);
  //       } else {
  //         console.log(response);
  //      }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //   })
  // }
  const uploadGooleOauth = () => {
    const userDetails = localStorage.getItem("userDetail");
    const temp = JSON.parse(userDetails);
  
    fetch(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=gcreds&companyid=${temp.companyid}&token=${temp.token}&fileName=${uploadedFile.file[0].name}`,
      {
        method: "POST",
        body: uploadedFile.file[0],
        headers: {
          "content-type": uploadedFile.file[0].type,
          "content-length": `${uploadedFile.file[0].size}`,
        }
      }
    )
    .then((res) => res.json())
    .then((data) => {
      // Assuming the JSON response contains a URL field
      const { url } = data;
  
      // Open a new tab with the URL from the JSON response
      window.open(url, '_blank');
    })
    .catch((error) => {
      console.log(error);
    });
  }
  const [integrationDatas, setIntegratonData] = useState([]);
  const [selectedIntegration, setSelectedIntegration] = useState([]);

  const getIntegrationDrop = async (id, name) => {
    await getIntegrations(id, name)
      .then((response) => {
        if (response.status == 200 && response.data.message === "fail") {
          CommonMethods(navigate)
        } else {
          setIntegratonData(response.data);
        }
      }).catch((error) => {
        console.log(error)
      })
  }
  const AdminIntegration = async () => {
    await getStaticIntegrations()
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate)
        } else {
          // setIntegratonData(response.data);
          setSelectedIntegration(response.data)
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }
  const [selectIntegration, setSelectIntegration] = useState(null);
  const [intName, setIntName] = useState(null);
  const handleIntegrationChange = (event) => {
    const integrationId = event.target.value;
    setSelectIntegration(integrationId);
    const name = selectedIntegration.map((item, index) => (
      item.staticintid === event.target.value ? item.intname : null
    ))
    const nameNew = name.find((value) => value !== null);
    setIntName(nameNew);
    getIntegrationDrop(cloudType.idproducts, nameNew);
  };
  const renderTextFields = () => {
    if (!selectIntegration) return null;

    let selectedIntegrationData = selectedIntegration.find(
      (item) => item.staticintid === selectIntegration
    );
    if (!selectedIntegrationData) return null;
    const adminAttributesArray = selectedIntegrationData.admin_attributes ? selectedIntegrationData.admin_attributes.split(',') : []
    // const valueTextField = (integrationDatas || Object.keys(integrationDatas).length !== 0) ? integrationDatas.adminprop.split(',') : null
    const valueTextField = integrationDatas.adminprop ? integrationDatas.adminprop.split(',') : [];
    return adminAttributesArray.map((attribute, index) => (
      <div key={index} className="input-group">
        <label>{attribute}</label>
        <TextField
          value={valueTextField[index] || ""}
          onChange={(event) => {
            event.preventDefault();
            const updatedValueTextField = [...valueTextField];
            updatedValueTextField[index] = event.target.value;
            setIntegratonData((prevIntegrationDatas) => ({
              ...prevIntegrationDatas,
              adminprop: updatedValueTextField.join(','),
            }));
          }}
          type="text-cred"
          fullWidth
        />
      </div>
    ));
  };
  const AddStaticIntegration = async (id, intName, adminprop) => {
    await addIntegration(id, intName, adminprop)
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate);
        }
        else {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }
  const IntegrationDelete = async (intid) => {
    await deleteIntegrations(intid)
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate);
        } else {
          const updatedIntegrationData = {
            ...integrationDatas,
            adminprop: null
          };
          setIntegratonData(updatedIntegrationData);
          setDeleteAlert(true);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }
  const Properties = () => {
    return (
      <>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="card-container">
            <div className="column">
              <div className="card" style={{ backgroundColor: colors.tertiaryColor }}>
                <h3>Edit Customer Email Property</h3>
                <div className="input-group">
                  <label>Product:</label>
                  <Select
                    value={credEmailData.idproducts}
                    onChange={(event) => {
                      setEmailCredData({
                        ...credEmailData,
                        idproducts: event.target.value,
                      });
                      getEmailCredentialDetail(event.target.value);
                    }}
                    style={{
                      alignItems: "left",
                      height: "2.5rem",
                      backgroundColor: colors.tertiaryColor,
                    }}
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {productList.map((item, index) => (
                      <MenuItem key={index} value={item.idproducts}>
                        {item.productname}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                {/* // */}
                <div className="input-group">
                  <label>Action:</label>
                  <Select
                    value={credEmailData.action}
                    onChange={(event) => {
                      setEmailCredData({
                        ...credEmailData,
                        action: event.target.value,
                      });
                    }}
                    style={{
                      alignItems: "left",
                      height: "2.5rem",
                      backgroundColor: colors.tertiaryColor,
                    }}
                    fullWidth
                  >
                    <MenuItem value="ReadEmail">Read Email</MenuItem>
                    <MenuItem value="sendEmail">Send Email</MenuItem>
                  </Select>
                </div>
                {/* // */}
                {credEmailData.action === 'ReadEmail' && (
                  <>
                    <div className="input-group">
                      <label>Port :</label>
                      <TextField
                        type="text-cred"
                        value={credEmailData.port}
                        onChange={(event) => {
                          event.preventDefault();
                          setEmailCredData({
                            ...credEmailData,
                            port: Number(event.target.value),
                          });
                        }}
                        fullWidth
                      />
                    </div>
                    <div className="input-group">
                      <label>Host :</label>
                      <TextField
                        type="text-cred"
                        value={credEmailData.host}
                        onChange={(event) => {
                          event.preventDefault();
                          setEmailCredData({ ...credEmailData, host: event.target.value });
                        }}
                        fullWidth
                      />
                    </div>
                    <div className="input-group">
                      <label>Protocol :</label>
                      <TextField
                        value={credEmailData.protocol}
                        onChange={(event) => {
                          event.preventDefault();
                          setEmailCredData({ ...credEmailData, protocol: event.target.value });
                        }}
                        type="text-cred"
                        fullWidth
                      />
                    </div>
                  </>
                )}
                {credEmailData.action === "sendEmail" && (
                  <>
                    <div className="input-group">
                      <label>Port :</label>
                      <TextField
                        type="text-cred"
                        value={credEmailData.sendport}
                        onChange={(event) => {
                          event.preventDefault();
                          setEmailCredData({
                            ...credEmailData,
                            sendport: Number(event.target.value),
                          });
                        }}
                        fullWidth
                      />
                    </div>
                    <div className="input-group">
                      <label>Host :</label>
                      <TextField
                        type="text-cred"
                        value={credEmailData.sendhost}
                        onChange={(event) => {
                          event.preventDefault();
                          setEmailCredData({ ...credEmailData, sendhost: event.target.value });
                        }}
                        fullWidth
                      />
                    </div>
                  </>
                )}
                <div
                  className="input-group"
                  style={{ justifyContent: "flex-end" }}
                >
                  <ColorButton
                    size="small"
                    variant="contained"
                    sx={{ margin: "10px 10px 2px 10px" }}
                    onClick={() => {
                      saveEmailCredentialDetail(credEmailData);
                    }}
                    className="executebutton"
                    style={{ alignItem: "center" }}
                  >
                    Submit
                  </ColorButton>
                </div>
              </div>
              <div className="card" style={{ backgroundColor: colors.tertiaryColor }}>
                <h3 style={{ marginTop: "20px" }}>Edit Customer Database Property</h3>
                <div className="input-group">
                  <label>Product:</label>
                  <Select
                    value={credDbData.idproducts}
                    onChange={(event) => {
                      setDbCredData({
                        ...credDbData,
                        idproducts: event.target.value,
                      });
                      getDbCredentialDetail(event.target.value);
                    }}
                    style={{
                      alignItems: "left",
                      height: "2.5rem",
                      backgroundColor: colors.tertiaryColor,
                    }}
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {productList.map((item, index) => (
                      <MenuItem key={index} value={item.idproducts}>
                        {item.productname}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="input-group">
                  <label>DB Type:</label>
                  <Select
                    value={credDbData.custDbType}
                    onChange={(event) => {
                      // {console.log("credDbData.DBOtherType", credDbData.DBOtherType)}
                      // {console.log("credDbData.custDbType", credDbData.custDbType)}

                      setDbCredData({
                        ...credDbData,
                        custDbType: event.target.value,
                        DBOtherType: undefined,
                      });
                    }}
                    type="text-cred"
                    style={{
                      alignItems: "left",
                      height: "2.5rem",
                      backgroundColor: colors.tertiaryColor,
                    }}
                    fullWidth
                  >
                    {DBTypeLIstData.map((item, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="input-group">
                  {credDbData.custDbType === "Other" && (
                    <>
                      <label>Type :</label>
                      <TextField
                        value={credDbData.DBOtherType}
                        onChange={(event) => {
                          event.preventDefault();
                          setDbCredData((prev) => {
                            return {
                              ...prev,
                              DBOtherType: event.target.value,
                            };
                          });
                        }}
                        type="text-cred"
                        fullWidth
                      />
                    </>
                  )}
                </div>
                <div className="input-group">
                  <label>DB Url :</label>
                  <TextField
                    value={credDbData.custDbUrl}
                    onChange={(event) => {
                      event.preventDefault();
                      setDbCredData((prev) => {
                        return {
                          ...prev,
                          custDbUrl: event.target.value,
                        };
                      });
                    }}
                    type="text-cred"
                    fullWidth
                  />
                </div>
                <div className="input-group">
                  <label>Username:</label>
                  <TextField
                    value={credDbData.custDbUsername}
                    onChange={(event) => {
                      event.preventDefault();
                      setDbCredData((prev) => {
                        return {
                          ...prev,
                          custDbUsername: event.target.value,
                        };
                      });
                    }}
                    type="text-cred"
                    fullWidth
                  />
                </div>
                <div className="input-group">
                  <label>Password:</label>
                  <TextField
                    value={credDbData.custDbPwd}
                    type="password"
                    inputProps={{
                      style: {
                        padding: 7,
                      },
                    }}
                    onChange={(event) => {
                      event.preventDefault();
                      setDbCredData((prev) => {
                        return {
                          ...prev,
                          custDbPwd: event.target.value,
                        };
                      });
                    }}
                    fullWidth
                  />
                </div>
                <div
                  className="input-group"
                  style={{ justifyContent: "flex-end" }}
                >
                  <ColorButton
                    size="small"
                    variant="contained"
                    sx={{ margin: "10px 10px 2px 10px" }}
                    onClick={() => {
                      saveDbCredentialDetail(credDbData);
                    }}
                    className="executebutton"
                    style={{ alignItem: "center" }}
                  >
                    Submit
                  </ColorButton>
                </div>
              </div>
              <div className="card" style={{ backgroundColor: colors.tertiaryColor }}>
                <h3 style={{ marginTop: "20px" }}>Integrations</h3>
                <div className="input-group">
                  <label>Product:</label>
                  <Select
                    value={cloudType.idproducts}
                    onChange={(event) => {
                      setCloudType({
                        ...cloudType,
                        idproducts: event.target.value,
                      })
                      AdminIntegration(event.target.value);
                    }}
                    style={{
                      alignItems: "left",
                      height: "2.5rem",
                      backgroundColor: colors.tertiaryColor,
                    }}
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {productList.map((item, index) => (
                      <MenuItem key={index} value={item.idproducts}>
                        {item.productname}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="input-group">
                  <label>Integration:</label>
                  <Select
                    // value={cloudType.cloudTypes}
                    // onChange={(event) => {
                    //   setCloudType({
                    //     ...cloudType,
                    //     cloudTypes: event.target.value
                    //   })

                    // }}
                    value={selectIntegration || ''}
                    onChange={handleIntegrationChange}
                    type="text-cred"
                    style={{
                      alignItems: "left",
                      height: "2.5rem",
                      backgroundColor: colors.tertiaryColor,
                    }}
                    fullWidth
                  >
                    {selectedIntegration.map((item, index) => (
                      <MenuItem key={index} value={item.staticintid}>
                        {item.intname}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div>
                  {renderTextFields()}
                </div>
                <div
                  className="input-group"
                  style={{ justifyContent: "flex-end" }}
                >
                  <ColorButton
                    size="small"
                    variant="contained"
                    sx={{ margin: "10px 10px 2px 10px" }}
                    className="executebutton"
                    style={{ alignItem: "center" }}
                    onClick={() => {
                      IntegrationDelete(integrationDatas.integrationid)
                    }}
                  >
                    Delete
                  </ColorButton>
                  <ColorButton
                    size="small"
                    variant="contained"
                    sx={{ margin: "10px 10px 2px 10px" }}
                    className="executebutton"
                    style={{ alignItem: "center" }}
                    onClick={() => {
                      if (Object.keys(integrationDatas).length !== 0) {
                        setOpenAlert(true);
                      }
                      AddStaticIntegration(cloudType.idproducts, intName, integrationDatas.adminprop)
                    }}
                  >
                    Submit
                  </ColorButton>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="card" style={{ backgroundColor: colors.tertiaryColor }}>
                <h3>Edit System Property</h3>
                <div className="input-group">
                  <label>ID:</label>
                  <TextField type="text-cred" fullWidth />
                </div>
                <div className="input-group">
                  <label>Name:</label>
                  <TextField type="text-cred" fullWidth />
                </div>
                <div className="input-group">
                  <label>Value:</label>
                  <TextField type="text-cred" fullWidth />
                </div>
                <div className="input-group">
                  <label>Comment:</label>
                  <TextField type="text-cred" fullWidth />
                </div>
                <div
                  className="input-group"
                  style={{ justifyContent: "flex-end" }}
                >
                  <ColorButton
                    size="small"
                    variant="contained"
                    sx={{ margin: "10px 10px 2px 10px" }}
                    onClick={() => {
                      saveEmailCredentialDetail(credDbData);
                    }}
                    className="executebutton"
                    style={{ alignItem: "center" }}
                  >
                    Submit
                  </ColorButton>
                </div>
              </div>

              <div className="card" style={{ backgroundColor: colors.tertiaryColor }}>

                <h3>Add Themes</h3>
                <div className="input-group">
                  <label>Select Logo:</label>
                  {/* {console.log("TestDataDetails",TestDataDetails)} */}

                  <TextField
                    type="file"
                    id="recording-file-upload"

                    variant="standard"
                    // value={}
                    onChange={handleFileChange}
                  />
                </div>
                <div className="input-group">
                  <label>Primary Color:</label>
                  <TextField
                    value={TestDataDetails.primary}
                    onChange={(event) => {
                      event.preventDefault();
                      setTestDataDetails({
                        ...TestDataDetails,
                        primary: event.target.value
                      })
                    }}
                    type="text-cred"
                    fullWidth
                  />

                </div>
                <div className="input-group">
                  <label>Secondary Color:</label>
                  <TextField
                    value={TestDataDetails.secondary}
                    onChange={(event) => {
                      event.preventDefault();
                      setTestDataDetails({
                        ...TestDataDetails,
                        secondary: event.target.value
                      })
                    }}
                    type="text-cred"
                    fullWidth
                  />
                </div>
                <div className="input-group">
                  <label>Tertiary Color:</label>
                  <TextField
                    value={TestDataDetails.tertiary}
                    onChange={(event) => {
                      event.preventDefault();
                      setTestDataDetails({
                        ...TestDataDetails,
                        tertiary: event.target.value
                      })
                    }}
                    type="text-cred"
                    fullWidth
                  />
                </div>
                <div
                  className="input-group"
                  style={{ justifyContent: "flex-end" }}
                >

                  <ColorButton
                    size="small"
                    // value={logo}
                    // onChange={handleUpload}
                    variant="contained"
                    sx={{ margin: "10px 10px 2px 10px" }}
                    onClick={() => {

                      handleUpload()
                      setTimeout(() => {
                        handleUploaddata()
                      }, 2000);
                    }}
                    // onClick={(handleUpload)}
                    className="executebutton"
                    style={{ alignItem: "center" }}
                  >
                    Submit
                  </ColorButton>
                </div>
              </div>
              {/*  */}
              <div className="card" style={{ backgroundColor: colors.tertiaryColor, height: 160 }}>
                <h3>Google Oauth Credentials</h3>
                <div className="input-group">
                  <label>upload a file</label>
                  <TextField
                    type="file"
                    id="recording-file-upload"
                    variant="standard"
                    // value={}
                    onChange={handleFileUpload}
                  />
                </div>
                <div
                  className="input-group"
                  style={{ justifyContent: "flex-end" }}
                >
                  {/* <ColorButton
                    size="small"
                    variant="contained"
                    sx={{ margin: "10px 10px 2px 10px" }}
                    onClick={() => {

                    }}
                    className="executebutton"
                    style={{ alignItem: "center" }}
                  >
                    Submit
                  </ColorButton> */}
                  {uploadedFile.file[0] && (
                    <ColorButton
                      size="small"
                      variant="contained"
                      sx={{ margin: "10px 10px 2px 10px" }}
                      onClick={() => {
                        uploadGooleOauth();
                      }}
                      className="executebutton"
                      style={{ alignItem: "center" }}
                    >
                      authorize
                    </ColorButton>
                  )}

                </div>
              </div>
              {/*  */}
            </div>

            <div className="column">
              <div className="card" style={{ backgroundColor: colors.tertiaryColor }}>
                <h3>Create New Product</h3>
                <div className="input-group">
                  <label>Product:</label>
                  <TextField
                    value={newProductName}
                    onChange={(event) => {
                      event.preventDefault();
                      setNewProductName(event.target.value);
                    }}
                    type="text-cred"
                    fullWidth
                  />
                </div>
                <div
                  className="input-group"
                  style={{ justifyContent: "flex-end" }}
                >
                  <ColorButton
                    size="small"
                    variant="contained"
                    sx={{ margin: "10px 10px 2px 10px" }}
                    onClick={() => {
                      sendProduct(newProductName);
                      setNewProductName("");
                    }}
                    className="executebutton"
                    style={{ alignItem: "center" }}
                  >
                    Submit
                  </ColorButton>
                </div>
              </div>
              <div className="card" style={{ marginTop: "20px", backgroundColor: colors.tertiaryColor }}>
                <h3>Create New Module</h3>
                <div className="input-group">
                  <label>Product:</label>
                  <Select
                    value={product}
                    onChange={(event) => {
                      setproduct(event.target.value);
                    }}
                    style={{
                      alignItems: "left",
                      height: "2.5rem",
                      backgroundColor: colors.tertiaryColor,
                    }}
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {productList.map((item, index) => (
                      <MenuItem key={index} value={item.idproducts}>
                        {item.productname}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="input-group">
                  <label>Module:</label>
                  <TextField
                    value={newModuleName}
                    onChange={(event) => {
                      event.preventDefault();
                      setNewModuleName(event.target.value);
                    }}
                    type="text-cred"
                    fullWidth
                  />
                </div>
                <div
                  className="input-group"
                  style={{ justifyContent: "flex-end" }}
                >
                  <ColorButton
                    size="small"
                    variant="contained"
                    sx={{ margin: "10px 10px 2px 10px" }}
                    onClick={() => {
                      sendModule(product, newModuleName);
                      setNewModuleName("");
                    }}
                    className="executebutton"
                    style={{ alignItem: "center" }}
                  >
                    Submit
                  </ColorButton>
                </div>
              </div>
              <div className="card" style={{ backgroundColor: colors.tertiaryColor }}>
                <h3>Clear Storage Before</h3>
                <div className="input-group">
                  <label>Date:</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      className="date-picker"
                      label="Before Date"
                      value={toDate}
                      onChange={(date) => setToDate(date)}
                      sx={{
                        width: '350px',

                      }}
                    />
                  </LocalizationProvider>
                </div>
                <div
                  className="input-group"
                  style={{ justifyContent: "flex-end", backgroundColor: colors.tertiaryColor }}
                >
                  <ColorButton
                    size="small"
                    variant="contained"
                    sx={{ margin: "10px 10px 2px 10px" }}
                    onClick={() => {
                      setClearStorage(encodeURIComponent(formatDate(toDate)))
                    }}
                    className="executebutton"
                    style={{ alignItem: "center" }}
                  >
                    Submit
                  </ColorButton>
                </div>
              </div>

              <div className="card" style={{ backgroundColor: colors.tertiaryColor }}>
                <h3>Create Company URL</h3>
                <div className="input-group">
                  <label>Company URL:</label>
                  <TextField
                    value={companyURL}
                    onChange={(event) => {
                      event.preventDefault();
                      setCompanyURL(event.target.value);
                    }}
                    type="text-cred"
                    fullWidth
                  />
                </div>
                <div
                  className="input-group"
                  style={{ justifyContent: "flex-end" }}
                >
                  <ColorButton
                    size="small"
                    variant="contained"
                    sx={{ margin: "10px 10px 2px 10px" }}
                    onClick={() => {
                      sendCompanyURL(companyURL);
                    }}
                    className="executebutton"
                    style={{ alignItem: "center" }}
                  >
                    Submit
                  </ColorButton>
                </div>
              </div>
            </div>
          </div>
        </Box >
      </>
    )
  }

  const User = () => {
    function TablePaginationActions(props) {
      const theme = useTheme();
      const { count, page, rowsPerPage, onPageChange } = props;

      const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
      };

      const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
      };

      const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
      };

      const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
      };
      return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
          <IconButton
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="first page"
          >
            {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
          </IconButton>
          <IconButton
            onClick={handleBackButtonClick}
            disabled={page === 0}
            aria-label="previous page"
          >
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
          </IconButton>
          <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="next page"
          >
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </IconButton>
          <IconButton
            onClick={handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="last page"
          >
            {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
          </IconButton>
        </Box>
      );
    }

    TablePaginationActions.propTypes = {
      count: PropTypes.number.isRequired,
      onPageChange: PropTypes.func.isRequired,
      page: PropTypes.number.isRequired,
      rowsPerPage: PropTypes.number.isRequired,
    };

    function createData(name, calories, fat) {
      return { name, calories, fat };
    }

    const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userData.length) : 0;

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow style={{ fontSize: '16px', fontWeight: 'bold' }} >
              <TableCell>Username</TableCell>
              <TableCell align="right">First Name</TableCell>
              <TableCell align="right">Last Name</TableCell>
              <TableCell align="right">Phone</TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="right">User Role</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              (rowsPerPage > 0
                ? userData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : userData
              )
                .map((row) => (
                  <TableRow
                    key={row.iduserprofile}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.username}
                    </TableCell>
                    <TableCell align="right">{row.firstname}</TableCell>
                    <TableCell align="right">{row.lastname}</TableCell>
                    <TableCell align="right">{row.phonenumber}</TableCell>
                    <TableCell align="right">{row.email}</TableCell>
                    <TableCell align="right">
                      {/* {row.usertype} */}
                      <FormControl>
                        <Select
                          variant="standard"
                          value={userRole.find((item) => item.iduser_roles === row.usertype)?.iduser_roles || ""}
                          onChange={(event) => {
                            handleupdateUserType(row.iduserprofile, event.target.value);
                          }}
                        >
                          {
                            userRole.map((item) => {
                              return (
                                <MenuItem key={item.iduser_roles} value={item.iduser_roles} > {item.user_role} </MenuItem>
                              )
                            })
                          }
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell align="right">{row.status}</TableCell>
                    <TableCell align="right">
                      {
                        row.status === 3 ? (
                          <Button variant="contained" color="success" title="Approve"
                            onClick={() => userApproved(row.iduserprofile)}
                          >
                            Approve
                          </Button>
                        ) :
                          row.status === 2 ? (
                            <Button variant="contained" color="secondary" title="Approve"
                              onClick={() => userReset(row.iduserprofile)}
                            >
                              Reset
                            </Button>
                          ) : null

                      }
                    </TableCell>
                  </TableRow>
                ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter >
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={3}
                count={userData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    )

  }
  const tableColumnsUserGroup = [
    // {
    //   name: "",
    //   id: "",
    //   isLongString: false,
    //   isNumeric: false,
    //   align: "center",
    //   colspan: 1,
    //   width: '15%',
    // },
    {
      name: "Module Name",
      id: "Module Name",
      isLongString: false,
      isNumeric: false,
      align: "center",
      colspan: 1,
      width: '25%',
    },
    {
      name: "Status",
      id: "status",
      isLongString: false,
      isNumeric: false,
      align: "center",
      colspan: 1,
      width: '25%',
    },
    // {
    //   name: "Module Name",
    //   id: "Product",
    //   isLongString: false,
    //   isNumeric: false,
    //   align: "center",
    //   colspan: 2,
    //   width: '25%',
    // },
    // {
    //   name: "Status",
    //   id: "Status",
    //   isLongString: false,
    //   isNumeric: false,
    //   align: "center",
    //   colspan: 1,
    //   width: '25%',
    // },
  ]
  const TableBodayData = [
    {
      id: 1,
      name: "Riskcentral",
      product: "Nogrunt",
      status: "Read Only"
    },
    {
      id: 2,
      name: "Gapblue",
      product: "Nogrunt 1",
      status: "Edit Only "
    },
    {
      id: 3,
      name: "Nogrunt",
      product: "Demo",
      status: "Execute Only"
    },
    {
      id: 4,
      name: "kepsla",
      product: "Regression",
      status: "Edit Only"
    },
    {
      id: 5,
      name: "DMART",
      product: "Regression",
      status: "Edit Only"
    },
  ]
  const statusDrop = [
    {
      id: 1,
      statusName: "Full Access"
    },
    {
      id: 2,
      statusName: "Execute Only"
    },
    {
      id: 3,
      statusName: "Read Only"
    },
  ]
  const ExpandTable = [
    // {
    //   name: "",
    //   id: "",
    //   isLongString: false,
    //   isNumeric: false,
    //   align: "center",
    //   colspan: 1,
    //   width: '10%',
    // },
    // {
    //   name: "Module Name",
    //   id: "Module Name",
    //   isLongString: false,
    //   isNumeric: false,
    //   align: "center",
    //   colspan: 1,
    //   width: '40%',
    // },
    // {
    //   name: "Permission",
    //   id: "Permission",
    //   isLongString: false,
    //   isNumeric: false,
    //   align: "center",
    //   colspan: 2,
    //   width: '30%',
    // },
    {
      name: "User Groups",
      id: "Product",
      isLongString: false,
      isNumeric: false,
      align: "center",
      colspan: 2,
      width: '60%',
    },
    {
      name: "Permissions",
      id: "permissions",
      isLongString: false,
      isNumeric: false,
      align: "center",
      colspan: 1,
      width: '40%',
    }
  ]
  // const ExpandTableBodayData = [
  //   {
  //     id: 1,
  //     name: "Module 1",
  //     ValidUser: "QA",
  //     status: "Full Access"
  //   },
  //   {
  //     id: 2,
  //     name: "Module 2",
  //     ValidUser: "Dev",
  //     status: "Full Access "
  //   },
  //   {
  //     id: 3,
  //     name: "Module 3",
  //     ValidUser: "Dev Admin",
  //     status: "Full Access"
  //   },
  //   {
  //     id: 4,
  //     name: "Module 4 ",
  //     ValidUser: "QA Admin",
  //     status: "Full Access"
  //   },
  // ]
  const [selectStatus, setSelectStatus] = useState("");
  const [expandedRows, setExpandedRows] = useState({});

  const toggleRowExpansion = (itemId) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [itemId]: !prevExpandedRows[itemId],
    }));
    getModulePermission(itemId);
  };

  const userGroup = () => {
    return (
      <div>
        <div
          style={{
            // backgroundColor: colors?.secondaryColor,
            // color: colors?.primaryColor,
            width: "100%"
          }}
        >
          {/* <Typography paddingBottom={0} variant="h6" component="h1"
            sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
          >
            User Group
          </Typography> */}
          <div  >
            <div className="subheads">
              <FormControl
                sx={{
                  width: 240,
                  // color: colors.PrimaryColor
                }}>
                <InputLabel
                  style={{
                    // color: colors.primaryColor,
                    // backgroundColor: colors.secondaryColor,
                    padding: 5,
                  }}
                  id={"product"}
                >
                  Product
                </InputLabel>
                <Select
                  labelId={"product"}
                  id={"product Select"}
                  value={userGroup.idproducts}
                  onChange={(event) => {
                    setUserGroup({
                      ...userGroup,
                      idproducts: event.target.value
                    })
                    getModules(event.target.value);
                    // getModulePermission(event.target.value)

                  }}
                  name={"product"}
                  label="product"
                  IconComponent={(props) => (
                    <ArrowDropDownSharpIcon
                      style={{
                        // color: colors.primaryColor,
                        fontSize: 40
                      }}
                      {...props}
                    />
                  )}
                  sx={{
                    // color: colors.primaryColor,
                    // border: `2px solid ${colors.primaryColor}`,
                    fontSize: 16,
                    "&:focus": {
                      borderRadius: 4,
                      // border: `4px solid ${colors.primaryColor}`,
                    },
                  }}
                  style={{
                    minWidth: 100,
                    marginRight: "2rem",
                  }}
                >
                  {productList.map((product, index) => (
                    <MenuItem key={index} value={product.idproducts}>
                      {product.productname}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        {/*  */}
        <div style={{ display: "flex", justifyContent: "flex-start", overflow: "hidden" }} >
          <Table
            stickyHeader aria-label="a dense table" sx={{ minWidth: 650, marginTop: "10px", marginRight: 1 }}
          >
            <TableHead>
              <StyledTableRow >
                {tableColumnsUserGroup.map((item) => (
                  <>
                    <StyledTableCell key={item.id} align={item.align} colSpan={item.colspan} width={item.width}>
                      {item.name}
                    </StyledTableCell>
                  </>
                ))}
                <StyledTableCell />
                <StyledTableCell colSpan={1} />
              </StyledTableRow>
            </TableHead>
            <TableBody>

              {moduleList.map((moduleItem, index) => {
                const handleStatusChange = (event) => {
                  setModuleList((prevModuleList) => {
                    const updatedModuleList = [...prevModuleList];
                    const index = updatedModuleList.findIndex((prevModuleItem) => prevModuleItem.idmodules === moduleItem.idmodules);
                    if (index !== -1) {
                      updatedModuleList[index] = { ...moduleItem, selectStatus: event.target.value };
                    }
                    return updatedModuleList;
                  });
                };
                if (index % 2 === 1) {
                  return (
                    <>
                      <StyledTableRow >
                        <StyledTableCell key={moduleItem.id} width='25%' align="center" sx={{ fontSize: 20 }}>
                          {moduleItem.modulename}
                        </StyledTableCell>
                        <StyledTableCell width='25%' align="center" colSpan={1}>
                          {/* <FormControl>
                            <Select
                              variant="standard"
                              key={moduleItem.idmodules}
                              value={moduleItem.selectStatus}
                              onChange={handleStatusChange}
                            >
                              <MenuItem value="Full Access">Full Access</MenuItem>
                              <MenuItem value="Execute Only">Execute Only</MenuItem>
                              <MenuItem value="Read Only">Read Only</MenuItem>
                            </Select>
                          </FormControl> */}
                        </StyledTableCell>
                        <StyledTableCell width='30%' align="center" colSpan={2}>
                          <IconButton
                            color="inherit"
                            aria-label="expand more"
                            component="label"
                            onClick={() => toggleRowExpansion(moduleItem.idmodules)}
                          >
                            <ExpandMoreIcon />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                      <div style={{ width: "134%" }}>
                        <Table stickyHeader aria-label="a dense table" sx={{ minWidth: 650 }} size="small">
                          {
                            expandedRows[moduleItem.idmodules] && (
                              <>
                                <TableHead>
                                  <StyledTableRow >
                                    {ExpandTable.map((item) => (
                                      <>
                                        {/* <StyledTableCell colSpan={1} /> */}
                                        <StyledTableCell key={item.id} align={item.align} colSpan={item.colspan} width={item.width}>
                                          {item.name}
                                        </StyledTableCell>
                                      </>
                                    ))}
                                    <StyledTableCell />
                                    <StyledTableCell colSpan={1} />
                                  </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                  {
                                    userRole.map((item) => {

                                      const handleSelectStatusChange = (event) => {
                                        setSelectStatus((prevStatus) => {
                                          const updatedStatus = { ...prevStatus, [moduleItem.idmodules]: { ...prevStatus[moduleItem.idmodules], [item.id]: event.target.value } };
                                          return updatedStatus;
                                        });
                                      }
                                      let matchingPermission = null;

                                      for (let i = 0; i < modulePermission.length; i++) {
                                        if (modulePermission[i].roleid === item.iduser_roles) {
                                          matchingPermission = modulePermission[i];
                                          break; // Exit the loop once a match is found
                                        }
                                      }
                                      const permission = matchingPermission ? matchingPermission.permission : "Full Access";

                                      return (
                                        <>
                                          <StyledTableRow>
                                            <StyledTableCell width='60%' align="center" colSpan={2}>
                                              {item.user_role}
                                            </StyledTableCell>

                                            <StyledTableCell width='40%' align="center" colSpan={1}>
                                              <FormControl>
                                                <Select
                                                  variant="standard"
                                                  key={moduleItem.idmodules}
                                                  value={matchingPermission?.permission || "FullAccess"}
                                                  onChange={(event) => {
                                                    handleupdateModulePerm(moduleItem.idmodules, item.iduser_roles, event.target.value);
                                                  }}
                                                >
                                                  <MenuItem value="Full Access">Full Access</MenuItem>
                                                  <MenuItem value="Execute Only">Execute Only</MenuItem>
                                                  <MenuItem value="Read Only">Read Only</MenuItem>
                                                </Select>
                                              </FormControl>
                                            </StyledTableCell>
                                          </StyledTableRow>
                                        </>
                                      )
                                    })
                                  }
                                </TableBody>
                              </>
                            )
                          }
                        </Table>
                      </div>
                    </>
                  )
                }
              })}

            </TableBody>
          </Table>
          <div>
            <Table
              stickyHeader aria-label="a dense table" sx={{ minWidth: 650, marginTop: "10px" }}
            >
              <TableHead>
                <StyledTableRow >
                  {tableColumnsUserGroup.map((item) => (
                    <>
                      {/* <StyledTableCell colSpan={1} /> */}
                      <StyledTableCell key={item.id} align={item.align} colSpan={item.colspan} width={item.width}>
                        {item.name}
                      </StyledTableCell>
                    </>
                  ))}
                  <StyledTableCell />
                  <StyledTableCell colSpan={1} />
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {moduleList.map((moduleItem, index) => {
                  const handleStatusChange = (event) => {
                    setModuleList((prevModuleList) => {
                      const updatedModuleList = [...prevModuleList];
                      const index = updatedModuleList.findIndex((prevModuleItem) => prevModuleItem.idmodules === moduleItem.idmodules);
                      if (index !== -1) {
                        updatedModuleList[index] = { ...moduleItem, selectStatus: event.target.value };
                      }
                      return updatedModuleList;
                    });
                  };
                  if (index % 2 === 0) {
                    return (
                      <>
                        <StyledTableRow >
                          <StyledTableCell key={moduleItem.id} width='25%' align="center" sx={{ fontSize: 20 }}>
                            {moduleItem.modulename}
                          </StyledTableCell>
                          {/* {TableBodayData.map((item) => ( */}
                          <StyledTableCell width='25%' align="center" colSpan={1}>
                            {/* <FormControl>
                              <Select
                                variant="standard"
                                key={moduleItem.idmodules}
                                value={moduleItem.selectStatus}
                                onChange={handleStatusChange}
                              >
                                <MenuItem value="Full Access">Full Access</MenuItem>
                                <MenuItem value="Execute Only">Execute Only</MenuItem>
                                <MenuItem value="Read Only">Read Only</MenuItem>
                              </Select>
                            </FormControl> */}
                          </StyledTableCell>
                          <StyledTableCell width='30%' align="center" colSpan={2}>
                            <IconButton
                              color="inherit"
                              aria-label="expand more"
                              component="label"
                              onClick={() => toggleRowExpansion(moduleItem.idmodules)}
                            >
                              <ExpandMoreIcon />
                            </IconButton>
                          </StyledTableCell>
                          {/* ))} */}
                        </StyledTableRow>
                        <div style={{ width: "125%" }}>
                          <Table stickyHeader aria-label="a dense table" sx={{ minWidth: 650 }} size="small">
                            {
                              expandedRows[moduleItem.idmodules] && (
                                <>
                                  <TableHead>
                                    <StyledTableRow >
                                      {ExpandTable.map((item) => (
                                        <>
                                          <StyledTableCell key={item.id} align={item.align} colSpan={item.colspan} width={item.width}>
                                            {item.name}
                                          </StyledTableCell>
                                        </>
                                      ))}
                                      <StyledTableCell />
                                      <StyledTableCell colSpan={1} />
                                    </StyledTableRow>
                                  </TableHead>
                                  <TableBody>
                                    {
                                      userRole.map((item) => {
                                        const handleSelectStatusChange = (event) => {
                                          setSelectStatus((prevStatus) => {
                                            const updatedStatus = { ...prevStatus, [moduleItem.idmodules]: { ...prevStatus[moduleItem.idmodules], [item.id]: event.target.value } };
                                            return updatedStatus;
                                          });
                                          // const dropdownId = item.id;
                                          // setSelectStatus((prevStatus) => {
                                          //   const updatedStatus = { ...prevStatus, [dropdownId]: event.target.value };
                                          //   return updatedStatus;
                                          // });
                                        }
                                        let matchingPermission = null;

                                        for (let i = 0; i < modulePermission.length; i++) {
                                          if (modulePermission[i].roleid === item.iduser_roles) {
                                            matchingPermission = modulePermission[i];
                                            break; // Exit the loop once a match is found
                                          }
                                        }
                                        const permission = matchingPermission ? matchingPermission.permission : "Full Access";

                                        return (
                                          <>
                                            <StyledTableRow>
                                              {/* <StyledTableCell width='40%' align="center" colSpan={1}>
                                                {item.name}
                                              </StyledTableCell> */}
                                              <StyledTableCell width='60%' align="center" colSpan={2}>
                                                {item.user_role}
                                              </StyledTableCell>
                                              <StyledTableCell width='40%' align="center" colSpan={1}>
                                                <FormControl>
                                                  <Select
                                                    variant="standard"
                                                    key={moduleItem.idmodules}
                                                    // value={selectStatus}
                                                    // onChange={(event) => {
                                                    //   setSelectStatus(event.target.value)
                                                    // }}
                                                    // value={selectStatus[item.id] || 'Full Access'}
                                                    value={matchingPermission?.permission || "FullAccess"}
                                                    onChange={(event) => {
                                                      handleupdateModulePerm(moduleItem.idmodules, item.iduser_roles, event.target.value);
                                                    }}
                                                  >
                                                    <MenuItem value="FullAccess">Full Access</MenuItem>
                                                    <MenuItem value="ExecuteOnly">Execute Only</MenuItem>
                                                    <MenuItem value="ReadOnly">Read Only</MenuItem>
                                                  </Select>
                                                </FormControl>
                                              </StyledTableCell>
                                            </StyledTableRow>
                                          </>
                                        )
                                      })
                                    }
                                  </TableBody>
                                </>
                              )
                            }
                          </Table>
                        </div>
                      </>
                    )
                  }
                })}
              </TableBody>
            </Table>
          </div>
        </div>
      </div >
    )
  }
  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        alignItem: "center",
        justifyContent: "space-evenly",
        paddingTop: "2rem",
        flexDirection: "column",
        paddingX: "2rem",
      }}
    >
      <Snackbar open={savenextmessage} style={{ marginTop: "265px" }} autoHideDuration={SnackPosition.duration} onClose={closeSuccessToast}
        anchorOrigin={{
          vertical: SnackPosition.vertical,
          horizontal: SnackPosition.horizontal
        }}
      >
        <Alert onClose={() => { setsavenextmessage(false) }} severity={format} className="snackBar">
          {snackBarMsg}
        </Alert>
      </Snackbar>
      <Card
        sx={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          paddingY: "1.5rem",
          backgroundColor: colors.secondaryColor,
          color: colors.primaryColor,
        }}
      >
        <Typography paddingBottom={0} variant="h6" component="h2">
          Admin
        </Typography>
      </Card>
      {openAlert && (
        <Alert
          variant="filled"
          severity="success"
          onClose={handleCloseAlert}
          open={openAlert}
          className="alert"
        >
          Data is Successfully Saved.
        </Alert>
      )}
      {DeleteAlert && (
        <Alert
          variant="filled"
          severity="success"
          onClose={handleCloseDeleteAlert}
          open={DeleteAlert}
          className="alert"
        >
          Data is Successfully Deleted.
        </Alert>
      )}
      {/* <Box
        sx={{
          bgcolor: 'background.paper',
          display: "flex",
          flexDirection: "column"
        }} >
        <AppBar position="static" style={{ backgroundColor: "transparent" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            // indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
            id="tabs"

          >
            <Tab style={{ color: colors.primaryColor, backgroundColor: colors.secondaryColor }} label="User" {...a11yProps(0)} onClick={() => getUserData()} className="tab1" />
            <Tab style={{ color: colors.primaryColor, backgroundColor: colors.secondaryColor }} label="Properties" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          // axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}

        >
          <TabPanel value={value} index={0} dir={theme.direction} >
            {User()}
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            {Properties()}
          </TabPanel>
        </SwipeableViews>
      </Box> */}
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', marginLeft: 4 }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="User" value="1" />
              <Tab label="Properties" value="2" />
              <Tab label="Permissions" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">{User()}</TabPanel>
          <TabPanel value="2"> {Properties()}</TabPanel>
          <TabPanel value="3"> {userGroup()}</TabPanel>

        </TabContext>
      </Box>

    </Box>
  );
};

export default AdminPage;
