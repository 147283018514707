import {
  Box,
  Chip,
  styled,
  Table,
  css,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { EllipsisTooltipText } from "../../components-new/common";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import PrettyDonut from "./PrettyDonut";
import { useTestCaseResultsFromTestSuiteQuery } from "../../useQuery";
import { Link } from "react-router-dom";
import { ExeReportHistoryStatus } from "../../components-new/layout/constants";

const StyledTableCell = styled(TableCell)`
  color: #e0e0e0;
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1px solid #e2e6f3;
  background: #2550d1;
`;

const StyledTableBodyCell = styled(TableCell)`
  padding: 8px 20px;
  border-bottom: 1px solid #eee;
  p {
    color: #2b3539;
    font-size: 16px;
    font-weight: 500;
    padding-right: 15px;
    min-width: 60px;
  }
`;

const StyledInfoBox = styled(Box)`
  padding: 16px 20px;
`;

const StyledTypography = styled(Typography)`
  font-size: "14px";
  font-weight: 600;
  color: #232d3d;
`;

const StyledTitle = styled(Typography)`
  color: #e0e0e0;
  font-size: 14px;
  font-weight: 600;
  padding: 16px 20px;
  border-bottom: 2px solid rgba(226, 230, 243, 0.8);
`;

const StyledTitleRight = styled(StyledTitle)`
  flex: 1;
  text-align: center;
`;

const StyledTitleLeft = styled(StyledTitle)`
  flex: 2;
  border-right: 2px solid rgba(226, 230, 243, 0.8);
`;

const StyledInfo = styled(StyledTitle)`
  color: #232d3d;
`;

const StyledInfoLeft = styled(StyledInfo)`
  border-right: 2px solid rgba(226, 230, 243, 0.8);
  flex: 2;
`;

const StyledInfoRight = styled(StyledInfo)`
  flex: 1;
  text-align: center;
`;

export default function PrettyReports({ data, testSuiteName, products }) {
  const suitesList = useSelector((state) => state.executionSlice.selectedTestSuiteData);
  const selectedProduct = useSelector((state) => state.executionSlice.selectedProduct);
  const prodName = products?.find((item) => item.idproducts === selectedProduct);

  const { data: testCaseData } = useTestCaseResultsFromTestSuiteQuery(
    data.idtest_suite_results,
    "All",
    {
      enabled: true,
    },
  );

  return (
    <Box>
      <Box
        mt={2}
        display={"flex"}
        bgcolor="#ffffff"
        borderBottom={`2px solid rgba(226, 230, 243, 0.80)`}
        sx={{ borderTopRightRadius: "8px", borderTopLeftRadius: "8px" }}
      >
        <StyledInfoBox flex={1}>
          <EllipsisTooltipText
            text={`Product: ${prodName.productname}`}
            mode="chars"
            maxChars={50}
          />
        </StyledInfoBox>
        <StyledInfoBox
          flex={1}
          borderRight={`2px solid rgba(226, 230, 243, 0.80)`}
          borderLeft={`2px solid rgba(226, 230, 243, 0.80)`}
        >
          <EllipsisTooltipText text={`Session: `} mode="chars" maxChars={50} />
        </StyledInfoBox>
        <StyledInfoBox flex={1}>
          <EllipsisTooltipText
            text={`Build: ${data.build ? data.build : "#2545"} `}
            mode="chars"
            maxChars={50}
          />
        </StyledInfoBox>
      </Box>
      <Box
        mt={4}
        bgcolor={"#2550d1"}
        sx={{ borderTopRightRadius: "8px", borderTopLeftRadius: "8px" }}
      >
        <StyledInfoBox color="#E0E0E0">
          <Typography fontWeight={600}>Execution Information</Typography>
        </StyledInfoBox>
      </Box>
      <Box display="flex" gap={2}>
        <Box
          mt={2}
          flex={1}
          bgcolor={"#fff"}
          sx={{ borderTopRightRadius: "8px", borderTopLeftRadius: "8px" }}
        >
          <Box display="flex" borderBottom={`2px solid rgba(226, 230, 243, 0.80)`}>
            <StyledInfoBox flex={1} borderRight={`2px solid rgba(226, 230, 243, 0.80)`}>
              <StyledTypography>Suite Name</StyledTypography>
            </StyledInfoBox>
            <StyledInfoBox flex={2}>
              <StyledTypography>{testSuiteName}</StyledTypography>
            </StyledInfoBox>
          </Box>
          <Box display="flex" borderBottom={`2px solid rgba(226, 230, 243, 0.80)`}>
            <StyledInfoBox flex={1} borderRight={`2px solid rgba(226, 230, 243, 0.80)`}>
              <StyledTypography>Session Start Time</StyledTypography>
            </StyledInfoBox>
            <StyledInfoBox flex={2}>
              <StyledTypography>
                {dayjs(data.Executed_Date).format("DD/MM/YYYY HH:mm:ss")}
              </StyledTypography>
            </StyledInfoBox>
          </Box>
          <Box display="flex" borderBottom={`2px solid rgba(226, 230, 243, 0.80)`}>
            <StyledInfoBox flex={1} borderRight={`2px solid rgba(226, 230, 243, 0.80)`}>
              <StyledTypography>Execution Time</StyledTypography>
            </StyledInfoBox>
            <StyledInfoBox flex={2}>
              <StyledTypography>{data.Duration}</StyledTypography>
            </StyledInfoBox>
          </Box>
        </Box>
        <Box
          mt={2}
          flex={1}
          bgcolor={"#fff"}
          sx={{ borderTopRightRadius: "8px", borderTopLeftRadius: "8px" }}
        >
          <Box display="flex" borderBottom={`2px solid rgba(226, 230, 243, 0.80)`}>
            <StyledInfoBox flex={1} borderRight={`2px solid rgba(226, 230, 243, 0.80)`}>
              <StyledTypography>No. of Test Cases</StyledTypography>
            </StyledInfoBox>
            <StyledInfoBox flex={2}>
              <StyledTypography>{data.totalCount}</StyledTypography>
            </StyledInfoBox>
          </Box>
          <Box display="flex" borderBottom={`2px solid rgba(226, 230, 243, 0.80)`}>
            <StyledInfoBox flex={1} borderRight={`2px solid rgba(226, 230, 243, 0.80)`}>
              <StyledTypography>Session End Time</StyledTypography>
            </StyledInfoBox>
            <StyledInfoBox flex={2}>
              <StyledTypography>
                {dayjs(data.Executed_Date)
                  .add(data.Duration, "second")
                  .format("DD/MM/YYYY HH:mm:ss")}
              </StyledTypography>
            </StyledInfoBox>
          </Box>
          <Box display="flex" borderBottom={`2px solid rgba(226, 230, 243, 0.80)`}>
            <StyledInfoBox flex={1} borderRight={`2px solid rgba(226, 230, 243, 0.80)`}>
              <StyledTypography>Execution Status</StyledTypography>
            </StyledInfoBox>
            <StyledInfoBox flex={2}>
              <StyledTypography>
                {data.Status === "STOPP" ? "STOPPED" : data.Status}
              </StyledTypography>
            </StyledInfoBox>
          </Box>
        </Box>
      </Box>
      <Box display="flex" mt={4} pb={5}>
        <Box flex={1}>
          <PrettyDonut data={data} />
        </Box>
        <Box flex={2} display="flex" gap={2}>
          <Box
            flex={1}
            overflow="hidden"
            sx={{ borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}
          >
            <Box display="flex" bgcolor={"#2550D1"}>
              <StyledTitleLeft>Total Test Cases</StyledTitleLeft>
              <StyledTitleRight>{data.totalCount}</StyledTitleRight>
            </Box>
            <Box display="flex" bgcolor={"#ffffff"}>
              <StyledInfoLeft>Pass</StyledInfoLeft>
              <StyledInfoRight>{data.passCount}</StyledInfoRight>
            </Box>
            <Box display="flex" bgcolor={"#ffffff"}>
              <StyledInfoLeft>Fail</StyledInfoLeft>
              <StyledInfoRight>{data.failCount}</StyledInfoRight>
            </Box>
            <Box display="flex" bgcolor={"#ffffff"}>
              <StyledInfoLeft>Not Executed</StyledInfoLeft>
              <StyledInfoRight>
                {data.totalCount - (data.passCount + data.failCount)}
              </StyledInfoRight>
            </Box>
            <Box display="flex" bgcolor={"#ffffff"}>
              <StyledInfoLeft>Skipped Over</StyledInfoLeft>
              <StyledInfoRight>{data?.skippedOver || "0"}</StyledInfoRight>
            </Box>
            <Box display="flex" bgcolor={"#ffffff"}>
              <StyledInfoLeft>Incomplete</StyledInfoLeft>
              <StyledInfoRight>{data?.incomplete || "0"}</StyledInfoRight>
            </Box>
          </Box>
          <Box
            flex={1}
            overflow="hidden"
            sx={{ borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}
          >
            <Box display="flex" bgcolor={"#2550D1"}>
              <StyledTitleLeft>Execution Details</StyledTitleLeft>
              <StyledTitleRight>{""}</StyledTitleRight>
            </Box>
            <Box display="flex" bgcolor={"#ffffff"}>
              <StyledInfoLeft>Browser</StyledInfoLeft>
              <StyledInfoRight>{suitesList[0]?.browser || "0"}</StyledInfoRight>
            </Box>
            <Box display="flex" bgcolor={"#ffffff"}>
              <StyledInfoLeft>Env</StyledInfoLeft>
              <StyledInfoRight>{suitesList[0]?.env || "NA"}</StyledInfoRight>
            </Box>
            <Box display="flex" bgcolor={"#ffffff"}>
              <StyledInfoLeft>Stop test after fail</StyledInfoLeft>
              <StyledInfoRight>{suitesList[0]?.stopafterfailure || "NA"}</StyledInfoRight>
            </Box>
            <Box display="flex" bgcolor={"#ffffff"}>
              <StyledInfoLeft>Rerun fail test</StyledInfoLeft>
              <StyledInfoRight>{suitesList[0]?.rerunfailedtests || "NA"}</StyledInfoRight>
            </Box>
            <Box display="flex" bgcolor={"#ffffff"}>
              <StyledInfoLeft>Run Tests in Parallel</StyledInfoLeft>
              <StyledInfoRight>
                {suitesList[0]?.supports_parallel_execution || "NA"}
              </StyledInfoRight>
            </Box>
            <Box display="flex" bgcolor={"#ffffff"}>
              <StyledInfoLeft>Synchronized Tests</StyledInfoLeft>
              <StyledInfoRight>{suitesList[0]?.synchedscenarios || "NA"}</StyledInfoRight>
            </Box>
            <Box display="flex" bgcolor={"#ffffff"}>
              <StyledInfoLeft>Headless Execution</StyledInfoLeft>
              <StyledInfoRight>{suitesList[0]?.headless || "NA"}</StyledInfoRight>
            </Box>
            <Box display="flex" bgcolor={"#ffffff"}>
              <StyledInfoLeft>Screenshots on Failure</StyledInfoLeft>
              <StyledInfoRight>{suitesList[0]?.ssonerror || "NA"}</StyledInfoRight>
            </Box>
            <Box display="flex" bgcolor={"#ffffff"}>
              <StyledInfoLeft>Send Individual Result Email</StyledInfoLeft>
              <StyledInfoRight>{suitesList[0]?.resultsemail || "NA"}</StyledInfoRight>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Run Date</StyledTableCell>
                <StyledTableCell>Run By</StyledTableCell>
                <StyledTableCell>Run Duration</StyledTableCell>
                <StyledTableCell>Log File</StyledTableCell>
                <StyledTableCell>Browser</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {testCaseData?.testcaseresult?.length ? (
                testCaseData?.testcaseresult?.map((item, index) => (
                  <TableRow key={index}>
                    <StyledTableBodyCell>{item.Executed_Date}</StyledTableBodyCell>
                    <StyledTableBodyCell>{item.Executed_By}</StyledTableBodyCell>
                    <StyledTableBodyCell>{item.Duration}</StyledTableBodyCell>

                    <StyledTableBodyCell>
                      <a
                        target="_blank"
                        href={item.logfile}
                        rel="noopener noreferrer"
                        className="text-blue-500 underline"
                      >
                        {item.testcase}
                      </a>
                    </StyledTableBodyCell>

                    <StyledTableBodyCell>{item.Browser}</StyledTableBodyCell>

                    <StyledTableBodyCell>
                      <Box
                        sx={{
                          display: "inline-block",
                          minWidth: "82px",
                          padding: "8px 10px",
                          borderRadius: "41px",
                          backgroundColor:
                            item.Status === "PASS"
                              ? "#e7fbe4"
                              : item.Status === "FAIL"
                                ? "#fbe7e7"
                                : item.Status === "ABEND"
                                  ? "#eef4fc"
                                  : "#D3D3D3",
                          textAlign: "center",
                          textTransform: "capitalize",
                        }}
                      >
                        {ExeReportHistoryStatus[item.Status]}
                      </Box>
                    </StyledTableBodyCell>
                    {item.Status !== ExeReportHistoryStatus.ABORTED && (
                      <StyledTableBodyCell>
                        {/* <Link target="_blank" sx={{ cursor: "pointer" }} onClick={(e) => null}>
                          View test results
                        </Link> */}
                        <Link
                          target="_blank"
                          sx={{ cursor: "pointer" }}
                          className="text-blue-700 hover:text-blue-800 underline underline-offset-2"
                          onClick={(e) => {
                            e.preventDefault();

                            let url = `/new/reports?tcrid=${item.idtest_case_results}&tcname=${item.testcase}&tcRundate=${item.Executed_Date}`;
                            window.open(url, "_blank");
                          }}
                        >
                          View test results
                        </Link>
                      </StyledTableBodyCell>
                    )}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  {" "}
                  <StyledTableBodyCell align="center" colSpan={7}>
                    No data available
                  </StyledTableBodyCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
