import React, { useEffect, useState, useCallback } from "react";
import { Menu, Divider, Box, Typography, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedTestCase } from "../../Redux/TestAuthoring/TestAuthoring";
import {
  useCopyTCQuery,
  useDeleteTCQuery,
  useDownloadPomFilesQuery,
  useDownloadTestStepQuery,
  useRenameTCQuery,
  useTestSuiteQueryNew,
  useMoveProductsQuery,
} from "../../useQuery";
import useAddToSuiteMutation from "../../useQuery/useAddToSuiteMutation";
import { useNotification } from "../../hooks";
import {
  PlusIcon,
  CopyIcon,
  DeleteIcon,
  DownloadIcon,
  InfoIcon,
  NlpIcon,
  RenameIcon,
  ScreenshotIcon,
} from "../../components-new/icons";
import { CustomInput, CustomSelect } from "../../components-new/common";
import TSMenuItem from "./TSMenuItem";
import CustomDialog from "../../components-new/common/CustomDialog";
import useNlpStepQuery from "../../useQuery/useNlpStepQuery";
import useDownloadCodeQuery from "../../useQuery/useDownloadCodeQuery";

const TSMenu = ({
  anchorEl,
  handleClose,
  handleExecuteTest,
  setTestCaseSearchKeyword,
  handleReplaceTestSteps,
  handleDeleteTestSteps,
  handleCreateTestCase,
  onTestCaseAdded,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState("");
  const selectedTestCase = useSelector((state) => state.authoringSlice.selectedTestcaseItem);
  const selectedProduct = useSelector((state) => state.authoringSlice.selectedProduct);
  const [newName, setNewName] = useState("");
  const [copyName, setCopyName] = useState("");
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [selectedSuite, setSelectedSuite] = useState("");
  const [selectedProductId, setSelectedProductId] = useState("");
  const notify = useNotification();
  const dispatch = useDispatch();

  const { data: products } = useMoveProductsQuery();

  // Add to suite mutation
  const { mutate: addToSuite, isSuccess: isAddToSuiteSuccess } = useAddToSuiteMutation();

  const { mutate: deleteTestCase, isSuccess: isSuccessDelete } = useDeleteTCQuery();
  const {
    data: renameData,
    mutate: renameTestCase,
    isSuccess: isSuccessRename,
  } = useRenameTCQuery();
  const { data: copyData, mutate: copyTestCase, isSuccess: isSuccessCopy } = useCopyTCQuery();

  const { data: testSuites, isLoading: isLoadingTestSuites } = useTestSuiteQueryNew(
    selectedProductId,
    !!selectedProductId,
  );

  const { data: nlpStepsData, refetch: refetchNlpSteps } = useNlpStepQuery(
    selectedTestCase.idtest_case,
  );
  const { refetch: refetchDownloadCode } = useDownloadCodeQuery(
    selectedTestCase.idtest_case,
    selectedTestCase.Test_Case,
  );
  const { refetch: refetchDownloadTestSteps } = useDownloadTestStepQuery(
    selectedTestCase.idtest_case,
  );
  const { refetch: refetchDownloadPomFiles } = useDownloadPomFilesQuery(selectedProduct);

  const handleOpenDialog = (type) => {
    setDialogType(type);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedSuite("");
    setSelectedProductId("");
    handleClose();
  };

  const handleAddToTestSuite = () => {
    handleClose();
    setDialogType("addToSuite");
    setOpenDialog(true);
  };

  const handleConfirmAddToSuite = useCallback(() => {
    if (!selectedSuite) {
      notify("Please select a test suite", "error");
      return;
    }
    const testCasePayload = [selectedTestCase];

    addToSuite({
      data: testCasePayload,
      params: {
        idtest_suite: selectedSuite,
      },
    });
  }, [selectedSuite, selectedTestCase, addToSuite, notify]);

  useEffect(() => {
    if (isAddToSuiteSuccess) {
      notify("Test case added to suite successfully", "success");
      handleCloseDialog();
      if (onTestCaseAdded) {
        onTestCaseAdded();
      }
    }
  }, [isAddToSuiteSuccess, notify, onTestCaseAdded]);

  const handleDownloadCode = () => {
    handleClose();
    refetchDownloadCode();
  };

  const handleDownloadTestSteps = () => {
    handleClose();
    refetchDownloadTestSteps();
  };

  const handleDownloadPomFiles = () => {
    handleClose();
    refetchDownloadPomFiles();
  };

  const handleCopy = () => {
    handleClose();
    setDialogType("copy");
    setCopyName(selectedTestCase.Test_Case);
    setOpenDialog(true);
  };

  const handleRename = () => {
    handleClose();
    setDialogType("rename");
    setNewName(selectedTestCase.Test_Case);
    setOpenDialog(true);
  };

  const handleNlpSteps = () => {
    handleClose();
    setDialogType("nlpSteps");
    refetchNlpSteps();
    setOpenDialog(true);
  };

  const handleNameChange = useCallback(
    (e) => {
      if (dialogType === "rename") {
        setNewName(e.target.value);
        setTestCaseSearchKeyword(e.target.value);
      }
      if (dialogType === "copy") setCopyName(e.target.value);
    },
    [dialogType, setTestCaseSearchKeyword],
  );

  const handleConfirm = useCallback(() => {
    setOpenDialog(false);
    switch (dialogType) {
      case "delete":
        deleteTestCase({ id: selectedTestCase.idtest_case });
        dispatch(setSelectedTestCase(null));
        break;
      case "rename":
        renameTestCase({ id: selectedTestCase.idtest_case, newName });
        break;
      case "copy":
        copyTestCase({ tcid: selectedTestCase.idtest_case, copyNewName: copyName });
        break;
      default:
        break;
    }
  }, [
    dialogType,
    deleteTestCase,
    renameTestCase,
    copyTestCase,
    selectedTestCase.idtest_case,
    newName,
    copyName,
    dispatch,
  ]);

  useEffect(() => {
    const isNameUnchanged =
      dialogType === "rename"
        ? newName === selectedTestCase.Test_Case
        : copyName === selectedTestCase.Test_Case;
    setIsSaveDisabled(isNameUnchanged);
  }, [newName, copyName, dialogType, selectedTestCase.Test_Case]);

  useEffect(() => {
    if (isSuccessDelete) {
      notify("The item has been successfully deleted.", "success");
      handleClose();
    }
  }, [isSuccessDelete, handleClose, notify]);

  useEffect(() => {
    if (isSuccessRename) {
      dispatch(setSelectedTestCase(renameData.data.idtest_case));
      handleClose();
      notify("The item has been renamed successfully.", "success");
    }
  }, [dispatch, isSuccessRename, renameData?.data?.idtest_case, handleClose, notify]);

  useEffect(() => {
    if (isSuccessCopy) {
      handleClose();
      setTestCaseSearchKeyword("");
      dispatch(setSelectedTestCase(copyData.data.idtest_case));
      notify(`Test case copied with new name: ${copyName} successfully.`, "success");
    }
  }, [
    isSuccessCopy,
    copyData?.data?.idtest_case,
    copyName,
    dispatch,
    handleClose,
    notify,
    setTestCaseSearchKeyword,
  ]);

  const renderDialogContent = () => {
    switch (dialogType) {
      case "addToSuite":
        const suiteOptions = Array.isArray(testSuites)
          ? testSuites.flat()?.map((suite) => ({
              label: suite.Test_Suite,
              value: suite.idtest_suite,
            }))
          : [];

        return (
          <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex" flexDirection="row" columnGap={2}>
              <CustomSelect
                label="Products"
                options={(Array.isArray(products) ? products : []).map((prodItem) => ({
                  label: prodItem.productname,
                  value: prodItem.idproducts,
                }))}
                width="230px"
                background="#ffffff"
                value={selectedProductId || ""}
                onChange={(e) => {
                  setSelectedProductId(e.target.value);
                  setSelectedSuite("");
                }}
              />
              <CustomSelect
                label="Suite"
                options={suiteOptions}
                width="230px"
                background="#ffffff"
                value={selectedSuite || ""}
                onChange={(e) => setSelectedSuite(e.target.value)}
                disabled={!selectedProductId}
              />
            </Box>
          </Box>
        );

      case "delete":
        return <Typography>This action cannot be undone.</Typography>;
      case "rename":
        return (
          <>
            <Typography>Testcase name</Typography>
            <CustomInput autoFocus={true} value={newName} onChange={handleNameChange} />
          </>
        );
      case "copy":
        return (
          <>
            <Typography>Testcase name</Typography>
            <CustomInput autoFocus={true} value={copyName} onChange={handleNameChange} />
          </>
        );
      case "info":
        return (
          <Box display="flex" flexDirection="column" rowGap={2}>
            {[
              { label: "Created date", value: selectedTestCase.Created_Date },
              { label: "Created by", value: selectedTestCase.Created_By },
              { label: "Baseline Date", value: selectedTestCase.baselinedate },
            ].map((item, index) => (
              <Box key={index} display="flex" flexDirection="row" columnGap={2}>
                <Typography variant="subtitle2" width="100px">
                  {item.label}
                </Typography>
                <Typography>{item.value}</Typography>
              </Box>
            ))}
          </Box>
        );
      case "nlpSteps":
        return (
          <CustomInput
            fullWidth
            labelinput="Step Details"
            multiline
            rows={4}
            value={nlpStepsData?.nlpsteps}
            isDisabled="true"
            InputProps={{ readOnly: true }}
          />
        );
      default:
        return null;
    }
  };

  const renderDialogActions = () => {
    if (dialogType === "addToSuite") {
      return (
        <>
          <Button onClick={handleCloseDialog} sx={{ color: "#ff5336" }}>
            Cancel
          </Button>
          <Button
            onClick={handleConfirmAddToSuite}
            variant="contained"
            sx={{ background: "#ff5336" }}
            disabled={!selectedSuite}
          >
            Save
          </Button>
        </>
      );
    }
    if (dialogType === "delete") {
      return (
        <>
          <Button onClick={handleCloseDialog} color="primary">
            No
          </Button>
          <Button onClick={handleConfirm} color="primary">
            Yes
          </Button>
        </>
      );
    }
    if (["rename", "copy"].includes(dialogType)) {
      return (
        <>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary" disabled={isSaveDisabled}>
            Save
          </Button>
        </>
      );
    }
    return (
      <Button onClick={handleCloseDialog} color="primary">
        Close
      </Button>
    );
  };

  return (
    <Menu
      id="customized-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <TSMenuItem icon={<RenameIcon />} text="Rename" onClick={handleRename} />
      <TSMenuItem icon={<CopyIcon />} text="Copy" onClick={handleCopy} />
      <TSMenuItem
        icon={<PlusIcon />}
        text="Create Test Case"
        onClick={() => {
          handleClose();
          handleCreateTestCase();
        }}
      />
      <TSMenuItem icon={<NlpIcon />} text="NLP steps" onClick={handleNlpSteps} />
      <TSMenuItem icon={<DownloadIcon />} text="Download code" onClick={handleDownloadCode} />
      <TSMenuItem
        icon={<DownloadIcon />}
        text="Download test steps"
        onClick={handleDownloadTestSteps}
      />
      <TSMenuItem
        icon={<DownloadIcon />}
        text="Download pom files"
        onClick={handleDownloadPomFiles}
      />
      <TSMenuItem
        icon={<PlusIcon color="#242F3F" />}
        text="Add to test suite"
        onClick={handleAddToTestSuite}
      />
      <TSMenuItem
        icon={<ScreenshotIcon />}
        text="Baseline images"
        onClick={() => handleExecuteTest(true)}
      />
      <TSMenuItem
        icon={<InfoIcon color="#242F3F" />}
        text="Info"
        onClick={() => handleOpenDialog("info")}
      />
      <Divider />
      <TSMenuItem
        icon={<DeleteIcon />}
        text="Replace Test Steps"
        onClick={() => {
          handleClose();
          handleReplaceTestSteps();
        }}
      />
      <TSMenuItem
        icon={<DeleteIcon />}
        text="Delete Test Steps"
        onClick={() => {
          handleClose();
          handleDeleteTestSteps();
        }}
      />
      <TSMenuItem
        icon={<DeleteIcon />}
        text="Delete"
        onClick={() => {
          handleClose();
          handleOpenDialog("delete");
        }}
      />

      <CustomDialog
        open={openDialog}
        width={530}
        onClose={handleCloseDialog}
        title={
          dialogType === "delete"
            ? `Are you sure you want to delete ${selectedTestCase?.Test_Case}?`
            : dialogType === "rename"
              ? "Rename Testcase"
              : dialogType === "nlpSteps"
                ? "Test Case Step Details"
                : dialogType === "copy"
                  ? "Copy Testcase"
                  : dialogType === "addToSuite"
                    ? "Add to Test Suite"
                    : "Test Case Info"
        }
        content={renderDialogContent()}
        actions={renderDialogActions()}
      />
    </Menu>
  );
};

export default TSMenu;
