import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
} from "@mui/material";

import { EditIcon } from "../icons";
import EllipsisTooltipText from "./EllipsisTooltipText";
import ActionCheckBox from "../../pages-new/Studio/ActionCheckBox";

const TestTableView = ({
  testCaseResults,
  handleEdit,
  tsrData,
  isCheckbox,
  handleCheckboxChange,
  checkedItems,
}) => {
  return (
    <TableContainer
      component={Paper}
      sx={{ mt: 3, boxShadow: "0px 43px 62.4px 0px rgba(0, 0, 0, 0.09)" }}
    >
      <Table>
        <TableHead>
          <TableRow>
            {isCheckbox && <TableCell padding="checkbox" />}
            <TableCell>Step Number</TableCell>
            {/* <TableCell>Status</TableCell> */}
            {/* <TableCell>Screenshot</TableCell> */}
            <TableCell>Page Description</TableCell>
            <TableCell>Action</TableCell>
            <TableCell>Test Data</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {testCaseResults?.data.map((item, index) => (
            <TableRow key={item.idtest_step}>
              {isCheckbox && (
                <TableCell padding="checkbox">
                  <ActionCheckBox
                    handleCheckboxChange={handleCheckboxChange}
                    item={item}
                    checkedItems={checkedItems}
                  />
                </TableCell>
              )}
              <TableCell>{item.Step_Number}</TableCell>
              {/* <TableCell>
                <Box display="flex" alignItems="center">
                  {item.Issues ? <CircleWarningIcon /> : <CircleTickIcon />}
                </Box>
              </TableCell> */}
              {/* <TableCell>
                <Box
                  sx={{
                    width: 100,
                    height: 60,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "1px solid rgba(0, 0, 0, 0.12)",
                    borderRadius: "4px",
                    overflow: "hidden",
                  }}
                >
                  {tsrData &&
                  (tsrData[index]?.screenshot1 || tsrData[index]?.Failure_Screenshot_Location) ? (
                    <img
                      style={{ height: "100%", width: "100%", objectFit: "cover" }}
                      src={tsrData[index].screenshot1 || tsrData[index].Failure_Screenshot_Location}
                      alt="testImage"
                    />
                  ) : (
                    <ImageNotSupportedOutlinedIcon sx={{ color: "#8089A8" }} />
                  )}
                </Box>
              </TableCell> */}
              <TableCell>
                <Typography>{item.Page_Description || "-"}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{item.Action}</Typography>
              </TableCell>
              <TableCell>
                <EllipsisTooltipText text={item.TestData || "-"} maxChars={50} mode="chars" />
              </TableCell>
              <TableCell>
                <IconButton
                  onClick={() => handleEdit(item)}
                  sx={{
                    border: "2px solid #547de8",
                    borderRadius: "88px",
                    width: 40,
                    height: 40,
                  }}
                >
                  <EditIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TestTableView;
