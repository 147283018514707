import { Box, Link, styled, Typography } from "@mui/material";
import { StatusCard } from "../../components-new/common";
import { CalendarIcon, InfoIcon, TimeIcon } from "../../components-new/icons";
import ChromeIcon from "../../components-new/icons/ChromeIcon";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import LabelIcon from "@mui/icons-material/Label";
import DuoOutlinedIcon from "@mui/icons-material/DuoOutlined";
import { API_BASE_PATH, API_BASE_RELATIVE_PATH } from "../../Config/Config";
import { useSelector } from "react-redux";

const StatusContainer = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  border: 1px solid #eeee;
  padding: 15px;
  background: #ffff;
}
`;

const StatusBar = ({ testcaseid, testcaseresultId, data }) => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) {
    throw new Error("No user details found in localStorage");
  }
  const user = JSON.parse(userDetails);
  const isMobile = useSelector((state) => state.authoringSlice.isMobile);

  const recordingUrl = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=downloadTCRvideo&companyId=${user.companyid}&testcaseid=${testcaseid}&testcaseresultId=${testcaseresultId}&token=${user.token}`;
  return (
    <StatusContainer>
      <Box display="flex" columnGap={5} flex={2}>
        <StatusCard icon={<CalendarIcon />} title={"Run Date"} value={data?.Executed_Date} />
        <StatusCard
          icon={<LabelIcon style={{ color: "#FF5336" }} />}
          title={"Name"}
          value={data?.testcasename}
        />
        <StatusCard icon={<TimeIcon />} title={"Run Time"} value={data?.DurationSum} />
        <StatusCard icon={<InfoIcon />} title={"Status"} value={data?.Status} />
        <StatusCard icon={<ChromeIcon />} title={"Browser Type"} value={data?.Browser} />
        <StatusCard
          icon={<FilePresentIcon style={{ color: "#FF5336" }} />}
          title={"LogFile"}
          value={
            <Typography
              component={Link}
              href={data?.logFile}
              underline="always"
              color="inherit"
              target="_blank"
            >
              Log File
            </Typography>
          }
        />
        {isMobile && (
          <StatusCard
            icon={<DuoOutlinedIcon style={{ color: "#FF5336" }} />}
            title={"Recording"}
            value={
              <Typography
                component={Link}
                href={recordingUrl}
                underline="always"
                color="inherit"
                target="_blank"
              >
                Recording
              </Typography>
            }
          />
        )}
      </Box>
    </StatusContainer>
  );
};

export default StatusBar;
