import React from "react";
import {
    ChevronDownIcon,
    CheckCircleIcon,
    XCircleIcon,
} from "@heroicons/react/24/outline";

const TestCaseResult = ({ results }) => {
    if (!results || results.length === 0) {
        return <div className="text-postman-gray-medium p-4">No results to display.</div>;
    }

    return (
        <div className="bg-white text-postman-gray-dark w-full">
            {/* Header */}
            <div className="bg-postman-gray-light px-4 py-3 border-b border-gray-200">
                <h2 className="text-sm font-medium">Test Case Results</h2>
                <p className="text-xs text-postman-gray-medium mt-1">
                    Generated on: {new Date().toLocaleString()}
                </p>
            </div>

            {/* Results */}
            <div className="divide-y divide-gray-200">
                {results.map((testCaseResult, index) => {
                    const { testCase, result } = testCaseResult;

                    return (
                        <details key={index} className="group">
                            <summary className="flex items-center justify-between p-3 cursor-pointer list-none hover:bg-postman-gray-light select-none">
                                <div className="flex items-center gap-2">
                                    <div className="text-xs font-medium px-2 py-1 rounded bg-postman-orange text-white">
                                        {testCase.endpoint}
                                    </div>
                                    <span className="text-sm text-postman-gray-medium">
                                        {testCase.testCase}
                                    </span>
                                    <span
                                        className={`inline-flex items-center px-2 py-1 rounded text-xs font-medium
                        ${result.testResults["Status Code Check"].includes("PASS")
                                                ? "bg-green-100 text-postman-green"
                                                : "bg-red-100 text-postman-red"
                                            }`}
                                    >
                                        Status: {testCase.expectedStatusCode}
                                    </span>
                                </div>
                                <ChevronDownIcon className="h-4 w-4 text-postman-gray-medium transition-transform group-open:rotate-180" />
                            </summary>

                            <div className="border-t border-gray-200 bg-postman-gray-light">
                                <div className="p-3 border-b border-gray-200 last:border-0">
                                    <div className="flex items-center justify-between mb-2">
                                        <div className="flex items-center gap-3">

                                            <span
                                                className={`text-xs font-medium ${testCase.httpMethod === "GET"
                                                    ? "text-postman-blue"
                                                    : testCase.httpMethod === "POST"
                                                        ? "text-postman-green"
                                                        : testCase.httpMethod === "PUT"
                                                            ? "text-postman-yellow"
                                                            : testCase.httpMethod === "DELETE"
                                                                ? "text-postman-red"
                                                                : "text-postman-gray-medium"
                                                    }`}
                                            >
                                                {testCase.httpMethod}
                                            </span>
                                        </div>
                                        <div className="flex items-center gap-1.5">
                                            {result.testResults["Status Code Check"].includes("PASS") ? (
                                                <CheckCircleIcon className="h-4 w-4 text-postman-green" />
                                            ) : (
                                                <XCircleIcon className="h-4 w-4 text-postman-red" />
                                            )}
                                            <span
                                                className={`text-xs font-medium
                        ${result.testResults["Status Code Check"].includes("PASS")
                                                        ? "text-postman-green"
                                                        : "text-postman-red"
                                                    }`}
                                            >
                                                {result.testResults["Status Code Check"].includes("PASS") ? "PASSED" : "FAILED"}
                                            </span>
                                        </div>
                                    </div>

                                    <div className="space-y-2 text-sm">
                                        <div>
                                            <div className="text-xs text-postman-gray-medium mb-1">
                                                Expected Response:
                                            </div>
                                            <pre className="bg-white p-2 rounded text-xs overflow-x-auto border border-gray-200">
                                                {JSON.stringify(testCase.expectedResult, null, 2)}
                                            </pre>
                                        </div>

                                        <div>
                                            <div className="text-xs text-postman-gray-medium mb-1">
                                                Actual Response:
                                            </div>
                                            <pre className="bg-white p-2 rounded text-xs overflow-x-auto border border-gray-200">
                                                {JSON.stringify(result.originalResponse, null, 2)}
                                            </pre>
                                        </div>

                                        {result.error && (
                                            <div className="mt-2">
                                                <div className="text-xs text-postman-red mb-1">
                                                    Error:
                                                </div>
                                                <div className="bg-red-50 text-postman-red p-2 rounded text-xs border border-red-200">
                                                    {result.error}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </details>
                    );
                })}
            </div>
        </div>
    );
};

export default TestCaseResult;
