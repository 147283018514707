import React, { useState, useMemo } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useGetPagesQuery } from "../../useQuery";
import { Box, Pagination } from "@mui/material";
import searchIcon from "../../Assets/search-icon.svg";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useNavigate } from "react-router-dom";

const PageConfiguration = () => {
  const navigate = useNavigate();
  const [selectedRowIndex, setSelectedRowIndex] = useState(0);
  const [sortBy, setSortBy] = useState("Latest");
  const [showSortDropdown, setShowSortDropdown] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const userDetails = localStorage.getItem("userDetail");
  const user = JSON.parse(userDetails);
  const selectedProduct = user.products[0].idproducts;

  const { data: pagesData, isLoading: isLoadingPages } = useGetPagesQuery(selectedProduct);

  const handleRowClick = (index) => {
    setSelectedRowIndex(index);
  };

  const handleSortClick = () => {
    setShowSortDropdown(!showSortDropdown);
  };

  const handleSortSelect = (option) => {
    setSortBy(option);
    setShowSortDropdown(false);
  };

  // Sorting function
  const sortData = (data) => {
    if (!data) return [];

    const sortedData = [...data];
    switch (sortBy) {
      case "Latest":
        return sortedData.sort((a, b) => b.id - a.id);
      case "Oldest":
        return sortedData.sort((a, b) => a.id - b.id);
      case "Name A-Z":
        return sortedData.sort((a, b) => a.pagename.localeCompare(b.pagename));
      case "Name Z-A":
        return sortedData.sort((a, b) => b.pagename.localeCompare(a.pagename));
      case "Elements":
        return sortedData.sort((a, b) => b.totalelementonpage - a.totalelementonpage);
      default:
        return sortedData;
    }
  };

  // Filtering and sorting data
  const filteredAndSortedData = useMemo(() => {
    if (!pagesData?.nodes) return [];

    const filtered = pagesData.nodes.filter((page) =>
      page.pagename.toLowerCase().includes(searchQuery.toLowerCase()),
    );

    return sortData(filtered);
  }, [pagesData, searchQuery, sortBy]);

  // Pagination
  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredAndSortedData.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredAndSortedData, currentPage]);

  const handleEdit = (page) => {};

  const handleView = (page) => {
    navigate(`/config/page-elements/${page.pageid}`);
  };

  if (isLoadingPages)
    return (
      <div className="bg-[#151515] min-h-screen flex items-center justify-center ml-[320px]">
        <div className="text-white text-xl urbanist-font bg-[#1E1E1E] p-8 rounded-lg shadow-lg flex items-center space-x-4">
          <svg
            className="animate-spin h-6 w-6 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <span>Loading...</span>
        </div>
      </div>
    );

  return (
    <div className=" bg-[#151515] p-8 min-h-screen ml-[320px]">
      <div className="flex flex-row  items-center justify-between mb-8 py-4">
        <div>
          <h1 className="text-white text-2xl font-bold mb-2  urbanist-font ">View Pages</h1>
          <p className="text-gray-400 text-md  urbanist-font ">List of Pages</p>
        </div>
        <div className="relative flex flex-row items-center flex-1 justify-end">
          <div className="flex items-center flex-1 max-w-[340px] ml-4">
            <div className="relative w-full">
              <div className="absolute inset-y-0 left-4 flex items-center pointer-events-none">
                <img src={searchIcon} alt="Search Icon" />
              </div>
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full h-[40px] py-2 pl-12 pr-4 bg-[#272727] rounded-[30px] text-gray-400 placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-600"
              />
            </div>
          </div>
          <button
            onClick={handleSortClick}
            className="flex items-center justify-center space-x-1 bg-[#1E1E1E] text-[12px] text-gray-400 w-[140px] h-[40px] rounded-[30px] ml-4"
          >
            <span className="urbanist-font">Sort By: {sortBy}</span>
            <KeyboardArrowDownIcon className="w-5 h-5" />
          </button>
          {showSortDropdown && (
            <div className="absolute right-0 top-12 w-[140px] bg-[#1E1E1E] rounded-lg shadow-lg py-1 z-10">
              {["Latest", "Oldest", "Name A-Z", "Name Z-A", "Elements"].map((option) => (
                <button
                  key={option}
                  onClick={() => handleSortSelect(option)}
                  className="w-full text-left px-4 py-2 text-[12px] text-gray-400 hover:bg-gray-800 urbanist-font"
                >
                  {option}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="bg-[#1E1E1E] rounded-lg overflow-hidden p-4">
        <table className="w-full bg-[#181818] rounded-md border-separate border-spacing-0">
          <thead>
            <tr className="text-white text-sm border-b border-white">
              <th className="text-left py-4 px-6 urbanist-font border-r border-[#4F4F4F]">
                PAGE NAME
              </th>
              <th className="text-left py-4 px-6 urbanist-font border-r border-[#4F4F4F]">
                TOTAL ELEMENTS
              </th>
              <th className="text-left py-4 px-6 urbanist-font border-r border-[#4F4F4F]">
                USED ELEMENTS
              </th>
              <th className="text-left py-4 px-6 urbanist-font border-r border-[#4F4F4F]">EDIT</th>
              <th className="text-left py-4 px-6 urbanist-font">VIEW ELEMENTS</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData.map((page, index) => (
              <tr
                key={page.id}
                className={`${
                  index === selectedRowIndex
                    ? "bg-gradient-to-r from-purple-600 via-pink-500 to-orange-500 "
                    : "hover:bg-gray-800 bg-[#181818]"
                } cursor-pointer transition-colors`}
                onClick={() => handleRowClick(index)}
              >
                <td className="py-4 px-6 border-r border-[#4F4F4F]">
                  <span className="text-white font-medium urbanist-font">{page.pagename}</span>
                </td>
                <td className="py-4 px-6 text-white urbanist-font border-r border-[#4F4F4F]">
                  {page.totalelementonpage}
                </td>
                <td className="py-4 px-6 text-white urbanist-font border-r border-[#4F4F4F]">
                  {page.usedelementsonpage}
                </td>
                <td className="py-4 px-6 border-r border-[#4F4F4F]">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEdit(page);
                    }}
                    className="space-x-2 bg-white hover:bg-gray-200 rounded-full h-12 w-12 flex items-center justify-center transition-colors"
                  >
                    <ModeEditOutlineOutlinedIcon />
                  </button>
                </td>
                <td className="py-4 px-6">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleView(page);
                    }}
                    className="space-x-2 bg-white hover:bg-gray-200 rounded-full h-12 w-12 flex items-center justify-center transition-colors"
                  >
                    <VisibilityOutlinedIcon />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Box py={2} display={"flex"} justifyContent="center">
        <Pagination
          count={Math.ceil(filteredAndSortedData.length / itemsPerPage)}
          page={currentPage}
          onChange={(e, newpage) => setCurrentPage(newpage)}
          color="primary"
          variant="outlined"
          shape="rounded"
        />
      </Box>
    </div>
  );
};

export default PageConfiguration;
