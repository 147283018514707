import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

let scheduleData;
let suiteId;

const fetchSchedule = async () => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");
  const user = JSON.parse(userDetails);

  const baseUrl = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=suiteScheduler&companyid=${user.companyid}&token=${user.token}&suiteid=${suiteId}`;

  const response = await axios.get(
    `${baseUrl}${scheduleData.frequency ? `&frequency=${scheduleData.frequency}` : ""}${scheduleData.scheduletime ? `&time=${scheduleData.scheduletime}` : ""}&subfreq=${scheduleData.subfreq ? `${scheduleData.subfreq}` : ""}&date=${scheduleData.scheduledate ? `${scheduleData.scheduledate}` : null}`,
  );

  return response.data;
};

const useSuiteSchedulerQuery = () => {
  const query = useQuery({
    queryKey: ["updateSchedule"],
    queryFn: () => {
      return fetchSchedule();
    },
    enabled: false,
    retry: true,
    cacheTime: 90000,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    staleTime: Infinity,
  });

  const refetchWithParams = async (payload, suiteid) => {
    scheduleData = payload;
    suiteId = suiteid;
    await query.refetch();
  };

  return {
    ...query,
    refetchWithParams,
  };
};

export default useSuiteSchedulerQuery;
