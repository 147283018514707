import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

const searchTcDropDown = async (id, pageNumber = 1) => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");
  const user = JSON.parse(userDetails);

  const response = await axios.get(
    `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=searchTCDropDown&companyid=${user.companyid}&token=${user.token}&modid=${id}&pnum=${pageNumber}`,
  );
  return response.data;
};

const useSearchTcDropDown = (id, pageNumber) => {
  return useQuery({
    queryKey: ["searchTCDropDown", id],
    queryFn: () => searchTcDropDown(id, pageNumber),
    retry: true,
  });
};

export default useSearchTcDropDown;
