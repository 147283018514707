import { Box, Typography } from "@mui/material";
import React from "react";
import {
  useSearchTestCaseRecordsQuery,
  useTestCaseResultsFromTestSuiteQuery,
} from "../../useQuery";
import { ExeTestCaseReportsTable } from "./";
import { useLocation } from "react-router-dom";
import CustomBreadcrumbs from "../../components-new/common/CustomBreadCrums";
import { PageTitle } from "../../components-new/common";

const ExecutionReportsHistory = () => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const tcid = queryParams.get("tcid");
  const tsId = queryParams.get("tsId");
  const criteria = queryParams.get("criteria");
  const runDate = queryParams.get("date");
  const name = queryParams.get("name");

  const { data: reportsData, isSuccess: isTestCaseFetchedSuccess } = useSearchTestCaseRecordsQuery(
    tcid,
    {
      enabled: Boolean(tcid) && !Boolean(criteria),
    },
  );

  const { data: testCaseData, isSuccess: isTestCaseFetchedSuccessFromTS } =
    useTestCaseResultsFromTestSuiteQuery(tsId, criteria, {
      enabled: Boolean(tsId) && Boolean(criteria) && !Boolean(tcid),
    });

  const TestSuitesBreadCrumbs = [
    <Typography key="1" color="text.primary">
      Execution Reports
    </Typography>,
    <Typography key="2" color="text.primary">{`Test Suite - ${name}`}</Typography>,
    <Typography key="3" color="text.primary">
      {`Run date - ${runDate}`}
    </Typography>,
    <Typography key="4" color="text.primary">
      Test cases status
    </Typography>,
  ];

  const TestCasesBreadCrumbs = [
    <Typography key="1" color="text.primary">
      Execution Reports
    </Typography>,
    <Typography key="2" color="text.primary">
      {name}
    </Typography>,
    <Typography key="3" color="text.primary">
      Test cases history
    </Typography>,
  ];

  return (
    <Box sx={{ my: 4 }}>
      {tcid && <PageTitle title={name} variant="h5" mb={2} />}
      <CustomBreadcrumbs ariaLabel="breadcrumb" mb={2}>
        {tsId ? TestSuitesBreadCrumbs : TestCasesBreadCrumbs}
      </CustomBreadcrumbs>
      <Box pt={2}>
        {tcid && reportsData && (
          <ExeTestCaseReportsTable data={reportsData} isSuccess={isTestCaseFetchedSuccess} />
        )}
        {tsId && criteria && testCaseData && (
          <ExeTestCaseReportsTable
            testSuiteName={name}
            runDate={runDate}
            data={testCaseData.testcaseresult}
            isSuccess={isTestCaseFetchedSuccessFromTS}
          />
        )}
      </Box>
    </Box>
  );
};

export default ExecutionReportsHistory;
