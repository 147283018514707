import axios from "axios";

// const API_URL = `${API_BACKEND_URL}`;
//API_BACKEND_URL IMPORTED FROM Config

const apiService = async (method, url, data = null, params = {}, headers = {}) => {
  // try {
  //   const response = await axios({
  //     method: method,
  //     url: `${API_URL}${url}`,
  //     data: data,
  //     params: params,
  //     headers: headers,
  //   });
  //   return response;
  // } catch (error) {
  //   console.log(error.response)
  //   alert(error?.response?.data)
  // }
};

export const apiAnyRequestService = async (method, url, data = null, params = {}, headers = {}) => {
  try {
    const response = await axios({
      method: method,
      url: `${url}`,
      data: data,
      params: params,
      headers: headers,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export default apiService;
