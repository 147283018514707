import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

export const getPages = async (id) => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) {
    throw new Error("No user details found in localStorage");
  }
  const user = JSON.parse(userDetails);
  const response = await axios.get(
    `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?token=${user.token}&action=getPages&productid=${id}&companyid=${user.companyid}`,
  );
  return response.data;
};

const useGetPages = (id) => {
  return useQuery({
    queryKey: ["getPages", id],
    queryFn: () => getPages(id),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};

export default useGetPages;
