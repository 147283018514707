import { useMutation } from "@tanstack/react-query";
import { API_BASE_PATH, API_BASE_RELATIVE_PATH } from "../Config/Config";
import axios from "axios";

const updateSuiteEmail = async ({ data, id }) => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");
  const user = JSON.parse(userDetails);

  const response = await axios

    .post(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestSuiteResultsEmail&companyid=${user.companyid}&token=${user.token}&tsuid=${id}`,
      data,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });

  return response;
};

const useUpdateSuiteEmail = () => {
  return useMutation({
    mutationFn: updateSuiteEmail,
    onError: (error) => {
      console.log("Error uploading file", error.message);
    },
  });
};

export default useUpdateSuiteEmail;
