import React from "react";
import {
  InsertDriveFile,
  Share,
  GetApp,
  Chat,
  InsertChart,
  NotificationsActive,
} from "@mui/icons-material";

const TestCase = () => {
  const metrics = [
    { label: "Total Cases", value: "66456", subtext: "" },
    { label: "NA", value: "6871", subtext: "" },
    { label: "Executable", value: "59585", subtext: "" },
    { label: "Executed", value: "48170", subtext: "" },
    { label: "Pass", value: "47054", subtext: "" },
    { label: "Pass", value: "1116", subtext: "" },
    { label: "Blocked", value: "3915", subtext: "" },
    { label: "No Run", value: "7500", subtext: "" },
  ];

  const percentages = [
    { label: "Total Executed", value: "89%" },
    { label: "Total Pass", value: "88%" },
    { label: "Total Fail", value: "1%" },
    { label: "Total Blocked", value: "3%" },
    { label: "Total No Run", value: "9%" },
  ];

  const navItems = [
    { label: "File", icon: <InsertDriveFile className="w-5 h-5" /> },
    { label: "Share", icon: <Share className="w-5 h-5" /> },
    { label: "Export", icon: <GetApp className="w-5 h-5" /> },
    { label: "Chat in Teams", icon: <Chat className="w-5 h-5" /> },
    { label: "Get Insights", icon: <InsertChart className="w-5 h-5" /> },
    { label: "Subscribe To Report", icon: <NotificationsActive className="w-5 h-5" /> },
  ];

  // Dummy sprint data
  const sprintData = [
    {
      sprintName: "Sprint 9 - UAT_Drop 3",
      totalCases: 617,
      na: 0,
      executableTestCases: 617,
      executed: 0,
      pass: 0,
      fail: 0,
      blocked: 0,
      notRun: 617,
      executedPercent: 0,
      passPercent: 0,
      failPercent: 0,
      notRunPercent: 0,
      blockedPercent: 100,
    },
    {
      sprintName: "Sprint 9 - UAT_Drop 2",
      totalCases: 13046,
      na: 76,
      executableTestCases: 12970,
      executed: 5282,
      pass: 4647,
      fail: 635,
      blocked: 3088,
      notRun: 4600,
      executedPercent: 41,
      notRunPercent: 36,
      passPercent: 5,
      failPercent: 24,
      blockedPercent: 35,
    },
    {
      sprintName: "Sprint 9 - UAT_Drop 1",
      totalCases: 1858,
      na: 6,
      executableTestCases: 1852,
      executed: 1702,
      pass: 1643,
      fail: 59,
      blocked: 145,
      notRun: 5,
      executedPercent: 92,
      notRunPercent: 89,
      passPercent: 3,
      failPercent: 8,
      blockedPercent: 0,
    },
    {
      sprintName: "Sprint 9 - SIT",
      totalCases: 86,
      na: 0,
      executableTestCases: 86,
      executed: 33,
      pass: 31,
      fail: 2,
      blocked: 6,
      notRun: 47,
      executedPercent: 38,
      notRunPercent: 36,
      passPercent: 2,
      failPercent: 7,
      blockedPercent: 55,
    },
    {
      sprintName: "Sprint 9 - Mobile",
      totalCases: 1150,
      na: 0,
      executableTestCases: 1150,
      executed: 931,
      pass: 828,
      fail: 103,
      blocked: 201,
      notRun: 18,
      executedPercent: 81,
      notRunPercent: 72,
      passPercent: 9,
      failPercent: 17,
      blockedPercent: 2,
    },
    {
      sprintName: "Sprint 8 - UAT_Drop 3",
      totalCases: 2161,
      na: 89,
      executableTestCases: 2072,
      executed: 2071,
      pass: 2056,
      fail: 15,
      blocked: 0,
      notRun: 1,
      executedPercent: 100,
      notRunPercent: 99,
      passPercent: 1,
      failPercent: 0,
      blockedPercent: 0,
    },
    {
      sprintName: "Sprint 8",
      totalCases: 2525,
      na: 61,
      executableTestCases: 2464,
      executed: 2464,
      pass: 2438,
      fail: 26,
      blocked: 0,
      notRun: 0,
      executedPercent: 100,
      notRunPercent: 99,
      passPercent: 1,
      failPercent: 0,
      blockedPercent: 0,
    },
  ];

  return (
    <div className="p-6 bg-white rounded-lg shadow-xl  mx-auto">
      {/* Header */}
      <div className="bg-black text-white p-6 rounded-lg mb-6">
        <h1 className="text-2xl font-bold text-center">Overall Test Cases Details</h1>
      </div>

      {/* Enhanced Navigation */}
      {/* <div className="flex flex-wrap gap-2 mb-8">
        {navItems.map((item) => (
          <button
            key={item.label}
            className="flex items-center gap-2 px-4 py-2 bg-white rounded-lg shadow-sm hover:shadow-md transition-all duration-200 border border-gray-200 text-black hover:bg-black hover:text-white group"
          >
            <span className="transition-colors">{item.icon}</span>
            <span className="text-sm font-medium transition-colors">{item.label}</span>
          </button>
        ))}
      </div> */}

      {/* Metrics Grid with monochrome styling */}
      <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-8 gap-4 mb-8">
        {metrics.map((metric, index) => (
          <div
            key={index}
            className="bg-white border border-gray-200 rounded-xl p-4 text-center hover:shadow-md transition-shadow duration-200 hover:border-black"
          >
            <div className="text-2xl font-bold text-black">{metric.value}</div>
            <div className="text-sm text-gray-600 mt-1">{metric.label}</div>
            {metric.subtext && <div className="text-xs text-gray-500 mt-1">{metric.subtext}</div>}
          </div>
        ))}
      </div>

      {/* Percentage Metrics with monochrome styling */}
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4 mb-8">
        {percentages.map((percentage, index) => (
          <div
            key={index}
            className="bg-white border border-gray-200 rounded-xl p-6 text-center hover:shadow-md transition-shadow duration-200 hover:border-black"
          >
            <div className="text-3xl font-bold text-black">{percentage.value}</div>
            <div className="text-sm text-gray-600 mt-2">{percentage.label}</div>
          </div>
        ))}
      </div>

      {/* Sprint Data Table with monochrome styling */}
      <div className="bg-white rounded-xl shadow-sm overflow-x-auto border border-gray-200">
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-black ">
              {[
                "Sprint",
                "Total Cases",
                "NA",
                "Executable Test Cases",
                "Executed",
                "Pass",
                "Fail",
                "Blocked",
                "Not Run",
                "Executed (%)",
                "Passed (%)",
                "Failed (%)",
                "Not Run (%)",

                "Blocked (%)",
              ].map((column, index) => (
                <th
                  key={index}
                  className="border-b border-r border-gray-200 p-4 text-left text-sm font-semibold text-white "
                >
                  {column}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sprintData.map((row, rowIndex) => (
              <tr key={rowIndex} className="hover:bg-gray-50">
                <td className="border-b border-r border-gray-200 p-4 text-sm text-gray-600">
                  {row.sprintName}
                </td>
                {[
                  row.totalCases,
                  row.na,
                  row.executableTestCases,
                  row.executed,
                  row.pass,
                  row.fail,
                  row.blocked,
                  row.notRun,
                  row.executedPercent,
                  row.notRunPercent,
                  row.passPercent,
                  row.failPercent,
                  row.blockedPercent,
                ].map((value, colIndex) => (
                  <td
                    key={colIndex}
                    className="border-b border-r border-gray-200 p-4 text-sm text-gray-600"
                  >
                    {value}
                  </td>
                ))}
              </tr>
            ))}
            {/* Total Row */}
            <tr className="bg-gray-700 font-semibold">
              <td className="p-4 text-sm text-white">Total</td>
              {[
                "totalCases",
                "na",
                "executableTestCases",
                "executed",
                "pass",
                "fail",
                "blocked",
                "notRun",
                "executedPercent",
                "notRunPercent",
                "passPercent",
                "failPercent",
                "blockedPercent",
              ].map((key) => (
                <td key={key} className="p-4 text-sm text-white">
                  {sprintData?.reduce((sum, row) => sum + (row[key] || 0), 0)}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TestCase;
