import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
  Alert,
  Paper,
  Modal,
  IconButton,
  Tab,
  Tabs,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Card,
  CardContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  useGetCapabilitiesQuery,
  useModuleQuery,
  useProductQuery,
  usePostCapabilityQuery,
  useRecentTestCaseResultsQuery,
} from "../../useQuery";

const MobileAutomation = () => {
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedModule, setSelectedModule] = useState("");
  const [testScenarioName, setTestScenarioName] = useState("");
  const [error, setError] = useState("");
  const [token, setToken] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputMode, setInputMode] = useState("form");
  const [jsonInput, setJsonInput] = useState("");
  const [jsonError, setJsonError] = useState("");
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [newCapabilityName, setNewCapabilityName] = useState("");
  const [hasModifiedCapabilities, setHasModifiedCapabilities] = useState(false);
  const [recordingMode, setRecordingMode] = useState(""); // "" | "continue" | "new"

  const defaultCapabilities = {
    "appium:platformName": "",
    "appium:platformVersion": "",
    "appium:deviceName": "",
    "appium:automationName": "",
    "appium:app": "",
    "appium:appPackage": "",
    "appium:appActivity": "",
    "appium:appWaitActivity": "",
    "appium:fullReset": false,
    "appium:noReset": true,
    "appium:appWaitDuration": 30000,
    "appium:deviceReadyTimeout": 30,
    "appium:disableIdLocatorAutocompletion": true,
  };

  const [capabilityValues, setCapabilityValues] = useState(defaultCapabilities);

  // Queries
  const { data: products } = useProductQuery();
  const { data: modules } = useModuleQuery(selectedProduct, {
    enabled: !!selectedProduct,
  });
  const { data: savedCapabilities, refetch: refetchCapabilities } = useGetCapabilitiesQuery();
  const postCapabilityMutation = usePostCapabilityQuery();
  const { data: lastestResult } = useRecentTestCaseResultsQuery();

  const lastRecordedTestScenario = lastestResult?.testcase?.Test_Case;
  const lastRecordedTestCaseId = lastestResult?.Testcase;
  // const stepNums = lastestResult?.stepnums;
  // const lastStepNum = Object.values(stepNums)?.pop();
  const stepNums = lastestResult?.stepnums || {};
  const lastStepNum = Object.values(stepNums).at(-1) || null;

  // Load user token on component mount
  useEffect(() => {
    const loadUserToken = () => {
      try {
        const userDetails = localStorage.getItem("userDetail");
        if (!userDetails) {
          setError("User details not found. Please log in again.");
          return;
        }
        const temp = JSON.parse(userDetails);
        setToken(temp.token);
      } catch (err) {
        setError("Error retrieving user details. Please log in again.");
      }
    };

    loadUserToken();
  }, []);

  const handleCapabilityChange = (key, value) => {
    setHasModifiedCapabilities(true);
    setCapabilityValues((prev) => ({
      ...prev,
      [key]: value,
    }));

    if (inputMode === "json") {
      const updatedCapabilities = {
        ...capabilityValues,
        [key]: value,
      };
      setJsonInput(JSON.stringify(updatedCapabilities, null, 2));
    }
  };

  const handleJsonInput = (value) => {
    setJsonInput(value);
    setJsonError("");
    setHasModifiedCapabilities(true);

    try {
      const parsed = JSON.parse(value);
      const requiredFields = [
        "appium:platformName",
        "appium:platformVersion",
        "appium:deviceName",
        "appium:automationName",
        "appium:app",
        "appium:appPackage",
        "appium:appActivity",
      ];

      const missingFields = requiredFields.filter((field) => !parsed[field]);
      if (missingFields.length > 0) {
        setJsonError(`Missing required fields: ${missingFields.join(", ")}`);
        return;
      }

      setCapabilityValues({
        ...defaultCapabilities,
        ...parsed,
      });
    } catch (e) {
      setJsonError("Invalid JSON format");
    }
  };

  const handleSaveToServer = async () => {
    if (!newCapabilityName.trim()) {
      setError("Please enter a name for the capability");
      return;
    }

    try {
      await postCapabilityMutation.mutateAsync({
        name: newCapabilityName,
        capabilities: capabilityValues,
      });

      await refetchCapabilities();
      setSaveDialogOpen(false);
      setNewCapabilityName("");
      setError("");
      setIsModalOpen(false);
      setHasModifiedCapabilities(false);
    } catch (err) {
      setError("Failed to save capability. Please try again.");
    }
  };

  const handleLoadCapability = (capability) => {
    setCapabilityValues(capability.capabilities);
    if (inputMode === "json") {
      setJsonInput(JSON.stringify(capability.capabilities, null, 2));
    }
    setIsModalOpen(false);
    setHasModifiedCapabilities(false);
  };

  const handleCloseModal = () => {
    if (hasModifiedCapabilities) {
      setSaveDialogOpen(true);
    } else {
      setIsModalOpen(false);
    }
  };

  const handleSaveCapabilities = () => {
    if (inputMode === "json" && jsonError) {
      return;
    }
    setSaveDialogOpen(true);
  };

  const handleProductChange = (event) => {
    setSelectedProduct(event.target.value);
    setSelectedModule("");
  };

  const handleModuleChange = (event) => {
    setSelectedModule(event.target.value);
  };

  const handleTestScenarioChange = (event) => {
    setTestScenarioName(event.target.value);
  };

  const handleOpenAppium = (isContinueRecording = false) => {
    if (!token) {
      setError("Authentication token not found. Please log in again.");
      return;
    }

    if (!isContinueRecording && (!selectedProduct || !selectedModule || !testScenarioName.trim())) {
      setError("Please fill in all required fields before starting a new recording.");
      return;
    }

    try {
      const baseUrl = "http://localhost:5173";
      const params = new URLSearchParams({
        token,
        product: isContinueRecording ? lastestResult?.Product : selectedProduct,
        module: isContinueRecording ? lastestResult?.Modules : selectedModule,
        testScenario: isContinueRecording ? lastRecordedTestScenario : testScenarioName,
        continueRecording: isContinueRecording ? "true" : "false",
      });

      if (isContinueRecording && lastRecordedTestCaseId) {
        params.append("testCaseId", lastRecordedTestCaseId);
        // params.append("continueRecording", "true");
        params.append("lastStep", lastStepNum);
      }

      window.open(`${baseUrl}?${params.toString()}`, "_blank");
    } catch (e) {
      setError("Error processing capabilities. Please check the values.");
    }
  };

  const handleInputModeChange = (_, newMode) => {
    setInputMode(newMode);
    if (newMode === "json") {
      setJsonInput(JSON.stringify(capabilityValues, null, 2));
    }
  };

  const renderSavedCapabilitiesTab = () => (
    <Box className="w-full">
      <Typography variant="h6" className="mb-4">
        Saved Capabilities
      </Typography>

      <List>
        {savedCapabilities?.map((capability) => (
          <ListItem key={capability.id} className="bg-gray-50 mb-2 rounded">
            <ListItemText
              primary={capability.name}
              secondary={`Platform: ${capability.capabilities["appium:platformName"]}, Device: ${capability.capabilities["appium:deviceName"]}`}
            />
            <ListItemSecondaryAction>
              <Button
                onClick={() => handleLoadCapability(capability)}
                variant="contained"
                size="small"
                className="bg-blue-600 hover:bg-blue-500"
              >
                Use This Configuration
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>

      {(!savedCapabilities || savedCapabilities.length === 0) && (
        <Typography className="text-gray-500 text-center py-4">
          No saved capabilities found
        </Typography>
      )}
    </Box>
  );

  const renderRecordingModeSelection = () => (
    <Card className="mb-6 p-4 mt-4 ">
      <CardContent className="">
        <FormControl component="fieldset">
          <FormLabel component="legend" className="text-lg font-medium mb-4">
            What would you like to do?
          </FormLabel>
          <RadioGroup
            value={recordingMode}
            onChange={(e) => {
              setRecordingMode(e.target.value);
              if (e.target.value === "new") {
                setSelectedProduct("");
                setSelectedModule("");
                setTestScenarioName("");
              }
            }}
          >
            {lastRecordedTestScenario && (
              <FormControlLabel
                value="continue"
                control={<Radio />}
                label={
                  <Box className="">
                    <Typography variant="body1" className="font-medium">
                      Continue recording existing test scenario
                    </Typography>
                    <Typography variant="body2" className="text-gray-600">
                      Continue Recording: {lastRecordedTestScenario}
                    </Typography>
                  </Box>
                }
                className="mb-5"
              />
            )}
            <FormControlLabel
              value="new"
              control={<Radio />}
              label={
                <Box className="">
                  <Typography variant="body1" className="font-medium">
                    Record a new test scenario
                  </Typography>
                  <Typography variant="body2" className="text-gray-600">
                    Start Recording a new Test Scenario
                  </Typography>
                </Box>
              }
            />
          </RadioGroup>
        </FormControl>
      </CardContent>
    </Card>
  );

  const renderNewTestCaseForm = () => (
    <Box display="flex" alignItems="center" gap={2} mt={3}>
      <Box>
        <Typography variant="body1" className="text-gray-700 mb-2">
          Select Product
        </Typography>
        <Select
          value={selectedProduct}
          onChange={handleProductChange}
          displayEmpty
          fullWidth
          sx={{ minWidth: 200 }}
        >
          <MenuItem disabled value="">
            Select Product
          </MenuItem>
          {products?.map((product) => (
            <MenuItem key={product.idproducts} value={product.idproducts}>
              {product.productname}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box>
        <Typography variant="body1" className="text-gray-700 mb-2">
          Select Module
        </Typography>
        <Select
          value={selectedModule}
          onChange={handleModuleChange}
          displayEmpty
          fullWidth
          disabled={!selectedProduct}
          sx={{ minWidth: 200 }}
        >
          <MenuItem disabled value="">
            Select Module
          </MenuItem>
          {modules?.map((module) => (
            <MenuItem key={module.idmodules} value={module.idmodules}>
              {module.modulename}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box>
        <Typography variant="body1" className="text-gray-700 mb-2">
          Test Scenario Name
        </Typography>
        <TextField
          value={testScenarioName}
          onChange={handleTestScenarioChange}
          placeholder="Enter Test Scenario"
          fullWidth
          sx={{ minWidth: 200 }}
        />
      </Box>

      <Button
        variant="contained"
        onClick={() => setIsModalOpen(true)}
        className="bg-green-600 hover:bg-green-500"
        sx={{
          borderRadius: "10px",
          py: 1.5,
          px: 4,
          textTransform: "none",
          fontSize: "1rem",
          height: "56px",
          mt: 3,
        }}
        disabled={!selectedModule || !testScenarioName}
      >
        Enter Capabilities
      </Button>

      <Button
        variant="contained"
        onClick={() => handleOpenAppium(false)}
        disabled={!selectedModule || !testScenarioName.trim()}
        className="bg-blue-600 hover:bg-blue-500"
        sx={{
          borderRadius: "10px",
          py: 1.5,
          px: 4,
          textTransform: "none",
          fontSize: "1rem",
          height: "56px",
          mt: 3,
        }}
      >
        Start Session
      </Button>
    </Box>
  );

  return (
    <Box className="px-6 mx-auto">
      <Typography variant="h4" className="text-blue-600" fontWeight={500}>
        Mobile Automation Testing
      </Typography>

      {renderRecordingModeSelection()}

      {recordingMode === "continue" && lastRecordedTestScenario && (
        <Box className="mt-4">
          <Button
            variant="contained"
            onClick={() => handleOpenAppium(true)}
            className="bg-blue-600 hover:bg-blue-500"
          >
            Continue Recording
          </Button>
        </Box>
      )}

      {recordingMode === "new" && renderNewTestCaseForm()}

      {/* Capabilities Modal */}
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="capabilities-modal"
        className="flex items-center justify-center"
      >
        <Box className="bg-white rounded-lg p-6 max-w-4xl w-full mx-4 max-h-[90vh] overflow-y-auto">
          <Box className="flex justify-between items-center mb-4">
            <Typography variant="h6" component="h2">
              Device Capabilities
            </Typography>
            <IconButton onClick={handleCloseModal} size="small" className="text-gray-500">
              <CloseIcon />
            </IconButton>
          </Box>

          <Tabs value={inputMode} onChange={handleInputModeChange} className="mb-4">
            <Tab label="Form Input" value="form" />
            <Tab label="JSON Input" value="json" />
            <Tab label="Saved Capabilities" value="saved" />
          </Tabs>

          {inputMode === "form" ? (
            <Box className="grid grid-cols-2 gap-4">
              {Object.entries(defaultCapabilities).map(([key, defaultValue]) => {
                const isBoolean = typeof defaultValue === "boolean";
                const isNumber = typeof defaultValue === "number";

                return (
                  <Box key={key} className="mb-4">
                    <Typography variant="body2" className="text-gray-700 mb-1">
                      {key}
                    </Typography>
                    {isBoolean ? (
                      <Select
                        fullWidth
                        value={capabilityValues[key]}
                        onChange={(e) => handleCapabilityChange(key, e.target.value)}
                      >
                        <MenuItem value={true}>true</MenuItem>
                        <MenuItem value={false}>false</MenuItem>
                      </Select>
                    ) : isNumber ? (
                      <TextField
                        fullWidth
                        type="number"
                        value={capabilityValues[key]}
                        onChange={(e) => handleCapabilityChange(key, Number(e.target.value))}
                      />
                    ) : (
                      <TextField
                        fullWidth
                        value={capabilityValues[key]}
                        onChange={(e) => handleCapabilityChange(key, e.target.value)}
                        placeholder={`Enter ${key}`}
                      />
                    )}
                  </Box>
                );
              })}
            </Box>
          ) : inputMode === "json" ? (
            <Box className="w-full">
              <TextField
                multiline
                rows={20}
                fullWidth
                value={jsonInput}
                onChange={(e) => handleJsonInput(e.target.value)}
                error={!!jsonError}
                helperText={jsonError}
                placeholder="Paste your capabilities JSON here"
                sx={{
                  fontFamily: "monospace",
                  "& .MuiInputBase-input": {
                    fontFamily: "monospace",
                  },
                }}
              />
            </Box>
          ) : (
            renderSavedCapabilitiesTab()
          )}

          <Box className="flex justify-end gap-2 mt-4">
            <Button variant="outlined" onClick={handleCloseModal} className="text-gray-600">
              Cancel
            </Button>
            {hasModifiedCapabilities && (inputMode === "form" || inputMode === "json") && (
              <Button
                variant="contained"
                onClick={handleSaveCapabilities}
                disabled={inputMode === "json" && !!jsonError}
                className="bg-blue-600 hover:bg-blue-500"
              >
                Save & Use Configuration
              </Button>
            )}
          </Box>
        </Box>
      </Modal>

      {/* Save Capability Dialog */}
      <Dialog open={saveDialogOpen} onClose={() => setSaveDialogOpen(false)}>
        <DialogTitle>Save Capability Configuration</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Configuration Name"
            fullWidth
            value={newCapabilityName}
            onChange={(e) => setNewCapabilityName(e.target.value)}
            placeholder="Enter a name for this configuration"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSaveDialogOpen(false)} className="text-gray-600">
            Cancel
          </Button>
          <Button
            onClick={handleSaveToServer}
            variant="contained"
            className="bg-blue-600 hover:bg-blue-500"
          >
            Save & Continue
          </Button>
        </DialogActions>
      </Dialog>

      {/* Error Display */}
      {error && (
        <Alert severity="error" className="mt-4" onClose={() => setError("")}>
          {error}
        </Alert>
      )}
    </Box>
  );
};

export default MobileAutomation;
