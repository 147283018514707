import ReactApexChart from "react-apexcharts";

export default function PrettyDonut({ data }) {
  const notExec = data.totalCount - (data.passCount + data.failCount);
  const series = [
    data.passCount,
    data.failCount,
    notExec,
    data?.skippedOver || 0,
    data?.incomplete || 0,
  ];

  const seriesNames = ["Pass", "Fail", "Not Executed", "Skipped Over", "Incomplete"];

  const options = {
    chart: {
      type: "donut",
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#008000", "#FF4C4C", "#835FF0", "#D6DEF8"],
    labels: seriesNames,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
    legend: {
      position: "bottom",
      offsetY: 0,
      height: 50,
      formatter: (seriesName, opts) => {
        const percentage = (
          (opts.w.globals.series[opts.seriesIndex] / data.totalCount) *
          100
        ).toFixed(2);
        return `${seriesName}: ${percentage}%`;
      },
    },
  };

  return <ReactApexChart options={options} series={series} type="donut" width={"100%"} />;
}
