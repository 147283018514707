import { Checkbox } from "@mui/material";

const ActionCheckBox = ({ handleCheckboxChange, checkedItems, item }) => {
  return (
    <Checkbox
      sx={{ p: 0 }}
      onChange={(e) => {
        handleCheckboxChange(e, item);
      }}
      checked={checkedItems?.includes(item)}
    />
  );
};

export default ActionCheckBox;
