const isValidJson = (str) => {
  try {
    JSON.parse(str);
    return true;
  } catch {
    return false;
  }
};

const ConstructUrl = (collection, testCase) => {    
  try {
    const baseUrl = typeof collection === "string" ? collection : collection?.baseurl ?? "";
    if (!baseUrl) {
      throw new Error("Base URL is missing or invalid.");
    }

    let query_params = {};
    if (testCase?.body) {
      if (isValidJson(testCase.body)) {
        query_params = JSON.parse(testCase.body);
      } else {
        console.warn(`Invalid JSON in inputParameters, skipping: ${testCase.body}`);
        query_params = {}; // Default to an empty object for invalid parameters
      }
    }

    let endpoint = testCase?.endpoint ?? "";
    if (!endpoint) {
      throw new Error("Endpoint is missing or invalid.");
    }
    
    // Replace path parameters (e.g., {petId}) in the endpoint
    Object.entries(query_params).forEach(([key, value]) => {
      const paramPlaceholder = `{${key}}`;
      if (endpoint.includes(paramPlaceholder)) {
        endpoint = endpoint.replace(paramPlaceholder, encodeURIComponent(value));
        delete query_params[key]; // Remove path params from query params
      }
    });

    const fullUrl = (() => {
      if (testCase?.httpMethod === "GET") {
        const queryString = Object.keys(query_params).length
          ? Object.entries(query_params)
              .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
              .join("&")
          : "";

        return queryString ? `${baseUrl + endpoint}?${queryString}` : baseUrl + endpoint;
      }

      // For POST or other methods where body is required
      return baseUrl + endpoint;
    })();

    const requestBody =
      testCase?.httpMethod !== "GET" && Object.keys(query_params).length
        ? JSON.stringify(query_params)
        : null;

    return { fullUrl, requestBody };
  } catch (error) {
    console.log(error);
  }
};

export default ConstructUrl;
