import { ChevronDownIcon, FolderIcon, PresentationChartBarIcon } from "@heroicons/react/24/outline";

import axios from "axios";
import ConstructUrl from "./ConstructUrl";
import { useState } from "react";
import TestCaseExplorer from "./TestCaseExplorer";
import { API_BACKEND_URL } from "../../../Config/Config";

const Chain = ({
  chains,
  chain,
  baseUrl,
  setLoader,
  setActiveTab,
  setResponse5,
  setChainResult,
  setChainTest,
  setEndPoint,
  setUrl,
  setMethod,
  setBody,
  setHeaders,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isChainModal, setChainModal] = useState(false);
  const toggleAccordion = () => {
    setIsOpen((prev) => !prev);
  };

  const handleChainTest = async (collection) => {
    setLoader(true);
    const resultData = [];

    try {
      const results = {};

      for (const endpoint of collection.endpoints) {
        const { path, method, id } = endpoint;

        try {
          // Fetch test cases for the current endpoint
          const response = await axios.get(`${API_BACKEND_URL}/testcase?endpoint=${endpoint.id}`);
          const testCases = response.data;

          const pathObj = `${id}@${path}`;
          results[pathObj] = {};

          for (const testCase of testCases) {
            const { expectedStatusCode, expectedResult } = testCase;

            let updatedUrl = ConstructUrl(collection, testCase);
            let fullUrl = updatedUrl.fullUrl;
            let request_body = updatedUrl.requestBody;
            try {
              // Perform the API request
              const response = await axios({
                url: fullUrl,
                method,
                headers: {
                  "Content-Type": "application/json",
                },
                data: method === "GET" || method === "DELETE" ? null : request_body,
              });

              results[pathObj][expectedStatusCode] = {
                path,
                status: response.status,
                success: response.status.toString() === expectedStatusCode.toString(),
                expected: expectedResult,
                actual: response.data,
                method,
              };
              resultData.push(results);
            } catch (error) {
              const statusCode = error.response ? error.response.status : null;
              const errorData = error.response ? error.response.data : null;

              results[pathObj][expectedStatusCode] = {
                status: statusCode || "error",
                success: false,
                expected: expectedResult,
                actual: error.message,
                errorDetails: errorData || "No additional error details available.",
                method,
              };

              resultData.push(results);

              if (statusCode === 404) {
                console.warn(`Endpoint not found: ${fullUrl}`);
              } else if (statusCode === 500) {
                console.error(`Server error at: ${fullUrl}`);
              } else {
                console.log(`Unhandled error for endpoint: ${fullUrl}`);
              }
            }
          }
        } catch (fetchError) {
          console.error(`Error fetching test cases for endpointId ${endpoint.id}:`, fetchError);
        }
      }
    } catch (err) {
      console.error("Error in handleChainTest:", err);

      setResponse5({
        error: "An unexpected error occurred while testing endpoints.",
        details: err,
      });
    } finally {
      setChainResult(resultData);
      setActiveTab("testResults");
      setChainTest("chainResults");
      setLoader(false);
    }
  };

  const handleApiClick = (endpoint, chain) => {
    setEndPoint(endpoint);
    const isCompleteEndpoint = endpoint.path.includes("http") || endpoint.path.includes("https");
    if (isCompleteEndpoint) {
      setUrl(`${endpoint.path}`);
    } else {
      setUrl(`${baseUrl}${endpoint.path}`);
    }
    const path = endpoint.method;
    setMethod(path);

    if (path === "GET" || "DELETE") {
      setActiveTab("queryParams");
    } else if (endpoint.body) {
      console.log("request bodyyyyyy");
      setActiveTab("body");
      // setBody(endpoint.requestBody)
      setBody(inputData(endpoint.body));
      setHeaders(inputData(endpoint.headers));
    }
  };

  const inputData = (inputArray) => {
    if (inputArray) {
      const outputArray = Object.entries(inputArray).map(([key, value]) => ({
        key: key, // Use the original key from the input object
        value: value, // Use the original value from the input object
      }));
      return outputArray;
    }
    return [];
  };

  // Parse the steps JSON string into an array of objects
  const steps = JSON.parse(chain?.steps || "[]");

  return (
    <div className="border-b border-gray-200 max-w-sm bg-gray-50">
      <div className="group">
        <div
          className="flex items-center px-4 py-3 hover:bg-gray-100 cursor-pointer border shadow-sm mt-2"
          onClick={toggleAccordion}
        >
          <ChevronDownIcon
            className={`h-5 w-5 text-gray-500 mr-3 transition-transform ${
              isOpen ? "rotate-180" : ""
            }`}
          />
          <FolderIcon className="h-6 w-6 text-blue-400 mr-3" />
          <div className="flex-1 text-sm">
            <span>{chain?.name}</span>
            <span className="ml-3 text-xs font-bold text-orange-500">[Swagger]</span>
          </div>
        </div>

        {isOpen && (
          <div className="bg-white border  pb-2 rounded-sm shadow-md px-2">
            <div className="py-2 px-3 flex justify-center gap-4">
              <button
                onClick={() => handleChainTest(chain)}
                className="px-9 py-2 bg-postman-blue hover:bg-blue-700 rounded-sm text-white font-semibold font-sans text-sm "
              >
                Test the chain
              </button>
              <PresentationChartBarIcon className="w-8" onClick={() => setChainModal(true)} />
            </div>

            <div className="px-4 py-2 text-sm text-gray-600">
              <div className="mt-2">
                {steps.map((step, index) => (
                  <div key={index} className="mb-2">
                    <p className="ml-4">
                      <span
                        className={`mr-2 font-medium ${
                          step.http_method === "GET"
                            ? "text-green-600"
                            : step.http_method === "POST"
                              ? "text-yellow-600"
                              : step.http_method === "PUT"
                                ? "text-blue-600"
                                : "text-red-600"
                        }`}
                      >
                        {step.http_method}
                      </span>
                      {step.endpoint}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>

      <TestCaseExplorer
        handleClose={() => setChainModal(false)}
        isOpen={isChainModal}
        testCases={chains}
      />
    </div>
  );
};

export default Chain;
