import { useState, useEffect, useCallback } from "react";

const useItemWidth = (containerRef, itemsPerRow, gap) => {
  const [itemWidth, setItemWidth] = useState(320);

  const updateItemWidth = useCallback(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const availableWidth = containerWidth - (itemsPerRow - 1) * gap;
      const calculatedWidth = Math.floor(availableWidth / itemsPerRow);
      const finalWidth = Math.max(320, calculatedWidth);

      if (finalWidth !== itemWidth) {
        setItemWidth(finalWidth);
      }
    }
  }, [containerRef, itemsPerRow, gap, itemWidth]);

  useEffect(() => {
    updateItemWidth();

    const resizeObserver = new ResizeObserver(updateItemWidth);

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    window.addEventListener("resize", updateItemWidth);

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
      resizeObserver.disconnect();
      window.removeEventListener("resize", updateItemWidth);
    };
  }, [containerRef, updateItemWidth]);

  return itemWidth;
};

export default useItemWidth;
