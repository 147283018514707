import React, { Fragment, useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useBotGenerateTC, useModuleQuery, useProductQuery } from "../../useQuery";
import { v4 as uuidv4 } from "uuid";
import TestCaseTable from "./TestCaseTabel";
import axios from "axios";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../../Config/Config";
import CircularProgress from "@mui/material/CircularProgress";
import { useQueryClient } from "@tanstack/react-query";
import { CustomSelect } from "../../components-new/common";
import CustomDialog from "../../components-new/common/CustomDialog";
import useNotification from "../../hooks/useNotification";

function ChatBot() {
  const queryClient = useQueryClient();
  const notify = useNotification();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [showPrompt, setShowPrompt] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [productId, setProductId] = useState(null);
  const [moduleId, setModuleId] = useState(null);
  const [gerkinMsg, setGerkinMsg] = useState("");
  const [gerkinInfoMsg, setGerkinInfoMsg] = useState("");
  const [isGerkinModal, setIsGerkinModal] = useState(false);
  const [gerkinItem, setGerkinItem] = useState(null);
  const [isScripts, setIsScripts] = useState(false);
  const [scriptsMsg, setScriptsMsg] = useState("");
  const [scriptsInfoMsg, setScriptsInfoMsg] = useState("");

  const { data: products } = useProductQuery();
  const { data: modules, refetch: refetchModules } = useModuleQuery(productId, {
    enabled: false,
  });
  const { refetchWithParams: generateTC, data, isFetching, isSuccess } = useBotGenerateTC();

  useEffect(() => {
    if (!isSuccess) return;
    if (showPrompt) return;
    setMessages((prev) => [...prev, { sender: "Bot", data: data }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, data]);

  useEffect(() => {
    if (!productId) return;

    refetchModules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  const handleSubmit = () => {
    if (!input.trim()) return;
    setMessages((prev) => [...prev, { sender: "User", text: input }]);
    setInput("");
    generateTC({
      req: input,
    });
    setShowPrompt(false);
  };

  const generateScript = async (selected) => {
    const userDetails = localStorage.getItem("userDetail");
    if (!userDetails) throw new Error("No user details found in localStorage");
    const user = JSON.parse(userDetails);

    setIsProcessing(true);
    const response = await axios.post(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=generateScript&companyid=${user.companyid}&token=${user.token}`,
      selected, // Send the selected data in the body
    );
    setIsProcessing(false);
    const updatedResponseData = messages[1].data.map((item) => {
      const matchingMessage = response.data.find((message) => message.tcid === item.tcid);
      return matchingMessage ? { ...item, ...matchingMessage } : item;
    });

    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages];

      updatedMessages[1] = { ...updatedMessages[1], data: updatedResponseData };

      return updatedMessages;
    });
  };

  const handleProcessSelected = (selected) => {
    generateScript(selected);
  };

  const handleAutomate = (selected) => {
    saveScript(selected);
  };

  const saveScript = async (selected) => {
    if (!moduleId) {
      notify("Please select product and module", "warning");
      return;
    }
    const userDetails = localStorage.getItem("userDetail");
    if (!userDetails) throw new Error("No user details found in localStorage");
    const user = JSON.parse(userDetails);

    setIsProcessing(true);
    const response = await axios.post(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=saveScript&companyid=${user.companyid}&token=${user.token}`,
      selected, // Send the selected data in the body
      {
        params: {
          modid: moduleId,
        },
      },
    );
    setIsProcessing(false);
    const updatedResponseData = messages[1].data.map((item) => {
      const matchingMessage = response.data.find((message) => message.tcid === item.tcid);
      return matchingMessage ? { ...item, ...matchingMessage } : item;
    });

    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages];

      updatedMessages[1] = { ...updatedMessages[1], data: updatedResponseData };

      return updatedMessages;
    });
  };

  const handleGerkhinChange = () => {
    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages];
      const index = updatedMessages[1].data.findIndex(
        (msgItem) => msgItem.tcid === gerkinItem.tcid,
      );

      if (index !== -1) {
        const updatedItem = { ...updatedMessages[1].data[index], gherkin: gerkinMsg };

        updatedMessages[1].data[index] = updatedItem;
      }

      if (index !== -1) {
        if (!gerkinInfoMsg.length) return;
        const updatedItem = { ...updatedMessages[1].data[index], gherkinInfo: gerkinInfoMsg };

        updatedMessages[1].data[index] = updatedItem;
      }

      return updatedMessages;
    });
  };

  const handleScriptsChange = () => {
    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages];
      const index = updatedMessages[1].data.findIndex(
        (msgItem) => msgItem.tcid === gerkinItem.tcid,
      );

      if (index !== -1) {
        const updatedItem = { ...updatedMessages[1].data[index], automationScripts: scriptsMsg };

        updatedMessages[1].data[index] = updatedItem;
      }

      if (index !== -1) {
        if (!scriptsInfoMsg.length) return;
        const updatedItem = {
          ...updatedMessages[1].data[index],
          automationScriptsInfo: scriptsInfoMsg,
        };

        updatedMessages[1].data[index] = updatedItem;
      }

      return updatedMessages;
    });
  };

  const handleEdit = (item) => {
    setIsScripts(false);
    setGerkinMsg(item.gherkin);
    setGerkinItem(item);
    if (item.gherkinInfo) {
      setGerkinInfoMsg(item.gherkinInfo);
    }
    setIsGerkinModal(true);
  };

  const handleScriptsEdit = (item) => {
    setGerkinItem(item);
    setScriptsMsg(item.automationScripts);

    if (item.automationScriptsInfo) {
      setScriptsInfoMsg(item.automationScriptsInfo);
    }
    setIsScripts(true);
    setIsGerkinModal(true);
  };

  return (
    <>
      <CustomDialog
        width={"1000px"}
        title={isScripts ? "Scripts" : "Edit Exit Criteria"}
        open={isGerkinModal}
        actions={
          <Box p={2} pt={0}>
            <Button
              sx={{ color: "#ff5336" }}
              onClick={() => {
                if (isScripts) {
                  setScriptsMsg("");
                  setScriptsInfoMsg("");
                }
                if (!isScripts) {
                  setGerkinInfoMsg("");
                  setGerkinMsg("");
                }
                setIsGerkinModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ background: "#ff5336" }}
              onClick={() => {
                if (!isScripts) {
                  handleGerkhinChange();
                }
                if (isScripts) {
                  handleScriptsChange();
                }
                setIsGerkinModal(false);
              }}
              disabled={isScripts ? !scriptsInfoMsg : !gerkinInfoMsg}
            >
              Save
            </Button>
          </Box>
        }
        content={
          <>
            {!isScripts ? (
              <Box display={"flex"} gap={2}>
                <Box flex={1}>
                  <Typography color="#8089A8" fontSize="14px" fontWeight={500}>
                    Exit Criteria
                  </Typography>
                  <TextField
                    multiline
                    rows={10}
                    value={gerkinMsg}
                    fullWidth
                    InputProps={{
                      sx: { resize: "none" },
                    }}
                    onChange={(e) => setGerkinMsg(e.target.value)}
                  />
                </Box>

                <Box flex={1}>
                  <Typography color="#8089A8" fontSize="14px" fontWeight={500}>
                    Reason for change (mandatory)
                  </Typography>
                  <TextField
                    multiline
                    rows={10}
                    value={gerkinInfoMsg}
                    fullWidth
                    InputProps={{
                      sx: { resize: "none" },
                    }}
                    onChange={(e) => setGerkinInfoMsg(e.target.value)}
                  />
                </Box>
              </Box>
            ) : (
              <Box display={"flex"} gap={2}>
                <Box flex={1}>
                  <Typography color="#8089A8" fontSize="14px" fontWeight={500}>
                    Scripts Value
                  </Typography>
                  <TextField
                    multiline
                    rows={10}
                    value={scriptsMsg}
                    fullWidth
                    InputProps={{
                      sx: { resize: "none" },
                    }}
                    onChange={(e) => setScriptsMsg(e.target.value)}
                  />
                </Box>

                <Box flex={1}>
                  <Typography color="#8089A8" fontSize="14px" fontWeight={500}>
                    Scripts Info Message
                  </Typography>
                  <TextField
                    multiline
                    rows={10}
                    value={scriptsInfoMsg}
                    fullWidth
                    InputProps={{
                      sx: { resize: "none" },
                    }}
                    onChange={(e) => setScriptsInfoMsg(e.target.value)}
                  />
                </Box>
              </Box>
            )}
          </>
        }
      />
      <Box display="flex" gap={2} p={2} flexDirection="column">
        {!showPrompt && (
          <>
            <Box display="flex" alignItems="center" justifyContent="space-between" gap={2}>
              <Box display="flex" alignItems="center" gap={2}>
                <CustomSelect
                  label={"Product"}
                  options={(Array.isArray(products) ? products : []).map((prodItem) => ({
                    label: prodItem.productname,
                    value: prodItem.idproducts,
                  }))}
                  width={"230px"}
                  background={"#ffffff"}
                  value={productId || ""}
                  onChange={(e) => {
                    setProductId(e.target.value);
                    // setModuleId(null);
                  }}
                />
                {productId && (
                  <CustomSelect
                    label={"Module"}
                    options={(Array.isArray(modules) ? modules : []).map((modItem) => ({
                      label: modItem.modulename,
                      value: modItem.idmodules,
                    }))}
                    width={"230px"}
                    background={"#ffffff"}
                    value={moduleId || ""}
                    onChange={(e) => {
                      setModuleId(e.target.value);
                      // setTestCaseId(null);
                    }}
                  />
                )}
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  // sx={{ width: "50%", margin: "auto" }}
                  disabled={isFetching}
                  variant="contained"
                  onClick={() => {
                    setMessages([]);
                    queryClient.setQueryData(["generateBotTC"], null);
                    setShowPrompt(true);
                  }}
                >
                  New Requirement
                </Button>
              </Box>
            </Box>
            <Box flex={1} p={2} boxShadow={"0 4px 6px rgba(0,0,0,0.1)"} overflow={"auto"}>
              {messages.length > 0 &&
                messages.map((message) => (
                  <Fragment key={uuidv4()}>
                    {message.sender === "User" && (
                      <Box mb={2}>
                        <Typography fontWeight="800">{message.sender}</Typography>
                        {/* Using Box with white-space: pre-wrap to preserve the formatting */}
                        <Box
                          component="pre"
                          style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}
                        >
                          {message.text}
                        </Box>
                      </Box>
                    )}
                    {message.sender === "Bot" && !isProcessing && (
                      <Box mb={2}>
                        <TestCaseTable
                          data={message.data}
                          handleProcessSelected={handleProcessSelected}
                          handleAutomate={handleAutomate}
                          handleGerkhinChange={handleEdit}
                          handleScriptsChange={handleScriptsEdit}
                        />
                      </Box>
                    )}
                  </Fragment>
                ))}
              {(isFetching || isProcessing) && (
                <Box sx={{ display: "flex" }}>
                  <CircularProgress />
                </Box>
              )}
            </Box>
          </>
        )}
        <Box
          flex={1}
          p={2}
          boxShadow={"0 4px 6px rgba(0,0,0,0.1)"}
          display="flex"
          flexDirection="column"
          gap={2}
        >
          {showPrompt && (
            <>
              <textarea
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Type your message here..."
                style={{ resize: "none" }}
                rows={!showPrompt ? 10 : 40}
              />
              <Button
                disabled={isFetching}
                variant="contained"
                onClick={handleSubmit}
                sx={{ width: "50%", margin: "auto" }}
              >
                {isFetching ? `Generating test case...` : `Generate Test Cases`}
              </Button>
            </>
          )}
        </Box>
      </Box>
    </>
  );
}

export default ChatBot;
