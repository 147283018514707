import React from "react";
import ReactDOM from "react-dom/client";
import "./index.style.scss";

import reportWebVitals from "./reportWebVitals";

import { Provider } from "react-redux";
import { store } from "./Redux/store";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import App from "./App.component";
import ErrorBoundary from "./components-new/error-boundary/ErrorBoundary";
import { HelpProvider } from "./context/help-context";

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ErrorBoundary>
    <HelpProvider>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <App />
          {window.location.hostname === "localhost" && <ReactQueryDevtools initialIsOpen={false} />}
        </QueryClientProvider>
      </Provider>
    </HelpProvider>
  </ErrorBoundary>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
