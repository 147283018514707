import React from 'react';

const InlineReplaceIcon = ({ width = 406, height = 307, fill = "currentColor" }) => (
  <svg width="394" height="360" viewBox="0 0 394 360" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M298.823 358.852C298.185 359.483 297.159 359.483 296.521 358.852L234.646 297.624C233.998 296.984 233.998 295.939 234.646 295.299L296.778 233.816C297.419 233.181 298.453 233.186 299.09 233.825L311.765 246.592C312.402 247.234 312.397 248.271 311.754 248.908L277.228 283.073C276.189 284.101 276.917 285.873 278.378 285.873H370.938C371.841 285.873 372.574 285.14 372.574 284.237V181.627C372.574 180.724 373.307 179.991 374.21 179.991H392.338C393.242 179.991 393.974 180.724 393.974 181.627V305.413C393.974 306.316 393.242 307.049 392.338 307.049H278.398C276.933 307.049 276.207 308.827 277.252 309.853L311.736 343.74C312.387 344.38 312.389 345.427 311.741 346.07L298.823 358.852Z" fill="#242F3F"/>
  <path d="M95.1523 1.12884C95.7898 0.49807 96.8161 0.49807 97.4535 1.12884L159.329 62.3564C159.977 62.9969 159.977 64.0425 159.329 64.683L97.1963 126.165C96.5548 126.8 95.5207 126.795 94.8848 126.155L82.2099 113.389C81.5725 112.747 81.5771 111.709 82.2202 111.073L116.747 76.9076C117.786 75.8793 117.058 74.1079 115.596 74.1079H23.0366C22.1332 74.1079 21.4006 74.8405 21.4006 75.7443V178.354C21.4006 179.257 20.6681 179.99 19.7646 179.99H1.636C0.732462 179.99 -8.18e-08 179.257 0 178.354L1.08136e-05 54.5678C1.08954e-05 53.664 0.732473 52.9314 1.63601 52.9314H115.576C117.041 52.9314 117.768 51.1543 116.723 50.1278L82.2387 16.2418C81.5877 15.602 81.5859 14.5532 82.2347 13.9112L95.1523 1.12884Z" fill="#242F3F"/>
  <path d="M187.923 209.17H16.3639C7.32853 209.17 0.00390625 216.497 0.00390625 225.534V343.618C0.00390625 352.655 7.32853 359.981 16.3639 359.981H187.923C196.958 359.981 204.283 352.655 204.283 343.618V225.534C204.283 216.497 196.958 209.17 187.923 209.17Z" fill="#242F3F"/>
  <path d="M377.612 -0.00927734H206.053C197.018 -0.00927734 189.693 7.31697 189.693 16.3544V134.438C189.693 143.475 197.018 150.802 206.053 150.802H377.612C386.648 150.802 393.972 143.475 393.972 134.438V16.3544C393.972 7.31697 386.648 -0.00927734 377.612 -0.00927734Z" fill="#242F3F"/>
  </svg>
);

export default InlineReplaceIcon;


