import React, { useEffect, useState } from "react";
import "./NewCreateCollection.css";
import SideBar from "./SideBar";
import ListFile from "./ListFile";
import Execute from "./Execute";
import { ColorButton, colorExport } from "./../common/common.Component";
import { Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import ImpactAnalysis from "./../GitHubPage/ImpactAnalysis"
import Tabs from '@mui/material/Tabs';
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  fetchProducts,
  fetchModules,
  fetchSuiteCase,
  fetchTestCasesfromModule,
  fetchEnvironments,
  search,
  pagination,
  Addordeletetestcase,
  userexpreince,
  reorderTestcase,
  newSuite,
  Deletesuite,
  editTestSuitename,
  fetchLicenceKey,
  TestSuitespagination,
  SearchTestSuite,
  SearchAllTestSuites,
  GetTestCasesByTagsForProduct,
  AddModuleTestCase,
} from "./../../API/NewCreateCollectionAPI";
import { CommonMethods } from "./../common/CommonMethods";
import { useNavigate } from "react-router-dom";
import { API_BASE_PATH, API_BASE_RELATIVE_PATH } from "../../Config/Config";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import ClearAllIcon from '@mui/icons-material/ClearAll';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
function App() {

  const colors = colorExport();
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const navigate = useNavigate();

  const [pageno, changeno] = useState(1);
  const [fromImpactpage,setImpactpage]=useState(false);
  const [fromImpactselected,setFromImpactselected]=useState("");
  const [fromImpactpagedata,setImpactpagedata]=useState({});
  const [fromImpactpageid,setFromImpactpageid]=useState("");
  const [productList, setproductList] = useState([]);
  const [product, ChangeProduct] = useState("");
  const [moduleList, setModuleList] = useState([]);
  const [moduleid, changeModule] = useState("");
  const [suitesList, setSuitesList] = useState([]);
  const [testcase, setTestcase] = useState([]);
  const [testsuites, changesuite] = useState([]);
  const [deleteButton, changebutton] = useState("");
  const [removebtn, setremovebtn] = useState(false);
  const [addBotton, setaddBotton] = useState(false);
  const [environments, setEnvironments] = useState([]);
  const [selectedSuite, changesSelectedSuite] = useState([]);
  const [settingOnOff, changeSetting] = useState(false);
  const [suitid, setsuitid] = useState("");
  const [executePage, chageexecutePage] = useState(true);
  const [geturl, chagegeturl] = useState(true);
  const [nextpagebutton, changeNextbutton] = useState(false);
  const [showmessage, setshowmessage] = useState(true);
  const [urlid, seturlid] = useState("");
  const [searchinput, changesearchinput] = useState("");
  const browser = ["All", "Any", "Chrome", "Edge", "Firefox", "Safari",];
  const [selectedcase, changeselect] = useState([]);
  const [selectedcaseIndex, changeselectIndex] = useState([]);
  const [resolution, setResolution] = useState([]);
  const [addremmessage, setAddremmessage] = useState("");
  const [testcasename, settestcasename] = useState("");
  const [totalpages, setTotalpages] = useState(0);
  const [deletesuitepopup, setdeletesuitepopup] = useState(false);
  const [deletesuiteid, setdeletesuiteid] = useState(0);
  const [licenceKey, setLicenceKey] = useState();
  const [headers, setHeaders] = useState('');
  const [deleteTestcasesPopup, setDeleteTestcasesPopup] = useState(false);
  const [pageSize, setPageSize] = useState("");
  const [hidentotalpage, setHidenTotalPage] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [suiteSelectedid, setSuitesSelectedId] = useState(null);
  const [moveTestCaseModulepop, setMoveTestCaseModulepop] = useState(false);
  const [moveModuleid, setMoveModuleid] = useState(null);
  const [productImpact, setProduct] = useState("");
  const [knowledge, setKnowledge] = useState("");
  const [repo, setRepo] = useState("");
  const [toDate, setToDate] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [allCommentInTextArea, setAllCommentInTextArea] = useState("")
  const [suiteOption, setSuiteOption] = useState("");
  const [newTestSuite, setNewTestSuite] = useState("");
  const [suiteImpact, setSuitet] = useState("");
  let drag = 0;
  let source = 0;
  let testid = 0;

  const [value, setValue] = React.useState("1");

  const handleChange = (event , newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getProducts();
  }, []);
  useEffect(() => {
    if (testsuites[0]?.hasOwnProperty("list")) {
      testsuites[0].list.length === pageSize ? changeNextbutton(false) : changeNextbutton(true);
    } else {
      testsuites.length === pageSize ? changeNextbutton(false) : changeNextbutton(true);
    }
    searchinput !== "" ? setHidenTotalPage(true) : setHidenTotalPage(false);
    // if (totalpages === pageno) {
    //    changeNextbutton(true)
    //  }
    //  else {
    // changeNextbutton(false);
    // }
  }, [testcase, testsuites, suitesList, pageno, totalpages]);
  useEffect(() => {
    if (deleteButton) {
      if (searchinput !== "") {
        TestSuitesSearch(searchinput)

      } else {
        paginationSuites();
      }
    } else {
      if (moduleid !== "") {
        if (searchinput !== "") {
          if (testsuites[0][0]?.hasOwnProperty("Test_Suite")) {
            AllTestSuitesSearch(searchinput);
          }
          else {
            searchcases(searchinput);
          }
        } else {
          // page();
          if (testsuites[0][0]?.hasOwnProperty("Test_Suite")) {

          } else {
            page();
          }
        }
      }
    }
  }, [pageno]);
  useEffect(() => {
    changeno(1)
  }, [totalpages])

  // useEffect(() => {
  //    if (deleteButton) {
  //     TestSuitesSearch(searchinput)
  //   } else {
  //      if (moduleid !== "") searchcases(searchinput);
  //   }
  //   // if (moduleid !== "") searchcases(searchinput);
  // }, [searchinput]);
  useEffect(() => {
    setremovebtn(deleteButton && !(selectedcase.length === 0));
    setaddBotton(true && !(selectedcase.length === 0));
    if (urlid === "") setaddBotton(false && !(selectedcase.length === 0));
  }, [selectedcase, urlid]);

  useEffect(() => {
    changeselect([]);
  }, [suitid]);
  const getProducts = async () => {
    await fetchProducts()
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate);
        } else {
          // setLicenseKey(response.data.map((ele) => ele.licensekey));
          setproductList(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getModules = async (id) => {
    await fetchModules(id)
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate);
        } else {
          setModuleList(response.data);
          // setSuitesList(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getLicenceKey = async () => {
    await fetchLicenceKey()
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate);
        } else {
          setLicenceKey(response.data);
          // setSuitesList(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getSuites = async function (id) {
    await fetchSuiteCase(id)
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate);
        } else {
          setSuitesList((e) => (e = response.data));
          setPageSize(response.data[0][0].pageSize);
          // setTotalpages(response.data[0].totalPages);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const AllTestSuitesSearch = async (searchword) => {
    await SearchAllTestSuites(product, searchword, pageno)
      .then((response) => {
        if (response.data.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate)
        }
        else {
          changesuite(response.data);
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }
  const AllTagsForProduct = async (searchword) => {
    await GetTestCasesByTagsForProduct(product, searchword, pageno)
      .then((response) => {
        if (response.data.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate)
        }
        else {
          changesuite(response.data);
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }
  const TestSuitesSearch = async (searchword) => {
    await SearchTestSuite(suitid, searchword, pageno)
      .then((response) => {
        if (response.data.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate)
        }
        else {
          changesuite(response.data)
          setTotalpages(response.data[0].totalPages);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const paginationSuites = async () => {
    await TestSuitespagination(suitid, pageno)
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate)
        }
        else {
          setsuitid(response.data[0].idtest_suite);
          changesuite(response.data[0].list);
        }
      }).catch((error) => {
        console.log(error)
      })
  }
  const getenvi = async (id) => {
    await fetchEnvironments(id)
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate);
        } else {
          // setLicenseKey(response.data.map((ele) => ele.licensekey));
          setEnvironments(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getTestCase = async (id) => {
    await fetchTestCasesfromModule(id)
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate);
        } else {
          setTotalpages(response.data[0].totalPages);
          setTestcase(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const searchcases = async (serchdata) => {
    if (moduleid === "") return;
    await search(moduleid, serchdata, pageno)
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate);
        } else {
          setTestcase(response.data);
          setTotalpages(response.data[0].totalPages);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const page = async () => {
    await pagination(moduleid, pageno)
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate);
        } else {
          setTestcase(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const userData = async (id) => {
    await userexpreince(id)
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate);
        } else {
          setResolution(response.data["ResolutionList"]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const addTestcaseModule = async (moveModuleid, selectedcase) => {
    await AddModuleTestCase(moveModuleid, selectedcase)
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate);
        } else {

        }
      }).catch((error) => {
        console.log(error);
      })
  }
  const addordelete = async (action, id, index) => {
    let newid = id;
    setAddremmessage(`Test Cases added to test suite "${testcasename}"`);
    if (selectedcase.length === 0) {
      return;
    }
    if (id === "") {
      return;
    }
    if (action === "deleteTCfromTSuite") {
      newid = suitid;
      let name;
      suitesList.map((ele) => {
        if (ele[0].idtest_suite === newid) {
          name = ele[0].Test_Suite;
        }
      })
      setAddremmessage(`Test Cases removed from test suite "${name}"`);

    }

    await Addordeletetestcase(newid, action, selectedcase, pageno, selectedcaseIndex)
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate);
        } else {
          if (selectedcase) {
            paginationSuites();
          }
          modifingsuites(
            response.data[0]["idtest_suite"],
            response.data[0]["list"]
          );
          if (action === "addToTestSuites") {
            if (response.data[0]["Failure:"]) {
              setAddremmessage(`${response.data[0]["Failure:"]}`);
            }
          }
          if (action === "deleteTCfromTSuite" && response.data[0]["list"].length === 0) {
            changeno(pageno - 1);
            setTotalpages(totalpages - 1)
          }
        }
      })
      .then(() => {
        getSuites(product);
      })
      .catch((error) => {
        console.log(error);
      });
    changeselect([]);
  };
  function modifingsuites(id, list) {
    setshowmessage(!showmessage);
    suitesList.map((el, i) =>
      el[0]["idtest_suite"] === id
        ? (suitesList[i][0]["list"] = list)
        : console.log("didntfind")
    );
    // cases = list;
    // changesuite(list);
  }
  function collectsuites(suiteid, suite, bool, removeSearch, header) {
    setSuitesSelectedId(null)
    changesuite((prev) => (prev = suite));
    changebutton(bool);
    setsuitid(suiteid);
    if (suite.length !== 0) {
      if (bool) {
        setTotalpages(suite[0].list[0].totalPages);
      }
      else {
        if (testcase[0]?.hasOwnProperty("idtest_case")) {
          setTotalpages(suite[0].totalPages);
        } if (suite[0][0]?.hasOwnProperty("Test_Suite")) {
          setTotalpages(suite[0][0].totalPages);
        }
      }
    }
    setHeaders(header)

    if (removeSearch) {
      changesearchinput("")
    }
  }

  const onexecute = () => {
    chageexecutePage(!executePage);
  };
  function selectedProduct(event,fromimpact,typE) {
    let id;
    if(fromimpact){
      id=event;
      setImpactpage(false);
      if(typE==='NoSelection'){
        setImpactpage(true);
      }
    }else{
      id=event.target.value;
      setImpactpage(false);
    }
    ChangeProduct((e) => (e = id));
    getModules(id);
    getSuites(id);
    getenvi(id);
    userData(id);
    seturlid("");
  }
  function selectedModule(event) {
    changeModule((e) => (e = event.target.value));
    getTestCase(event.target.value);
  }

  function url() {
    chagegeturl(!geturl);

  }
  function nextpage() {
    // changeno(pageno + 1);
    if (pageno !== totalpages) {
      // changeNextbutton(true)
      changeno(pageno + 1);
    } else if (pageno === totalpages) {
      changeNextbutton(true);
    }
    else {
      changeNextbutton(false);
    }
  }
  function prevpage() {
    changeno(pageno - 1);
  }
  function clickonSuites(id) {
    setSuitesSelectedId(id);
  }
  function suiteforexicute(event) {
    seturlid(event.target.value);
    changeSetting(false);
    let obj = suitesList.find(
      (obj) => obj[0]["idtest_suite"] === event.target.value
    );
    changesSelectedSuite(obj);
    settestcasename(obj[0].Test_Suite);
  }
  function seturlidfromexecute(id) {
    seturlid(id);
  }
  function postapiAdd() {
    addordelete("addToTestSuites", urlid);
  }
  function addToSeletedCase(obj,index) {
    changeselect(selectedcase.concat([obj]));
    changeselectIndex(index);
  }
  function postapiDelete() {
    setDeleteTestcasesPopup(true)
    // addordelete("deleteTCfromTSuite");
  }
  function removeFromSeletedCase(obj) {
    selectedcase.map((el, i) =>
      obj["idtest_case"] === el["idtest_case"]
        ? selectedcase.splice(i, 1)
        : console.log()
    );
    const copy = selectedcase.slice();
    changeselect(copy);
  }
  function settingExecute(suite, bool) {
    chagegeturl(true)
    changesSelectedSuite(suite);
    seturlid(suite[0].idtest_suite);
    changeSetting(bool);
    onexecute();
  }
  function updatesuite() {
    getSuites(product);
  }

  function handledrag(ind, suiteindex, tesid) {
    drag = ind;
    source = suiteindex;
    testid = tesid;
  }
  function handledrop(ind, destination) {
    let copy;
    if (deleteButton) {
      console.log("pageno", pageno);
      let suite = testsuites.slice();
      if (testsuites[0]["list"] === undefined) {
        copy = testsuites.slice();
      } else {
        copy = testsuites[0]["list"].slice();
      }

      let rem = copy.splice(drag, 1);
      copy.splice(ind, 0, rem[0]);
      suite[0]["list"] = copy;
      changesuite(suite);
    }
    draganddrop(destination, ind);
  }
  const draganddrop = async (destination, destind) => {
    await reorderTestcase(testid, suitid, source, destination, drag, destind, pageno)
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate);
        } else {
          if (typeof response.data === Object) {
            changesuite(response.data);
          }
        }
      })
      .then(() => {
        getSuites(product);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const createsuite = async (name) => {
    await newSuite(product, name)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          }
        } else {
          console.log("response", response);
        }
      })
      .then(() => {
        getSuites(product);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  function newsuite(name) {
    createsuite(name);
  }
  const suitedelete = async (id) => {
    await Deletesuite(id, pageno)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            console.log("fail condition");
            CommonMethods(navigate);
          }
        } else {
          console.log("response", response);
        }
      })
      .then(() => {
        getSuites(product);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  function deletesuite(suite) {
    setdeletesuiteid(suite[0].idtest_suite);
    if (executePage !== true) onexecute();
    if (geturl !== true) url();
    setdeletesuitepopup(!deletesuitepopup);
    // suitedelete(suite[0].idtest_suite);
  }
  function editname(name, id) {
    editsuitename(name, id);
  }
  const editsuitename = async (name, id) => {
    await editTestSuitename(id, name)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          }
        } else {
          console.log("response", response);
        }
      })
      .then(() => {
        updatesuite();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // function CustomTabPanel(props) {
  //   const { children, value, index, ...other } = props;
  
  //   return (
  //     <div
  //       role="tabpanel"
  //       hidden={value !== index}
  //       id={`simple-tabpanel-${index}`}
  //       aria-labelledby={`simple-tab-${index}`}
  //       {...other}
  //     >
  //       {value === index && (
  //         <Box sx={{ p: 3 }}>
  //           <Typography>{children}</Typography>
  //         </Box>
  //       )}
  //     </div>
  //   );
  // }
  // function a11yProps(index) {
  //   return {
  //     id: `simple-tab-${index}`,
  //     'aria-controls': `simple-tabpanel-${index}`,
  //   };
  // }
  const testSuite = () => {
  return (
    <div className="App">
      <div className="header" style={{ backgroundColor: colors?.secondaryColor, color: colors?.primaryColor, }}>
        <div className="adjust" style={{ backgroundColor: colors?.secondaryColor, color: colors?.primaryColor, }}>

          <Typography paddingBottom={0} variant="h6" component="h1">
            {/* Create Test Suite */}
            {headers}
          </Typography>
        </div>
        <Box
          sx={{
            display: "flex",
            marginLeft: 4,
            flex: 1,
          }}
        >
          <FormControl sx={{ width: 240, color: colors.PrimaryColor }}>
            <InputLabel
              style={{
                color: colors.primaryColor,
                backgroundColor: colors.secondaryColor,
                padding: 5,
              }}
              id={"product"}
            >
              Product
            </InputLabel>
            <Select
              labelId={"product"}
              id={"product Select"}
              value={product}
              onChange={(ev)=>{
                selectedProduct(ev,false)
              }}
              name={"product"}
              label="product"
              IconComponent={(props) => (
                <ArrowDropDownSharpIcon
                  style={{ color: colors.primaryColor, fontSize: 40 }}
                  {...props}
                />
              )}
              sx={{
                color: colors.primaryColor,
                border: `2px solid ${colors.primaryColor}`,
                fontSize: 16,
                "&:focus": {
                  borderRadius: 4,
                  border: `4px solid ${colors.primaryColor}`,
                },
              }}
              style={{
                minWidth: 100,
                marginRight: "2rem",
              }}
            >
              {productList.map((product, index) => (
                <MenuItem key={index} value={product.idproducts}>
                  {product.productname}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {product !== "" && (
            <>
              <FormControl sx={{ width: 240, color: colors.PrimaryColor }}>
                <InputLabel
                  style={{
                    color: colors.primaryColor,
                    backgroundColor: colors.secondaryColor,
                    padding: 5,
                  }}
                  id={"Module"}
                >
                  Module
                </InputLabel>
                <Select
                  labelId={"Module"}
                  id={"Module Select"}
                  value={moduleid}
                  onChange={selectedModule}
                  name={"Module"}
                  label="Module"
                  IconComponent={(props) => (
                    <ArrowDropDownSharpIcon
                      style={{ color: colors.primaryColor, fontSize: 40 }}
                      {...props}
                    />
                  )}
                  sx={{
                    color: colors.primaryColor,
                    border: `2px solid ${colors.primaryColor}`,
                    fontSize: 16,
                    "&:focus": {
                      borderRadius: 4,
                      border: `4px solid ${colors.primaryColor}`,
                    },
                  }}
                  style={{
                    minWidth: 100,
                    marginRight: "2rem",
                  }}
                >
                  {moduleList.map((module, index) => (
                    <MenuItem key={index} value={module.idmodules}>
                      {module.modulename}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: 240, color: colors.PrimaryColor }}>
                <InputLabel
                  style={{
                    color: colors.primaryColor,
                    backgroundColor: colors.secondaryColor,
                    padding: 5,
                  }}
                  id={"product"}
                >
                  Suites
                </InputLabel>
                <Select
                  labelId={"Suites"}
                  id={"Suites Select"}
                  value={urlid}
                  onChange={suiteforexicute}
                  name={"Suites"}
                  label="Suites"
                  IconComponent={(props) => (
                    <ArrowDropDownSharpIcon
                      style={{ color: colors.primaryColor, fontSize: 40 }}
                      {...props}
                    />
                  )}
                  sx={{
                    color: colors.primaryColor,
                    border: `2px solid ${colors.primaryColor}`,
                    fontSize: 16,
                    "&:focus": {
                      borderRadius: 4,
                      border: `4px solid ${colors.primaryColor}`,
                    },
                  }}
                  style={{
                    minWidth: 100,
                    marginRight: "2rem",
                  }}
                >
                  {suitesList.map((el, i) => (
                    <MenuItem key={i} value={el[0]["idtest_suite"]}>
                      {el[0]["Test_Suite"]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Tooltip title={"Clear Filter"}>
                <IconButton
                  size="small"
                  variant="contained"
                  onClick={(event) => {
                    ChangeProduct("");
                    changeModule("");
                    seturlid("");
                  }}
                  style={{
                    color: colors.primaryColor,
                    backgroundColor: colors.secondaryColor,
                    marginLeft: 20,
                    // fontSize: '32px',
                  }}
                >
                  <ClearAllIcon style={{ fontSize: 50 }} />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Add"}>
                <IconButton
                  size="small"
                  variant="contained"
                  style={{
                    color: colors.primaryColor,
                    backgroundColor: colors.secondaryColor,
                    marginLeft: 50,
                    // fontSize: '32px',
                  }}
                  onClick={postapiAdd}
                >
                  <AddCircleIcon
                    style={{ fontSize: 45 }}
                    disabled={!addBotton}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Remove"}>
                <IconButton
                  size="small"
                  variant="contained"
                  style={{
                    color: colors.primaryColor,
                    backgroundColor: colors.secondaryColor,
                    marginLeft: 50,
                    // fontSize: '32px',
                  }}
                  onClick={postapiDelete}
                  disabled={!removebtn}
                >
                  <RemoveCircleIcon style={{ fontSize: 45 }} />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Move Test case Module"}>
                <IconButton
                  size="small"
                  variant="contained"
                  style={{
                    color: colors.primaryColor,
                    backgroundColor: colors.secondaryColor,
                    marginLeft: 50,
                    // fontSize: '32px',
                  }}
                  onClick={() => {
                    setMoveTestCaseModulepop(true);
                  }}
                >
                  <DriveFileMoveIcon
                    style={{ fontSize: 45 }}
                    disabled={!addBotton}
                  />
                </IconButton>
              </Tooltip>
            </>
          )}
          {product !== "" && (
            <Box
              sx={{
                display: "flex",
                ml: "5rem",
                flex: 1,
                justifyContent: "flex-end",
                alignItems: "center",
                //  flexDirection: "row",
                //  paddingY: "1.5rem",
                //  backgroundColor: colors.secondaryColor,
                //  color: colors.primaryColor,
                marginRight: '2rem'
              }}
            >
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  marginLeft: "0rem",
                  display: "flex",
                  //  alignItems: "center",
                  width: 400,
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search Test Case here.."
                  inputProps={{ "aria-label": "Search Test" }}
                  value={searchinput}
                  onChange={(e) => {
                    e.preventDefault();
                    changesearchinput(e.target.value);
                  }}
                />
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                  onClick={() => {
                    if (deleteButton) {
                      TestSuitesSearch(searchinput)
                    }
                    else {
                      if (moduleid == "" && (searchinput.startsWith("#") || searchinput.startsWith("repo:") || searchinput.startsWith("after:"))) {
                        AllTagsForProduct(searchinput);
                      } else if (testsuites[0][0]?.hasOwnProperty("Test_Suite")) {
                        AllTestSuitesSearch(searchinput);
                      } else {
                        if (moduleid !== "") searchcases(searchinput);
                      }
                    }
                  }}
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Box>
          )}
        </Box>
      </div>
      <div className="app__body">
        <SideBar
          fromImpactpage={fromImpactpage}
          fromImpactpagedata={fromImpactpagedata}
          fromImpactpageid={fromImpactpageid}
          fromImpactselected={fromImpactselected}
          suites={suitesList}
          testcase={testcase}
          funcCollect={collectsuites}
          settingExecute={settingExecute}
          newsuite={newsuite}
          deletesuite={deletesuite}
          product={product}
          suiteSelectedid={suiteSelectedid}
        />
        <span>
          <ListFile
            testcase={testsuites}
            delButton={deleteButton}
            addtoar={addToSeletedCase}
            removetoar={removeFromSeletedCase}
            product={product}
            moduleid={moduleid}
            handledrag={handledrag}
            handledrop={handledrop}
            clickonSuites={clickonSuites}
          />
        </span>
      </div>
      <span hidden={executePage}>
        <Execute
          suitesList={suitesList}
          suiteexecute={selectedSuite}
          environments={environments}
          cancelFunc={onexecute}
          className="exicutepage"
          browsers={browser}
          resolutions={resolution}
          settingOnOff={settingOnOff}
          updatesuite={updatesuite}
          url={url}
          getLicenceKey={getLicenceKey}
          seturlidfromexecute={seturlidfromexecute}
          editname={editname}
          product={product}
          urlid={urlid}
          licenceKey={licenceKey}
        />
      </span>
      {/* <div hidden={geturl}>
        <div className="geturl">
          <h4>
            {
              `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=DLExecuteSuite&companyid=${temp.companyid}&token=${licenceKey}&prodid=
            ${product}&SuiteId=${urlid}`
            }
          </h4>
          <IconButton onClick={url}>
            <CloseIcon />
          </IconButton>
        </div>
      </div> */}
      <span hidden={testsuites.length === 0} >
        <div className="page">
          <ColorButton
            size="small"
            variant="contained"
            sx={{ margin: "10px 2px 10px 5px" }}
            onClick={prevpage}
            className="executebutton"
            disabled={pageno === 1}
          >
            prev
          </ColorButton>
          {/* hidden={hidentotalpage} */}
          <h3>
            {pageno} of {totalpages}
          </h3>
          <ColorButton
            size="small"
            variant="contained"
            sx={{ margin: "10px 2px 10px 5px" }}
            disabled={nextpagebutton}
            onClick={nextpage}
            className="executebutton"
          >
            next
          </ColorButton>
        </div>
      </span>
      {deletesuitepopup && (
        <Alert
          variant="filled"
          severity="warning"
          className="delete-suite"
          style={{
            color: colors?.secondaryColor,
            border: " 4px black solid",
            width: "510px",
            height: '150px',
            borderRadius: '10px'
          }}
        >
          <span className="delete-text">
            Are you sure you want to delete this Suite?
          </span>
          <div className="delete_button">
            <ColorButton
              size="small"
              variant="contained"
              sx={{ margin: "10px 2px 10px 5px" }}
              onClick={() => {
                suitedelete(deletesuiteid);
                setdeletesuitepopup(false);
              }}
            >
              yes
            </ColorButton>
            <ColorButton
              size="small"
              variant="contained"
              sx={{ margin: "10px 2px 10px 5px" }}
              onClick={() => setdeletesuitepopup(!deletesuitepopup)}
            >
              no
            </ColorButton>
          </div>
        </Alert>
      )}
      <div hidden={showmessage}>
        <div className="statusmessage" style={{ width: '500px', height: '188px' }} >
          <div className="mesg_heading" style={{ backgroundColor: colors.secondaryColor }}>
            <h4 className="msg_heading_text" style={{ color: colors.primaryColor }}>Message</h4>
          </div>
          <p style={{
            marginTop: "19px",
            display: "flex",
            justifyContent: "center"

          }} >{addremmessage}</p>
          <ColorButton
            size="small"
            variant="contained"
            sx={{
              margin: "0",
              display: "flex",
              justifyContent: 'center',
              alignItems: "center",
              width: "auto",
              marginLeft: "9rem",
              marginRight: "9rem",
              marginTop: "55px"
            }}
            onClick={() => {
              setshowmessage(!showmessage);
            }}
          >
            close
          </ColorButton>
        </div>
      </div>
      {/* delete tst case from test suites  */}
      {
        deleteTestcasesPopup && (
          <Alert
            variant="filled"
            severity="warning"
            className="delete-suite"
            style={{
              color: colors?.secondaryColor,
              border: " 4px black solid",
              width: "550px",
              height: '150px',
              borderRadius: '10px'
            }}
          >
            <span className="delete-text">
              Are you sure you want to delete this Test Case?
            </span>
            <div className="delete_button">
              <ColorButton
                size="small"
                variant="contained"
                sx={{ margin: "10px 2px 10px 5px" }}
                onClick={() => {
                  addordelete("deleteTCfromTSuite");
                  setDeleteTestcasesPopup(false);
                }}
              >
                yes
              </ColorButton>
              <ColorButton
                size="small"
                variant="contained"
                sx={{ margin: "10px 2px 10px 5px" }}
                onClick={() => setDeleteTestcasesPopup(!deleteTestcasesPopup)}
              >
                no
              </ColorButton>
            </div>
          </Alert>
        )
      }
      {/* // */}
      {
        moveTestCaseModulepop && (
          <Box
            className="move_module"
          >
            <Typography
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: colors.primaryColor,
                backgroundColor: colors.secondaryColor,
                margin: 3
              }}
            >
              Move Test Case</Typography>
            <div style={{ display: "flex", justifyContent: "flex-start" }} >
              <FormControl sx={{ width: 240, margin: 2 }}>
                <InputLabel
                  style={{
                    padding: 5,
                  }}
                  id={"product"}
                >
                  Product
                </InputLabel>
                <Select
                  labelId={"product"}
                  id={"product Select"}
                  value={product}
                  onChange={(ev)=>{
                    selectedProduct(ev,false)
                  }}
                  name={"product"}
                  label="product"
                  IconComponent={(props) => (
                    <ArrowDropDownSharpIcon
                      {...props}
                    />
                  )}
                  sx={{
                    fontSize: 16,
                    "&:focus": {
                      borderRadius: 4,
                    },
                  }}
                  style={{
                    minWidth: 100,
                    marginRight: "2rem",
                  }}
                >
                  {productList.map((product, index) => (
                    <MenuItem key={index} value={product.idproducts}>
                      {product.productname}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {product !== "" && (
                <>
                  <FormControl sx={{ width: 240, margin: 2 }}>
                    <InputLabel
                      style={{
                        padding: 5,
                      }}
                      id={"Module"}
                    >
                      Module
                    </InputLabel>
                    <Select
                      labelId={"Module"}
                      id={"Module Select"}
                      value={moveModuleid}
                      onChange={(event) => {
                        setMoveModuleid(event.target.value)
                      }}
                      name={"Module"}
                      label="Module"
                      IconComponent={(props) => (
                        <ArrowDropDownSharpIcon
                          {...props}
                        />
                      )}
                      sx={{
                        fontSize: 16,
                        "&:focus": {
                          borderRadius: 4,
                        },
                      }}
                      style={{
                        minWidth: 100,
                        marginRight: "2rem",
                      }}
                    >
                      {moduleList.map((module, index) => (
                        <MenuItem key={index} value={module.idmodules}>
                          {module.modulename}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}
            </div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
              <ColorButton
                onClick={() => {
                  addTestcaseModule(moveModuleid, selectedcase)
                }}
              >
                Submit
              </ColorButton>
              <ColorButton
                onClick={() => {
                  setMoveTestCaseModulepop(false)
                }}
              >
                Cancel
              </ColorButton>
            </div>
          </Box>
        )
      }
      {/* // */}
    </div >
  );
  }
  // let impactpagedata={};
  function fromtheImpactAnalysis(obj){
    selectedProduct(obj.product,true,obj.selected)
    setFromImpactselected(obj.selected);
    setImpactpagedata(obj.testcase);
    setFromImpactpageid(obj.suitid);
    // impactpagedata={data:obj.testcase,id:obj.suite.id}
  }
  const impactAnalysis = () => {
    return <ImpactAnalysis 
      setValue={setValue}
      fromtheImpactAnalysis={fromtheImpactAnalysis}
      setProduct={setProduct}
      productImpact={productImpact}
      setKnowledge= {setKnowledge}
      knowledge= {knowledge}
      setRepo={setRepo}
      repo={repo}
      setToDate={setToDate}
      toDate={toDate}
      setFromDate={setFromDate}
      fromDate={fromDate}
      setAllCommentInTextArea={setAllCommentInTextArea}
      allCommentInTextArea={allCommentInTextArea}
      setSuiteOption={setSuiteOption}
      suiteOption={suiteOption}
      setNewTestSuite={setNewTestSuite}
      newTestSuite={newTestSuite}
      setSuitet={setSuitet}
      suiteImpact={suiteImpact}
    />
  }
  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        alignItem: "center",
        justifyContent: "space-evenly",
        paddingTop: "2rem",
        flexDirection: "column",
        paddingX: "2rem",
      }}
    >
      <Box sx={{ width: '100%', typography: 'body1' }}>

      <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', marginLeft: 4 }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Test Suite" value="1" />
              <Tab label="Impact Analysis" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">{testSuite()}</TabPanel>
          <TabPanel value="2"> {impactAnalysis()}</TabPanel>          
        </TabContext>
        </Box>
    </Box>
  )
}

export default App;
