import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { ScreenshotIcon, EditIcon, SynopsisIcon } from "../../components-new/icons";
import EditTabContainer from "./EditTabContainer";
import Synopsis from "./Edit/Synopsis";
import { fetchResultInRecording } from "../../API/RecordingTableAPI";
import Screenshot from "./Edit/Screenshot";
import TabComponent from "../../components-new/common/TabComponent";

const EditTabs = ({ editItem, fromExcReports = false }) => {
  const [valuesSavedResult, setValuesSavedResult] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getResults = async (api_input) => {
    setIsLoading(true);
    try {
      const response = await fetchResultInRecording(api_input);
      if (response?.data) {
        if (response.status === 200) {
          if (response.data.message !== "fail") {
            setValuesSavedResult(Object.values(response.data));
          }
        }
      }
    } catch (error) {
      console.error("Error fetching results:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getResults(editItem.Test_Case_Id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabs = [
    {
      icon: <EditIcon />,
      label: "Edit",
      content: <EditTabContainer item={editItem} isLoading={isLoading} />,
    },
    {
      icon: <ScreenshotIcon />,
      label: "Screenshot",
      content: <Screenshot currentEditItem={editItem} fromExcReports={fromExcReports} />,
    },
    {
      icon: <SynopsisIcon />,
      label: "Synopsis",
      content: (
        <Synopsis
          currentEditItem={editItem}
          fromExcReports={fromExcReports}
          valuesSavedResult={valuesSavedResult.filter(
            (values) => values.Test_Case_Results_Id === editItem.Test_Case_Id,
          )}
        />
      ),
    },
    {
      icon: <ScreenshotIcon />,
      label: "Image Diff",
      content: <Screenshot fromImageDiff={fromExcReports} currentEditItem={editItem} />,
    },
  ];

  if (isLoading) {
    return <Box p={3}>Loading...</Box>;
  }

  return (
    <Box sx={{ width: "100%" }} mt={5}>
      <TabComponent
        tabs={
          fromExcReports
            ? tabs.filter((item) => item.label !== "Edit")
            : tabs.filter((item) => item.label !== "Image Diff")
        }
      />
    </Box>
  );
};

export default EditTabs;
