import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useGetPageElements from "../../useQuery/useGetPageElements";
import searchIcon from "../../Assets/search-icon.svg";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";

const PageElementsView = () => {
  const { pageId } = useParams();
  const { data: elementsData, isLoading } = useGetPageElements(pageId);
  const [searchQuery, setSearchQuery] = useState("");

  // Filter elements based on search query
  const filteredElements =
    elementsData?.filter((element) =>
      element.elementname.toLowerCase().includes(searchQuery.toLowerCase()),
    ) || [];

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-[#151515] text-white urbanist-font">
        Loading elements...
      </div>
    );
  }

  return (
    <div className="min-h-screen  bg-[#151515]  p-8 ml-[320px]">
      <div className=" flex flex-col ">
        {/* Search Input */}
        <div className="flex flex-row  items-center justify-between mb-8 py-4 gap-4">
          <button onClick={() => window.history.back()}>
            <ArrowBackOutlinedIcon className="text-white" />
          </button>
          <div>
            <h1 className="text-white text-2xl font-bold mb-2  urbanist-font ">View Elements</h1>
            <p className="text-gray-400 text-md  urbanist-font ">
              List of Elements for page {pageId}
            </p>
          </div>
          <div className="relative flex flex-row items-center flex-1 justify-end">
            <div className="flex items-center flex-1 max-w-[340px] ml-4">
              <div className="relative w-full">
                <div className="absolute inset-y-0 left-4 flex items-center pointer-events-none">
                  <img src={searchIcon} alt="Search Icon" />
                </div>
                <input
                  type="text"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full h-[40px] py-2 pl-12 pr-4 bg-[#272727] rounded-[30px] text-gray-400 placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-600 urbanist-font"
                />
              </div>
            </div>
          </div>
        </div>
        {/* Elements Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 bg-[#1E1E1E] p-6 rounded-lg">
          {filteredElements.map((element) => (
            <div
              key={element.elementid}
              className="bg-black rounded-lg shadow-lg p-6 transform transition-all hover:scale-105 hover:shadow-xl relative"
            >
              {/* Edit Button */}
              <button
                className="absolute top-4 right-4 bg-white rounded-full h-10 w-10 flex items-center justify-center hover:bg-gray-200 transition-colors"
                onClick={() => {}}
              >
                <ModeEditOutlineOutlinedIcon className="text-black" />
              </button>

              <h3 className="text-xl font-bold text-white mb-2 urbanist-font">
                {element.elementname}
              </h3>

              <div className="space-y-2 text-gray-400">
                <p>
                  <span className="font-semibold urbanist-font">Unique Name:</span>{" "}
                  {element.uniquename}
                </p>
                <p>
                  <span className="font-semibold urbanist-font">Coverage Count:</span>{" "}
                  {element.coveragecount}
                </p>
                <p>
                  <span className="font-semibold urbanist-font">Element Type:</span>{" "}
                  {element.elementtype}
                </p>
              </div>

              <div className="mt-4 bg-gray-700 rounded-lg p-3">
                <p className="text-xs text-gray-300 break-words urbanist-font">
                  XPath: {element.recordedxpath}
                </p>
              </div>
            </div>
          ))}
        </div>

        {/* No Elements Found */}
        {filteredElements.length === 0 && (
          <div className="text-center text-gray-500 mt-16">
            <p className="text-2xl">No elements found</p>
            <p className="mt-2">Try adjusting your search query</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PageElementsView;
