import React, { createContext, useContext } from "react";
import helpData from "../utils/help.json";

const HelpContext = createContext();

export const HelpProvider = ({ children }) => {
  const getPageHelp = (path) => {
    if (helpData.pages[path]) {
      return helpData.pages[path];
    }

    const pathSegments = path.split("/");
    const lastSegment = `/${pathSegments[pathSegments.length - 1]}`;

    if (helpData.pages[lastSegment]) {
      return helpData.pages[lastSegment];
    }

    for (const key in helpData.pages) {
      if (path.includes(key)) {
        return helpData.pages[key];
      }
    }

    return null;
  };

  const value = {
    getPageHelp,
  };

  return <HelpContext.Provider value={value}>{children}</HelpContext.Provider>;
};

export const useHelp = () => {
  const context = useContext(HelpContext);
  if (!context) {
    throw new Error("useHelp must be used within a HelpProvider");
  }
  return context;
};
