import * as React from "react";

import nogruntlogo from "./nogruntlogo.svg";

export default function Navbar({ toggleSidebar }) {
  return (
    <nav className="bg-white px-6 py-3 border-b ">
      <div className="navbar">
        <img src={nogruntlogo} alt="Nogrunt" />
      </div>
    </nav>
  );
}
