import { ViewList, ViewModule } from "@mui/icons-material";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

const ViewToggle = ({ view, onViewChange }) => {
  return (
    <ToggleButtonGroup
      value={view}
      exclusive
      onChange={onViewChange}
      aria-label="view mode"
      size="small"
      sx={{
        backgroundColor: "#fff",
        "& .MuiToggleButton-root": {
          border: "1px solid rgba(0, 0, 0, 0.12)",
          "&.Mui-selected": {
            backgroundColor: "#2550d1",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#2550d1",
            },
          },
        },
      }}
    >
      <ToggleButton value="grid" aria-label="grid view">
        <ViewModule />
      </ToggleButton>
      <ToggleButton value="table" aria-label="table view">
        <ViewList />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ViewToggle;
