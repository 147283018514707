import axios from "axios";

import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

export const postEnviromentSubmit = async (api_input) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .post(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=editTestCase&companyid=${temp.companyid}&token=${temp.token}&EnvironmentBrowser=${api_input.EnvironmentBrowser}&EnvironmentUrl=${api_input.EnvironmentUrl}&EnvironmentName=${api_input.EnvironmentName}&Height=${api_input.Height}&Width=${api_input.Width}&testcase=${api_input.idtest_case}`,
      api_input,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};
export const validateDetailsFromDB = async (api_input) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=validate&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${api_input.testCaseId}&teststepid=${api_input.teststepid}&valdtype=${api_input.valdtype}&dbquery=${api_input.dbquery}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const validateDetailsFromRegex = async (api_input) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const encodedRegex = encodeURIComponent(api_input.regex);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=validate&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${api_input.testCaseId}&teststepid=${api_input.teststepid}&valdtype=${api_input.valdtype}&regex=${encodedRegex}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const validateDetailsFromGoogleSheet = async (api_input) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=validate&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${api_input.testCaseId}&teststepid=${api_input.teststepid}&valdtype=${api_input.valdtype}&sheetId=${api_input.fieldname}&sheet=${api_input.sheetId}&cell=${api_input.cell}&scope=${api_input.scope}&endRange=${api_input.endRange}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const validateDetailsFromApi = async (api_input) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const encodedQuery = api_input.apiQuery === null ? "" : encodeURIComponent(api_input.apiQuery);
  const encodedParam = api_input.apiParam === null ? "" : encodeURIComponent(api_input.apiParam);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=validate&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${api_input.testCaseId}&teststepid=${api_input.teststepid}&valdtype=${api_input.valdtype}&fieldName=${api_input.fieldName}&apiid=${api_input.apiid}&apiParam=${encodedParam}&apiQuery=${encodedQuery}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const validateDetailsFromDate = async (api_input) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);

  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=validate&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${api_input.testCaseId}&teststepid=${api_input.teststepid}&valdtype=${api_input.valdtype}&date=${api_input.date}&dateFormat=${api_input.dateFormat}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const validateReferenceFileData = async (api_input) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const encodedValue = encodeURIComponent(api_input.FileName);
  let apicall;
  if (api_input.fromFileType === "xlsx" || api_input.fromFileType === "csv") {
    apicall = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=validate&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${api_input.testCaseId}&teststepid=${api_input.teststepid}&valdtype=${api_input.valdtype}&name=${encodedValue}&sheetId=${api_input.sheetId}&cell=${api_input.cell}&scope=${api_input.scope}&endRange=${api_input.endRange}`;
  } else if (api_input.fromFileType === "json") {
    apicall = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=validate&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${api_input.testCaseId}&teststepid=${api_input.teststepid}&valdtype=${api_input.valdtype}&name=${encodedValue}&filter=${api_input.filter}&scope=${api_input.scope}&endRange=${api_input.endRange}`;
  } else {
    apicall = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=validate&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${api_input.testCaseId}&teststepid=${api_input.teststepid}&valdtype=${api_input.valdtype}&fileName=${encodedValue}`;
  }
  const Response = await axios
    .get(
      apicall,
      // `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=validate&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${api_input.testCaseId}&teststepid=${api_input.teststepid}&valdtype=${api_input.valdtype}&fileName=${encodedValue}&fileField=${api_input.filefield}`
      // http://localhost:9000/keyloggingv2/ReactApp?action=validate&companyid=1&token=06756068&testCaseId=493&teststepid=50421&valdtype=InRefrenceFile&fileName=NoGrunt.PNG&fileField=Nogrunt
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
  return Response;
};

export const validateDownloadedFile = async (api_input) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const encodedValue = encodeURIComponent(
    api_input.DownloadedFile,
  );

  let xlsUrl = "";
  if(api_input?.DownloadedFile?.includes(".xlsx")  || api_input?.DownloadedFile?.includes(".xls") ||
    api_input?.DownloadedFile?.includes(".csv")){
      xlsUrl = `&sheetId=${api_input.sheetId}&cell=${api_input.cell}&scope=${api_input.scope}&endRange=${api_input.endRange}`;
  }
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=validate&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${api_input.testCaseId}&teststepid=${api_input.teststepid}&valdtype=InDownloadedFile&name=${encodedValue}${xlsUrl}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
  return Response;
};

export const validateStreamedFile = async (api_input) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const encodedValue = encodeURIComponent(
    api_input.streamurl,
  );

 const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=validate&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${api_input.testCaseId}&teststepid=${api_input.teststepid}&valdtype=InStreamedFile&name=${encodedValue}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
  return Response;
};

export const validateDescriptionData = async (api_input) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const encodedValue = encodeURIComponent(api_input.DescriptionData);
  const Response = await axios
    .post(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=testcasedescription&companyid=${temp.companyid}&token=${temp.token}&tcid=${api_input.testCaseId}&description=${encodedValue}`,
      api_input.DescriptionData,
      //http://localhost:9000/keyloggingv2/ReactApp?action=testcasedescription&companyid=1&token=71423772&tcid=520&description=fasdgfjk
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const saveSettingsData = async (api_input) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const encodedValue = encodeURIComponent(api_input.DescriptionData);
  const Response = await axios
    .post(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=savetcsettings&companyid=${temp.companyid}&token=${temp.token}&tcid=${api_input.testCaseId}`,
      api_input,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const validateDetailsFromDependantTC = async (api_input) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const encodedValue = encodeURIComponent(api_input.usevar);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=validate&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${api_input.testCaseId}&teststepid=${api_input.teststepid}&valdtype=${api_input.valdtype}&dependantTC=${api_input.dependantTestCaseId}&useVar=${encodedValue}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const validateDetailsFromFile = async (api_input) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=validate&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${api_input.testCaseId}&teststepid=${api_input.teststepid}&valdtype=${api_input.valdtype}&filename=${api_input.filename}&filefield=${api_input.filefield}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};
export const validateDetailsOnScreen = async (api_input) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=validate&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${api_input.testCaseId}&teststepid=${api_input.teststepid}&valdtype=${api_input.valdtype}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const validateDetailsBySearch = async (api_input) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=validate&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${api_input.testCaseId}&teststepid=${api_input.teststepid}&valdtype=${api_input.valdtype}&testdata=${api_input.testdata}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const validateDetailsChangeText = async (api_input) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=validate&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${api_input.testCaseId}&teststepid=${api_input.teststepid}&valdtype=${api_input.valdtype}&testdatavalue=${api_input.UploadString}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
  return Response;
};

export const validateDetailsFromEmail = async (api_input) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);

  const baseUrl = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=validate&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${api_input.testCaseId}&teststepid=${api_input.teststepid}&valdtype=${api_input.valdtype}`;

  const url =
    api_input.valdtype === "FromEmail"
      ? `${baseUrl}&customerEmail=${encodeURIComponent(api_input.customerEmail)}&customerPassword=${encodeURIComponent(api_input.customerPassword)}&EmailSelectionCriteria=${encodeURIComponent(api_input.EmailSelectionCriteria)}&EmailFilter=${encodeURIComponent(api_input.EmailFilter)}`
      : `${baseUrl}&EmailSelectionCriteria=${encodeURIComponent(api_input.EmailSelectionCriteria)}&EmailFilter=${encodeURIComponent(api_input.EmailFilter)}`;

  const Response = await axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};
