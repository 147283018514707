import { Box } from "@mui/material";
import React from "react";
import { PageTitle } from "../components-new/common";
import IAComments from "./ImpactAnalysis/IAComments";

const Analytics = () => {
  return (
    <Box sx={{ mt: 2, pb: 4 }}>
      <PageTitle title="Impact Analysis" />
      <IAComments />
    </Box>
  );
};

export default Analytics;
