import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { styled } from "@mui/system";

const CustomDialogStyled = styled(Dialog)(({ width }) => ({
  "& .MuiPaper-root": {
    width: width || "500px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    borderRadius: "14px",
    maxWidth: width,
  },
  "& .MuiDialogContent-root": {
    width: "100%",
  },
  "& .MuiDialogActions-root": {
    width: "100%",
    alignItems: "flex-end",
  },
}));

const CustomDialog = ({ open, onClose, title, content, actions, width }) => (
  <CustomDialogStyled open={open} onClose={onClose} width={width}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>{content}</DialogContent>
    <DialogActions>{actions}</DialogActions>
  </CustomDialogStyled>
);

export default CustomDialog;
