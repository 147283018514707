import React, { useEffect } from "react";
import { TrashIcon } from "@heroicons/react/24/outline";
import { API_BACKEND_URL } from "../../../Config/Config";

const HistoryPanel = ({
  setHistoryData,
  setUrl,
  setMethod,
  setResponse5,
  setActiveTab,
  historyData,
}) => {
  // Fetch the history data from the API endpoint
  useEffect(() => {
    const fetchHistoryData = async () => {
      try {
        const response = await fetch(`${API_BACKEND_URL}/history`);
        const data = await response.json();
        console.log("Fetched history data:", data); // Check the fetched data
        setHistoryData(data);
      } catch (error) {
        console.error("Error fetching history data:", error);
      }
    };

    fetchHistoryData();
  }, []);

  const getMethodColor = (method) => {
    const colors = {
      GET: "text-green-500",
      POST: "text-yellow-500",
      PUT: "text-blue-500",
      DELETE: "text-red-500",
      PATCH: "text-purple-500",
    };
    return colors[method] || "text-gray-500";
  };

  const handleDelete = (id) => {
    // Handle the delete logic (e.g., remove the item from the state)
    setHistoryData((prevData) => prevData.filter((item) => item.id !== id));
  };

  const handleHistoryItemClick = (apiRequest, apiResponse) => {
    setUrl(apiRequest.url);
    setMethod(apiRequest.method);
    setResponse5(apiResponse);
    setActiveTab("testResults");
  };

  return (
    <div className="flex flex-col min-h-full h-full">
      <div className="flex flex-col overflow-y-auto h-full">
        {Array.isArray(historyData) && historyData.length > 0 ? (
          historyData.map((item) => {
            const apiRequest = JSON.parse(item.apiRequest);
            const apiResponse = JSON.parse(item.apiResponse);

            return (
              <div
                key={item.id}
                className="flex items-center px-2 py-2 hover:bg-gray-300 cursor-pointer group"
                onClick={() => handleHistoryItemClick(apiRequest, apiResponse)}
              >
                <span className={`font-mono text-sm w-16 ${getMethodColor(apiRequest.method)}`}>
                  {apiRequest.method}
                </span>
                <span
                  className="text-sm text-postman-gray-medium truncate flex-1 overflow-hidden whitespace-nowrap w-[220px]"
                  title={apiRequest.url}
                >
                  {apiRequest.url}
                </span>
                <button
                  onClick={() => handleDelete(item.id)}
                  className="opacity-0 group-hover:opacity-100 p-1 hover:bg-gray-200 rounded transition-opacity"
                >
                  <TrashIcon className="h-4 w-4 text-gray-500 hover:text-red-500" />
                </button>
              </div>
            );
          })
        ) : (
          <div>No history data available.</div>
        )}
      </div>
    </div>
  );
};

export default HistoryPanel;
