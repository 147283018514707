import { styled } from "@mui/material/styles";
import { Box, Tooltip } from "@mui/material";

const StyledBox = styled(Box)`
  width: 40px;
  height: 40px;
  border-radius: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const IconContainerCircle = ({ children, background = "transparent", tooltipText }) => {
  return (
    <Tooltip title={tooltipText} arrow>
      <StyledBox sx={{ backgroundColor: background }} border={"2px solid #D7DBE8"}>
        {children}
      </StyledBox>
    </Tooltip>
  );
};

export default IconContainerCircle;
