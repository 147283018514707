import { configureStore } from "@reduxjs/toolkit";

import SiteSettingsReducer from "./SiteSettings/SiteSettings";
import authoringSliceReducer from "./TestAuthoring/TestAuthoring";
import notificationReducer from "./Notification/NotificationSlice";
import executionSliceReducer from "./ExecutionReport/ExecutionReport";
import testSuiteReducer from "./TestSuite/TestSuite";

export const store = configureStore({
  reducer: {
    SiteSettings: SiteSettingsReducer,
    authoringSlice: authoringSliceReducer,
    notification: notificationReducer,
    executionSlice: executionSliceReducer,
    testSuite: testSuiteReducer,
  },
});
