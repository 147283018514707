export const Loader2 = ({ description }) => {

    return (
        <div className="h-full flex flex-col gap-y-4 items-center justify-center">
            <div className="w-10 h-10 relative animate-spin">
                <img alt="Logo" src="/logo-loader.png" fill />
            </div>
            <p className="text-sm text-muted-foreground">
                Nogrunt LLM is generating test case of <span>{description}</span>...
            </p>
        </div>
    );
};