import React, { useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const BulkApiTester = ({
  results,
  testReport,
}) => {
    
  const ReportFrame = styled("iframe")({
    width: "100%",
    border: "none",
    height: "100%",
    borderRadius: "8px",

    marginTop: "20px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  });

  // Add this useEffect hook in your component
  useEffect(() => {
    if (testReport) {
      const frame = document.querySelector("iframe");
      frame.onload = () => {
        frame.style.height = `${frame.contentWindow.document.documentElement.scrollHeight}px`;
      };
    }
  }, [testReport]);

  return (
    <div sx={{ pl: 0 }} className="mx-4">
      <Box sx={{ mr: 1 }}>
        
        {testReport && (
          <Button
            variant="outlined"
            size="medium"
            color="secondary"
            href={testReport}
            target="_blank"
            sx={{ ml: 2, my: 2 }}
          >
            View in New Tab
          </Button>
        )}

        {results.length > 0 && (
          <Box>
            <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
              TEST RESULTS SUMMARY
            </Typography>

            <div className="bg-gray-200 rounded-lg shadow-md p-6 border-2">
              <div className="grid grid-cols-3 gap-6 place-items-center">
                <div className="flex flex-col">
                  <h2 className="text-lg font-semibold text-gray-700">Total Tests</h2>
                  <span className="text-2xl font-bold text-gray-900">{results.length}</span>
                </div>

                <div className="flex flex-col">
                  <h2 className="text-lg font-semibold text-gray-700">Passed</h2>
                  <span className="text-2xl font-bold text-green-600">
                    {results.filter((r) => r.success).length}
                  </span>
                </div>

                <div className="flex flex-col">
                  <h2 className="text-lg font-semibold text-gray-700">Failed</h2>
                  <span className="text-2xl font-bold text-red-600">
                    {results.filter((r) => !r.success).length}
                  </span>
                </div>
              </div>
            </div>

            {/* Add the embedded report here */}
            {testReport && <ReportFrame src={testReport} title="Test Results Report" />}
          </Box>
        )}
      </Box>
    </div>
  );
};

export default BulkApiTester;
