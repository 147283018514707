export const KeywordData = [
  { value: "Assertion", label: "Assertion" },
  { value: "Alert", label: "Alert" },
  { value: "Button", label: "Button" },
  { value: "Check Box", label: "Check Box" },
  { value: "Clipboard", label: "Clipboard" },
  { value: "DropDown", label: "DropDown" },
  { value: "EditBox", label: "EditBox" },
  { value: "File", label: "File" },
  { value: "File Upload", label: "File Upload" },
  { value: "Link", label: "Link" },
  { value: "Radio Button", label: "Radio Button" },
  { value: "SVGElement", label: "SVGElement" },
  // { value: "Tab", label: "Tab" },
  { value: "TextArea", label: "TextArea" },
  { value: "Url", label: "Url" },
  { value: "Send", label: "Send" },
  { value: "Script", label: "Script" },
  { value: "Window", label: "Window" },
  { value: "TestCase", label: "Test Case" },
];

export const TestDataOptions = [
  { value: "AsRecorded", label: "As Recorded" },
  { value: "ChangeTestData", label: "Change TestData" },
  { value: "IsAVar", label: "Create As Reference Variable" },
  { value: "FromApi", label: "From Api" },
  { value: "FromDB", label: "From DB" },
  { value: "FromEmail", label: "From Email" },

  { value: "FromFile", label: "From File" },
  { value: "FromReferenceFile", label: "From TestData" },
  { value: "GenData", label: "Generate Data" },
  { value: "GoogleSheet", label: "Google Sheet" },
  // { value: "SameRow", label: "On Same Row as Test Data" },
  // { value: "SameRowElement", label: "On Same Row as Previous Element" },

  { value: "UseVariableFromTheScenario", label: "Data Dependence-This Scenario" },
  { value: "DependentonOtherTestScenario", label: "Data Dependence-Other Scenario" },
  // { value: "Script", label: "Script" },
  { value: "SendEmail", label: "Send Email" },
];

export const AssertionOptions = [
  { value: "ChangeTestData", label: "Change Text" },
  { value: "Date", label: "Date" },
  { value: "DynamicText", label: "Dynamic Text" },
  { value: "FromEmail", label: "From Email" },
  { value: "InApi", label: "In Api" },
  { value: "DB", label: "In DB" },
  { value: "InDownloadedFile", label: "In Downloaded Files" },
  { value: "InFile", label: "In File" },
  { value: "InGoogleSheet", label: "In Google Sheet" },
  { value: "InRefrenceFile", label: "In TestData" },
  { value: "OnScreen", label: "On Screen" },
  { value: "UseVariableFromTheScenario", label: "Data Dependence-This Scenario" },
  { value: "DependentonOtherTestScenario", label: "Data Dependence-Other Scenario" },
  { value: "Regex", label: "Regex" },
];

export const AssertTextOptions = [
  { value: "validatePartial", label: "Contains" },
  { value: "validateExact", label: "Exact" },
];

export const AssertSubOptions = [
  { value: "validateChecked", label: "Checked" },
  { value: "validateCount", label: "Count" },
  { value: "validatePartial", label: "Contains" },
  { value: "validateExact", label: "Exact" },
  { value: "validateEditable", label: "Editable" },
  { value: "validateFontFam", label: "Font Family" },
  { value: "validateFontSize", label: "Font Size" },
  { value: "validateHref", label: "Href" },
  { value: "validateMulti", label: "Multiple" },
  { value: "validatePageUrl", label: "Page URL" },
  { value: "validateActive", label: "Active" },
  { value: "validateSrc", label: "Image Src" },
];

export const DropDownOptions = [
  { value: "ByText", label: "By Text" },
  { value: "ById", label: "By Index" },
  { value: "ByValue", label: "By Value" },
];

export const CheckBoxOptions = [
  { value: "ByText", label: "By Text" },
  { value: "ById", label: "By Id" },
  { value: "ByPath", label: "By Path" },
];

export const AssertionTargetOptions = [
  { value: "ChangeTestData", label: "Change Text" },
  { value: "IsAVar", label: "Create As Reference Variable" },
  { value: "Date", label: "Date" },
  { value: "DynamicText", label: "Dynamic Text" },
  { value: "FromEmail", label: "From Email" },
  { value: "InApi", label: "In Api" },
  { value: "DB", label: "In DB" },
  { value: "InDownloadedFile", label: "In Downloaded Files" },
  { value: "InFile", label: "In File" },
  { value: "InGoogleSheet", label: "In Google Sheet" },
  { value: "InRefrenceFile", label: "In TestData" },
  { value: "OnScreen", label: "On Screen" },
  { value: "UseVariableFromTheScenario", label: "Data Dependence-This Scenario" },
  { value: "DependentonOtherTestScenario", label: "Data Dependence-Other Scenario" },
  { value: "Regex", label: "Regex" },
];

export const scopeOptionList = [
  { value: "Multi Run", label: "Multi Run" },
  { value: "Multi Execution", label: "Multi Execution" },
  { value: "Single Run", label: "Single Run" },
];

export const selectUserOptions = [
  { label: "testuser1@test.com", value: "testuser1@test.com" },
  { label: "testuser2@test.com", value: "testuser2@test.com" },
  { label: "testuser3@test.com", value: "testuser3@test.com" },
];

export const DurationOptions = [
  { label: "Last Week", value: "last week" },
  { label: "Last 15 days", value: "last 15 days" },
  { label: "Last Month", value: "last month" },
];

export const RunTimeOptions = [
  { label: "00:00 - 01:00", value: "00:00 - 01:00" },
  { label: "01:00 - 02:00", value: "01:00 - 02:00" },
  { label: "02:00 - 03:00", value: "02:00 - 03:00" },
  { label: "03:00 - 04:00", value: "03:00 - 04:00" },
  { label: "04:00 - 05:00", value: "04:00 - 05:00" },
  { label: "05:00 - 06:00", value: "05:00 - 06:00" },
  { label: "06:00 - 07:00", value: "06:00 - 07:00" },
  { label: "07:00 - 08:00", value: "07:00 - 08:00" },
  { label: "08:00 - 09:00", value: "08:00 - 09:00" },
  { label: "09:00 - 10:00", value: "09:00 - 10:00" },
  { label: "10:00 - 11:00", value: "10:00 - 11:00" },
  { label: "11:00 - 12:00", value: "11:00 - 12:00" },
  { label: "12:00 - 13:00", value: "12:00 - 13:00" },
  { label: "13:00 - 14:00", value: "13:00 - 14:00" },
  { label: "14:00 - 15:00", value: "14:00 - 15:00" },
  { label: "15:00 - 16:00", value: "15:00 - 16:00" },
  { label: "16:00 - 17:00", value: "16:00 - 17:00" },
  { label: "17:00 - 18:00", value: "17:00 - 18:00" },
  { label: "18:00 - 19:00", value: "18:00 - 19:00" },
  { label: "19:00 - 20:00", value: "19:00 - 20:00" },
  { label: "20:00 - 21:00", value: "20:00 - 21:00" },
  { label: "21:00 - 22:00", value: "21:00 - 22:00" },
  { label: "22:00 - 23:00", value: "22:00 - 23:00" },
  { label: "23:00 - 00:00", value: "23:00 - 00:00" },
];

export const StatusOptions = [
  { label: "Must fail", value: "Negative" },
  { label: "Must pass", value: "Positive" },
  { label: "Optional", value: "Optional" },
];

export const GenDataOptions = [
  { label: "CHAR", value: "CHAR" },
  { label: "ALPHA", value: "ALPHA" },
  { label: "NUMBER", value: "NUMBER" },
  { label: "EMAIL", value: "EMAIL" },
  { label: "TODAY", value: "TODAY" },
];

export const WaitOptions = [
  { label: "After", value: "After" },
  { label: "Before", value: "Before" },
  { label: "Check", value: "Check" },
];

export const ElementOptions = [
  { label: "Image", value: "IMG" },
  { label: "SVG", value: "SVG" },
  { label: "button", value: "Button" },
];

export const strategyItems = [
  {
    label: "X-Path",
    value: "X-Path",
  },
  {
    label: "X-Y Coordinates",
    value: "XY",
  },
  {
    label: "Search on Page",
    value: "Search",
  },
  {
    label: "On same row as test data",
    value: "SameRow",
  },
  {
    label: "On same row as previous element",
    value: "SameRowElement",
  },
];
