import React from "react";
import { Autocomplete, CircularProgress, IconButton, TextField } from "@mui/material";
import { SearchIcon } from "../../components-new/icons";

const AutocompleteComponent = ({
  options,
  value,
  onChange,
  isLoading,
  onInputChange,
  handleSearchClick,
  getOptionLabel,
  placeholder,
  disabled = false,
}) => {
  return (
    <Autocomplete
      freeSolo
      disabled={disabled}
      sx={{ width: "300px", backgroundColor: "#ffffff" }}
      value={value}
      onChange={onChange}
      options={options ?? []}
      getOptionLabel={getOptionLabel}
      onInputChange={onInputChange}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          fullWidth
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                <IconButton onClick={handleSearchClick}>
                  <SearchIcon />
                </IconButton>
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, option) => (
        <li {...props} key={option.value}>
          {getOptionLabel(option)}
        </li>
      )}
    />
  );
};

export default AutocompleteComponent;
