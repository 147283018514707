import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_BASE_PATH, API_BASE_RELATIVE_PATH } from "../Config/Config";
import axios from "axios";

const replaceTestSteps = async ({ data, params }) => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");
  const user = JSON.parse(userDetails);

  const response = await axios
    .post(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=replaceTestSteps&companyid=${user.companyid}&token=${user.token}`,
      data,
      {
        params,
      },
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });

  return response.data;
};

const useReplaceTestStepsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: replaceTestSteps,
    onSuccess: () => {
      queryClient.invalidateQueries(["testCaseList"]);
    },
    onError: (error) => {
      console.log("Error ", error.message);
    },
  });
};

export default useReplaceTestStepsMutation;
