import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useSearchTcDropDown } from "../../useQuery";
import { Box, Pagination } from "@mui/material";
import searchIcon from "../../Assets/search-icon.svg";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../../Config/Config";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const Home = () => {
  const [selectedRowIndex, setSelectedRowIndex] = useState(0);
  const [sortBy, setSortBy] = useState("Latest");
  const [showSortDropdown, setShowSortDropdown] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const userDetails = localStorage.getItem("userDetail");
  const user = JSON.parse(userDetails);
  const pomDownload =
    user?.company?.backendcoverage === "true" && user?.company?.coverage === "true" ? true : false;
  const selectedModule = user.products[0].modules[0].idmodules;
  const selectedProduct = user.products[0].idproducts;
  const [currentPage, setCurrentPage] = useState(1);

  const { data: testCases, isLoadingTestcase } = useSearchTcDropDown(selectedModule, currentPage);

  const totalPages = testCases && testCases.length > 0 ? testCases[0].totalPages : 0;

  const handleRowClick = (index) => {
    setSelectedRowIndex(index);
  };

  const sortData = (data) => {
    if (!data) return [];

    const sortedData = [...data];
    switch (sortBy) {
      case "Latest":
        return sortedData.sort((a, b) => {
          return new Date(b.Created_Date) - new Date(a.Created_Date);
        });
      case "Oldest":
        return sortedData.sort((a, b) => {
          return new Date(a.Created_Date) - new Date(b.Created_Date);
        });
      case "Name A-Z":
        return sortedData.sort((a, b) => a.Test_Case.localeCompare(b.Test_Case));
      case "Name Z-A":
        return sortedData.sort((a, b) => b.Test_Case.localeCompare(a.Test_Case));
      default:
        return sortedData;
    }
  };

  const handleSortClick = () => {
    setShowSortDropdown(!showSortDropdown);
  };

  const handleSortSelect = (option) => {
    setSortBy(option);
    setShowSortDropdown(false);
  };

  const handleDownload = (testCase) => {
    try {
      const userDetails = localStorage.getItem("userDetail");
      const user = JSON.parse(userDetails);
      const downloadUrl = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=genCode&companyid=${user.companyid}&token=${user.token}&testcaseid=${testCase.idtest_case}&testcasename=${testCase.Test_Case}`;

      window.location.href = downloadUrl;
    } catch (error) {
      console.error("Error downloading file:", error);
      throw error;
    }
  };

  const handlePomDownload = () => {
    try {
      const userDetails = localStorage.getItem("userDetail");
      const user = JSON.parse(userDetails);
      const downloadUrl = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=gpcTesting&companyid=${user.companyid}&token=${user.token}&pid=${selectedProduct}`;

      window.location.href = downloadUrl;
    } catch (error) {
      console.error("Error downloading POM file:", error);
      throw error;
    }
  };
  const filteredData = testCases?.filter((testCase) =>
    testCase.Test_Case.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const sortedData = sortData(filteredData);

  if (isLoadingTestcase)
    return (
      <div className="bg-[#151515] min-h-screen flex items-center justify-center ml-[320px]">
        <div className="text-white text-xl urbanist-font bg-[#1E1E1E] p-8 rounded-lg shadow-lg flex items-center space-x-4">
          <svg
            className="animate-spin h-6 w-6 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <span>Loading...</span>
        </div>
      </div>
    );

  return (
    <div className=" bg-[#151515] p-8 min-h-screen ml-[320px]">
      <div className="flex flex-row  items-center justify-between mb-8 py-4">
        <div>
          <h1 className="text-white text-2xl font-bold mb-2  urbanist-font ">
            Available Downloads
          </h1>
          <p className="text-gray-400 text-md  urbanist-font ">
            List of files available for download. Click the download button to get the file
          </p>
        </div>
        <div className="relative flex flex-row items-center flex-1 justify-end">
          <div className="flex items-center flex-1 max-w-[340px] ml-4">
            <div className="relative w-full">
              <div className="absolute inset-y-0 left-4 flex items-center pointer-events-none">
                <img src={searchIcon} alt="Search Icon" />
              </div>
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full h-[40px] py-2 pl-12 pr-4 bg-[#272727] rounded-[30px] text-gray-400 placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-600"
              />
            </div>
          </div>
          <button
            onClick={handleSortClick}
            className="flex items-center justify-center space-x-1 bg-[#1E1E1E] text-[12px] text-gray-400 w-[140px] h-[40px] rounded-[30px] ml-4"
          >
            <span className="urbanist-font">Sort By: {sortBy}</span>
            <KeyboardArrowDownIcon className="w-5 h-5" />
          </button>
          {showSortDropdown && (
            <div className="absolute right-0 top-12 w-[140px] bg-[#1E1E1E] rounded-lg shadow-lg py-1 z-10">
              {["Latest", "Oldest", "Name A-Z", "Name Z-A", "Size"].map((option) => (
                <button
                  key={option}
                  onClick={() => handleSortSelect(option)}
                  className="w-full text-left px-4 py-2 text-[12px] text-gray-400 hover:bg-gray-800 urbanist-font"
                >
                  {option}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="bg-[#1E1E1E] rounded-lg overflow-hidden p-4">
        <table className="w-full bg-[#181818] rounded-md ">
          <thead>
            <tr className="text-gray-400 text-sm border-b border-gray-800 ">
              <th className="text-left py-4 px-6 urbanist-font">SCENARIO</th>
              <th className="text-left py-4 px-6 urbanist-font">CREATED DATE</th>
              <th className="text-left py-4 px-6 urbanist-font">CODE</th>
              {pomDownload && (
                <th className="text-left py-4 px-6 urbanist-font">PAGE OBJECT MODEL</th>
              )}
            </tr>
          </thead>
          <tbody>
            {sortedData?.map((testCase, index) => (
              <tr
                key={testCase.idtest_case}
                className={`${
                  index === selectedRowIndex
                    ? "bg-gradient-to-r from-purple-600 via-pink-500 to-orange-500"
                    : "hover:bg-gray-800"
                } cursor-pointer transition-colors`}
                onClick={() => handleRowClick(index)}
              >
                <td className="py-4 px-6">
                  <span className="text-white font-medium urbanist-font">{testCase.Test_Case}</span>
                </td>
                <td className="py-4 px-6 text-white urbanist-font">{testCase.Created_Date}</td>
                <td className="py-4 px-6 flex flex-row space-x-2">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDownload(testCase);
                    }}
                    className="flex items-center justify-center h-[40px] w-[40px] rounded-full bg-white hover:bg-gray-200 transition-colors"
                  >
                    <FileDownloadIcon className="w-4 h-4" />
                  </button>
                </td>
                {pomDownload && (
                  <td className="py-4 px-20  space-x-4 ">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePomDownload(testCase);
                      }}
                      className="flex items-center justify-center h-[40px] w-[40px] rounded-full bg-white hover:bg-gray-200 transition-colors"
                    >
                      <FileDownloadIcon className="w-4 h-4" />
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Box py={2} display={"flex"} justifyContent="center">
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(e, newpage) => setCurrentPage(newpage)}
          color="primary"
          variant="outlined"
          shape="rounded"
        />
      </Box>
    </div>
  );
};

export default Home;
