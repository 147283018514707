import React, { useState } from "react";
import { XMarkIcon, PlusIcon } from "@heroicons/react/24/outline";
import { API_BACKEND_URL } from "../../../Config/Config";

const EndpointForm = ({ collectionID, onClose }) => {
  const [formData, setFormData] = useState({
    path: "",
    method: "",
    summary: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const url = `${API_BACKEND_URL}/${collectionID}/endpoints`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const result = await response.json();
        console.log("Response:", result);
        alert("Data submitted successfully!");
        onClose();
      } else {
        console.error("Error:", response.status, response.statusText);
        alert("Failed to submit data.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while submitting the data.");
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl">
        <div className="flex items-center justify-between p-3 border-b border-postman-gray-light">
          <h2 className="text-lg font-semibold text-postman-gray-dark">Add New API</h2>
          <button
            onClick={onClose}
            className="text-postman-gray-medium hover:text-postman-gray-dark"
          >
            <XMarkIcon className="h-6 w-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-4">
          <div className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <input
                  type="text"
                  name="path"
                  value={formData.path}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border  rounded-md focus:ring-2 focus:ring-postman-blue focus:border-transparent"
                  placeholder="Path"
                  required
                />
              </div>
              <div>
                <input
                  type="text"
                  name="method"
                  value={formData.method}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border  rounded-md focus:ring-2 focus:ring-postman-blue focus:border-transparent"
                  placeholder="Method"
                  required
                />
              </div>
            </div>

            <div>
              <textarea
                name="summary"
                value={formData.summary}
                onChange={handleChange}
                rows={3}
                className="w-full px-3 py-2 border  rounded-md focus:ring-2 focus:ring-postman-blue focus:border-transparent"
                placeholder="Summary"
                required
              />
            </div>

            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-postman-gray-medium hover:text-postman-gray-dark"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="flex items-center px-4 py-2 bg-postman-orange text-white rounded-md hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-postman-orange"
              >
                <PlusIcon className="h-5 w-5 mr-2" />
                Add API
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EndpointForm;
