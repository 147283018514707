export const CognitiveSrc = [
  { label: "Open AI", value: "Open AI" },
  { label: "Custom Models", value: "Custom Models" },
  { label: "Hastags", value: "Hastags" },
];

export const SuiteOptions = [
  { label: "New Test Suite", value: "NewTestSuite" },
  { label: "Existing Test Suite", value: "ExistingTestSuite" },
  { label: "No Selection", value: "NoSelection" },
];
