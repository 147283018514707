import React from 'react';

const PomDownloadIcon = ({ width = 406, height = 307, fill = "currentColor" }) => (
  <svg width={width} height={height} viewBox="0 0 406 307" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path 
      d="M279.096 306.196C274.119 306.196 269.861 304.583 266.32 301.356C262.773 298.124 261 294.241 261 289.706V264.97H279.096V289.706H387.67V264.97H405.766V289.706C405.766 294.241 403.995 298.124 400.455 301.356C396.908 304.583 392.646 306.196 387.67 306.196H279.096ZM333.383 273.215L288.144 231.989L300.811 220.033L324.335 241.471V174.272H342.431V241.471L365.955 220.033L378.622 231.989L333.383 273.215Z" 
      fill={fill} 
    />
    <path 
      fillRule="evenodd" 
      clipRule="evenodd" 
      d="M215.619 274.349H32.2763V64.5527H0V274.349C0 292.101 14.5244 306.625 32.2763 306.625H215.619V274.349ZM215.619 242.073H96.829C79.077 242.073 64.5527 227.548 64.5527 209.796L64.7141 32.2763C64.7141 14.5244 79.077 0 96.829 0H177.52L209.796 32.2763H322.763C340.515 32.2763 355.04 46.8007 355.04 64.5527V138.06H322.763V64.5527H196.402L164.125 32.2763H96.829V209.796H215.619V242.073Z" 
      fill={fill} 
    />
  </svg>
);

export default PomDownloadIcon;

