import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

const fetchStepHistory = async () => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found");
  const user = JSON.parse(userDetails);

  const response = await axios.get(`${API_BASE_PATH}/KeyLogging/api/mobile-capabilities/company`);

  return response.data;
};

const useGetCapabilitiesQuery = () => {
  return useQuery({
    queryKey: ["capabilities"],
    queryFn: () => fetchStepHistory(),
    retry: true,
  });
};

export default useGetCapabilitiesQuery;
