import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  suiteList: [],
  selectedSuite: null,
};

const testSuiteSlice = createSlice({
  name: "testSuite",
  initialState,
  reducers: {
    updateSuiteList: (state, action) => {
      state.suiteList = action.payload;
    },
    setSelectedSuite: (state, action) => {
      state.selectedSuite = action.payload;
    },
    setselectedsuitedata: (state, { payload: { updatedData } }) => {
      if (updatedData.idtest_suite === state.selectedSuite) {
        const suiteIndex = state.suiteList.findIndex(
          (suite) => suite.idtest_suite === updatedData.idtest_suite,
        );
        if (suiteIndex !== -1) {
          state.suiteList[suiteIndex] = { ...state.suiteList[suiteIndex], ...updatedData };
        }
      }
    },
    updateSuiteField: (state, action) => {
      const { field, value } = action.payload;

      // Find the suite matching state.selectedSuite
      const suiteIndex = state.suiteList.findIndex(
        (suite) => suite.idtest_suite === state.selectedSuite,
      );

      if (suiteIndex !== -1) {
        // Update the field with the new value
        state.suiteList[suiteIndex] = {
          ...state.suiteList[suiteIndex],
          [field]: value, // Update the specific field
        };
      }
    },
  },
});

export const { updateSuiteList, setSelectedSuite, setselectedsuitedata, updateSuiteField } =
  testSuiteSlice.actions;
export default testSuiteSlice.reducer;
